import MainNamespace from './globalComponents/MainNamespace';
import releaseLandingPage from 'client/components/ReleaseLandingPage';

const mainNamespace = new MainNamespace();

mainNamespace.addComponents({
    releaseLandingPage
});

mainNamespace.init();
