import Component from 'client/core/Component';
import ajax from 'client/utils/ajax';
import $ from 'jquery';
import { isAuthenticated, isWishListOnlyRegisteredEnabled } from 'sitePreferences';
import { throttle } from 'lodash';

const SELECTORS = {
    brandName: '.b-product-tile-brand',
    brandContainer: '.b-product-tile-title-container',
    wishlistBtn: '.js-wish-list-btn',
    wishlistIcon: '.js-wishlist-icon',
    productTile: '.js-product-tile',
    productTileLink: '.js-product-tile-link',
    tileContainer: '.js-tile-container',
    contentSlot: '[data-slot-id]',
    main: '.b-product-tile-inner',
    wishlistProducts: '.js-wishlist-info',
    carousel: '[data-cmp="carousel"]',
    slide: '.slick-slide',
    slideActive: 'slick-active'
};

const CLASSES = {
    wishlistAdd: 'js-wish-list--add',
    wishlistRemove: 'js-wish-list--remove',
    iconWishlistAdded: 'i-wishlist--added'
};

export default class ProductBrand extends Component {
    init() {
        this.$brandContainer = this.$el.find(SELECTORS.brandContainer);
        this.$brandName = this.$el.find(SELECTORS.brandName);

        if (this.$brandName.length) {
            this.$brandName[0].dataset.brand = this.$brandName[0].textContent;
            this.emitter.addListener('resize', throttle(this.calculateBrandWidth.bind(this), 200));
            this.emitter.addListener('devicechange', () => this.calculateBrandWidth());
            this.emitter.addListener('orientationchange', () => this.calculateBrandWidth());
            this.emitter.addListener('recalculateBrand', () => this.calculateBrandWidth());
            this.emitter.addListener('recalculateBrandCarousel', () => this.calculateBrandCarousel());
            this.calculateBrandWidth();
        }

        this.$wishlistBtn = this.$el.find(SELECTORS.wishlistBtn);
        this.$wishlistIcon = this.$el.find(SELECTORS.wishlistIcon);

        this.bindEvent('click', SELECTORS.wishlistBtn, this.onWishlistBtnClick.bind(this));

        this.bindEvent('click', SELECTORS.productTileLink, (el, ev) => {
            if (this.gtmEnabled) {
                this.config.list = this.$el.closest('[data-list]').data('list') || 'list';

                this.trackProductClick(el);
            }

            if (this.config.addToCartUrl) {
                this.onAddToCartBtnClick(el, ev);
            }
        });

        if (this.$wishlistBtn.length > 0) {
            this.pid = this.$wishlistBtn.data('pid');
        }

        this.emitter.addListener('on.click.update.conent',
            ($content, uuid) => this.onUpdateContent($content, uuid)
        );
    }

    onUpdateContent($tileWrapper, uuid) {
        if (this.$el.closest(SELECTORS.productTile).data('uuid') !== uuid) return;

        const wishListProductData = $(SELECTORS.wishlistProducts).data('wishlistProducts');
        let wishlistProducts = [];

        if (wishListProductData) {
            wishlistProducts = wishListProductData.split(',');
        }

        this.$el = $tileWrapper.find(SELECTORS.main);

        const pidInWishlist = wishlistProducts.indexOf(this.$el.data('pid')) > -1;

        this.$el.find(SELECTORS.wishlistBtn)
            .toggleClass(CLASSES.wishlistAdd, !pidInWishlist)
            .toggleClass(CLASSES.wishlistRemove, pidInWishlist)
            .find(SELECTORS.wishlistIcon)
            .toggleClass(CLASSES.iconWishlistAdded, pidInWishlist);

        this.init();
    }

    trackProductClick(el) {
        const $el = $(el),
            $productTile = $el.closest(SELECTORS.productTile),
            contentSlot = this.$el.closest(SELECTORS.contentSlot),
            slotID = contentSlot.data('slot-id'),
            slotName = contentSlot.data('slot-name'),
            recommenderName = contentSlot.data('recommender-name') || contentSlot.data('recommender'),
            $carousel = $el.closest(SELECTORS.carousel),
            list = slotID || $carousel.length ? 'Reco' : 'List',
            // eslint-disable-next-line no-nested-ternary
            specific = recommenderName ?
                (slotName ? `${slotName}/${recommenderName}` : recommenderName) :
                slotID || this.config.list;

        const product = $productTile.length ? $productTile.data('gtm') : null;

        if (product) {
            const position = $el.closest(SELECTORS.tileContainer).data('position');

            const eventData = Object.assign(
                { currencyCode: window.SitePreferences.currencyCode },
                {
                    'click': {
                        'actionField': {
                            'list': `${this.gtmPageType}/${list}/${specific}`
                        },
                        'products': [
                            Object.assign({ position: position || 1 }, product)
                        ]
                    }
                }
            );

            this.emitter.emit('gtmEcommerceEvent', 'eeProductClick', eventData);
        }
    }

    calculateBrandCarousel() {
        if (this.$el.closest(SELECTORS.slide).hasClass(SELECTORS.slideActive)) {
            this.calculateBrandWidth();
        }
    }

    calculateBrandWidth() {
        this.$brandContainerWidth = this.$brandContainer.width();
        this.$brandNameWidth = this.$brandName[0].scrollWidth;
        if (this.$brandNameWidth > this.$brandContainerWidth) {
            this.truncateContent();
        } else if (this.$brandNameWidth < this.$brandContainerWidth) {
            this.$brandName[0].textContent = this.$brandName[0].dataset.brand;
            this.$brandNameWidth = this.$brandName[0].scrollWidth;
            this.truncateContent();
        }
    }

    truncateContent() {
        while (this.$brandName[0].textContent.trim().split(' ').length > 1 &&
        this.$brandNameWidth > this.$brandContainerWidth) {
            this.$brandNameContent = this.$brandName[0].textContent;
            this.$truncatedContent = this.$brandNameContent.trim().split(' ').slice(0, -1).join(' ');
            this.$brandName[0].textContent = this.$truncatedContent;
            this.$brandNameWidth = this.$brandName[0].scrollWidth;
        }
    }

    onAddedToWishlist(resp) {
        this.emitter.emit('wishlist.added', Object.assign({ addedFrom: 'plp' }, resp));

        this.$wishlistBtn.removeClass(CLASSES.wishlistAdd).addClass(CLASSES.wishlistRemove);
        this.$wishlistIcon.addClass(CLASSES.iconWishlistAdded);
    }

    onRemovedFromWishlist(resp) {
        this.emitter.emit('wishlist.removed', Object.assign({ 'pid': this.pid, addedFrom: 'plp' }, resp));

        this.$wishlistBtn.removeClass(CLASSES.wishlistRemove).addClass(CLASSES.wishlistAdd);
        this.$wishlistIcon.removeClass(CLASSES.iconWishlistAdded);
    }

    addToWishlist(params) {
        var url = params.url,
            pid = params.pid;

        ajax.getJson({
            url: url,
            type: 'POST',
            data: {
                pid
            }
        }).then((resp) => {
            if (resp && (resp.success || resp.exists)) {
                this.onAddedToWishlist(resp);
            }
        });
    }

    removeFromWishlist(url) {
        ajax.getJson({
            url: url
        }).then((resp) => {
            if (resp && resp.success) {
                this.onRemovedFromWishlist(resp);
            }
        });
    }

    onWishlistBtnClick(el, ev) {
        ev.preventDefault();

        const $wishlistBtn = this.$wishlistBtn,
            action = $wishlistBtn.hasClass(CLASSES.wishlistAdd) ? 'add' : 'remove',
            params = {
                url: $wishlistBtn.data(`href-${action}`),
                pid: this.pid,
                callback: this.addToWishlist.bind(this)
            };

        if (!isAuthenticated) {
            this.emitter.emit('wishlist.clicked', params);
        }

        if (action === 'add') {
            if (!isAuthenticated && isWishListOnlyRegisteredEnabled) {
                this.emitter.emit('login.popup.show', params);

                return true;
            }
            params.pid && this.addToWishlist(params);
        } else {
            this.removeFromWishlist(params.url);
        }

        return false;
    }

    onAddToCartBtnClick(el, ev) {
        ev.preventDefault();

        const pid = this.config.pid,
            options = {},
            url = this.config.addToCartUrl,
            quantity = 1;

        ajax.getJson({
            url: url,
            type: 'POST',
            data: {
                pid,
                options,
                quantity
            }
        }).then((data) => {
            if (data && ('error' in data)) {
                if (data.error) {
                    this.emitter.emit('cart.add.error', data);
                } else {
                    this.emitter.emit('cart.add.added', data);
                }
            }
        });
    }
}
