import Component from 'client/core/Component';
import { CLASSES } from 'client/utils/globals';

const
    SELECTORS = {
        searchBtn: '.js-show-search-btn'
    };

export default class HeaderSearchSwitcher extends Component {

    init () {
        this.bindEvent('click', SELECTORS.searchBtn, this.searchShow);
        this.emitter.addListener('searchSwitcherShow', this.switcherShow.bind(this));
        this.emitter.addListener('searchSwitcherHide', this.switcherHide.bind(this));
    }

    searchShow () {
        this.emitter.emit('headerSearchShow');
        this.$el.addClass(CLASSES.invisible);
    }

    switcherShow () {
        this.$el.removeClass(CLASSES.invisible);
    }

    switcherHide () {
        this.$el.addClass(CLASSES.invisible);
    }
}
