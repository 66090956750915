import prefs from 'sitePreferences';

const mixins = {
    address: {
        validatePostalCode(addressType) {
            let postalCodeCmp = this.$el.find(`[data-id="${addressType}-postalCode"]`).data('cmp-instance');

            if (typeof postalCodeCmp.validate === 'function' && postalCodeCmp.getValue()) {
                postalCodeCmp.validate();
            }
        },
        onValidatePostalCode(cmp) {
            var isValid = false,
                cmpPrefix = cmp.id.split('-')[0],
                countryCode = this.getNestedComponentById(cmpPrefix + '-countryCode').getValue(),
                value = cmp.getValue(),
                regex = cmp.$el.data('regex'),
                prefPostalCode;

            if (!value || !countryCode) {
                cmp.setError && cmp.setError(cmp.data('requiredtext'));

                return isValid;
            }

            if (prefs.postalCode) {
                prefPostalCode = (prefs.postalCode[countryCode] && prefs.postalCode[countryCode].regrex) ||
                    regex ||
                    prefs.postalCode.default.regrex;
            }

            var rule = prefPostalCode || regex || '';

            isValid = (new RegExp(rule)).test(value);

            if (isValid) {
                cmp.clearError && cmp.clearError();
            } else {
                var message = (prefs.postalCode[countryCode] && prefs.postalCode[countryCode].message) ||
                prefs.postalCode.default.message;

                message && cmp.setError && cmp.setError(message);
            }

            return isValid;
        }
    }
};

export default mixins;
