// @ts-nocheck
/* eslint-disable no-restricted-globals */
/* eslint-disable object-curly-newline */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-plusplus */
import Component from 'client/core/Component';
import ajax from 'client/utils/ajax';
import {
    pushState,
    replaceState, setCustomReferer, removeCustomReferer,
    getPIDAnchor, setPIDAnchor, removePIDAnchor } from 'client/utils/history';
import { appendParamToURL, appendPreferenceToURL } from 'client/utils/url';
import { scrollTo } from 'client/utils/common';
import prefs from 'sitePreferences';
import $ from 'jquery';

const SELECTORS = {
    'NO_PRODUCTS': '.js-no-products-found',
    'PRODUCT_GRID': '.js-product-grid',
    'REFINEMENTS': '.js-refinements-wrapper',
    'MOBILE_REFINEMENTS': '.js-mobile-menu-wrapper',
    'FILTER_BAR': '.js-filter-bar',
    'CATEGORIES': '.js-categories-wrapper',
    'TITLE': '.js-plp-title',
    'PRODUCT_TILE': '.js-product-tile',
    'LOAD_MORE_BTN': '.js-show-more-products',
    'RECOMMENDATION_CAROUSEL': '.js-plp-reco-carousel',
    'ACTIVE_PROMOTION_FILTER': 'div.active-promo-filter'
};

const SPECIFIC_CATEGORIES = {
    new: 'isNew',
    soon: 'isComingSoon',
    sale: 'isSale'
};

export default class SearchWrapper extends Component {
    init () {
        this.noRefinementsCombination = this.$el.find(SELECTORS.NO_PRODUCTS).length > 0;
        this.statesPushed = 0;
        this.promotionFilterActive = $(SELECTORS.ACTIVE_PROMOTION_FILTER).length > 0;

        this.emitter.addListener('refinement.updated', $response => this.checkResponse($response));
        this.emitter.addListener('history.state.push', url => this.onPushState(url));
        this.emitter.addListener('scroll.to.anchor', () => this.scrollToAnchor());
        this.emitter.emit('trbo.page', this.config.pageType);

        this.bindEvent('click', SELECTORS.PRODUCT_TILE, (el) => this.productTileClick(el));

        if (this.gtmEnabled) {
            if (this.config.specificCategory && this.config.topTen) {
                this.emitter.emit('gtmChangeListResults', {
                    count: this.config.results,
                    topTenProduct: this.config.topTen
                });
            }

            if (this.config.pageType === 'search') {
                this.trackSearch();
            }
        }

        window.onload = () => {
            this.scrollToAnchor();
            // iPhone history fix
            setTimeout(() => {
                window.onpopstate = (e) => {
                    setTimeout(this.onPopState.bind(this), 0, e);
                };
            }, 100);
        };
    }

    productTileClick(el) {
        setPIDAnchor($(el).data('pid'));
    }

    trackSearch() {
        const data = {
            eventCategory: 'search',
            eventAction: this.config.keyword,
            eventLabel: this.config.results,
            ga4eventname: 'search'
        };

        this.emitter.emit('gtmEvent', data);
    }

    checkResponse($response) {
        let $noProducts = $response.find(SELECTORS.NO_PRODUCTS),
            $refinement = $response.find(SELECTORS.REFINEMENTS),
            $filterBar = $response.find(SELECTORS.FILTER_BAR),
            $productGrid = $response.find(SELECTORS.PRODUCT_GRID),
            $categories = $response.find(SELECTORS.CATEGORIES),
            $mobileRefinements = $response.find(SELECTORS.MOBILE_REFINEMENTS),
            $plpTitle = $response.find(SELECTORS.TITLE),
            $showRecommendatiOnPlp = $response.find(SELECTORS.RECOMMENDATION_CAROUSEL).data('show-plp-carousel');

        this.$el.find(SELECTORS.RECOMMENDATION_CAROUSEL).toggleClass('h-hide', !$showRecommendatiOnPlp);

        if ($showRecommendatiOnPlp) {
            let plpCarousel = this.getNestedComponentById('carousel');

            if (plpCarousel) {
                plpCarousel.$el.trigger('reinit');
            }
        }

        if ($noProducts.length > 0) {
            this.replaceHtml($noProducts.html(), this.$el.find(SELECTORS.PRODUCT_GRID));
            this.noRefinementsCombination = true;
        } else if (this.noRefinementsCombination && $productGrid.length > 0) {
            this.replaceHtml($productGrid.html(), this.$el.find(SELECTORS.PRODUCT_GRID));
            this.noRefinementsCombination = false;
        }

        // Reinit mobile menu
        this.replaceHtml($mobileRefinements.html(), this.$el.find(SELECTORS.MOBILE_REFINEMENTS));
        const mobileMenuCmp = this.getNestedComponentById('refinementMenu');

        this.emitter.emit('namespace.component.reinit', mobileMenuCmp.$el);

        // Reinit other components
        this.replaceHtml($refinement.html(), this.$el.find(SELECTORS.REFINEMENTS));
        this.replaceHtml($filterBar.html(), this.$el.find(SELECTORS.FILTER_BAR));
        this.replaceHtml($categories.html(), this.$el.find(SELECTORS.CATEGORIES));
        this.replaceHtml($plpTitle.html(), this.$el.find(SELECTORS.TITLE));
    }

    scrollToAnchor() {
        var pIdAnchor = getPIDAnchor();

        if (pIdAnchor) {
            if ('scrollRestoration' in history) {
                history.scrollRestoration = 'manual';
            }

            let anchorSelector = $(`${SELECTORS.PRODUCT_TILE}[data-pid="${pIdAnchor}"]`);

            anchorSelector.length && scrollTo(anchorSelector, { duration: 0 });
            anchorSelector.length && removePIDAnchor();
        }
    }

    onPopState(event) {
        if (this.statesPushed > 0) {
            event.preventDefault();
            if (--this.statesPushed === 0) {
                removeCustomReferer();
            }

            if ('scrollRestoration' in history) {
                history.scrollRestoration = 'manual';
            }

            var pIdAnchor = getPIDAnchor();

            if (pIdAnchor) {
                scrollTo(this.$el.find(SELECTORS.PRODUCT_TILE['data-pid=' + pIdAnchor]));
                removePIDAnchor();
            }
        } else if ('scrollRestoration' in history) {
            history.scrollRestoration = 'auto';
        }

        this.emitter.emit('search.popstate');

        let request = {
            // url: this.config.baseUrl || location.href,
            url: location.href || this.config.baseUrl,
            spinner: 'grid'
        };

        if (this.config.specificCategory) {
            if (!this.promotionFilterActive || this.config.specificCategory !== 'sale') {
                request.url = appendPreferenceToURL(
                    request.url,
                    this.config.baseUrl,
                    SPECIFIC_CATEGORIES[this.config.specificCategory],
                    'true'
                );
            } else if (this.promotionFilterActive && this.config.specificCategory === 'sale') {
                request.url = appendParamToURL(request.url, 'specificCategory', 'sale');
            }
        }

        if (this.config.isBrandListingPage === true) {
            request.format = 'html';
        }

        let promise = ajax.load(request);

        promise.then((r) => this.emitter.emit('refinement.updated', $('<div></div>').append(r)));
    }

    onPushState(url) {
        var productsStartSize = null;
        var start = null;
        var pageNumber = 0;

        if (this.statesPushed === 0 && !document.referrer) {
            setCustomReferer(location.href);
        }

        if (typeof url === 'object') {

            if (!url.isLoadMore) {
                return;
            }

            productsStartSize = url.productsStartSize;
            start = url.start;
            pageNumber = url.pageNumber;
            url = url.url;
        } else {
            url = appendParamToURL(url, 'sz', prefs.defaultGridSize || 'all');
        }

        if (productsStartSize && start && (pageNumber === 1) && (productsStartSize === start)) {
            replaceState(url);
            return;
        }

        pushState(url);

        this.statesPushed++;
    }
}
