import BaseProductCardSizeSelect from 'client/components/product/ProductCardSizeSelect';
import { CLASSES } from 'client/utils/globals';

const STATE_CLASSES = {
    selectPlaceholder: 'f-custom-select-title--placeholder'
};

export default class ProductCardSizeSelect extends BaseProductCardSizeSelect {
    renderSelect() {
        const options = Array.from(this.nativeSelect.find('option'));

        this.customSelectList.empty();
        options.map(this.addItem.bind(this));

        const value = this.nativeSelect.val();
        let title = this.customSelect.find(`li[data-value="${value}"]`).html();

        if (options.length === 1 && !options[0].value) {
            title = this.$el.data('outofstock-text');
        }

        if (!value) {
            this.customSelectTitle.addClass(STATE_CLASSES.selectPlaceholder);
        }

        this.placeholderText = title;
        this.customSelectTitle.html(title);
        this.nativeSelect.addClass(CLASSES.transparent);
    }
}
