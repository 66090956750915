import Component from 'client/core/Component';
import {
    isMedium, isSmall, SMALL, MEDIUM
} from 'client/utils/screendetector';
import { CLASSES } from 'client/utils/globals';

const SELECTORS = {
    invisible: 'h-invisible',
    quantity: '.js-quantity',
    goToCheckoutBtn: '.js-go-to-checkout-btn',
    goToCheckoutLink: '.js-go-to-checkout-link',
    page: '.js-page'
};

export default class JustAddedPopup extends Component {
    init () {
        this.timerID = null;
        this.isShown = false;
        this.pageType = this.$el.closest(SELECTORS.page).attr('data-action');

        this.$quantity = this.$el.find(SELECTORS.quantity);
        this.$goToCheckoutBtn = this.$el.find(SELECTORS.goToCheckoutBtn);

        this.emitter.addListener('cart.add.added', this.show.bind(this));
        this.emitter.addListener('cart.add.added.gtm', this.updateGtmData.bind(this));
        this.emitter.addListener('deviceChange', this.onChangeDevice.bind(this));

        if (this.gtmEnabled) {
            this.bindEvent('click', SELECTORS.goToCheckoutLink, this.onCheckoutBtnClick.bind(this));
        }
    }

    updateGtmData(eventData) {
        let newProduct = eventData.add.products[0];
        let itemUpdated = false;

        if (!this.config.gtm.checkout.products) {
            this.config.gtm.checkout.products = [];
        }

        for (let j = 0; j < this.config.gtm.checkout.products.length; j++) {
            const gtmItem = this.config.gtm.checkout.products[j];

            if (gtmItem.id === newProduct.id) {
                this.config.gtm.checkout.products[j].quantity += 1;
                itemUpdated = true;
            }
        }

        if (!itemUpdated) {
            this.config.gtm.checkout.products.push(newProduct);
        }
    }

    onCheckoutBtnClick() {
        const data = this.config.gtm;
        const total = this.config.total;

        if (data) {
            this.emitter.emit('gtmEcommerceEvent', 'eeCheckout', data, {
                'eventCategory': 'cart',
                'eventAction': 'begin checkout',
                'eventLabel': 'mini cart',
                'eventNonInteraction': 'true',
                'ga4eventname': 'begin_checkout',
                'value': total
            });
        }
    }

    show (data = {}) {
        if (!data.quantityTotal || !(isMedium() || isSmall())) {
            return;
        }

        this.config.total = data.cart.totals.grandTotalPrice.value;

        this.updateGoToCheckoutBtn(data.cart && data.cart.valid && !data.cart.valid.error);
        this.updateQuantity(data.quantityTotal);

        if (this.pageType !== 'Cart-Show') {
            this.timerStop();
            this.showElement();
            this.timerStart(5000, this.hideElement);
        }
    }

    updateGoToCheckoutBtn(enabled) {
        this.$goToCheckoutBtn.children('button').toggleClass(CLASSES.hide, enabled);
        this.$goToCheckoutBtn.children('a').toggleClass(CLASSES.hide, !enabled);
    }

    onChangeDevice (deviceType) {
        if (
            this.isShown &&
            deviceType !== SMALL &&
            deviceType !== MEDIUM
        ) {
            this.timerStop();
            this.hideElement();
        }
    }

    timerStart (time, callback) {
        this.timerID = window.setTimeout(callback.bind(this), time);
    }

    timerStop () {
        this.timerID && window.clearTimeout(this.timerID);
    }

    updateQuantity (quantity) {
        this.$quantity.text(quantity);
        if (quantity) {
            this.$quantity.removeClass(CLASSES.hide);
        } else {
            this.$quantity.addClass(CLASSES.hide);
        }
    }

    showElement () {
        this.emitter.emit('mobileCartPopupDisplayed');

        this.$el.removeClass(SELECTORS.invisible);
        this.isShown = true;
    }

    hideElement () {
        this.emitter.emit('mobileCartPopupHidden');

        this.$el.addClass(SELECTORS.invisible);
        this.isShown = false;
    }
}
