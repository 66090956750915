/* global google */

import GoogleMapsAbstractComponent from './GoogleMapsAbstractComponent';
import templatesManager from 'client/utils/templatesManager';
import prefs from 'sitePreferences';

const DEFAULT_MAP_OPTIONS = {
    scrollwheel: false,
    zoom: 13,
    minZoom: 5,
    maxZoom: 19
};

export default class GoogleMap extends GoogleMapsAbstractComponent {
    init () {
        super.init();

        this.config.mapOptions = Object.assign({}, DEFAULT_MAP_OPTIONS, this.config.mapOptions);
        this.centerPoint = null;
        this.markers = {};

        this.initialized = false;

        this._actions = [];
        this._actionInterval = null;
    }

    _addListener (eventName, eventCallback) {
        this.addListener(eventName, (...args) => this._runAction(eventCallback, ...args));
    }

    _runAction (fn, ...args) {
        if (this.giComponent) {
            fn.call(this, ...args);
        } else if (this._actionInterval) {
            this._actions.push({
                callback: fn,
                params: [...args]
            });
        } else {
            this._actions.push({
                callback: fn,
                params: [...args]
            });

            this._actionInterval = setInterval(() => {
                if (this.giComponent) {
                    clearInterval(this._actionInterval);

                    for (let action of this._actions) {
                        action.callback.call(this, action.params);
                    }

                    this._actionInterval = null;
                }
            }, 10);
        }
    }

    _initComponent () {
        if (this.initialized) {
            this.resize();
            return;
        }

        this.initialized = true;

        this.giComponent = window.qa = new google.maps.Map(
            this.$el.find('.js-canvas')[0],
            Object.assign({}, { mapTypeId: google.maps.MapTypeId.ROADMAP }, this.config.mapOptions)
        );
        this.bounds = new google.maps.LatLngBounds();

        if (this.config.marker) {
            this.addMarker(this.config.marker);
        }

        if (this.config.mapCenterLat && this.config.mapCenterLng) {
            this.center({
                lat: this.config.mapCenterLat,
                lng: this.config.mapCenterLng
            });
        } else {
            this.center();
        }

        this.resize();
    }

    hideAllInfoWindows () {
        for (let { infoWindow } of Object.values(this.markers)) {
            infoWindow && infoWindow.close();
        }
    }

    activateMarker (markerID, options = {}) {
        let storeMarker = this.markers[markerID];

        if (storeMarker) {
            this.giComponent.setCenter(storeMarker.marker.getPosition());
            this.hideAllInfoWindows();
            google.maps.event.trigger(storeMarker.marker, 'click');
            this.giComponent.setZoom(options.zoom || this.config.mapOptions.zoom);
        }
    }

    /**
     * @param {google.maps.LatLng | google.maps.LatLngLiteral} [newCenter]
     */
    center (newCenter) {
        if (newCenter) {
            this.centerPoint = newCenter;
        }

        this.giComponent && this.centerPoint && this.giComponent.setCenter(this.centerPoint);
    }

    resize () {
        if (this.giComponent) {
            google.maps.event.trigger(this.giComponent, 'resize');
            this.center();
        }
    }

    // Fit the all the store marks in the center of a minimum bounds
    fit () {
        this.giComponent.fitBounds(this.bounds);
    }

    clear () {
        for (let { marker, eventListener } of Object.values(this.markers)) {
            marker.setMap(null);
            eventListener && google.maps.event.removeListener(eventListener);
        }

        this.markers = {};
    }

    addMarkers (markerList) {
        this._runAction(markerList => {
            for (let marker of markerList) {
                this._addMarker(marker);
            }

            this.fit();
        }, markerList);
    }

    addMarker (marker) {
        this._runAction(marker => {
            this._addMarker(marker);
            this.fit();
        }, marker);
    }

    async _addMarker (item) {
        if (!this.config.mapMarkerIconUrl) { return; }

        let marker = new google.maps.Marker({
            position: new google.maps.LatLng(item.store.latitude, item.store.longitude),
            map: this.giComponent,
            icon: {
                url: this.config.mapMarkerIconUrl,
                // Magic values for this icon url
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(17, 46),
                scaledSize: new google.maps.Size(45, 46)
            }
        });

        this.markers[item.store.ID] = { marker };

        if (!item.content && this.config.markerInfoTemplateId) {
            let tmpl = templatesManager.templates[this.config.markerInfoTemplateId];

            if (tmpl) {
                item.isBopisEnabled = prefs.isBopisEnabled;
                item.currentFavouriteStoreID = await this.getFavouriteStoreAttrValue('DATA_FAVOURITE_STORE_ID');
                item = Object.assign({}, item, { content: tmpl(this.config.marker || item) });
            }
        }

        if (item.content) {
            let infoWindow = new google.maps.InfoWindow({
                maxWidth: 360,
                content: item.content
            });

            let eventListener = google.maps.event.addListener(marker, 'click', () => {
                this.hideAllInfoWindows();
                infoWindow.open(this.giComponent, marker);
            });

            google.maps.event.addListener(infoWindow, 'domready', this.initFSBOnGoogleInfoWindowReady.bind(this));

            Object.assign(this.markers[item.store.ID], { infoWindow, eventListener });
        }

        // Create a minimum bound based on a set of storeLocations
        this.bounds.extend(marker.getPosition());
    }
}
