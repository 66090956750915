import { getJson } from 'client/utils/ajax';
import Form from 'client/core/Form';
import {
    ADDRESS_VALIDATOR_ERROR,
    ADDRESS_VALIDATOR_DELIVERY_ERROR,
    ADDRESS_VALIDATOR_CHECK_FIELDS,
    ADDRESS_VALIDATOR_ARE_YOU_SURE
} from 'resources';
import { redirectTo } from 'client/utils/common';
import $ from 'jquery';

const DEFAULT_CONFIG = {
    isDisabled: false
};

const SELECTORS = {
    'SHIPPING_COUNTRY': '.js-shipping-country-code',
    'SUBMITTED_BTN':
        '.js-address-add-save, .js-address-edit-save, [data-cmp="shippingStep"] .js-checkout-step-submit'
};

export default class AddressValidator extends Form {
    init () {
        this.isRequiredValidation = true;
        this.fields = new Map(this.config.fields);

        this.cacheResult = new Map();


        this.config = Object.assign({}, DEFAULT_CONFIG, this.config);

        this.emitter.addListener('address.validator.corrected', (data) => this.updateAddressFromModal(data));
        this.emitter.addListener('checkout.csrf.updated.root', (data) => {
            this.config.csrfName = data.tokenName;
            this.config.csrfToken = data.token;
        });
    }

    collectData () {
        let data = [];

        for (let [name, cmpID] of this.fields) {
            let cmp = this.getNestedComponentById(cmpID);

            if (cmp && typeof cmp.getValue === 'function') {
                data.push({
                    name,
                    value: cmp.getValue()
                });
            }
        }

        data.push({
            name: this.config.csrfName,
            value: this.config.csrfToken
        });

        return data;
    }

    getEnteredData () {
        let data = {};

        for (let [name, cmpID] of this.fields) {
            let cmp = this.getNestedComponentById(cmpID);

            if (cmp && typeof cmp.getValue === 'function') {
                data[name] = cmp.getValue();
            }
        }

        data.countryName = this.$el.find(SELECTORS.SHIPPING_COUNTRY).val() || '';

        return data;
    }

    /**
     * @param {Array}
     *            data
     */
    dataToHash (data) {
        return JSON.stringify(data.filter(o => o.name !== this.config.csrfName));
    }

    updateAddress (address) {
        for (let [name, cmpID] of this.fields) {
            let cmp = this.getNestedComponentById(cmpID);

            if (cmp && typeof cmp.setValue === 'function' && name in address) {
                cmp.setValue(address[name]);
            }
        }
    }

    updateAddressFromModal(data) {
        this.updateAddress(data.address);

        this.isRequiredValidation = false;

        $(SELECTORS.SUBMITTED_BTN).trigger('click');
    }

    setDisabled (isDisabled) {
        this.config.isDisabled = isDisabled;
    }

    async onSubmit () {
        if (this.config.isDisabled) {
            return true;
        }

        let validationResult = await super.validate(),
            event = null,
            fieldsToCheck = null,
            isFieldsCheckNeeded = false;

        if (validationResult && this.isRequiredValidation) {
            let correctedAddress = null,
                supportedForDelivery = true;

            try {
                let data = this.collectData(),
                    dataAsString = this.dataToHash(data),
                    cachedResult = this.cacheResult.get(dataAsString),
                    response = {};

                if (cachedResult) {
                    response = cachedResult;
                } else {
                    response = await getJson({
                        type: 'POST',
                        url: this.config.url,
                        data: this.collectData()
                    });

                    this.cacheResult.set(dataAsString, response);
                }

                validationResult = response.valid;
                correctedAddress = response.correctedAddress;
                fieldsToCheck = response.fieldsToCheck;
                supportedForDelivery = response.supportedForDelivery;
            } catch (err) {
                if (err.responseJSON && err.responseJSON.redirectUrl) {
                    redirectTo(err.responseJSON.redirectUrl);

                    return false;
                }

                validationResult = true;
            }

            event = this.getEvent(correctedAddress);
            isFieldsCheckNeeded = Array.isArray(fieldsToCheck) && fieldsToCheck.length > 0;

            if (validationResult && isFieldsCheckNeeded) {
                this.emitter.emit(event.showEvent, {
                    type: 'warning',
                    message: ADDRESS_VALIDATOR_CHECK_FIELDS,
                    scrollTo: true
                });
                this._nestedComponents.forEach(cmp => {
                    if (fieldsToCheck.indexOf(cmp.id) > -1) {
                        cmp.setWarning(ADDRESS_VALIDATOR_ARE_YOU_SURE);
                    }
                });
            } else if (validationResult) {
                this.emitter.emit(event.hideEvent);
            } else if (correctedAddress) {
                this.emitter.emit(event.showEvent, {
                    templateAttrs: {
                        'suggested': correctedAddress,
                        'entered': this.getEnteredData()
                    }
                });
            } else if (!supportedForDelivery) { // eslint-disable-line
                                                // no-negated-condition
                this.emitter.emit(event.showEvent, {
                    type: 'error',
                    message: ADDRESS_VALIDATOR_DELIVERY_ERROR,
                    scrollTo: true
                });
            } else {
                this.emitter.emit(event.showEvent, {
                    type: 'error',
                    message: ADDRESS_VALIDATOR_ERROR,
                    scrollTo: true
                });
            }
        }

        if (!this.isRequiredValidation) {
            event = this.getEvent(false);
            this.emitter.emit(event.hideEvent);
        }

        this.isRequiredValidation = !isFieldsCheckNeeded;

        return validationResult && !isFieldsCheckNeeded;
    }

    getEvent(modal) {
        let showEvent = null,
            hideEvent = null;

        if (modal) {
            showEvent = this.config.suggestionAddressModalId ?
                `checkout.shipping.suggestionAddressModal.${this.config.suggestionAddressModalId}.show` :
                'checkout.shipping.suggestionAddressModal.show';
            hideEvent = this.config.suggestionAddressModalId ?
                `checkout.shipping.suggestionAddressModal.${this.config.suggestionAddressModalId}.hide` :
                'checkout.shipping.suggestionAddressModal.hide';
        } else {
            showEvent = this.config.infoBlockId ?
                `checkout.shipping.infoblock.${this.config.infoBlockId}.show` :
                'checkout.shipping.infoblock.show';
            hideEvent = this.config.infoBlockId ?
                `checkout.shipping.infoblock.${this.config.infoBlockId}.hide` :
                'checkout.shipping.infoblock.hide';
        }

        return { showEvent, hideEvent };
    }

    onValidateSuite(cmp) {
        const shippingStreet = this.getNestedComponentById('shipping-street'),
            value = cmp.getValue();

        return !value || (value !== shippingStreet.getValue());
    }
}
