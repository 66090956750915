import loyaltySignupPopup from 'client/components/loyalty/LoyaltySignupPopup';
import blockReviewAnchor from 'client/components/BlockReviewAnchor';
import blockReviewAnchorUrl from 'client/components/loyalty/BlockReviewAnchorUrl';
import dashBoardProfileCard from 'client/components/loyalty/DashBoardProfileCard';

export default {
    loyaltySignupPopup,
    blockReviewAnchor,
    blockReviewAnchorUrl,
    dashBoardProfileCard
};
