import Component from 'client/core/Component';
import MobileAddedToCartModel from 'client/model/MobileAddedToCartModel';
import { refresh, isTablet, isMobile } from 'client/utils/mobileDetect';
import { CLASSES } from 'client/utils/globals';
import { scrollTo, isInViewport } from 'client/utils/common';
import { isRNWebView, sendEventToMobileApp } from 'client/utils/react';
import { isAuthenticated, isLoginModalButtonEnabled } from 'sitePreferences';
import { setCookie } from 'client/utils/cookie';
import { throttle } from 'lodash';

import $ from 'jquery';

import lazySizes from 'lazysizes';

const lazySizesConfig = {
    lazyClass: 'h-lazyload',
    loadedClass: 'h-lazyloaded',
    loadingClass: 'h-lazyloading',
    preloadClass: 'h-lazypreload',
    errorClass: 'h-lazyerror',
    autosizesClass: 'h-lazyautosizes',
    loadMode: 1,
    hFac: 0,
    init: false
};

lazySizes.cfg = Object.assign(lazySizes.cfg, lazySizesConfig);

const STATE_CLASSES = {
    touchable: 'h-touchable',
    notTouchable: 'h-untouchable',
    scrollShow: 'h-scroll-show',
    noPopups: 'h-no-popups',
    mobileMenuOpened: 'h-mobile-menu-opened'
};

const SELECTORS = {
    footerLink: '.b-footer-service-link',
    footerContent: '.b-footer-service-content',
    footerSectionTitle: '.b-footer-service-title',
    teaser: '.js-teaser',
    slide: '.slick-slide',
    accountMenuLogoutLink: '.js-account-page-menu-link--logout',
    guestCheckoutBtn: '.js-guest-checkout-btn',
    blockScrollMoveUp: '.h-block-scroll-move-up:not(.h-lazyloaded)',
    loginPopupBtn: '.js-login-popup-btn',
    component: '[data-cmp]'
};

const POPUPS = [
    'cookieHint',
    'firstVisitPopup',
    'localizationDialog'
];

const $body = $('body');

// const NO_IMAGE = window.Urls.noImage;

export default class MediaInteraction extends Component {
    init () {
        this.offset = 0;
        this.disabledScroll = false;
        this.processBlockScrollListenerAdded = false;

        this.emitter.addListener('deviceChange', () => this.defineInteraction(true));
        this.emitter.addListener('resize', throttle(() => this.defineInteraction(true), 200));
        this.emitter.addListener('orientationchange', () => this.defineInteraction(true));
        this.emitter.addListener('disabledScroll', () => this.disableScroll());
        this.emitter.addListener('enabledScroll', () => this.enableScroll());
        this.emitter.addListener('processScrollMoveUp', () => this.scrollMoveUpProcessing());

        this.bindEvent('keyup', '[role="button"]', this.accessibleClick);

        if (isLoginModalButtonEnabled) {
            this.bindEvent('click', SELECTORS.loginPopupBtn, this.onLoginBtnClick);
        }

        this.defineInteraction();

        if (this.gtmEnabled) {
            this.bindEvent('click', SELECTORS.footerLink, this.trackFooterLinkClick.bind(this));
            this.bindEvent('click', SELECTORS.accountMenuLogoutLink, this.trackLogout.bind(this));

            this.$teaser = this.$el.find(SELECTORS.teaser);

            if (this.$teaser.length) {
                this.bindEvent('click', SELECTORS.teaser, this.trackBannerClick.bind(this));

                this.emitter.addListener('orientationChange', this.trackTeaserImpression.bind(this));
                this.emitter.addListener('resize', throttle(this.trackTeaserImpression.bind(this), 200));
                this.emitter.addListener('scroll', throttle(this.trackTeaserImpression.bind(this), 200));
                this.emitter.addListener('carouselAfterChange', (event, slick, currentSlide) => {
                    const $teaser = $(slick.$slides[currentSlide]).find(SELECTORS.teaser);

                    this.trackTeaserImpression($teaser);
                });
            }

            this.bindEvent('click', SELECTORS.guestCheckoutBtn, (el) => {
                this.trackGuestCheckout(el);
            });
        }

        if (isRNWebView()) {
            this.emitter.addListener('wishlist.added', sendEventToMobileApp.bind(this, {
                type: 'wishlist.updated'
            }, true));
            this.emitter.addListener('wishlist.removed', sendEventToMobileApp.bind(this, {
                type: 'wishlist.updated'
            }, true));
            this.emitter.addListener('wishlist.clicked', sendEventToMobileApp.bind(this, {
                type: 'wishlist.clicked'
            }, true));

            this.emitter.addListener('cart.add.added', data => {
                sendEventToMobileApp({
                    type: 'cart.item.added',
                    data: new MobileAddedToCartModel(data)
                }, true);
            });

            this.emitter.addListener('cart.updated', data => {
                sendEventToMobileApp({
                    type: 'cart.updated',
                    data: {
                        quantity: data.numItems || 1
                    }
                }, true);
            });

            this.emitter.addListener('favourite.store.updated', data => {
                sendEventToMobileApp({
                    type: 'favourite.store.updated',
                    data: data.params
                }, true);
            });

            if (document.readyState === 'interactive') {
                sendEventToMobileApp({
                    type: 'page.available'
                }, true);
            }

            if (this.config.mobilePageLoadEvent) {
                sendEventToMobileApp(this.config.mobilePageLoadEvent, true);
            }
        }

        this.scrollMoveUpProcessing();

        this.emitter.addListener('popupDone', (cmp) => this.onPopupDone(cmp));

        this.popupsToTrack = [];

        const onLoadComplete = () => {
            if (this.gtmEnabled) {
                this.trackTeaserImpression();
            }

            this.trackPopups();

            setTimeout(() => {
                this.initLazySizes();
            });
        };

        this.emitter.addListener('afterReplaceHtml', ($el) => this.initLazySizes($el));
        this.emitter.addListener('afterAppendHtml', ($el) => this.initLazySizes($el));
        this.emitter.addListener('afterRecommendations', ($el) => this.initLazySizes($el));
        this.emitter.addListener('setFavouriteStoreData', (data) => this.setFavouriteStoreData(data));

        if (document.readyState === 'complete') {
            onLoadComplete();
        } else {
            $(window).on('load', onLoadComplete.bind(this));
        }

        $(document).on('lazyloaded', (e) => this.onLazyLoaded(e));
    }

    onLoginBtnClick($el, event) {
        event.preventDefault();

        setCookie('loginBackUrl', window.location.href);

        if (!isAuthenticated && isLoginModalButtonEnabled) {
            this.emitter.emit('login.popup.show', {
                isCustomLoginBtn: true
            });
        }
    }

    scrollMoveUpProcessing() {
        this.scrollMoveUpBlocks = this.$el.find(SELECTORS.blockScrollMoveUp);

        if (this.scrollMoveUpBlocks.length) {
            if (!this.processBlockScrollListenerAdded) {
                this.emitter.addListener('orientationChange', this.processBlockScrollMoveUp.bind(this));
                this.emitter.addListener('resize', throttle(() => this.processBlockScrollMoveUp(), 500));
                this.emitter.addListener('scroll', throttle(() => this.processBlockScrollMoveUp(), 500));

                this.processBlockScrollListenerAdded = true;
            }

            this.processBlockScrollMoveUp();
        }
    }

    setFavouriteStoreData(data) {
        var keys = Object.keys(data);

        keys.forEach(element => {
            this.$el.attr(element, data[element]);
        });
    }

    initLazySizes($scope = null) {
        if ($scope) {
            if (!$scope.find(`.${lazySizesConfig.lazyClass}`).length) {
                return;
            }
        } else {
            $scope = this.$el;
        }

        // Apple do not normally work with this handler. SNPSTA-2259
        // $scope.find(`.${lazySizesConfig.lazyClass}`).on('error', (e) => {
        //     const $img = $(e.target);

        //     $img.siblings('source').remove();
        //     $img.removeAttr('data-src');
        //     $img.attr('src', NO_IMAGE);
        // });

        lazySizes.init();
    }

    onLazyLoaded(e) { // TODO: v.fastov - optimize to prevent possible extra calls
        // if components have animation for appear then images inside have attribute data-cmp-has-animation
        if (e.target.hasAttribute('data-cmp-has-animation')) {
            const $blockScrollMoveUp = $(e.target).closest(SELECTORS.blockScrollMoveUp);

            if ($blockScrollMoveUp.length) {
                $blockScrollMoveUp.addClass(lazySizesConfig.loadedClass);

                this.processBlockScrollMoveUp();
            }
        }
    }

    trackPopups() {
        this.$el.find(SELECTORS.component).each((index, element) => {
            const cmp = $(element).data('cmp');

            // eslint-disable-next-line no-bitwise
            if (~POPUPS.indexOf(cmp)) {
                this.popupsToTrack.push(cmp);
            }
        });

        if (this.popupsToTrack.length) {
            this.emitter.emit('popupNotify');
        }
    }

    onPopupDone(cmp) {
        if (this.popupsToTrack.length) {
            const index = this.popupsToTrack.indexOf(cmp);

            // eslint-disable-next-line no-bitwise
            if (~index) {
                this.popupsToTrack.splice(index, 1);

                if (!this.popupsToTrack.length) {
                    this.$el.addClass(STATE_CLASSES.noPopups);
                }
            }
        }
    }

    processBlockScrollMoveUp() {
        if (this.scrollMoveUpBlocks.length) {
            setTimeout(() => {
                for (let i = 0, l = this.scrollMoveUpBlocks.length; i < l; i++) {
                    const $element = $(this.scrollMoveUpBlocks[i]);

                    if ($element.length && isInViewport($element)) {
                        $element.addClass(lazySizesConfig.loadedClass);
                        $element.addClass(STATE_CLASSES.scrollShow);

                        this.scrollMoveUpBlocks.splice(i, 1);
                    }
                }
            });
        }
    }

    trackLogout() {
        const data = {
            eventCategory: 'account',
            eventAction: 'logout',
            eventLabel: '',
            ga4eventname: 'account_logout'
        };

        this.emitter.emit('gtmEvent', data);
    }

    // eslint-disable-next-line class-methods-use-this
    accessibleClick(el, ev) {
        if (ev.keyCode === 13) {
            el.click();
        }
    }

    trackGuestCheckout(el) {
        const $el = $(el),
            gtmData = $el.data('gtmCheckout');

        if (gtmData) {
            this.emitter.emit('gtmEcommerceEvent', 'eeCheckout', gtmData);
        }
    }

    trackFooterLinkClick(el) {
        const $el = $(el),
            $footerContent = $el.closest(SELECTORS.footerContent);

        const data = {
            eventCategory: 'footer',
            eventAction: $footerContent.length ? $footerContent.find(SELECTORS.footerSectionTitle).text() : '',
            eventLabel: $el.text(),
            ga4eventname: 'footer_click'
        };

        this.emitter.emit('gtmEvent', data);
    }

    trackTeaserImpression(event, $teaser) {
        const promotions = [];

        if (!$teaser) {
            $teaser = this.$teaser;
        }

        $teaser.each((idx, el) => {
            const $el = $(el);

            if ($el.data('teaser-tracked') || !isInViewport($el) || $el.is(':hidden')) {
                return;
            }

            if ($el.closest(SELECTORS.slide).attr('aria-hidden') === 'true') {
                return;
            }

            $el.data('teaser-tracked', '1');

            promotions.push(this.getTeaserData($el));
        });

        if (promotions.length) {
            const eventData = {
                'promoView': {
                    promotions
                }
            };

            this.emitter.emit('gtmEcommerceEvent', 'eeTeaserImpression', eventData);
        }
    }

    trackBannerClick(el) {
        const eventData = {
            'promoClick': {
                'promotions': [
                    this.getTeaserData($(el))
                ]
            }
        };

        this.emitter.emit('gtmEcommerceEvent', 'eeBannerClick', eventData);

    }

    // eslint-disable-next-line class-methods-use-this
    getTeaserData($el) {
        const $link = ($el.tagName === 'A') ? $el : $el.find('a');
        const URL = $link.length ? $link.attr('href') : $link.prevObject.attr('href');
        const data = {
            'id': $el.data('teaser-id') || '', // TeaserID
            'name': $el.data('teaser-name') || '', // Teaser Name
            'creative': URL || '', // Teaser URL
            'position': $el.data('teaser-position') || '' // Box ID
        };

        return data;
    }

    defineInteraction(isChange) {
        if (isChange) {
            refresh();
        }

        let mobile = isMobile();
        let tablet = isTablet();

        if (mobile || tablet) {
            this.$el.removeClass(STATE_CLASSES.notTouchable);
            this.$el.addClass(STATE_CLASSES.touchable);
        } else {
            this.$el.removeClass(STATE_CLASSES.touchable);
            this.$el.addClass(STATE_CLASSES.notTouchable);
        }
    }

    disableScroll() {
        let isMobileMenuOpened = $body.hasClass(STATE_CLASSES.mobileMenuOpened);

        if (!this.disabledScroll && !isMobileMenuOpened) {
            this.emitOnChild('stickyHeader', 'scrollIsDisabled');
            this.emitter.emit('scrollIsDisabled');
            this.offset = $(window).scrollTop();
            this.$el.css('top', `${-this.offset}px`).addClass(CLASSES.disableScroll);
            this.emitOnChild('stickyHeader', 'recalcStickyPosition', this.offset);
            this.disabledScroll = !this.disabledScroll;
        }
    }

    enableScroll() {
        let isMobileMenuOpened = $body.hasClass(STATE_CLASSES.mobileMenuOpened);

        let isScrollDisabled = this.disabledScroll || this.$el.hasClass(CLASSES.disableScroll);

        if (isScrollDisabled && !isMobileMenuOpened) {
            this.emitOnChild('stickyHeader', 'scrollIsEnabled');
            this.emitter.emit('scrollIsEnabled');
            this.$el.removeClass(CLASSES.disableScroll).css('top', '0');
            scrollTo(this.offset, {
                duration: 0
            });
            this.emitOnChild('stickyHeader', 'recalcStickyPosition');
            this.disabledScroll = !this.disabledScroll;
        }
    }
}
