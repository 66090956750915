import $ from 'jquery';
import { appendParamToURL } from 'client/utils/url';
import { isCurrentlySmall, isCurrentlyMedium } from 'client/utils/screendetector';
import prefs from 'sitePreferences';

var listItems = [];
var gtmssEnterPressed = false;
var gtmssSeeAllClicked = false;
var gtmssSuggestionClicked = false;

const SELECTORS = {
    'SUGGESTION_BRAND': '.b-suggestions-brands-list > a',
    'SUGGESTION_CATEGORIES': '.b-suggestions-categories-list a',
    'SUGGESTION_PRODUCTS': '.b-suggestions-products-list > a',
    'SUGGESTION_LIST': '.js-suggestions-list-item',
    'SUGGESTION_SHOW_ALL': '.js-suggestions-show-all',
    'SEARCH_INPUT': '.js-search-input'
};

export function fireSearchSuggestionTag(emitter) {

    if (listItems.length) {
        const eventLabel = listItems.join(',');

        listItems = [];

        const eventData = {
            'eventCategory': 'search',
            'eventAction': 'search-suggestion-layer/open',
            'eventLabel': eventLabel,
            'eventNonInteraction': 'true',
            'ga4eventname': 'search_layer'
        };

        emitter.emit('gtmEvent', eventData);
    }
}

export function trackSearchSuggestion($html) {
    listItems = [];

    let isMobile = isCurrentlySmall() || isCurrentlyMedium();
    let expandTracking = prefs.isSearchImprovementEnabled ? prefs.isSearchImprovementEnabled : !isMobile;

    if (expandTracking && $html.find(SELECTORS.SUGGESTION_BRAND).length > 0) {
        listItems.push('brand');
    }

    if (expandTracking && $html.find(SELECTORS.SUGGESTION_CATEGORIES).length > 0) {
        listItems.push('category');
    }

    if ($html.find(SELECTORS.SUGGESTION_PRODUCTS).length > 0) {
        listItems.push('product');
    }
}

export function trackSuggestionClicks($html, emitter) {
    var $lastListHtml = $html;

    $html.find(SELECTORS.SUGGESTION_LIST).on('click', function(event) {
        event.preventDefault();
        if (!gtmssSuggestionClicked) {
            var href = $(this).attr('href');
            var clickType = $(this).attr('data-attr-type');

            trackSearchSuggestion($lastListHtml);
            fireSearchSuggestionTag(emitter);

            const eventData = {
                'eventCategory': 'search',
                'eventAction': 'search-suggestion-layer/click-suggestion',
                'eventLabel': clickType,
                'eventNonInteraction': 'false',
                'ga4eventname': 'search_layer',
                'eventCallback': function() {
                    window.location = href;
                }
            };

            emitter.emit('gtmEvent', eventData);

            gtmssSuggestionClicked = true;

            if (!window.dataLayerInited) {
                window.location = href;
            }
        }

        return false;
    });
}

export function trackSuggestionGoToResult($html, emitter) {
    $html.find(SELECTORS.SUGGESTION_SHOW_ALL).on('click', function(event) {
        event.preventDefault();

        if (!gtmssSeeAllClicked) {
            trackSearchSuggestion($html);
            fireSearchSuggestionTag(emitter);

            var href = appendParamToURL($(this).attr('data-search-url'), 'q', $(SELECTORS.SEARCH_INPUT).val());

            const eventData = {
                'eventCategory': 'search',
                'eventAction': 'search-suggestion-layer/see-all-results',
                'eventLabel': 'see-all-results-button',
                'eventNonInteraction': 'false',
                'ga4eventname': 'search_layer',
                'eventCallback': function() {
                    window.location = href;
                }
            };

            emitter.emit('gtmEvent', eventData);

            gtmssSeeAllClicked = true;

            if (!window.dataLayerInited) {
                window.location = href;
            }
        }

        return false;
    });
}

export function trackSuggestionPressEnter(emitter) {
    if (gtmssEnterPressed) {
        return;
    }
    const eventData = {
        'eventCategory': 'search',
        'eventAction': 'search-suggestion-layer/see-all-results',
        'eventLabel': 'enter',
        'eventNonInteraction': 'false',
        'ga4eventname': 'search_layer'
    };

    emitter.emit('gtmEvent', eventData);

    gtmssEnterPressed = true;
}

export function trackSuggestionPressSearchIcon(emitter) {

    fireSearchSuggestionTag(emitter);

    const eventData = {
        'eventCategory': 'search',
        'eventAction': 'search-suggestion-layer/see-all-results',
        'eventLabel': 'search-icon',
        'eventNonInteraction': 'false',
        'ga4eventname': 'search_layer'
    };

    emitter.emit('gtmEvent', eventData);
}

export function handleSearchSuggestion($html, emitter) {
    trackSearchSuggestion($html, emitter);
    trackSuggestionClicks($html, emitter);
    trackSuggestionGoToResult($html, emitter);
}
