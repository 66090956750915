import Carousel from 'client/components/Carousel';
import prefs from 'sitePreferences';
import $ from 'jquery';

const DEFAULT_CONFIG = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    autoplay: false,
    adaptiveHeight: true,
    responsive: [{
        breakpoint: prefs.breakpointMedium + 1,
        settings: {
            arrows: false,
            draggable: false
        }
    }]
};

export default class HeroCarousel extends Carousel {
    init() {
        this.config = Object.assign({}, DEFAULT_CONFIG, this.config, this.config.assetConfig);

        delete this.config.assetConfig;
        super.init();

        this.$el.on('setPosition', this.recalculateHeight.bind(this));
        this.$el.on('beforeChange', this.recalculateHeight.bind(this));
    }

    getCurrentSlide(event, slick, currentSlide, nextSlide) {
        return {
            current: $(slick.$slides.get(currentSlide)),
            next: $(slick.$slides.get(nextSlide))
        };
    }

    recalculateHeight(event, slick, currentSlide, nextSlide) {
        let slides,
            height,
            dotsHeightSettings = 20; // margin bottom

        if (currentSlide) {
            slides = this.getCurrentSlide(event, slick, currentSlide, nextSlide);
            height = slides.next && Math.round(slides.next.find('img').height());
        } else {
            slides = this.getCurrentSlide(event, slick, 0, 1);
            height = slides.current && Math.round(slides.current.find('img').height());
        }

        if (slick.$dots) {
            slick.$dots.css('top', height - dotsHeightSettings - slick.$dots.height());
        }

        if (slick.$nextArrow) {
            slick.$nextArrow.css('top', height / 2);
        }

        if (slick.$prevArrow) {
            slick.$prevArrow.css('top', height / 2);
        }
    }
}
