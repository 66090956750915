import BaseAddress from 'client/components/account/addressBook/Address';
import { CLASSES, ADDRESS_TYPES } from 'client/utils/globals';

const SELECTORS = {
    'US': '.js-state-field-US'
};

export default class Address extends BaseAddress {
    init () {
        super.init();

        let event = `checkout.${this.config.isBilling ? 'billing' : 'shipping'}.country.changed`;

        this.isActive = false;

        if (this.config.isBilling) {
            this.emitter.addListener(event, this.onBillingCountryChanged.bind(this));
        } else {
            this.emitter.addListener(event, this.onCountryChanged.bind(this));
        }
    }

    onCountryChanged() {
        if (!this.isActive) {
            return;
        }

        this.onEdit(this.getCountryCode('shipping'));
    }

    onEdit(countryCode = null) {
        this.emitter.emit('addressbook.address.edit.start', {
            addressId: this.config.id,
            methodId: this.config.methodId,
            isBilling: this.config.isBilling,
            callback: response => {
                this.replaceHtml(response, this.$containers.editForm, {
                    onAfterInit: () => {
                        this.isActive = true;
                        this.$containers.address.addClass(CLASSES.addressUnderEdit);
                        this.$containers.edit.removeClass(CLASSES.hide);

                        if (this.config.isBilling) {
                            this.$states = { US: this.$el.find(SELECTORS.US) };

                            this.emitter.emit('globalCustomSelect.billing-stateCode-US-select.enable');

                            this.onBillingCountryChanged(this.getCountryCode('billing'));
                        } else {
                            this.validatePostalCode(ADDRESS_TYPES.shipping);
                        }
                    }
                });
            },
            section: this,
            countryCode: countryCode
        });

        this.$radioButton.prop('checked', true);
    }

    getCountryCode(type) {
        let formCmp = this.$containers.editForm.find('form').data('cmp-instance');

        if (formCmp) {
            let formData = formCmp.getFields(type);

            if (formData && formData.countryCode) {
                return formData.countryCode;
            }
        }

        return null;
    }

    onBillingCountryChanged (country) {
        if (!this.isActive) {
            return;
        }

        switch (country) {
            case 'US':
                this.$states.US.removeClass(CLASSES.hide).find('select').removeAttr('disabled');
                this.emitter.emit('globalCustomSelect.billing-stateCode-US-select.enable');
                break;
            default:
                this.$states.US.addClass(CLASSES.hide).find('select').attr('disabled', 'disabled');
                break;
        }

        this.validatePostalCode(ADDRESS_TYPES.billing);
    }
}
