import Component from 'client/core/Component';
import Handlebars from 'handlebars';
import handlebarsHelper from 'client/utils/handlebarsHelper';
import { CLASSES } from 'client/utils/globals';
import $ from 'jquery';
import ajax from 'client/utils/ajax';
import { appendParamToURL } from 'client/utils/url';
import { isInViewport } from 'client/utils/common';
import { isSmall, isMedium } from 'client/utils/screendetector';
import { debounce } from 'lodash';
import { sendEventToMobileApp } from 'client/utils/react';

const SELECTORS = {
    'LINE_ITEMS': '.js-cart-line-items',
    'CART_QTY': '.js-cart-qty',
    'CONTAINER': '.js-cart-container',
    'CHECKOUT_BTN': '.js-checkout-btn',
    'ERRORS': '.js-cart-errors',
    'LINE_WRAPPER': '.js-cart-item-wrapper',
    'LIST_LINES_WRAPPER': '.js-cart-items-list-wrapper',
    'EMPTY_CART': '.js-empty-cart',
    'CART_WITH_ITEMS': '.js-cart-with-items',
    'CART_ASIDE': '.js-cart-aside',
    'CART_RECOMMENDATIONS_WRAPPER': '.js-cart-recommendations-wrapper',
    'CART_RECOMMENDATIONS': '.js-cart-recommendations',
    'CART_WRAPPER': '.js-cart-wrapper',
    'AVAILABILITY_INFO': '.js-availability-info',
    'CLOSE_AVAILABILITY_INFO': '.js-close-availability-info'
};

const TEMPLATES = {
    'LINE_ITEMS': '#cartLineItemsTemplate',
    'ERRORS': '#cartErrorTemplate'
};

const STATE_CLASSES = {
    'RECOMMENDATIONS_INITIALIZED': 'h-recommendstions-initialized'
};

const SESSION_AVAILABILITY_CLOSED = 'cart.availability.closed';

export default class CartWrapper extends Component {
    init() {
        this.editModal = {
            active: false,
            quantity: null,
            uuid: null
        };

        handlebarsHelper(Handlebars);

        ajax.registerPrefilter((o) => this.ajaxPrefilter(o));

        this.templates = {
            lineItems: Handlebars.compile(this.$el.find(TEMPLATES.LINE_ITEMS).html()),
            errors: Handlebars.compile(this.$el.find(TEMPLATES.ERRORS).html())
        };

        this.containers = {
            $emptyCart: this.$el.find(SELECTORS.EMPTY_CART),
            $cartWithItems: this.$el.find(SELECTORS.CART_WITH_ITEMS),
            $cartAside: this.$el.find(SELECTORS.CART_ASIDE),
            $lineItems: this.$el.find(SELECTORS.LINE_ITEMS),
            $cartQty: this.$el.find(SELECTORS.CART_QTY),
            $cart: this.$el.find(SELECTORS.CONTAINER),
            $checkoutBtn: this.$el.find(SELECTORS.CHECKOUT_BTN),
            $errors: this.$el.find(SELECTORS.ERRORS),
            $availabilityInfo: this.$el.find(SELECTORS.AVAILABILITY_INFO)
        };

        this.calculateLineItemsHeight();

        this.emitter.addListener('cart.updated', (r) => this.onCartUpdated(r));
        this.emitter.addListener('modal.closed', (r) => this.onEditModalClose(r));
        this.emitter.addListener('modal.open', (options) => this.onEditModalOpen(options));

        this.bindEvent('click', SELECTORS.CHECKOUT_BTN, (el, ev) => this.onCheckout(el, ev));
        this.bindEvent('click', SELECTORS.CLOSE_AVAILABILITY_INFO, () => this.closeAvailabilityInfo());

        $(window).on('resize', debounce(() => {
            this.calculateLineItemsHeight();
        }, 500));

        if (this.gtmEnabled) {
            if (document.readyState === 'complete') {
                this.trackProductImpression();
            } else {
                $(window).on('load', this.trackProductImpression.bind(this));
            }

            this.emitter.addListener('orientationChange', this.trackProductImpression.bind(this));
            this.emitter.addListener('resize', this.trackProductImpression.bind(this));
            this.emitter.addListener('scroll', this.trackProductImpression.bind(this));
        }

        if (this.isAvailabilityClosed()) {
            this.containers.$availabilityInfo.addClass(CLASSES.hide);
        }
    }

    calculateLineItemsHeight(isCartEmpty) {
        if (this.containers.$cartAside && this.containers.$cartAside.children('.b-content-asset').length) {
            if (isSmall() || isMedium()) {
                const infoAssetHeight = this.containers.$cartAside.children('.b-content-asset').children().first().outerHeight(); // eslint-disable-line max-len

                this.$el.css('padding-bottom', infoAssetHeight);
            } else {
                const liQuntity = this.$el.find(SELECTORS.LINE_WRAPPER).length;

                if (liQuntity > 0 && liQuntity <= 2 && !isCartEmpty) {
                    const listLinesWrapperHeight = this.$el.find(SELECTORS.LIST_LINES_WRAPPER).height();

                    this.$el.addClass(CLASSES.collapsed);
                    this.$el.find(SELECTORS.CART_RECOMMENDATIONS_WRAPPER).css('top', listLinesWrapperHeight);

                } else {
                    this.$el.removeClass(CLASSES.collapsed);
                    this.$el.find(SELECTORS.CART_RECOMMENDATIONS_WRAPPER).css('top', 0);
                }

                this.emitter.emit('slick.reinit');
            }
        }
    }

    getProductData($el) {
        const product = $el.data('gtm');

        if (product) {
            const eventData = Object.assign({ list: `${this.gtmPageType}/Cart` }, product);

            return eventData;
        }

        return null;
    }

    closeAvailabilityInfo() {
        this.containers.$availabilityInfo.addClass(CLASSES.hide);
        sessionStorage.setItem(SESSION_AVAILABILITY_CLOSED, 'true');
    }

    trackProductImpression() {
        const $productTile = this.$el.find(SELECTORS.LINE_WRAPPER);

        if (!$productTile.length) {
            return;
        }

        const impressions = [];

        $productTile.each((idx, el) => {
            const $el = $(el);

            if ($el.data('tracked') || !isInViewport($el)) {
                return;
            }

            const data = this.getProductData($el);

            if (data) {
                const index = Number($el.data('index'));

                impressions.push(Object.assign({ position: (index + 1) || 1 }, data));
            }

            $el.data('tracked', '1');
        });

        if (impressions.length) {
            const eventData = Object.assign(
                { currencyCode: window.SitePreferences.currencyCode },
                { impressions: impressions }
            );

            this.emitter.emit('gtmEcommerceEvent', 'eeProductImpression', eventData);
        }
    }

    onEditModalOpen(options = {}) {
        if (options.params && options.params.editView) {
            this.editModal.active = true;
            this.editModal.quantity = options.params.selectedQuantity;
            this.editModal.uuid = options.params.id;
        }
    }

    onEditModalClose(response) {
        if (response && response.id === this.editModal.uuid) {
            this.editModal.uuid = null;
            this.editModal.active = false;
            this.emitter.emit('cart.updated', response.params);
        }
    }

    onCartUpdated(response) {
        if (response.numItems > 0) {
            this.showCartWithItems(response);
        } else {
            this.showEmptyCart(this.calculateLineItemsHeight);
        }
    }

    updateLineItems(items) {
        let container = $('<div></div>');
        let compiled = this.templates.lineItems(items);

        container.append(compiled);

        this.replaceHtml(container.html(), this.containers.$lineItems, {}, this.calculateLineItemsHeight.bind(this));
    }

    updateCartQty(basket) {
        this.containers.$cartQty.text(basket.resources.numberOfItems);
    }

    updateCheckoutBtn(basket) {
        if (basket.valid.error) {
            this.containers.$checkoutBtn.data('disabled', true);
            this.containers.$checkoutBtn.addClass(CLASSES.disabled);
        } else {
            this.containers.$checkoutBtn.data('disabled', false);
            this.containers.$checkoutBtn.removeClass(CLASSES.disabled);
        }
    }

    onCheckout(el, ev) {
        let stickyButtonCmp = this.getNestedComponentById('stickyButton');
        let gtmData = {};
        let total = 0;
        let coupon = '';

        if (stickyButtonCmp && stickyButtonCmp.config) {
            total = stickyButtonCmp.config.total || 0;
            gtmData = stickyButtonCmp.config.gtm || {};
            coupon = stickyButtonCmp.config.coupon || '';
        }

        this.emitter.emit('gtmEcommerceEvent', 'gtmEvent', gtmData, {
            'eventCategory': 'cart',
            'eventAction': 'begin checkout',
            'eventLabel': 'cart page',
            'eventNonInteraction': 'true',
            'ga4eventname': 'begin_checkout',
            'value': total,
            'coupon': coupon
        });

        if (this.containers.$checkoutBtn.data('disabled')) {
            ev.preventDefault();
            return false;
        }

        sendEventToMobileApp({
            type: 'checkout.open'
        }, true);

        return true;
    }

    updateCartError(basket) {
        this.containers.$errors.empty();

        if (basket.valid.error) {
            this.containers.$errors.html(this.templates.errors(basket));
        }
    }

    showCartWithItems(response) {
        this.containers.$emptyCart.addClass(CLASSES.hide);
        this.containers.$cartWithItems.removeClass(CLASSES.hide);

        this.updateLineItems(response);
        this.updateCartQty(response);
        this.updateCheckoutBtn(response);
        this.updateCartError(response);
    }

    showEmptyCart(calculateLineItemsHeightCb) {
        this.containers.$cartWithItems.addClass(CLASSES.hide);
        this.containers.$emptyCart.removeClass(CLASSES.hide);
        calculateLineItemsHeightCb.call(this, true);
    }

    ajaxPrefilter(options) {
        if (this.editModal.active) {
            let url = options.url;

            url = appendParamToURL(url, 'quickView', true);
            url = appendParamToURL(url, 'editView', true);
            url = appendParamToURL(url, 'selectedQuantity', this.editModal.quantity);
            url = appendParamToURL(url, 'selectedUUID', this.editModal.uuid);

            options.url = url;
        }
    }

    static addClassEinstentCarousel() {
        $(SELECTORS.CART_WRAPPER).addClass(STATE_CLASSES.RECOMMENDATIONS_INITIALIZED);
    }

    // eslint-disable-next-line class-methods-use-this
    isAvailabilityClosed() {
        const isClosed = sessionStorage.getItem(SESSION_AVAILABILITY_CLOSED);

        return !!isClosed;
    }

}
