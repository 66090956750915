import Component from 'client/core/Component';

import $ from 'jquery';
import ajax from 'client/utils/ajax';
import { CLASSES } from 'client/utils/globals';
import { appendParamsToURL } from 'client/utils/url';
import { scrollTo } from 'client/utils/common';
import { isManageGoogleMapByConsent } from 'sitePreferences';

const SELECTORS = {
    mapCanvas: '.js-map-canvas',
    detectLocationBtn: '.js-detect-location-button',
    listResults: '.js-store-locator-list-results',
    form: '.js-store-locator-search-form',
    postalCode: '.js-store-locator-postal-code',
    radius: '.js-store-locator-radius',
    mapViewResults: '.js-store-locator-map-view-wrapper',
    buttonsWrapper: '.js-store-locator-tab-buttons-wrapper',
    storeDetailSection: '.js-store-details-section',
    consentBlock: '.js-tooltip',
    consentApplyBtn: '.js-tooltip-apply-btn',
    loadError: '.js-load-error'
};

const LIST_SELECTORS = {
    store: '.js-store-locator-store-details',
    viewOnMap: '.js-store-locator-store-map'
};

// eslint-disable-next-line no-undef
let Google = typeof google === 'undefined' ? null : google;

export default class StoreLocatorComponent extends Component {
    resetMap() {
        this.bounds = {};

        while (this.listeners.length) {
            let listener = this.listeners.pop();

            listener.remove();
        }

        Object.keys(this.markers).forEach((key) => {
            let marker = this.markers[key];

            marker.setMap(null);
            marker = null;
        });

        this.markers = {};
    }

    getMapDiv() {
        let mapdiv = this.$elements.mapCanvas.attr('data-locations');

        try {
            mapdiv = JSON.parse(mapdiv);
        } catch (e) {
            console.error('JSON parse error');

            mapdiv = {};
        }

        return mapdiv;
    }

    /**
     * Uses google maps api to render a map
     */
    renderMap(coords = null) {
        const $els = this.$elements;

        let infoWindow = new Google.maps.InfoWindow({
            maxWidth: 360
        });

        const latLng = coords ? new Google.maps.LatLng(coords) : null;

        // init map
        if (this.map) {
            this.resetMap();

            latLng && this.map.setCenter(latLng);
        } else {
            let mapOptions = {
                scrollwheel: false,
                zoom: 7,
                minZoom: 5,
                maxZoom: 19
            };

            if (latLng) {
                mapOptions.center = latLng;
            }

            this.map = new Google.maps.Map($els.mapCanvas[0], mapOptions);
        }

        let mapDiv = this.getMapDiv();

        this.bounds = new Google.maps.LatLngBounds();

        const markerIcon = this.$elements.mapCanvas.attr('data-icon');

        // Customized google map marker icon with svg format
        let markerImg = {
            url: markerIcon,
            origin: new Google.maps.Point(0, 0),
            anchor: new Google.maps.Point(17, 46),
            scaledSize: new Google.maps.Size(45, 46)
        };

        Object.keys(mapDiv).forEach((key) => {
            let item = mapDiv[key];
            let storeLocation = new Google.maps.LatLng(item.latitude, item.longitude);
            let marker = new Google.maps.Marker({
                position: storeLocation,
                map: this.map,
                icon: markerImg
            });

            if (item.infoWindowHtml) {
                let listener = marker.addListener('click', () => {
                    infoWindow.setOptions({
                        content: item.infoWindowHtml
                    });

                    infoWindow.open(this.map, marker);
                });

                this.listeners.push(listener);
            }

            this.markers[item.id] = marker;

            // Create a minimum bound based on a set of storeLocations
            this.bounds.extend(marker.position);
        });

        // Fit the all the store marks in the center of a minimum bounds when any store has been found.
        if (mapDiv && mapDiv.length !== 0) {
            this.map.fitBounds(this.bounds);
        }

        this.mapViewUpdated = true;
    }

    /**
     * Renders the results of the search and updates the map
     * @param {Object} data - Response from the server
     */
    updateStoresResults(data) {
        var $els = this.$elements;

        if (data.storesResultsHtml) {
            $els.listResults.html(data.storesResultsHtml);
        }

        $els.mapCanvas.attr('data-locations', data.locations);

        if (this.isMapView()) {
            this.renderMap();
        } else {
            this.mapViewUpdated = false;
        }

        this.emitter.emit('storeLocator.data.updated', data);
    }

    getRadius() {
        return this.$elements.radius.val();
    }

    validate() {
        const postalCode = this.$elements.postalCode.data('cmp-instance');

        return (postalCode && (typeof postalCode.validate === 'function') && postalCode.validate());
    }

    /**
     * Search for stores with new zip code
     *
     * @returns {boolean} false to prevent default event
     */
    onSearch() {
        const $els = this.$elements,
            $form = $els.form;

        let radius = this.getRadius();
        let url = $form.attr('action');
        let urlParams = { radius: radius };

        if (this.validate()) {
            url = appendParamsToURL(url, urlParams);

            const doSearch = (lat, long) => {
                ajax.getJson({
                    url: url,
                    data: {
                        postalCode: this.location.postalCode || this.components.postalCode.getValue(),
                        area: this.components.postalCode.getValue() || this.location.area,
                        lat: lat,
                        long: long,
                        radius: this.getRadius()
                    }
                })
                    .then((resp) => {
                        this.updateStoresResults(resp);
                    });
            };

            if (this.location.lat && this.location.long) {
                doSearch(this.location.lat, this.location.long);
            } else {
                const geocoder = new Google.maps.Geocoder();

                let geocodeRequest = {
                    address: this.components.postalCode.getValue()
                };

                if (this.countryCode) {
                    geocodeRequest.componentRestrictions = { country: this.countryCode };
                }

                geocoder.geocode(geocodeRequest, (results, status) => {
                    if (status === Google.maps.GeocoderStatus.OK) {
                        let location = results[0].geometry.location;

                        doSearch(location.lat(), location.lng());
                    } else {
                        doSearch(this.location.lat, this.location.long);
                    }
                });
            }
        }

        return false;
    }

    extractPostalCode(result) {
        if ('address_components' in result) {
            for (let component of result.address_components) {
                if (~component.types.indexOf('postal_code')) {
                    return component.long_name;
                }
            }
        }

        return null;
    }

    extractLocation(result) {
        if ('geometry' in result) {
            const geometry = result.geometry;

            if ('location' in geometry) {
                const location = geometry.location;

                return {
                    lat: location.lat(),
                    long: location.lng()
                };
            }
        }

        return {
            lat: null,
            long: null
        };
    }

    extractArea(result) {
        if ('address_components' in result) {
            for (let component of result.address_components) {
                if (~component.types.indexOf('administrative_area_level_2') ||
                ~component.types.indexOf('administrative_area_level_1')
                ) {
                    return component.long_name;
                }
            }
        }

        return null;
    }

    setPostalCode(postalCode) {
        this.components.postalCode.setValue(postalCode || '');
    }

    getCurrentPosition(success, error) {
        setTimeout(() => {
            navigator.geolocation.getCurrentPosition(success, error);
        });
    }

    /**
     * Search for stores with detected location
     *
     * @returns {boolean} false to prevent default event
     */
    onDetectLocation() {
        if (!navigator.geolocation) {
            return false;
        }

        const doDetectLocation = () => {
            this.getCurrentPosition((position) => {
                const geocoder = new Google.maps.Geocoder(),
                    latlng = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };

                geocoder.geocode({
                    location: latlng
                }, (results, status) => {
                    let postalCode = null;

                    if (status === 'OK') {
                        if (results[0]) {
                            postalCode = this.extractPostalCode(results[0]);
                        }
                    }

                    this.setPostalCode(postalCode);
                });
            }, () => {
                window.alert(this.$elements.detectLocationBtn.data('msg-geolocation-disabled'));
            });
        };

        if (navigator.permissions && navigator.permissions.query) {
            navigator.permissions.query({ name: 'geolocation' })
                .then((permissionStatus) => {
                    if (permissionStatus.state === 'denied') {
                        window.alert(this.$elements.detectLocationBtn.data('msg-geolocation-disabled'));
                    } else {
                        doDetectLocation();
                    }
                });
        } else {
            doDetectLocation();
        }

        return false;
    }

    getStartCoords(callback) {
        const setCountryCoords = (done = null) => {
            let coords = null;

            if (this.countryName) {
                const geocoder = new Google.maps.Geocoder();

                geocoder.geocode({ address: this.countryName }, (results, status) => {
                    if (status === Google.maps.GeocoderStatus.OK) {
                        let location = results[0].geometry.location;

                        coords = {
                            lat: location.lat(),
                            lng: location.lng()
                        };
                    }

                    callback && callback(coords);

                    done && done();
                });
            } else {
                callback && callback(coords);

                done && done();
            }
        };

        const setCurrentCoords = () => {
            this.getCurrentPosition((position) => {
                if (position && position.coords) {
                    const coords = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };

                    callback && callback(coords);
                } else {
                    setCountryCoords();
                }
            });
        };

        if (navigator.geolocation) {
            if (navigator.permissions && navigator.permissions.query) {
                navigator.permissions.query({ name: 'geolocation' })
                    .then((permissionStatus) => {
                        if (permissionStatus.state === 'granted') {
                            setCurrentCoords();
                        } else {
                            setCountryCoords(() => {
                                if (permissionStatus.state !== 'denied') {
                                    setCurrentCoords();
                                }
                            });
                        }
                    });
            } else {
                setCountryCoords(() => {
                    setCurrentCoords();
                });
            }
        } else {
            setCountryCoords();
        }
    }

    isMapView() {
        return !this.$elements.mapViewResults.hasClass(CLASSES.hide);
    }

    onViewUpdated() {
        if (this.isMapView() && !this.mapViewUpdated) {
            this.renderMap();
        }
    }

    processAutocompleteResult() {
        const place = this.autocomplete.getPlace();

        if (place) {
            this.location.postalCode = this.extractPostalCode(place);
            this.location.area = this.extractArea(place);

            const location = this.extractLocation(place);

            this.location.lat = location.lat;
            this.location.long = location.long;
        }
    }

    initAutocomplete() {
        if (this.components.postalCode) {
            this.autocomplete = new Google.maps.places.Autocomplete(
                this.components.postalCode.getInputField()[0],
                {
                    fields: ['address_component', 'geometry'],
                    strictBounds: true,
                    types: ['geocode']
                }
            );

            this.autocomplete.setFields(['address_component']);

            if (this.countryCode) {
                this.autocomplete.setComponentRestrictions({ country: this.countryCode });
            }

            this.autocomplete.addListener('place_changed', this.processAutocompleteResult.bind(this));
        }
    }

    viewOnMap(el, event) {
        event.preventDefault();

        const storeID = $(el).closest(LIST_SELECTORS.store).data('store-id'),
            marker = this.markers[storeID];

        this.map.setCenter(marker.getPosition());

        this.emitter.emit('storeLocator.setView', 'map');

        Google.maps.event.trigger(marker, 'click');

        scrollTo(this.$buttonsWrapper);

        return false;
    }

    onPostalCodeInputChange() {
        this.location = {
            postalCode: null,
            lat: null,
            long: null
        };
    }

    loadMapScript() {
        return new Promise((resolve, reject) => {
            if (typeof google !== 'undefined') {
                this.proceedInit();
                resolve();
                return;
            }

            const script = document.createElement('script');

            script.src = this.$el.find(SELECTORS.mapCanvas).data('google-map-api-url');
            script.defer = true;

            // @ts-ignore
            window.initMap = () => {
                // eslint-disable-next-line no-undef
                Google = typeof google === 'undefined' ? null : google;
                this.proceedInit();
                return resolve();
            };

            script.onerror = () => {
                this.$el.find(SELECTORS.loadError).removeClass('h-hide');
                return reject('Error loading Google Maps API script');
            };

            document.head.appendChild(script);
        });
    }

    acceptConsentBtn() {
        this.$el.find(SELECTORS.consentBlock).addClass('h-hide');
        window.dispatchEvent(new CustomEvent('apply.consent.cookie', { detail: { consentStatus: true } }));
        this.loadMapScript();
    }

    proceedInit() {
        this.map = null;
        this.bounds = {};
        this.markers = {};
        this.listeners = [];
        this.mapViewUpdated = true;
        this.$buttonsWrapper = $(SELECTORS.buttonsWrapper);

        this.location = {
            postalCode: null,
            lat: null,
            long: null
        };

        this.countryCode = this.$el.data('country-code');
        this.countryName = this.$el.data('country-name');

        this.$elements = {};

        for (let key of Object.keys(SELECTORS)) {
            this.$elements[key] = this.$el.find(SELECTORS[key]);
        }

        this.components = {};

        if (this.$elements.postalCode.length) {
            this.components.postalCode = this.$elements.postalCode.data('cmp-instance');
            this.components.postalCode.inputField.on('change', this.onPostalCodeInputChange.bind(this));
            this.initAutocomplete();
        }

        if (this.$elements.mapCanvas.length && this.$elements.mapCanvas.data('has-google-api')) {
            this.getStartCoords((coords) => {
                this.renderMap(coords);
            });
        }

        this.bindEvent('click', SELECTORS.detectLocationBtn, this.onDetectLocation.bind(this));
        this.bindEvent('submit', SELECTORS.form, this.onSearch.bind(this));

        this.bindEvent('click', LIST_SELECTORS.viewOnMap, this.viewOnMap.bind(this));

        this.emitter.addListener('storeLocator.view.updated', this.onViewUpdated.bind(this));
    }

    init() {
        if (!window.consentStatus && isManageGoogleMapByConsent) {
            this.$el.find(SELECTORS.consentBlock).removeClass('h-hide');
            this.bindEvent('click', SELECTORS.consentApplyBtn, this.acceptConsentBtn);
        } else {
            this.loadMapScript();
        }
    }
}
