import InputText from 'client/components/forms/commonElements/InputText';

const SELECTORS = {
    counter: '.js-counter'
};

class InputTextarea extends InputText {
    init() {
        this.config.initialPlaceholder = true;
        super.init();

        const $this = this;

        this.$el.on('change keyup', 'textarea', e => {
            const $counter = $this.$el.find(SELECTORS.counter);

            $counter.html(`${e.target.value.length}/${e.target.maxLength}`);
        });
    }
}

export default InputTextarea;
