import ExpandBlock from 'client/components/ExpandBlock';
import { CLASSES } from 'client/utils/globals';

const SELECTORS = {
    expandBtn: '.js-expand-category',
    expandIcon: '.js-expand-icon',
    compressIcon: '.js-compress-icon',
    container: '.js-expand-container'
};

export default class ExpandCategory extends ExpandBlock {
    init() {
        super.init();

        this.expanded = this.config.expanded;

        this.$plusIcon = this.btnWrapper.children(SELECTORS.expandIcon).first();
        this.$minusIcon = this.btnWrapper.children(SELECTORS.compressIcon).first();
        this.$container = this.$el.children(SELECTORS.container).first();

        this.$el.find(SELECTORS.expandBtn).first().on('click', ev => {
            ev.stopImmediatePropagation();
            ev.preventDefault();

            if (this.expanded) {
                this.compress();
            } else {
                this.expand();
            }
        });
    }

    expand() {
        this.$container.removeClass(CLASSES.hide);
        this.changeButtonsState();
        this.expanded = true;
    }

    compress() {
        this.$container.addClass(CLASSES.hide);
        this.changeButtonsState();
        this.expanded = false;
    }

    changeButtonsState() {
        this.$plusIcon.toggleClass(CLASSES.hide);
        this.$minusIcon.toggleClass(CLASSES.hide);
    }
}
