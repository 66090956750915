import InputCheckbox from './commonElements/InputCheckbox';

export default class ShippingBillingSame extends InputCheckbox {
    init() {
        super.init();

        this.bindEvent('change', () => { this.emitter.emit('useshippingasbilling.changed'); });
    }


}
