import BaseAddressValidator from 'client/components/forms/AddressValidator';

export default class AddressValidator extends BaseAddressValidator {
    updateAddress (address) {
        for (let [name, cmpID] of this.fields) {
            let cmp = this.getNestedComponentById(cmpID);

            if (cmp && typeof cmp.setValue === 'function' && name in address && name !== 'countryCode') {
                cmp.setValue(address[name]);
            }
        }
    }
}
