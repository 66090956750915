import Component from 'client/core/Component';

const SELECTORS = {
    PAYMENT_METHOD_PREFIX: '#paymentMethod_'
};

const DEFAULT_CONFIG = {
    isLocked: false,
    lockClass: ''
};

export default class AbstractExpandedPaymentOptionTab extends Component {
    init () {
        this.config = Object.assign({}, DEFAULT_CONFIG, this.config);
        this._paymentMethodSelector = SELECTORS.PAYMENT_METHOD_PREFIX + this.config.pid;

        this.isLocked = this.config.isLocked;

        this.$paymentOption = this.$el.find(this._paymentMethodSelector);

        this.bindEvent('change', this._paymentMethodSelector, () => {
            this.toggleContent();
            this.emit('paymentmethod.selection.changed', this.$paymentOption.prop('change') ? this.config.pid : null);
        });

        this.emitter.addListener('step.payment.paymentmethod.restricted', paymentMethodIDs =>
            this.toggleRestrictions(paymentMethodIDs.indexOf(this.config.pid) > -1)
        );

        this.emitter.addListener('step.payment.paymentmethod.fraud.lock', (typeLock) => this.fraudLock(typeLock));
    }

    getDisplayValue() {
        return this.$paymentOption.filter(':checked').data('displayValue');
    }

    /**
     * @abstract
     */
    isSelected() {
        throw new Error('It should be implemented');
    }

    /**
     * @abstract
     */
    toggleContent() {
        throw new Error('It should be implemented');
    }

    toggleRestrictions (isLocked = false) {
        if (this.$el.data('fraudLocked')) {
            this.$el.addClass(this.config.lockClass);
            return false;
        }

        isLocked && this.$paymentOption.prop('checked', false);

        this.isLocked = isLocked;

        this.toggleContent();

        if (isLocked) {
            this.$el.addClass(this.config.lockClass);
        } else {
            this.$el.removeClass(this.config.lockClass);
        }
    }

    fraudLock (typeLock) {
        if (this.$el.data(typeLock)) {
            this.$el.addClass(this.config.lockClass);
            this.$el.data('fraudLocked', true);
        }
    }
}
