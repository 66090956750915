import Component from 'client/core/Component';
import { CLASSES } from 'client/utils/globals';
import $ from 'jquery';
import { debounce } from 'lodash';

const SELECTORS = {
    'INNER': '.js-dropdown-inner',
    'BTN': '.js-dropdown-button'
};

export default class RefinementDropdown extends Component {
    init() {
        this.$button = this.$el.find(SELECTORS.BTN);
        this.$inner = this.$el.find(SELECTORS.INNER);

        this.isOpen = false;

        this.bindEvent('click', SELECTORS.BTN, () => this.toggleState());
        this.bindEvent('click touchstart', (el, e) => {
            e.stopPropagation();
            // Close the custom select
            this.emitter.emit('custom.select.close');
        });
        // Close dropdown when filter applied
        this.emitter.addListener('refinement.close', (id) => this.onFilterClose({ attribute: id }));
        this.emitter.addListener('refinement.applyFilter', (data) => this.onFilterClose(data));
        this.emitter.addListener('refinement.dropdown.open', (id, topPos) => this.onDropdownOpen(id, topPos));
        this.emitter.addListener('refinement.dropdown.close', () => this.makeDefaultOpacity());

        $(window).on('resize', debounce(() => {
            this.onresizeChangeOpacity();
        }, 500));

        this.emitter.addListener('custom.select.open', () => this.closeDropdown());
        this.emitter.addListener('globalClick', () => this.closeDropdown());
    }

    trackDropDownOpen(filterName) {
        const data = {
            eventCategory: 'filter',
            eventAction: `${filterName} open`,
            eventLabel: '',
            ga4eventname: 'plp_filter'
        };

        this.emitter.emit('gtmEvent', data);
    }

    toggleState() {
        this.$el.toggleClass(CLASSES.dropdownOpened);

        this.isOpen = !this.isOpen;

        // Tell other dropdown components to hide
        if (this.isOpen) {
            if (this.gtmEnabled) {
                this.trackDropDownOpen(this.config.attr);
            }

            this.emitter.emit('refinement.dropdown.open', this.config.attr, this.$el.offset().top);
        } else {
            this.emitter.emit('refinement.dropdown.close');
        }
    }

    onFilterClose(data) {
        if (data.attribute === this.config.attr && this.isOpen) {
            this.closeDropdown();
            this.emitter.emit('refinement.dropdown.close');
        }
    }

    onDropdownOpen(id, topPos) {
        if (id !== this.config.attr && this.isOpen) {
            this.closeDropdown();
        }

        // Snipes: adding class to change color for filters below of opened
        this.makeHalfOpacity(topPos);
    }

    makeHalfOpacity(topPos) {
        if (this.$el.offset().top > topPos) {
            this.$el.addClass(CLASSES.placedBelowOpened);
        } else {
            this.makeDefaultOpacity();
        }
    }

    onresizeChangeOpacity() {
        if (this.isOpen) {
            this.emitter.emit('refinement.dropdown.open', this.config.attr, this.$el.offset().top);
        }
    }

    makeDefaultOpacity() {
        if (this.$el.hasClass(CLASSES.placedBelowOpened)) {
            this.$el.removeClass(CLASSES.placedBelowOpened);
        }
    }

    closeDropdown() {
        this.$el.removeClass(CLASSES.dropdownOpened);
        this.makeDefaultOpacity();
        this.isOpen = false;
    }
}
