export default class AddedToCart {
    constructor(data) {
        this.currency = data.cart.totals.subTotalPrice.currency;
        this.value = data.cart.totals.subTotalPrice.value;
        this.lineItem = {};
        this.setCartItems(data.cart, data.pliUUID);
    }

    setCartItems(cartObject, pliUUID) {
        this.cartItems = cartObject.items.map(function (lineItem) {
            var newLineItem = new this.LineItemModel(lineItem);

            if (lineItem.UUID === pliUUID) {
                this.lineItem = newLineItem;
            }

            return newLineItem;
        }, this);
    }

    LineItemModel(lineItem) {
        this.name = lineItem.productName || '';
        this.id = lineItem.id || '';
        this.brand = lineItem.brand || '';
        this.category = 'not-set-app';
        this.variant = lineItem.gtm.variant || '';
        this.quantity = lineItem.quantity || 0;
        this.price = lineItem.price ?
        {
            list: lineItem.price.list ? lineItem.price.list.value : lineItem.price.sales.value,
            sale: lineItem.price.sales.value
        } :
        {
            list: 0,
            sale: 0
        };
        this.priceTotal = lineItem.priceTotal ? lineItem.priceTotal.priceDecimal : 0;
    }
}
