import Input from 'client/core/Input';

export default class InputCheckbox extends Input {
    init() {
        super.init();

        this.$field.on('change', (e) => {
            this.$field.val(e.target.checked);
        });
    }

    getValue () {
        return this.$field.prop('checked');
    }

    setValue (flag) {
        this.$field.prop('checked', flag);
    }

    validate () {
        let isValid = true;

        if (this.config.required) {
            isValid = this.getValue();
        }

        isValid ? this.clearError() : this.setError(this.config.requiredtext);

        return isValid;
    }
}
