import InputText from 'client/components/forms/commonElements/InputText';

import {
    LIMIT_EXCEEDED
} from 'resources';

export default class CoinsInput extends InputText {
    validate () {
        this.setValue(+this.getValue(), true);

        let validateResult = super.validate();

        if (validateResult && !this.isLocked()) {
            const val = +this.getValue();

            if (this.config.disabled) {
                validateResult = false;
            } else if (
                !val ||
                isNaN(val) ||
                val === 0 ||
                ('minValue' in this.config && val < +this.config.minValue) ||
                ('maxValue' in this.config && val > +this.config.maxValue)
            ) {
                this.setError(LIMIT_EXCEEDED);

                validateResult = false;
            }
        }

        return validateResult;
    }

    isLocked () {
        return this.$field.prop('disabled') || this.$field.prop('readonly');
    }
}
