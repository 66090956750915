import InputText from 'client/components/forms/commonElements/InputText';
import { SHIPPING_VALIDATION_POST_NUMBER_INVALID } from 'resources';

export default class InputPostNumber extends InputText {
    async validate() {
        let value = this.getValue(),
            valid = false;

        value = value.split('');
        if (value.length < 10) {
            for (let i = value.length; i < 10; i++) {
                value.unshift('0');
            }
        }

        let calcTarget = (parseInt(value[8], 10) + parseInt(value[6], 10) +
            parseInt(value[4], 10) + parseInt(value[2], 10) + parseInt(value[0], 10)) * 4;

        calcTarget += (parseInt(value[7], 10) + parseInt(value[5], 10) +
            parseInt(value[3], 10) + parseInt(value[1], 10)) * 9;

        if (calcTarget % 10 === 0) {
            valid = parseInt(value[9], 10) === 0;
        } else {
            let nextModTen = calcTarget;

            for (let i = 0; i < 10; i++) {
                nextModTen += 1;
                if (nextModTen % 10 === 0) {
                    break;
                }
            }

            valid = parseInt(value[9], 10) === nextModTen - calcTarget;
        }

        if (valid) {
            return super.validate();
        } else {
            this.setError(SHIPPING_VALIDATION_POST_NUMBER_INVALID);

            return valid;
        }
    }
}
