import Component from 'client/core/Component';
import prefs from 'sitePreferences';
export default class CheckoutConfirmation extends Component {
    init () {
        window._trboq = window._trboq || [];

        if (typeof window._trboq !== 'undefined' && this.config.type) {
            switch (this.config.type) {
                case 'sale':
                    window._trboq.push(['sale', this.config.trboData]);
                    break;
            }
        }

        this.initOutsideTriggers();

        if (prefs.isFitAnalyticsEnabled) {
            window.fitAnalyticsData = window.fitAnalyticsData || {};
            var fitAnalyticsData = window.fitAnalyticsData;

            window.fitAnalyticsData = Object.assign({}, this.config.fitAnalyticsData, fitAnalyticsData);
        }
    }

    /**
     * This function list all shop thelook function and attach them
     * to the global scope in order to be execute by 3th parity script
     */
    initOutsideTriggers() {
        window.stlGoToPDP = (pid) => {
            this.stlGoToPDP(pid);
        };

        window.stlSwipeForAlt = (pid) => {
            this.stlSwipeForAlt(pid);
        };

        window.stlSeelectSize = (pid) => {
            this.stlSeelectSize(pid);
        };
    }

    /**
     * Triggered on STL outfit page when user clicks on the product to see more details
     * on the related pdp.
     * @param {string} pid
     */
    stlGoToPDP(pid) {
        const data = {
            eventCategory: 'shop-the-look',
            eventAction: 'go-to-pdp',
            eventLabel: pid,
            ga4eventname: 'shopthelook_interaction'
        };

        this.emitter.emit('gtmEvent', data);
    }

    /**
     * Triggered on STL outfit page when user swipes to
     * the left or right to see alternatives for the promoted product.
     * @param {string} pid
     */
    stlSwipeForAlt(pid) {
        const data = {
            eventCategory: 'shop-the-look',
            eventAction: 'swipe-for-alternatives',
            eventLabel: pid,
            ga4eventname: 'shopthelook_interaction'
        };

        this.emitter.emit('gtmEvent', data);
    }

    /**
     * Triggered when user swipes left/right on a plp product picture.
     * @param {string} pid
     */
    stlSeelectSize(pid) {
        const data = {
            eventCategory: 'shop-the-look',
            eventAction: 'select-size',
            eventLabel: pid,
            ga4eventname: 'shopthelook_interaction'
        };

        this.emitter.emit('gtmEvent', data);
    }

}
