import BaseAddEditForm from 'client/components/account/addressBook/AddEditForm';

const FIELDS = [
    'title',
    'firstName',
    'lastName',
    'address1',
    'address2',
    'city',
    'street',
    'suite',
    'countryCode',
    'postalCode',
    'phone',
    'postNumber',
    'packstationNumber',
    'postOfficeNumber'
];

export default class AddEditForm extends BaseAddEditForm {
    getFields(type) {
        let fields = {};

        FIELDS.forEach(key => {
            let cmpName = `${type}-${key}`;
            let cmp = this.getNestedComponentById(cmpName);

            if (cmp && typeof cmp.getValue === 'function') {
                fields[key] = cmp.getValue();
            }
        });

        return fields;
    }
}
