import Component from 'client/core/Component';
import { CLASSES } from 'client/utils/globals';
import $ from 'jquery';

const SELECTORS = {
    'ADD': '.js-new-address-btn',
    'CANCEL': '.js-address-add-cancel',
    'FORM': '.js-new-address-form-container',
    'CONTAINER': '.js-new-address-container',
    'SAVE': '.js-address-add-save',
    'INPUT': '.js-new-address-input',
    'ERROR': '.js-error-container'
};

export default class NewAddress extends Component {
    init () {
        this.$addButton = this.$el.find(SELECTORS.ADD);
        this.$addForm = this.$el.find(SELECTORS.FORM);
        this.$container = this.$el.find(SELECTORS.CONTAINER);
        this.$input = this.$container.find(SELECTORS.INPUT);

        this.bindEvent('click', SELECTORS.ADD, (el, e) => this.onAdd(e));
        this.bindEvent('click', SELECTORS.SAVE, (el, e) => this.onSave(e));
        this.bindEvent('click', SELECTORS.CANCEL, () => this.onCancel());

        this.emitter.addListener('step.shipping.method.changed', (data) => this.onMethodChanged(data.id));
    }

    async onSave(e) {
        if (e) {
            e.preventDefault();
        }

        let form = this.$addForm.find('form').data('cmp-instance');

        if (form.config.methodId === 'pick-up-station') {
            this.getNestedComponentById('pick-up-station-search').config.required = true;
        }
        if (form) {
            let isValid = await form.validate();

            if (isValid) {
                this.emitter.emit('addressbook.address.add.save', {
                    form: form.$el.serialize(),
                    methodId: this.selectedShippingMethod,
                    isBilling: this.config.isBilling,
                    callback: () => this.onCancel()
                });
            }
        }
    }

    onAdd(e, inSameContainer = false, emittedFromEvent = false) {
        if (e) {
            e.preventDefault();
        }

        if (emittedFromEvent && this.config.isBilling) {
            return;
        } else {
            this.$input.prop('checked', true);

            this.emitter.emit('addressbook.address.add.start', {
                callback: (r) => this.buildAddForm(r),
                methodId: this.selectedShippingMethod,
                isBilling: this.config.isBilling,
                section: this,
                sameContainer: inSameContainer
            });
        }
    }

    getErrorData(data) {
        const errorKeys = Object.keys(data);
        const errorData = errorKeys.map(key => `<li>${key}: ${data[key]}</li>`);
        const errorHtml = `<ul>${errorData.join('')}</ul>`;

        return errorHtml;
    }

    onResponse(response) {
        if (response.success) {
            if ('isActive' in this) {
                this.isActive = false;
            }
            this.onCancel();
        } else {
            const errorHtml = this.getErrorData(response.fields);

            $(SELECTORS.ERROR).empty().html(errorHtml);
        }
    }

    onCancel() {
        this.emitter.emit('addressbook.address.add.cancel');
        this.$addButton.removeClass(CLASSES.hide);
        this.$container.addClass(CLASSES.hide);
        $(SELECTORS.ERROR).empty();

        this.replaceHtml('', this.$addForm);
    }

    buildAddForm(response) {
        this.$addButton.addClass(CLASSES.hide);
        this.$container.removeClass(CLASSES.hide);

        this.replaceHtml(response, this.$addForm, {
            onAfterInit: () => {
                if (!this.selectedShippingMethod) {
                    let method = this.$addForm.find('form').data('method-id');

                    if (method) {
                        this.selectedShippingMethod = method;
                    }
                }
            }
        });

        $(SELECTORS.ERROR).empty();
    }

    onMethodChanged(id) {
        if (id) {
            this.selectedShippingMethod = id;
            this.onAdd(null, true, true);
        }
    }
}
