import $ from 'jquery';

const SPINNER_CLASSES = {
    'WRAPPER': 'b-spinner-wrapper',
    'LOADER': 'b-spinner-loader',
    'TEXT': 'b-spinner-text',
    'JS_WRAPPER': 'js-spinner-wrapper'
};

const SPINNER_SELECTOR = '.js-spinner-wrapper';

class Spinner {
    constructor() {
        if (!Spinner.instance) {
            Spinner.instance = this;

            this.activeSpinners = new Map(); // eslint-disable-line no-undef
        }

        return Spinner.instance;
    }

    start($target, section, text) {
        let spinner = this.activeSpinners.get(section);

        if (spinner) {
            return;
        }

        let $spinner = this.buildSpinner(text, $target);

        this.addClasses($target);
        $target.append($spinner);
        this.activeSpinners.set(section, {
            $target: $target,
            $spinner: $spinner
        });
    }

    stop(section, spinner = null) {
        if (!spinner) {
            spinner = this.activeSpinners.get(section);
        }

        if (spinner) {
            spinner.$spinner.remove();
            this.removeClasses(spinner.$target);
            this.activeSpinners.delete(section);
        }
    }

    getActiveSpinner(section) {
        return this.activeSpinners.get(section);
    }

    static StopAllSpinners() {
        $ && $.spinner && $.spinner.stopAll();
    }

    stopAll() {
        if (this.activeSpinners.size) {
            for (const [key] of this.activeSpinners) {
                this.stop(key);
            }
        }

        // Remove forgotten spinners
        $(document).find(SPINNER_SELECTOR).remove();
    }

    addClasses($target) {
        let data = $target.data();

        if (data.activeClass) {
            $target.addClass(data.activeClass);
        }

        if (data.parentActiveClass) {
            $target.parent().addClass(data.parentActiveClass);
        }
    }

    removeClasses($target) {
        let data = $target.data();

        if (data.activeClass) {
            $target.removeClass(data.activeClass);
        }

        if (data.parentActiveClass) {
            $target.parent().removeClass(data.parentActiveClass);
        }
    }

    buildSpinner(text, $target) {
        let data = $target.data(),
            $wrapper = $(`<div class="${SPINNER_CLASSES.WRAPPER} ${SPINNER_CLASSES.JS_WRAPPER}"></div>`),
            $loader = $(`<div class="${SPINNER_CLASSES.LOADER}">${data.spinnerSvg}</div>`);

        $wrapper.append($loader);

        if (text) {
            $wrapper.append($(`<div class="${SPINNER_CLASSES.TEXT}">${text}</div>`));
        }

        return $wrapper;
    }
}

const instance = new Spinner();

$.spinner = instance;

export default Spinner;
