import Component from 'client/core/Component';
import { CLASSES } from 'client/utils/globals';
import $ from 'jquery';

const SELECTORS = {
    'CONTAINER': {
        'ADDRESS': '.js-address-item',
        'EDIT': '.js-address-item-edit',
        'REMOVE': '.js-address-item-remove',
        'EDIT_FORM': '.js-address-edit-form-container',
        'RADIO': '.js-address-default-select'
    },
    'REMOVE': {
        'BTN': '.js-address-remove-button',
        'CONFIRM': '.js-remove-confirm',
        'CANCEL': '.js-remove-cancel'
    },
    'EDIT': {
        'BTN': '.js-address-edit-button',
        'SAVE': '.js-address-edit-save',
        'CANCEL': '.js-address-edit-cancel'
    }
};

export default class Address extends Component {
    init () {
        this.$containers = {
            address: this.$el.find(SELECTORS.CONTAINER.ADDRESS),
            remove: this.$el.find(SELECTORS.CONTAINER.REMOVE),
            edit: this.$el.find(SELECTORS.CONTAINER.EDIT),
            editForm: this.$el.find(SELECTORS.CONTAINER.EDIT_FORM)
        };

        this.$radioButton = this.$el.find(SELECTORS.CONTAINER.RADIO);

        // Remove Address functionality
        this.bindEvent('click', SELECTORS.REMOVE.BTN, () => this.onRemove());
        this.bindEvent('click', SELECTORS.REMOVE.CONFIRM, () => this.onRemoveConfirm());
        this.bindEvent('click', SELECTORS.REMOVE.CANCEL, () => this.onRemoveCancel());

        // Edit Address Functionality
        this.bindEvent('click', SELECTORS.EDIT.BTN, () => this.onEdit());
        this.bindEvent('click', SELECTORS.EDIT.SAVE, () => this.onEditConfirm());
        this.bindEvent('click', SELECTORS.EDIT.CANCEL, () => this.onEditCancel());

        if (this.config.id === this.config.preferred) {
            this.$radioButton.prop('checked', true);
        }

        this.bindEvent('change', SELECTORS.CONTAINER.RADIO, (el) => this.setDefault(el));
    }

    setDefault(el) {
        let $el = $(el);

        if ($el.prop('checked')) {
            this.emitter.emit('addressbook.address.setDefault', {
                id: this.config.id,
                isBilling: this.config.isBilling
            });
        }
    }

    onRemove() {
        this.$containers.address.addClass(CLASSES.hide);
        this.$containers.remove.removeClass(CLASSES.hide);

        this.emitter.emit('addressbook.address.remove.start', this);
    }

    onRemoveConfirm() {
        this.emitter.emit('addressbook.address.remove.confirm', {
            id: this.config.id,
            isDefault: this.config.id === this.config.preferred,
            isBilling: this.config.isBilling
        });
        this.destroy();
    }

    onRemoveCancel() {
        this.$containers.remove.addClass(CLASSES.hide);
        this.$containers.address.removeClass(CLASSES.hide);

        this.emitter.emit('addressbook.address.remove.cancel', this);
    }

    onEdit() {
        this.emitter.emit('addressbook.address.edit.start', {
            addressId: this.config.id,
            methodId: this.config.methodId,
            isBilling: this.config.isBilling,
            callback: response => {
                this.replaceHtml(response, this.$containers.editForm);

                this.$containers.address.addClass(CLASSES.addressUnderEdit);
                this.$containers.edit.removeClass(CLASSES.hide);
            },
            section: this
        });

        this.$radioButton.prop('checked', true);
    }

    async onEditConfirm() {
        let form = this.$containers.editForm.find('form').data('cmp-instance');

        if (form) {
            let isValid = await form.validate();

            if (isValid) {
                this.emitter.emit('addressbook.address.edit.save', {
                    form: form.$el.serialize(),
                    methodId: this.config.methodId,
                    addressId: this.config.id,
                    isBilling: this.config.isBilling,
                    callback: () => this.onEditCancel()
                });
            }
        }
    }

    onEditCancel() {
        this.emitter.emit('addressbook.address.edit.cancel');
        this.$containers.edit.addClass(CLASSES.hide);
        this.$containers.address.removeClass(CLASSES.addressUnderEdit);

        this.replaceHtml('', this.$containers.editForm); // clear form container
    }
}
