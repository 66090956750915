import Component from 'client/core/Component';
import { CLASSES } from 'client/utils/globals';
import { getJson } from 'client/utils/ajax';
import $ from 'jquery';
import { appendParamToURL } from 'client/utils/url';
import { scrollToMsg, validateDate } from 'client/utils/common';

const SELECTORS = {
    TAB: '.js-payment-option-tab',
    CONTENT: '.js-payment-option-content',
    PAYMENT_OPTION: 'input[name$=_paymentMethod]',
    INPUT_FIELD: '.js-field',
    PAYMENT_ERROR: '.js-payment-error',
    CHECKOUT_MGR: '[data-cmp="checkoutMgr"]'
};

const STATE_CLASSES = {
    active: 'active'
};

const ATTRIBUTES = {
    CURRENT_SHIPPING_METHOD: 'data-current-shipping-method',
    IS_BOPIS_SHIP_TO_STORE: 'data-is-bopis-ship-to-store',
    APPLICABLE_PAYMENT_METHODS: 'data-applicable-payment-methods',
    MATCHING_PAYMENT_METHOD: 'data-matching-payment-method'
};

const METHOD_IDEAL = 'ideal';
const METHOD_KLARNA = 'Klarna';

export default class PaymentOptions extends Component {
    init() {
        this.tabs = this.$el.find(SELECTORS.TAB);
        this.contents = this.$el.find(SELECTORS.CONTENT);
        this.paymentOptions = this.$el.find(SELECTORS.PAYMENT_OPTION);
        this.selectedMethod = null;
        this.$paymentError = this.$el.parent().find(SELECTORS.PAYMENT_ERROR);

        this.inactive();
        this.activeSelectedOption();
        this.bindEvent('click', SELECTORS.TAB, (el, ev) => {
            this.active(el, ev);
            this.paymentOptionWasSelected(this.selectedMethod.id);
        });

        this.addListenerOnChildren('paymentmethod.selection.changed', this.paymentOptionWasSelected.bind(this));

        /* eslint-disable */
        this.emitter.addListener('giftCards.updated', (_resp) => this.toggleRestrictions());
        this.emitter.addListener('shipping.address.changed', () => this.toggleRestrictions());
        this.emitter.addListener('step.shipping.updated', (_resp) => this.toggleRestrictions());
        this.emitter.addListener('lock.fraud.fail.payment.option', this.lockFraudFailPaymentOptions.bind(this));
        this.emitter.addListener('lock.fraud.flag.payment.option', this.lockFraudFlagPaymentOptions.bind(this));
        /*eslint-enable*/

        if (this.selectedMethod && this.selectedMethod.id &&
            this.selectedMethod.id.toString().toLowerCase() === METHOD_IDEAL
        ) {
            this.fetchIDEALData();
        }
    }

    getCheckoutMgrData() {
        const checkoutMgr = $(SELECTORS.CHECKOUT_MGR);
        let isBopisShipToStore = false;
        let applicablePaymentMethods = [];

        try {
            isBopisShipToStore = JSON.parse(checkoutMgr.attr(ATTRIBUTES.IS_BOPIS_SHIP_TO_STORE));
        } catch (e) {
            isBopisShipToStore = false;
        }

        try {
            applicablePaymentMethods = JSON.parse(checkoutMgr.attr(ATTRIBUTES.APPLICABLE_PAYMENT_METHODS) || '[]');
        } catch (e) {
            applicablePaymentMethods = [];
        }


        return {
            currentShippingMethodID: checkoutMgr.attr(ATTRIBUTES.CURRENT_SHIPPING_METHOD),
            isBopisShipToStore,
            applicablePaymentMethods,
            matchingPaymentMethodID: checkoutMgr.attr(ATTRIBUTES.MATCHING_PAYMENT_METHOD)
        };
    }

    // eslint-disable-next-line consistent-return
    active(el, event) {
        this.$paymentError.addClass(CLASSES.hide);

        let $el = $(el);
        let currentMethodID = this.selectedMethod ? this.selectedMethod.id : null;

        if ($el.hasClass(CLASSES.disabled)) {
            return false;
        }
        event && event.stopPropagation();
        event && event.preventDefault();
        this.inactive();


        $el.addClass(STATE_CLASSES.active);
        $el.find(SELECTORS.PAYMENT_OPTION).prop('checked', true);
        $el.siblings(SELECTORS.CONTENT).removeClass(CLASSES.hide);
        $el.siblings(SELECTORS.CONTENT).find(SELECTORS.INPUT_FIELD).prop('disabled', false);

        this.selectedMethod = {
            id: this.paymentOptions.filter(':checked').val(),
            name: this.paymentOptions.filter(':checked').data('displayValue')
        };

        // Check if payment method was switched and it's iDEAL
        if (this.selectedMethod && this.selectedMethod.id.toString().toLowerCase() === METHOD_IDEAL &&
            currentMethodID !== this.selectedMethod.id
        ) {
            this.fetchIDEALData();
        }
    }

    fetchIDEALData() {
        let billingCmp = $('[data-id=billing-countryCode]').data('cmp-instance');

        if (billingCmp) {
            let billingCountryCode = billingCmp.getValue();
            let url = this.config.idealUrl;

            if (billingCountryCode) {
                url = appendParamToURL(url, 'countryCode', billingCountryCode);
            }

            if (billingCountryCode) {
                let promise = getJson({ url: url });

                promise.then(response => {
                    if (response.success) {
                        this.$paymentError.addClass(CLASSES.hide);
                        this.updateIDEALList(response.bankList);
                    } else if (response.error && response.errorText) {
                        this.showError(response.errorText, this.$paymentError);
                    } else {
                        this.showError(this.config.generalError, this.$paymentError);
                    }
                });
            }
        }
    }

    showError(msg, $block) {
        $block.removeClass(CLASSES.hide);
        $block.html(msg);
        scrollToMsg($block);
    }

    updateIDEALList(bankList) {
        let selectField = this.getNestedComponentById('payment-ideal');
        let $pleaseSelect = $(selectField.$el.find('option').get(0));
        let options = [];

        if ($pleaseSelect.length > 0) {
            options.push({
                id: $pleaseSelect.attr('id'),
                value: $pleaseSelect.attr('value'),
                text: $pleaseSelect.text()
            });
        }

        if (selectField) {
            options = options.concat(bankList.map(item => {
                return {
                    id: item.code,
                    value: item.code,
                    text: item.name,
                    selected: item.code === this.config.idealSelected
                };
            }));

            selectField.updateOptions(options);
        }
    }

    inactive($el) {
        if ($el) {
            const $paymentOption = $el.find(SELECTORS.PAYMENT_OPTION),
                isChecked = $paymentOption.prop('checked');

            $el.removeClass(STATE_CLASSES.active);
            $el.siblings(SELECTORS.CONTENT).addClass(CLASSES.hide);
            $el.siblings(SELECTORS.CONTENT).find(SELECTORS.INPUT_FIELD).prop('disabled', true);

            if (isChecked) {
                $paymentOption.prop('checked', false);
                this.selectedMethod = null;
            }
        } else {
            this.tabs.removeClass(STATE_CLASSES.active);
            this.contents.addClass(CLASSES.hide);
            this.contents.find(SELECTORS.INPUT_FIELD).prop('disabled', true);
            this.paymentOptions.prop('checked', false);
            this.selectedMethod = null;
        }
    }

    /**
     * @returns {string[]}
     */
    get selectedMethods() {
        const selectedCmps = new Set();

        this.loopAllNestedComponents(cmp => {
            if (cmp.callMethod('isSelected') && cmp.config.pid) {
                selectedCmps.add(cmp.config.pid);
            }
        });

        return Array.from(selectedCmps);
    }

    /**
     * @param {string[]} selectedPaymentMethods
     *
     * @returns {string[]}
     */
    getPaymentRestrictions (selectedPaymentMethods) {
        let paymentRestrictions = {};

        if (this.config.paymentRestrictions) {
            paymentRestrictions = this.config.paymentRestrictions.paymentMethods || {};
        }

        let restrictedPaymentMethods = new Set();

        selectedPaymentMethods.forEach(selectedPaymentMethod => {
            if (paymentRestrictions[selectedPaymentMethod]) {
                paymentRestrictions[selectedPaymentMethod].forEach(
                    restrictedPaymentMethods.add,
                    restrictedPaymentMethods
                );
            }

            Object.keys(paymentRestrictions).forEach(pmName => {
                ~(paymentRestrictions[pmName] || []).indexOf(selectedPaymentMethod) &&
                    restrictedPaymentMethods.add(pmName);
            });
        });

        let shippingRestrictions = {};

        if (this.config.paymentRestrictions) {
            shippingRestrictions = this.config.paymentRestrictions.shippingMethods || {};
        }

        const checkoutMgrData = this.getCheckoutMgrData();
        const currentShippingMethodID = checkoutMgrData.currentShippingMethodID;
        const applicablePaymentMethods = checkoutMgrData.applicablePaymentMethods;
        let shippingRestriction = '';

        if (shippingRestrictions && currentShippingMethodID) {
            shippingRestriction = shippingRestrictions[currentShippingMethodID];
        }

        applicablePaymentMethods.forEach(pm => {
            if (checkoutMgrData.isBopisShipToStore && !pm.isDirect) {
                restrictedPaymentMethods.add(pm.ID);
            }

            if (shippingRestriction === pm.ID) {
                restrictedPaymentMethods.add(pm.ID);
            }
        });

        return Array.from(restrictedPaymentMethods);
    }

    paymentOptionWasSelected(paymentID) {
        this.toggleRestrictions(this.getPaymentRestrictions(this.selectedMethods.concat(paymentID)));
    }

    isKlarnaPaymentMethod(id) {
        return METHOD_KLARNA === id;
    }

    /**
     * @param {string[]} [restrictions]
     */
    toggleRestrictions(restrictions = this.getPaymentRestrictions(this.selectedMethods)) {
        // eslint-disable-next-line consistent-return
        this.tabs.each(key => {
            if ($(this.tabs[key]).data('fraudLocked')) {
                let paymentMethodID = $(this.tabs[key]).find('input').data('id');
                var isKlarnaPaymentMethod = this.isKlarnaPaymentMethod(paymentMethodID);
                var isKlarnaPaymentRejected = this.$el.data('klarna-rejected');
                var isDisabled = $(this.tabs[key]).hasClass(CLASSES.disabled);

                if (isDisabled && isKlarnaPaymentMethod && isKlarnaPaymentRejected) {
                    restrictions.push(paymentMethodID);
                    this.showError(this.$el.data('klarna-error'), this.$paymentError);
                }

                $(this.tabs[key]).removeClass(CLASSES.disabled);
            }
        });

        restrictions.length &&
            restrictions.forEach(restrictedPaymentMethod => {
                let paymentOption = this.$el.find(`.js-payment-option-${restrictedPaymentMethod}`);

                this.inactive(paymentOption);
                paymentOption.data('fraudLocked', true);
                paymentOption.addClass(CLASSES.disabled);
            });

        this.emitter.emit('step.payment.paymentmethod.restricted', restrictions);
    }

    activeSelectedOption() {
        let $selected = this.$el.find(`${SELECTORS.PAYMENT_OPTION}[data-selected="true"]`);

        if ($selected && $selected.length) {
            this.active($selected.parents(SELECTORS.TAB));
        }
    }

    getValue() {
        return this.selectedMethod;
    }

    onValidateBirthday(cmp) {
        return validateDate(cmp.getValue());
    }

    lockFraudFailPaymentOptions() {
        this.lockFraudPaymentOptions('Fail');
    }

    lockFraudFlagPaymentOptions() {
        this.lockFraudPaymentOptions('Flag');
    }

    lockFraudPaymentOptions(typeLock) {
        this.tabs.each(key => {
            var paymentOption = $(this.tabs[key]);
            var dataAttrName = 'fraud' + typeLock + 'Detection';

            if (paymentOption.data(dataAttrName)) {
                this.inactive(paymentOption);
                paymentOption.addClass(CLASSES.disabled);
                paymentOption.data('fraudLocked', true);
                this.emitter.emit('step.payment.paymentmethod.fraud.lock', dataAttrName);
            }
        });
    }
}
