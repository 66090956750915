import Component from 'client/core/Component';

const SELECTORS = {
    countDownPlace: '.js-countdown'
};

const MIN_TIME_RANGE = 5000; // ms
const INTERVAL_OF_UPDATE = 1000; // ms

export default class RaffleCountdown extends Component {
    /**
     * @param {number} timeLeft
     * @param {boolean | undefined} isHeroCountdown
     * @returns {string}
     */
    static FormatDate(timeLeft, isHeroCountdown) {
        let d = new Date(timeLeft);
        let hours = d.getUTCHours().toString().padStart(2, '0'),
            minutes = d.getUTCMinutes().toString().padStart(2, '0'),
            seconds = d.getUTCSeconds().toString().padStart(2, '0');

        let timeLeftInSeconds = timeLeft / 1000;

        let days = (Math.floor(timeLeftInSeconds / (24 * 60 * 60))).toString();

        if (isHeroCountdown) {
            return `${days.padStart(days.length > 2 ? days.length : 2, '0')}d ${hours}h ${minutes}m ${seconds}s`;
        }

        return `${days.padStart(days.length > 2 ? days.length : 2, '0')}:${hours}:${minutes}:${seconds}`;
    }

    runCountdown() {
        if (this.config.startDate && this.howManyLeft >= MIN_TIME_RANGE) {
            this.show();
            this.updateCountDown();

            this.interval = setInterval(() => {
                if (!this.howManyLeft || !this.updateCountDown()) {
                    clearInterval(this.interval);

                    this.hide();
                }
            }, INTERVAL_OF_UPDATE);
        } else {
            this.hide();
        }
    }

    hide () {
        // this.$el.addClass(GLOBAL_CLASSES.hide);
        this.emit('hidden');
    }

    show () {
        // this.$el.removeClass(GLOBAL_CLASSES.hide);
        this.emit('shown');
    }

    /**
     * @returns {number}
     */
    get howManyLeft() {
        let msLeft = this.config.startDate - Date.now();

        return msLeft > 0 ? msLeft : 0;
    }

    /**
     * @returns {boolean} whether it was successful
     */
    updateCountDown() {
        const $countDownPlace = this.$el.find(SELECTORS.countDownPlace);

        if (!$countDownPlace.length) { return false; }

        const timeLeft = (new Date(this.config.startDate)).getTime() - Date.now();

        $countDownPlace.html(RaffleCountdown.FormatDate(timeLeft, this.config.isHeroCountdown));

        return true;
    }
}
