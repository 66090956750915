import BaseRefinementMenu from 'client/components/search/RefinementMenu';

import $ from 'jquery';

const SELECTORS = {
    'CONTENT': '.js-list-item',
    'ITEM': '.js-refinement-value',
    'SELECTED': '.js-selected-items',
    'REFINEMENT': '.js-list-content',
    'MIN_PRICE': '.js-filter-min-price',
    'MAX_PRICE': '.js-filter-max-price'
};

export default class RefinementMenu extends BaseRefinementMenu {
    setSelectedRefinements() {
        let $refinements = this.$el.find(SELECTORS.CONTENT);
        let symbol = this.$el.data('price-symbol') || '';

        $refinements.each((index, el) => {
            let $el = $(el),
                $refinement = $el.find(SELECTORS.REFINEMENT),
                value = '';

            if ($refinement.data('attr') === 'price') {
                let minPriceVal = $refinement.find(SELECTORS.MIN_PRICE).val(),
                    maxPriceVal = $refinement.find(SELECTORS.MAX_PRICE).val();

                if (minPriceVal || maxPriceVal) {
                    if (minPriceVal) {
                        value = `${minPriceVal} ${symbol} - `;
                    } else {
                        value = `0 ${symbol} - `;
                    }

                    if (maxPriceVal) {
                        value += `${maxPriceVal} ${symbol}`;
                    } else {
                        value += `99999 ${symbol}`;
                    }
                }
            } else {
                let $items = $el.find(SELECTORS.ITEM),
                    selected = [];

                $items.each((i, e) => {
                    let $e = $(e);

                    if ($e.data('selected') === true) {
                        selected.push($e.data('name'));
                    }
                });

                value = selected.join(', ');
            }

            $el.find(SELECTORS.SELECTED).html(value);
        });
    }
}
