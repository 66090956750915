import Component from 'client/core/Component';

const SELECTORS = {
    contentWrapper: '.js-banner-wrapper'
};

export default class CategoryBanner extends Component {
    init() {
        this.emitter.addListener(
            'refinement.updated',
            ($response) => this.updateBanner($response)
        );
    }

    updateBanner($response) {
        let content = $response.find(SELECTORS.contentWrapper).first().html();

        this.replaceHtml(content, this.$el.find(SELECTORS.contentWrapper));
    }
}
