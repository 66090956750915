import Carousel from 'client/components/Carousel';
import Handlebars, { compile } from 'handlebars';
import handlebarsHelper from 'client/utils/handlebarsHelper';
import { appendParamToURL } from 'client/utils/url';
import prefs from 'sitePreferences';
import $ from 'jquery';
import { CLASSES } from '../../utils/globals';
// @ts-ignore
import { LIMITED_TO } from 'resources';
import { pushState } from 'client/utils/history';

const SELECTORS = {
    carousel: '.js-pdp-color-carousel',
    attrBtn: '.js-pdp-attribute-btn',
    selectedColorValue: '.js-pdp-color-label .js-attr-value',
    attributeTile: '.js-pdp-attribute-tile',
    colorItem: '.js-color-value',
    sizeItem: '.js-size-value',
    target: '.js-target',
    source: '.js-source',
    colorAttributeBtn: '.js-pdp-attribute-btn--color',
    productLimitation: '.js-limitation-message'
};

const STATE_CLASSES = {
    selected: 'b-swatch-value--selected',
    attributeBtnSelected: 'js-pdp-attribute-btn--selected',
    selectable: 'b-swatch-value--selectable',
    highlighted: 'h-highlighted',
    swatchWrapper: 'b-swatch-value-wrapper'
};

const CONFIG = {
    dots: false,
    infinite: true,
    arrows: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    swipe: true,
    swipeToSlide: false,
    autoplay: false,
    responsive: [
        {
            breakpoint: prefs.breakpointMedium + 1,
            settings: {
                arrows: false,
                slidesToShow: 6,
                slidesToScroll: 1,
                swipe: true,
                swipeToSlide: true,
                draggable: false
            }
        },
        {
            breakpoint: prefs.breakpointSmall + 1,
            settings: {
                slidesToShow: 4
            }
        }
    ]
};

export default class VariationAttributes extends Carousel {
    initCarouselInstance() {
        const $elements = this.$elements,
            $carousel = $elements.carousel;

        let config = Object.assign({}, CONFIG);

        $carousel.on('init', () => {
            this.emitter.emit('namespace.component.initall', $carousel);
        });

        $carousel.slick(config);
    }

    renderSizeAttr(product) {
        if (this.template) {
            let result = this.template(product);

            this.$target.html(result);
        }
    }

    updateColorAttr(pid, color, size) {
        const param = (pid && size) ? {
            name: `dwvar_${pid}_size`,
            value: size
        } : null;

        this.$el.find(`${SELECTORS.carousel} ${SELECTORS.attrBtn}`).each((idx, el) => {
            const $el = $(el);

            let href = $el.data('url') || '',
                visibleUrl = $el.data('visibleUrl'),
                sizes = $el.data('sizes') || [];

            if ((typeof sizes === 'string') && sizes.length) {
                try {
                    sizes = JSON.parse(sizes);
                } catch (e) {
                    console.error('JSON parse error:', e, sizes); // eslint-disable-line no-console

                    sizes = [];
                }
            }

            sizes = Array.isArray(sizes) ? sizes : [];

            // eslint-disable-next-line no-bitwise
            if (href && param && ~sizes.indexOf(size)) {
                href = appendParamToURL(href, param.name, param.value);
            }

            if (href) {
                $el.attr('href', visibleUrl).data('href', href);
            } else {
                $el.attr('href', '#').data('href', '');
            }
        });
    }

    // eslint-disable-next-line class-methods-use-this
    getVariable(variables, name) {
        return (variables && variables[name] && variables[name].value) ? variables[name].value : null;
    }

    onVariationUpdate(product, queryParams) {

        if (queryParams.chosen === 'color') {
            this.renderSizeAttr(product);
        }

        this.updateColorAttr(
            queryParams.pid,
            this.getVariable(queryParams.variables, 'color'),
            this.getVariable(queryParams.variables, 'size')
        );

        this.updateProductLimitationMsg(product);
    }

    updateProductLimitationMsg(product) {
        const $productLimitation = this.$el.find(SELECTORS.productLimitation);

        if ($productLimitation.length) {
            $productLimitation.removeClass(STATE_CLASSES.highlighted);

            if (product.limitation.isLimited) {
                $productLimitation.removeClass(CLASSES.hide);
                const limitedToMsg = LIMITED_TO.replace('{0}', product.limitation.limitedTo);

                $productLimitation.text(limitedToMsg);
            } else {
                $productLimitation.addClass(CLASSES.hide);
                $productLimitation.text('');
            }
        }
    }

    onCartAddError(data) {
        const $productLimitation = this.$el.find(SELECTORS.productLimitation);

        if (data.errorType === 'productLimitation' && $productLimitation.length > 0) {
            $productLimitation.addClass(STATE_CLASSES.highlighted);
        }
    }

    // eslint-disable-next-line consistent-return
    initCarousel() {
        this.$elements = {
            carousel: this.$el.find(SELECTORS.carousel)
        };

        if (!('slick' in $.fn) || this.$elements.carousel.hasClass('slick-initialized')) {
            return false;
        }

        this.initCarouselInstance();
    }

    trackAttributeSelection(attrId, attrValue) {
        const data = {
            eventCategory: 'product',
            eventAction: `${attrId} selection`,
            eventLabel: attrValue
        };

        this.emitter.emit('gtmEvent', data);
    }

    onAttributeSelected($el) {
        const attrId = $el.data('attr-id'),
            attrValue = $el.data('value');

        const oldIndex = this.$target
            .find('.' + STATE_CLASSES.selected)
            .closest('.' + STATE_CLASSES.swatchWrapper)
            .index();

        if (attrId === 'color') {
            this.$el.find(SELECTORS.selectedColorValue).html(attrValue);
            this.$el.find(SELECTORS.colorAttributeBtn).removeClass(STATE_CLASSES.attributeBtnSelected);
            $el.addClass(STATE_CLASSES.attributeBtnSelected);
            this.emitter.emit('change.color', SELECTORS.selectedColorValue, attrValue);

            const visibleUrl = $el.data('visibleUrl');

            if (visibleUrl) {
                pushState(visibleUrl);

                if (
                    'bndla' in window &&
                    window.bndla &&
                    'reloadApi' in window.bndla &&
                    typeof window.bndla.reloadApi === 'function'
                ) {
                    window.bndla.reloadApi();
                }
            }
        }

        if ($el.children().hasClass(STATE_CLASSES.selectable)) {
            this.$el.find((attrId === 'color') ?
                SELECTORS.colorItem :
                SELECTORS.sizeItem).removeClass(STATE_CLASSES.selected);
            $el.find(SELECTORS.attributeTile).removeClass(CLASSES.clicked).addClass(STATE_CLASSES.selected);
        }

        const newIndex = this.$target
            .find('.' + STATE_CLASSES.selected)
            .closest('.' + STATE_CLASSES.swatchWrapper)
            .index();

        if (this.gtmEnabled && oldIndex !== newIndex) {
            if (attrId === 'size') {
                const articleCode = $el.data('articleCode');
                const sizeAttrValue = articleCode ? `${articleCode}, ${attrValue}` : attrValue;

                this.trackAttributeSelection(attrId, sizeAttrValue);
            } else {
                this.trackAttributeSelection(attrId, attrValue);
            }
        }

        if (prefs.isFitAnalyticsEnabled) {
            // @ts-ignore
            window.fitAnalyticsData = window.fitAnalyticsData || {};
            // @ts-ignore
            var fitAnalyticsData = window.fitAnalyticsData;

            // @ts-ignore
            window.fitAnalyticsData = Object.assign(fitAnalyticsData, $el.data('fit-analitycs-data'));
        }
    }

    // eslint-disable-next-line class-methods-use-this
    onAttributeUnselected($el) {
        $el.removeClass(STATE_CLASSES.selected);
    }

    getProductData($el) {
        const product = $el.data('gtm');

        if (product) {
            const eventData = Object.assign({ list: `${this.gtmPageType}/PDP Color Switch` }, product);

            return eventData;
        }

        return null;
    }

    trackProductImpression() {
        const $colorAttributeBtn = this.$el.find(SELECTORS.colorAttributeBtn);

        if (!$colorAttributeBtn.length) {
            return;
        }

        const impressions = [];

        $colorAttributeBtn.each((idx, el) => {
            const $el = $(el);

            const data = this.getProductData($el);

            if (data) {
                const position = $el.data('position');

                impressions.push(Object.assign({ position: position || 1 }, data));
            }
        });

        if (impressions.length) {
            const eventData = Object.assign(
                // @ts-ignore
                { currencyCode: window.SitePreferences.currencyCode },
                { impressions: impressions }
            );

            this.emitter.emit('gtmEcommerceEvent', 'eeProductImpression', eventData);
        }
    }

    trackProductClick(el, color) {
        const $el = $(el),
            product = $el.data('gtm');

        if (product) {
            const eventData = {
                // @ts-ignore
                'currencyCode': window.SitePreferences.currencyCode,
                'click': {
                    'actionField': {
                        'list': `${this.gtmPageType}/PDP Color Switch`
                    },
                    'products': [
                        product
                    ]
                }
            };

            this.emitter.emit('gtmEcommerceEvent', 'eeProductClick', eventData);

            if (color) {
                let eventLabel = '';

                if (product && Object.hasOwnProperty.call(product, 'dimension25')) {
                    eventLabel = product.dimension25;
                }

                this.emitter.emit('gtmEvent', {
                    eventCategory: 'product',
                    eventAction: 'color selection',
                    eventLabel: eventLabel,
                    ga4eventname: 'product_color_selection'
                });
            }

        }
    }

    // eslint-disable-next-line consistent-return
    init() {
        if (!('slick' in $.fn)) {
            return false;
        }

        this.initCarousel();

        this.emitter.addListener('attribute.selected', ($el) => this.onAttributeSelected($el));
        this.emitter.addListener('attribute.unselected', this.onAttributeUnselected.bind(this));
        this.emitter.addListener(
            'variation.updated',
            (product, queryParams) => this.onVariationUpdate(product, queryParams)
        );
        this.emitter.addListener('cart.add.error', this.onCartAddError.bind(this));

        handlebarsHelper(Handlebars);

        this.$target = this.$el.find(SELECTORS.target);
        this.source = this.$el.find(SELECTORS.source).html();

        if (this.source) {
            this.template = compile(this.source);
        }

        if (this.gtmEnabled) {
            this.trackProductImpression();

            this.bindEvent('click', SELECTORS.colorAttributeBtn, (el) => {
                this.trackProductClick(el, true);
            });
        }
    }
}
