import Component from 'client/core/Component';
import { throttle } from 'lodash';
import { isTablet, refresh } from 'client/utils/mobileDetect';
import { isSmall, isMedium } from 'client/utils/screendetector';
import { CLASSES } from 'client/utils/globals';
import prefs from 'sitePreferences';
import $ from 'jquery';

const
    SELECTORS = {
        headerHumburger: '[data-cmp="headerHamburgerRedesigned"]',
        menuContainer: '.js-header-menu-container',
        menuClose: '.js-menu-close',
        overlay: '.js-mobile-menu-overlay',
        listShow: '.js-list-show',
        listHide: '.js-list-hide',
        menuWrapper: '.js-header-menu-wrapper:not(.js-menu-with-gender-tabs)',
        listWrapper: '.js-list-wrapper',
        menuTitle: '.js-menu-title',
        listItem: '.js-list-item',
        sectionTitle: '.js-menu-section-title',
        menuSection: '.js-menu-section',
        menuListLink: '.js-menu-list-link',
        menuListLinkMobile: '.js-menu-list-link--mobile',
        headerWrap: '.js-header-search',
        menuLevelTitle: '.js-menu-level-title',
        mobileGenderBannerLinks: '.js-header-mobile-menu-banner-links',
        mobileGenderCatTab: '.js-mobile-gender-category-tab',
        mobileGenderCatLink: '.js-mobile-gender-category-link',
        mobileGenderCatTabContent: '.js-mobile-gender-category-tab-content',
        mobileGenderCatActive: '.h-active',
        mobileGenderNeutralWrapper: '.js-mobile-gender-neutral-wrapper',
        nonCatalogMenuWrapper: '.js-header-menu-section',
        mobileMenuSubtitle: '.js-header-menu-subtitle-wrap',
        topGenderMenuWrapper: '.js-top-gender-menu-wrapper',
        mobileHeaderMenuBannerItem: '.js-header-menu-banner-item',
        mobileHeaderMenuBannerItemActive: '.js-header-menu-banner-item:not(.h-hide)',
        mobileGenderCategoryListItem: '.js-mobile-gender-category-list-item'
    },
    STATE_CLASSES = {
        sectionVisibility: 'h-visibility',
        hideMobile: 'h-hide-sm h-hide-md',
        showMobile: 'h-show-sm h-show-md',
        overlayShow: 'b-mobile-menu-overlay--show',
        menuShow: 'mobile-menu-show',
        touchableDevice: 'h-disable-hover',
        mobileMenuOpened: 'h-mobile-menu-opened',
        mobileGenderCatActive: 'h-active',
        hide: 'h-hide',
        menuContainer: 'js-header-menu-container',
        menuRemoveTransition: 'h-remove-transition',
        mobileGenderSubcategoryShow: 'h-mobile-gender-subcategory-show', // ?
        mobileGenderChanged: 'h-mobile-gender-changed'
    };

const ALL_MENU_TAB_ID = 'all';
const $body = $('body');

export default class HeaderHamburgerRedesigned extends Component {

    init () {
        this.listHide = this.$el.find(SELECTORS.listHide);
        this.menuContainer = this.$el.find(SELECTORS.menuContainer);
        this.overlay = this.$el.find(SELECTORS.overlay);
        this.menuWrapper = this.$el.find(SELECTORS.menuWrapper);
        this.menuTitle = this.$el.find(SELECTORS.menuTitle);
        this.sectionTitle = this.$el.find(SELECTORS.sectionTitle);
        this.menuSection = this.$el.find(SELECTORS.menuSection);
        this.$headerWrap = $(SELECTORS.headerWrap);
        this.$menuLevelTitle = this.$el.find(SELECTORS.menuLevelTitle);
        this.mobileGenderNeutralWrapper = this.$el.find(SELECTORS.mobileGenderNeutralWrapper);
        this.mobileMenuSubtitle = this.$el.find(SELECTORS.mobileMenuSubtitle);
        this.nonCatalogMenuWrapper = this.menuContainer.find(SELECTORS.nonCatalogMenuWrapper);
        this.mobileGenderCategoryListItem = this.menuContainer.find(SELECTORS.mobileGenderCategoryListItem);

        this.isOpen = false;
        this.currentLevel = 0;
        this.currentLink = '';
        this.currentCat = this.menuTitle.data('menu-title');
        this.prevCat = '';
        this.clickedCategories = new Set(['all']);
        this.isGenderSpecific = this.menuContainer.data('genderSpecific') || false;
        this.showedMenuOnInit = false;

        this.bindEvent('click', SELECTORS.listShow, this.pullLeft);
        this.bindEvent('click', SELECTORS.listHide, this.pullRight);
        this.bindEvent('click', SELECTORS.menuClose, this.mobileMenuToggle);
        this.bindEvent('click', SELECTORS.overlay, this.mobileMenuToggle);
        this.bindEvent('click', SELECTORS.menuTitle, this.relocate);
        this.bindEvent('click', SELECTORS.mobileGenderCatLink, this.mobileGenderCatSwitch);
        this.bindEvent('click', SELECTORS.menuContainer, (el, event) => {
            if ($(event.target).hasClass(STATE_CLASSES.menuContainer)) {
                this.mobileMenuToggle();
            }
        });

        if (this.gtmEnabled) {
            this.bindEvent('click', SELECTORS.menuListLink, this.trackMenuItemClick);
        }

        this.emitter.addListener('mobileMenuShow', this.mobileMenuToggle.bind(this));

        this.emitter.addListener('orientationChange', () => {
            if (this.isOpen) {
                this.showedMenuOnInit = false;
                this.mobileMenuToggle();
            }
            this.toggleScroll(true);
        });

        this.emitter.addListener(
            'refinement.updated',
            ($response) => this.updateHeaderHamburger($response)
        );

        this.emitter.addListener('resize', throttle(() => {
            if (!isSmall() && !isMedium()) {
                this.showCurrentMenuListOnInit();
            } else {
                this.showedMenuOnInit = false;
            }
        }, 1000));

        if (!this.menuContainer.find(SELECTORS.mobileGenderCatActive).length) {
            this.menuContainer.find(`${SELECTORS.mobileGenderCatTabContent}:first`)
                .addClass(STATE_CLASSES.mobileGenderCatActive);
        }
    }

    showCurrentMenuListOnInit() {
        if (this.showedMenuOnInit) {
            return;
        }

        const listCatsId = this.config.categoriesIdPath.data;

        if (!listCatsId.length) {
            return;
        }

        const currentCatId = listCatsId[listCatsId.length - 1];
        const parentCatId = listCatsId[listCatsId.length - 2] || null;
        const prevCatId = listCatsId[listCatsId.length - 3] || null;
        const mainCatId = listCatsId[0];

        // Find elements from array categoriesIdPath
        let listLinkCurrentCat = this.menuContainer
                                    .find(`${SELECTORS.menuListLink}[data-raw-cat-id="${currentCatId}"]`); //refactor after fix duplicate ids

        let listLinkParentCat = this.menuContainer
                                    .find(`${SELECTORS.menuListLinkMobile}[data-raw-cat-id='${parentCatId}']`).first(); // eslint-disable-line max-len

        const listLinkPrevCat = this.menuContainer
                                    .find(`${SELECTORS.menuListLinkMobile}[data-raw-cat-id='${prevCatId}']`).first();

        const $activeGenderCatTabContent = this.menuContainer
                                    .find(`${SELECTORS.mobileGenderCatTabContent}[data-raw-cat-id="${mainCatId}"]`); // eslint-disable-line max-len

        this.currentLevel = listCatsId.length - 2 || 0;
        this.currentCat = listLinkParentCat.data('cat');
        this.prevCat = listLinkPrevCat.data('cat');

        // Find gender category tabs
        const genderCategoryTabs = this.menuContainer.find(SELECTORS.mobileGenderCatTabContent);

        // Hidden gender category tabs
        genderCategoryTabs.addClass(STATE_CLASSES.menuRemoveTransition);
        genderCategoryTabs.css('transform', 'translateX(-100%)');
        genderCategoryTabs.removeClass(STATE_CLASSES.mobileGenderCatActive);
        genderCategoryTabs.addClass(STATE_CLASSES.hideMobile);

        // Saved active gender category
        this.clickedCategories = new Set([$activeGenderCatTabContent.data('cat')]);

        // Moved menu to show the right level
        if (listCatsId.length <= 2) {
            $activeGenderCatTabContent.css('transform', 'translateX(0%)');
        } else {
            $activeGenderCatTabContent.css('transform', `translateX(-${100 * (this.currentLevel)}%)`);
            this.mobileGenderCategoryListItem.addClass(STATE_CLASSES.mobileGenderSubcategoryShow);
        }

        // Showed active gender category content
        $activeGenderCatTabContent.removeClass(STATE_CLASSES.hideMobile);
        $activeGenderCatTabContent.addClass(STATE_CLASSES.showMobile);
        $activeGenderCatTabContent.addClass(STATE_CLASSES.mobileGenderCatActive);
        genderCategoryTabs.removeClass(STATE_CLASSES.menuRemoveTransition);

        // Changed the active gender category
        this.menuContainer.find(SELECTORS.mobileGenderCatLink).removeClass(STATE_CLASSES.mobileGenderCatActive);
        this.menuContainer.find(`${SELECTORS.mobileGenderCatLink}[data-raw-cat-id="${mainCatId}"]`)
                          .addClass(STATE_CLASSES.mobileGenderCatActive);

        //Show header menu subtitle
        if (this.currentLevel) {
            this.backBtnShow();

            const currentSubTitle = listLinkParentCat.text();

            this.mobileMenuSubtitle.find(SELECTORS.menuTitle).text(currentSubTitle);
            this.mobileMenuSubtitle.css('display', 'flex');
            this.menuSection.addClass(STATE_CLASSES.sectionVisibility);
        }

        //Change active gender banner image
        this.showMatchingCatBanner(mainCatId);

        //Show active menu list
        listLinkCurrentCat.parents(SELECTORS.listWrapper).removeClass(STATE_CLASSES.hideMobile);

        this.showedMenuOnInit = true;
    }

    trackMenuItemClick(el) {
        const data = {
            eventCategory: 'navigation',
            eventAction: $(el).data('parent-id'),
            eventLabel: el.id,
            ga4eventname: 'navigation'
        };

        this.emitter.emit('gtmEvent', data);
    }

    showMobileMenuItem($elem) {
        if (!$elem) {
            return;
        }

        const $listItem = $elem.parent().closest(SELECTORS.listItem);

        if ($listItem && $listItem.length > 0) {
            $listItem.removeClass(STATE_CLASSES.hideMobile);
            this.showMobileMenuItem($listItem);
        }
    }

    hideMobileMenuItem() {
        const prevListWrapper = $(`${SELECTORS.listWrapper}[data-cat='${this.currentCat}']`);

        prevListWrapper.removeClass(STATE_CLASSES.hideMobile);
        prevListWrapper.find(SELECTORS.listItem).removeClass(STATE_CLASSES.hideMobile);
    }

    toggleMobileMenuSubtitle() {
        if (this.currentLevel === 0) {
            this.mobileMenuSubtitle.hide();
        } else {
            this.mobileMenuSubtitle.css('display', 'flex');
        }
    }

    // Depending on the menu section, we can show back button and section title or hide.
    // This requires to manually control the "top" style of section to lift or lower it depending on above content.
    // This "top: Xpx" style could be set to different wrappers depending on menu section.
    // For example, if this is a category from the general (gender neutral) section, then we take the 2nd level child wrapper
    // and set top for it, and if category belongs to gender-specific category, then we take the first descendant.
    findMatchedWrapper(levelsCount) {
        let categoryPath = Array.from(this.clickedCategories);

        if (Number.isInteger(levelsCount)) {
            categoryPath = categoryPath.slice(0, levelsCount);
        }

        let wrapper;

        categoryPath.forEach((value) => {
            if (wrapper) {
                wrapper = wrapper.find(`${SELECTORS.listWrapper}[data-cat="${value}"]`);
            } else if (value === ALL_MENU_TAB_ID) {
                wrapper = $(SELECTORS.mobileGenderNeutralWrapper);
            } else {
                wrapper = $(`${SELECTORS.listWrapper}[data-cat="${value}"]`);
            }
        });

        return wrapper;
    }

    pullLeft (el, event) {
        if (!isSmall() && !isMedium()) {
            return;
        }

        event.preventDefault();

        if (!this.isOpen) {
            return;
        }

        const $el = $(el),
            catName = $el.data('cat'),
            nextListWrapper = $el.closest(SELECTORS.listItem, this.$el).find(SELECTORS.listWrapper).first(),
            listWrapper = $el.closest(SELECTORS.listWrapper, this.$el),
            href = $el.attr('href'),
            prevCat = listWrapper.data('cat');

        // Update global variables
        this.currentCat = catName;
        this.prevCat = prevCat;
        this.currentLink = href;
        this.currentLevel++;

        // Hide all list item
        this.menuContainer.find(SELECTORS.listItem).addClass(STATE_CLASSES.hideMobile);

        // Show need elements
        this.showMobileMenuItem($el);
        $el.closest(SELECTORS.listItem).find(SELECTORS.listItem).removeClass(STATE_CLASSES.hideMobile);
        nextListWrapper.removeClass(STATE_CLASSES.hideMobile);

        if (this.currentLevel) {
            this.backBtnShow();
            this.sectionTitle.hide();
            this.menuSection.addClass(STATE_CLASSES.sectionVisibility);

            // Need for the animation
            if (!this.mobileGenderCategoryListItem.hasClass(STATE_CLASSES.mobileGenderSubcategoryShow)) {
                this.mobileGenderCategoryListItem.addClass(STATE_CLASSES.mobileGenderSubcategoryShow);
            }
        } else {
            this.sectionTitle.show();
            this.menuSection.removeClass(STATE_CLASSES.sectionVisibility);
        }

        const activeTab = this.menuContainer
                            .find(`${SELECTORS.mobileGenderCatTabContent}${SELECTORS.mobileGenderCatActive}`);

        activeTab.css('transform', `translateX(-${100 * this.currentLevel}%)`);

        // Update text above menu list, near back button
        this.menuTitle.text(this.currentCat);
        this.$menuLevelTitle.addClass(STATE_CLASSES.headerMenuTitleAlign);

        this.clickedCategories.add(this.currentCat);

        if (this.isGenderSpecific) {
            if (listWrapper.data('main')) {
                nextListWrapper.find(SELECTORS.menuListLinkMobile).removeClass(STATE_CLASSES.hideMobile);
            } else {
                $(SELECTORS.mobileGenderNeutralItem).find(SELECTORS.menuListLinkMobile).addClass(STATE_CLASSES.hideMobile); // eslint-disable-line max-len
            }

            this.toggleMobileMenuSubtitle();
        } else {
            this.$menuLevelTitle.get(0).scrollIntoView();
        }
    }

    toggleMenuItems(cat) {
        if (this.menuContainer.hasClass(STATE_CLASSES.menuShow)) {
            $(this.menuContainer)
                .find(`${SELECTORS.listItem}`)
                .addClass(STATE_CLASSES.hideMobile);
        }

        $(`.js-list-wrapper[data-cat=${cat}]`).find(SELECTORS.listItem).removeClass(STATE_CLASSES.hideMobile);
    }

    pullRight () {
        let delay = this.menuWrapper.css('transition-duration') || '1';
        const $targetList = this.$el.find(`${SELECTORS.listWrapper}[data-cat='${this.currentCat}']`);

        delay = (delay.substring(0, delay.length - 1)) * 1000;
        this.currentLevel--;

        if (this.currentLevel) {
            this.sectionTitle.hide();
            this.menuSection.addClass(STATE_CLASSES.sectionVisibility);
        } else {
            this.backBtnHide();
            this.sectionTitle.show();
            this.menuSection.removeClass(STATE_CLASSES.sectionVisibility);
            this.menuSection.find(SELECTORS.listItem).removeClass(STATE_CLASSES.hideMobile);

            // Need for the animation
            this.mobileGenderCategoryListItem.removeClass(STATE_CLASSES.mobileGenderSubcategoryShow);
            this.mobileGenderCategoryListItem.removeClass(STATE_CLASSES.menuRemoveTransition);
        }

        this.clickedCategories.delete(this.currentCat);
        this.hideMobileMenuItem();

        const $targetLink = this.$el.find(`${SELECTORS.listShow}[data-cat='${this.currentCat}']`),
            $prevList = $targetLink.closest(SELECTORS.listWrapper, this.$el);

        // Update global variables
        this.currentCat = this.prevCat;
        this.prevCat = $prevList.data('cat');
        this.currentLink = $targetLink.attr('href');

        // Update text above menu list, near back button
        this.menuTitle.text(this.currentCat);

        const activeTab = this.menuContainer.find(`${SELECTORS.mobileGenderCatTabContent}${SELECTORS.mobileGenderCatActive}`); // eslint-disable-line max-len

        activeTab.css('transform', `translateX(-${100 * this.currentLevel}%)`);

        if (this.isGenderSpecific) {
            const newWrapper = this.findMatchedWrapper();

            $targetList.addClass(STATE_CLASSES.hideMobile);
            newWrapper.children(SELECTORS.listItem).removeClass(STATE_CLASSES.hideMobile);
            newWrapper.children(SELECTORS.menuListLinkMobile).removeClass(STATE_CLASSES.hideMobile);
            newWrapper
                .children(SELECTORS.listItem)
                .children(SELECTORS.menuListLinkMobile)
                .removeClass(STATE_CLASSES.hideMobile);
            this.toggleMobileMenuSubtitle();
        } else {
            setTimeout(function () {
                $targetList.addClass(STATE_CLASSES.hideMobile);
            }, delay);
        }
    }

    backBtnShow () {
        this.listHide.removeClass(CLASSES.hide);
    }

    backBtnHide () {
        this.listHide.addClass(CLASSES.hide);
    }

    mobileMenuToggle () {
        this.menuContainer.toggleClass(STATE_CLASSES.menuShow);
        this.overlay.toggleClass(STATE_CLASSES.overlayShow);

        this.isOpen = !this.isOpen;

        if (this.isOpen) {
            this.showCurrentMenuListOnInit();
            this.emitter.emit('disabledScroll');
            $body.addClass(STATE_CLASSES.mobileMenuOpened);
            this.$headerWrap.addClass(STATE_CLASSES.mobileMenuOpened);
            this.$el.find(SELECTORS.menuClose).focus();
        } else {
            $body.removeClass(STATE_CLASSES.mobileMenuOpened);
            this.$headerWrap.removeClass(STATE_CLASSES.mobileMenuOpened);
            this.emitter.emit('enabledScroll');
        }
    }

    relocate() {
        if (this.currentLink) {
            location.href = this.currentLink;
        }
    }

    toggleScroll(isChange) {
        if (isChange) {
            refresh();
        }

        const windowWidth = $(window).innerWidth();
        const tablet = isTablet();

        if (this.isOpen && tablet && windowWidth > prefs.breakpointMedium) {
            this.emitter.emit('enabledScroll');
        } else if (this.isOpen) {
            this.emitter.emit('disabledScroll');
        }
    }

    updateHeaderHamburger($response) {
        const newHeaderHamburger = $response.find(SELECTORS.headerHumburger).html();

        if (newHeaderHamburger) {
            this.replaceHtml(newHeaderHamburger, null, {}, () => {
                this.emitter.emit('namespace.component.destroy', this.$el);
                this.emitter.emit('namespace.component.reinit', this.$el);
            });
        }
    }

    showMatchingCatBanner(catId) {
        $(SELECTORS.mobileHeaderMenuBannerItemActive).addClass(STATE_CLASSES.hide);
        $(`${SELECTORS.mobileHeaderMenuBannerItem}[data-raw-cat-id="${catId}"]`).removeClass(STATE_CLASSES.hide);
    }

    // Need only for mobile and tablet
    mobileGenderCatSwitch(el, ev) {
        if (!this.isOpen) {
            return;
        }

        ev.preventDefault();

        this.currentLevel = 0;

        const currentActiveLinkSelector = `${SELECTORS.mobileGenderCatLink}${SELECTORS.mobileGenderCatActive}`;
        const currentActiveTabSelector = `${SELECTORS.mobileGenderCatTabContent}${SELECTORS.mobileGenderCatActive}`;
        const currentActiveLink = this.menuContainer.find(currentActiveLinkSelector);
        const currentActiveTab = this.menuContainer.find(currentActiveTabSelector);
        const choosedGenderCatId = $(ev.target).closest(SELECTORS.mobileGenderCatLink).data('rawCatId');

        // 1. Toggle active gender menu link
        currentActiveLink.removeClass(STATE_CLASSES.mobileGenderCatActive);

        if ($(ev.target).closest(SELECTORS.mobileGenderBannerLinks)) {
            $(SELECTORS.topGenderMenuWrapper)
                .find(`${SELECTORS.mobileGenderCatLink}[data-raw-cat-id="${choosedGenderCatId}"]`)
                .addClass(STATE_CLASSES.mobileGenderCatActive);
        } else {
            $(ev.target).closest(SELECTORS.mobileGenderCatLink).addClass(STATE_CLASSES.mobileGenderCatActive);
        }


        // 2. Toggle active gender menu content
        const choosedGenderCat = this.menuContainer.find(`${SELECTORS.mobileGenderCatTabContent}[data-raw-cat-id="${choosedGenderCatId}"]`); // eslint-disable-line max-len

        choosedGenderCat.css('transform', `translateX(-${100 * this.currentLevel}%)`);

        // 2.1. Hide previous active menu content
        if (choosedGenderCatId === currentActiveLink.data('rawCatId')) {
            // Need for the animation
            this.mobileGenderCategoryListItem.removeClass(STATE_CLASSES.menuRemoveTransition);
        } else {
            // Need for the animation
            this.mobileGenderCategoryListItem.addClass(STATE_CLASSES.menuRemoveTransition);
        }

        currentActiveTab.find(SELECTORS.menuListLinkMobile).addClass(STATE_CLASSES.hideMobile);
        currentActiveTab.removeClass(STATE_CLASSES.mobileGenderCatActive);
        currentActiveTab.addClass(STATE_CLASSES.hideMobile);

        // 2.2. Show new clicked active menu content


        choosedGenderCat.addClass(STATE_CLASSES.mobileGenderCatActive);
        choosedGenderCat.removeClass(STATE_CLASSES.hideMobile);
        choosedGenderCat.find(SELECTORS.menuListLinkMobile).removeClass(STATE_CLASSES.hideMobile);
        choosedGenderCat.find(SELECTORS.listItem).removeClass(STATE_CLASSES.hideMobile);
        choosedGenderCat.addClass(STATE_CLASSES.showMobile);

        // Need for the animation
        this.mobileGenderCategoryListItem.removeClass(STATE_CLASSES.mobileGenderSubcategoryShow);

        setTimeout(function () {
            if (this.mobileGenderCategoryListItem &&
                this.mobileGenderCategoryListItem.hasClass(STATE_CLASSES.menuRemoveTransition)
            ) {
                this.mobileGenderCategoryListItem.removeClass(STATE_CLASSES.menuRemoveTransition);
            }
        }, 750);

        // Update active banner
        this.showMatchingCatBanner(choosedGenderCatId);

        // Hide subtitle and back button
        this.mobileMenuSubtitle.hide();

        // 2.3. Toggle non-catalog navigation menu containers
        this.sectionTitle.show();
        this.menuSection.removeClass(STATE_CLASSES.sectionVisibility);
        this.menuSection.find(SELECTORS.listItem).removeClass(STATE_CLASSES.hideMobile);

        //3.1. Update category path
        const catName = $(el).data('cat');

        this.clickedCategories.clear();
        this.clickedCategories.add(catName);
    }
}
