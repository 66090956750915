import { extractParamFromURL, appendParamToURL } from 'client/utils/url';

/**
 * @function
 * @param {String} url
 * @return {String}
 */
export function addGridNavigation(url) {
    let currentUrl = location.search,
        sz = extractParamFromURL(currentUrl, 'sz'),
        start = extractParamFromURL(currentUrl, 'start'),
        size = extractParamFromURL(currentUrl, 'size'),
        srule = extractParamFromURL(currentUrl, 'srule');

    if (sz) {
        url = appendParamToURL(url, 'sz', sz);
    }

    if (start) {
        url = appendParamToURL(url, 'start', start);
    }

    if (size) {
        url = appendParamToURL(url, 'size', size);
    }

    if (srule) {
        url = appendParamToURL(url, 'srule', srule);
    }

    return url;
}

export function addSearchNavigation(url) {
    let currenQuery = location.search,
        searchQuery = extractParamFromURL(currenQuery, 'q'),
        srule = extractParamFromURL(currenQuery, 'srule'),
        isRefinedByBrand = extractParamFromURL(currenQuery, 'brandRefinement');

    if (searchQuery) {
        url = appendParamToURL(url, 'q', searchQuery.replace(/\+/g, ' '));
    }

    if (srule) {
        url = appendParamToURL(url, 'srule', srule);
    }

    if (isRefinedByBrand) {
        url = appendParamToURL(url, 'brandRefinement', true);
    }

    return url;
}
