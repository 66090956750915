import Component from 'client/core/Component';
import ajax from 'client/utils/ajax';
import { redirectTo } from 'client/utils/common';
import { appendParamToURL } from 'client/utils/url';
import templatesManager from 'client/utils/templatesManager';

const SELECTORS = {
    'SHIPPING': '.js-shipping-address-container',
    'BILLING': '.js-billing-address-container'
};

export default class AddressBookMgr extends Component {
    init () {
        this.shippingAddressTemplate = templatesManager.templates.shippingAddressListTemplate;
        this.billingAddressTemplate = templatesManager.templates.billingAddressListTemplate;

        this.activeEditSection = null;
        this.activeAddSection = null;
        this.activeRemoveSection = null;

        this.$shippingContainer = this.$el.find(SELECTORS.SHIPPING);
        this.$billingContainer = this.$el.find(SELECTORS.BILLING);

        this.emitter.addListener('addressbook.address.add.start', data => this.addAddressStart(data));
        this.emitter.addListener('addressbook.address.add.save', data => this.addAddressSave(data));
        this.emitter.addListener('addressbook.address.add.cancel', () => this.checkDefaultAddress());
        this.emitter.addListener('addressbook.address.edit.start', data => this.editAddressStart(data));
        this.emitter.addListener('addressbook.address.edit.save', data => this.editAddressSave(data));
        this.emitter.addListener('addressbook.address.edit.cancel', () => this.checkDefaultAddress());
        this.emitter.addListener('addressbook.address.remove.start', data => this.removeAddressStart(data));
        this.emitter.addListener('addressbook.address.remove.confirm', data => this.removeAddressConfirm(data));
        this.emitter.addListener('addressbook.address.remove.cancel', () => this.removeAddressCancel());
        this.emitter.addListener('addressbook.address.setDefault', data => this.setAddressDefault(data));

        this.emitter.addListener('checkout.step.shipping.finder', () => this.initFinderFields());
        this.emitter.addListener('modal.closed', p => this.onModalClosed(p));

        ajax.registerErrorHandler((a, b, c, d) => this.responseFilter(a, b, c, d));
    }

    setAddressDefault(data) {
        if (!data.isBilling) {
            let url = this.config.setDefaultUrl;

            url = appendParamToURL(url, 'addressId', data.id);

            ajax.getJson({ url: url }).then(() => {
                this.updateList();
            });
        }
    }

    onModalClosed(event) {
        const PICK_UP_STATION_FIELDS = {
            'packstation': 'packstationNumber',
            'filiale': 'postOfficeNumber',
            'hermes': 'carrierStationId',
            'sda': 'carrierStationId',
            'ups': 'carrierStationId'
        };
        const DHLCarrier = ['packstation', 'filiale'];

        if (event.id in PICK_UP_STATION_FIELDS) {
            let address = event.params.address;

            let obj = {
                id: 'new',
                city: address.city,
                street: address.street,
                suite: address.streetNo,
                postalCode: address.zip,
                stationType: event.id,
                carrierStationName: address.name || '',
                carrier: DHLCarrier.includes(event.id) ? 'dhl' : event.id
            };

            obj[PICK_UP_STATION_FIELDS[event.id]] = event.params.ID;
            this.emitter.emit('addressBook.shipping.updated.pickup', obj);
            this.emitter.emit('addressBook.shipping.updated', obj);
        } else if (event.id === 'storeLocator') {
            let address = event.params.address,
                obj = {
                    id: 'new',
                    city: address.city,
                    postalCode: address.postalCode,
                    snipesStore: event.params.ID
                };

            let delimiter = address.address1.indexOf(',') > 0 ? ',' : ' ';
            let splitted = address.address1.split(delimiter);

            if (splitted[splitted.length - 1] && splitted[splitted.length - 1].match(/^\d{1,}/gi)) {
                obj.suite = splitted.slice(-1);
                obj.street = splitted.slice(0, -1).join(' ');
            } else {
                obj.street = address.address1;
            }

            this.emitter.emit('addressBook.shipping.updated.store', obj);
            this.emitter.emit('addressBook.shipping.updated', obj);
            this.emitter.emit('step.shipping.hide.title');
        }
    }

    initFinderFields() {
        let zip = null;
        let city = null;

        let $form = this.$el.find('[data-id="addEditForm"]');
        let form = $form.data('cmp-instance');

        form.getNestedComponentById('shipping-postalCode', cmp => {
            let value = cmp.getValue();

            if (value) {
                zip = value;
            }
        });

        form.getNestedComponentById('shipping-city', cmp => {
            let value = cmp.getValue();

            if (value) {
                city = value;
            }
        });

        this.emitter.emit('checkout.step.shipping.finder.init', {
            zip: zip,
            city: city
        });
    }

    responseFilter(event) {
        let response;

        try {
            response = JSON.parse(event.responseText);
        } catch (e) {
            return;
        }

        if (response && !response.loggedin && response.redirectUrl) {
            redirectTo(response.redirectUrl);
        }
    }

    addAddressStart(data) {
        if (!data.sameContainer) {
            this.toggleSections();
        }

        this.activeAddSection = data.section;

        let url = data.isBilling ? this.config.addBillingUrl : this.config.addUrl;

        if (data.methodId) {
            url = appendParamToURL(url, 'methodId', data.methodId);
        }

        let promise = ajax.load({ url: url });

        promise.then(response => data.callback(response));
    }

    addAddressSave(data) {
        this.activeAddSection = null;

        let url = this.config.saveUrl;

        if (data.isBilling) {
            url = appendParamToURL(url, 'isBilling', data.isBilling);
        } else if (data.methodId) {
            url = appendParamToURL(url, 'methodId', data.methodId);
        }

        let promise = ajax.getJson({
            url: url,
            type: 'POST',
            data: data.form
        });

        promise.then(response => {
            data.callback(response);

            if (response.success) {
                this.updateList();
            }
        });
    }

    editAddressStart(data) {
        this.toggleSections();

        this.activeEditSection = data.section;

        let url;

        if (data.isBilling) {
            url = this.config.editBillingUrl;
        } else {
            url = appendParamToURL(this.config.editUrl, 'methodId', data.methodId);
        }

        url = appendParamToURL(url, 'addressId', data.addressId);

        let promise = ajax.load({ url: url });

        promise.then((r) => data.callback(r));
    }

    editAddressSave(data) {
        this.activeEditSection = null;

        let url = this.config.saveUrl;

        if (data.isBilling) {
            url = appendParamToURL(url, 'isBilling', data.isBilling);
        } else if (data.methodId) {
            url = appendParamToURL(url, 'methodId', data.methodId);
        }

        if (data.addressId) {
            url = appendParamToURL(url, 'addressId', data.addressId);
        }

        let promise = ajax.getJson({
            url: url,
            type: 'POST',
            data: data.form
        });

        promise.then(response => {
            data.callback(response);

            this.updateList();
        });
    }

    removeAddressStart(data) {
        this.toggleSections();
        this.activeRemoveSection = data;
    }

    removeAddressCancel() {
        this.activeRemoveSection = null;
    }

    removeAddressConfirm(data) {
        this.activeRemoveSection = null;

        let url = this.config.deleteUrl;

        url = appendParamToURL(url, 'addressId', data.id);
        url = appendParamToURL(url, 'isDefault', data.isDefault);

        let promise = ajax.getJson({ url: url });

        promise.then(() => this.updateList());
    }

    updateList() {
        let url = this.config.updateUrl;

        let promise = ajax.getJson({ url: url });

        promise.then(response => {
            this.updateTemplates(response);
            this.toggleSections();
        });
    }

    updateTemplates(response) {
        let list = response.addressList;

        this.replaceHtml(
            this.shippingAddressTemplate({
                list: list.shipping,
                preferred: response.addressList.preferred
            }),
            this.$shippingContainer
        );
        this.replaceHtml(this.billingAddressTemplate({ list: list.billing }), this.$billingContainer);
    }

    toggleSections() {
        if (this.activeAddSection) {
            this.activeAddSection.onCancel();
            this.activeAddSection = null;
        }

        if (this.activeEditSection) {
            this.activeEditSection.onEditCancel();
            this.activeEditSection = null;
        }

        if (this.activeRemoveSection) {
            this.activeRemoveSection.onRemoveCancel();
            this.activeRemoveSection = null;
        }

        this.emitter.emit('checkout.shipping.infoblock.hide');
    }

    checkDefaultAddress() {
        this.iterateNestedComponents(cmp => {
            if (cmp.config.cmp === 'address' && !cmp.config.isBilling) {
                if (cmp.config.id === cmp.config.preferred) {
                    cmp.$radioButton.prop('checked', true);
                }
            }
        });
    }
}
