import Component from 'client/core/Component';
import ajax from 'client/utils/ajax';
import $ from 'jquery';
import templatesManager from 'client/utils/templatesManager';
import { CLASSES } from 'client/utils/globals';

const commonConfig = {
    isClosable: true
};

const $body = $('body');

const STATE_CLASSES = {
    modalOpened: 'js-modal-opened'
};

const DEFAULT_ID = 'ModalContainer';

const SELECTORS = {
    modal: '.js-modal',
    btn: '.js-modal-button',
    modalOpen: '.h-modal-open',
    modalContainer: '[data-cmp="modalContainer"]',
    modalCloseButton: '.js-close-btn'
};

const DEFAULT_CONFIG = {
    isDialogLink: true, // whether a click on the element should trigger showing the modal
    delay: 0,
    allowSoftClosing: true
};

export default class Modal extends Component {
    init () {
        this.isOpen = false;

        this.config = Object.assign({}, DEFAULT_CONFIG, this.config);

        if (this.config.autoOpen) {
            setTimeout(this.showModal.bind(this), this.config.delay);
        }

        this.config.isDialogLink && this.bindEvent('click', this.showModal.bind(this));
        this.emitter.addListener('modal.close', this.close.bind(this));
    }

    get modalContainerID() {
        return this.config.id || DEFAULT_ID;
    }

    getModalContainer () {
        return $(document).find(`${SELECTORS.modalContainer}[data-id="${this.modalContainerID}"]`);
    }

    showModal (el, e) {
        e && e.preventDefault();

        let config = this.config;
        let params = {};

        Object.keys(config).forEach((key) => {
            let selectorPos = key.indexOf('Selector');

            if (~selectorPos) {
                params[key.substr(0, selectorPos)] = $(document).find(config[key]).html();
            } else {
                params[key] = config[key];
            }
        });

        if (this.config.id) {
            this.emitEvent('modal.open', { id: this.config.id, params: params });
        }

        if (params.targetUrl) {
            ajax.load({
                url: params.targetUrl
            }).then((response) => {
                params.body = response;
                this.open(params);
            });
        } else {
            this.open(params);
        }
    }

    create (params) {
        let body = params.body;

        if (params.template) {
            let temp = templatesManager.templates[params.template];

            if (temp) {
                if (params.templateAttrs) {
                    try {
                        body = temp(params.templateAttrs);
                    } catch (e) {
                        body = temp();
                    }
                } else {
                    body = temp();
                }
            }
        }

        return templatesManager.templates.modalTemplate({
            content: params.content,
            title: params.title,
            body: body,
            buttons: params.buttons,
            cssClass: params.cssClass,
            isClosable: params.isClosable
        });
    }

    buttons (params) {
        if (!this.$modalContainer) {
            return;
        }

        var self = this;

        (params.buttons || []).forEach(button => {
            this.$modalContainer.on('click', (button.selector || SELECTORS.btn), function(e) {
                typeof button.callback === 'function' && button.callback(e, self, $(this));
            });
        });
    }

    open (params) {
        // close any open dialog
        this.close();
        this.isOpen = this.openWithContent($.extend(true, {}, commonConfig, params));
        this.isOpened(this.isOpen);
        if (this.isOpen) {
            this.emitter.emit('disabledScroll');
            $body.addClass(CLASSES.modalOpened);

            let $modalContainer = this.getModalContainer();

            if (this.config.updateHeightEvent) {
                $modalContainer.data('updateHeightEvent', this.config.updateHeightEvent);
            }

            if (this.config.contentSelector) {
                $modalContainer.data('contentSelector', this.config.contentSelector);
            }

            this.emitter.emit('namespace.component.reinit', $modalContainer, {
                carousels: false
            });
            this.emitEvent('modal.open');
        }

        this.$modalContainer && this.$modalContainer.find(SELECTORS.modalCloseButton).focus();
    }

    openWithContent (params) {
        if (params.template) {
            return this.openTemplate(params);
        }

        var content = params.body || params.content;

        if (!content) {
            return false;
        }

        var possibleContent = null;

        if (typeof content === 'string') {
            possibleContent = $($.parseHTML(content));
        } else {
            possibleContent = $(content);
        }

        if (!possibleContent.hasClass('js-modal') && possibleContent.hasClass('modal')) {
            //do not support non js-based modals
            return false;
        }

        this.createWrapper();

        if (possibleContent.hasClass('modal')) {
            this.$modalContainer.html(content);
        } else {
            this.$modalContainer.append(this.create(params));
        }

        //process buttons
        this.buttons(params);
        this.disableClosability(params);
        this.createModal(params);

        return true;
    }

    createWrapper() {
        this.$modalContainer = $('<div>')
            .attr('data-id', this.modalContainerID)
            .attr('data-cmp', 'modalContainer')
            .attr('data-allow-soft-closing', this.config.allowSoftClosing)
            .appendTo('body');
    }

    disableClosability(params) {
        //disable all kind of closability
        if (!params.isClosable) {
            if (!params.options) {
                params.options = {};
            }

            params.options.backdrop = 'static';
            params.options.keyboard = false;
        }
    }

    createModal(params) {
        let modal = this.$modalContainer.find(SELECTORS.modal);

        modal.modal(params.options);

        this.addEvents(modal);

        return modal;
    }

    addEvents(modal) {
        modal.on('hidden.bs.modal', () => {
            if (!this.$modalContainer) {
                return;
            }

            this.emitter.emit('namespace.component.destroyall', this.$modalContainer);
            this.$modalContainer.remove();
            this.$modalContainer = null;
            this.emitter.emit('enabledScroll');
            this.isOpened(false);
            this.emitEvent('modal.close');
        });

        modal.on('shown.bs.modal', () => {
            this.emitEvent('modal.open');
        });
    }

    openTemplate(params) {
        this.createWrapper();

        this.$modalContainer.append(this.create(params));

        //process buttons
        this.buttons(params);
        this.disableClosability(params);
        this.createModal(params);

        return true;
    }

    close (params) {
        if (params && params.id) {
            if (params.id === this.config.id) {
                this.emitEvent('modal.closed', params);
            } else {
                return;
            }
        }

        this.$modalContainer && this.$modalContainer.find(SELECTORS.modal).modal('hide');
        this.emitter.emit('enabledScroll');
        $body.removeClass(CLASSES.modalOpened);
        this.isOpened(false);
    }

    exists () {
        return this.$modalContainer && (this.$modalContainer.length > 0);
    }

    isActive () {
        return this.exists() && (this.$modalContainer.children.length > 0);
    }

    // Are there in general any opened modals
    isThereActive () {
        return $body.hasClass(STATE_CLASSES.modalOpened);
    }

    isOpened (state) {
        this.isOpen = state;

        $body[state ? 'addClass' : 'removeClass'](STATE_CLASSES.modalOpened);
    }

    emitEvent (event, data) {
        this.emit(event, data);
        this.emitter.emit(event, data);
    }
}

