import Component from 'client/core/Component';
import $ from 'jquery';

import ajax from 'client/utils/ajax';
import { isAuthenticated, isWishListOnlyRegisteredEnabled, isWishListEnabled } from 'sitePreferences';
import { wishlistAddButton } from 'urls';
import { appendParamsToURL } from 'client/utils/url';

const SELECTORS = {
    btn: '.js-wish-list-btn',
    title: '.js-btn-pdp-wish-list-title',
    selectedSize: '.js-size-value.b-swatch-value--selected'
};

const CLASSES = {
    btnAction: 'js-wish-list--',
    hideBtn: 'h-in-wishlist-btn'
};

export default class AddToWishListButton extends Component {
    onVariationUpdate(product) {
        if (product.wishlist) {
            const { itemExists, pid } = product.wishlist;

            if (pid) {
                const $btn = this.$elements.btn;
                const hrefRemove = $btn.data('href-remove');

                if (hrefRemove && Array.isArray(hrefRemove)) {
                    const removeUrl = hrefRemove.split('?pid=')[0] + '?pid=' + pid;

                    $btn.data('href-remove', removeUrl);
                }

                $btn.data('pid', pid);

                if (itemExists) {
                    this.toggleBtn('add', 'remove');
                } else {
                    this.toggleBtn('remove', 'add');
                }
            }
        }
    }

    toggleBtn(from, to) {
        const $btn = this.$elements.btn;

        this.$elements.title.html($btn.data(`title-${to}`));

        $btn.removeClass(`${CLASSES.btnAction}${from}`).addClass(`${CLASSES.btnAction}${to}`);

        if (to === 'add') {
            $btn.removeClass(CLASSES.hideBtn);
        } else {
            $btn.addClass(CLASSES.hideBtn);
        }
    }

    onAdded(resp) {
        const $btn = this.$elements.btn;

        this.emitter.emit('wishlist.added', Object.assign({ addedFrom: $btn.data('page') }, resp));

        this.toggleBtn('add', 'remove');
    }

    onRemoved(resp) {
        const $btn = this.$elements.btn;

        this.emitter.emit('wishlist.removed', Object.assign({ addedFrom: $btn.data('page') }, resp));

        this.toggleBtn('remove', 'add');
    }

    addProduct(params) {
        var pid = params.pid,
            url = params.url;

        ajax.getJson({
            url: url,
            type: 'POST',
            data: {
                pid
            }
        }).then((resp) => {
            if (resp && (resp.success || resp.exists)) {
                this.onAdded(resp);
            }
        });
    }

    removeProduct(url) {
        ajax.getJson({
            url: url
        }).then((resp) => {
            if (resp && resp.success) {
                this.onRemoved(resp);
            }
        });
    }

    // eslint-disable-next-line consistent-return
    onBtnClick() {
        const $btn = this.$elements.btn,
            action = $btn.hasClass(`${CLASSES.btnAction}add`) ? 'add' : 'remove',
            selectedSizeItem = $(SELECTORS.selectedSize).parent(),
            sizeAttr = selectedSizeItem.data('variant-id'),
            params = {
                url: $btn.data(`href-${action}`),
                pid: (selectedSizeItem.length > 0 ? sizeAttr : $btn.data('pid')),
                callback: this.addProduct.bind(this)
            };


        if (!isAuthenticated) {
            this.emitter.emit('wishlist.clicked', params);
        }

        const hrefRemove = $btn.data('href-remove');

        if (hrefRemove) {
            const removeUrl = hrefRemove.split('?pid=')[0] + '?pid=' + params.pid;

            $btn.data('href-remove', removeUrl);
        }

        $btn.data('pid', params.pid);

        if (action === 'add') {
            if (!isAuthenticated && isWishListOnlyRegisteredEnabled) {
                this.emitter.emit('login.popup.show', params);
                return true;
            }

            params.pid && this.addProduct(params);
        } else {
            this.removeProduct(params.url);
        }
    }

    loadTemplate() {
        const url = appendParamsToURL(wishlistAddButton, { pid: this.config.pid, quickView: this.config.quickview });

        ajax.load({
            url: url,
            async: false
        }).then((resp) => {
            if (resp) {
                this.appendHtml(resp);

                this.$elements = {
                    btn: this.$el.find(SELECTORS.btn)
                };

                this.$elements.title = this.$elements.btn.find(SELECTORS.title);
            }
        });
    }

    init() {
        if (!isWishListEnabled) {
            return;
        }

        this.loadTemplate();

        this.emitter.addListener('variation.updated', (product) => this.onVariationUpdate(product));
        this.emitter.addListener('add.product.wishlist', (params) => this.addProduct(params));

        this.bindEvent('click', SELECTORS.btn, this.onBtnClick);
    }
}
