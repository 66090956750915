import Component from 'client/core/Component';
import templatesManager from 'client/utils/templatesManager';

export default class FindInStoreProductPanel extends Component {
    init () {
        this.$priceSection = this.$el.find('.js-price');

        this.template = templatesManager.templates.findInStoreVariantPrice;

        this.addListenerOnChild(this.config.sizeCmp, 'changed', this.onSizeChanged.bind(this));
    }

    onSizeChanged (newVal) {
        var variantIDs = Object.keys(this.config.prices),
            price = null;

        for (var i = 0, l = variantIDs.length; i < l; i++) {
            if (variantIDs[i] === newVal) {
                price = this.config.prices[variantIDs[i]].price;
            }
        }

        price && this.$priceSection.html(this.template(price));
    }
}
