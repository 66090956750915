/**
 * @param {number} value
 * @param {number} rate
 * @returns {number}
 */
exports.coinsToCurrencyAmount = function (value, rate) {
    return (value * rate) / 100;
};

/**
 * @param {number} value
 * @param {number} rate
 * @returns {number}
 */
exports.currencyAmountToCoins = function (value, rate) {
    return Math.floor(value * rate * 100);
};
