import CartComponent from 'client/components/cart/CartComponent';

export default class OrderSummary extends CartComponent {
    update(resp) {
        if (resp && resp.order) {
            let result = this.template(resp.order);

            this.replaceHtml(result, this.$target);
        }
    }

    trackCheckout() {
        if (!this.config.intloginEnabled) {
            this.emitter.emit('gtmCheckoutOptionsEvent', 2, this.config.login);
        }
    }

    init() {
        super.init();

        this.emitter.addListener('step.shipping.updated', (r) => this.update(r));
        this.emitter.addListener('step.payment.updated', (r) => this.update(r));
        this.emitter.addListener('cart.coupon.applied', (r) => this.update(r));
        this.emitter.addListener('action.summary.update', (r) => this.update(r));

        if (this.gtmEnabled) {
            this.trackCheckout();

            if (this.config.gtmShippingMethodId) {
                this.emitter.emit('gtmCheckoutOptionsEvent', 3, this.config.gtmShippingMethodId);
            }

            if (this.config.gtmPaymentMethodId) {
                this.emitter.emit('gtmCheckoutOptionsEvent', 4, this.config.gtmPaymentMethodId);
            }
        }
    }
}
