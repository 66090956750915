import Component from 'client/core/Component';
import { CLASSES } from 'client/utils/globals';

const SELECTORS = {
    'MORE_BTN': '.js-more-less-expand',
    'LESS_BTN': '.js-more-less-collapse',
    'MORE_ITEMS': '.js-more-item',
    'LESS_ITEMS': '.js-less-item',
    'MORE_LESS_CONTAINER': '.js-more-less-container'
};

export default class MoreLessFilter extends Component {
    init() {
        this.$moreBtn = this.$el.find(SELECTORS.MORE_BTN);
        this.$lessBtn = this.$el.find(SELECTORS.LESS_BTN);
        this.$moreItems = this.$el.find(SELECTORS.MORE_ITEMS);
        this.$lessItems = this.$el.find(SELECTORS.LESS_ITEMS);

        this.bindEvent('click', SELECTORS.MORE_BTN, () => this.showFilters());
        this.bindEvent('click', SELECTORS.LESS_BTN, () => this.hideFilters());
    }

    hideFilters() {
        if (this.$moreBtn.hasClass(CLASSES.hide)) {
            this.$moreBtn.removeClass(CLASSES.hide);
            this.$moreItems.addClass(CLASSES.hide);
        }
        this.$lessBtn.addClass(CLASSES.hide);
        this.$lessItems.addClass(CLASSES.hide);
    }

    showFilters() {
        if (this.$lessBtn.hasClass(CLASSES.hide)) {
            this.$lessBtn.removeClass(CLASSES.hide);
            this.$lessItems.removeClass(CLASSES.hide);
        }
        this.$moreBtn.addClass(CLASSES.hide);
        this.$moreItems.removeClass(CLASSES.hide);
    }
}
