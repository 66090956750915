
import { GTM_EVENT_CATEGORIES, COOKIE } from 'client/utils/globals';
import { hasCookie, getCookie } from 'client/utils/cookie';
import prefs from 'sitePreferences';

export function getFavouriteStoreAttrValue() {
    const cookieName = COOKIE.favouriteStore;
    let cookieValue;

    if (hasCookie(cookieName)) {
        cookieValue = getCookie(cookieName);
    }

    return cookieValue || 'none';
}

const mappedTrackingArr = [{
    eventActions: ['pdp/preselected-store'],
    eventLabel: getFavouriteStoreAttrValue,
    eventNonInteraction: true
},
{
    eventActions: ['pdp/preselected-store (online only)'],
    eventLabel: () => 'none',
    eventNonInteraction: true
},
{
    eventActions: ['pdp-layer/open'],
    eventLabel: getFavouriteStoreAttrValue,
    eventNonInteraction: false
},
{
    eventActions: ['pdp-layer/select-store/default', 'pdp-layer/select-store/user'],
    eventLabel: getFavouriteStoreAttrValue,
    eventNonInteraction: false
},
{
    eventActions: ['pdp-layer/set-favorite-store', 'pdp-layer/remove-favorite-store'],
    eventLabel: 'storeID',
    eventNonInteraction: false
},
{
    eventActions: ['pdp-layer/product-availability'],
    eventLabel: () => 'none-within-selected-radius',
    eventNonInteraction: false
}];

const getBopisEventLabelForTrack = (bopisTrackingInfo, mappedTrackingInfo) => {

    if (bopisTrackingInfo.storeID) {
        return bopisTrackingInfo.storeID;
    }

    if (typeof mappedTrackingInfo.eventLabel === 'function') {
        return mappedTrackingInfo.eventLabel();
    }

    if (typeof mappedTrackingInfo.eventLabel === 'string') {
        return bopisTrackingInfo[mappedTrackingInfo.eventLabel];
    }

    return null;
};

export const trackBopisStoreData = (bopisTrackingInfo) => {
    if (prefs.isBopisEnabled) {
        const context = bopisTrackingInfo.context;
        const mappedTrackingInfo = mappedTrackingArr.find(
            item => item.eventActions.includes(bopisTrackingInfo.eventAction)
        );
        const isOpenLayer = bopisTrackingInfo.eventAction === 'pdp-layer/open';

        if (!mappedTrackingInfo) {
            return;
        }

        const data = {
            eventCategory: GTM_EVENT_CATEGORIES.bopis,
            eventAction: bopisTrackingInfo.eventAction,
            eventLabel: getBopisEventLabelForTrack(bopisTrackingInfo, mappedTrackingInfo) || '',
            eventNonInteraction: mappedTrackingInfo.eventNonInteraction,
            ga4eventname: isOpenLayer ? 'pdp_store_layer' : 'pdp_store_info'
        };

        if (context && context.emitter && context.emitter.emit) {
            context.emitter.emit('gtmEvent', data);
        }
    }
};
