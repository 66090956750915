import Component from 'client/core/Component';
import { isManageShopTheLookByConsent } from 'sitePreferences';

import { CLASSES } from 'client/utils/globals';

const SELECTORS = {
    acceptBtn: '.js-apply-consent-modal-btn'
};

export default class ConsentModal extends Component {
    init () {
        if (isManageShopTheLookByConsent && !window.consentStatus) {
            this.$el.removeClass(CLASSES.hide);
            this.bindEvent('click', SELECTORS.acceptBtn, this.accept);
            this.emitter.addListener('apply.consent', this.closeModal.bind(this));
        }
    }

    accept() {
        this.$el.addClass(CLASSES.hide);
        window.dispatchEvent(new CustomEvent('apply.consent.cookie', { detail: { consentStatus: true } }));
    }

    closeModal() {
        this.$el.addClass(CLASSES.hide);
    }
}
