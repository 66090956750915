import $ from 'jquery';

const SELECTORS = {
    header: '.js-header'
};

const SCROLL_TO_MSG_OFFSET = -10;

export function scrollTo(topOffsetOrElement, options = {}) {
    const $scrollElement = $('html, body'),
        scrollToElement = typeof topOffsetOrElement === 'object';

    let topOffset = 0,
        optOffset = typeof options.offset === 'undefined' ? 0 : options.offset,
        headerHeight = 0;

    if (topOffsetOrElement) {
        if (scrollToElement) {
            let headerCmp = $(SELECTORS.header).data('cmp-instance');

            if (headerCmp) {
                if (headerCmp.isSticky) {
                    headerHeight = headerCmp.headerHeight.sticky;
                } else {
                    headerHeight = headerCmp.headerHeight.regular;
                }
            }

            topOffset = topOffsetOrElement.offset().top - headerHeight;
        } else {
            topOffset = topOffsetOrElement;
        }
    }

    const scrollTopOffset = topOffset + optOffset;

    $scrollElement.animate({
        scrollTop: (scrollTopOffset > 0) ? scrollTopOffset : 0
    }, {
        duration: typeof options.duration === 'undefined' ? 500 : options.duration,
        complete: () => {
            if (typeof options.callback === 'function') {
                options.callback();
            }
        }
    });
}

/**
 * @param {String} url
 */
export function redirectTo(url) {
    // @ts-ignore
    window.location.href = url;
}

/**
 * Detect if an element is visible in the view port
 *
 * @param {jQuery} $element
 * @param checkWidth
 * @returns {boolean}
 */
export function isInViewport($element, checkWidth = false) {
    // @ts-ignore
    const elementTop = $element.offset().top;
    // @ts-ignore
    const elementBottom = elementTop + $element.outerHeight();

    let headerCmp = $(SELECTORS.header).data('cmp-instance'),
        headerHeight = 0;

    if (headerCmp) {
        if (headerCmp.isSticky) {
            headerHeight = (headerCmp.headerHeight && headerCmp.headerHeight.sticky) || 0;
        } else {
            headerHeight = (headerCmp.headerHeight && headerCmp.headerHeight.regular) || 0;
        }
    }

    const scrollTop = $(window).scrollTop(),
        viewportTop = scrollTop + headerHeight,
        viewportBottom = scrollTop + $(window).height();

    let inViewport = ((elementBottom >= viewportTop) && (elementBottom <= viewportBottom)) ||
        ((elementTop <= viewportBottom) && (elementTop >= viewportTop));

    if (inViewport && checkWidth) {
        // @ts-ignore
        const elementLeft = $element.offset().left;
        // @ts-ignore
        const elementRight = elementLeft + $element.outerWidth();

        const viewportLeft = 0;
        const viewportRight = $(window).width();

        inViewport = ((elementRight >= 0) && (elementRight <= viewportRight)) ||
            ((elementLeft <= viewportRight) && (elementLeft >= viewportLeft));
    }

    return inViewport;
}

/**
 * Returns the current date as formatted string
 *
 * @returns {string}
 */
export function getCurrentDateAsString() {
    const today = new Date(),
        dd = String(today.getDate()).padStart(2, '0'),
        mm = String(today.getMonth() + 1).padStart(2, '0'),
        yyyy = today.getFullYear();

    return `${dd}.${mm}.${yyyy}`;
}

/**
 * Scrolls to a given element with a predefined vertical offset
 *
 * @param {jQuery} $element
 */
export function scrollToMsg($element) {
    if (!isInViewport($element)) {
        scrollTo($element, {
            offset: SCROLL_TO_MSG_OFFSET
        });
    }
}

/**
 * Check a given date as a string on validity
 *
 * @param {String} date
 * @returns {boolean}
 */
export function checkDate(date) {
    let minDate = new Date('12/31/1900');
    let maxDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    let currentDate = new Date(date);
    let isValid = false;

    if (currentDate !== null && currentDate <= maxDate && currentDate > minDate) {
        isValid = true;
    }

    return isValid;
}

/**
 * Validate a given date as a string by mask (DD.MM.YYYY) and validity
 *
 * @param {String} value
 * @returns {boolean}
 */
export function validateDate(value) {
    let rule = '^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$'; // eslint-disable-line no-useless-escape
    let regEx = new RegExp(rule);

    let isValid = regEx.test(value);

    if (isValid) {
        let [dd, mm, yyyy] = value.split('.');

        isValid = checkDate(`${yyyy}-${mm}-${dd}`);
    }

    return isValid;
}

/**
 * Checks if 'c_app' parameter present in location URL
 * @param {string} paramName
 * @typedef {Object} AppParam
 * @property {string} name
 * @property {string} value
 * @returns {AppParam | undefined}
 */

// eslint-disable-next-line consistent-return
export function getAppParam(paramName) {
    // @ts-ignore
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has(paramName)) {
        return {
            name: paramName,
            value: urlParams.get(paramName)
        };
    }
}

/**
 * Retuns array of mobile App required params
 * @returns {Array<string>}
 */
export function getAppRequiredParams() {
    // @ts-ignore
    const appRequiredParams = window.SitePreferences.appRequiredParams || '';

    return appRequiredParams.split(',');
}

/**
 * Updates all links on the page with App required params
 * @param {Element=} el
 * @param {AppParam[]} params
 * @returns {void}
 */

export function addAppParamsToAllLinks(params, el) {
    // @ts-ignore
    const $rootEl = $(el || document);
    // @ts-ignore
    const excludedDomains = Object.values(window.SitePreferences.excludedDomainsForAppParam);

    $rootEl.find('a').each(function() {
        const $linkEl = $(this);
        const linkHostName = $linkEl.prop('hostname');
        let hrefAttrVal = $linkEl.attr('href');

        if (hrefAttrVal && hrefAttrVal !== '#' && !excludedDomains.includes(linkHostName)) {
            params.forEach((param) => {
                const paramWithValue = `${param.name}=${param.value}`;

                if (!hrefAttrVal.includes(paramWithValue)) {
                    if (hrefAttrVal.includes('?')) {
                        hrefAttrVal += `&${paramWithValue}`;
                    } else {
                        hrefAttrVal += `?${paramWithValue}`;
                    }
                    $linkEl.attr('href', hrefAttrVal);
                }
            });
        }

    });
}
