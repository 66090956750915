import Component from 'client/core/Component';
import $ from 'jquery';
import { scrollTo } from 'client/utils/common';
import { throttle } from 'lodash';

const
    SELECTORS = {
        backToTop: '.js-back-to-top'
    };

export default class BackToTop extends Component {
    init () {
        this.innerHeight = 0;
        this.viewPortHeight = 0;
        this.isBackToTopSticky = false;
        this.calculateViewPort();
        this.calculatePosition({ scrollTop: $(window).scrollTop() });
        this.emitter.addListener('scroll', throttle(this.calculatePosition.bind(this), 200));
        this.emitter.addListener('deviceChange', this.calculateViewPort.bind(this));
        this.bindEvent('click', SELECTORS.backToTop, () => {
            this.emitter.emit('back.to.top');
            scrollTo(0, { duration: 0 });
        });

        if (window.ReactNativeWebView) {
            this.$el.find(SELECTORS.backToTop).addClass('h-back-button-app');
        }
    }

    calculateViewPort() {
        this.innerHeight = window.innerHeight;
        this.viewPortHeight = this.innerHeight * 0.85;
    }

    calculatePosition ({ scrollTop: scrollTop } = { scrollTop: 0 }) {
        if (!this.isBackToTopSticky && scrollTop > this.viewPortHeight) {
            this.isBackToTopSticky = true;
            this.$el.addClass(this.config.stickyClass);
        } else if (this.isBackToTopSticky && scrollTop <= this.viewPortHeight) {
            this.isBackToTopSticky = false;
            this.$el.removeClass(this.config.stickyClass);
        }
    }
}

