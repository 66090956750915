import Component from 'client/core/Component';
import { scrollTo } from 'client/utils/common';

import $ from 'jquery';

const SELECTORS = {
    reviewAnchor: '.js-product-reviews-wrapper'
};

export default class ProductReviewAnchor extends Component {
    init() {
        this.$reviewAnchorElement = $(SELECTORS.reviewAnchor);
        this.bindEvent('click', this.$el, this.scrollToAnchor);
    }

    scrollToAnchor(e, event) {
        event.preventDefault();
        scrollTo(this.$reviewAnchorElement);
    }
}
