import Form from 'client/core/Form';

import ajax from 'client/utils/ajax';
import { CLASSES } from 'client/utils/globals';
import { scrollToMsg, getCurrentDateAsString, validateDate } from 'client/utils/common';

import inputmask from 'inputmask';


const SELECTORS = {
    error: '.js-error',
    email: '[data-id="{FORM}_email"]',
    emailconfirm: '[data-id="{FORM}_emailconfirm"]',
    password: '[data-id="{FORM}_password"]',
    successMsg: '.js-success-message'
};

const INPUTS = {
    birthday: '[name="{FORM}_birthday"]'
};

class ProfileEditForm extends Form {
    toggleError(msg = '') {
        this.$.error.html(msg);
        this.$.error.toggleClass(CLASSES.hide, !msg);

        if (msg) {
            scrollToMsg(this.$.error);
        }
    }

    setFieldError(field, message) {
        let fld = field.match(this.FIELD_PATTERN);

        let cmp = this.$el.find(`#${fld}`).closest('[data-cmp]').data('cmp-instance');

        cmp && cmp.setError && cmp.setError(message);
    }

    showFieldErrors(fields) {
        if (typeof fields === 'object') {
            for (let key of Object.keys(fields)) {
                this.setFieldError(key, fields[key]);
            }
        }
    }

    onSubmit(el, ev) {
        this.$.successMsg.addClass('h-hide');
        ev.preventDefault();

        const isValid = super.onSubmit();

        if (isValid) {
            const $ProfileEditForm = this.$el,
                url = $ProfileEditForm.attr('action'),
                data = $ProfileEditForm.serializeArray();

            ajax.getJson({
                url: url,
                type: 'POST',
                data
            }).then((resp) => {
                this.toggleError(resp.errorMessage || '');

                if (resp.success) {
                    this.$.successMsg.removeClass('h-hide');
                    scrollToMsg(this.$.successMsg);
                    this.clearAuthData();
                } else if (resp.fields && !resp.errorMessage) {
                    this.showFieldErrors(resp.fields);
                }
            });
        }

        return isValid;
    }

    clearAuthData() {
        this.$.emailconfirm.data('cmp-instance').setValue('');
        this.$.emailconfirm.data('cmp-instance').clearError();
        this.$.emailconfirm.data('cmp-instance').placeholderToggle();
        this.$.password.data('cmp-instance').setValue('');
        this.$.password.data('cmp-instance').clearError();
        this.$.password.data('cmp-instance').placeholderToggle();
    }

    onValidateEmailConfirm(cmp) {
        const emailCmp = this.$el.find(this.$.email).data('cmp-instance');

        return (emailCmp.getValue().toLowerCase() === cmp.getValue().toLowerCase());
    }

    onValidatePasswordConfirm(cmp) {
        const passwordCmp = this.$el.find(this.$.password).data('cmp-instance');

        return (passwordCmp.getValue() === cmp.getValue());
    }

    onValidateBirthday(cmp) {
        return validateDate(cmp.getValue());
    }

    resolveSelector(selector) {
        return selector.replace('{FORM}', this.form);
    }

    init() {
        this.form = this.$el.data('form');

        this.FIELD_PATTERN = new RegExp(`^${this.form}_(.+)$`, 'i');

        this.$ = {};

        for (let k of Object.keys(SELECTORS)) {
            this.$[k] = this.$el.find(this.resolveSelector(SELECTORS[k]));
        }

        inputmask(
            'datetime',
            {
                inputFormat: 'dd.mm.yyyy',
                placeholder: '_',
                min: '01.01.1901',
                max: getCurrentDateAsString()
            }
        )
            .mask(this.resolveSelector(INPUTS.birthday));
    }
}

export default ProfileEditForm;
