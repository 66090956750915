import StoreFinderMapMgr from 'client/components/StoreFinderMapMgr';
import templatesManager from 'client/utils/templatesManager';
import prefs from 'sitePreferences';
import $ from 'jquery';
import { trackBopisStoreData } from 'client/utils/storefinderHelper';

const SELECTORS = {
    'ACCORDION_ITEM': '.js-accordion-item',
    'MAP_ITEM_BODY': '.js-find-in-store-data-list-item-body',
    'STORE_INFO': '.js-store-info',
    'MAP_IFRAME': '.js-canvas iframe'
};

export default class FindInStoreMapResult extends StoreFinderMapMgr {
    init () {
        this.template = templatesManager.templates.findInStoreMapResult;
        this.bindEvent(
            'click',
            SELECTORS.STORE_INFO,
            (e, ev, params) => {
                ev.preventDefault();

                const data = e.dataset;
                let counter = 1;

                const showGoogleMap = (clickedElement) => {
                    if (counter === 10) {
                        return;
                    }

                    counter++;

                    const isGoogleMapContentVisible = this.isGoogleMapContentVisible(clickedElement);

                    if (data.mapCmpId && isGoogleMapContentVisible) {
                        this.emit('event.store.selected', {
                            storeID: data.mapCmpId
                        });
                    } else {
                        setTimeout(() => showGoogleMap(e), counter * 10);
                    }
                };

                showGoogleMap(e);
                trackBopisStoreData({
                    context: this,
                    eventAction: (params && params.isAuto) ?
                        'pdp-layer/select-store/default' : 'pdp-layer/select-store/user',
                    storeID: data.mapCmpId
                });
            },
            {
                unbindWithoutHandler: true
            }
        );
    }

    isGoogleMapContentVisible(clickedElement) {
        const $accordionItem = $(clickedElement).closest(SELECTORS.ACCORDION_ITEM);
        const $mapItemContent = $accordionItem.find(SELECTORS.MAP_ITEM_BODY);
        const isMapItemContentVisible = $mapItemContent &&
                                        $mapItemContent.length &&
                                        $mapItemContent.is(':visible') &&
                                        $(SELECTORS.MAP_IFRAME).is(':visible');
                                        //$(SELECTORS.MAP_IFRAME) should not be cached because map loaded after component init.

        return isMapItemContentVisible;
    }

    async showResults (data = []) {
        data.sort((a, b) => {
            return (+a.distance || 0) - (+b.distance || 0);
        });

        const params = {
            stores: data,
            isBopisEnabled: prefs.isBopisEnabled,
            currentFavouriteStoreID: await this.getFavouriteStoreAttrValue('DATA_FAVOURITE_STORE_ID')
        };

        this.$el.html(this.template(params));
        this.emitter.emit('namespace.component.reinit', this.$el);
    }
}
