import Component from 'client/core/Component';
import { compile } from 'handlebars';

const SELECTORS = {
    target: '.js-target',
    source: '.js-source'
};

export default class ImageStickyPanel extends Component {
    init() {
        this.$target = this.$el.find(SELECTORS.target);
        this.source = this.$el.find(SELECTORS.source).html();
        this.template = compile(this.source);

        this.emitter.addListener(
            'variation.updated',
            (product, queryParams) => this.updateImageStickyPanel(product, queryParams)
        );
    }

    updateImageStickyPanel(product, queryParams) {
        if (queryParams.chosen !== 'color') {
            return;
        }

        let result = this.template(product);

        this.replaceHtml(result, this.$target);
    }
}
