import Refinement from 'client/components/search/Refinement';
import $ from 'jquery';
import { FILTER_BUTTON_LABEL_SHOW_ALL_RESULTS, FILTER_BUTTON_LABEL_LOADING } from 'resources';

const SELECTORS = {
    'VALUE': '.js-refinement-value',
    'SELECTED_CLASS': 'selected',
    'SEARCH_INPUT': '.js-brand-search',
    'VALUES_CONTAINER': '.js-refinemnents-container',
    'SHOW_ALL_RESULTS': '.js-show-all-result-button'
};

export default class AttributeRefinement extends Refinement {
    init() {
        super.init();

        this.$showAllResultButton = $(SELECTORS.SHOW_ALL_RESULTS);
        this.initialState = this.getSelectedElements();
        this.selectedElements = this.getSelectedElements();
        this.$items = this.$el.find(SELECTORS.VALUE);

        this.bindEvent('click', SELECTORS.VALUE, (el, ev) => this.changeRefinementState(el, ev));

        if (this.config.attr === 'brand') {
            this.bindEvent('keyup', SELECTORS.SEARCH_INPUT, (el) => this.searchBrand(el));
        }
        this.emitter.addListener('showAllResultsButton.lock', data => this.changeShowAllResultButtonStatus(data));
    }

    onRefinementChange(data) {
        if (this.config.attr === data.attr && this.config.device !== data.device) {
            let $el = this.$el.find(`[data-id="${data.elementID}"]`);

            if ($el.length > 0) {
                this.toggleState(data.id, $el);
            }
        }
    }

    /**
     * @returns {Map}
     */
    getSelectedElements() {
        let selectedElements = new Map(), // eslint-disable-line no-undef
            $values = this.$el.find(SELECTORS.VALUE);

        $values.each((index, element) => {
            let $element = $(element);

            if (!$element.data('disabled') && $element.data('selected')) {
                selectedElements.set($element.data('id'), true);
            }
        });

        return selectedElements;
    }

    changeShowAllResultButtonStatus(lock) {
        if (lock) {
            this.$showAllResultButton.html(FILTER_BUTTON_LABEL_LOADING);
            this.$showAllResultButton.prop('disabled', true);
        } else {
            this.$showAllResultButton.html(FILTER_BUTTON_LABEL_SHOW_ALL_RESULTS);
            this.$showAllResultButton.prop('disabled', false);
        }
    }

    applyFilter() {
        let data = {
            attribute: this.config.attr,
            type: 'attribute',
            values: [...this.selectedElements.keys()]
        };

        // Let parent component reload grid with refined search
        this.emitter.emit('refinement.applyFilter', data);
        this.changeShowAllResultButtonStatus(true);
        super.applyFilter();
    }

    changeRefinementState(el, ev) {
        ev.preventDefault();
        let $el = $(el),
            id = $el.data('id');

        if (!$el.data('disabled') && !$el.data('navigation')) {
            if (ev.target.tagName === 'INPUT') {
                ev.target.checked = !ev.target.checked;
            } else {
                this.toggleState(id, $el);

                this.emitter.emit('refinement.selectionChanged', {
                    device: this.device,
                    elementID: id,
                    attr: this.config.attr
                });

                if (!this.config.multiple && this.changed) {
                    this.applyFilter();
                }
            }
        }
    }

    toggleState(id, $el) {
        if (this.selectedElements.has(id)) {
            this.selectedElements.delete(id);
            $el.data('selected', false);
            $el.removeClass(SELECTORS.SELECTED_CLASS);
        } else {
            this.selectedElements.set(id, true);
            $el.data('selected', true);
            $el.addClass(SELECTORS.SELECTED_CLASS);
        }

        this.changed = this.isRefinementStateChanged();
        this.changeButtonState();
    }

    isRefinementStateChanged() {
        if (this.initialState.size !== this.selectedElements.size) {
            return true;
        }

        let changed = false,
            iKeys = this.initialState.keys();

        for (let iKey of iKeys) {
            let found = false,
                cKeys = this.selectedElements.keys();

            for (let cKey of cKeys) {
                if (iKey === cKey) {
                    found = true;
                    break;
                }
            }

            if (!found) {
                changed = true;
                break;
            }
        }

        return changed;
    }

    searchBrand(el) {
        let newValue = $(el).val(),
            $itemsToShow = this.$items.clone();

        if (newValue || this.isRefinementStateChanged()) {
            $itemsToShow = $($itemsToShow.toArray().filter(item => {
                if (this.selectedElements.has($(item).data('id').toLowerCase())) {
                    if (!$(item).hasClass(SELECTORS.SELECTED_CLASS)) {
                        $(item).data('selected', true);
                        $(item).addClass(SELECTORS.SELECTED_CLASS);
                    }
                    return $(item);
                }

                return $(item).data('id').toLowerCase().match(
                    new RegExp(`^${newValue.trim().toLowerCase().replace(/\s/g, '.*')}`, 'gi')
                );
            }));
        }

        let $container = this.$el.find(SELECTORS.VALUES_CONTAINER);

        $container.empty().append($itemsToShow);
    }
}
