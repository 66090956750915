import Component from 'client/core/Component';

import { CLASSES as GLOBAL_CLASSES } from 'client/utils/globals';
const SELECTORS = {
    MAIN_WRAPPER: '.js-main-countdown-wrapper',
    ACTIVE_BLOCK: '.js-countdown-active',
    DEFAULT_BLOCK: '.js-countdown-expired'
};

export default class RaffleCountdownGroup extends Component {
    init() {
        this.addListenerOnChild('raffleCountdown', 'hidden', () => this.toggleVisibility(false));
        this.addListenerOnChild('raffleCountdown', 'shown', () => this.toggleVisibility(true));

        this.callMethodOFNestedComponent('raffleCountdown', 'runCountdown');
    }

    /**
     * @param {boolean} visibility
     */
    toggleVisibility(visibility) {
        this.$el[visibility ? 'removeClass' : 'addClass'](GLOBAL_CLASSES.hide);

        if (this.config.isAssetCountdown) {
            const parentWrapper = this.$el.closest(SELECTORS.MAIN_WRAPPER);

            if (parentWrapper.length) {
                if (visibility) {
                    parentWrapper.find(SELECTORS.ACTIVE_BLOCK).removeClass(GLOBAL_CLASSES.hide);
                    parentWrapper.find(SELECTORS.DEFAULT_BLOCK).addClass(GLOBAL_CLASSES.hide);
                } else {
                    parentWrapper.find(SELECTORS.ACTIVE_BLOCK).addClass(GLOBAL_CLASSES.hide);
                    parentWrapper.find(SELECTORS.DEFAULT_BLOCK).removeClass(GLOBAL_CLASSES.hide);
                }
            }
        }
    }
}
