/* eslint-disable consistent-return */
import AbstractCheckoutStep from 'client/components/checkout/AbstractCheckoutStep';
import { getJson } from 'client/utils/ajax';
import { CLASSES } from 'client/utils/globals';
import $ from 'jquery';
import { appendParamToURL } from 'client/utils/url';
import { replaceState } from 'client/utils/history';
// @ts-ignore
import { CHECKOUT_TECH_ERROR } from 'resources';
// @ts-ignore
import { isRNWebView, sendEventToMobileApp } from 'client/utils/react';

const SELECTORS = {
    PAYMENT_WITH: '.js-place-order-with',
    MOBILE_SUBMIT_BLOCK: '.js-mobile-place-order-block',
    REGISTER_BY_ORDER_CTA: '.js-register-by-order-cta'
};

export default class PlaceOrderStep extends AbstractCheckoutStep {
    init() {
        super.init();

        // @ts-ignore
        this.$document = $(document);
        this.$mobileSubmitBlock = this.$document.find(SELECTORS.MOBILE_SUBMIT_BLOCK);
        this.$elements = {
            registerByOrderCta: this.$el.find(SELECTORS.REGISTER_BY_ORDER_CTA),
            registerByOrderCtaMobile: this.$mobileSubmitBlock.find(SELECTORS.REGISTER_BY_ORDER_CTA)
        };

        this.isCheckRegistration = false;
        this.isCheckoutRegistration = this.$el.data('checkoutRegistration');

        this.emitter.addListener(
            'checkout.registerDuringCheckout.updated',
            (data) => this.isCheckoutRegisterUpdated(data)
        );
        this.emitter.addListener('paymentoption.updated', (r) => this.updateSubmitBtnText(r));
        this.emitter.addListener('step.placeOrder.submit', () => this.onRegistrationSubmit(true));
        this.emitter.emit('resetArvatoTrack');
    }

    onRegistrationSubmit(data) {
        if (this.isCheckoutRegistration) {
            this.emitter.addListener('checkout.registerDuringCheckout.registration.done', () => {
                this.isCheckRegistration = false;
                this.onSubmit(data);
            });

            this.emitter.emit('checkout.registerDuringCheckout.registration');
        } else {
            this.isCheckRegistration = false;
            this.onSubmit(data);
        }
    }

    isCheckoutRegisterUpdated(data) {
        this.isCheckRegistration = !!data.register;
        if (data.register) {
            this.$elements.registerByOrderCta.removeClass('h-hide');
            this.$elements.registerByOrderCtaMobile.removeClass('h-hide');
        } else {
            this.$elements.registerByOrderCta.addClass('h-hide');
            this.$elements.registerByOrderCtaMobile.addClass('h-hide');
        }
    }

    updateSubmitBtnText(paymentOptionText) {
        this.$document.find(SELECTORS.PAYMENT_WITH).html(` ${paymentOptionText}`);
    }

    active(stepIndex, currentStep) {
        super.active(stepIndex, currentStep);
        this.emitter.emit('checkout.placeorder.infoblock.hide');

        this.$mobileSubmitBlock.removeClass(CLASSES.hide);
        this.emitter.emit('checkout.placeorder.step.active');
    }

    inactive(stepIndex, currentStep) {
        super.inactive(stepIndex, currentStep);

        this.$mobileSubmitBlock.addClass(CLASSES.hide);
        this.emitter.emit('checkout.placeorder.step.inactive');
    }

    // eslint-disable-next-line complexity
    async onSubmit(skipValidation = false) {

        if (this.isCheckRegistration) {
            this.emitter.emit('lock.placeorder.button');
            this.onRegistrationSubmit(skipValidation);
            return;
        }

        const { submitted, form } = skipValidation ? {
            submitted: true,
            form: this.form.$el
        // @ts-ignore
        } : await this.form.onSubmit();

        if (submitted) {
            this.emitter.emit('checkout.placeorder.infoblock.hide');

            let data = null;

            try {

                this.emitter.emit('lock.placeorder.button');
                data = await getJson({
                    url: form.data('placeOrderUrl'),
                    type: 'POST',
                    data: form.serialize()
                });
            } catch (e) {

                this.emitter.emit('unlock.placeorder.button');

                this.emitter.emit('checkout.placeorder.infoblock.show', {
                    type: 'error',
                    message: CHECKOUT_TECH_ERROR
                });

                return;
            }

            if (data.error) {

                this.emitter.emit('unlock.placeorder.button');

                if (data.event) {
                    this.emitter.emit(data.event);
                }

                if (isRNWebView() && data.cartError) {
                    sendEventToMobileApp({
                        type: 'checkout.error.cart'
                    }, true);
                    return false;
                }

                if (data.cartError && data.redirectUrl) {
                    // @ts-ignore
                    window.location.href = data.redirectUrl;
                    // to prevent doing the button is available for the next click
                    // in general, it should be done via a return value. However, there's a potential impact
                    // eslint-disable-next-line no-promise-executor-return, no-return-await
                    return await (new Promise(res => setTimeout(() => res(), 10000)));
                } else {
                    // eslint-disable-next-line no-lonely-if
                    if (data.errorStage) {
                        var errorType = 'payment';

                        if (data.failedCertificates) {
                            errorType = 'giftcard';
                        } else if (data.missingPayment) {
                            errorType = 'missingpayment';
                        }

                        this.emit('checkout.step.error', {
                            step: data.errorStage.stage,
                            errorType: errorType
                        });
                    } else {
                        this.emitter.emit('checkout.placeorder.infoblock.show', {
                            type: data.typeMessage || 'error',
                            message: data.errorMessage
                        });
                    }

                    data.order && this.emitter.emit('action.summary.update', data);
                }
            } else {
                let continueUrl = data.continueUrl;

                if (data.confirmPage) {
                    continueUrl = appendParamToURL(continueUrl, 'ID', encodeURIComponent(data.orderID));
                    continueUrl = appendParamToURL(continueUrl, 'token', encodeURIComponent(data.orderToken));
                }

                if (data.cancelUrl) {
                    replaceState(data.cancelUrl);
                }

                if (isRNWebView() && data.cancelUrlOCAPI) {
                    sendEventToMobileApp({
                        type: 'checkout.order.place',
                        data: {
                            cancelUrl: data.cancelUrlOCAPI
                        }
                    }, true);
                }

                // @ts-ignore
                window.location.href = continueUrl;
                // to prevent doing the button is available for the next click
                // in general, it should be done via a return value. However, there's a potential impact
                // eslint-disable-next-line consistent-return, no-return-await, no-promise-executor-return
                return await (new Promise(res => setTimeout(() => res(), 10000)));
            }
        }
    }
}
