import AbstractExpandedPaymentOptionTab from '../checkout/AbstractExpandedPaymentOptionTab';
import { CLASSES } from 'client/utils/globals';
import { getJson, getCSRFToken } from 'client/utils/ajax';
import Spinner from 'client/utils/spinner';
import templatesManager from 'client/utils/templatesManager';

import {
    COINS_WORD,
    COIN_WORD,
    SERVER_UNAVAILABLE,
    COINS_AMOUNT_AUTOCHANGED
} from 'resources';

import {
    LOYALTY_ICR
} from 'constants';

const helper = require('../../../../../../../plugin_loyalty/cartridge/scripts/sharedHelper');

const SELECTORS = {
    TAB: '.js-coins-tab',
    CONTENT: '.js-coins-content',
    PAYMENT_OPTION: '#paymentMethod_Coins',
    INFO_BLOCK: '.js-applied-coint-info-block',
    FORM_BLOCK: '.js-active-form-block',
    REMOVE_CONFIRMATION_BLOCK: '.js-remove-coins-confirmation',
    APPLIED_COINS_MESSAGE_BLOCK: '.js-applied-coins-message',
    INPUT_BLOCK: '.js-field-cmp',
    ERROR_MSG_BLOCK: '.js-applying-error',
    ERROR_MSG_TEXT: '.js-error-text',
    CORRESPONDS_TO_AMOUNT: '.js-corresponds-to-amount',
    APPLY_BTN: '.js-apply-coins',
    REMOVE_BTN: '.js-remove-coins',
    REMOVE_CONFIRMATION_BTN: '.js-remove-confirmation-action',
    REMOVE_CONFIRMATION_ERROR_BLOCK: '.js-remove-confirmation-error-block'
};

const STATE_CLASSES = {
    active: 'b-checkout-loyalty-tab--active'
};

const TEMPLATE_NAMES = {
    correspondsToAmount: 'corresponds-to-amount',
    appliedCoins: 'applied-coins-message'
};

export default class CoinsPaymentOption extends AbstractExpandedPaymentOptionTab {
    init() {
        super.init();

        this.isCoinsApplied = this.config.isApplied;
        this.isComponentDoAction = false;

        this.permanentLock = false;

        this.correspondsToAmountTmpl = templatesManager.templates[TEMPLATE_NAMES.correspondsToAmount];
        this.appliedCoinsMessageTmpl = templatesManager.templates[TEMPLATE_NAMES.appliedCoins];

        this.$correspondsToAmount = this.$el.find(SELECTORS.CORRESPONDS_TO_AMOUNT);
        this.$formBlock = this.$el.find(SELECTORS.FORM_BLOCK);
        this.$infoBlock = this.$el.find(SELECTORS.INFO_BLOCK);
        this.$removeConfirmationBlock = this.$el.find(SELECTORS.REMOVE_CONFIRMATION_BLOCK);
        this.$removeConfirmationErrorBlock = this.$el.find(SELECTORS.REMOVE_CONFIRMATION_ERROR_BLOCK);
        this.$appliedCoinsMessageBlock = this.$el.find(SELECTORS.APPLIED_COINS_MESSAGE_BLOCK);
        this.$errorMsgBlock = this.$el.find(SELECTORS.ERROR_MSG_BLOCK);
        this.$inputBlock = this.$el.find(SELECTORS.INPUT_BLOCK);
        this.$tab = this.$el.find(SELECTORS.TAB);
        this.$content = this.$el.find(SELECTORS.CONTENT);
        this.$applyBtn = this.$el.find(SELECTORS.APPLY_BTN);

        /**
         * @type {import('./CoinsInput').default}
         */
        // @ts-ignore
        this.coinsAmountInputCmp = this.getNestedComponentById('coinsAmount');

        if (!this.config.isAvailable || (this.coinsAmountInputCmp && this.coinsAmountInputCmp.isLocked())) {
            this.$applyBtn.addClass('disabled');
            return;
        }

        this.bindEvent('click', SELECTORS.APPLY_BTN, (...args) => this.doIfPossible(this.onApplyCoinsBtnClick, args));
        this.bindEvent('click', SELECTORS.REMOVE_BTN, (...args) => this.doIfPossible(this.onRemoveCoinsBtnClick, args));

        if (this.coinsAmountInputCmp) {
            this.coinsAmountInputCmp.bindEvent('change', this.onCoinsAmountInputChange.bind(this));
        }
    }

    async onApplyCoinsBtnClick(el, ev) {
        ev.preventDefault();
        ev.stopPropagation();

        if (this.coinsAmountInputCmp.validate() && !this.coinsAmountInputCmp.isLocked()) {
            let applyCoinsResult;

            this.showBlock('form');
            this.$applyBtn.addClass(CLASSES.hide);

            try {
                applyCoinsResult = await this.useCoins('apply', this.coinsAmountInputCmp.getValue());
            } catch (e) {
                applyCoinsResult = { error: true };
                this.permanentLock = true;
                this.$applyBtn.addClass('disabled');
            } finally {
                this.$applyBtn.removeClass(CLASSES.hide);
            }

            if (applyCoinsResult.error) {
                this.showBlock('error', applyCoinsResult.errorMessage || SERVER_UNAVAILABLE);
                if (
                    ~[
                        LOYALTY_ICR.GENERAL,
                        LOYALTY_ICR.UNAVAILABLE,
                        LOYALTY_ICR.DISABLED
                    ].indexOf(applyCoinsResult.icr)
                ) {
                    this.$applyBtn.addClass('disabled');
                    this.permanentLock = true;
                }
                return;
            }

            /* eslint-disable max-len */
            this.$appliedCoinsMessageBlock.html(this.appliedCoinsMessageTmpl({
                appliedCoins: `${applyCoinsResult.usedCoinsAmount} ${(applyCoinsResult.usedCoinsAmount === 1 ? COIN_WORD : COINS_WORD)}`,
                leftCoins: `${applyCoinsResult.leftCoinsAmount} ${(applyCoinsResult.leftCoinsAmount === 1 ? COIN_WORD : COINS_WORD)}`
            }));
            /* eslint-enable max-len */

            this.showBlock('info');
            this.lockContentBlock();
        }
    }

    onRemoveCoinsBtnClick(el, ev) {
        ev.preventDefault();
        ev.stopPropagation();

        this.showBlock('removeconfirmation');

        const _this = this;

        this.$removeConfirmationBlock.on('click', SELECTORS.REMOVE_CONFIRMATION_BTN, async function (e) {
            e.preventDefault();
            e.stopPropagation();

            _this.doIfPossible(async () => {
                _this.$removeConfirmationErrorBlock.addClass(CLASSES.hide);
                _this.$removeConfirmationBlock.addClass(CLASSES.hide);

                if (this.getAttribute('data-remove') === 'true') {
                    let removeCoinsResult;

                    try {
                        removeCoinsResult = await _this.useCoins('remove');
                    } catch (er) {
                        removeCoinsResult = { error: true };
                    }

                    if (removeCoinsResult.error) {
                        _this.$removeConfirmationErrorBlock
                            .text(removeCoinsResult.errorMessage || SERVER_UNAVAILABLE)
                            .removeClass(CLASSES.hide);
                        _this.$removeConfirmationBlock.removeClass(CLASSES.hide);
                    } else {
                        _this.$removeConfirmationBlock.off('click', SELECTORS.REMOVE_CONFIRMATION_BTN);
                        _this.showBlock('form');
                        _this.unlockContentBlock();
                    }
                } else {
                    _this.showBlock('info');
                }
            });
        });
    }

    onCoinsAmountInputChange() {
        this.showBlock('form');

        let potentialAppliedAmountOfCoins = +this.coinsAmountInputCmp.getValue();

        if (typeof potentialAppliedAmountOfCoins !== 'number' || isNaN(potentialAppliedAmountOfCoins)) { return; }

        if (potentialAppliedAmountOfCoins < this.config.minValue) {
            potentialAppliedAmountOfCoins = this.config.minValue;
            this.showBlock('error', COINS_AMOUNT_AUTOCHANGED);
            this.coinsAmountInputCmp.setValue(this.config.minValue, true);
        } else if (potentialAppliedAmountOfCoins > this.config.maxValue) {
            potentialAppliedAmountOfCoins = this.config.maxValue;
            this.showBlock('error', COINS_AMOUNT_AUTOCHANGED);
            this.coinsAmountInputCmp.setValue(this.config.maxValue, true);
        }

        this.$correspondsToAmount.html(
            this.correspondsToAmountTmpl({
                amount: helper.coinsToCurrencyAmount(potentialAppliedAmountOfCoins, this.config.rate)
            })
        );

        !this.permanentLock && this.$applyBtn.toggleClass(
            'disabled',
            !this.coinsAmountInputCmp.validate() || this.coinsAmountInputCmp.getValue() <= 0
        );
    }

    showBlock(blockName, blockMsg) {
        switch (blockName) {
            case 'form':
                this.$inputBlock.removeClass(CLASSES.formFieldError).addClass(CLASSES.formFieldSuccess);
                this.$errorMsgBlock.addClass(CLASSES.hide);
                this.$infoBlock.addClass(CLASSES.hide);
                this.$removeConfirmationBlock.addClass(CLASSES.hide);
                this.$formBlock.removeClass(CLASSES.hide);
                break;

            case 'info':
                this.$inputBlock.removeClass(CLASSES.formFieldError).addClass(CLASSES.formFieldSuccess);
                this.$errorMsgBlock.addClass(CLASSES.hide);
                this.$removeConfirmationBlock.addClass(CLASSES.hide);
                this.$formBlock.addClass(CLASSES.hide);
                this.$infoBlock.removeClass(CLASSES.hide);
                break;

            case 'removeconfirmation':
                this.$inputBlock.removeClass(CLASSES.formFieldError).addClass(CLASSES.formFieldSuccess);
                this.$errorMsgBlock.addClass(CLASSES.hide);
                this.$formBlock.addClass(CLASSES.hide);
                this.$infoBlock.addClass(CLASSES.hide);
                this.$removeConfirmationBlock.removeClass(CLASSES.hide);
                break;

            case 'error':
                this.$infoBlock.addClass(CLASSES.hide);
                this.$removeConfirmationBlock.addClass(CLASSES.hide);
                this.$errorMsgBlock.removeClass(CLASSES.hide).text(blockMsg || '');
                this.$inputBlock.removeClass(CLASSES.formFieldSuccess).addClass(CLASSES.formFieldError);
                this.$formBlock.removeClass(CLASSES.hide);
                break;
        }
    }

    lockContentBlock() {
        this.isLocked = true;
    }

    unlockContentBlock() {
        this.isLocked = false;
    }

    /**
     * @param {("remove" | "apply")} actionType
     * @param {string | number} [coinsAmount] required only with actionType="apply"
     */
    async useCoins(actionType = 'remove', coinsAmount) {
        this.emitter.emit(`coinspayment.action.${actionType}`);

        const csrfToken = await getCSRFToken({ doNotStopSpinner: true });

        if (!csrfToken) {
            Spinner.StopAllSpinners(); // eslint-disable-line new-cap

            return {
                error: true
            };
        }

        const response = await getJson({
            type: 'post',
            url: this.config[`url${actionType[0].toUpperCase() + actionType.slice(1)}`],
            data: {
                coinsAmount,
                [csrfToken.tokenName]: csrfToken.token
            }
        });

        if (response.redirectUrl) {
            window.location.href = response.redirectUrl;
            response.error = true;
        } else if (!response.error) {
            response.order && this.emitter.emit('action.summary.update', response);
            response.order && this.emitter.emit('step.shipping.updated', response);

            this.isCoinsApplied = actionType === 'apply';
        }

        return response;
    }

    toggleContent() {
        if (this.isLocked || this.isCoinsApplied) {
            this.$paymentOption.prop('checked', this.isCoinsApplied);
            return;
        }

        let state = this.$paymentOption.prop('checked');

        this.$tab.toggleClass(STATE_CLASSES.active, state);
        this.$content.toggleClass(CLASSES.hide, !state);

        !this.permanentLock && this.$applyBtn.toggleClass(
            'disabled',
            this.coinsAmountInputCmp.isLocked() ||
            !this.coinsAmountInputCmp.validate() ||
            this.coinsAmountInputCmp.getValue() <= 0
        );
    }

    doIfPossible (callback, args = []) {
        if (!this.isComponentDoAction) {
            this.isComponentDoAction = true;

            try {
                callback.apply(this, args);
            } catch (e) {
                // eslint-disable-next-line
                console.log(e);
            } finally {
                this.isComponentDoAction = false;
            }
        }
    }

    switchActivationTo(flag) {
        if (flag) {
            this.$tab.addClass(STATE_CLASSES.active);
            this.$content.removeClass(CLASSES.hide);
            this.$content.find(SELECTORS.INPUT_FIELD).prop('disabled', false);
            this.$paymentOption.prop('checked', true);
        } else {
            this.$tab.removeClass(STATE_CLASSES.active);
            this.$content.addClass(CLASSES.hide);
            this.$content.find(SELECTORS.INPUT_FIELD).prop('disabled', true);
            this.$paymentOption.prop('checked', false);
        }
    }

    isSelected() {
        return this.config.isAvailable &&
            (this.coinsAmountInputCmp && !this.coinsAmountInputCmp.isLocked()) &&
            (this.isCoinsApplied || this.$paymentOption.prop('checked'));
    }
}
