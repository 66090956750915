import $ from 'jquery';
import Component from 'client/core/Component';

export default class HeightIframe extends Component {
    init () {
        this.$el.on('load', this.setHeightIframe.bind(this));
        this.$el.on('load', this.setScrollTop.bind(this));
    }

    setHeightIframe() {
        window.onmessage = (e) => {
            if (e.data.frameHeight) {
                const height = `${e.data.frameHeight + 30}px`;

                this.$el.height(height);
            }
        };
    }

    setScrollTop() {
        $(window).scrollTop(0);
    }
}
