import Form from 'client/core/Form';

const PICK_UP_STATION_FIELDS = [
    'packstationNumber',
    'postOfficeNumber',
    'carrierStationId',
    'carrierStationName'
];

const SELECTORS = {
    'SHIPPING_COUNTRY': '[data-id="shipping-countryCode"]',
    'BILLING_COUNTRY': '[data-id="billing-countryCode"]'
};

export default class AddEditForm extends Form {
    init () {
        this.emitter.addListener('addressBook.shipping.updated', f => this.fillShipping(f));
        this.bindEvent('change', SELECTORS.SHIPPING_COUNTRY, this.onCountryChanged.bind(this));
        this.bindEvent('change', SELECTORS.BILLING_COUNTRY, this.onCountryChanged.bind(this));
    }

    async validate() {
        let isValid = await super.validate();

        if (isValid) {
            let addressValidatorCmp = this.getNestedComponentById('address-validator');

            if (addressValidatorCmp && typeof addressValidatorCmp.onSubmit === 'function') {
                isValid = await addressValidatorCmp.onSubmit();
            }
        }

        return isValid;
    }

    fillShipping(fields) {
        if (fields.carrier) {
            PICK_UP_STATION_FIELDS.forEach(field => {
                this.getNestedComponentById(`shipping-${field}`).setValue('');
            });
            if (['hermes', 'sda', 'ups'].includes(fields.carrier)) {
                this.getNestedComponentById('shipping-postNumber').setValue('');
            }
        }
        for (const key in fields) { // eslint-disable-line no-restricted-syntax
            if (Object.prototype.hasOwnProperty.call(fields, key) && key !== 'id') {
                this.getNestedComponentById(`shipping-${key}`, (cmp) => {
                    cmp.setValue(fields[key], !fields[key]);
                });
            }
        }
    }

    onValidatePackstationNumber(cmp) {
        let validRanges = this.config.packstationValidRanges;

        validRanges = validRanges.map(item => {
            let [min, max] = item.split('-');

            return { min: parseInt(min, 10), max: parseInt(max, 10) };
        });

        const value = parseInt((cmp.getValue() + '').replace(/\r?\n/g, '\r\n'), 10);

        if (isNaN(value)) {
            return false;
        }

        let isValid = false;

        for (let range of validRanges) {
            if (value >= range.min && value <= range.max) {
                isValid = true;
                break;
            }
        }

        return isValid;
    }
}
