import Modal from 'client/components/Modal';

import $ from 'jquery';

const DEFAULT_CONFIG = {
    namespace: 'global'
};

const SELECTORS = {
    suggestionAddressConfirm: '#suggestionAddressConfirm',
    confirmButton: '.js-confirm-btn',
    document: document
};

export default class SuggestionAddressModal extends Modal {
    init () {
        super.init();

        this.defaultConfig = Object.assign({}, DEFAULT_CONFIG, this.config);
        this.emitter.addListener('modal.close', (el, e) => this.onConfirm(e));

        this.emitter.addListener(
            `${this.config.namespace}.suggestionAddressModal.show`,
            (templateAttrs) => this.showModal(templateAttrs)
        );
        this.emitter.addListener(
            `${this.config.namespace}.suggestionAddressModal.hide`,
            this.hideModal.bind(this)
        );
        this.emitter.addListener(
            `${this.config.namespace}.suggestionAddressModal.${this.config.id}.show`,
            (templateAttrs) => this.showModal(templateAttrs)
        );
        this.emitter.addListener(
            `${this.config.namespace}.suggestionAddressModal.${this.config.id}.hide`,
            this.hideModal.bind(this)
        );
    }

    onConfirm (e) {
        e && e.preventDefault();

        if (this.suggestionAddressConfirm === 'suggested') {
            this.emitter.emit('address.validator.corrected', {
                address: this.config.templateAttrs.suggested,
                type: 'suggested'
            });
        } else {
            this.emitter.emit('address.validator.corrected', {
                address: this.config.templateAttrs.entered,
                type: 'entered'
            });
        }

        this.hideModal();
    }

    hideModal () {
        this.$el.remove();
    }

    showModal (templateAttrs, el, e) {
        e && e.preventDefault();

        templateAttrs.templateAttrs.textbtn = this.config.textbtn;

        this.suggestionAddressConfirm = false;
        this.config = Object.assign({}, templateAttrs, this.defaultConfig);

        if (!super.isOpened()) {
            super.showModal();
        }

        this.$modalContainer = this.getModalContainer();
        this.suggestionAddressConfirm = 'suggested';

        this.$modalContainer.on('click', SELECTORS.suggestionAddressConfirm, () => {
            this.suggestionAddressConfirm = $(SELECTORS.suggestionAddressConfirm).filter(':checked').val();
        });
    }
}
