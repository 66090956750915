import Component from 'client/core/Component';
import { CLASSES, GLOBAL_SELECTORS } from 'client/utils/globals';
import $ from 'jquery';
import prefs from 'sitePreferences';

const ADDRESS_FIELDS = [
    'id',
    'title',
    'firstName',
    'lastName',
    'address1',
    'address2',
    'city',
    'street',
    'suite',
    'countryCode',
    'postalCode',
    'phone',
    'carrierName',
    'postNumber',
    'packstationNumber',
    'postOfficeNumber',
    'snipesStore',
    'carrierStationId',
    'carrierStationName'
];

const SELECTORS = {
    'SHIPMENT': '.js-shipment',
    'LABEL': '.js-shipment-label',
    'NEW': '.js-add-new-shipment',
    'NEW_WRAPPER': '.js-add-new-shipment-wrapper',
    'NEW_RADIO': '#new-shipping-address-selector',
    'NEW_CONTAINER': '.js-new-shipping-selector',
    'CHECKOUT_MGR': '[data-cmp="checkoutMgr"]'
};

const ATTRIBUTES = {
    DATA_FAVOURITE_STORE_ADDRESS_SHIPPING: 'data-favourite-store-address-for-shipping',
    CURRENT_SHIPPING_METHOD: 'data-current-shipping-method'
};

export default class ShipmentSelector extends Component {
    init() {
        this.$selectedAddress = this.getSelectedAddress();
        this.$newAddressContainer = this.$el.find(SELECTORS.NEW_CONTAINER);
        this.$newAddressButton = this.$el.find(SELECTORS.NEW);
        this.$newAddressButtonWrapper = this.$el.find(SELECTORS.NEW_WRAPPER);
        this.$tabs = this.getTabs();

        this.bindEvent('click', SELECTORS.LABEL, this.onLabelClick);
        this.bindEvent('change', SELECTORS.SHIPMENT, (el) => this.selectShipment(el));
        this.bindEvent('click', SELECTORS.NEW, (el, ev) => this.newShipment(el, ev));

        if (this.$selectedAddress === null) {
            this.newShipment();
        } else {
            this.isNewAddress = this.$selectedAddress.data('id') === 'new';
            if (this.isNewAddress) {
                this.newShipment();
            } else {
                this.selectShipment(this.$selectedAddress.get(0));
            }
        }
    }

    getTabs() {
        let methods = prefs.shippingMethods.list,
            $tabs = {};

        for (const method of methods) {
            $tabs[method] = this.$el.find(`.js-${method}-tab`);

            if ($tabs[method].length > 0 && $tabs[method].find('input').data('selected')) {
                this.$selectedTab = $tabs[method];
                this.$selectedTab.removeClass(CLASSES.hide);
            } else {
                $tabs[method].addClass(CLASSES.hide);
            }
        }

        if (!this.$selectedTab) {
            this.$selectedTab = $tabs[methods[0]];
        }

        return $tabs;
    }

    onMethodChanged(id, changedByEvent) {
        this.$selectedTab.find('input').data('selected', false);
        this.$selectedTab.addClass(CLASSES.hide);

        this.$selectedTab = this.$tabs[id];
        let $addressList = this.$selectedTab.find('input');
        let $availableAddressList = $addressList.filter(':enabled');

        if ($availableAddressList.length > 0) {
            let $address = $availableAddressList.first();

            $address.data('selected', true);
            this.selectShipment($address.get(0));

            this.$selectedTab.removeClass(CLASSES.hide);
            this.isNewAddress = false;
        } else {
            if ($addressList.length) {
                this.$selectedTab.removeClass(CLASSES.hide);
            }
            if (changedByEvent) {
                this.newShipment(null, true);
            } else {
                this.newShipment();
            }
            let dataStoreAttribute = $(GLOBAL_SELECTORS.mediaInteraction).attr(
                ATTRIBUTES.DATA_FAVOURITE_STORE_ADDRESS_SHIPPING);
            let storeAddressForShipping = dataStoreAttribute ? JSON.parse(dataStoreAttribute) : false;

            if (id === 'ship-to-store' && storeAddressForShipping) {
                this.emitter.emit('step.shipping.address.changed', storeAddressForShipping);
                this.emitter.emit('addressBook.shipping.updated.store', storeAddressForShipping);
            }
        }

        this.toggleNewAddress();
    }

    onLabelClick() {
        this.isNewAddress = false;
    }

    selectShipment(el, isEntireNew) {
        let $el = $(el),
            data = $el.data(),
            fields = this.getSelectorFields(data);

        if (isEntireNew) {
            let checkoutMgr = $(SELECTORS.CHECKOUT_MGR),
                shippingMethod = checkoutMgr.attr(ATTRIBUTES.CURRENT_SHIPPING_METHOD),
                isSelectedShipToStore = $('[data-shipping-method=ship-to-store]').hasClass('h-selected'),
                dataStoreAttribute = $(GLOBAL_SELECTORS.mediaInteraction).attr(
                    ATTRIBUTES.DATA_FAVOURITE_STORE_ADDRESS_SHIPPING),
                storeAddressForShipping = dataStoreAttribute || false;

            try {
                storeAddressForShipping = JSON.parse(dataStoreAttribute);
            } catch (e) {
                storeAddressForShipping = false;
            }

            if (shippingMethod === 'ship-to-store' && storeAddressForShipping && isSelectedShipToStore) {
                fields = storeAddressForShipping;
                this.emitter.emit('addressBook.shipping.updated.store', storeAddressForShipping);
            } else {
                for (let key of ADDRESS_FIELDS) {
                    if (key !== 'id' && key !== 'countryCode') {
                        fields[key] = '';
                    }
                }
            }
        }

        fields.isEntireNew = isEntireNew;

        this.$selectedAddress = $el;

        // Show/Hide button Add new address
        this.toggleNewAddress();

        if (!this.$selectedAddress.prop('checked')) {
            this.$selectedAddress.prop('checked', true);
        }

        this.emitter.emit('step.shipping.address.changed', fields);
    }

    toggleNewAddress() {
        let addressLength = this.$selectedTab.find('input').length;

        this.$newAddressContainer.toggleClass(CLASSES.hide, !this.isNewAddress || addressLength === 0);
        this.$newAddressButton.toggleClass(CLASSES.hide, this.isNewAddress || addressLength === 0);
        this.$newAddressButtonWrapper.toggleClass(CLASSES.hide, this.isNewAddress || addressLength === 0);
    }

    newShipment(el, ev) {
        let newShipment = false;

        if (ev) {
            typeof ev.preventDefault === 'function' && ev.preventDefault();
            newShipment = true;
        }

        let $newAddress = this.$el.find(SELECTORS.NEW_RADIO);

        $newAddress.prop('checked', true);
        this.isNewAddress = true;
        this.selectShipment($newAddress.get(0), newShipment);
    }

    getSelectorFields(data) {
        let fields = {};

        for (let field of ADDRESS_FIELDS) {
            fields[field] = data[field];
        }

        return fields;
    }

    getSelectedShipment() {
        return this.getSelectorFields(this.$selectedAddress.data());
    }

    getSelectedAddress() {
        let $selected = this.$el.find(`${SELECTORS.SHIPMENT}[data-selected="true"]`);

        return $selected.length > 0 ? $selected : null;
    }
}
