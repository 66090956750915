import Component from 'client/core/Component';
import $ from 'jquery';

const SELECTORS = {
    seoExpandWrapper: '.js-expand-seo-wrapper',
    seoSlotWrapper: '.js-category-seo-slot-wrapper'
};

export default class ExpandBlockWrapper extends Component {
    init() {
        this.emitter.addListener(
            'refinement.updated',
            ($response) => this.updateExpandableCatSeoBlock($response)
        );
    }

    updateExpandableCatSeoBlock($response) {
        const $seoSlotWrapper = $(SELECTORS.seoSlotWrapper);
        const seoSlotContent = $response.find(SELECTORS.seoSlotWrapper).html();

        if ($seoSlotWrapper) {
            $seoSlotWrapper.empty();
            $seoSlotWrapper.html(seoSlotContent);
            const $newSeoSlotComponent = $(SELECTORS.seoExpandWrapper);

            this.emitter.emit('namespace.component.destroy', $seoSlotWrapper);
            this.emitter.emit('namespace.component.reinit', $newSeoSlotComponent, {
                carousels: false
            });
        }
    }
}
