import Form from 'client/core/Form';
import { CLASSES } from 'client/utils/globals';
import prefs from 'sitePreferences';
import $ from 'jquery';
import { getHMACToken } from 'urls';
import { getJson, getCSRFToken } from 'client/utils/ajax';
import { scrollToMsg } from 'client/utils/common';
import { isRNWebView, sendEventToMobileApp } from 'client/utils/react';

const customValidator = require('client/utils/customValidator');

const SELECTORS = {
    'ERROR': '.js-error',
    'SHIPPING_FORM': '.js-shipping-address-form-content',
    'FULL_BILLING_FORM': '.js-billing-address-form',
    'BILLING_FORM': '.js-billing-address-form-content',
    'SAVE_ADDRESS': '.js-save-address',
    'INPUT_FIELD': '.js-field',
    'ADDRESS_TAB': '.js-shipping-form-tab',
    'SELECTED_TAB': '.js-selected-tab',
    'USE_AS_BILLING': '.js-use-as-billing',
    'CARRIER': '.js-carrier-type',
    'CARRIER_INPUT': '.js-carrier-type .js-carrier-input',
    'ESTIMATED_DELIVERY': '.js-estimated-delivery',
    'FIELD': '.js-field',
    'FIELD_CMP': '.js-field-cmp',
    'SHIPPING_COUNTRY_CODE': '.js-shipping-country-code',
    'ADDRESSWRAP': '.js-shipping-address',
    'ERROR_BLOCK': '.js-error-block',
    'CHECKOUT_MGR': '[data-cmp="checkoutMgr"]',
    'BILLING_COUNTRY': '[data-id="billing-countryCode"]'
};

const ADDRESS_TYPES = {
    'HOME_DELIVERY': 'home-delivery',
    'SHIP_TO_STORE': 'ship-to-store',
    'PICK_UP_STATION': 'pick-up-station'
};

const ADDRESS_FIELDS = [
    'id',
    'title',
    'firstName',
    'lastName',
    'address1',
    'address2',
    'city',
    'street',
    'suite',
    'countryCode',
    'postalCode',
    'phone',
    'postNumber',
    'packstationNumber',
    'postOfficeNumber',
    'snipesStore',
    'carrierStationId',
    'carrierStationName'
];

const ADDRESS_SUMMARY_FIELDS_DEFAULT = [
    ['firstName', 'lastName'],
    ['street', 'suite'],
    ['address2'],
    ['postalCode', 'city'],
    ['countryCode'],
    ['email']
];

const STATE_CLASSES = {
    'ADDRESS_BORDERED': 'b-shipping-address--bordered',
    'LOCKED': 'm-locked',
    'HIDE': 'h-hide'
};

export default class CheckoutAddressForm extends Form {
    init() {
        this.selectedMethod = this.config.selectedMethod;
        this.isGiftCardBasket = this.config.isGiftCardBasket;
        this.selectedMethodName = this.config.selectedMethodName;
        this.$selectedAddressTab = this.$el.find(`${SELECTORS.ADDRESS_TAB}${SELECTORS.SELECTED_TAB}`);
        this.$shippingAddressForm = this.$el.find(SELECTORS.SHIPPING_FORM);
        this.$saveAddress = this.$el.find(SELECTORS.SAVE_ADDRESS);
        this.$error = this.$el.find(SELECTORS.ERROR);
        this.$carrier = this.$el.find(SELECTORS.CARRIER);
        this.$fullBillingAddressForm = this.$el.find(SELECTORS.FULL_BILLING_FORM);
        this.$billingAddressForm = this.$el.find(SELECTORS.BILLING_FORM);
        this.$contactForm = this.$el.find(SELECTORS.CONTACT_FORM);
        this.$estimatedDelivery = this.$el.find(SELECTORS.ESTIMATED_DELIVERY);
        this.$addressWraper = this.$el.find(SELECTORS.ADDRESSWRAP);
        this.$useAsBilling = this.$el.find(SELECTORS.USE_AS_BILLING);
        this.$packStationField = this.getNestedComponentById('shipping-packstationNumber');
        this.$postOfficeField = this.getNestedComponentById('shipping-postOfficeNumber');
        this.$postNumberField = this.getNestedComponentById('shipping-postNumber');
        this.$carrierStationIdField = this.getNestedComponentById('shipping-carrierStationId');
        this.$carrierStationNameField = this.getNestedComponentById('shipping-carrierStationName');

        this.shipmentSelectorCmp = this.getNestedComponentById('shipment-selector');
        this.addressSaveBtn = this.getNestedComponentById('shipping_saveAddress');

        this.bindEvent('change', SELECTORS.CARRIER_INPUT, el => {
            this.$estimatedDelivery.empty().text($(el).data('delivery-msg'));
        });
        this.$el.find(`${SELECTORS.CARRIER_INPUT}:checked`).trigger('change');

        this.emitter.addListener('step.shipping.address.changed', f => {
            this.toggleError();
            this.fillShipping(f);
            this.toggleAddressSaveButton(f.id);
        });
        this.emitter.addListener('useshippingasbilling.changed', this.toggleBillingForm.bind(this));
        this.emitter.addListener('step.shipping.method.changed', data => {
            this.toggleError();
            this.onMethodChanged(data.id, data.changedByEvent);
            this.setSelectedShippingMethod(data.shipmentMethodName);
        });
        this.emitter.addListener('checkout.step.shipping.finder', () => this.initFinderFields());
        this.emitter.addListener('modal.closed', p => this.onModalClosed(p));
        this.emitter.addListener('fill.billing.form', fields => this.fillBilling(fields));
        this.emitter.addListener('toggle.billing.form', (value) => this.toggleFullBillingForm(value));
        this.emitter.addListener('checkout.step.login.email.updated', (email) => {
            this.customerEmail = email;
        });

        this.bindEvent('change', SELECTORS.BILLING_COUNTRY, this.onCountryChanged.bind(this));

        if (this.selectedMethod) {
            this.onMethodChanged(this.selectedMethod);
        }

        this.fillShipping(this.shipmentSelectorCmp.getSelectedShipment());
        this.updateFormFields();

        this.useAsBillingCmp = this.isHomeDelivery(this.selectedMethod) ?
            this.getNestedComponentById('shipping-useAsBilling') : null;

        this.toggleBillingForm();
        this.fillSummary();
    }

    toggleFullBillingForm(value) {
        this.$fullBillingAddressForm.toggleClass(CLASSES.hide, value);
    }

    onValidatePackstationNumber(cmp) {
        let validRanges = this.config.packstationValidRanges;

        validRanges = validRanges.map(item => {
            let [min, max] = item.split('-');

            return { min: parseInt(min, 10), max: parseInt(max, 10) };
        });

        const value = parseInt((cmp.getValue() + '').replace(/\r?\n/g, '\r\n'), 10);

        if (isNaN(value)) {
            return false;
        }

        let isValid = false;

        for (let range of validRanges) {
            if (value >= range.min && value <= range.max) {
                isValid = true;
                break;
            }
        }

        return isValid;
    }

    onModalClosed(event) {
        const PICK_UP_STATION_FIELDS = {
            'packstation': 'packstationNumber',
            'filiale': 'postOfficeNumber',
            'hermes': 'carrierStationId',
            'sda': 'carrierStationId',
            'ups': 'carrierStationId'
        };
        const DHLCarrier = ['packstation', 'filiale'];

        if (event.id in PICK_UP_STATION_FIELDS) {
            let address = event.params.address;

            let obj = {
                id: 'new',
                city: address.city,
                street: address.street,
                suite: address.streetNo,
                postalCode: address.zip,
                stationType: event.id,
                carrierStationName: address.name || '',
                carrier: DHLCarrier.includes(event.id) ? 'dhl' : event.id
            };

            obj[PICK_UP_STATION_FIELDS[event.id]] = event.params.ID;
            this.emitter.emit('addressBook.shipping.updated.pickup', obj);
            this.fillShipping(obj);
        } else if (event.id === 'storeLocator') {
            let address = event.params.address,
                obj = {
                    id: 'new',
                    city: address.city,
                    postalCode: address.postalCode,
                    snipesStore: event.params.ID
                };

            let delimiter = address.address1.indexOf(',') > 0 ? ',' : ' ';
            let splitted = address.address1.split(delimiter);

            if (splitted[splitted.length - 1] && splitted[splitted.length - 1].match(/^\d{1,}/gi)) {
                obj.suite = splitted.slice(-1);
                obj.street = splitted.slice(0, -1).join(' ');
            } else {
                obj.street = address.address1;
            }
            this.emitter.emit('addressBook.shipping.updated.store', obj);
            this.emitter.emit('step.shipping.hide.title');

            this.fillShipping(obj);
        }
    }

    toggleAddressSaveButton(id) {
        if (this.addressSaveBtn && id !== 'new') {
            this.addressSaveBtn.setValue(false);
        }
    }

    toggleBillingForm() {
        const useShippingAsBilling = Boolean(this.useAsBillingCmp && this.useAsBillingCmp.getValue());

        this.$billingAddressForm.toggleClass(CLASSES.hide, useShippingAsBilling);
        this.$useAsBilling.toggleClass(CLASSES.hide, Boolean(this.useAsBillingCmp) === false);
        this.$addressWraper.toggleClass(STATE_CLASSES.ADDRESS_BORDERED, Boolean(this.useAsBillingCmp) === false);
    }

    initFinderFields() {
        let zip = null;
        let city = null;

        this.getNestedComponentById('shipping-postalCode', cmp => {
            if (this.isCmpActive(cmp)) {
                let value = cmp.getValue();

                if (value) {
                    zip = value;
                }
            }
        });

        this.getNestedComponentById('shipping-city', cmp => {
            if (this.isCmpActive(cmp)) {
                let value = cmp.getValue();

                if (value) {
                    city = value;
                }
            }
        });

        this.emitter.emit('checkout.step.shipping.finder.init', {
            zip: zip,
            city: city
        });
    }

    fillShipping(fields) {
        if (this.isGiftCardBasket) {
            return;
        }
        // Show/Hide form according to selected address type
        // If new address then show clear form
        // Otherwise hide it and prefill fields
        this.toggleCarrierFields(fields);
        this.$shippingAddressForm.toggleClass(CLASSES.collapsed, fields.id !== 'new');
        this.toggleShippingForm(fields.id !== 'new');

        for (const key in fields) { // eslint-disable-line no-restricted-syntax
            if (Object.prototype.hasOwnProperty.call(fields, key) && key !== 'id') {
                this.loopAllNestedComponents((cmp) => {
                    if (cmp.id === `shipping-${key}` && this.isCmpActive) {
                        if (fields.isEntireNew) {
                            cmp.setValue(fields[key], false);
                            cmp.clearError();
                        } else {
                            cmp.setValue(fields[key], !fields[key]);
                        }
                    }
                });
            }
        }

        this.fillAddress1('shipping');
        this.setDataAttrSelectedShippingMethod(fields);
        this.fillCarrier(fields);
    }

    setDataAttrSelectedShippingMethod(fields) {
        let carrier = fields.carrier || fields.carrierName;

        if (carrier) {
            const checkoutMgr = $(SELECTORS.CHECKOUT_MGR);
            let shippingMethodId = this.$selectedAddressTab.data('id') + '_' + carrier;

            checkoutMgr.attr('data-current-shipping-method', shippingMethodId);
            this.emitter.emit('shipping.address.changed');
        }
    }

    toggleShippingForm(isHidden) {
        if (typeof isHidden === 'undefined') {
            this.$shippingAddressForm.toggleClass(CLASSES.hide);
            this.$saveAddress.toggleClass(CLASSES.hide);
        } else {
            this.$shippingAddressForm.toggleClass(CLASSES.hide, isHidden);
            this.$saveAddress.toggleClass(CLASSES.hide, isHidden);
        }
    }

    fillCarrier(fields) {
        this.resetCarrier();

        if (fields.carrierName) {
            let selectedTabID = this.$selectedAddressTab.data('id');

            if (selectedTabID === ADDRESS_TYPES.HOME_DELIVERY) {
                let $carrierInput = this.$el.find(`${SELECTORS.CARRIER_INPUT}[value="${fields.carrierName}"]`);

                $carrierInput.prop('checked', true);
                $carrierInput.trigger('change');
            } else if (selectedTabID === ADDRESS_TYPES.PICK_UP_STATION) {
                this.getNestedComponentById('shipping-carrier').setValue(fields.carrierName);
            }
        }
    }

    toggleCarrierFields(fields) {
        if (!fields.carrier && !fields.carrierName) {
            return;
        }
        let carrierName = fields.carrier || fields.carrierName;

        this.$packStationField.setValue('');
        this.$postOfficeField.setValue('');
        this.$carrierStationIdField.setValue('');
        this.$carrierStationNameField.setValue('');

        if (carrierName === 'dhl') {
            this.$postNumberField.config.required = true;
            this.$carrierStationIdField.config.required = false;
            this.$carrierStationNameField.config.required = false;
        } else {
            this.$postNumberField.setValue('');
            this.$postNumberField.config.required = false;
            this.$carrierStationIdField.config.required = true;
            this.$carrierStationNameField.config.required = false;
        }
    }

    resetCarrier() {
        let selectedTabID = this.$selectedAddressTab.data('id');

        if (selectedTabID === ADDRESS_TYPES.HOME_DELIVERY) {
            this.$el.find(`${SELECTORS.CARRIER_INPUT}:first`).prop('checked', true);
        }
    }

    fillBilling(fields) {
        for (const key in fields) { // eslint-disable-line no-restricted-syntax
            if (Object.prototype.hasOwnProperty.call(fields, key) && key !== 'id') {
                this.getNestedComponentById(`billing-${key}`, (cmp) => {
                    if (key === 'countryCode' || key === 'title') {
                        if (!fields[key]) {
                            return;
                        }
                    }
                    cmp.setValue(fields[key], !fields[key]);
                });
            }
        }
    }

    fillAddress1(type) {
        let address1Cmp = this.getNestedComponentById(`${type}-address1`),
            streetCmp = this.getNestedComponentById(`${type}-street`),
            suiteCmp = this.getNestedComponentById(`${type}-suite`);

        if (address1Cmp && streetCmp && suiteCmp) {
            address1Cmp.setValue(`${streetCmp.getValue()}, ${suiteCmp.getValue()}`, false);
        }
    }

    fillPhone(type) {
        let phoneCmp = this.getNestedComponentById(`${type}-phone`),
            contactPhoneCmp = this.getNestedComponentById('contact-phone'),
            phone = contactPhoneCmp && contactPhoneCmp.getValue();

        if (phone) {
            phoneCmp.setValue(phone);
        }
    }

    copyShippingToBilling() {
        for (let field of ADDRESS_FIELDS) {
            let shippingField = this.getNestedComponentById(`shipping-${field}`),
                billingField = this.getNestedComponentById(`billing-${field}`);

            if (shippingField && billingField) {
                billingField.setValue(shippingField.getValue());
            }
        }
    }

    getMethodType(methodID) {
        let method;

        if ((new RegExp(prefs.shippingMethods.homeDelivery).test(methodID))) {
            method = ADDRESS_TYPES.HOME_DELIVERY;
        } else if ((new RegExp(prefs.shippingMethods.shipToStore).test(methodID))) {
            method = ADDRESS_TYPES.SHIP_TO_STORE;
        } else if ((new RegExp(prefs.shippingMethods.pickUpStation).test(methodID))) {
            method = ADDRESS_TYPES.PICK_UP_STATION;
        } else {
            method = ADDRESS_TYPES.HOME_DELIVERY;
        }

        return method;
    }

    onMethodChanged(methodID, changedByEvent) {
        methodID = this.getMethodType(methodID);

        this.$carrier.toggleClass(CLASSES.hide, methodID !== ADDRESS_TYPES.HOME_DELIVERY);
        this.selectedMethod = methodID;

        let selectedClass = SELECTORS.SELECTED_TAB.slice(1);

        // Hide old tab
        this.$selectedAddressTab.addClass(CLASSES.hide);
        this.$selectedAddressTab.removeClass(selectedClass);

        this.$el.find(SELECTORS.ADDRESS_TAB).each((index, item) => {
            let $tab = $(item);

            $tab.find('fieldset').prop('disabled', true);
        });

        // Select new tab
        this.$selectedAddressTab = this.$el.find(`${SELECTORS.ADDRESS_TAB}[data-id="${methodID}"]`);
        this.$selectedAddressTab.removeClass(CLASSES.hide);
        this.$selectedAddressTab.addClass(selectedClass);
        this.$selectedAddressTab.find('fieldset').prop('disabled', false);

        // Show/Hide use as billing address CTA
        this.useAsBillingCmp = this.isHomeDelivery(methodID) ?
            this.getNestedComponentById('shipping-useAsBilling') : null;

        this.toggleBillingForm();
        this.updateFormFields();

        this.emitter.emit('checkout.shipping.infoblock.hide');

        this.shipmentSelectorCmp.onMethodChanged(methodID, changedByEvent);
    }

    updateFormFields() {
        let $tabs = this.$el.find(SELECTORS.ADDRESS_TAB);

        $tabs.each((index, tab) => {
            let $tab = $(tab),
                $fields = $tab.find('[data-cmp]'),
                disabled = !$tab.hasClass(SELECTORS.SELECTED_TAB.slice(1));

            $fields.each((i, field) => {
                let $field = $(field),
                    cmp = $field.data('cmp-instance');

                if (cmp && typeof cmp.setDisabled === 'function') {
                    cmp.setDisabled(disabled);
                }
            });
        });
    }

    getCarrier() {
        let result = false;
        let selectedTabID = this.$selectedAddressTab.data('id');

        if (selectedTabID === ADDRESS_TYPES.HOME_DELIVERY) {
            result = this.$el.find(`${SELECTORS.CARRIER_INPUT}:checked`)
                .data('carrier-displayname');
        } else if (selectedTabID === ADDRESS_TYPES.PICK_UP_STATION) {
            let carrierInput = this.getNestedComponentById('shipping-carrier');
            let carrierType = carrierInput.getValue();

            result = carrierInput.config[carrierType + 'DisplayName'];
        }
        return result;
    }

    setSelectedShippingMethod(methodName) {
        this.config.selectedShippingMethod = methodName;
    }

    getSelectedShippingMethod() {
        return this.config.selectedShippingMethod;
    }

    mapFields(type, fieldRow, nested) {
        let separator = nested ? ' ' : ', ';

        if (typeof field === 'object') {
            return this.mapFields(type, fieldRow, true);
        } else {
            let value = [];

            // eslint-disable-next-line complexity
            fieldRow.forEach(field => {
                if (field === 'storeType') {
                    value.push(this.storeType);
                } else {
                    let addressField;

                    if (type === 'shipping') {
                        addressField = this.$el.find(`${SELECTORS.ADDRESS_TAB}[data-id="${this.selectedMethod}"] ` +
                            `[data-id="${type}-${field}"]`).data('cmp-instance');
                    } else if (type === 'billing') {
                        addressField = this.getNestedComponentById(`${type}-${field}`);
                    }

                    if (addressField && this.isCmpActive(addressField)) {
                        let newValue = addressField && addressField.getValue() ? addressField.getValue() || '' : '';

                        if (field === 'countryCode' && newValue && type === 'shipping') {
                            let input = this.$selectedAddressTab.find(SELECTORS.SHIPPING_COUNTRY_CODE);

                            newValue = input && input.length ? input.val() : '';
                        } else if (field === 'countryCode' && newValue && type === 'billing') {
                            let option = addressField.$el.find(`option[id=${newValue}]`);

                            newValue = option && option.length ? option[0].label : '';
                        } else if (field === 'carrier') {
                            newValue = newValue.toUpperCase();
                        }

                        value.push(newValue);
                    }
                }
            });

            return value.filter(val => val !== '' && val !== null).join(separator);
        }
    }

    mapAddressSummary(type, fields = ADDRESS_SUMMARY_FIELDS_DEFAULT) {
        let mappedFields = [];

        for (let fieldRow of fields) {
            mappedFields.push(this.mapFields(type, fieldRow, true));
        }

        mappedFields = mappedFields.filter(item => item !== '' && item !== null);

        return mappedFields.join(', ');
    }

    // eslint-disable-next-line consistent-return
    async fillSummary() {
        this.summary = {};
        this.summary.shipping = this.mapAddressSummary('shipping', this.getAddressSummaryFields());

        if ((this.useAsBillingCmp && !this.useAsBillingCmp.getValue()) || !this.useAsBillingCmp) {
            this.summary.billing = this.mapAddressSummary('billing');
        }

        let carrier = this.getCarrier();
        let selectedShippingMethod = this.getSelectedShippingMethod();

        if (carrier) {
            this.summary.carrier = carrier + ' ' + selectedShippingMethod;
        }
        const checkoutMgr = $(SELECTORS.CHECKOUT_MGR);
        let shippingMethod = checkoutMgr.attr('data-current-shipping-method');

        if (shippingMethod === 'email-shipping') {
            this.summary.carrier = selectedShippingMethod;
            this.summary.isGiftCardBasket = true;
        }

        if (this.onValidateEmail(this.getEmail()) && this.getEmail() !== '') {
            const csrfToken = await getCSRFToken({ doNotStopSpinner: true });

            if (!csrfToken) {
                return {
                    error: true
                };
            }

            const response = await getJson({
                type: 'post',
                url: getHMACToken,
                data: {
                    email: this.getEmail(),
                    [csrfToken.tokenName]: csrfToken.token
                }
            });

            this.emitter.emit('emarsys.guest.email.filled', response.cuid);
        }
    }

    toggleError(error = null) {
        if (error) {
            this.$error.html(this.config[error]);

            scrollToMsg(this.$error);
        }

        this.$error.toggleClass(CLASSES.hide, !error);
    }

    getEmail() {
        const emailCmp = this.getNestedComponentById('contact-email');

        return emailCmp ? emailCmp.getValue() : this.customerEmail || '';
    }

    async onSubmit(skipAddressValidation) {
        this.fillAddress1('shipping');
        this.fillPhone('shipping');
        this.fillSummary();

        if (this.useAsBillingCmp && this.useAsBillingCmp.getValue()) {
            this.copyShippingToBilling();
        } else {
            this.fillAddress1('billing');
            this.fillPhone('billing');
        }

        let isValid = await this.validate();

        if (isValid) {
            let addressValidatorCmpIds = ['address-validator', 'address-billing-validator'];

            for (let validatorCmpId of addressValidatorCmpIds) {
                let addressValidatorCmp = this.getNestedComponentById(validatorCmpId);

                if (addressValidatorCmp && typeof addressValidatorCmp.onSubmit === 'function') {
                    if (isRNWebView()) {
                        sendEventToMobileApp({
                            type: 'checkout.address.entered',
                            email: this.getEmail()
                        }, true);
                    }

                    if (!skipAddressValidation) {
                        isValid = await addressValidatorCmp.onSubmit();
                    }

                    if (!isValid) {
                        break;
                    }
                    // this.emitter.emit('emarsys.guest.email.filled',
                    //  this.getNestedComponentById('contact-email').getValue());
                }
            }
        } else if (this.$shippingAddressForm.hasClass(CLASSES.hide)) {
            this.toggleShippingForm();
            var isValidEmail = this.onValidateEmail(this.getEmail());

            if (isValidEmail) {
                this.toggleError('savedAddressInvalid');
            }
        } else {
            let selectedTabID = this.$selectedAddressTab.data('id');

            if (selectedTabID === ADDRESS_TYPES.HOME_DELIVERY) {
                this.scrollToFirstErrorEl();
            }
        }
        this.emitter.emit('shipping.address.changed');

        return { submitted: isValid, form: this.$el };
    }

    isCmpActive(cmp) {
        let $fieldSet = cmp.$el.closest('fieldset');

        return ($fieldSet.length > 0 && !$fieldSet.prop('disabled')) || $fieldSet.length === 0;
    }

    getAddressSummaryFields() {
        let method = this.selectedMethod;
        let ADDRESS_SUMMARY_FIELDS = [
            ['firstName', 'lastName']
        ];
        const PICK_UP_STATION_FIELDS = {
            'packstationNumber': 'Packstation',
            'postOfficeNumber': 'PostOffice',
            'carrierStationId': 'CarrierStationId'
        };

        ADDRESS_SUMMARY_FIELDS.push(['street', 'suite']);

        if ((new RegExp(prefs.shippingMethods.homeDelivery).test(method))) {
            ADDRESS_SUMMARY_FIELDS.push(['address2']);
        } else if ((new RegExp(prefs.shippingMethods.pickUpStation).test(method))) {
            for (const key in PICK_UP_STATION_FIELDS) { // eslint-disable-line no-restricted-syntax
                if (Object.prototype.hasOwnProperty.call(PICK_UP_STATION_FIELDS, key)) {
                    let addressField = this.$el.find(`${SELECTORS.ADDRESS_TAB}[data-id="${this.selectedMethod}"] ` +
                    `[data-id="${'shipping'}-${key}"]`).data('cmp-instance');

                    if (addressField && addressField.getValue()) {
                        this.storeType = this.config['storeType' + PICK_UP_STATION_FIELDS[key]];
                    }
                }
            }
            ADDRESS_SUMMARY_FIELDS.push(['postNumber']);
            ADDRESS_SUMMARY_FIELDS.push(
                ['carrier', 'packstationNumber', 'postOfficeNumber', 'carrierStationId']
            );
        } else if ((new RegExp(prefs.shippingMethods.shipToStore).test(method))) {
            this.storeType = this.config.storeTypeShipToStore;
            ADDRESS_SUMMARY_FIELDS.push(['storeType', 'snipesStore']);
        } else {
            ADDRESS_SUMMARY_FIELDS.push(['address2']);
        }

        ADDRESS_SUMMARY_FIELDS.push(['postalCode', 'city']);
        ADDRESS_SUMMARY_FIELDS.push(['countryCode']);

        return ADDRESS_SUMMARY_FIELDS;
    }

    isHomeDelivery(methodID) {
        let homeDeliveryRegExp = new RegExp(prefs.shippingMethods.homeDelivery);

        return homeDeliveryRegExp.test(methodID);
    }

    onValidateSuite(cmp) {
        const shippingStreet = this.getNestedComponentById('billing-street'),
            value = cmp.getValue();

        return !value || (value !== shippingStreet.getValue());
    }

    onValidateEmail(cmp) {
        let validationResult = false;
        const isStringArg = typeof cmp === 'string';

        if (isStringArg) {
            validationResult = customValidator.validateEmail(cmp);
        } else {
            validationResult = customValidator.validateEmail(cmp.getValue());
        }

        if (!validationResult && !isStringArg) {
            cmp.$el.find(SELECTORS.FIELD).prop('readonly', validationResult);
            cmp.$el.removeClass(STATE_CLASSES.LOCKED);
        }

        return validationResult;
    }

    scrollToFirstErrorEl() {
        let errorElements = this.$el.find(SELECTORS.ERROR_BLOCK);
        let $firstFieldError = null;

        for (var i = 0; i < errorElements.length; i++) {

            if (!$(errorElements[i]).hasClass(STATE_CLASSES.HIDE)) {
                $firstFieldError = $(errorElements[i]).parents(SELECTORS.FIELD_CMP);

                break;
            }
        }

        scrollToMsg($firstFieldError);
    }
}
