import MainNamespace from 'buffalo/globalComponents/MainNamespace';

import cartWrapper from 'client/components/cart/CartWrapper';
import availability from 'client/components/product/Availability';
import imageCarousel from 'client/components/product/ImageCarousel';
import productMgr from 'client/components/product/ProductMgr';
import modalStickyButton from 'client/components/cart/ModalStickyButton';
import addToWishListButton from 'client/components/product/AddToWishListButton';
import addToCartProduct from 'buffalo/components/product/AddToCartProduct';
import variationAttributes from 'client/components/product/VariationAttributes';
import sizingSystemSwitch from 'client/components/product/SizingSystemSwitch';
import orderSummary from 'client/components/cart/OrderSummary';
import cartCoupon from 'client/components/cart/CartCoupon';
import productComponent from 'client/components/product/ProductComponent';
import productCard from 'client/components/product/ProductCard';
import productCardSizeSelect from 'buffalo/components/product/ProductCardSizeSelect';
import einsteinCarousel from 'client/components/EinsteinCarousel';

const mainNamespace = new MainNamespace();

mainNamespace.addComponents({
    cartWrapper,
    productMgr,
    variationAttributes,
    sizingSystemSwitch,
    availability,
    addToWishListButton,
    modalStickyButton,
    addToCartProduct,
    imageCarousel,
    cartCoupon,
    orderSummary,
    productComponent,
    productCard,
    productCardSizeSelect,
    einsteinCarousel
});

mainNamespace.init();
