import Component from 'client/core/Component';

const MENU_SHOW_TIMEOUT = 250;

const SELECTORS = {
    menuItem: '.js-main-menu-item'
};

const STATE_CLASSES = {
    menuHover: 'h-hover-submenu',
    menuShow: 'h-show-submenu'
};

export default class MainMenu extends Component {
    init () {
        this.$menuItem = this.$el.children(SELECTORS.menuItem);
        this.bindEvent('mouseenter', SELECTORS.menuItem, this.showSubMenu);
        this.bindEvent('mouseleave', SELECTORS.menuItem, this.hideSubMenu);
    }

    showSubMenu() {
        this.$menuItem.removeClass(STATE_CLASSES.menuShow);
        this.$menuItem.addClass(STATE_CLASSES.menuHover);
        setTimeout(() => {
            this.$menuItem.addClass(STATE_CLASSES.menuShow);
        }, MENU_SHOW_TIMEOUT);
    }

    hideSubMenu() {
        this.$menuItem.removeClass(STATE_CLASSES.menuHover);
        this.$menuItem.removeClass(STATE_CLASSES.menuShow);
    }
}
