import BaseGlobalCustomSelect from 'client/components/forms/commonElements/GlobalCustomSelect';
import $ from 'jquery';

const SELECTORS = {
    'NATIVE_SELECT': '.js-native-select',
    'CUSTOM_SELECT': '.js-custom-select',
    'CUSTOM_SELECT_HEADER': '.js-custom-select-header',
    'CUSTOM_SELECT_TITLE': '.js-custom-select-title',
    'CUSTOM_SELECT_LIST': '.js-custom-select-list',
    'CUSTOM_LIST_ITEM': '.js-custom-select-item',
    'CUSTOM_SELECT_COMPONENT': '.js-custom-select-cmp',
    'CUSTOM_LOCALIZATION_SELECTOR': '#localizationLocaleSelector',
    'SEARCH_COUNTRY_INPUT': '.js-search-country-selector-input',
    'SEARCH_COUNTRY_SELECT': '.js-country-select',
    'SEARCH_COUNTRY_HEADER': '.js-custom-select-header',
    'SEARCH_COUNTRY_TITLE': '.js-custom-select-title'
};

const STATE_CLASSES = {
    selectPlaceholder: 'f-custom-select-title--placeholder',
    itemSelected: 'h-item-selected'
};

export default class GlobalCustomSelect extends BaseGlobalCustomSelect {
    init(reInit = false) {
        this.nativeSelect = this.$el.find(SELECTORS.NATIVE_SELECT);
        this.countrySelector = this.$el.find(SELECTORS.SEARCH_COUNTRY_INPUT);
        this.countryHeader = this.$el.find(SELECTORS.SEARCH_COUNTRY_HEADER);
        this.customSelect = this.$el.find(SELECTORS.CUSTOM_SELECT);
        this.customSelectTitle = this.$el.find(SELECTORS.CUSTOM_SELECT_TITLE);
        this.customSelectList = this.$el.find(SELECTORS.CUSTOM_SELECT_LIST);
        this.countrySelect = this.$el.find(SELECTORS.SEARCH_COUNTRY_SELECT);
        this.countryTitle = this.$el.find(SELECTORS.SEARCH_COUNTRY_TITLE);
        this.isOpen = false;
        this.isSelected = false;
        if (reInit) {
            this.updateSelectOptions();
        } else {
            this.renderSelect();
        }
        this.changeCustomSelect(this.nativeSelect.find('option:selected').val());

        let hideItemsRestriction = typeof this.config.maxItemsToShow !== 'undefined' &&
            this.config.maxItemsToShow === 0;

        if (!this.nativeSelect.prop('disabled') && !this.config.disabled && !hideItemsRestriction) {
            this.initEvents();
        }

        if (this.config.id) {
            this.emitter.addListener(`globalCustomSelect.${this.config.id}.enable`, () => this.onSelectEnable());
        }

        this.keySearchCountry(
            this.countryHeader,
            this.countrySelector,
            this.countrySelect,
            this.countryTitle,
            this.customSelectList[0].children
        );
    }

    onSelectEnable() {
        if (!this.nativeSelect.prop('disabled') && !this.config.disabled) {
            return;
        }

        this.initEvents();

        this.nativeSelect.removeProp('disabled');
        this.config.disabled = false;
    }

    initEvents() {
        this.bindEvent('click', SELECTORS.CUSTOM_LIST_ITEM, this.itemClick.bind(this));
        this.bindEvent('click', SELECTORS.CUSTOM_SELECT_HEADER, this.listToggle.bind(this));
        this.bindEvent('change', SELECTORS.NATIVE_SELECT, this.changeTitle.bind(this));
        this.bindEvent('keydown', SELECTORS.CUSTOM_SELECT, this.accessibleClick.bind(this));

        this.emitter.addListener('custom.select.open', (that) => this.onSelectOpen(that));
        this.emitter.addListener('custom.select.close', () => this.listHide());
        this.emitter.addListener('globalClick', this.globalClick.bind(this));
        this.emitter.addListener('globalWindowBlur', this.listHide.bind(this));
        this.emitter.addListener(`custom.select.${this.config.id}.change`, () => this.changeTitle());
        this.emitter.addListener(`custom.select.${this.config.id}.options.updated`, () => this.updateSelectOptions());
    }

    itemClick(el) {
        const value = $(el).data('value');

        this.nativeSelect.val(value).trigger('change');

        this.flagChange(value);
        $(SELECTORS.CUSTOM_LOCALIZATION_SELECTOR).val(value).trigger('change');
    }

    flagChange(value) {
        var $div = $('div.b-localization-form-flags');

        $div.find('div').removeClass(STATE_CLASSES.itemSelected);
        $div.find(`div[data-value="${value}"]`).addClass(STATE_CLASSES.itemSelected);
    }

    keySearchCountry(searchHeader, searchField, selectWrapper, searchTitle, searchElements) {
        var query;

        $(searchHeader).on('click', function() {
            $(searchElements).show();
            query = $(this).find(searchTitle).text().trim();

            if (!$(selectWrapper).hasClass('h-custom-select-opened')) {
                $(searchField).attr('placeholder', query);
                $(searchField).focus().val('');
            }
        });


        $(searchField).on('keyup', function(e) {
            e.preventDefault();

            query = $(this).val().toLowerCase();

            if (query) {
                $.each($(searchElements), function() {
                    let title = $(this).text().toLowerCase().trim();

                    if (title.indexOf(query) === 0) {
                        $(this).show();
                    } else {
                        $(this).hide();
                    }
                });
            } else {
                $(searchElements).show();
            }
        });

        $(selectWrapper).on('keydown', function(e) {
            e.stopPropagation();
        });
    }
}
