import Component from 'client/core/Component';
import { compile } from 'handlebars';
import prefs from 'sitePreferences';

import $ from 'jquery';
import PhotoSwipe from 'photoswipe';
// eslint-disable-next-line
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

import { isSmall, isMedium } from 'client/utils/screendetector';

const animationThumbnail = (prefs.photoswipeConfig && prefs.photoswipeConfig.getThumbBoundsFnCssSelector) ||
'.js-pdp-carousel .slick-slide.slick-current.slick-active img';

const SELECTORS = {
    pswpElement: '.js-photoswipe-gallery',
    thumb: '.js-photoswipe-thumbnails-item',
    activeThumb: '.js-photoswipe-thumbnails-item.active',
    animationThumbnail: animationThumbnail,
    target: '.js-target',
    source: '.js-source'
};

const CLASSES = {
    active: 'active'
};


export default class PhotoswipeGallery extends Component {
    init() {
        this.photoswipeConfig = prefs.photoswipeConfig || {};
        this.zoomConfig = this.getZoomConfig();
        this.zooming = true;
        this.zoomLevel = null;
        this.initialZoomLevel = null;
        this.currentStepZoom = this.zoomConfig.minZoomLevel;

        if (this.photoswipeConfig.enabled) {
            this.emitter.addListener('init.photoswipe.gallery', (data) => this.initPhotoswipe(data));
            this.emitter.addListener(
                'variation.updated',
                (product, queryParams, photoswipeData) => this.onVariationUpdate(queryParams, photoswipeData)
            );
            this.bindEvent('mouseenter click', SELECTORS.thumb, (el, ev) => this.handleThumbnails(el, ev));
        }
    }

    initPhotoswipe(data) {
        const pswpElement = document.querySelector(SELECTORS.pswpElement);
        const photoswipeImagesAttr = (isSmall() || isMedium()) ? 'photoswipeMobileImages' : 'photoswipeDesctopImages';
        const photoswipeImages = this.$el.find(SELECTORS.pswpElement).data(photoswipeImagesAttr);
        const currentCarouselItemIndex = data.$slickCarouselItem.attr('data-slick-index');
        const photoswipeConfigOptions = this.photoswipeConfig.options || {};
        const photoswipeConfigOptionsMobile = this.photoswipeConfig.optionsMobile || {};

        let options = Object.assign({}, photoswipeConfigOptions, {
            index: Number(currentCarouselItemIndex),
            getDoubleTapZoom: this.getDoubleTapZoom.bind(this)
        });

        if (isSmall() || isMedium()) {
            options = Object.assign({}, options, photoswipeConfigOptionsMobile);
        }

        if (!data.touchLength || data.touchLength < 1) {
            options.getThumbBoundsFn = this.getThumbBoundsFn;
        }

        this.gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, photoswipeImages, options);
        this.gallery.init();
        this.showZoomInCursor();
        this.gallery.listen('beforeChange', this.resetZoomState.bind(this));
        this.gallery.listen('afterChange', function() {
            const currentIndex = this.gallery.getCurrentIndex();

            this.handleThumbnailsActiveClass(currentIndex);
        }.bind(this));
        this.gallery.listen('close', function() {
            const index = this.gallery.getCurrentIndex();

            this.emitter.emit('on.photoswipe.gallery.close', index);
        }.bind(this));
        this.gallery.listen('close', this.resetZoomState.bind(this));
        this.handleThumbnailsActiveClass(currentCarouselItemIndex);

        if (data.touchLength > 1) {
            this.gallery.zoomTo(
                this.zoomConfig.minZoomLevel || 1,
                { x: this.gallery.viewportSize.x / 2, y: this.gallery.viewportSize.y / 2 },
                2000
            );
        }
    }

    getThumbBoundsFn() {
        const thumbnail = document.querySelector(SELECTORS.animationThumbnail);
        let pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
        let rect = thumbnail.getBoundingClientRect();

        return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
    }

    handleThumbnails(el, ev) {
        const clickedIndex = $(ev.currentTarget).data('index');

        this.handleThumbnailsActiveClass(clickedIndex);
        this.gallery.goTo(clickedIndex);
    }

    handleThumbnailsActiveClass(index) {
        this.$el.find(SELECTORS.activeThumb).removeClass(CLASSES.active);
        this.$el.find(SELECTORS.thumb).eq(index).addClass(CLASSES.active);
    }

    resetZoomState() {
        this.zooming = true;
        this.zoomLevel = this.initialZoomLevel;
        this.currentStepZoom = this.zoomConfig.minZoomLevel;
        this.showZoomInCursor();
    }

    showZoomInCursor() {
        this.$el.find(SELECTORS.pswpElement)
                .removeClass('pswp-zoom-out-activated')
                .addClass('pswp-zoom-in-activated');
    }

    showZoomOutCursor() {
        this.$el.find(SELECTORS.pswpElement)
                .removeClass('pswp-zoom-in-activated')
                .addClass('pswp-zoom-out-activated');
    }

    getDoubleTapZoomForDesktop(item) {

        this.initialZoomLevel = item.initialZoomLevel;

        if (!this.zoomLevel) {
            this.zoomLevel = item.initialZoomLevel;
        }

        let res;
        let { minZoomLevel, maxZoomLevel, zoomLevelStep, stepsOnZoomOut } = this.zoomConfig;

        if (this.zooming) {
            if (this.zoomLevel < this.currentStepZoom) {
                res = this.currentStepZoom;
            }

            if (this.currentStepZoom >= maxZoomLevel) {
                this.zooming = false;
                this.showZoomOutCursor();
            }

            if (this.currentStepZoom < maxZoomLevel) {
                this.currentStepZoom += zoomLevelStep;
            }

            this.zoomLevel = res;
            return res;
        } else {
            if (!stepsOnZoomOut) {
                res = item.initialZoomLevel;
                this.resetZoomState();
                return res;
            }

            if (this.currentStepZoom <= minZoomLevel) {
                this.zooming = true;
                this.showZoomInCursor();
            }

            if (this.currentStepZoom > minZoomLevel) {
                this.currentStepZoom -= zoomLevelStep;
            }

            if (this.zoomLevel >= this.currentStepZoom) {
                res = this.currentStepZoom;
            }

            if (this.zoomLevel <= this.currentStepZoom) {
                res = item.initialZoomLevel;
            }

            this.zoomLevel = res;
            return res;
        }
    }

    getDoubleTapZoomForMobile(item) {

        this.initialZoomLevel = item.initialZoomLevel;

        if (!this.zoomLevel) {
            this.zoomLevel = item.initialZoomLevel;
        }

        let res;
        let isReseted = false;
        let { maxZoomLevel, zoomLevelStep } = this.zoomConfig;

        if (this.zoomLevel < this.currentStepZoom) {
            res = this.currentStepZoom;
            this.zoomLevel = res;
        }

        if (this.currentStepZoom >= maxZoomLevel) {
            res = maxZoomLevel;
            this.resetZoomState();
            isReseted = true;
        }

        if ((this.currentStepZoom < maxZoomLevel) && !isReseted) {
            this.currentStepZoom += zoomLevelStep;
        }

        return res;
    }

    getDoubleTapZoom(isMouseClick, item) {
        if (isMouseClick) {
            return this.getDoubleTapZoomForDesktop(item);
        } else {
            return this.getDoubleTapZoomForMobile(item);
        }
    }

    onVariationUpdate(queryParams, photoswipeData) {
        if (queryParams.chosen !== 'color') {
            return;
        }

        this.$target = this.$el.find(SELECTORS.target);
        this.source = this.$el.find(SELECTORS.source).html();
        this.template = compile(this.source);

        if (this.template) {
            const result = this.template(photoswipeData);

            this.$target.html(result);
        }
    }

    getZoomConfig() {
        const zoomConfigFromPrefs = this.photoswipeConfig.zoomConfig || {};
        const defaultZoomComfig = {
            minZoomLevel: 1,
            maxZoomLevel: 2,
            zoomLevelStep: 1,
            stepsOnZoomOut: true
        };
        const currentZoomConfig = Object.assign({}, defaultZoomComfig, zoomConfigFromPrefs);

        return currentZoomConfig;
    }

}
