import Component from 'client/core/Component';

import ajax from 'client/utils/ajax';
import $ from 'jquery';
import { CLASSES } from 'client/utils/globals';
import { scrollToMsg } from 'client/utils/common';
import { appendParamToURL } from 'client/utils/url';

const SELECTORS = {
        toggleBtn: '.js-coupon-toggle-btn',
        formContainer: '.js-coupon-form-container',
        form: '.js-coupon-form',
        successMsgAdd: '.js-coupon-success',
        successMsgRemoved: '.js-coupon-removed',
        error: '.js-coupon-error',
        errorMessage: '.js-coupon-error-msg',
        couponCode: '#couponCode',
        submitBtn: '.js-coupon-submit-btn',
        removeItem: '.js-coupon-remove',
        couponCodeItemLine: '.js-coupons-container'
    },
    SUCCESS_MESSAGE_TIMEOUT = 3000,
    COUPON_CODE_NIN_LENGTH = 3,
    COUPON_CODE_START_LENGTH = 1;

export default class CartCoupon extends Component {

    hideSuccessAddMessage() {
        const $elements = this.$elements,
            $successMsgAdd = $elements.successMsgAdd;

        !$successMsgAdd.hasClass(CLASSES.hide) && $successMsgAdd.addClass(CLASSES.hide);
    }

    showSuccessAddMessage() {
        const $elements = this.$elements,
            $successMsgAdd = $elements.successMsgAdd;

        $successMsgAdd.hasClass(CLASSES.hide) && $successMsgAdd.addClass(CLASSES.hide);
    }

    hideSuccessRemoveMessage() {
        const $elements = this.$elements,
            $successMsgRemoved = $elements.successMsgRemoved;

        !$successMsgRemoved.hasClass(CLASSES.hide) && $successMsgRemoved.addClass(CLASSES.hide);
    }

    showSuccessRemoveMessage() {
        const $elements = this.$elements,
            $successMsgRemoved = $elements.successMsgRemoved;

        $successMsgRemoved.hasClass(CLASSES.hide) && $successMsgRemoved.removeClass(CLASSES.hide);
    }

    resetForm() {
        const $elements = this.$elements;

        this.toggleSubmitBtn(false);

        $elements.couponCode.val('');
    }

    onSuccess(evType) {
        if (this.successTimeout) {
            clearTimeout(this.successTimeout);
            this.successTimeout = null;
        }

        this.successTimeout = setTimeout(() => {
            this.successTimeout = null;

            switch (evType) {
                case 'add':
                    this.resetForm();
                    this.hideSuccessAddMessage();
                    break;
                case 'remove':
                    this.hideSuccessRemoveMessage();
                    break;
                default:
                    break;
            }


        }, SUCCESS_MESSAGE_TIMEOUT);

    }

    onError(resp) {

        const $error = this.$elements.error;

        if (resp === 'reset') {
            $error.addClass(CLASSES.hide);
            return;
        }

        const errorMessage = resp.errorMessage || $error.data('default-message');

        this.$elements.errorMessage.html(errorMessage);

        $error.removeClass(CLASSES.hide);
        this.$elements.form.addClass(CLASSES.disabled);

        const code = this.getCouponCode();

        this.toggleSubmitBtn(code.length >= COUPON_CODE_START_LENGTH);

        scrollToMsg($error);
    }

    getCouponCode() {
        var value = this.$elements.couponCode.val().trim();

        this.$elements.couponCode.val(value);
        return value;
    }

    toggleSubmitBtn(enable) {
        this.$elements.submitBtn.prop('disabled', !enable);
        this.$elements.form.removeClass(CLASSES.disabled);
        this.$elements.form.toggleClass(CLASSES.readyToSubmit, enable);
    }

    onCodeChange(ev) {
        const code = this.getCouponCode();

        this.onError('reset');

        this.toggleSubmitBtn(code.length >= COUPON_CODE_START_LENGTH);

        if (code.length >= COUPON_CODE_START_LENGTH) {
            this.$elements.form.addClass(CLASSES.active);
        } else {
            this.$elements.form.removeClass(CLASSES.active);
        }

        const keyCode = ev.keyCode || ev.which;

        if (keyCode === '13' && code && (code.length >= COUPON_CODE_NIN_LENGTH)) {
            this.$elements.form.submit();
        }
    }

    updateCouponsContainer(discountsHtml) {
        this.$elements.couponCodeItemLine.html(discountsHtml);
        this.$elements.removeItem = this.$el.find(SELECTORS.removeItem);
    }

    onSubmit(el, ev) {
        ev.preventDefault();

        const code = this.getCouponCode(),
            $elements = this.$elements,
            $form = $elements.form,
            $error = $elements.error,
            $successMsgAdd = $elements.successMsgAdd,
            url = $form.attr('action');

        if (code && (code.length >= COUPON_CODE_NIN_LENGTH) && url) {
            !$error.hasClass(CLASSES.hide) && $error.addClass(CLASSES.hide);
            !$successMsgAdd.hasClass(CLASSES.hide) && $successMsgAdd.addClass(CLASSES.hide);

            this.toggleSubmitBtn(false);

            const data = $form.serializeArray();

            ajax.getJson({
                url,
                data
            }).then((resp) => {

                if (resp && resp.error) {
                    this.onError(resp);
                } else {
                    this.$elements.couponCode.val('');
                    this.emitter.emit('cart.coupon.applied', resp);
                    this.emitter.emit('cart.updated', resp);
                    this.showSuccessAddMessage();
                    this.updateCouponsContainer(resp.totals.discountsHtml);
                    this.$elements.form.removeClass(CLASSES.active);
                    this.onSuccess('add');
                }
            });

        }
    }

    onRemoveClick(el, ev) {
        const $el = $(ev.currentTarget);
        const uuid = $el.attr('coupon-uuid');
        let url = window.Urls.removeCouponUrl;

        if (uuid) {
            url = appendParamToURL(url, 'uuid', uuid);
        }

        let promise = ajax.getJson({
            url: url,
            type: 'GET'
        });

        promise.then(resp => {

            if (resp && resp.error) {
                this.onError(resp);
            } else {
                this.emitter.emit('cart.coupon.removed', resp);
                this.emitter.emit('cart.updated', resp);
                this.showSuccessRemoveMessage();
                this.updateCouponsContainer(resp.totals.discountsHtml);
                this.onSuccess('remove');
            }
        });

        return false;
    }


    initElements() {
        this.$elements = {};

        for (let key of Object.keys(SELECTORS)) {
            this.$elements[key] = this.$el.find(SELECTORS[key]);
        }
    }

    init() {
        this.initElements();

        this.bindEvent('click', SELECTORS.removeItem, this.onRemoveClick);
        this.bindEvent('keyup', SELECTORS.couponCode, this.onCodeChange);
        this.bindEvent('input', SELECTORS.couponCode, this.onCodeChange);
        this.bindEvent('submit', SELECTORS.form, this.onSubmit);
    }
}
