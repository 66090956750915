import ProductCard from 'client/components/product/ProductCard';
import ajax from 'client/utils/ajax';
import { CLASSES as GLOBAL_CLASSES } from 'client/utils/globals';

const CLASSES = {
    notReadyToAdd: 'h-not-ready-to-add'
};

export default class WishlistProductCard extends ProductCard {
    addToCart() {
        const $btn = this.$.addToCartBtn,
            url = $btn.data('url'),
            pid = $btn.data('pid'),
            options = $btn.data('options'),
            quantity = 1;

        if (url && pid && !$btn.hasClass(CLASSES.notReadyToAdd) && !$btn.hasClass(GLOBAL_CLASSES.productAdded)) {
            this.resetAddError();
            this.emitter.emit('cart.add.adding');

            ajax.getJson({
                url: url,
                type: 'POST',
                data: {
                    pid,
                    options,
                    quantity
                }
            }).then((resp) => {
                if (resp) {
                    if (!resp.error) {
                        this.onAdded(resp);
                    } else if (resp.message) {
                        this.onAddError(resp.message);
                    }
                }
            });
        }
    }

    onAdded(resp) {
        this.emitter.emit('cart.add.added', resp, 'Wishlist/Wishlist');

        this.removeFromWishlist();
    }

    removeFromWishlist() {
        const $btn = this.$.removeCardBtn,
            url = $btn.data('url');

        if (url) {
            ajax.getJson({
                url
            }).then((resp) => {
                if (resp && resp.success) {
                    this.emitter.emit('wishlist.removed', resp);

                    this.remove();
                }
            });
        }
    }

    onRemove() {
        this.removeFromWishlist();
    }

    onSizeChanged(opid, npid, init) {
        super.onSizeChanged(opid, npid);

        if (npid && (npid !== opid) && !init) {
            ajax.getJson({
                url: this.config.url,
                type: 'POST',
                data: {
                    npid,
                    opid
                }
            }).then((resp) => {
                if (resp && resp.success && resp.deleteURL) {
                    this.$.removeCardBtn.data('url', resp.deleteURL);
                }
            });
        }
    }
}
