import InputSelect from 'client/components/forms/commonElements/InputSelect';

export default class CountryField extends InputSelect {
    init () {
        super.init();

        this.addListener('changed', this.onCountryChanged.bind(this));
    }

    setValue (newVal, silently = false) {
        this.$field.val(newVal);
        this.placeholderToggle();

        if (silently) {
            this.emit(`custom.select.${this.config.id}-select.change`);
        } else {
            this.emit('changed', newVal);
            this.inputField.trigger('change');
            this.validate();
        }
    }

    onCountryChanged(data) {
        const language = this.$field.find(':selected').data('language');
        const options = {
            language
        };

        this.emitter.emit(this.config.event, data, options);
    }

    destroy() {
        this.removeListener('changed', this.onCountryChanged.bind(this));

        super.destroy();
    }
}
