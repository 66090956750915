'use strict';

import { pxAdvancedBlockingResponse } from 'sitePreferences';
import templatesManager from 'client/utils/templatesManager';

function stringToHTML(str) {
    var dom = document.createElement('div');

    dom.innerHTML = str;
    return dom;
}

function handleResponse(data) {
    let pxScript = document.createElement('script');
    let pxCaptchaTemplate = templatesManager.templates.pxCaptchaTemplate;

    window._pxAppId = data.appId;
    window._pxJsClientSrc = data.jsClientSrc;
    window._pxFirstPartyEnabled = data.firstPartyEnabled;
    window._pxVid = data.vid;
    window._pxUuid = data.uuid;
    window._pxHostUrl = data.hostUrl;

    pxScript.src = data.blockScript;

    document.getElementsByTagName('head')[0].appendChild(pxScript);
    document.body.innerHTML = '';
    document.body.appendChild(
        stringToHTML(
            pxCaptchaTemplate({})
        )
    );
}

function errorHandler(xhr, textStatus, errorThrown) { // eslint-disable-line no-unused-vars
    if (xhr.status === 403) {
        handleResponse(xhr.responseJSON);

        window._pxOnCaptchaSuccess = function(isValid) {
            if (isValid) {
                document.querySelector('#px-captcha').remove();
                delete window._pxOnCaptchaSuccess;
                window.location.reload();
            }
        };
    }
}

export function perimeterXErrorHandler(registerErrorHandler) {
    pxAdvancedBlockingResponse && registerErrorHandler(errorHandler);
}
