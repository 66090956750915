import Component from 'client/core/Component';
import $ from 'jquery';

const SELECTORS = {
    categoryCarousel: '.js-category-carousel'
};

export default class CategoryCarousel extends Component {
    init() {
        this.emitter.addListener(
            'refinement.updated',
            ($response) => this.updateCategoryCarousel($response)
        );
    }

    updateCategoryCarousel($response) {
        const $categoryCarousel = $(SELECTORS.categoryCarousel);
        const categoryCarouselContent = $response.find(SELECTORS.categoryCarousel).html();

        if ($categoryCarousel) {
            $categoryCarousel.html(categoryCarouselContent);

            this.emitter.emit('namespace.component.reinit', this.$el, {
                carousels: true
            });
        }
    }
}
