/* global google */

import GoogleMapsGeoAbstractComponent from './GoogleMapsGeoAbstractComponent';
import { getLocation } from 'client/utils/location';

export default class GoogleGeocoder extends GoogleMapsGeoAbstractComponent {
    init () {
        this.bindEvent('click', this.onClick);
    }

    async onClick() {
        try {
            const position = await getLocation();

            let geoData = {
                location: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                }
            };

            let { postalCode } = await this.geocode(geoData);

            this.changeCollectedData({
                location: geoData.location,
                postalCode
            });
        } catch (er) {
            console.log(`GoogleMapsGeocoderCmp: ${er}`);
            this.fireError({ msg: this.config.geolocationError });
        }
    }
}
