/* global google */

import GoogleMapsGeoAbstractComponent from './GoogleMapsGeoAbstractComponent';
import $ from 'jquery';

export default class GoogleMapsAutocomplete extends GoogleMapsGeoAbstractComponent {
    /**
     * @private
     */
    _initComponent () {
        if (this.config.inputField) {
            this.getNestedComponentById(this.config.inputField, cmp => {
                this.fieldCmp = cmp;

                this.giComponent = new google.maps.places.Autocomplete(
                    cmp.$field[0],
                    {
                        fields: ['address_component', 'geometry'],
                        strictBounds: true,
                        types: ['geocode'],
                        componentRestrictions: { country: this.config.countryCode }
                    }
                );

                this.giComponent.setFields(['address_component']);

                this.giComponent.setComponentRestrictions({
                    country: this.config.countryRestriction || []
                });
            });
        }
    }

    async getGeoCoords () {
        if (!this.fieldCmp || typeof this.fieldCmp.getValue !== 'function') {
            throw new Error('Child component is missed');
        }

        let value = this.fieldCmp.getValue();

        if (!value) {
            return {
                lat: null,
                lng: null
            };
        }

        return (await this.geocode({
            address: value,
            componentRestrictions: { country: this.config.countryCode }
        })).location;
    }

    getField () {
        return this.fieldCmp;
    }

    destroy () {
        super.destroy();

        $('.pac-container.pac-logo').remove();
    }
}
