'use strict';

import $ from 'jquery';
import Handlebars from 'handlebars';
import handlebarsHelper from 'client/utils/handlebarsHelper';

let templates = null;

const templatesManager = {
    templates: null,
    getTemplates: function() {
        return this.templates;
    }
};

Object.defineProperty(templatesManager, 'templates', {
    get: function() {
        if (!templates) {
            handlebarsHelper(Handlebars);

            templates = {};

            $("script[type='text/x-handlebars-template']").each(function() {
                let $element = $(this),
                    template = Handlebars.compile($element.html());

                templates[$element.attr('id')] = template;
            });
        }

        return templates;
    },
    enumerable: true,
    configurable: true
});

export default templatesManager;
