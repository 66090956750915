import InputSelect from 'client/components/forms/commonElements/InputSelect';

export default class StateField extends InputSelect {
    init () {
        super.init();

        if (this.config.event) {
            this.emitter.addListener(this.config.event, data => this.onStateFieldChanged(data));
        }
    }

    onStateFieldChanged(stateCode) {
        if (stateCode) {
            this.setValue(stateCode);
        }
    }
}
