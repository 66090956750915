import BasePlaceOrderStep from 'client/components/checkout/PlaceOrderStep';
import $ from 'jquery';
import { CLASSES } from 'client/utils/globals';

const SELECTORS = {
    CHECKOUT_ORDER_SUMMARY: '.js-checkout-order-summary',
    SPECIFIC_INFO: '.js-checkout-restriction-specific-info'
};

const STATE_CLASSES = {
    CHECKOUT_REVIEW_ACTIVE: 'b-cart-order-summary--review'
};

export default class PlaceOrderStep extends BasePlaceOrderStep {
    init() {
        super.init();
        this.$checkoutOrderSummary = $(SELECTORS.CHECKOUT_ORDER_SUMMARY);
        this.$restrictionSpecificInfo = $(SELECTORS.SPECIFIC_INFO);
    }

    active(stepIndex, currentStep) {
        super.active(stepIndex, currentStep);
        this.$checkoutOrderSummary.addClass(STATE_CLASSES.CHECKOUT_REVIEW_ACTIVE);
        this.$restrictionSpecificInfo.removeClass(CLASSES.hide);
    }

    inactive(stepIndex, currentStep) {
        super.inactive(stepIndex, currentStep);
        this.$checkoutOrderSummary.removeClass(STATE_CLASSES.CHECKOUT_REVIEW_ACTIVE);
        this.$restrictionSpecificInfo.addClass(CLASSES.hide);
    }
}
