import { extractPostalCode, extractLocation } from 'client/utils/googleGeolocation';

const MODEL_TYPE_MAP = {
    'pickUpStation': 'pick-up-station'
};

export default class Finder {
    constructor(configuration, Google) {
        this.google = Google;
        this.zip = null;
        this.city = null;
        this.initLocationField();
        this.isZipCodeDetected = false;
        this.isLocationDetected = false;
        this.type = this.getModelType(configuration.type);
        this.countryCode = configuration.countryCode;

        this.setMarker(configuration);

        this.infoWindow = new Google.maps.InfoWindow({ maxWidth: 360 });
    }

    setMarker(config) {
        this.marker = {
            url: config.iconUrl,
            storeLocator: config.iconUrl,
            packstation: config.iconPackstationUrl,
            filiale: config.iconPostfilialeUrl,
            hermes: config.iconHermesUrl,
            sda: config.iconSdaUrl,
            origin: new this.google.maps.Point(0, 0),
            anchor: new this.google.maps.Point(17, 46),
            scaledSize: new this.google.maps.Size(45, 46)
        };
    }

    setLocation(latOrLocation, lng, isLocationDetected) {
        if (lng) {
            if (!this.location) {
                this.initLocationField();
            }

            this.location.lat = latOrLocation;
            this.location.lng = lng;
        } else {
            this.location = latOrLocation;
        }

        this.isLocationDetected = isLocationDetected;
    }

    getLocation() {
        return this.location;
    }

    getModelType(rawType) {
        return MODEL_TYPE_MAP[rawType] || rawType;
    }

    setAddress(zipCode, isDetected, city = null) {
        this.city = city;
        this.zip = zipCode;
        this.isZipCodeDetected = isDetected;

        if (!isDetected) {
            this.setLocation(null, null);
        }
    }

    getAddress() {
        return this.zip ? this.zip : this.city;
    }

    async processValue(value) {
        if (this.type === 'storeLocator') {
            let address = await this.geocode(null, value);

            this.setLocation(address.location);
            this.setAddress(address.postalCode, true, value);
        } else if (isNaN(value)) {
            this.setAddress(null, false, value);
        } else {
            this.setAddress(value, false, null);
        }
    }

    async geocode(location, address, retrievePostalCode) {
        let geocoder = new this.google.maps.Geocoder(),
            query = this.countryCode && !retrievePostalCode ? {
                componentRestrictions: {
                    country: this.countryCode
                }
            } : {},
            finalResults = {};

        if (location) {
            query.location = new this.google.maps.LatLng(location.lat, location.lng);
        } else if (address) {
            query.address = address;
        }

        let results = await this.getResults(geocoder, query);

        results = this.processGeoCodeResult(results);

        if (results.location) {
            finalResults.location = results.location;
            finalResults.postalCode = results.postalCode;
        }

        if ((!results.location && results.postalCode) || (!results.postalCode && retrievePostalCode)) {
            let searchResults = await this.geocode(results.location);

            if (searchResults && searchResults.postalCode) {
                finalResults.postalCode = searchResults.postalCode;
            }
        }

        return finalResults;
    }

    async getResults (geocoder, query) {
        return await new Promise((res) => {
            geocoder.geocode(query, (results, status) => {
                if (status === this.google.maps.GeocoderStatus.OK) {
                    res(results);
                } else {
                    console.log(`GoogleMapsGeocoderCmp: status=${status}`); // eslint-disable-line
                    res(null);
                }
            });
        });
    }

    processGeoCodeResult(results) {
        let postalCode = results && results[0] ? extractPostalCode(results[0]) : null;
        let location = results && results[0] ? extractLocation(results[0]) : null;

        let retObj = {};

        if (postalCode) {
            if (this.type !== 'storeLocator' && postalCode && postalCode.indexOf(',') !== -1) {
                postalCode = postalCode.trim().split(',').pop().trim();
            }

            if (postalCode) {
                retObj.postalCode = postalCode;
            } else {
                console.log('GoogleMapsGeocoderCmp: postalCode is not detected'); // eslint-disable-line
                return null;
            }
        }

        retObj.location = location;

        return retObj;
    }

    initLocationField() {
        this.location = {
            lat: null,
            lng: null
        };
    }
}
