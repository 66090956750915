import Component from 'client/core/Component';
import { throttle } from 'lodash';
import $ from 'jquery';

const SELECTORS = {
    modalContainer: '.js-modal',
    modalOverlay: '.js-modal-overlay',
    modalContent: '.js-modal-content',
    quickViewStickyButton: '.js-sticky-button',
    cardContainer: '.js-card-video-link'
};

const FULL_HEIGHT_CLASS = 'modal-full-height';

const CONFIG = {
    allowSoftClosing: true
};

export default class ModalContainer extends Component {
    init () {
        this.config = Object.assign({}, CONFIG, this.config);

        this.bindEvent('click touchstart', this.hideModal.bind(this));

        this.throttledScroll = throttle(this.onContentScroll.bind(this), 100);
        this.$el.find(SELECTORS.modalContent).on('scroll', this.throttledScroll);

        this.$quickViewStickyButton = this.$el.find(SELECTORS.quickViewStickyButton);

        if (this.config.updateHeightEvent) {
            this.updateModalHeight();

            this.emitter.addListener(
                this.config.updateHeightEvent,
                () => this.updateModalHeight(this.config.contentSelector)
            );

            if (this.$quickViewStickyButton.length > 0) {
                this.emitter.addListener('variation.updated', () => this.reInitStickyButton());
            }
        }

        const overlayXtraClass = $(SELECTORS.cardContainer).data('overlay-helper-class');

        if (overlayXtraClass) {
            this.$el.find(SELECTORS.modalOverlay).addClass(overlayXtraClass);
        }
    }

    reInitStickyButton() {
        this.emitter.emit('namespace.component.reinit', this.$quickViewStickyButton);
    }

    updateModalHeight(actualContentSelector) {
        let $modalContainer = this.$el.find(SELECTORS.modalContainer);

        if (!actualContentSelector) {
            actualContentSelector = this.$el.find(SELECTORS.modalContent);
        }

        $modalContainer.removeClass(FULL_HEIGHT_CLASS);
        // calculate modal window height relative to window height and -10% from it
        if ((window.innerHeight - (window.innerHeight / 10)) < (this.$el.find(actualContentSelector).innerHeight())) {
            $modalContainer.addClass(FULL_HEIGHT_CLASS);
        } else {
            $modalContainer.removeClass(FULL_HEIGHT_CLASS);
        }
    }

    hideModal(el, ev) {
        if ($(ev.target).is(SELECTORS.modalOverlay) && this.config.allowSoftClosing) {
            this.emitter.emit('modal.close');
            this.emit('modal.close');
        }
    }

    onContentScroll(ev) {
        this.emitter.emit('modal.content.scrolled', $(ev.target));
    }

    destroy() {
        this.$el.find(SELECTORS.modalContent).off('scroll');

        super.destroy();
    }
}

