import Component from 'client/core/Component';
import { CLASSES } from 'client/utils/globals';
import { productShowUrl } from 'urls';
import prefs from 'sitePreferences';

import $ from 'jquery';

const SELECTORS = {
    livereachProductTile: '.js-product-tile[data-master-id]',
    productDetails: '.js-product-details',
    livereachWrapper: '.js-livereach-wrapper'
};

export default class Livereach extends Component {
    init () {
        if (prefs.livereachEnabled) {
            if (
                window.Livereach &&
                window.Livereach.EmbedFactory.createEmbedInDomElement
            ) {
                window.Livereach.embedHelper_openProductById = function (productId) {
                    window.location.href = productShowUrl + '?pid=' + productId;
                };

                const $productDetails = $(SELECTORS.productDetails);

                if ($productDetails.length) {
                    this.initOnPdp($productDetails);
                    this.emitter.addListener('variation.updated', this.reInitOnPdp.bind(this));
                } else {
                    this.scanProductTiles();
                }
            } else {
                // eslint-disable-next-line no-console
                console.error('window.Livereach.EmbedFactory.createEmbedInDomElement() is not initialized');
            }
        }
    }

    scanProductTiles () {
        const $tiles = $(SELECTORS.livereachProductTile);

        let productIds = [],
            masterIds = [];

        $tiles.each(function (index, el) {
            const isContentAvailableJSON = $(el).attr('data-livereach-content-available');
            const isContentAvailable = this.isContentAvailableForEmbedId(isContentAvailableJSON, this.config.embedid);

            if (isContentAvailable) {
                let productId = $(el).attr('data-pid');
                let masterId = $(el).attr('data-master-id');

                productIds.push(productId);
                masterIds.push(masterId);
            }
        }.bind(this));

        if (productIds.length && masterIds.length) {
            this.initLivereach(productIds, masterIds);
        } else {
            this.initLivereach(null, null);
        }
    }

    isContentAvailableForEmbedId (isContentAvailableJSON, embedId) {
        let isContentAvailableMap = {};

        try {
            isContentAvailableMap = JSON.parse(isContentAvailableJSON);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('JSON parse error:', e, isContentAvailableJSON);
        }

        return isContentAvailableMap[embedId] || false;
    }

    initOnPdp ($productDetails) {
        const isContentAvailableJSON = $productDetails.attr('data-livereach-content-available');
        const isContentAvailable = this.isContentAvailableForEmbedId(isContentAvailableJSON, this.config.embedid);
        const masterId = $productDetails.attr('data-master-id');

        if (isContentAvailable && masterId) {
            const variationGroupId = $productDetails.attr('data-variation-group-id');

            this.initLivereach([variationGroupId], [masterId]);
        }
    }

    reInitOnPdp (product) {
        if (product.productType === 'variationGroup') {
            const isContentAvailableJSON = product.livereach.isContentAvailableJSON;
            const isContentAvailable = this.isContentAvailableForEmbedId(isContentAvailableJSON, this.config.embedid);

            if (isContentAvailable) {
                this.initLivereach([product.livereach.variationGroupId], [product.livereach.masterId]);
            } else {
                this.$el.closest(SELECTORS.livereachWrapper).addClass(CLASSES.hide);
            }
        }
    }

    initLivereach (productIds, masterIds) {
        const domElement = this.$el.get(0);
        const embedId = this.config.embedid;

        if (embedId) {
            this.$el.closest(SELECTORS.livereachWrapper).removeClass(CLASSES.hide);
            window.Livereach.EmbedFactory.createEmbedInDomElement(
                domElement,
                embedId,
                {
                    filters: {
                        productIds: productIds,
                        productGroupIds: masterIds
                    },
                    localization: {
                        countryCode: this.config.countryCode,
                        language: this.config.locale
                    }
                }
            );
        }
    }
}
