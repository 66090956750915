import Component from 'client/core/Component';
import { throttle } from 'lodash';
import $ from 'jquery';
import { CLASSES } from 'client/utils/globals';
import { isIOS } from 'client/utils/mobileDetect';

const SELECTORS = {
    stickyElement: '.js-element-sticky-wrapper',
    checkoutBtn: '.js-checkout-btn'
};

export default class StickyButton extends Component {
    init() {
        this.$stickyElement = this.$el.find(SELECTORS.stickyElement);
        this.isSticky = true;
        this.disabledScroll = false;
        this.$window = $(window);
        this.onScroll({ scrollTop: this.$window.scrollTop() });
        this.emitter.addListener('scroll', throttle(this.onScroll.bind(this), 100));
        // eslint-disable-next-line no-return-assign
        this.emitter.addListener('scrollIsDisabled', () => (this.disabledScroll = true));
        // eslint-disable-next-line no-return-assign
        this.emitter.addListener('scrollIsEnabled', () => (this.disabledScroll = false));
        this.emitter.addListener('resize', this.onScroll.bind(this, this.$window.scrollTop()));
        this.emitter.addListener('orientationChange', this.onScroll.bind(this, this.$window.scrollTop()));

        if (this.gtmEnabled) {
            this.bindEvent('click', SELECTORS.checkoutBtn, this.onCheckoutBtnClick.bind(this));
        }

        this.tabbarHeight = 0;
        document.addEventListener('message', this.onTabbarHeightUpdate.bind(this), false);
        window.addEventListener('message', this.onTabbarHeightUpdate.bind(this), false);
    }

    onTabbarHeightUpdate(event) {
        let message = {};
        let reactNative = window.SnipesAppIntegration.getReactNative();

        if (!reactNative) {
            console.warn('It is not react native webview.'); // eslint-disable-line no-console
            return;
        }

        try {
            message = JSON.parse(event.data);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn('JSON parse is failed', e);
        }

        if (message.type === 'app.tabbar.height') {
            if (message.data && 'tabbarHeight' in message.data) {
                this.tabbarHeight = message.data.tabbarHeight || 0;
            }
        }
    }

    onCheckoutBtnClick() {
        if (!this.config.minicart) {
            return;
        }

        const data = this.config.gtm;
        const total = this.config.total;

        if (data) {
            this.emitter.emit('gtmEcommerceEvent', 'eeCheckout', data, {
                'eventCategory': 'cart',
                'eventAction': 'begin checkout',
                'eventLabel': 'mini cart',
                'eventNonInteraction': 'true',
                'ga4eventname': 'begin_checkout',
                'value': total
            });
        }
    }

    calculatePosition() {
        // eslint-disable-next-line no-restricted-globals
        let { height, width } = screen;

        // this.$el.css('min-height', this.$stickyElement.innerHeight());

        if (isIOS() && Math.abs(window.orientation) === 90) {
            height = width;
        }

        this.stickyElementPositionTop = (this.$el.offset().top - height) +
            this.$stickyElement.innerHeight();
        this.stickyElementPositionTop = this.stickyElementPositionTop < 0 ? 0 : this.stickyElementPositionTop;
    }

    onScroll({ scrollTop: scrollTop } = { scrollTop: 0 }) {
        this.calculatePosition();

        if (!this.disabledScroll) {
            if (this.isSticky && scrollTop >= this.stickyElementPositionTop) {
                this.$el.addClass(CLASSES.stickyElementStatic);
                this.isSticky = false;
            } else if (!this.isSticky && scrollTop < this.stickyElementPositionTop && scrollTop >= 0) {
                this.$el.removeClass(CLASSES.stickyElementStatic);
                this.isSticky = true;
            }
        }
    }
}
