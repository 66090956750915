import Component from 'client/core/Component';
import $ from 'jquery';

const SELECTORS = {
    accordionHead: '.js-accordion-head'
};

export default class DetailsAccordion extends Component {
    trackAccordionItemHeadClick(el) {
        if ($(el).attr('aria-expanded') === 'false') {
            const data = {
                eventCategory: 'product',
                eventAction: 'mobile product info',
                eventLabel: 'accordion'
            };

            this.emitter.emit('gtmEvent', data);
        }
    }

    init() {
        if (this.gtmEnabled) {
            this.bindEvent('click', SELECTORS.accordionHead, this.trackAccordionItemHeadClick.bind(this));
        }
    }
}
