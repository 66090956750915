import Component from 'client/core/Component';
import ajax from 'client/utils/ajax';
import { CLASSES as GLOBAL_CLASSES } from 'client/utils/globals';

const SELECTORS = {
    addToCartError: '.js-add-to-cart-error',
    addToCartErrorMessage: '.js-add-to-cart-error-message',
    addToCartBtn: '.js-add-to-cart-btn',
    removeCardBtn: '.js-remove-card-btn',
    sizeSelect: '.js-size',
    controls: '.js-card-controls',
    removeConfirmation: '.js-card-remove-confirmation',
    removeConfirmBtn: '.js-remove-confirm-btn',
    removeCancelBtn: '.js-remove-cancel-btn',
    badge: '.js-card-badge',
    releaseDate: '[data-release-date]',
    releaseDateLabel: '.js-release-date, .js-plp-release-date'
};

const CLASSES = {
    badge: 'b-product-tile-badge',
    notReadyToAdd: 'h-not-ready-to-add'
};

const SLICK = {
    slide: '.slick-slide'
};

const BTN_MSG_DELAY = 5000;

export default class ProductCard extends Component {
    onAdded(resp) {
        const $btn = this.$.addToCartBtn;

        $btn.addClass(GLOBAL_CLASSES.productAdded);

        setTimeout(() => {
            $btn.removeClass(GLOBAL_CLASSES.productAdded);
        }, BTN_MSG_DELAY);

        this.emitter.emit('cart.add.added', resp, 'category');
        this.emitter.emit('cart.updated', resp.cart);
    }

    onAddError(message) {
        this.$.addToCartErrorMessage.html(message);
        this.$.addToCartError.removeClass(GLOBAL_CLASSES.hide);
    }

    resetAddError() {
        this.$.addToCartError.addClass(GLOBAL_CLASSES.hide);
    }

    addToCart() {
        const $btn = this.$.addToCartBtn,
            url = $btn.data('url'),
            pid = $btn.data('pid'),
            options = $btn.data('options'),
            quantity = 1;

        if (url && pid && !$btn.hasClass(CLASSES.notReadyToAdd) && !$btn.hasClass(GLOBAL_CLASSES.productAdded)) {
            this.resetAddError();

            ajax.getJson({
                url: url,
                type: 'POST',
                data: {
                    pid,
                    options,
                    quantity
                }
            }).then((resp) => {
                if (resp) {
                    if (!resp.error) {
                        this.onAdded(resp);
                    } else if (resp.message) {
                        this.onAddError(resp.message);
                    }
                }
            });
        }
    }

    remove(callback) {
        this.destroy();
        this.$el.remove();

        callback && callback();
    }

    onRemove() {
        const $slide = this.$el.closest(SLICK.slide),
            $carousel = this.$el.closest('[data-cmp="carousel"]');

        if ($slide.length && $carousel.length) {
            const slideIndex = $slide.data('slick-index'),
                carousel = $carousel.data('cmp-instance');

            if ((typeof slideIndex !== 'undefined') && carousel) {
                $carousel.slick('slickRemove', slideIndex);

                this.emitter.emit('namespace.component.destroyall', $carousel, {
                    onAfterDestroy: () => {
                        this.remove(() => {
                            this.emitter.emit('namespace.component.initall', $carousel, {
                                onAfterInit: carousel.reindex()
                            });
                        });
                    }
                });
            }
        }
    }

    toggleConfirmation(show) {
        this.resetAddError();

        this.$.controls.toggleClass(GLOBAL_CLASSES.hide, show);
        this.$.removeConfirmation.toggleClass(GLOBAL_CLASSES.hide, !show);
    }

    onSizeChanged(opid, npid) {
        const available = this.$.sizeSelect.find('option:selected').data('available'),
            $addToCartBtn = this.$.addToCartBtn;

        this.resetAddError();

        $addToCartBtn.toggleClass(CLASSES.notReadyToAdd, !npid || !available);
        $addToCartBtn.prop('disabled', !npid || !available);
        $addToCartBtn.data('pid', npid);

    }

    onSizeChange(init = false) {
        const $nativeSizeSelect = this.$.sizeSelect.data('cmp-instance').nativeSelect,
            npid = $nativeSizeSelect.val(),
            $addToCartBtn = this.$.addToCartBtn,
            opid = $addToCartBtn.data('pid');

        if (npid || !init) {
            this.onSizeChanged(opid, npid, init);
        }
    }

    updateBadge(badge, code) {
        const $badge = this.$.badge,
            oldCode = $badge.data('code');

        this.resetAddError();

        $badge.removeClass(`${CLASSES.badge}--${oldCode}`).addClass(`${CLASSES.badge}--${code}`);
        $badge.data('code', code);

        $badge.html(badge);
        $badge.toggleClass(GLOBAL_CLASSES.hide, !badge);
    }

    resetSizeSelect() {
        const $sizeSelect = this.$.sizeSelect,
            sizeSelect = $sizeSelect.data('cmp-instance'),
            $nativeSizeSelect = sizeSelect.nativeSelect;

        $sizeSelect.data('disabled', '');
        $nativeSizeSelect.prop('disabled', false);

        sizeSelect.initEvents();
    }

    resetCard() {
        const $addToCartBtn = this.$.addToCartBtn;

        $addToCartBtn.prop('disabled', false);
        $addToCartBtn.html($addToCartBtn.data('label'));

        this.updateBadge('', 'instock');

        this.resetSizeSelect();
    }

    resetCountdown() {
        if (this.countdown) {
            clearInterval(this.countdown);
            this.countdown = null;
        }
    }

    updateCountdown(count) {
        const dd = Math.floor(count / (1000 * 60 * 60 * 24)),
            hh = Math.floor((count % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            mm = Math.floor((count % (1000 * 60 * 60)) / (1000 * 60)),
            ss = Math.floor((count % (1000 * 60)) / 1000);

        let label = [hh, mm, ss];

        label = label.map((val) => {
            return val.toString().padStart(2, '0');
        });

        if (dd) {
            label.unshift(dd);
        }

        const html = label.join(':');

        this.$.addToCartBtn.html(html);
        this.$.badge.html(html);
    }

    startCountdown(to) {
        this.countdown = setInterval(() => {
            const count = to - (new Date()).getTime();

            if (count >= 0) {
                this.updateCountdown(count);
            } else {
                this.resetCountdown();
                this.resetCard();
            }
        }, 1000);
    }

    touchCountdown() {
        const $addToCartBtn = this.$.addToCartBtn,
            releaseDate = $addToCartBtn.data('release-date');

        if (releaseDate) {
            const to = (new Date(releaseDate)).getTime(),
                count = to - (new Date()).getTime();

            if (count >= 0) {
                this.updateCountdown(count);
                this.startCountdown(to);
            }
        }
    }

    onCartUpdated() {
        this.resetAddError();
    }

    updateReleaseDate() {
        const $releaseDate = this.$el.find(SELECTORS.releaseDate),
            releaseDateUTC = $releaseDate.data('release-date'),
            isCountdown = $releaseDate.data('countdown'),
            $releaseDateLabel = this.$el.find(SELECTORS.releaseDateLabel),
            $addToCartBtn = this.$.addToCartBtn;

        if (releaseDateUTC) {
            const updated = this.formatDate(new Date(releaseDateUTC), isCountdown || false);
            const toUpdateLabel = new Date() < new Date(releaseDateUTC);

            if (toUpdateLabel) {
                $releaseDateLabel.html(updated);
                $addToCartBtn.html(updated);
            }
        }
    }

    init(reInit = false) {
        this.$ = {};

        for (let k of Object.keys(SELECTORS)) {
            this.$[k] = this.$el.find(SELECTORS[k]);
        }

        this.updateReleaseDate();
        this.touchCountdown();

        this.bindEvent('click', SELECTORS.addToCartBtn, this.addToCart);
        this.bindEvent('click', SELECTORS.removeCardBtn, () => {
            this.toggleConfirmation(true);
        });
        this.bindEvent('change', SELECTORS.sizeSelect, () => {
            this.onSizeChange(false);
        });
        this.bindEvent('click', SELECTORS.removeConfirmBtn, this.onRemove);
        this.bindEvent('click', SELECTORS.removeCancelBtn, () => {
            this.toggleConfirmation(false);
        });

        this.emitter.addListener('cart.updated', this.onCartUpdated.bind(this));

        if (!reInit) {
            this.onSizeChange(true);
        }
    }
}
