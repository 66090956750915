import BaseAutocompleteAddress from 'client/components/forms/AutocompleteAddress';
import { getJson, registerErrorHandler } from 'client/utils/ajax';
import { globalExpressPostalCodeUrl, globalExpressThoroughfareUrl } from 'sitePreferences';

const MAX_RECORDS = 5;
const MIN_CHARS = 3;

export default class AutocompleteAddress extends BaseAutocompleteAddress {
    // eslint-disable-next-line consistent-return
    async onAutocompleteCity() {
        let postalCode = await this.getFieldValue(this.$fields.get('postalCode'));

        if (postalCode.length < MIN_CHARS) {
            this.emitter.emit(`autocomlete.list.${this.$fields.get('postalCode')}.close`);
            return false;
        }

        let config = {
            url: globalExpressPostalCodeUrl,
            data: {
                'format': 'json',
                'postalcode': postalCode,
                'country': await this.getFieldValue(this.$fields.get('country')),
                'nativecharset': true,
                'maxrecords': MAX_RECORDS
            },
            callback: (response) => this.handleCityResult(response)
        };

        this.getData(config);
    }

    // eslint-disable-next-line consistent-return
    async onAutocompleteStreet() {
        let postalCode = await this.getFieldValue(this.$fields.get('postalCode'));
        let street = await this.getFieldValue(this.$fields.get('street'));

        if (!postalCode.length || street.length < MIN_CHARS) {
            this.emitter.emit(`autocomlete.list.${this.$fields.get('street')}.close`);
            return false;
        }

        let config = {
            url: globalExpressThoroughfareUrl,
            data: {
                'format': 'json',
                'postalcode': postalCode,
                'country': await this.getFieldValue(this.$fields.get('country')),
                'thoroughfare': street,
                'nativecharset': true,
                'maxrecords': MAX_RECORDS
            },
            callback: (response) => this.handleStreetResult(response)
        };

        this.getData(config);
    }

    async getData(config) {
        let authData = await this.getAuthData();

        if (Object.keys(authData).length) {
            registerErrorHandler(this.setConnectionError.bind(this));

            getJson({
                type: 'GET',
                url: config.url,
                headers: {
                    'Authorization': 'Bearer ' + authData.token,
                    'User-Session-Id': authData.sessionID,
                    'Signature': authData.signature
                },
                data: config.data,
                callback: config.callback
            });
        }
    }

    parseCity(response) {
        let result = [];

        response.Results.forEach(
            element => {
                if (
                    'Address' in element && 'Locality' in element.Address &&
                    'PostalCode' in element.Address
                ) {
                    // eslint-disable-next-line no-nested-ternary
                    let postalCode = element.Address.PostalCode ?
                        element.Address.PostalCode :
                        (('PostalCodePrimary' in element.Address) ?
                            element.Address.PostalCodePrimary :
                            ''
                        );

                    result.push({
                        'value': postalCode,
                        'htmlValue': `${postalCode} ${element.Address.Locality}`,
                        'data': {
                            'fieldname': 'shipping-city',
                            'fieldvalue': element.Address.Locality,
                            'event': 'autocomlete.fields',
                            'state': element.Address.AdministrativeArea
                        }
                    });
                }
            }
        );

        return result;
    }

    parseStreet(response) {
        let result = [];

        response.Results.forEach(
            element => {
                if ('Address' in element && 'Address1' in element.Address) {
                    // eslint-disable-next-line no-nested-ternary
                    let street = element.Address.Address1 ?
                        element.Address.Address1 :
                        (('Thoroughfare' in element.Address) ?
                            element.Address.Thoroughfare :
                            ''
                        );

                    result.push({
                        'value': street,
                        'htmlValue': street,
                        'data': {
                            'state': element.Address.AdministrativeArea
                        }
                    });
                }
            }
        );

        return result;
    }

    async getFieldValue(cmpID) {
        let activeCmp = null;

        this.iterateNestedComponents(cmp => {
            if (cmp.id === cmpID && this.isCmpActive(cmp)) {
                activeCmp = cmp;
            }
        }, true);

        let value = '';

        if (activeCmp && typeof activeCmp.getValue === 'function') {
            value = activeCmp.getValue().trim();
        }

        return value;
    }

    async setFieldValue(cmpID, value) {
        let activeCmp = null;

        this.iterateNestedComponents(cmp => {
            if (cmp.id === cmpID && this.isCmpActive(cmp)) {
                activeCmp = cmp;
            }
        });

        if (activeCmp && typeof activeCmp.setValue === 'function' && value) {
            activeCmp.setValue(value);
        }
    }

    isCmpActive(cmp) {
        let $fieldSet = cmp.$el.closest('fieldset');

        return ($fieldSet.length > 0 && !$fieldSet.prop('disabled')) || $fieldSet.length === 0;
    }
}
