import EventEmitter from 'client/core/EventEmitter';

import prefs from 'sitePreferences';
import { debounce } from 'lodash';


const smallId = 'small',
    mediumId = 'medium',
    largeId = 'large',
    extraLargeId = 'extraLarge',
    mediaS = `screen and (max-width: ${prefs.breakpointSmall}px)`,
    mediaM = `screen and (min-width: ${prefs.breakpointSmall + 1}px) and (max-width: ${prefs.breakpointMedium}px)`,
    mediaL = `screen and (min-width: ${prefs.breakpointMedium + 1}px) and (max-width: ${prefs.breakpointLarge}px)`,
    mediaXL = `screen and (min-width: ${prefs.breakpointExtraLarge}px)`,
    emitter = new EventEmitter(),
    queriesMap = [
        [smallId, mediaS],
        [mediumId, mediaM],
        [largeId, mediaL],
        [extraLargeId, mediaXL]
    ];

const getCurrentDeviceType = () => {
    const matchQuery = queriesMap.find(([, query]) =>
            window.matchMedia(query).matches
        );

    return matchQuery && matchQuery[0];
};

let currentDevice = getCurrentDeviceType();

const matchViewport = newDevice => {
    const previousDevice = currentDevice;

    currentDevice = newDevice;

    if (previousDevice !== currentDevice) {
        emitter.emit('deviceChange', currentDevice);
    }
};

const applyCurrentDeviceType = debounce(() => matchViewport(getCurrentDeviceType()), 100);

queriesMap.forEach(([, query]) => window.matchMedia(query).addListener(applyCurrentDeviceType));

export {
    smallId as SMALL,
    mediumId as MEDIUM,
    largeId as LARGE,
    extraLargeId as EXTRALARGE
};

export function getDeviceType() { return currentDevice; }
export function isTouchDevice() {
    return ('ontouchstart' in window) ||
        (window.DocumentTouch && document instanceof window.DocumentTouch);
}
export function isSmall() { return getDeviceType() === smallId; }
export function isMedium() { return getDeviceType() === mediumId; }
export function isLarge() { return getDeviceType() === largeId; }
export function isExtraLarge() { return getDeviceType() === extraLargeId; }

export function isCurrentlySmall() { return getCurrentDeviceType() === smallId; }
export function isCurrentlyMedium() { return getCurrentDeviceType() === mediumId; }
export function isCurrentlyLarge() { return getCurrentDeviceType() === largeId; }
export function isCurrentlyExtraLarge() { return getCurrentDeviceType() === extraLargeId; }
