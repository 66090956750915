/* eslint-disable class-methods-use-this */
import Component from 'client/core/Component';
import $ from 'jquery';

const PAGE_TYPE_MAPPING = {
    search: 'search',
    'Home-Show': 'home',
    plp: 'category',
    pdp: 'detail',
    blp: 'search',
    clp: 'static',
    'Cart-Show': 'basket',
    checkout: 'checkout',
    'Order-Confirm': 'confirmation',
    'Page-Show': 'static',
    'Account-Show': 'account',
    'Login-Show': 'account',
    'Account-Registration': 'account',
    'Account-CliqueOverview': 'account',
    'Brands-ShowIndexPage': 'other',
    'cgid=men': 'category',
    'cgid=women': 'category',
    'cgid=kids': 'category',
    'Stores-Find': 'other'
};

export default class TRBO extends Component {
    initTRBOListeners() {
        this.emitter.addListener('cart.product.added', this.conversionBasket);
        this.emitter.addListener('pdp.loaded', this.trackPDP);
        this.emitter.addListener('trbo.page', this.trackPage);
        this.emitter.addListener('trbo.checkout.step', this.reloadPlugin.bind(this));
        this.emitter.addListener('trbo.color.click', this.dispatchReload.bind(this));
        this.emitter.addListener('trbo.remove.item', this.dispatchDeltaCart);
    }

    currentBasketTracking(basket) {
        window._trboq = window._trboq || [];
        window._trboq.push([
            'currentBasket',
            basket
        ]);
    }

    trackPage(pageType) {
        window._trboq = window._trboq || [];
        if (PAGE_TYPE_MAPPING[pageType]) {
            window._trboq.push([
                'page',
                {
                    type: PAGE_TYPE_MAPPING[pageType]
                }
            ]);
        }
    }

    trackPDP(data) {
        this.emit('trbo.page', 'pdp');
        window._trboq = window._trboq || [];

        window._trboq.push([
            'productView',
            {
                products: [
                    {
                        product_id: data.pid,
                        name: data.productName,
                        price: data.productPrice
                    }
                ]
            }
        ]);
    }

    conversionBasket(data) {
        let basket = data.data.cart ? data.data.cart : false;
        let items = basket.items;
        var item = items.find((item) => {
            return item.id === data.pid;
        });

        window._trboq = window._trboq || [];

        window._trboq.push([
            'basket',
            {
                value: item.price.sales.decimalPrice,
                products: [
                    {
                        product_id: item.id,
                        name: item.productName,
                        quantity: 1,
                        price: item.price.sales.decimalPrice
                    }
                ]
            }
        ]);
    }

    dispatchChgColorVariant() {
        var reloadApiEvent = new Event('trboReloadApi');

        document.dispatchEvent(reloadApiEvent);

        // var chgColorVariant = new Event('chgColorVariant');

        // document.dispatchEvent(chgColorVariant);
    }

    dispatchDeltaCart() {
        var rfcEvent = new Event('trboRemove');

        document.dispatchEvent(rfcEvent);
    }

    dispatchReload(data, trackPDPflag) {
        var reloadApiEvent = new Event('trboReloadApi');

        document.dispatchEvent(reloadApiEvent);
        if (trackPDPflag) {
            var vgData = {
                pid: data.id,
                productName: data.productName,
                productPrice: data.price.sales.decimalPrice
            };

            this.trackPDP(vgData);
        }

    }

    reloadPlugin() {

        if (typeof window._trbo !== 'undefined' && window._trbo.app && window._trbo.app.reloadApi) {
            window._trbo.app.reloadApi(true, true);
        }
    }

    loadPlugin() {
        const pluginId = this.config.trboPlugin;
        const currentBasket = this.config.trboBasket;

        if (currentBasket) {
            this.currentBasketTracking(currentBasket);
        }
        this.body = this.$el.parent();
        if (pluginId && !this.loaded) {
            const scriptContent = `
                (function () {
                    var c = document.createElement("script");
                    c.type = "text/javascript";
                    c.id = "trbo_plugin_code_main";
                    c.async = 1;
                    c.src = document.location.protocol + "//static.trbo.com/plugin/${pluginId}";
                    if (!document.getElementById("trbo_plugin_code_main")) {
                        var b = document.getElementsByTagName("script")[0]; b.parentNode.insertBefore(c, b);
                    }
                })();
            `;

            this.body.append(`<script>${scriptContent}</script>`);
            this.loaded = true;
        }
    }

    init() {
        this.initTRBOListeners();
        if (PAGE_TYPE_MAPPING[this.config.action]) {
            this.trackPage(this.config.action);
        } else {
            var elements = $('.js-page');
            var elementWithDataQueryString = elements.filter(function() {
                return $(this).attr('data-querystring');
            });

            if (elementWithDataQueryString.first()) {
                var queryStringValue = elementWithDataQueryString.first().attr('data-querystring');

                if (PAGE_TYPE_MAPPING[queryStringValue]) {
                    this.trackPage(queryStringValue);
                }
            }
        }

        // eslint-disable-next-line no-negated-condition
        if (!window.consentStatus) {
            window.addEventListener(
                'apply.consent.cookie',
                this.loadPlugin.bind(this)
            );
        } else {
            this.loadPlugin();
        }
    }
}
