import Component from 'client/core/Component';

const SELECTOR = '.js-grid-sort-select';

export default class GlobalCustomSelectWrap extends Component {
    init() {
        this.emitter.addListener('refinement.updated', ($r) => this.updateCustomSelect($r));
    }

    updateCustomSelect($response) {
        let $container = $response.find(SELECTOR);

        if ($container.length) {
            this.replaceHtml($container.html());
        }
    }
}
