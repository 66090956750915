import $ from 'jquery';
import { CLASSES, COOKIE } from 'client/utils/globals';
import { setCookie, hasCookie, getCookie } from 'client/utils/cookie';
import Modal from 'client/components/Modal';
import templatesManager from 'client/utils/templatesManager';
import ajax from 'client/utils/ajax';
import { setLocale } from 'urls';

const SELECTORS = {
    localizationLocaleSelector: '#localizationLocaleSelector',
    localizationDialog: '.js-localization-dialog',
    localizationMobileDialog: '.js-localization-mobile-dialog',
    countrySelector: '.js-localization-country-select',
    countryWrapper: '.js-localization-country-wrapper',
    languageWrapper: '.js-localization-language-wrapper',
    submit: '.js-localization-submit',
    form: '.js-localization-form',
    document: document
};

const STATE_CLASSES = {
    formFieldSingle: 'b-localization-form-field--single'
};

const LOCALE = [
    'language',
    'country'
];

export default class LocalizationDialog extends Modal {
    init() {
        super.init();

        this.context = this.config.templateAttrs;

        this.bindEvent('click', SELECTORS.localizationDialog, this.showModal.bind(this, true));
        this.emitter.addListener('countryChange', this.countrySelectorChangeHandler);

        $(SELECTORS.document).on('click', SELECTORS.localizationMobileDialog, function(e) {
            e && e.preventDefault();

            $(SELECTORS.localizationDialog).trigger('click');
        });

        if (!hasCookie(COOKIE.hideLocalizationDialog)) {
            this.emitter.addListener('modal.close', () => this.onModalClosed());
        }

        this.processModalAutoOpen();
    }

    notifyDone() {
        this.emitter.emit('popupDone', this.config.cmp);
    }

    async processModalAutoOpen() {
        let localizationCookie = hasCookie(COOKIE.localizationSettings) ? getCookie(COOKIE.localizationSettings) : '';
        let hidePopUpCookie = hasCookie(COOKIE.hideLocalizationDialog) ? getCookie(COOKIE.hideLocalizationDialog) : '';
        let customerCountry = await this.detectCustomerCountry();
        let siteCountryCode = this.config.templateAttrs.currentLocale.countryCode.toLowerCase();

        let isAutoOpen = !localizationCookie && !hidePopUpCookie && siteCountryCode !== customerCountry;

        if (isAutoOpen) {
            this.showModal(false, null, null);
        } else {
            this.notifyDone();

            this.emitter.addListener('popupNotify', () => {
                this.notifyDone();
            });
        }
    }

    async detectCustomerCountry() {
        return await new Promise(async (res) => {
            let customerCountryCookie = hasCookie(COOKIE.customerCountry) ? getCookie(COOKIE.customerCountry) : '';

            if (customerCountryCookie) {
                // If we already have saved customer country code then just return it
                res(customerCountryCookie);
            } else {
                let extractCountry = (locale) => {
                    if (locale.indexOf('-') > -1) {
                        return locale.split('-')[1].toLowerCase();
                    } else if (locale.indexOf('_') > -1) {
                        return locale.split('_')[1].toLowerCase();
                    } else {
                        return locale.toLowerCase();
                    }
                };

                // As DW caching this modal dialog and we cannot get user sensitive data just right in the template parameters
                // we need to make request to server to get customer countryCode
                let response = await ajax.getJson({ url: this.config.detectCountryUrl });

                if (response.success && response.countryCode) {
                    let country = extractCountry(response.countryCode);

                    setCookie(COOKIE.customerCountry, country);
                    res(country);

                // If DW cannot recognize customer's country code for some reason we can use browser language
                } else if (navigator && 'language' in navigator && navigator.language) {
                    let country = extractCountry(navigator.language);

                    setCookie(COOKIE.customerCountry, extractCountry(country));
                    res(country);
                } else {
                    res(null);
                }
            }
        });
    }

    showModal(byClick, el, e) {
        e && e.preventDefault();

        let self = this;

        self.config.templateAttrs.byClick = byClick;

        super.showModal();

        this.$modalContainer = this.getModalContainer();
        this.$modalContainerSafe = this.$modalContainer;
        this.$modalContainer.on('change', SELECTORS.countrySelector, function() {
            self.emitter.emit('countryChange', self, $(this));
        });
        this.$modalContainer.on('submit', SELECTORS.form, function(e) {
            self.formSubmitHandler.call(this, e, self);
        });
    }

    onModalClosed() {
        setCookie(COOKIE.hideLocalizationDialog, true);

        if (!this.config.templateAttrs.byClick) {
            this.notifyDone();
        }
    }

    countrySelectorChangeHandler(self, el) {
        let $el = $(el),
            selectedCountryCode = $el.val(),
            countries = self.context.countries,
            selectedCountry = countries[selectedCountryCode],
            country = self.context.detectedCountry ? self.context.detectedCountry : self.context.currentCountry,
            $sbmt = self.$modalContainer.find(SELECTORS.submit);

        if (selectedCountry.locales.length > 1) {
            self.$modalContainer.find(SELECTORS.languageWrapper).removeClass(CLASSES.hide);
            self.$modalContainer.find(SELECTORS.countryWrapper).removeClass(STATE_CLASSES.formFieldSingle);
        } else {
            self.$modalContainer.find(SELECTORS.languageWrapper).addClass(CLASSES.hide);
            self.$modalContainer.find(SELECTORS.countryWrapper).addClass(STATE_CLASSES.formFieldSingle);
        }

        if (country.countryCode === selectedCountryCode) {
            $sbmt.html($sbmt.data('stay-here'));
        } else {
            $sbmt.html($sbmt.data('go-to-shop'));
        }

        self.replaceHtml(
            templatesManager.templates.localizationDialogCountrySpecificTemplate({
                locales: selectedCountry.locales
            }),
            self.$modalContainer.find(SELECTORS.languageWrapper)
        );
    }

    trackStoreSwitch() {
        const $localizationLocaleSelector = this.$modalContainerSafe.find(SELECTORS.localizationLocaleSelector);

        if (!$localizationLocaleSelector.length) {
            return;
        }

        const aLocale = $localizationLocaleSelector.val().toUpperCase().split('_');

        for (let i = (aLocale.length - 1); i >= 0; i--) {
            const data = {
                eventCategory: 'store switch',
                eventAction: LOCALE[i],
                eventLabel: aLocale[i],
                ga4eventname: 'locale_selection'
            };

            this.emitter.emit('gtmEvent', data);
        }
    }

    disableSubmitBtn() {
        this.$modalContainer.find(SELECTORS.submit).prop('disabled', true);
    }

    async formSubmitHandler(e, self) {
        e && e.preventDefault();

        let $form = $(this);

        try {
            let response = await ajax.getJson({
                url: setLocale,
                data: $form.serialize()
            });

            if (response.success) {
                if (self.gtmEnabled) {
                    self.trackStoreSwitch();
                }

                if (!response.disableRedirect) {
                    self.disableSubmitBtn();
                    window.location.href = response.redirectUrl;
                    return;
                }
            }
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
        }

        self.emitter.emit('modal.close');
    }
}
