import Component from 'client/core/Component';
import { debounce } from 'lodash';

const DEFAULT_CONFIG = {
    delay: 300
};

const SELECTORS = {
    searchInput: '.js-no-results-input',
    clearSearchBtn: '.js-no-results-clear-search',
    searchBtn: '.js-no-results-search-btn'
};

const STATE_CLASSES = {
    closeBTtnShow: 'f-clear-search--show'
};

export default class NoResultsSearchForm extends Component {
    init () {
        this.config = Object.assign({}, this.config, DEFAULT_CONFIG);

        this.$searchInput = this.$el.find(SELECTORS.searchInput);
        this.$clearSearch = this.$el.find(SELECTORS.clearSearchBtn);
        this.bindEvent('keyup', SELECTORS.searchInput, debounce(this.onInputTyping, this.config.delay));
        this.bindEvent('click', SELECTORS.clearSearchBtn, this.clearSearch);
        this.bindEvent('click', SELECTORS.searchBtn, this.runSearch);
    }

    onInputTyping() {
        let searchPhrase = this.$searchInput.val().trim();

        if (searchPhrase.length === 0) {
            this.$clearSearch.removeClass(STATE_CLASSES.closeBTtnShow);
        } else {
            this.$clearSearch.addClass(STATE_CLASSES.closeBTtnShow);
        }
    }

    runSearch (el, e) {
        e.preventDefault();
        this.$el[0] && this.$el[0].nodeName === 'FORM' && this.$el.submit();
    }

    clearSearch (el, e) {
        e.preventDefault();
        this.$searchInput.val('');
        this.$clearSearch.removeClass(STATE_CLASSES.closeBTtnShow);
    }
}
