/**
 * @function
 * @param {string} url
 * @param {Object} stateData
 */
export function pushState(url, stateData = null) {
    let history = window.history;

    if (history && history.pushState) {
        history.pushState({ url: location.href, stateData }, document.title, url);
    }
}

/**
 * @function
 * @param {string} url
 */
export function replaceState(url) {
    let history = window.history;

    if (history && history.replaceState) {
        history.replaceState({ url: location.href }, document.title, url);
    }
}

/**
 * @param {String} referer
 */
export function setCustomReferer(referer) {
    if (!window.customReferer) {
        window.customReferer = referer;
    }
}

/**
 * returns {String | undefined}
 */
export function getCustomReferer () {
    return window.customReferer;
}

export function removeCustomReferer () {
    delete window.customReferer;
}

/**
 * @param {String} pId - product ID
 */
export function setPIDAnchor(pId) {
    window.sessionStorage.setItem('pIdAnchor', pId);
}

/**
 * returns {String | undefined}
 */
export function getPIDAnchor () {
    return window.sessionStorage.getItem('pIdAnchor');
}

export function removePIDAnchor () {
    window.sessionStorage.removeItem('pIdAnchor');
}
