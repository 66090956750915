/* global google */

import GoogleMapsAbstractComponent from './GoogleMapsAbstractComponent';

export default class GoogleMapsGeoAbstractComponent extends GoogleMapsAbstractComponent {
    init () {
        super.init();

        this.collectedData = {
            postalCode: null,
            location: {
                lat: null,
                lng: null
            }
        };
    }

    async geocode (geoData) {
        const emptyResult = {
            postalCode: null,
            location: {
                lat: null,
                lng: null
            }
        };

        if (typeof google === 'undefined') {
            return emptyResult;
        }

        this.geocoder = this.geocoder || new google.maps.Geocoder();

        return await new Promise(res => {
            this.geocoder.geocode(geoData, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    let postalCode = results[0] ? this.extractPostalCode(results[0]) : null,
                        location = results[0] ? this.extractLocation(results[0]) : null;

                    res({
                        postalCode,
                        location
                    });
                } else {
                    console.log(`GoogleMapsGeocoderCmp: status=${status}`);
                    res(emptyResult);
                }
            });
        });
    }

    changeCollectedData (data) {
        this.collectedData = Object.assign({}, this.collectedData, data);

        this.emit('data.changed', this.collectedData);
    }

    fireError (errorData = {}) {
        this.emit('data.error', errorData);
    }

    extractPostalCode(result) {
        if ('address_components' in result) {
            return result.formatted_address;
        }

        return null;
    }

    extractLocation(result) {
        if ('geometry' in result) {
            const geometry = result.geometry;

            if ('location' in geometry) {
                const location = geometry.location;

                return {
                    lat: location.lat(),
                    lng: location.lng()
                };
            }
        }

        return {
            lat: null,
            lng: null
        };
    }
}
