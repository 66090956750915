import Component from 'client/core/Component';
import $ from 'jquery';

export default class HtmlHead extends Component {
    init() {
        this.metaTagsConfigs = [
            {
                selector: 'title',
                replaceInner: true
            },
            {
                selector: 'meta[name="description"]',
                attrToUpdate: 'content'
            },
            {
                selector: 'meta[name="og:title"]',
                attrToUpdate: 'content'
            },
            {
                selector: 'meta[name="robots"]',
                attrToUpdate: 'content'
            },
            {
                selector: 'meta[name="og:url"]',
                attrToUpdate: 'content'
            },
            {
                selector: 'meta[name="og:description"]',
                attrToUpdate: 'content'
            },
            {
                selector: 'link[rel="canonical"]',
                attrToUpdate: 'href'
            },
            {
                selector: 'link[rel="alternate"]',
                attrToUpdate: 'href',
                localSpecific: true
            }
        ];
        this.emitter.addListener('refinement.updated', ($response) => this.onAjaxSeoUpdate($response));
    }

    updateAlternateUrls(alternateTags, metaTagsConfig) {
        alternateTags.toArray().forEach(altTag => {
            const responseHreflang = $(altTag).attr('hreflang');
            const currentMetaTag = this.$el.find(`${metaTagsConfig.selector}[hreflang="${responseHreflang}"]`);
            const newMetaUrl = $(altTag).attr(metaTagsConfig.attrToUpdate);

            currentMetaTag.attr(metaTagsConfig.attrToUpdate, newMetaUrl);
        });
    }

    onAjaxSeoUpdate($response) {
        this.metaTagsConfigs.forEach(metaTagsConfig => {
            const newMetaTags = $response.find(metaTagsConfig.selector);

            if (newMetaTags && newMetaTags.length) {

                if (metaTagsConfig.localSpecific) {
                    this.updateAlternateUrls(newMetaTags, metaTagsConfig);
                } else {
                    if (metaTagsConfig.attrToUpdate) {
                        const newMetaTagData = newMetaTags.attr(metaTagsConfig.attrToUpdate);

                        this.$el.find(metaTagsConfig.selector).attr(metaTagsConfig.attrToUpdate, newMetaTagData);
                    }

                    if (metaTagsConfig.replaceInner) {
                        const newMetaTagData = newMetaTags.html();

                        this.$el.find(metaTagsConfig.selector).html(newMetaTagData);
                    }
                }
            }
        });
    }
}
