import Component from 'client/core/Component';
import { SNIPES_TITLE } from 'resources';

const SELECTORS = {
    'SEARCH': '.js-ship-to-store-search-input',
    'BUTTON': '.js-ship-to-store-search-button',
    'LOCATION': '.js-ship-to-store-search-location',
    'MODAL': '.js-ship-to-store-modal',
    'EDIT': '.js-edit-ship-to-store',
    'SEARCH_BLOCK': '.js-ship-to-store-search-block',
    'STATION_FIELDS': '.js-ship-to-store-fields',
    'STATION_RESULT': '.js-ship-to-store-result'
};

export default class shipToStore extends Component {
    init() {
        this.$searchButton = this.$el.find(SELECTORS.BUTTON);
        this.$searchBlock = this.$el.find(SELECTORS.SEARCH_BLOCK);
        this.$stationFields = this.$el.find(SELECTORS.STATION_FIELDS);
        this.$stationResult = this.$el.find(SELECTORS.STATION_RESULT);
        this.$searchByLocation = false;
        this.bindEvent('keyup', SELECTORS.SEARCH, () => this.onInputChange());
        this.bindEvent('click', SELECTORS.BUTTON, () => this.startSearchByValue());
        this.bindEvent('click', SELECTORS.LOCATION, () => this.startSearchByLocation());
        this.bindEvent('click', SELECTORS.EDIT, () => this.showSearchBlock());
        this.emitter.addListener('addressBook.shipping.updated.store', f => this.fillShipToStoreAddressBlock(f));
        this.emitter.addListener('search.by.value', () => this.setSearchValue());
        this.emitter.addListener('search.by.location', () => this.initLocationSearch());

        if (this.getNestedComponentById('ship-to-home-search').getValue().length > 0) {
            this.$searchButton.removeAttr('disabled');
        }
    }

    initLocationSearch() {
        if (this.$searchByLocation) {
            this.emitter.emit('search.by.location.init');
        }
    }

    setSearchValue() {
        let searchInput = this.getNestedComponentById('ship-to-home-search');

        if (searchInput.getValue().length > 0 && !this.$searchByLocation) {
            this.emitter.emit('search.by.value.init', searchInput.getValue());
        }
    }

    showSearchBlock () {
        this.$el.find(this.$searchBlock).removeClass('h-hide');
        this.$el.find(this.$stationFields).addClass('h-hide');
        this.$el.find(this.$stationResult).addClass('h-hide');
    }

    hideSearchBlock () {
        this.$el.find(this.$stationFields).removeClass('h-hide');
        this.$el.find(this.$stationResult).removeClass('h-hide');
        this.$el.find(this.$searchBlock).addClass('h-hide');
    }

    fillShipToStoreAddressBlock(fields) {
        for (const key in fields) { // eslint-disable-line no-restricted-syntax
            if (Object.prototype.hasOwnProperty.call(fields, key) && key !== 'id') {
                let field = this.$el.find(`.js-store-${key}`);

                key === 'snipesStore' ? field.html(SNIPES_TITLE) : field.html(fields[key]);
            }
        }

        this.hideSearchBlock();
    }

    onInputChange () {
        let cmp = this.getNestedComponentById('ship-to-home-search');

        if (cmp.getValue().length > 0) {
            this.$searchButton.removeAttr('disabled');
        } else {
            this.$searchButton.attr('disabled', true);
        }
    }

    startSearchByValue () {
        let searchInput = this.getNestedComponentById('ship-to-home-search');

        this.$searchByLocation = false;
        if (searchInput.getValue().length > 0) {
            this.emitter.emit('show.store.modal.search');
        }
    }

    startSearchByLocation () {
        this.emitter.emit('show.store.modal.search');
        this.$searchByLocation = true;
    }
}
