import $ from 'jquery';
import ajax from 'client/utils/ajax';
import { appendParamToURL } from 'client/utils/url';
import { CLASSES } from 'client/utils/globals';
import PaymentOptions from 'client/components/checkout/PaymentOptions';

const SELECTORS = {
    accountPaymentForm: '.js-account-payment-form',
    paymentMethodItem: '.js-account-payment-method-item',
    paymentListItem: '.js-account-payment-list-item',
    paymentTab: '.js-acc-payment-option-tab',
    paymentInput: '.js-acc-payment',
    paymentRadio: '.js-account-payment-radio',
    paymentRadioChecked: '.js-account-payment-radio:checked',
    idealSelectList: '.js-payment-ideal-select .js-custom-select-list',
    idealSelectItem: '.js-custom-select-item',
    idealSelectOption: '.js-payment-ideal-select .js-native-select option',
    error: '.js-error'
};

const METHOD_IDEAL = 'ideal';

export default class AccountPaymentMethod extends PaymentOptions {

    init() {
        this.action = this.$el.data('payment-url');
        this.$errorMsgBlock = this.$el.find(SELECTORS.error);

        this.handleIdealSelect();

        this.bindEvent('click', SELECTORS.paymentListItem, (el, ev) => this.onPaymentClick(el, ev));
        this.bindEvent('click', SELECTORS.idealSelectItem, (el, ev) => this.onIdealOptionClick(el, ev));
        $(SELECTORS.accountPaymentForm).on('submit', this.onFormSubmit.bind(this));
    }

    onFormSubmit(ev) {
        ev.preventDefault();
        const serializedForm = this.$el.serialize();

        ajax.getJson({
            url: this.action,
            type: 'POST',
            data: serializedForm
        }).then((resp) => {
            if (resp.error) {
                this.showErrorMsg(resp.errorMessage);
            }
        });
    }

    onPaymentClick(el, ev) {
        this.hideErrorMsg();
        const paymentRadio = $(ev.currentTarget).find(SELECTORS.paymentRadio);

        if (paymentRadio.prop('disabled')) {
            return;
        }

        this.$el.find(`${SELECTORS.paymentTab}.active`).removeClass('active');
        $(ev.currentTarget).find(SELECTORS.paymentTab).addClass('active');

        this.$el.find(SELECTORS.paymentRadioChecked).prop('checked', false);
        paymentRadio.prop('checked', true);

        this.handleIdealSelect();

        this.$el[0] && this.$el[0].nodeName === 'FORM' && this.$el.submit();
    }

    onIdealOptionClick(el, ev) {
        ev.preventDefault();
        const selectedBankID = $(ev.currentTarget).data('value');
        const idealSelectOption = this.$el.find(`${SELECTORS.idealSelectOption}[id="${selectedBankID}"]`);

        idealSelectOption.prop('selected', true);
        this.$el.attr('data-ideal-selected', selectedBankID);
        this.config.idealSelected = selectedBankID;
        this.$el[0] && this.$el[0].nodeName === 'FORM' && this.$el.submit();
    }

    showErrorMsg(message) {
        this.$errorMsgBlock.removeClass(CLASSES.hide);
        this.$errorMsgBlock.html(message);
    }

    hideErrorMsg() {
        this.$errorMsgBlock.addClass(CLASSES.hide);
    }

    fetchIDEALData() {
        const countryCode = this.config.billingCountryCode;

        if (countryCode) {
            let url = appendParamToURL(this.config.idealUrl, 'countryCode', countryCode);

            let promise = ajax.getJson({ url: url });

            promise.then(response => {
                if (response.success) {
                    this.$errorMsgBlock.addClass(CLASSES.hide);
                    this.updateIDEALList.call(this, response.bankList);
                } else if (response.error && response.errorText) {
                    this.showError(response.errorText, this.$errorMsgBlock);
                } else {
                    this.showError(this.config.generalError, this.$errorMsgBlock);
                }
            });
        }
    }

    handleIdealSelect() {
        const selectedPM = this.$el.find(SELECTORS.paymentRadioChecked).data('id');

        if (selectedPM && selectedPM.toLowerCase() === METHOD_IDEAL) {
            this.fetchIDEALData.call(this);
        } else {
            this.resetIdealSelectOption();
        }
    }

    resetIdealSelectOption() {
        this.$el.find(`${SELECTORS.idealSelectOption}:selected`).prop('selected', false);
    }
}
