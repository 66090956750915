import Component from 'client/core/Component';
import { CLASSES } from 'client/utils/globals';

export default class BrandImage extends Component {
    init () {
        let el = this.$el[0];

        this.$imageCaption = this.$el.next();

        if (el.complete) {
            !el.naturalHeight && this.hideImage();
        } else {
            this.$el.on('error', () => this.hideImage());
        }
    }

    hideImage() {
        this.$el.addClass(CLASSES.hide);
        this.$imageCaption.removeClass(CLASSES.invisible);
    }
}
