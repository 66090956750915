import ProductComponent from './ProductComponent';

import $ from 'jquery';
import ajax from 'client/utils/ajax';
import { scrollTo } from 'client/utils/common';
import { CLASSES as GLOBAL_CLASSES } from 'client/utils/globals';

const BTN_MSG_DELAY = 5000,
    SELECTORS = {
        addToCartBtn: '.js-btn-add-to-cart',
        releaseBtn: '.js-btn-release',
        findInStoreBtn: '.js-btn-find-in-store',
        variationSection: '.js-pdp-variation-section',
        pdpActions: '.js-pdp-actions',
        productDetails: '.js-product-details',
        promoCalloutMsgStatic: 'js-promo-callout-static',
        promoCalloutMsgSticky: '.js-promo-callout-sticky'
    },
    CLASSES = {
        notReadyToAdd: 'js-not-ready-to-add',
        notReadyToAddClicked: 'h-not-ready-to-add-clicked',
        scrollAfterClick: 'h-scroll-after-click',
        hideShowPromoMsgClass: 'h-hide-static-promo-msg'
    };

export default class AddToCartProduct extends ProductComponent {
    onVariationUpdate(product) {
        super.onVariationUpdate(product);

        const $btn = this.$el.find(SELECTORS.addToCartBtn);

        this.resetCountdown();
        this.touchCountdown();
        $btn.removeClass(CLASSES.notReadyToAddClicked);
    }

    onError($btn, data) {
        if (data.errorType === 'giftCardError') {
            this.emitter.emit('giftcard.popup.show', data);
            this.emitter.emit('gtmEvent', data.giftCardModalTemplate.gtmData);
        } else {
            $btn.prop('disabled', true);

            this.emitter.emit('cart.add.error', data);
        }
    }

    onAdded() {
        const $btn = this.$el.find(SELECTORS.addToCartBtn);

        $btn.prop('disabled', true)
            .removeClass(GLOBAL_CLASSES.clicked)
            .addClass(GLOBAL_CLASSES.productAdded);

        setTimeout(() => {
            $btn.prop('disabled', false).removeClass(
                GLOBAL_CLASSES.productAdded
            );
        }, BTN_MSG_DELAY);
    }

    onBtnClick() {
        const $btn = this.$el.find(SELECTORS.addToCartBtn),
            pid = $btn.data('pid'),
            variables = $btn.data('variables'),
            url = $btn.data('href'),
            quantity = 1,
            isQuickView = $btn.data('quickView'),
            isEditView = $btn.data('editView'),
            actionType = this.$el.find(SELECTORS.findInStoreBtn);

        if (isQuickView && isEditView) {
            this.updateCart($btn);
            return;
        } else if (actionType.length) {
            this.emitter.emit('findinstore.action.open');
            return;
        }

        if ($btn.hasClass(CLASSES.notReadyToAdd)) {
            this.emitter.emit('product.addtocart.clicked');

            if (!$btn.hasClass(CLASSES.scrollAfterClick)) {
                scrollTo();
            }
        }

        let options = null;

        if (!$btn.hasClass(CLASSES.notReadyToAdd) && pid && variables && url) {
            if (Array.isArray(variables)) {
                try {
                    options = JSON.stringify(variables);
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error(e.message, variables);

                    return;
                }
            } else if (typeof variables === 'string') {
                options = variables;
            }

            this.emitter.emit('cart.add.adding');
            $btn.addClass(GLOBAL_CLASSES.clicked);

            ajax.getJson({
                url: url,
                type: 'POST',
                data: {
                    pid,
                    options,
                    quantity
                }
            }).then((data) => {
                if (data && 'error' in data) {
                    if (data.error) {
                        this.onError($btn, data);
                    } else {
                        this.emitter.emit('cart.add.added', data);
                        this.emitter.emit('cart.product.added', {
                            data: data,
                            pid: pid
                        });
                    }
                }
            });
        }
    }

    onBtnTouchStart() {
        const $btn = this.$el.find(SELECTORS.addToCartBtn);

        if ($btn.hasClass(CLASSES.notReadyToAdd)) {
            const $pdpActions = $btn.closest(SELECTORS.pdpActions),
                $pdpStickyPanel = $btn.closest('.js-sticky-panel'),
                $variationSection = $(SELECTORS.variationSection),
                isElStickyTop = $pdpActions.hasClass(
                    GLOBAL_CLASSES.stickyElementTop
                ),
                isElStickyBottom = $pdpActions.hasClass(
                    GLOBAL_CLASSES.stickyElementBottom
                ),
                isStickyMobile =
                    $pdpActions.length && (isElStickyTop || isElStickyBottom),
                isStickyDesktop =
                    $pdpStickyPanel.length &&
                    $pdpStickyPanel.hasClass('h-sticky-panel-show'),
                isSticky = isStickyMobile || isStickyDesktop;

            $btn.addClass(CLASSES.notReadyToAddClicked);
            $btn.addClass(CLASSES.scrollAfterClick);
            setTimeout(() => {
                $btn.removeClass(CLASSES.scrollAfterClick);
            }, 1000);

            if (isSticky && $variationSection.length) {
                scrollTo($variationSection);
            }
        }
    }

    resetCountdown() {
        if (this.countdown) {
            clearInterval(this.countdown);
            this.countdown = null;
        }
    }

    updateCountdown(count) {
        const $btn = this.$el.find(SELECTORS.releaseBtn);

        if (!$btn.data('countdown')) {
            return;
        }

        const dd = Math.floor(count / (1000 * 60 * 60 * 24)),
            hh = Math.floor((count % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            mm = Math.floor((count % (1000 * 60 * 60)) / (1000 * 60)),
            ss = Math.floor((count % (1000 * 60)) / 1000);

        let label = [hh, mm, ss];

        label = label.map((val) => {
            return val.toString().padStart(2, '0');
        });

        if (dd) {
            label.unshift(dd);
        }

        $btn.html(label.join(':'));
    }

    startCountdown(to) {
        this.countdown = setInterval(() => {
            const count = to - new Date().getTime();

            if (count >= 0) {
                this.updateCountdown(count);
            } else {
                this.resetCountdown();

                this.emitter.emit('product.refresh');
            }
        }, 1000);
    }

    startReleaseDate() {
        const $releaseBtn = this.$el.find(SELECTORS.releaseBtn),
            releaseDate = $releaseBtn.data('release-date');

        if (releaseDate) {
            $releaseBtn.html(this.formatDate(new Date(releaseDate), false));
        }
    }

    touchCountdown() {
        const $releaseBtn = this.$el.find(SELECTORS.releaseBtn),
            releaseDate = $releaseBtn.data('release-date');

        if (releaseDate) {
            const to = new Date(releaseDate).getTime(),
                count = to - new Date().getTime();

            if (count >= 0) {
                this.updateCountdown(count);
                this.startCountdown(to);
            }
        }
    }

    updateCart($btn) {
        if (!$btn.hasClass(CLASSES.notReadyToAdd)) {
            const pid = $btn.data('pid'),
                url = $btn.data('href'),
                uuid = $btn.data('uuid');

            let data = {
                pid,
                quantity: $btn.data('quantity'),
                uuid: uuid
            };

            ajax.getJson({
                url: url,
                type: 'POST',
                data: data
            })
                .then((data) => {
                    if (data && 'errorMessage' in data) {
                        this.onError($btn, {
                            message: data.errorMessage
                        });
                    } else {
                        this.emitter.emit('modal.close', {
                            id: uuid,
                            params: data.cartModel
                        });
                    }
                })
                .catch((e) => {
                    if (e && e.responseJSON && e.responseJSON.errorMessage) {
                        this.onError($btn, {
                            message: e.responseJSON.errorMessage
                        });
                    }
                });
        }
    }

    trackAvailability() {
        const productType = this.config.productType;

        const data = {
            eventCategory: 'product',
            eventAction:
                productType === 'variant' ?
                    'current size availability' :
                    'overall availability',
            eventLabel: this.$el.find(SELECTORS.addToCartBtn).length ?
                'in stock' :
                'out of stock'
        };

        this.emitter.emit('gtmEvent', data);
    }

    onCartUpdated() {
        const $btn = this.$el.find(SELECTORS.addToCartBtn);

        $btn.prop('disabled', false);
    }

    onAttributeUnselected() {
        const $btn = this.$el.find(SELECTORS.addToCartBtn);
        const productId = this.$el
            .closest(SELECTORS.productDetails)
            .data('pid');

        $btn.addClass(CLASSES.notReadyToAdd);
        $btn.attr('data-pid', productId);
        $btn.attr('data-variables', '{}');
    }

    // eslint-disable-next-line class-methods-use-this
    hideShowPromoMsg() {
        const promoCalloutMsgStatic = document.getElementsByClassName(
            SELECTORS.promoCalloutMsgStatic
        );
        let bounding = promoCalloutMsgStatic[0].getBoundingClientRect();

        if (bounding.top >= 20) {
            $(SELECTORS.promoCalloutMsgSticky).addClass(
                CLASSES.hideShowPromoMsgClass
            );
        } else {
            $(SELECTORS.promoCalloutMsgSticky).removeClass(
                CLASSES.hideShowPromoMsgClass
            );
        }
    }

    init() {
        super.init();

        this.touchCountdown();
        this.startReleaseDate();

        this.emitter.addListener('scroll', this.hideShowPromoMsg.bind(this));

        this.bindEvent('click', SELECTORS.btn, this.onBtnClick);
        this.bindEvent('touchstart', SELECTORS.btn, this.onBtnTouchStart);
        this.emitter.addListener('cart.updated', this.onCartUpdated.bind(this));
        this.emitter.addListener(
            'attribute.unselected',
            this.onAttributeUnselected.bind(this)
        );
        this.emitter.addListener('cart.add.added', this.onAdded.bind(this));

        // Disabled. See https://jira.ontrq.com/browse/SNPSTA-3276
        // if (this.gtmEnabled) {
        //     this.trackAvailability();
        // }
    }
}
