import MainNamespace from 'buffalo/globalComponents/MainNamespace';

import newPasswordForm from 'client/components/forms/NewPasswordForm';
import registerForm from 'client/components/account/RegisterForm';
import changePasswordForm from 'client/components/account/ChangePasswordForm';
import accountPaymentMethod from 'client/components/account/AccountPaymentMethod';
import profileEditForm from 'client/components/account/ProfileEditForm';
import passwordStrength from 'client/components/forms/commonElements/PasswordStrength';
import wishlist from 'client/components/account/Wishlist';
import wishlistProductCard from 'client/components/account/WishlistProductCard';
import productCardSizeSelect from 'buffalo/components/product/ProductCardSizeSelect';
import trackOrderForm from 'client/components/forms/TrackOrderForm';
import orderHistoryCard from 'client/components/account/OrderHistoryCard';
import newsletterPreferencesForm from 'client/components/account/NewsletterPreferencesForm';

const mainNamespace = new MainNamespace();

mainNamespace.addComponents({
    newPasswordForm,
    registerForm,
    changePasswordForm,
    accountPaymentMethod,
    passwordStrength,
    wishlist,
    wishlistProductCard,
    productCardSizeSelect,
    trackOrderForm,
    orderHistoryCard,
    profileEditForm,
    newsletterPreferencesForm
});

mainNamespace.init();
