import Form from 'client/core/Form';
import { getJson } from 'client/utils/ajax';
// @ts-ignore
import { isRNWebView, sendEventToMobileApp } from 'client/utils/react';
import { CLASSES } from 'client/utils/globals';

const SELECTORS = {
    password: '.js-login-password',
    passwordConfirm: '.js-login-password-confirm ',
    submitBtn: '.js-confirmation-registration-submit'
};

export default class ConfirmationRegistrationForm extends Form {
    async trackSubscribe(data) {
        const eventData = {
            eventCategory: 'newsletter',
            eventAction: 'checkout sign-up',
            eventLabel: data.interest,
            ga4eventname: 'account_sign_up'
        };

        this.emitter.emit('gtmEvent', eventData);
    }

    async trackRegister(layerData) {
        const eventData = Object.assign({}, layerData, {
            eventCategory: 'account',
            eventAction: 'register',
            eventLabel: 'within checkout',
            ga4eventname: 'account_sign_up'
        });

        this.emitter.emit('gtmEvent', eventData);
    }

    init() {
        this.bindEvent('focusout', SELECTORS.password, function() {
            const passwordCmp = this.$el.find(SELECTORS.password).data('cmp-instance');
            const passwordConfirmCmp = this.$el.find(SELECTORS.passwordConfirm).data('cmp-instance');

            if (passwordConfirmCmp.getValue() && passwordCmp.getValue()) {
                passwordConfirmCmp.validate();
            }
        });
    }

    // @ts-ignore
    onSubmit (el, e) {
        e.preventDefault();

        let submitted = super.onSubmit();

        submitted.then(isValid => {
            if (isValid) {
                let data = this.$el.serializeArray();
                let url = this.config.action;
                let password = this.$el.find(SELECTORS.password).data('cmp-instance').getValue();
                let email = this.config.email;
                let $submitBtn = this.$el.find(SELECTORS.submitBtn);

                $submitBtn.addClass(CLASSES.disabledLegacy);

                getJson({
                    url,
                    data,
                    type: 'POST'
                }).then(async (resp) => {
                    if (resp && resp.success) {
                        if (this.gtmEnabled) {
                            await this.trackRegister(resp.gtm);

                            if (resp.subscription) {
                                await this.trackSubscribe(resp.subscription);
                            }
                        }

                        if (isRNWebView()) {
                            sendEventToMobileApp({
                                type: 'checkout.finished.registered',
                                data: {
                                    email: email,
                                    password: password
                                }
                            }, true);
                            return;
                        }

                        // @ts-ignore
                        window.location.href = resp.redirectUrl;
                    } else {
                        this.setErrorMsg();
                    }
                }).always(() => $submitBtn.removeClass(CLASSES.disabledLegacy));
            }
        });
    }

    onValidatePasswordConfirm(cmp) {
        const passwordCmp = this.$el.find(SELECTORS.password).data('cmp-instance');

        return (passwordCmp.getValue() === cmp.getValue());
    }

    // eslint-disable-next-line class-methods-use-this
    setErrorMsg() {
        //TODO
    }
}
