const DEFAULT_COOKIE_DATA = {
    path: '/',
    expires: (new Date('9999-12-31 23:59:59')).toUTCString()
};

export function setCookie(name, value = '', additionalData = {}) {
    let cookie = `${name}=${encodeURIComponent(value)}`;

    additionalData = Object.assign({}, DEFAULT_COOKIE_DATA, additionalData);

    cookie += Object.keys(additionalData).reduce((cookieProps, key) => {
        return `${cookieProps};${key}=${additionalData[key]}`;
    }, '');

    document.cookie = cookie;
}

export function hasCookie(name) {
    return document.cookie.split(';').some(cookie => cookie.includes(`${name}=`));
}

export function getCookie(name) {
    for (let cookie of document.cookie.split(';')) {
        if (cookie.includes(`${name}=`)) {
            return cookie.replace(new RegExp(`${name}=`, 'gi'), '').trim();
        }
    }

    return null;
}

export function removeCookie(name) {
    if (hasCookie(name)) {
        setCookie(name, '', { expires: 'Thu, 01 Jan 1970 00:00:01 GMT' });
    }
}
