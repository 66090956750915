import Form from 'client/core/Form';
import { CLASSES } from 'client/utils/globals';
import { scrollToMsg } from 'client/utils/common';

const SELECTORS = {
    PAYMENT_ERROR: '.js-payment-empty'
};

const METHOD_IDEAL = 'ideal';

const REQUIRED_COMPONENTS = [
    {
        method: METHOD_IDEAL,
        components: [
            'payment-ideal',
            'payment-ideal-select'
        ]
    }
];

export default class CheckoutPaymentForm extends Form {
    init() {
        this.paymentOptions = this.getNestedComponentById('payment-options');
        this.giftCardOptions = this.getNestedComponentById('giftCard-payment-option');
        this.fullyCoveredByGiftCard = this.giftCardOptions && this.giftCardOptions.fullyCoveredByGiftCard;
        this.fillSummary();

        this.emitter.addListener('payment.coveredByGC', (b) => this.setGiftCardCovered(b));
    }

    async onSubmit() {
        const $paymentError = this.$el.find(SELECTORS.PAYMENT_ERROR);

        $paymentError.addClass(CLASSES.hide);

        if (!this.paymentOptions || this.paymentOptions.selectedMethod || this.fullyCoveredByGiftCard) {
            this.giftCardOptions && this.giftCardOptions.adjustFields();

            const method = this.paymentOptions && this.paymentOptions.selectedMethod &&
                this.paymentOptions.selectedMethod.id &&
                this.paymentOptions.selectedMethod.id.toLowerCase();

            if (method) {
                REQUIRED_COMPONENTS.forEach((requiredComponent) => {
                    const required = (method === requiredComponent.method);

                    requiredComponent.components.forEach((component) => {
                        const cmpInstance = this.getNestedComponentById(component);

                        if (cmpInstance) {
                            cmpInstance.config.required = required;
                        }
                    });
                });
            }

            let isValid = await this.validate();

            this.fillSummary();

            return { submitted: isValid, form: this.$el };
        } else {
            $paymentError.removeClass(CLASSES.hide);

            scrollToMsg($paymentError);

            let isValid = await new Promise(resolve => {
                resolve(false);
            });

            return { submitted: isValid, form: this.$el };
        }

    }

    fillSummary() {
        this.summary = {};

        let selectedOption = this.paymentOptions ? this.paymentOptions.getValue() : null,
            giftCardOption = this.giftCardOptions && this.giftCardOptions.isGiftCardApplied ?
                this.giftCardOptions.getDisplayValue() : null,
            selectedPaymentOption = new Set();

        this.loopAllNestedComponents(cmp => {
            if (
                cmp.hasMethod('isSelected') && cmp.isSelected() &&
                cmp.hasMethod('getDisplayValue')
            ) {
                selectedPaymentOption.add(cmp.getDisplayValue());
            }
        });

        if (selectedOption && !this.fullyCoveredByGiftCard) {
            selectedPaymentOption.add(selectedOption.name);
            this.emitter.emit('paymentoption.updated', selectedOption.name);
        }

        if (giftCardOption) {
            selectedPaymentOption.add(giftCardOption);

            if (this.fullyCoveredByGiftCard) {
                this.emitter.emit('paymentoption.updated', giftCardOption);
            }
        }

        this.summary.selectedPaymentOption = Array.from(selectedPaymentOption).join(', ');
    }

    setGiftCardCovered(state) {
        this.fullyCoveredByGiftCard = state;

        this.$el.find('.js-payment-list-item').toggleClass(CLASSES.hide, state);

        this.fillSummary();
    }
}
