import Component from './Component';

import { CLASSES as GLOBAL_CLASSES } from 'client/utils/globals';

const CLASSES = {
    warningBlock: 'f-warning'
};

const SELECTORS = {
    inputField: '.js-field',
    errorBlock: '.js-error-block',
    errorText: '.js-error-text'
};

export default class Input extends Component {
    init () {
        this.isValid = true;

        this.$field = this.$el.find(SELECTORS.inputField);
        this.$errorBlock = this.$el.find(SELECTORS.errorBlock);
        this.$errorTextMsg = this.$errorBlock.find(SELECTORS.errorText);

        this.disabled = this.$field.prop('disabled');
        this.$field.attr('aria-required', `${!!this.config.required}`);
    }

    serialize () {
        return {
            name: this.getName(),
            value: this.getValue()
        };
    }

    getName () {
        return this.$field.attr('name');
    }

    getValue () {
        return this.$field.val();
    }

    setValue (newVal, silently = false) {
        this.$field.val(newVal);
        this.emit('changed', newVal);
        !silently && this.validate();
    }

    setError (msg) {
        this.isValid = false;
        this.$el.addClass(GLOBAL_CLASSES.formFieldError);
        this.$el.removeClass(GLOBAL_CLASSES.formFieldSuccess);
        this.$el.removeClass(GLOBAL_CLASSES.formFieldWarning);
        this.$errorTextMsg.text(msg);
        this.$errorBlock.removeClass(GLOBAL_CLASSES.hide);
        this.$errorBlock.removeClass(CLASSES.warningBlock);
        this.$field.attr('aria-invalid', `${!this.isValid}`);
    }

    clearError () {
        this.isValid = true;
        this.$el.removeClass(GLOBAL_CLASSES.formFieldError);
        this.$el.removeClass(GLOBAL_CLASSES.formFieldWarning);
        this.toggleSuccess(this.getValue());
        this.$errorBlock.addClass(GLOBAL_CLASSES.hide);
        this.$errorBlock.removeClass(CLASSES.warningBlock);
        this.$field.attr('aria-invalid', `${!this.isValid}`);
    }

    setWarning (msg) {
        this.$el.addClass(GLOBAL_CLASSES.formFieldWarning);
        this.$el.removeClass(GLOBAL_CLASSES.formFieldSuccess);
        this.$errorTextMsg.text(msg);
        this.$errorBlock.removeClass(GLOBAL_CLASSES.hide);
        this.$errorBlock.addClass(CLASSES.warningBlock);
    }

    toggleSuccess(value) {
        if (typeof value === 'string') {
            value = value.trim();
        }

        if (value) {
            this.$el.addClass(GLOBAL_CLASSES.formFieldSuccess);
        } else {
            this.$el.removeClass(GLOBAL_CLASSES.formFieldSuccess);
        }
    }

    // eslint-disable-next-line complexity
    validate () {
        const value = (this.getValue() + '').replace(/\r?\n/g, '\r\n');

        if (this.disabled) {
            this.runAction(value);
            return true;
        }

        let errorMsg;

        if (this.config.required && !value.replace(/^\s+|\s+$/g, '')) {
            errorMsg = value ? this.config.parseerror : this.config.requiredtext;
        }

        if (!errorMsg && value) {
            if (this.config.minlength && value.length < this.config.minlength) {
                errorMsg = (this.config.minlengthtext + '').replace('{0}', this.config.minlength);
            } else if (this.config.maxlength && value.length > this.config.maxlength) {
                errorMsg = (this.config.maxlengthtext + '').replace('{0}', this.config.maxlength);
            } else if (this.config.regex && !(new RegExp(this.config.regex, 'g')).test(value)) {
                errorMsg = this.config.parseerror;
            } else if (this.config.onvalidate && this.config.validateerror) {
                const cmpInstance = this.$el.data('cmp-instance'),
                    parentCmp = cmpInstance.$el.parent().closest('[data-cmp]').data('cmp-instance');

                if (parentCmp && (typeof parentCmp[this.config.onvalidate] === 'function')) {
                    if (!parentCmp[this.config.onvalidate].call(parentCmp, this)) {
                        errorMsg = this.config.validateerror;
                    }

                    if (this.config.ignoreerrormessage) {
                        if (value && !errorMsg) {
                            this.runAction(value);
                        }

                        return !errorMsg;
                    }
                }
            }
        }

        if (errorMsg) {
            this.setError(errorMsg);
        } else {
            this.clearError();
        }

        if (value && !errorMsg) {
            this.runAction(value);
        }

        return !errorMsg;
    }

    setDisabled(disabled = false) {
        this.$field.prop('disabled', disabled);
        this.disabled = disabled;
    }

    // eslint-disable-next-line consistent-return
    runAction(fieldValue) {
        if (!this.config.actionurl || !this.config.actionevent) {
            return true;
        }

        this.emitter.emit(this.config.actionevent, {
            actionUrl: this.config.actionurl,
            fieldValue: fieldValue
        });
    }
}
