import AbstractCheckoutStep from 'client/components/checkout/AbstractCheckoutStep';
import ajax from 'client/utils/ajax';
import prefs from 'sitePreferences';
import { redirectTo } from 'client/utils/common';
import { isRNWebView, sendEventToMobileApp } from 'client/utils/react';

const TEMPLATE = {
    'METHOD': '.js-component-template-shipping-method',
    'SHIPMENT': '.js-component-template-shipment-selector',
    'HEADING': '.js-component-template-method-heading'
};

const CONTENT = {
    'METHOD': '.js-component-content-shipping-method',
    'SHIPMENT': '.js-component-content-shipment-selector',
    'HEADING': '.js-component-content-method-heading'
};

export default class ShippingStep extends AbstractCheckoutStep {
    init() {
        super.init();

        this.$containers = {
            method: this.$el.find(CONTENT.METHOD),
            shipment: this.$el.find(CONTENT.SHIPMENT),
            heading: this.$el.find(CONTENT.HEADING)
        };

        this.templates = {
            method: this.Handlebars.compile(this.$el.find(TEMPLATE.METHOD).html()),
            shipment: this.Handlebars.compile(this.$el.find(TEMPLATE.SHIPMENT).html()),
            heading: this.Handlebars.compile(this.$el.find(TEMPLATE.HEADING).html())
        };

        this.emitter.addListener('step.shipping.updated', r => this.onStepUpdated(r));
        this.emitter.addListener('step.shipping.method.changed', e => this.forwardEvent(e));
        this.emitter.addListener('step.shipping.hide.title', () => this.hideBopisTitle());
        this.emitter.addListener('onChangePostalCode', (data) => this.onChangePostalCode(data));
        this.emitter.addListener('checkout.registerDuringCheckout.steps', () => this.onSubmit(true));
    }

    onStepUpdated(response) {
        if (response.cartError && response.redirectUrl) {
            redirectTo(response.redirectUrl);
        } else {
            this.updateShipmentSelector(response);
            this.onMethodChanged({
                id: response.methodID,
                changedByEvent: true,
                isAdditionalCarrierEnabled: response.isAdditionalCarrierEnabled,
                isBopisAddressExist: response.isBopisAddressExist,
                overriddenCarrierName: response.overriddenCarrierName
            });
        }
    }

    forwardEvent(e) {
        return Object.assign({}, e);
    }

    updateShipmentSelector(response) {
        if (response.order && response.order.shipping.length > 0 &&
            response.customer && response.forms && response.forms.shippingForm
        ) {
            this.replaceHtml(this.templates.shipment({
                shipping: response.order.shipping[0],
                customer: response.customer,
                addressForm: response.forms.shippingForm.shippingAddress.addressFields
            }));
        }
    }

    hideBopisTitle() {
        this.replaceHtml(this.templates.heading({
            isBopisAddressExist: false
        }), this.$containers.heading);
    }

    getMethodType(methodID) {
        let method;

        if ((new RegExp(prefs.shippingMethods.homeDelivery).test(methodID))) {
            method = 'home-delivery';
        } else if ((new RegExp(prefs.shippingMethods.shipToStore).test(methodID))) {
            method = 'ship-to-store';
        } else if ((new RegExp(prefs.shippingMethods.pickUpStation).test(methodID))) {
            method = 'pick-up-station';
        } else {
            method = 'home-delivery';
        }

        return method;
    }

    onMethodChanged(data) {
        let id = this.getMethodType(data.id);
        let isPickUpStationAddressExist = this.$containers.shipment.find('.js-pick-up-station-tab').length > 0;
        let showPickUpStationTitle = id === 'pick-up-station' && isPickUpStationAddressExist;

        this.replaceHtml(this.templates.heading({
            method: id,
            isSavedPickUpAddress: showPickUpStationTitle,
            isAdditionalCarrierEnabled: data.isAdditionalCarrierEnabled,
            isBopisAddressExist: data.isBopisAddressExist,
            overriddenCarrierName: data.overriddenCarrierName
        }), this.$containers.heading);
    }

    // eslint-disable-next-line consistent-return
    async onChangePostalCode(data) {
        let csrfToken = await ajax.getCSRFToken({ doNotStopSpinner: true });

        if (!csrfToken) {
            return {
                error: true
            };
        }

        let promise = ajax.getJson({
            url: data.actionUrl,
            type: 'POST',
            data: {
                postalCode: data.fieldValue,
                [csrfToken.tokenName]: csrfToken.token
            }
        });

        promise.then((resp) => {
            if (resp.cartError && resp.redirectUrl) {
                redirectTo(resp.redirectUrl);
            } else if (resp.isChangeShippingCost) {
                this.emitter.emit('action.summary.update', resp);

                if (resp.msg) {
                    this.emitter.emit('checkout.shipping.infoblock.show', {
                        type: 'warning',
                        scrollTo: true,
                        message: resp.msg
                    });
                } else {
                    this.emitter.emit('checkout.shipping.infoblock.hide');
                }
            } else {
                this.emitter.emit('checkout.shipping.infoblock.hide');
            }
        });
    }

    trackCheckoutShipping(resp) {
        this.emitter.emit('gtmCheckoutOptionsEvent', 3, resp.shippingMethod);
    }

    trackSubscribe(data) {
        const eventData = {
            eventCategory: 'newsletter',
            eventAction: 'checkout sign-up',
            eventLabel: data.interest
        };

        this.emitter.emit('gtmEvent', eventData);
    }

    async onSubmit(skipAddressValidation) {
        this.emitter.emit('prevent.multiple.clicks');

        let { submitted, form } = await this.form.onSubmit(skipAddressValidation);

        if (submitted) {
            this.emitter.emit('lock.submit.button');

            let promise = ajax.getJson({
                url: form.data('editAddressUrl'),
                type: 'POST',
                data: form.serialize()
            });

            promise.then((resp) => {
                this.emitter.emit('unlock.submit.button');

                if (resp.cartError && resp.redirectUrl) {
                    redirectTo(resp.redirectUrl);
                } else if (resp.error && resp.errorStage) {
                    this.emitter.emit('checkout.shipping.infoblock.show', {
                        type: 'error',
                        scrollTo: true,
                        message: resp.errorStage.errorMessage
                    });
                    this.emit('checkout.step.error', resp.errorStage);
                } else {
                    if (this.gtmEnabled) {
                        this.trackCheckoutShipping(resp);

                        if (resp.subscription) {
                            this.trackSubscribe(resp.subscription);
                        }
                    }

                    if (isRNWebView()) {
                        sendEventToMobileApp({
                            type: 'checkout.next.step.action'
                        }, true);
                    }

                    this.emitter.emit('checkout.shipping.infoblock.hide');

                    super.onSubmit();

                    this.emitter.emit('checkout.registerDuringCheckout.registration.shipping.done');
                }
            });
        }
    }
}
