import Form from 'client/core/Form';

import ajax from 'client/utils/ajax';
import { CLASSES } from 'client/utils/globals';
import { hasCookie, getCookie, removeCookie } from 'client/utils/cookie';
import { appendParamToURL } from 'client/utils/url';
import inputmask from 'inputmask';
import {
    getCurrentDateAsString, redirectTo, scrollToMsg, validateDate
} from 'client/utils/common';

const customValidator = require('client/utils/customValidator');

const SELECTORS = {
    error: '.js-error',
    email: '[data-id="{FORM}_email"]',
    password: '[data-id="{FORM}_password"]',
    passwordConfirm: '[data-id="{FORM}_passwordConfirm"]',
    successMsg: '.js-success-saved-msg'
};

const INPUTS = {
    birthday: '[name="{FORM}_birthday"]'
};

const COOKIE_ID = 'ccsid';
const COOKIE_BACK_URL = 'loginBackUrl';

export default class RegisterForm extends Form {
    toggleError(msg = '') {
        this.$.error.html(msg);
        this.$.error.toggleClass(CLASSES.hide, !msg);

        if (msg) {
            scrollToMsg(this.$.error);
        }
    }

    setFieldError(field, message) {
        let fld = field.match(this.FIELD_PATTERN);

        let cmp = this.$el.find(`#${fld}`).closest('[data-cmp]').data('cmp-instance');

        cmp && cmp.setError && cmp.setError(message);
    }

    showFieldErrors(fields) {
        if (typeof fields === 'object') {
            // eslint-disable-next-line no-restricted-syntax
            for (let key of Object.keys(fields)) {
                this.setFieldError(key, fields[key]);
            }
        }
    }

    async trackSubscribe(data) {
        const eventData = {
            eventCategory: 'newsletter',
            eventAction: 'my account sign-up',
            eventLabel: data.interest
        };

        this.emitter.emit('gtmEvent', eventData);
    }

    async trackRegister(layerData) {
        const eventData = Object.assign({}, layerData, {
            eventCategory: 'account',
            eventAction: 'register',
            eventLabel: 'outside checkout',
            ga4eventname: 'account_sign_up'
        });

        this.emitter.emit('gtmEvent', eventData);
    }

    /**
     * Send google token to backend in order to be verified
     * @param {String} googleSiteKey
     * @returns {Promise<Object|string>}
     */
    async validateCaptcha(googleSiteKey) {
        return new Promise((res, rej) => {
            this.recaptchaReadyCallback(googleSiteKey).then(async recaptchaToken => {
                // @ts-ignore
                const csrfToken = await ajax.getCSRFToken({ doNotStopSpinner: true });

                if (!csrfToken) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return rej('ERRORE 403');
                }

                if (!recaptchaToken) {
                    // @ts-ignore
                    return rej(Resources.RECAPTCHA_VERIFICATION_FAILED);
                }

                // @ts-ignore
                let url = window.Urls.recaptchaVerify;

                if (recaptchaToken) {
                    url = appendParamToURL(url, 'recaptchaToken', recaptchaToken);
                }

                let response = null;

                // @ts-ignore
                response = await ajax.getJson({
                    url: url,
                    type: 'POST',
                    data: {
                        [csrfToken.tokenName]: csrfToken.token
                    }
                });

                if (response.isValid) {
                    return res(response);
                }

                // @ts-ignore
                this.toggleError(Resources.RECAPTCHA_VERIFICATION_FAILED);

                // @ts-ignore
                return rej(Resources.RECAPTCHA_VERIFICATION_FAILED);
            }).catch(() => {
                // @ts-ignore
                return rej(Resources.RECAPTCHA_VERIFICATION_FAILED);
            });
        });
    }

    /**
     * Waits for grecaptcha object to be present and execute action submit
     * @param {String} googleSiteKey
     * @returns {Promise<any>}
     */
    // eslint-disable-next-line class-methods-use-this
    async recaptchaReadyCallback(googleSiteKey) {
        return new Promise((res) => {
            // @ts-ignore
            window.grecaptcha.ready(function() {
                // @ts-ignore
                window.grecaptcha.execute(googleSiteKey, { action: 'submit' }).then(function(recaptchaToken) {
                    return res(recaptchaToken);
                });
            });
        });
    }

    // @ts-ignore
    async onSubmit(el, ev) {
        this.hideSuccessMessage();
        ev.preventDefault();
        let isRecaptchaTokenValid = false;
        let isRecaptchaEnabled = (
            // @ts-ignore
            SitePreferences.googleReCaptchaEnable && SitePreferences.googleReCaptchaSite.length > 0
        );

        if (isRecaptchaEnabled) {
            // @ts-ignore
            isRecaptchaTokenValid = await this.validateCaptcha(SitePreferences.googleReCaptchaSite);

            if (!isRecaptchaTokenValid) {
                return Promise.reject();
            }
        }
        let isValid = super.onSubmit();

        isValid.then(isValid => {

            if (isValid) {
                const ccsid = this.ccsid,
                    ccsValue = this.$el.closest('html').find('[data-id="' + ccsid + '"]').data('value');

                const $registerForm = this.$el,
                    url = $registerForm.attr('action');

                let data = $registerForm.serializeArray();

                data.unshift({
                    name: ccsid,
                    value: ccsValue
                });

                // @ts-ignore
                ajax.getJson({
                    url: url,
                    type: 'POST',
                    data
                }).then(async (resp) => {
                    this.toggleError(resp.errorMessage || '');
                    if (resp.success && hasCookie(COOKIE_BACK_URL)) {
                        const redirectUrl = decodeURIComponent(getCookie(COOKIE_BACK_URL));

                        removeCookie(COOKIE_BACK_URL);
                        redirectTo(redirectUrl);
                    } else if (resp.redirectUrl) {
                        redirectTo(resp.redirectUrl);
                    } else if (resp.success) {
                        if (this.gtmEnabled) {
                            await this.trackRegister(resp.gtm);

                            if (resp.subscription) {
                                await this.trackSubscribe(resp.subscription);
                            }
                        }

                        if (this.emarsysTrackingJsEnabled) {
                            this.emitter.emit('emarsys.set.email', resp.emarsys.cuid);
                        }

                        this.showSuccessMsg();
                    } else if (resp.fields && !resp.errorMessage) {
                        this.showFieldErrors(resp.fields);
                    }
                });
            }
        });

        return isValid;
    }

    // eslint-disable-next-line class-methods-use-this
    onValidateEmail(cmp) {
        return customValidator.validateEmail(cmp.getValue());
    }

    onValidateEmailConfirm(cmp) {
        const emailCmp = this.$el.find(this.$.email).data('cmp-instance');

        return (emailCmp.getValue().toLowerCase() === cmp.getValue().toLowerCase());
    }

    onValidatePasswordConfirm(cmp) {
        const passwordCmp = this.$el.find(this.$.password).data('cmp-instance');

        return (passwordCmp.getValue() === cmp.getValue());
    }

    // eslint-disable-next-line class-methods-use-this
    onValidateBirthday(cmp) {
        return validateDate(cmp.getValue());
    }

    resolveSelector(selector) {
        return selector.replace('{FORM}', this.form);
    }

    showSuccessMsg() {
        if (this.$.successMsg) {
            this.$.successMsg.removeClass(CLASSES.hide);
            scrollToMsg(this.$.successMsg);
        }
    }

    hideSuccessMessage() {
        if (this.$.successMsg) {
            this.$.successMsg.addClass(CLASSES.hide);
        }
    }

    init() {
        this.ccsid = getCookie(COOKIE_ID);
        removeCookie(COOKIE_ID);

        this.form = this.$el.data('form');

        this.FIELD_PATTERN = new RegExp(`^${this.form}_(.+)$`, 'i');

        this.$ = {};

        // eslint-disable-next-line no-restricted-syntax
        for (let k of Object.keys(SELECTORS)) {
            this.$[k] = this.$el.find(this.resolveSelector(SELECTORS[k]));
        }

        inputmask(
            'datetime',
            {
                inputFormat: 'dd.mm.yyyy',
                placeholder: '_',
                min: '01.01.1901',
                max: getCurrentDateAsString()
            }
        )
            .mask(this.resolveSelector(INPUTS.birthday));

        this.bindEvent('focusout', this.$.password, function() {
            const passwordCmp = this.$.password.data('cmp-instance');
            const passwordConfirmCmp = this.$.passwordConfirm.data('cmp-instance');

            if (passwordConfirmCmp.getValue() && passwordCmp.getValue()) {
                passwordConfirmCmp.validate();
            }
        });
    }
}
