import Component from 'client/core/Component';

const SELECTORS = {
    breadcrumbsWrapper: '.js-breadcrumbs-wrapper'
};

export default class Breadcrumbs extends Component {
    init() {
        this.emitter.addListener(
            'refinement.updated',
            ($response) => this.updateBreadcrumbs($response)
        );
    }

    updateBreadcrumbs($response) {
        this.replaceHtml($response.find(SELECTORS.breadcrumbsWrapper).html());
    }
}
