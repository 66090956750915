import templatesManager from 'client/utils/templatesManager';
import $ from 'jquery';

const SELECTORS = {
    'TOOLTIP': '.js-tooltip',
    'APPLY_BTN': '.js-tooltip-apply-btn',
    'CLOSE_BTN': '.js-tooltip-close-btn'
};
const template = templatesManager.templates.consentTooltipTemplate;

class Tooltip {
    constructor(element, message, callback) {
        this.element = $(element);
        this.message = message;
        this.callback = callback;
        this.tooltipElement = null;
        this.params = null;

        this.showTooltip = this.showTooltip.bind(this);
        this.hideTooltip = this.hideTooltip.bind(this);
        this.applyTooltip = this.applyTooltip.bind(this);
        this.setParams = this.setParams.bind(this);

        this.init();
    }

    init() {
        this.element.on('click', this.showTooltip);
    }

    createTooltip() {
        this.tooltipElement = $(template({ message: this.message }));

        this.tooltipElement.find(SELECTORS.APPLY_BTN).on('click', (event) => {
            event.stopPropagation();
            event.preventDefault();
            this.applyTooltip();

        });
        this.tooltipElement.find(SELECTORS.CLOSE_BTN).on('click', (event) => {
            event.stopPropagation();
            event.preventDefault();
            this.hideTooltip();
        });
    }

    showTooltip() {
        if (!this.tooltipElement) {
            this.createTooltip();
            this.element.append(this.tooltipElement);
        }
        this.tooltipElement.removeClass('h-hide');
        this.tooltipElement.addClass('h-expanded');
    }

    hideTooltip() {
        if (this.tooltipElement) {
            this.tooltipElement.addClass('h-hide');
            this.tooltipElement.removeClass('h-expanded');
        }

        if (this.params) {
            this.tooltipElement.remove();
        }
    }

    applyTooltip() {
        if (this.params) {
            this.callback(this.params.el, this.params.ev);
        } else {
            this.callback();
        }

        this.tooltipElement.remove();
    }

    setParams(params) {
        this.params = params;
    }
}

export default Tooltip;
