import BaseMiniCart from 'client/components/header/MiniCart';


const SELECTORS = {
    utilityMiniCart: '.js-utility-minicart'
};

const STATE_CLASSES = {
    utilityMiniCartFull: 'c-utility-minicart--full'
};

export default class MiniCart extends BaseMiniCart {
    init () {
        super.init();
    }

    updateMiniCartCount(count) {
        const utilityMiniCart = this.$el.find(SELECTORS.utilityMiniCart);

        if (count) {
            utilityMiniCart.addClass(STATE_CLASSES.utilityMiniCartFull);
        } else {
            utilityMiniCart.removeClass(STATE_CLASSES.utilityMiniCartFull);
        }

        super.updateMiniCartCount(count);
    }
}
