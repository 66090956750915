import Component from 'client/core/Component';
import { pushState } from 'client/utils/history';
import { scrollTo } from 'client/utils/common';
import { CLASSES } from 'client/utils/globals';
// @ts-ignore
import { isRNWebView, sendEventToMobileApp } from 'client/utils/react';

const LOGIN_STEP = 'login',
    SHIPPING_STEP = 'shipping',
    PAYMENT_STEP = 'payment',
    PLACE_ORDER_STEP = 'placeOrder',
    SUBMITTED_STEP = 'submitted';

const CHECKOUT_STAGES = [
    LOGIN_STEP,
    SHIPPING_STEP,
    PAYMENT_STEP,
    PLACE_ORDER_STEP,
    SUBMITTED_STEP
];

const SELECTORS = {
    cartCoupon: '[data-cmp="cartCoupon"]',
    PLACE_ORDER_BTN: '.js-submit-order'
};

const cartCouponStages = [LOGIN_STEP, SHIPPING_STEP, PAYMENT_STEP];

export default class CheckoutMgr extends Component {
    init() {
        this.$cartCoupon = this.$el.find(SELECTORS.cartCoupon);

        this.components = this.getChildrenComponents();
        this.currentStage = this.config.checkoutStage;

        // @ts-ignore
        window.onpopstate = (e) => this.onPopState(e);
        this.initEvents();
        this.changeActiveSection(true);
        this.emailFilled = false;
        this.loginStepReady = false;
        this.emitter.emit('trbo.page', 'checkout');
    }

    nextStage() {
        let index = CHECKOUT_STAGES.indexOf(this.currentStage);

        if (this.config.profileCheckoutInfo.isCustomerHasPaymentStepData) {
            this.gotoStep(PLACE_ORDER_STEP);
        } else if (this.editFrom) {
            this.currentStage = CHECKOUT_STAGES[this.editFrom];
            this.editFrom = null;
            this.changeActiveSection(true);
        } else if (index < CHECKOUT_STAGES.length - 1) {
            this.currentStage = CHECKOUT_STAGES[index + 1];
            this.changeActiveSection(true);
        }
    }

    gotoStep(step, forward = true, editFromStep = null) {
        if (editFromStep) {
            this.editFrom = editFromStep - 1;
        }

        if (CHECKOUT_STAGES.indexOf(step) > -1) {
            this.currentStage = step;
            this.changeActiveSection(forward);
        }
    }

    trackError(errorType) {
        const data = {
            eventCategory: 'checkout error',
            eventAction: this.currentStage,
            eventLabel: errorType,
            ga4eventname: 'checkout_error'
        };

        this.emitter.emit('gtmEvent', data);
    }

    error(error) {
        this.currentStage = error.step;
        this.changeActiveSection();
        let stepCmp = this.components.get(error.step);

        if (this.gtmEnabled) {
            this.trackError(error.errorType);
        }

        if (stepCmp && 'onStepError' in stepCmp && typeof stepCmp.onStepError === 'function') {
            stepCmp.onStepError(error.errorType);
        }
    }

    onPopState(event) {
        if (event.state && event.state.stateData) {
            this.gotoStep(event.state.stateData, false);
        } else {
            // @ts-ignore
            // eslint-disable-next-line no-restricted-globals
            history.back();
        }
    }

    /**
     * @returns {Map<string, Component>}
     */
    getChildrenComponents () {
        let cmps = new Map(); //eslint-disable-line no-undef

        // eslint-disable-next-line no-restricted-syntax
        for (const cmpID of CHECKOUT_STAGES) {
            let cmp = this.getNestedComponentById(cmpID);

            if (cmp) {
                cmps.set(cmpID, cmp);
            }
        }

        return cmps;
    }

    changeActiveSection(push) {
        // @ts-ignore
        // eslint-disable-next-line no-restricted-syntax
        for (const [id, cmp] of this.components) {
            const stepIndex = CHECKOUT_STAGES.indexOf(id),
                currentStep = CHECKOUT_STAGES.indexOf(this.currentStage);

            if (id === this.currentStage) {
                cmp.active(stepIndex, currentStep);
                const moveToCmp = this.components.get('login') || this.components.get('shipping');

                // Scroll to top position
                setTimeout(() => { scrollTo(moveToCmp.$el); }, 10);
            } else {
                cmp.inactive(stepIndex, currentStep);
            }
        }

        if (push) {
            // @ts-ignore
            // eslint-disable-next-line no-restricted-globals
            pushState(`${location.pathname}?stage=${this.currentStage}#${this.currentStage}`, this.currentStage);
            if (this.gtmEnabled) {
                const stepName = `${this.currentStage}Step`;
                const $stepCmp = this.$el.find(`[data-cmp="${stepName}"]`);
                const gtmDataJSON = $stepCmp.attr('data-gtm') || '{}';
                let gtmData;

                try {
                    gtmData = JSON.parse(gtmDataJSON);
                } catch (err) {
                    console.log(err); // eslint-disable-line no-console
                }

                Object.keys(gtmData).length > 0 && this.emitter.emit('gtmEcommerceEvent', 'eeCheckout', gtmData);
            }
        }

        // eslint-disable-next-line no-bitwise
        this.$cartCoupon.toggleClass(CLASSES.hide, !~cartCouponStages.indexOf(this.currentStage));
    }

    csrfUpdated(data) {
        let tokens = this.$el.find('[name="' + data.tokenName + '"]');
        let dataTokens = this.$el.find('[data-csrf-name="' + data.tokenName + '"]');

        tokens.val(data.token);
        dataTokens.attr('data-csrf-token', data.token);

        this.emitter.emit('checkout.csrf.updated.root', data);
    }

    onNextStage() {
        this.emitter.emit('checkout.step.submitted');
        this.nextStage();
        this.emitter.emit('trbo.checkout.step');
    }

    onEditStep(id, editFromStep) {
        this.gotoStep(id, true, editFromStep);
        this.emitter.emit('trbo.checkout.step');
    }

    lockPlaceOrderButton() {
        this.$el.find(SELECTORS.PLACE_ORDER_BTN).prop('disabled', true);
        setTimeout(this.unlockPlaceOrderButton.bind(this), 10000);
    }

    unlockPlaceOrderButton() {
        this.$el.find(SELECTORS.PLACE_ORDER_BTN).prop('disabled', false);
    }

    initEvents() {
        // @ts-ignore
        // eslint-disable-next-line no-restricted-syntax
        for (const [id, item] of this.components) {
            item.addListener('checkout.step.edit', editFromStep => this.onEditStep(id, editFromStep));
            item.addListener('checkout.step.submitted', () => this.onNextStage());
            item.addListener('checkout.step.error', (error) => this.error(error));
            item.addListener('checkout.csrf.updated', (data) => this.csrfUpdated(data));
        }

        this.emitter.addListener('checkout.csrf.updated', (data) => this.csrfUpdated(data));

        this.emitter.addListener('lock.placeorder.button', () => this.lockPlaceOrderButton());
        this.emitter.addListener('unlock.placeorder.button', () => this.unlockPlaceOrderButton());

        this.emitter.addListener('checkout.step.login.email.updated', (email) => {
            if (email && email.length > 0) {
                this.emailFilled = true;
            }
        });

        this.emitter.addListener('checkout.step.login.loaded', () => {
            this.loginStepReady = true;

            if (this.config.intloginEnabled && this.currentStage !== LOGIN_STEP && !this.emailFilled) {
                this.currentStage = LOGIN_STEP;
                this.changeActiveSection(true);
            }
        });

        if (isRNWebView()) {
            const checkoutSessionTimeout = 30 * 60 * 1000;

            // After 30 minutes of inactivity send postMessage to mobile app
            setTimeout(() => sendEventToMobileApp({
                type: 'checkout.error.session'
            }, true), checkoutSessionTimeout);
        }

        this.emitter.emit('checkout.manager.ready');
    }
}
