/* global google */

import Modal from 'client/components/Modal';
import { load } from 'client/utils/ajax';
import { CLASSES } from 'client/utils/globals';
import { SERVER_UNAVAILABLE } from 'resources';
import $ from 'jquery';
import Tooltip from 'client/components/Tooltips';
import { CONSENT_MESSAGE_STOREAVAILABILITY } from 'resources';
import { isManageGoogleMapByConsent } from 'sitePreferences';


const SELECTORS = {
    modal: '.js-modal',
    modalDesktopFullHeight: 'h-find-in-store-modal',
    findInStoreButton: '.js-pdp-find-in-store'
};

export default class FindInStore extends Modal {
    initEvent() {
        super.init();
        this.findInStoreButton = $(SELECTORS.findInStoreButton);
        this.productID = this.config.productId;

        this.emitter.addListener('variation.updated', this.onVariationUpdate.bind(this));
        this.emitter.addListener('findinstore.action.open', this.showModal.bind(this));
    }

    init () {
        if (!window.consentStatus && isManageGoogleMapByConsent) {
            this.toolTip = new Tooltip(this.$el, CONSENT_MESSAGE_STOREAVAILABILITY, this.acceptConsent.bind(this));
            return;
        }

        this.initEvent();
    }

    acceptConsent() {
        window.dispatchEvent(new CustomEvent('apply.consent.cookie', { detail: { consentStatus: true } }));
        this.initEvent();
        this.showModal();
    }

    onVariationUpdate (data) {
        this.productID = data.id;

        var aInfo = this.config.storeAvailabilityInfo.filter(ai => ai.ID === this.productID)[0];
        var aInfoDisabled = !aInfo || !(aInfo.isAvailableInStore && aInfo.isAvailableOnline);
        var bopisStoreButtonEnabled = data.bopisAvailabilityData && data.bopisAvailabilityData.findStoreButton.enabled;
        var bopisText = data.bopisAvailabilityData.findStoreButton.text;

        if (
                (aInfoDisabled && !data.bopisAvailabilityData) ||
                ((data.bopisAvailabilityData && !bopisStoreButtonEnabled) ||
                !bopisText)
            ) {
            this.$el.addClass(CLASSES.hide);
            this.findInStoreButton.addClass(CLASSES.hide);
            this.findInStoreButton.parent().addClass(CLASSES.oneCta);
        } else {
            this.$el.removeClass(CLASSES.hide);
            this.findInStoreButton.removeClass(CLASSES.hide);
            this.findInStoreButton.parent().removeClass(CLASSES.oneCta);
        }

        if (bopisStoreButtonEnabled && bopisText) {
            this.findInStoreButton.find('.js-find-in-store-text').text(bopisText);
        }
    }

    openFindInStoreModal () {
        if (this._interval) {
            return;
        } else if (typeof google === 'undefined') {
            this._interval = setInterval(this.openFindInStoreModal.bind(this), 10);
        } else {
            if (this._interval) {
                clearInterval(this._interval);
                this._interval = null;
            }

            $.spinner.stopAll();
            this.open(this.config);
            this.buttons({
                buttons: [
                    {
                        selector: '.js-close-modal',
                        callback: this.close.bind(this)
                    }
                ]
            });
        }
    }

    trackStoreFinderClick() {
        const data = {
            eventCategory: 'store finder',
            eventAction: 'product page',
            eventLabel: '',
            eventValue: '',
            ga4eventname: this.ga4eventnameVal
        };

        this.emitter.emit('gtmEvent', data);
    }

    async showModal (el, e) {
        e && e.preventDefault();

        var isBopis = this.$el.find('button').data('attr-is-bopis');

        if (!(this.productID && this.config.targetUrl)) {
            // eslint-disable-next-line
            console.error(`Not all required params exist: pid=${this.productID}, targetURL=${this.config.targetUrl}`);
            return;
        }

        this.emitter.emit('findinstore.open');

        try {
            this.config.content = await load({
                url: this.config.targetUrl,
                doNotStopSpinner: true,
                data: {
                    pid: this.productID
                }
            });
        } catch (error) {
            $.spinner.stopAll();
            alert(SERVER_UNAVAILABLE);

            return;
        }
        this.openFindInStoreModal();
        this.$modalWrapper = $(document).find(SELECTORS.modal).addClass(SELECTORS.modalDesktopFullHeight);

        this.ga4eventnameVal = 'pdp_store_finder';

        if (this.gtmEnabled && !isBopis) {
            this.trackStoreFinderClick();
        }
    }
}
