import Form from 'client/core/Form';
import { CLASSES } from 'client/utils/globals';
import { scrollToMsg } from 'client/utils/common';
import { getCookie, removeCookie } from 'client/utils/cookie';
import { isRNWebView, sendEventToMobileApp } from 'client/utils/react';
import ajax from 'client/utils/ajax';
import $ from 'jquery';

const SELECTORS = {
    CHECKOUT_MGR: '[data-cmp="checkoutMgr"]',
    emailFormInput: '#dwfrm_profile_customer_email',
    passwordFormInput: '#dwfrm_profile_login_password',
    successBlock: '.js-pwd-reset-success',
    errorBlock: '.js-login-error-block',
    errorText: '.js-login-error-text',
    emailRow: '.js-login-email',
    passwordRow: '.js-login-password',
    formHeader: '.js-modal-header',
    submit: '.js-submit'
};

const COOKIE_ID = 'ccsid';

const setLocation = url => {
    window.location.href = url;
};

export default class LoginForm extends Form {
    init() {
        this.ccsid = getCookie(COOKIE_ID);
        removeCookie(COOKIE_ID);
    }

    trackLogin(layerData) {
        const checkoutMgr = $(SELECTORS.CHECKOUT_MGR);
        const eventData = Object.assign({}, layerData, {
            eventCategory: 'account',
            eventAction: 'login',
            eventLabel: checkoutMgr ? 'Intent from Checkout' : '',
            ga4eventname: 'account_login'
        });

        this.emitter.emit('gtmEvent', eventData);

        const gtmData = this.config.gtmCheckout;

        if (gtmData) {
            this.emitter.emit('gtmEcommerceEvent', 'eeCheckout', gtmData);
        }
    }

    onSubmit(el, e) {
        const isEmailFieldDisabled = this.$el.find(SELECTORS.emailFormInput).attr('disabled');

        if (isEmailFieldDisabled) {
            this.$el.find(SELECTORS.emailFormInput).removeAttr('disabled');
        }

        e.preventDefault();

        const submitted = super.onSubmit();

        submitted.then(isValid => {
            if (isValid) {
                this.lockSubmitButton();

                let data = this.$el.serialize();
                let value = this.$el.closest('html').find('[data-id="' + this.ccsid + '"]').data('value');

                let email = this.$el.find(SELECTORS.emailFormInput).val();
                let password = this.$el.find(SELECTORS.passwordFormInput).val();

                data = this.ccsid + '=' + value + '&' + data;

                ajax.getJson({
                    url: this.config.actionUrl,
                    type: 'POST',
                    data: data
                }).then((result) => {
                    if (isEmailFieldDisabled) {
                        this.$el.find(SELECTORS.emailFormInput).attr('disabled', 'disabled');
                    }

                    let redirectOrReload = false;

                    if (result && result.success) {
                        if (this.gtmEnabled) {
                            this.trackLogin(result.gtm);
                        }

                        if (isRNWebView()) {
                            sendEventToMobileApp({
                                type: 'checkout.finished.registered',
                                data: {
                                    email: email,
                                    password: password
                                }
                            }, true);
                        }

                        if (this.emarsysTrackingJsEnabled) {
                            this.emitter.emit('emarsys.set.email', result.cuid);
                        }

                        if (this.config.isRedirect === false) {
                            // in case of the login with popup reload the page to update displayed promotions, header, etc
                            redirectOrReload = true;
                            window.location.reload();
                        } else if (result.redirectUrl) {
                            redirectOrReload = true;
                            setLocation(result.redirectUrl);
                        } else {
                            const $successBlock = this.$el.find(SELECTORS.successBlock);

                            this.$el.replaceWith($successBlock);
                            $successBlock.removeClass(CLASSES.hide);
                            let modalHeader = $(SELECTORS.formHeader);

                            modalHeader.addClass(CLASSES.hide);
                        }
                    } else {
                        this.setErrorMsg(result && result.error);
                        this.emitter.emit('login.form.error');
                    }

                    if (!redirectOrReload) {
                        this.unlockSubmitButton();
                    }
                }).fail((data) => {
                    if (isEmailFieldDisabled) {
                        this.$el.find(SELECTORS.emailFormInput).attr('disabled', 'disabled');
                    }

                    if (data.responseJSON && data.responseJSON.redirectUrl) {
                        setLocation(data.responseJSON.redirectUrl);
                    } else if (data.responseJSON.message) {
                        this.setErrorMsg([data.responseJSON.message]);
                        this.unlockSubmitButton();
                    } else {
                        window.location.reload();
                    }
                });
            }
        });
    }

    setErrorMsg(error) {
        let errorBlock = this.$el.find(SELECTORS.errorBlock);
        let errorTextMsg = errorBlock.find(SELECTORS.errorText);
        let errorMessage = (error && error[0]) ? error[0] : this.config.errorMsg;

        errorTextMsg.text(errorMessage);
        errorBlock.removeClass(CLASSES.hide);

        scrollToMsg(errorBlock);
    }

    lockSubmitButton() {
        this.$el.find(SELECTORS.submit).prop('disabled', true);
    }

    unlockSubmitButton() {
        this.$el.find(SELECTORS.submit).prop('disabled', false);
    }
}
