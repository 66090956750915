import Component from 'client/core/Component';
import { GLOBAL_CONSTANT_VALUES as constants } from 'client/utils/globals';

const SELECTORS = {
    'RADIUS_SELECT_DEFAULT_OPTION': '.js-store-locator-radius-wrapper option:first-child'
};

export default class FindInStoreSearchPanel extends Component {
    init () {
        this.geoData = {
            postalCode: null,
            location: {
                lat: null,
                lng: null
            }
        };

        this.postalFieldCmp = this.getNestedComponentById(this.config.postalFieldCmp);

        this.radiusCmp = this.getNestedComponentById(this.config.radiusCmp);

        if (this.postalFieldCmp) {
            this.postalFieldCmp.addListener('data.changed', data => {
                this.geoData = Object.assign({}, this.geoData, data);
            });
        }

        this.getNestedComponentById(this.config.detectLocationCmp, cmp => {
            cmp.addListener('data.changed', data => {
                this.geoData = Object.assign({}, this.geoData, data);

                if (this.postalFieldCmp && typeof this.postalFieldCmp.getField === 'function') {
                    let field = this.postalFieldCmp.getField();

                    field && typeof field.setValue === 'function' && field.setValue(data.postalCode || '');
                }
            });

            cmp.addListener('data.error', error => {
                // eslint-disable-next-line no-alert
                alert(error.msg);
            });
        });
        this.addListenerOnChild(this.config.runSearchCmp, 'clicked', this.onSubmit.bind(this));
    }

    getDefaultRadius() {
        const $radiusOption = this.$el.find(SELECTORS.RADIUS_SELECT_DEFAULT_OPTION);
        const defaultRadiusValue = $radiusOption && $radiusOption.length && $radiusOption.val();

        return defaultRadiusValue || constants.defaultStoreRadiusValue;
    }

    async validate () {
        if (!this.radiusCmp || !this.postalFieldCmp || !this.postalFieldCmp.fieldCmp) {
            return false;
        }

        let radiusValidationResult = await this.radiusCmp.validate(),
            postalValidationResult = await this.postalFieldCmp.fieldCmp.validate();

        return radiusValidationResult && postalValidationResult;
    }

    async onSubmit () {
        if (!(await this.validate())) {
            return;
        }

        if (
            !this.postalFieldCmp ||
            typeof this.postalFieldCmp.getGeoCoords !== 'function' ||
            !this.radiusCmp ||
            typeof this.radiusCmp.getValue !== 'function'
        ) {
            // eslint-disable-next-line no-console
            console.error('Wrong configuration! Radius component must be an instance of dropdown');

            return;
        }

        let coords = await this.postalFieldCmp.getGeoCoords();

        this.emit('action.find', Object.assign({}, {
            radius: (this.radiusCmp.getValue() || this.getDefaultRadius())
        }, coords));
    }
}
