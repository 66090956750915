import Component from 'client/core/Component';

import ajax from 'client/utils/ajax';
import { CLASSES } from 'client/utils/globals';
import { scrollTo } from 'client/utils/common';

const SELECTORS = {
    expand: '.js-expand-button',
    details: '.js-details-container'
};

export default class OrderHistoryCard extends Component {
    loadData(callback) {
        ajax.load({
            url: this.config.url,
            data: { orderID: this.config.id },
            callback,
            target: this.$.details
        });
    }

    onExpandClick() {
        if (!this.dataLoaded) {
            this.loadData((resp) => {
                if (resp) {
                    this.dataLoaded = true;

                    scrollTo(this.$el, {
                        callback: () => {
                            this.emitter.emit('namespace.component.initall', this.$.details);
                            this.$.details.removeClass(CLASSES.hide);
                        }
                    });
                }
            });
        }
    }

    init() {
        this.dataLoaded = false;

        this.$ = {};

        for (let k of Object.keys(SELECTORS)) {
            this.$[k] = this.$el.find(SELECTORS[k]);
        }

        this.bindEvent('click', SELECTORS.expand, this.onExpandClick);

        if (this.config.load) {
            this.$.expand.click();
        }
    }
}
