import Component from 'client/core/Component';
import $ from 'jquery';
import { CLASSES } from 'client/utils/globals';

const SELECTORS = {
    stickyElement: '.js-pdp-sticky-wrapper'
};

export default class ModalStickyButton extends Component {
    init() {
        this.$stickyElement = this.$el.find(SELECTORS.stickyElement);
        this.disabledScroll = false;
        this.$window = $(window);
        this.isSticky = true;
        this.definePosition();
        this.emitter.addListener('modal.content.scrolled', this.definePosition.bind(this));
        this.emitter.addListener('resize', this.definePosition.bind(this));
        this.emitter.addListener('orientationChange', this.definePosition.bind(this));
    }

    calculatePosition() {
        this.$el.css('min-height', this.$stickyElement.innerHeight());
        this.stickyElementPositionTop = (this.$el.offset().top - this.$window.innerHeight()) +
            this.$stickyElement.innerHeight();
        this.stickyElementPositionTop = this.stickyElementPositionTop < 0 ? 0 : this.stickyElementPositionTop;
    }

    definePosition() {
        this.calculatePosition();

        if (this.isSticky && this.stickyElementPositionTop <= 0) {
            this.$el.addClass(CLASSES.productButtonStatic);
            this.isSticky = false;
        } else if (!this.isSticky && this.stickyElementPositionTop > 0) {
            this.$el.removeClass(CLASSES.productButtonStatic);
            this.isSticky = true;
        }
    }
}
