import ProductBrand from 'client/components/product/ProductBrand';

export default class BrandTileName extends ProductBrand {
    init() {
        super.init();
        this.emitter.addListener('resize', () => this.changeBrandText());
        this.emitter.addListener('devicechange', () => this.changeBrandText());
        this.emitter.addListener('orientationchange', () => this.changeBrandText());
        this.emitter.addListener('recalculateBrand', () => this.changeBrandText());

        this.changeBrandText();
    }

    changeBrandText() {
        for (var i = 1; i < this.$brandName.length; i++) {
            this.$brandName[i].textContent = this.$brandName[0].textContent;
        }
    }
}
