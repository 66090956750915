import Component from 'client/core/Component';

import { CLASSES, COOKIE } from 'client/utils/globals';
import { setCookie, hasCookie } from 'client/utils/cookie';

const SELECTORS = {
    closeBtn: '.js-close-btn'
};

const STATE_CLASSES = {
    space: 'h-space'
};


export default class HeaderContentSlot extends Component {
    init () {
        if (!hasCookie(COOKIE.hideHeaderContent)) {
            if (this.$el.children().length) {
                this.bindEvent('click', SELECTORS.closeBtn, this.hide);
                this.$el.removeClass(CLASSES.hide);
            } else {
                this.$el.parent().removeClass(STATE_CLASSES.space);
            }
        } else if (this.$el.parent().hasClass(STATE_CLASSES.space)) {
            this.$el.parent().removeClass(STATE_CLASSES.space);
        }
    }

    hide () {
        setCookie(COOKIE.hideHeaderContent, true, { path: '/' });
        this.$el.parent().removeClass(STATE_CLASSES.space);
        this.$el.addClass(CLASSES.hide);
        this.emitter.emit('heroBannerSwitchHeight');
    }
}
