import Component from 'client/core/Component';
import { CLASSES, GLOBAL_SELECTORS } from 'client/utils/globals';
import prefs from 'sitePreferences';
import { getFavouriteStore } from 'urls';
import { getJson } from 'client/utils/ajax';
import $ from 'jquery';

const SELECTORS = {
    'FAVOURITE_STORE_BTN': '.js-favourite-store-button',
    'FAVOURITE_STORE_BTN_IN_WINDOW': '.js-favourite-store-button-in-window',
    'APP_FIND_IN_STORE_WRAPPER': '.js-app-find-in-store-wrapper'
};

const ATTRIBUTES = {
    DATA_FAVOURITE_STORE_ID: 'data-favourite-store-id',
    DATA_FAVOURITE_STORE_ADDRESS: 'data-favourite-store-address'
};

const PROPERTY = {
    DATA_FAVOURITE_STORE_ID: 'favouriteStoreID',
    DATA_FAVOURITE_STORE_ADDRESS: 'storeAddressStr'
};

// This class is not represented on interface and not included to namespaces.
// It does not need initialization. It is only for sharing common methods between different classes,
// which uses google maps for store search.
export default class StoreFinderMapMgr extends Component {
    // When calling this method - always bind to appropriate context (call, apply)
    async initFavouriteStoreButton() {
        if (!prefs.isBopisEnabled) {
            return;
        }

        let cmpInWindow = this.$el.find(SELECTORS.FAVOURITE_STORE_BTN_IN_WINDOW);

        if (cmpInWindow && cmpInWindow.length) {
            let cmpList = this.$el.find(SELECTORS.FAVOURITE_STORE_BTN);
            const customerFavouriteStoreID = await this.getFavouriteStoreAttrValue('DATA_FAVOURITE_STORE_ID');
            const initCmp = ($el) => this.emitter.emit('namespace.component.init', $el);

            cmpList.each(function() {
                const $el = $(this);

                initCmp($el);

                const clickedStoreId = $el.data('storeId');

                if (clickedStoreId === customerFavouriteStoreID) {
                    $el.addClass(CLASSES.active);
                } else {
                    $el.removeClass(CLASSES.active);
                }
            });
        } else {
            setTimeout(() => this.initFavouriteStoreButton(), 50);
        }
    }

    async getFavouriteStoreAttrValue(attrKey) {
        const appStoreWrapper = $(SELECTORS.APP_FIND_IN_STORE_WRAPPER);
        const mediaInteraction = $(GLOBAL_SELECTORS.mediaInteraction);
        let customerFavouriteStoreData = mediaInteraction.attr(ATTRIBUTES[attrKey]);

        if (appStoreWrapper && appStoreWrapper.length > 0) {
            customerFavouriteStoreData = appStoreWrapper.attr(ATTRIBUTES[attrKey]);
        }

        // @ts-ignore
        // eslint-disable-next-line quotes
        if (customerFavouriteStoreData === "") {
            await getJson({
                type: 'GET',
                url: getFavouriteStore,
                dataType: 'text',
                data: { 'property': PROPERTY[attrKey] }
            }).then(response => {
                customerFavouriteStoreData = response;
                var property = {};

                property[ATTRIBUTES[attrKey]] = response;

                this.emitter.emit('setFavouriteStoreData', property);
            });
        }

        return customerFavouriteStoreData;
    }

    initFSBOnGoogleInfoWindowReady() {
        const cmpInWindowList = $(SELECTORS.FAVOURITE_STORE_BTN_IN_WINDOW);
        const initCmp = ($el) => this.emitter.emit('namespace.component.init', $el);

        if (cmpInWindowList && cmpInWindowList.length) {
            cmpInWindowList.each(function() {
                const $el = $(this);

                initCmp($el);
            });
        }
    }

    handleFSBActiveness(storeID) {
        const allFSBs = $(SELECTORS.FAVOURITE_STORE_BTN);

        if (storeID) {
            const activeFBSs = $(`${SELECTORS.FAVOURITE_STORE_BTN}[data-store-id=${storeID}]`);

            allFSBs.removeClass('h-active');
            activeFBSs.addClass('h-active');
        } else {
            allFSBs.removeClass('h-active');
        }
    }
}
