export function extractPostalCode(result) {
    if ('address_components' in result) {
        for (let component of result.address_components) {
            if (~component.types.indexOf('postal_code')) {
                return component.long_name;
            }
        }
    }

    return null;
}

export function extractLocation(result) {
    if ('geometry' in result) {
        const geometry = result.geometry;

        if ('location' in geometry) {
            const location = geometry.location;

            return {
                lat: location.lat(),
                lng: location.lng()
            };
        }
    }

    return {
        lat: null,
        lng: null
    };
}

function _extractPostalCode (result) {
    if ('address_components' in result) {
        return result.formatted_address;
    }

    return null;
}

export function extractPostalCodeFromGeocoder(results, status, okStatus) {
    if (status === okStatus) {
        let postalCode = results[0] ? _extractPostalCode(results[0]) : null;

        if (postalCode) {
            return postalCode;
        } else {
            console.log('GoogleMapsGeocoderCmp: postalCode is not detected'); // eslint-disable-line
            return null;
        }
    } else {
        console.log(`GoogleMapsGeocoderCmp: status=${status}`); // eslint-disable-line
        return null;
    }
}

export function extractLocationFromGeocoder(position) {
    return {
        lat: position.coords.latitude,
        lng: position.coords.longitude
    };
}
