import Component from 'client/core/Component';
import $ from 'jquery';
import { getJson } from 'client/utils/ajax';
import { wishlistHeader } from 'urls';
import { isAuthenticated, isWishListEnabled } from 'sitePreferences';
import templatesManager from 'client/utils/templatesManager';

const SELECTORS = {
    wlMobileContainer: '.js-mobile-wishlist-header-ajax-container'
};

const WISHLIST_CLASSES = {
    wlMobileContainer: 'js-mobile-wishlist-header-ajax-container'
};

export default class WishlistHeader extends Component {
    init () {
        if (!isWishListEnabled) {
            return;
        }

        this.isMobile = this.$el.hasClass(WISHLIST_CLASSES.wlMobileContainer);
        this.wishlistHeaderUrl = wishlistHeader;
        this.container = this.$el;

        if (this.isMobile) {
            this.wishlistHeaderTemplate = templatesManager.templates.wishlistMobileHeaderTemplate;
            this.container = $(SELECTORS.wlMobileContainer);
        } else {
            this.wishlistHeaderTemplate = templatesManager.templates.wishlistHeaderTemplate;
        }

        getJson({
            url: this.wishlistHeaderUrl,
            type: 'GET'
        }).then((response) => {
            this.replaceHtml(
                this.wishlistHeaderTemplate({
                    isAuthenticated: isAuthenticated,
                    isWishListEnabled: isWishListEnabled,
                    wishlistItemsCount: response.wishlistItemsCount || 0,
                    wishListProducts: response.wishListProducts
                }),
                this.container
            );

            // show header buttons
            $('.b-header-utilities-container').removeClass('h-hide');
            $('.b-account-wishlist-minicart').removeClass('h-hide');
            this.emitter.emit('update.tile.wishlist.icon', response.wishlistVariationGroups);
        });

        this.initOutsideTriggerWishList();
    }

    initOutsideTriggerWishList() {
        window.triggerWishListUpdate = (data) =>
            this.emitter.emit('wishlist.added', Object.assign({ addedFrom: '' }, data));
        window.triggerWishListRemoveItem = (data) =>
            this.emitter.emit('wishlist.removed', data);
    }
}
