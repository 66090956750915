import Component from 'client/core/Component';
import $ from 'jquery';
import { scrollToMsg } from 'client/utils/common';

const SELECTORS = {
    accordionItem: '.js-accordion-item'
};

const DELAY = 50;

export default class Collapse extends Component {
    init() {

        $(SELECTORS.accordionItem).on('shown.bs.collapse', function (e) {
            setTimeout(() => {
                scrollToMsg($(e.currentTarget));
            }, DELAY);
        });
    }
}
