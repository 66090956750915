import $ from 'jquery';
import Component from 'client/core/Component';

const PAGE_WRAPPER = '.js-page-wrapper';

export default class Spinner extends Component {
    init() {
        this.pageWrapper = $(PAGE_WRAPPER);

        let events = this.config.events.split('|');

        for (let event of events) {
            this.emitter.addListener(event, () => this.startSpinner());
        }
    }

    startSpinner() {
        $.spinner.start(this.$el, this.config.section, this.config.text);
    }
}
