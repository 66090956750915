import Component from 'client/core/Component';
import { scrollTo } from 'client/utils/common';

import $ from 'jquery';

export default class BlockReviewAnchor extends Component {

    init() {
        this.reviewAnchor = this.$el.attr('href');
        this.$reviewAnchorElement = $(this.reviewAnchor);

        if (this.reviewAnchor && this.$reviewAnchorElement.length) {
            this.bindEvent('click', this.$el, this.scrollToAnchor);
        }
    }

    scrollToAnchor(e, event) {
        event.preventDefault();
        scrollTo(this.$reviewAnchorElement);
    }
}
