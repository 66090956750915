import BaseStickyHeader from 'client/components/header/StickyHeader';
import { CLASSES } from 'client/utils/globals';
import { isCurrentlySmall, isCurrentlyMedium } from 'client/utils/screendetector';

const SELECTORS = {
    storeLocatorLink: '.js-store-locator-link',
    stickyInner: '.js-sticky-inner',
    stickyElement: '.js-sticky-element',
    secondLevelMenu: '.js-second-level-menu',
    searchFormField: '.js-search-form-inner',
    stickyContainer: '.js-sticky-container',
    headerSearch: '.js-header-search',
    headerMobile: '.js-header-sticky-mobile',
    countrySelector: '.js-country-selector',
    searchStickyButton: '.js-search-switcher',
    searchButton: '.js-search-btn',
    headerBanner: '.js-header-banner'
};

export default class StickyHeader extends BaseStickyHeader {

    onScroll({ scrollTop: scrollTop } = { scrollTop: 0 }) {
        let isMobile = isCurrentlySmall() || isCurrentlyMedium();

        if (!this.disabledScroll) {
            if (this.isSticky && scrollTop <= 0) {
                this.$el.removeClass(this.config.stickyClass);
                this.isSticky = false;
                this.emitOnChild('quicksearchform', 'headerIsNotSticky');
                this.$el.parents().find(SELECTORS.headerBanner).removeClass('h-hide-banner');
            } else if (!this.isSticky && scrollTop > 0) {
                this.$el.addClass(this.config.stickyClass);
                this.isSticky = true;
                this.$el.parents().find(SELECTORS.headerBanner).addClass('h-hide-banner');
                this.emitOnChild('quicksearchform', 'headerIsSticky');
                setTimeout(() => {
                    this.headerHeight.sticky = Math.round(this.$stickyContainer.innerHeight());
                }, 200);
            } else if (this.isSticky && isMobile && scrollTop < this.headerDefaultHeight) {
                this.$el.css('min-height', this.headerMobileHeight);
            }
        }
        if (isMobile) {
            if (this.isSticky && scrollTop >= (this.$searchField.offset().top - this.$searchButtonHalfHeight)) {
                this.$searchStickyButton.removeClass(CLASSES.hide);
            } else {
                this.$searchStickyButton.addClass(CLASSES.hide);
            }
        } else {
            this.$searchStickyButton.removeClass(CLASSES.hide);
        }
    }
}
