import MainNamespace from 'buffalo/globalComponents/MainNamespace';

import storeLocator from 'client/components/storeLocator/StoreLocator';
import storeLocatorTabs from 'client/components/storeLocator/StoreLocatorTabs';


const mainNamespace = new MainNamespace();

mainNamespace.addComponents({
    storeLocator,
    storeLocatorTabs
});

mainNamespace.init();
