import Component from 'client/core/Component';

/**
 * Usage:
 * 1. Bind events with data-events="example.event.1:example.event.2:example.event.3"
 * 2. Map css classes with events data-mappings="example.event.1=some-class-1,some-class-2;example.event.2=some-class-3,some-class-4;"
 * 3. Emit mapped events at other components with 'true' or 'false' state as emitter parameter
 */
export default class ClassToggle extends Component {
    init () {
        this.events = this.config.events ? this.config.events.split(':') : [];
        this.mappings = this.parseMapping(this.config.mappings);

        for (let event of this.events) {
            let mapping = this.mappings[event];

            if (mapping) {
                this.emitter.addListener(event, (data) => {
                    this.toggleClasses(mapping, data);
                });
            }
        }
    }

    parseMapping(mappings) {
        let tmp = mappings ? mappings.split(';') : [];
        let mapping = {};

        for (let item of tmp) {
            if (item && item.length > 0) {
                let [event, classes] = item.split('=');

                if (mapping[event]) {
                    mapping[event] = mapping[event].concat(classes.split(','));
                } else {
                    mapping[event] = classes.split(',');
                }
            }
        }

        return mapping;
    }

    toggleClasses(classes, isActive = true) {
        for (let item of classes) {
            this.$el.toggleClass(item, isActive);
        }
    }
}
