import Component from 'client/core/Component';
import { CLASSES } from 'client/utils/globals';
import $ from 'jquery';

const SELECTORS = {
    'TOOLTIP': '.js-tooltip',
    'ICON': '.js-tooltip-icon',
    'CONTENT': '.js-tooltip-content',
    'CLOSE': '.js-tooltip-close-btn'
};

const STATE_CLASSES = {
    'CONTENT': 'b-tooltip-content--hide'
};

export default class Tooltip extends Component {
    init () {
        this.shown = false;

        this.$content = this.$el.find(SELECTORS.CONTENT);

        this.bindEvent('click', SELECTORS.ICON, (el, ev) => {
            // Prevent globalClick event
            ev.stopPropagation();

            this.shown = !this.shown;
            this.onClick();
        });

        this.bindEvent('click', SELECTORS.CLOSE, (el, ev) => {
            // Prevent globalClick event
            ev.stopPropagation();

            this.shown = false;
            this.onClick();
        });

        this.emitter.addListener('globalClick', this.globalClick.bind(this));
    }

    globalClick(target) {
        if (!$(target).parents(SELECTORS.TOOLTIP).length) {
            this.shown = false;
            this.onClick();
        }
    }

    onClick() {
        this.$content.toggleClass(STATE_CLASSES.CONTENT, !this.shown);
        this.$el.toggleClass(CLASSES.expanded, this.shown);
    }
}
