import ProductComponent from './ProductComponent';

import { CLASSES as GLOBAL_CLASSES } from 'client/utils/globals';

const SELECTORS = {
    label: '.js-availability-label',
    message: '.js-availability-label-message',
    releaseDate: '.js-release-date'
};

const CLASSES = {
    label: 'js-availability-label',
    availability: 'b-availability-label',
    labelModifier: 'b-availability-label--unavailable',
    unavailable: 'js-availability--unavailable'
};

export default class Availability extends ProductComponent {
    onVariationUpdate(product) {
        super.onVariationUpdate(product);
        this.emitter.emit('bopis.product.actions', product);
        this.updateReleaseDate();
    }

    onCartAddError(data) {
        const $label = this.$el.find(SELECTORS.label);
        const $message = this.$el.find(SELECTORS.message);

        if ($label.hasClass(CLASSES.unavailable)) {
            $label.removeClass(GLOBAL_CLASSES.hide);
            data.message && $message.html(data.message);
        } else {
            $label.removeClass().addClass(() => {
                return Object.values(CLASSES).join(' ');
            });

            $message.html(data.message || $label.data('label-unavailable'));
        }
    }

    onCartUpdated() {
        const $label = this.$el.find(SELECTORS.label);
        const $message = this.$el.find(SELECTORS.message);

        $label.removeClass().addClass(CLASSES.label);
        $message.empty();
    }

    updateReleaseDate() {
        const $label = this.$el.find(SELECTORS.label),
            releaseDateUTC = $label.data('release-date'),
            isCountdown = $label.data('countdown'),
            $releaseDate = this.$el.find(SELECTORS.releaseDate);

        if (releaseDateUTC && $releaseDate.length) {
            $releaseDate.html(this.formatDate(new Date(releaseDateUTC), isCountdown));
        }
    }

    init() {
        super.init();

        this.updateReleaseDate();

        this.emitter.addListener('cart.add.error', this.onCartAddError.bind(this));
        this.emitter.addListener('cart.updated', this.onCartUpdated.bind(this));
    }
}
