'use strict';

import $ from 'jquery';
import EventEmitter from './EventEmitter';
import { uuidv4 } from './Base';
import prefs from 'sitePreferences';
import { extractParamFromURL } from 'client/utils/url';

export default class Component extends EventEmitter {
    /**
     * @param {JQuery} el
     * @param {Object} config
     * @param {EventEmitter} globalEventEmitter
     */
    constructor (el, config = {}, globalEventEmitter) {
        super();

        this._globalEvents = [];
        this._globalEventEmitter = globalEventEmitter;
        this._nestedComponents = [];

        this.$el = $(el);
        this.config = config;
        this.id = config.id || this.$el.attr('id') || uuidv4();

        this.emitter = {
            addListener: (event, listener) => {
                this._globalEvents.push({ e: event, l: listener });
                this._globalEventEmitter.addListener(event, listener);
            },
            removeListener: (event, listener) => {
                let idx = null;

                for (let [index, obj] of this._globalEvents.entries()) {
                    if (obj.e === event && obj.l === listener) {
                        idx = index;
                        break;
                    }
                }

                if (idx !== null) {
                    this._globalEvents.splice(idx, 1);
                }

                this._globalEventEmitter.removeListener(event, listener);
            },
            emit: (...args) => this._globalEventEmitter.emit(...args)
        };

        this.gtmEnabled = (prefs.gtmEnabled && (typeof window.dataLayer !== 'undefined'));
        this.emarsysTrackingJsEnabled = (window.EmarsysPreferences && window.EmarsysPreferences.trackingJsEnabled);
        this.gtmPageType = (this.gtmEnabled && window.dataLayer.length) ? window.dataLayer[0].pageType : '';
    }

    /**
     * @callback ComponentResultCallback
     * @param {Component} cmp
     */

    /**
     * @param {string} id
     * @param {ComponentResultCallback} [callback]
     * @returns {Component | null}
     */
    getNestedComponentById (id, callback) {
        for (let cmp of this._nestedComponents) {
            if (cmp) {
                if (cmp.id === id) {
                    if (callback && $.isFunction(callback)) {
                        return callback.call(this, cmp);
                    } else {
                        return cmp;
                    }
                }

                let result = cmp.getNestedComponentById(id);

                if (result) {
                    if (callback && $.isFunction(callback)) {
                        return callback.call(this, result);
                    } else {
                        return result;
                    }
                }
            }
        }

        return null;
    }

    /**
     * @param {string} methodName
     * @returns {boolean}
     */
    hasMethod (methodName) {
        return methodName in this && typeof this[methodName] === 'function';
    }

    /**
     * @param {string} methodName
     * @param {*} [args]
     *
     * @returns {any}
     */
    callMethod (methodName, ...args) { // eslint-disable-line
        if (this.hasMethod(methodName)) {
            return this[methodName].apply(this, args);
        }
    }

    /**
     *
     * @param {string} nestedComponentID
     * @param {string} methodName
     * @param {*} [args]
     */
    callMethodOFNestedComponent (nestedComponentID, methodName, ...args) {
        const nestedCmp = this.getNestedComponentById(nestedComponentID);

        return nestedCmp && nestedCmp.hasMethod(methodName) && nestedCmp[methodName].apply(nestedCmp, args);
    }

    /**
     * @param {ComponentResultCallback} callback
     */
    loopAllNestedComponents (callback) {
        if ($.isFunction(callback)) {
            this._nestedComponents.forEach(cmp => {
                cmp.loopAllNestedComponents(callback);
                callback.call(this, cmp);
            });
        }
    }

    /**
     * @param {Function} fn
     * @param {Boolean} [inDepth=false]
     */
    iterateNestedComponents (fn, inDepth = false) {
        if ($.isFunction(fn)) {
            for (let cmp of this._nestedComponents) {
                if (inDepth) {
                    cmp.iterateNestedComponents(arguments);
                }

                fn.call(this, cmp);
            }
        }
    }

    emitOnChild (id, eventName, ...args) {
        let cmp = this.getNestedComponentById(id);

        if (!cmp) {
            return false;
        }

        cmp.emit(eventName, ...args);

        return true;
    }

    addListenerOnChild (id, event, listener) {
        let cmp = this.getNestedComponentById(id);

        if (!cmp) {
            return false;
        }

        cmp.addListener(event, listener);

        return true;
    }

    addListenerOnChildren (event, listener) {
        this.iterateNestedComponents(cmp => {
            cmp.addListener(event, listener);
        });
    }

    /**
     * Bind Events
     * @param {string} eventName
     * @param {string | Function} selectorOrHandler
     * @param {function} [handler]
     */
    bindEvent (eventName, selectorOrHandler, handler, configParams) {
        const _this = this;

        if (configParams && configParams.unbindWithoutHandler) {
            if ($.isFunction(selectorOrHandler)) {
                this.$el.off(eventName);
            } else {
                this.$el.off(eventName, selectorOrHandler);
            }
        } else if ($.isFunction(selectorOrHandler)) {
            this.$el.off(eventName, selectorOrHandler);
        } else {
            this.$el.off(eventName, selectorOrHandler, handler);
        }

        if ($.isFunction(selectorOrHandler)) {
            this.$el.on(eventName, function () {
                return selectorOrHandler.apply(_this, [this].concat(Array.prototype.slice.call(arguments)));
            });
        } else {
            this.$el.on(eventName, selectorOrHandler, function () {
                return handler.apply(_this, [this].concat(Array.prototype.slice.call(arguments)));
            });
        }
    }

    /**
     * @description Remove children DOM elements and assigned event listeners and replace it with new html
     * @param {String} action
     * @param {String} html
     * @param {JQuery<HTMLElement>} [$el] - scope..
     * @param {Object} [opts] options
     * @param {Function} [callback]
     */
    destroyForReplaceHtml(action, html, $el, opts, callback) {
        this.emitter.emit(action, $el, {
            onAfterDestroy: () => {
                $el.empty();
                $el.html(html);

                if (opts.onAfterInit) {
                    const superOnAfterInit = opts.onAfterInit;

                    opts.onAfterInit = () => {
                        superOnAfterInit();
                        callback && callback();
                        this.emitter.emit('afterReplaceHtml', $el);
                    };
                } else {
                    opts.onAfterInit = () => {
                        callback && callback();
                        this.emitter.emit('afterReplaceHtml', $el);
                    };
                }

                this.emitter.emit('namespace.component.reinitall', $el, opts);
            }
        });
    }

    /**
     * @description Remove children DOM elements and assigned event listeners and replace it with new html
     * @param {String} html
     * @param {JQuery<HTMLElement>} [$el] - scope..
     * @param {Object} [opts] options
     * @param {Function} [callback]
     */
    replaceHtml(html, $el = null, opts = {}, callback = null) {
        if (!$el) {
            $el = this.$el;
        }

        if (Array.isArray($el) && $el.length > 0) {
            $el.forEach(currentElement => {
                this.destroyForReplaceHtml('namespace.component.destroyall', html, currentElement, opts, callback);
            });
        } else {
            this.destroyForReplaceHtml('namespace.component.destroyall', html, $el, opts, callback);
        }
    }


    /**
     * @description Append DOM elements to component and reinit all children
     * @param {String} html
     * @param {JQuery<HTMLElement>} $el
     * @param {Boolean} [reInitWholeContainer]
     * @param {Function} [callback]
     */
    appendHtml(html, $el, reInitWholeContainer = true, callback = null) {
        if (!$el) {
            $el = this.$el;
        }

        if (reInitWholeContainer) {
            $el.append(html);
            this.emitter.emit('namespace.component.reinitall', $el, {
                onAfterInit: () => {
                    callback && callback();
                    this.emitter.emit('afterAppendHtml', $el);
                }
            });
        } else {
            let $html = $(html);

            this.emitter.emit('namespace.component.initall', $html, {
                onAfterInit: () => {
                    $el.append($html);

                    callback && callback();
                    this.emitter.emit('afterAppendHtml', $el);
                }
            });
        }
    }

    destroy() {
        this.$el.off();
        this.$el.find('*').off();

        let globalEvents = [...this._globalEvents];

        // unsubscribe from all events
        for (let { e: e, l: l } of globalEvents) {
            this.emitter.removeListener(e, l);
        }
        this._globalEvents = [];
        this._events = {};

        // destroy all child components
        for (let item of this._nestedComponents) {
            item.destroy();
        }

        this._nestedComponents = [];

        this.$el.removeData('cmp-instance');
    }

    /**
     * Date to formatted string
     *
     * @param {Date} d
     * @param {Boolean} dateTime
     * @returns {string}
     */
    formatDate(d, dateTime = true) {
        let dd = d.getDate().toString().padStart(2, '0'),
            mm = (d.getMonth() + 1).toString().padStart(2, '0'),
            yyyy = d.getFullYear().toString(),
            hh = d.getHours().toString().padStart(2, '0'),
            ii = d.getMinutes().toString().padStart(2, '0');

        return dateTime ? `${dd}.${mm}.${yyyy}, ${hh}:${ii}` : `${dd}.${mm}.${yyyy}`;
    }

    /**
     * Returns if parameter c_app exists in current yrl with true
     * @returns {Boolean}
     */
    isApp() {
        const queryString = location.search;
        const cApp = extractParamFromURL(queryString, 'c_app');

        return cApp === 'true';
    }
}
