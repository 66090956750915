import Component from './Component';
import Input from './Input';
import { scrollToMsg, isInViewport } from 'client/utils/common';
import mixins from './mixins';
import $ from 'jquery';
import prefs from 'sitePreferences';

const customValidator = require('client/utils/customValidator');
const customPostalCodeValidator = require('client/utils/customPostalCodeValidator');

const SELECTORS = {
    firstFieldError: '.f-field--error:first'
};

const COUNTRY_CODES = {
    HR: 'HR'
};

export default class Form extends Component {
    constructor (...args) {
        super(...args);

        if (this.$el.is('form')) {
            this.bindEvent('click', 'button, input[type="button"], input[type="submit"]', this.onSubmit);
        }
    }

    // The default implementation
    // In general, the logic of this method is supposed to be overwritten in a child class
    async onSubmit() {
        const isValid = await this.validate();

        if (!isValid) {
            const $firstFieldError = this.$el.find(SELECTORS.firstFieldError);

            if ($firstFieldError.length && !isInViewport($firstFieldError)) {
                scrollToMsg($firstFieldError);
            }
        }

        return isValid;
    }

    /**
     * @param {HTMLElement} action
     */
    collectDataForAction (action) {
        let data = [];

        this.loopAllNestedComponents(cmp => {
            if (cmp instanceof Input) {
                data.push(cmp.serialize());
            }
        });

        action && data.push({ name: action.name, value: 'true' });

        return data;
    }

    /**
     * @returns {Promise}
     */
    async validate () {
        let result = await Promise.all(this._nestedComponents.map(cmp => {
            if (typeof cmp.validate === 'function') {
                let validateResult = cmp.validate();

                return validateResult instanceof Promise ? validateResult : Promise.resolve(validateResult);
            }

            return Promise.resolve(true);
        }));

        return result.every(Boolean);
    }

    /**
     * @returns {boolean}
     */
    isFulfilled() {
        return this
            ._nestedComponents
            .map(cmp => {
                var fulfilled = true;

                if (!cmp.disabled && cmp.config.required) {
                    fulfilled = cmp.getValue() && cmp.isValid;
                }

                return fulfilled;
            })
            .every(Boolean);
    }

    onValidateEmail(cmp) {
        return customValidator.validateEmail(cmp.getValue());
    }

    onValidatePostalCode(cmp) {
        const cmpPrefix = cmp.id.split('-')[0];
        const countryCode = this.getNestedComponentById(cmpPrefix + '-countryCode').getValue();

        if (countryCode && countryCode === COUNTRY_CODES.HR) {
            return customPostalCodeValidator.validatePostalCode(cmp.getValue(), countryCode);
        } else {
            return mixins.address.onValidatePostalCode.call(this, cmp);
        }
    }


    onCountryChanged(el) {
        const cmpPrefix = $(el).data('id').split('-')[0];
        const postalCodeCmp = this.getNestedComponentById(`${cmpPrefix}-postalCode`);
        var countryCode = $(el).find('select') ? $(el).find('select').val() : 'default';

        if (!prefs.postalCode[countryCode]) {
            countryCode = 'default';
        }

        if (postalCodeCmp) {
            postalCodeCmp.config.maxlengthtext = prefs.postalCode[countryCode].maxlengthtext;
            postalCodeCmp.config.minlengthtext = prefs.postalCode[countryCode].minlengthtext;
        }

        if (postalCodeCmp &&
            typeof postalCodeCmp.validate === 'function' &&
            postalCodeCmp.getValue()
        ) {
            postalCodeCmp.validate();
        }
    }
}
