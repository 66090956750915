import Component from 'client/core/Component';
import { CLASSES } from 'client/utils/globals';
import { redirectTo } from 'client/utils/common';
import $ from 'jquery';
import ajax from 'client/utils/ajax';

const SELECTORS = {
    GIFT_CARD_BLOCK: '.js-card-block',
    REMOVE_BTN: '.js-remove-gift-card',
    REMOVE_CONFIRM: '.js-line-item-remove-confirmation',
    REMOVE_CONFIRM_BTN: '.js-remove-confirmation-action'
};

export default class GiftCardItem extends Component {
    init() {
        this.removeConfirmationBlock = this.$el.find(SELECTORS.REMOVE_CONFIRM);
        this.giftCardBlock = this.$el.find(SELECTORS.GIFT_CARD_BLOCK);

        this.bindEvent('click', SELECTORS.REMOVE_BTN, this.showRemoveConfirmation.bind(this));
        this.bindEvent('click', SELECTORS.REMOVE_CONFIRM_BTN, this.onGiftCardRemoveConfirm.bind(this));
    }

    onGiftCardRemoveConfirm(el, ev) {
        ev.preventDefault();
        let $el = $(el);

        if ($el.data('remove') === true) {
            let promise = ajax.getJson({
                url: $el.attr('href')
            });

            promise.then((response) => {
                if (!response.error) {
                    this.$el.remove();
                    this.emitter.emit('giftCard.removed', response);
                    this.emitter.emit('payment.coveredByGC', false);
                    this.emitter.emit('giftCards.updated', response);
                    response.order && this.emitter.emit('action.summary.update', response);
                } else if (response.redirectUrl) {
                    redirectTo(response.redirectUrl);
                }
            });
        } else {
            this.hideRemoveConfirmation();
        }
    }

    showRemoveConfirmation(el, ev) {
        ev.preventDefault();
        this.removeConfirmationBlock.removeClass(CLASSES.hide);
        this.giftCardBlock.addClass(CLASSES.hide);
    }

    hideRemoveConfirmation() {
        this.removeConfirmationBlock.addClass(CLASSES.hide);
        this.giftCardBlock.removeClass(CLASSES.hide);
    }
}
