import Modal from 'client/components/Modal';
import { isRNWebView } from 'client/utils/react';

export default class Alert extends Modal {
    init() {
        if (isRNWebView()) {
            return;
        }

        this.emitter.addListener('alert', (data) => this.showModal(data));

        super.init();
    }

    showModal(data) {
        this.config = Object.assign({}, data, this.config);

        super.showModal();
    }
}
