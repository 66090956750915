import GlobalCustomSelect from 'client/components/forms/commonElements/GlobalCustomSelect';
import { CLASSES } from 'client/utils/globals';
import $ from 'jquery';

const STATE_CLASSES = {
    selectPlaceholder: 'f-custom-select-title--placeholder'
};

export default class ProductCardSizeSelect extends GlobalCustomSelect {
    renderSelect() {
        const options = Array.from(this.nativeSelect.find('option'));

        this.customSelectList.empty();
        options.map(this.addItem.bind(this));

        const value = this.nativeSelect.val(),
            title = this.customSelect.find(`li[data-value="${value}"]`).html();

        if (!value) {
            this.customSelectTitle.addClass(STATE_CLASSES.selectPlaceholder);
        }

        this.placeholderText = title;
        this.customSelectTitle.html(title);
        this.nativeSelect.addClass(CLASSES.transparent);
    }

    getCardComponent() {
        const $cardComponent = this.$el.closest('[data-cmp]');

        return $cardComponent.length ? $cardComponent.data('cmp-instance') : null;
    }

    changeTitle() {
        const variant = this.nativeSelect.find('option:selected'),
            badge = variant.data('badge'),
            code = variant.data('code'),
            value = this.nativeSelect.val(),
            title = this.customSelect.find(`li[data-value="${value}"]`).html(),
            cardComponent = this.getCardComponent();

        if (cardComponent && cardComponent.updateBadge) {
            this.cardComponent.updateBadge(badge, code);
        }

        this.isSelected = (title !== this.placeholderText);
        this.$el.toggleClass(CLASSES.customsSelectSetValue, this.isSelected);
        this.customSelectTitle.removeClass(STATE_CLASSES.selectPlaceholder);
        this.customSelectTitle.html(title);
        this.listHide();
    }

    addItem(el) {
        const $el = $(el),
            name = $el.text().split('::'),
            value = $el.val(),
            available = $el.data('available'),
            dataTag = $el.data('tag').split('::'),
            dataClass = $el.data('class').split('::'),
            $item = $(`<li class="f-custom-select-item js-custom-select-item" data-value="${value}"></li>`);

        if (!value || !available) {
            $item.addClass(CLASSES.hide);
        }

        for (let i = 0, l = name.length; i < l; i++) {
            if (dataTag[i]) {
                let $tag = $(`<${dataTag[i]}>`);

                if (dataClass[i]) {
                    $tag.addClass(dataClass[i]);
                }

                $tag.append(name[i]);

                $item.append($tag);
            } else {
                $item.append(name[i]);
            }
        }

        this.customSelectList.append($item);
    }
}
