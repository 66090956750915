import Component from 'client/core/Component';
import { isTablet, refresh } from 'client/utils/mobileDetect';
import { isSmall, isMedium } from 'client/utils/screendetector';
import { CLASSES } from 'client/utils/globals';
import prefs from 'sitePreferences';
import $ from 'jquery';

const
    SELECTORS = {
        headerHumburger: '[data-cmp="headerHamburger"]',
        menuContainer: '.js-header-menu-container',
        menuClose: '.js-menu-close',
        overlay: '.js-mobile-menu-overlay',
        listShow: '.js-list-show',
        listHide: '.js-list-hide',
        menuWrapper: '.js-header-menu-wrapper',
        listWrapper: '.js-list-wrapper',
        menuTitle: '.js-menu-title',
        listItem: '.js-list-item',
        sectionTitle: '.js-menu-section-title',
        menuSection: '.js-menu-section',
        menuListLink: '.js-menu-list-link',
        headerWrap: '.js-header-search',
        menuLevelTitle: '.js-menu-level-title',
        mobileMenuContainer: '.js-header-menu-container.mobile-menu-show'
    },
    STATE_CLASSES = {
        sectionVisibility: 'h-visibility',
        hideMobile: 'h-hide-sm h-hide-md',
        overlayShow: 'b-mobile-menu-overlay--show',
        menuShow: 'mobile-menu-show',
        touchableDevice: 'h-disable-hover',
        mobileMenuOpened: 'h-mobile-menu-opened',
        headerMenuTitleAlign: 'b-header-menu-title--align'
    };


export default class HeaderHamburger extends Component {

    init () {
        this.listHide = this.$el.find(SELECTORS.listHide);
        this.menuContainer = this.$el.find(SELECTORS.menuContainer);
        this.overlay = this.$el.find(SELECTORS.overlay);
        this.menuWrapper = this.$el.find(SELECTORS.menuWrapper);
        this.menuTitle = this.$el.find(SELECTORS.menuTitle);
        this.sectionTitle = this.$el.find(SELECTORS.sectionTitle);
        this.menuSection = this.$el.find(SELECTORS.menuSection);
        this.$headerWrap = $(SELECTORS.headerWrap);
        this.$menuLevelTitle = this.$el.find(SELECTORS.menuLevelTitle);

        this.isOpen = false;
        this.currentLevel = 0;
        this.currentLink = '';
        this.currentCat = this.menuTitle.data('menu-title');
        this.prevCat = '';

        this.bindEvent('click', SELECTORS.listShow, this.pullLeft);
        this.bindEvent('click', SELECTORS.listHide, this.pullRight);
        this.bindEvent('click', SELECTORS.menuClose, this.mobileMenuToggle);
        this.bindEvent('click', SELECTORS.overlay, this.mobileMenuToggle);
        this.bindEvent('click', SELECTORS.menuTitle, this.relocate);

        if (this.gtmEnabled) {
            this.bindEvent('click', SELECTORS.menuListLink, this.trackMenuItemClick);
        }

        this.emitter.addListener('mobileMenuShow', this.mobileMenuToggle.bind(this));
        this.emitter.addListener('orientationChange', () => this.toggleScroll(true));
        this.emitter.addListener(
            'refinement.updated',
            ($response) => this.updateHeaderHamburger($response)
        );
    }

    trackMenuItemClick(el) {
        const data = {
            eventCategory: 'navigation',
            eventAction: $(el).data('parent-id'),
            eventLabel: el.id,
            ga4eventname: 'navigation'
        };

        this.emitter.emit('gtmEvent', data);
    }

    showMobileMenuItem($elem) {
        if (!$elem) {
            return;
        }

        const $listItem = $elem.parent().closest(SELECTORS.listItem);

        if ($listItem && $listItem.length > 0) {
            $listItem.removeClass(STATE_CLASSES.hideMobile);
            this.showMobileMenuItem($listItem);
        }
    }

    hideMobileMenuItem() {
        const prevListWrapper = $(`${SELECTORS.listWrapper}[data-cat='${this.currentCat}']`);

        prevListWrapper.removeClass(STATE_CLASSES.hideMobile);
        prevListWrapper.find(SELECTORS.listItem).removeClass(STATE_CLASSES.hideMobile);
    }

    pullLeft (el, event) {
        if (!isSmall() && !isMedium()) {
            return;
        }

        if (event) {
            event.preventDefault();
        }

        const $el = $(el),
            catName = $el.data('cat'),
            nextListWrapper = $el.closest(SELECTORS.listItem, this.$el).find(SELECTORS.listWrapper).first(),
            listWrapper = $el.closest(SELECTORS.listWrapper, this.$el),
            href = $el.attr('href'),
            prevCat = listWrapper.data('cat');

        this.currentCat = catName;
        this.prevCat = prevCat;
        this.currentLink = href;

        this.currentLevel++;

        $(SELECTORS.mobileMenuContainer).find(SELECTORS.listItem).addClass(STATE_CLASSES.hideMobile);

        this.showMobileMenuItem($el);
        $el.closest(SELECTORS.listItem).find(SELECTORS.listItem).removeClass(STATE_CLASSES.hideMobile);
        nextListWrapper.removeClass(STATE_CLASSES.hideMobile);

        if (this.currentLevel) {
            this.backBtnShow();
            this.sectionTitle.hide();
            this.menuSection.addClass(STATE_CLASSES.sectionVisibility);
        } else {
            this.sectionTitle.show();
            this.menuSection.removeClass(STATE_CLASSES.sectionVisibility);
        }

        this.menuWrapper.css('transform', `translateX(-${100 * this.currentLevel}%)`);
        this.scrollToElement();
        this.menuTitle.text(this.currentCat);
        this.$menuLevelTitle.addClass(STATE_CLASSES.headerMenuTitleAlign);
    }

    scrollToElement() {
        this.$menuLevelTitle.get(0).scrollIntoView();
    }

    pullRight () {
        let delay = this.menuWrapper.css('transition-duration');
        const $targetList = this.$el.find(`${SELECTORS.listWrapper}[data-cat='${this.currentCat}']`);

        delay = (delay.substring(0, delay.length - 1)) * 1000;
        this.currentLevel--;

        if (this.currentLevel) {
            this.sectionTitle.hide();
            this.menuSection.addClass(STATE_CLASSES.sectionVisibility);
        } else {
            this.backBtnHide();
            this.sectionTitle.show();
            this.menuSection.removeClass(STATE_CLASSES.sectionVisibility);
        }

        this.menuWrapper.css('transform', `translateX(-${100 * this.currentLevel}%)`);

        setTimeout(function () {
            $targetList.addClass(STATE_CLASSES.hideMobile);
        }, delay);

        this.currentCat = this.prevCat;
        this.hideMobileMenuItem();

        const $targetLink = this.$el.find(`${SELECTORS.listShow}[data-cat='${this.currentCat}']`),
            $prevList = $targetLink.closest(SELECTORS.listWrapper, this.$el);

        this.prevCat = $prevList.data('cat');
        this.currentLink = $targetLink.attr('href');

        this.menuTitle.text(this.currentCat);
        if (this.currentCat === this.menuTitle.data('menu-title')) {
            this.$menuLevelTitle.removeClass(STATE_CLASSES.headerMenuTitleAlign);
        }
    }

    backBtnShow () {
        this.listHide.removeClass(CLASSES.hide);
    }

    backBtnHide () {
        this.listHide.addClass(CLASSES.hide);
    }

    mobileMenuToggle () {
        this.menuContainer.toggleClass(STATE_CLASSES.menuShow);
        this.overlay.toggleClass(STATE_CLASSES.overlayShow);

        this.isOpen = !this.isOpen;

        if (this.isOpen) {
            this.emitter.emit('disabledScroll');
            this.$headerWrap.addClass(STATE_CLASSES.mobileMenuOpened);
            this.$el.find(SELECTORS.menuClose).focus();
        } else {
            this.$headerWrap.removeClass(STATE_CLASSES.mobileMenuOpened);
            this.emitter.emit('enabledScroll');
        }
    }

    relocate() {
        if (this.currentLink) {
            location.href = this.currentLink;
        }
    }

    toggleScroll(isChange) {
        if (isChange) {
            refresh();
        }

        const windowWidth = $(window).innerWidth();
        const tablet = isTablet();

        if (this.isOpen && tablet && windowWidth > prefs.breakpointMedium) {
            this.emitter.emit('enabledScroll');
        } else if (this.isOpen) {
            this.emitter.emit('disabledScroll');
        }
    }

    updateHeaderHamburger($response) {
        const newHeaderHamburger = $response.find(SELECTORS.headerHumburger).html();

        if (newHeaderHamburger) {
            this.replaceHtml(newHeaderHamburger, null, {}, () => {
                this.emitter.emit('namespace.component.destroy', this.$el);
                this.emitter.emit('namespace.component.reinit', this.$el);
            });
        }
    }
}
