import MainNamespace from 'buffalo/globalComponents/MainNamespace';

import brandPageMgr from 'client/components/brands/BrandsMgr';
import brandTiles from 'client/components/brands/BrandTiles';
import brandImage from 'client/components/BrandImage';
import brandTileName from 'client/components/brands/BrandTileName';

const mainNamespace = new MainNamespace();

mainNamespace.addComponents({
    brandPageMgr,
    brandTiles,
    brandImage,
    brandTileName
});

mainNamespace.init();
