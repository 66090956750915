// Global CSS classes
export const CLASSES = {
    // Form elements
    formError: 'f-form--error',
    formFieldError: 'f-field--error',
    formFieldWarning: 'f-field--warning',
    formFieldSuccess: 'f-field--success',
    formFieldRequired: 'f-state-required',

    // Helper elements
    hide: 'h-hide',
    hideFirstVisitPopup: 'h-modal-first-visit-hide',
    collapsed: 'h-collapsed',
    expanded: 'h-expanded',
    headerSticky: 'h-header-sticky',
    transparent: 'h-transparent',
    customSelectOpened: 'h-custom-select-opened',
    dropdownOpened: 'h-dropdown-opened',
    placedBelowOpened: 'h-placed-below-opened',
    loading: 'h-loading',
    loaded: 'h-loaded',
    disableScroll: 'h-disable-scroll',
    placeholderUp: 'h-placeholder-up',
    placeholderHidden: 'h-placeholder-hidden',
    productButtonStatic: 'h-product-button-static',
    productAdded: 'h-product-added',
    selected: 'h-selected',
    disabled: 'h-disabled',
    disabledLegacy: 'disabled',
    readyToSubmit: 'h-ready-to-submit',
    stickyElementStatic: 'h-sticky-element-static',
    stickyElementTop: 'h-sticky-element-top',
    stickyElementBottom: 'h-sticky-element-bottom',
    stickyPanelShow: 'h-sticky-panel-show',
    modalOpened: 'h-modal-opened',
    opened: 'h-opened',
    invisible: 'h-hidden',
    customsSelectSetValue: 'h-custom-select-value',
    addressUnderEdit: 'h-address-under-edit',
    oneCta: 'h-one-cta',
    active: 'h-active',
    clicked: 'h-clicked'
};

export const COOKIE = {
    hideHeaderContent: 'hideHeaderContent',
    acceptCookie: 'acceptCookie',
    localizationSettings: 'localizationSettings',
    hideLocalizationDialog: 'hideLocalizationDialog',
    hideFirstVisitPopup: 'hideFVP',
    customerCountry: 'customerCountry',
    subscribeEmarsys: 'newsletter_optin',
    favouriteStore: 'favourite_store'
};

export const GLOBAL_SELECTORS = {
    mediaInteraction: '[data-cmp="mediaInteraction"]'
};

export const GTM_EVENT_CATEGORIES = {
    bopis: 'bopis'
};

export const GLOBAL_CONSTANT_VALUES = {
    defaultStoreRadiusValue: 15
};

export const ADDRESS_TYPES = {
    billing: 'billing',
    shipping: 'shipping'
};

export const SHIPPING_METHODS = {
    'HOME_DELIVERY': 'home-delivery',
    'SHIP_TO_STORE': 'ship-to-store',
    'PICK_UP_STATION': 'pick-up-station'
};
