import InputText from 'client/components/forms/commonElements/InputText';

const SELECTORS = {
    'RANGE': '.js-packstation-range'
};

export default class PackstationNumber extends InputText {
    init () {
        super.init();

        this.$rangeElement = this.$el.closest(SELECTORS.RANGE);
    }

    validate() {
        if (this.disabled) {
            return true;
        }

        let result = super.validate();

        if (result) {
            let validRanges = this.$rangeElement.data('packstation-valid-ranges');
            let msg = this.$rangeElement.data('packstation-invalid-msg');

            validRanges = validRanges.map(item => {
                let [min, max] = item.split('-');

                return { min: parseInt(min, 10), max: parseInt(max, 10) };
            });

            const value = parseInt((this.getValue() + '').replace(/\r?\n/g, '\r\n'), 10);

            if (isNaN(value)) {
                this.setError(msg);

                return false;
            }

            let isValid = false;

            for (let range of validRanges) {
                if (value >= range.min && value <= range.max) {
                    isValid = true;
                    break;
                }
            }

            if (!isValid) {
                this.setError(msg);
            }

            return isValid;
        }

        return result;
    }
}
