import MobileMenu from 'client/components/MobileMenu';

import $ from 'jquery';

const SELECTORS = {
    'CONTENT': '.js-list-item',
    'ITEM': '.js-refinement-value',
    'SELECTED': '.js-selected-items',
    'REFINEMENT': '.js-list-content',
    'MIN_PRICE': '.js-filter-min-price',
    'MAX_PRICE': '.js-filter-max-price',
    'WRAPPER': '.js-mobile-menu-wrapper',
    'SHOW_ALL_RESULTS': '.js-show-all-result-button'
};

export default class RefinementMenu extends MobileMenu {
    init (reInit = false) {
        this.config.type = 'refinements';

        if (!reInit) {
            this.emitter.addListener('refinement.applyFilter', (data) => this.onFilterApply(data));
            this.emitter.addListener('refinement.close', () => {
                this.pullRight();
                this.close();
            });
        }

        super.init(reInit);
        this.bindEvent('click', SELECTORS.SHOW_ALL_RESULTS, () => this.close());
        this.setSelectedRefinements();
    }

    onFilterApply(data) {
        if (data.type === 'category') {
            let $categoryItem = this.$el.find(`${SELECTORS.CONTENT}[data-list-id="category"]`);

            $categoryItem.find(SELECTORS.SELECTED).html(data.name);

            this.pullAllRight();
            this.close();
        } else {
            this.pullRight();
            //this.close();
            this.setSelectedRefinements();
        }
    }

    setSelectedRefinements() {
        let $refinements = this.$el.find(SELECTORS.CONTENT);

        $refinements.each((index, el) => {
            let $el = $(el),
                $refinement = $el.find(SELECTORS.REFINEMENT),
                value = '';

            if ($refinement.data('attr') === 'price') {
                let minPriceVal = $refinement.find(SELECTORS.MIN_PRICE).val(),
                    maxPriceVal = $refinement.find(SELECTORS.MAX_PRICE).val();

                if (minPriceVal || maxPriceVal) {
                    if (minPriceVal) {
                        value = `${minPriceVal} - `;
                    } else {
                        value = '0 - ';
                    }

                    if (maxPriceVal) {
                        value += maxPriceVal;
                    } else {
                        value += '99999';
                    }
                }
            } else {
                let $items = $el.find(SELECTORS.ITEM),
                    selected = [];

                $items.each((i, e) => {
                    let $e = $(e);

                    if ($e.data('selected') === true) {
                        selected.push($e.data('name'));
                    }
                });

                value = selected.join(', ');
            }

            $el.find(SELECTORS.SELECTED).html(value);
        });
    }

    processTitleLink() {
        let href = this.$title.data('href');

        if (href) {
            this.emitter.emit('refinement.applyFilter', {
                type: 'category',
                url: href,
                name: this.$title.data('name')
            });
        }
    }
}
