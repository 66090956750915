import StoreFinderMapMgr from 'client/components/StoreFinderMapMgr';
import FinderModel from 'client/model/Finder';
import { appendParamToURL } from 'client/utils/url';
import ajax from 'client/utils/ajax';
import templatesManager from 'client/utils/templatesManager';
import { CLASSES, GLOBAL_SELECTORS } from 'client/utils/globals';
import { scrollToMsg } from 'client/utils/common';
import { extractLocation, extractPostalCode } from 'client/utils/googleGeolocation';
import { getLocation } from 'client/utils/location';
import SelectOption from 'client/model/SelectOption';
import prefs from 'sitePreferences';
import $ from 'jquery';

// eslint-disable-next-line no-undef
const Google = typeof google === 'undefined' ? null : google;

const SELECTORS = {
    'MAP': '.js-map-container',
    'LIST': '.js-post-list',
    'LIST_MOBILE': '.js-post-list-mobile',
    'ASSET': '.js-asset-container',
    'SUBMIT': '.js-finder-submit',
    'APPLY': '.js-finder-apply',
    'NO_RESULT': '.js-no-result-message',
    'LOCATION': '.js-detect-location-button',
    'WRAPPER': '.js-finder-results-wrapper',
    'ERROR': '.js-service-error',
    'PICK_UP_CHECKBOXES': '.js-pick-up-station-checkboxes',
    'DHL_CHECKBOX': '.js-dhl-checkbox',
    'HERMES_CHECKBOX': '.js-hermes-checkbox'
};

const ATTRIBUTES = {
    DATA_FAVOURITE_STORE_ID: 'data-favourite-store-id'
};

export default class ShippingFinder extends StoreFinderMapMgr {
    init () {
        this.within = this.getNestedComponentById('finder-within');

        this.model = new FinderModel(this.config, Google);
        this.bounds = new Google.maps.LatLngBounds();

        if (this.config.type === 'storeLocator') {
            this.itemTemplate = templatesManager.templates.shippingFinderLocatorItemTemplate;
            this.itemMobileTemplate = templatesManager.templates.shippingFinderLocatorItemMobileTemplate;
            this.infoWindowTemplate = templatesManager.templates.shippingFinderLocatorInfoWindow;
        } else {
            this.itemTemplate = templatesManager.templates.shippingFinderItemTemplate;
            this.itemMobileTemplate = templatesManager.templates.shippingFinderItemMobileTemplate;
            this.infoWindowTemplate = templatesManager.templates.shippingFinderInfoWindow;
        }

        this.list = [];
        this.mobileList = [];

        this.$map = this.$el.find(SELECTORS.MAP);
        this.$list = this.$el.find(SELECTORS.LIST);
        this.$mobileList = this.$el.find(SELECTORS.LIST_MOBILE);
        this.$asset = this.$el.find(SELECTORS.ASSET);
        this.$noResult = this.$el.find(SELECTORS.NO_RESULT);
        this.$wrapper = this.$el.find(SELECTORS.WRAPPER);
        this.$selectedAddress = null;
        this.$error = this.$el.find(SELECTORS.ERROR);
        this.$pickUpStationCheckboxes = this.$el.find(SELECTORS.PICK_UP_CHECKBOXES);
        this.$dhlCheckbox = this.$el.find(SELECTORS.DHL_CHECKBOX);
        this.$hermesCheckbox = this.$el.find(SELECTORS.HERMES_CHECKBOX);
        this.locationCmp = this.getNestedComponentById('finder-location');

        // @ts-ignore
        this.locationCmp.bindEvent('keydown', () => {
            this.model.isZipCodeDetected = false;
            this.model.isLocationDetected = false;
        });

        this.emitter.addListener('checkout.step.shipping.finder.init', (d) => this.onFinderInit(d));
        this.emitter.addListener('search.by.value.init', (searchValue) => this.onloadSearch(searchValue));
        this.emitter.addListener('search.by.location.init', () => this.locationSearch());

        this.bindEvent('change', SELECTORS.DHL_CHECKBOX, () => this.intiSearchByCheckbox());
        this.bindEvent('change', SELECTORS.HERMES_CHECKBOX, () => this.intiSearchByCheckbox());
        this.bindEvent('click', SELECTORS.SUBMIT, () => this.onSubmit());
        if (this.config.type === 'storeLocator') {
            this.emitter.emit('checkout.step.shipping.finder');
        }
        this.emitter.emit('search.by.value');
        this.emitter.emit('search.by.location');
        this.emitter.emit(this.config.initEvent);
    }

    intiSearchByCheckbox() {
        let list = this.$searchResultList;

        list = this.filterMarkersByCheckbox(list);
        this.renderMap(list);
        this.renderList(list);
        this.renderMobileList(list);
        this.toggleControls();

        this.$noResult.addClass(CLASSES.hide);
        this.$asset.addClass(CLASSES.hide);
        this.$wrapper.removeClass(CLASSES.hide);
        this.$error.addClass(CLASSES.hide);
    }

    onloadSearch (searchValue) {
        this.onFinderInit(false);
        this.setFinderValue(searchValue);
        this.onSubmit();
    }

    locationSearch() {
        this.onFinderInit(false);
        this.detectLocation();
        this.onSubmit();
    }

    async onFinderInit(data) {
        await this.fieldsInit(data);
        this.initMap();
        this.find();

        let options;

        this.bindEvent('click', SELECTORS.LOCATION, async () => {
            await this.detectLocation();
        });
        if (this.config.type === 'storeLocator') {
            this.initAutocomplete();
            this.emitter.emit('globalCustomSelect.finder-within-select.enable');
            options = this.getStoreOptions();
        } else {
            options = this.getDefaultOptions();
        }

        if (this.within && options && options.length > 0) {
            this.within.updateOptions(options);
        }
    }

    async detectLocation() {
        try {
            let position = await getLocation();

            this.model.setLocation(position.coords.latitude, position.coords.longitude, true);
            let result = await this.model.geocode(this.model.location, null, true);

            if (result.postalCode) {
                this.setFinderValue(result.postalCode);
            }

            if (this.list && this.list.length > 0) {
                let newList = this.processListDistance(this.list);

                this.renderList(newList);
                this.renderMobileList(newList);
            }
        } catch (error) {
            alert(this.config.geolocationError); // eslint-disable-line
        }
    }

    setFinderValue(value) {
        this.getNestedComponentById('finder-location', cmp => cmp.setValue(value));
    }

    async onSubmit() {
        let cmp = this.getNestedComponentById('finder-location');

        if (this.model.isZipCodeDetected || this.model.isLocationDetected) {
            this.find();
        } else if (cmp) {
            let isValid = await cmp.validate();

            if (isValid) {
                await this.model.processValue(cmp.getValue());
                this.find();
            }
        }
    }

    async fieldsInit(data) {
        this.model.setAddress(data.zip, false, data.city);

        let value = this.model.getAddress();
        let pickUpStationCheckboxes = this.$pickUpStationCheckboxes.find('input');

        if (this.model.type === 'pick-up-station' && pickUpStationCheckboxes.length > 1) {
            this.$pickUpStationCheckboxes.removeClass('h-hide');
        }
        if (value) {
            this.setFinderValue(value);

            if (this.model.type === 'storeLocator') {
                await this.model.processValue(value);
            }
        }

    }

    initMap() {
        this.map = new Google.maps.Map(this.$map.get(0), {
            zoom: 15,
            minZoom: 5,
            maxZoom: 19,
            disableDefaultUI: true,
            zoomControl: true,
            zoomControlOptions: {
                position: Google.maps.ControlPosition.RIGHT_BOTTOM
            }
        });

        this.mapInitialized = true;
    }

    resetMap() {
        this.bounds = new Google.maps.LatLngBounds();

        if (this.markers && this.markers.size > 0) {
            for (let [, marker] of this.markers) {
                marker.setMap(null);
            }

            this.markers.clear();
        }

        this.markers = new Map();
    }

    renderMap(list) {
        if (this.mapInitialized === false) {
            this.initMap();
        } else {
            this.resetMap();
        }

        this.list = list;
        for (let point of list) {
            let pos = new Google.maps.LatLng(point.location.lat, point.location.lng);
            let marker = new Google.maps.Marker({
                position: pos,
                map: this.map,
                icon: this.model.marker
            });

            marker.icon.url = this.model.marker[point.type];
            let markerClick = () => {
                const parameters = Object.assign({}, point, {
                    isBopisEnabled: prefs.isBopisEnabled,
                    currentFavouriteStoreID: $(GLOBAL_SELECTORS.mediaInteraction)
                        .attr(ATTRIBUTES.DATA_FAVOURITE_STORE_ID)
                });
                let $template = $(this.infoWindowTemplate(parameters));

                $template.on('click', SELECTORS.APPLY, () => {
                    this.emitter.emit('modal.close', {
                        id: point.type,
                        params: point
                    });

                    this.emitter.emit('shipping.finder.close', {
                        id: point.type,
                        params: point
                    });
                });

                this.model.infoWindow.setOptions({
                    content: $template.get(0)
                });
                this.model.infoWindow.open(this.map, marker);
                this.initFavouriteStoreButton.call(this);
            };

            marker.addListener('click', () => markerClick());
            marker.click = () => markerClick();

            this.markers.set(point.UUID, marker);

            this.bounds.extend(marker.position);
        }

        setTimeout(() => {
            this.map.fitBounds(this.bounds);
        });
    }

    renderList(list) {
        this.list = list;

        this.$list.empty();

        for (let item of list) {
            let $listItem = $(this.itemTemplate(item));

            $listItem.on('click', () => {
                let marker = this.markers.get(item.UUID);

                if (marker) {
                    marker.click();
                }

                this.initFavouriteStoreButton.call(this);

                if (this.$selectedAddress) {
                    this.$selectedAddress.removeClass(CLASSES.selected);
                }
                this.$selectedAddress = $listItem;
                this.$selectedAddress.addClass(CLASSES.selected);
            });

            this.$list.append($listItem);
        }
    }

    renderMobileList (list) {
        this.$mobileList.empty();
        this.mobileList = [];

        let listItem = {};

        for (let item of list) {
            const parameters = Object.assign({}, item, {
                isBopisEnabled: prefs.isBopisEnabled,
                currentFavouriteStoreID: $(GLOBAL_SELECTORS.mediaInteraction)
                    .attr(ATTRIBUTES.DATA_FAVOURITE_STORE_ID)
            });
            let $listItem = $(this.itemMobileTemplate(parameters));

            $listItem.collapse({});

            this.$mobileList.append($listItem);

            setTimeout(() => {
                $listItem.on('shown.bs.collapse', () => {
                    setTimeout(() => {
                        listItem.pos = new Google.maps.LatLng(item.location.lat, item.location.lng);

                        listItem.map = new Google.maps.Map($listItem.find(SELECTORS.MAP).get(0), {
                            minZoom: 5,
                            maxZoom: 19,
                            zoom: 14,
                            center: listItem.pos,
                            disableDefaultUI: true
                        });

                        listItem.marker = new Google.maps.Marker({
                            position: listItem.pos,
                            map: listItem.map,
                            icon: this.model.marker
                        });
                        listItem.marker.icon.url = this.model.marker[item.type];

                        $listItem.addClass(CLASSES.selected);

                        this.initFavouriteStoreButton.call(this);
                    });
                });

                $listItem.on('hidden.bs.collapse', () => {
                    $listItem.find(SELECTORS.MAP).empty();
                    listItem.marker.setMap(null);
                    $listItem.removeClass(CLASSES.selected);
                });
            }, 100);

            $listItem.on('click', SELECTORS.APPLY, () => {
                this.emitter.emit('modal.close', {
                    id: item.type,
                    params: item
                });

                this.emitter.emit('shipping.finder.close', {
                    id: item.type,
                    params: item
                });
            });

            this.mobileList.push(listItem);
        }
    }

    find() {
        let url = this.config.url;

        if (this.config.type === 'storeLocator') {
            this.findSnipesStore(url);
        } else {
            this.findByAddress(url);
        }
    }

    sortByDistance(list) {
        list.sort(function (a, b) {
            return a.location.distance - b.location.distance;
        });
    }

    filterMarkersByCheckbox(list) {
        if (this.$hermesCheckbox && !this.$hermesCheckbox.prop('checked')) {
            list = list.filter(station =>
                station.type !== 'hermes'
            );
        }
        if (this.$dhlCheckbox && !this.$dhlCheckbox.prop('checked')) {
            list = list.filter(station =>
                station.type !== 'packstation'
            );
            list = list.filter(station =>
                station.type !== 'filiale'
            );
        }
        return list;
    }

    findByAddress(url) {
        let zip = this.model.zip;
        let city = this.model.city;

        if (!city && !zip && !this.model.isLocationDetected) {
            return;
        }

        if (zip) {
            url = appendParamToURL(url, 'zip', zip);
        }

        if (city) {
            url = appendParamToURL(url, 'city', city);
        }

        if (this.model.isLocationDetected) {
            url = appendParamToURL(url, 'dataType', 'coordinates');
            url = appendParamToURL(url, 'lat', this.model.location.lat);
            url = appendParamToURL(url, 'lng', this.model.location.lng);
        } else {
            url = appendParamToURL(url, 'dataType', 'address');
        }


        let promise = ajax.getJson({ url: url });

        promise.then(response => {
            if (response.success === false) {
                if (response.errors && response.errors.length) {
                    this.$error.text(response.errors.join('\n'));
                    this.$error.removeClass(CLASSES.hide);
                    scrollToMsg(this.$error);
                }

                this.$asset.removeClass(CLASSES.hide);
                this.$noResult.addClass(CLASSES.hide);
                this.$wrapper.addClass(CLASSES.hide);
            } else if (response.success && response.list.length) {
                this.sortByDistance(response.list);
                this.$searchResultList = response.list;
                response.list = this.filterMarkersByCheckbox(response.list);
                this.renderMap(response.list);
                this.renderList(response.list);
                this.renderMobileList(response.list);
                this.toggleControls();

                this.$noResult.addClass(CLASSES.hide);
                this.$asset.addClass(CLASSES.hide);
                this.$wrapper.removeClass(CLASSES.hide);
                this.$error.addClass(CLASSES.hide);
            } else if (response.list && response.list.length === 0) {
                let msg = this.locationCmp && this.locationCmp.getValue();

                this.$noResult.text(this.config.noResults.replace('{0}', 0).replace('{1}', msg));
                this.$noResult.removeClass(CLASSES.hide);
                this.$error.addClass(CLASSES.hide);
                this.$wrapper.addClass(CLASSES.hide);
            }
        });
    }

    findSnipesStore(url) {
        let zip = this.model.zip;
        let city = this.model.city;
        let location = this.model.getLocation();

        if (location && location.lat && location.lng) {
            url = appendParamToURL(url, 'lat', location.lat);
            url = appendParamToURL(url, 'long', location.lng);
        } else if (zip) {
            url = appendParamToURL(url, 'postalCode', zip);
        } else if (city) {
            url = appendParamToURL(url, 'city', city);
        } else {
            return;
        }


        let radius = this.getRadius();

        if (radius) {
            url = appendParamToURL(url, 'radius', radius);
        }

        let promise = ajax.getJson({ url: url });

        promise.then(response => {
            let formatted;

            if ('storesJSON' in response) {
                formatted = this.transformStores(response.storesJSON, false);
            } else {
                formatted = this.transformStores(response.stores, true);
            }

            if (formatted.length && !response.noResults) {
                this.renderMap(formatted);
                this.renderList(formatted);
                this.renderMobileList(formatted);
                this.toggleControls();
                this.$asset.addClass(CLASSES.hide);
                this.$noResult.addClass(CLASSES.hide);
                this.$wrapper.removeClass(CLASSES.hide);
            } else {
                let msg = this.locationCmp && this.locationCmp.getValue();

                this.$noResult.text(
                    this.config.noResults.replace('{0}', 0).replace('{1}', msg)
                );
                this.$asset.removeClass(CLASSES.hide);
                this.$noResult.removeClass(CLASSES.hide);
                this.$wrapper.addClass(CLASSES.hide);
            }
        });
    }

    transformStores(list, isJSON) {
        let stores = [],
            radius = this.getRadius();

        if (isJSON) {
            stores = list;
        } else {
            try {
                stores = JSON.parse(list);
            } catch (e) {
                return stores;
            }
        }

        stores = stores.map(store => {
            let newStore = {
                ID: store.ID,
                UUID: store.UUID,
                type: 'storeLocator',
                location: {
                    lat: store.latitude,
                    lng: store.longitude
                },
                address: {
                    name: store.name,
                    address1: store.address1,
                    postalCode: store.postalCode,
                    city: store.city
                }
            };

            if (this.model.location && this.model.location.lat && this.model.location.lng) {
                newStore.location.distance = this.calculateDistance({
                    lat: store.latitude,
                    lng: store.longitude
                }, this.model.getLocation());
            } else if (store.distance && store.distance !== '-') {
                newStore.location.distance = parseFloat(store.distance);
            } else {
                newStore.location.distance = '-';
            }

            return newStore;
        });

        stores = stores.filter(store => {
            return store.location.distance === '-' ? true : store.location.distance <= parseInt(radius, 10);
        });

        this.sortByDistance(stores);

        return stores;
    }

    toggleControls() {
        let rendered = this.list.length > 0;

        if (rendered) {
            this.$map.removeClass(CLASSES.hide);
            this.$asset.addClass(CLASSES.hide);
        } else {
            this.$map.addClass(CLASSES.hide);
            this.$asset.removeClass(CLASSES.hide);
        }
    }

    initAutocomplete() {
        let cmp = this.getNestedComponentById('finder-location');

        if (cmp) {
            this.autocomplete = new Google.maps.places.Autocomplete(
                cmp.getInputField()[0],
                {
                    fields: ['address_component', 'geometry'],
                    strictBounds: true,
                    types: ['geocode']
                }
            );

            this.autocomplete.setFields(['address_component']);

            if (this.config.countryCode) {
                this.autocomplete.setComponentRestrictions({ country: this.config.countryCode });
            }

            this.autocomplete.addListener('place_changed', this.processAutocompleteResult.bind(this));
        }
    }

    processAutocompleteResult() {
        const place = this.autocomplete.getPlace();

        if (place) {
            this.model.setAddress(extractPostalCode(place), true);
            this.model.setLocation(extractLocation(place));

            if (this.list && this.list.length > 0) {
                let newList = this.processListDistance(this.list);

                this.renderList(newList);
                this.renderMobileList(newList);
            }
        }
    }

    getRadius() {
        let val = null;

        if (this.within) {
            val = this.within.getValue();
        }

        return val;
    }

    calculateDistance(postLocation, userLocation) {
        return (Google.maps.geometry.spherical.computeDistanceBetween(
            new Google.maps.LatLng(postLocation.lat, postLocation.lng),
            new Google.maps.LatLng(userLocation.lat, userLocation.lng)
        ) / 1000).toFixed(2);
    }

    processListDistance(list) {
        return list.map(item => {
            item.location.distance = this.calculateDistance(item.location, this.model.getLocation());

            return item;
        });
    }

    getStoreOptions() {
        let options = this.config.storesOptions,
            transformed = [],
            defaultOption = options.default;

        for (let option of options.options) {
            transformed.push(new SelectOption(option, option, `${option} km`, option === defaultOption));
        }

        return transformed;
    }

    getDefaultOptions() {
        let options = this.config.defaultOptions,
            transformed = [];

        for (let option of options) {
            transformed.push(new SelectOption(option.id, option.value, option.label, option.selected));
        }

        return transformed;
    }
}
