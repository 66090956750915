import RegisterForm from 'client/components/account/RegisterForm';

const SELECTORS = {
    password: '[data-id="{FORM}_newpassword"]',
    passwordConfirm: '[data-id="{FORM}_newpasswordconfirm"]'
};

class NewPasswordForm extends RegisterForm {
    init() {
        super.init();

        // eslint-disable-next-line no-restricted-syntax
        for (let k of Object.keys(SELECTORS)) {
            this.$[k] = this.$el.find(this.resolveSelector(SELECTORS[k]));
        }

        this.bindEvent('focusout', this.$.password, function() {
            const passwordCmp = this.$.password.data('cmp-instance');
            const passwordConfirmCmp = this.$.passwordConfirm.data('cmp-instance');

            if (passwordConfirmCmp.getValue() && passwordCmp.getValue()) {
                passwordConfirmCmp.validate();
            }
        });
    }
}

export default NewPasswordForm;
