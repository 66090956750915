import Component from 'client/core/Component';
import $ from 'jquery';
import { CLASSES } from 'client/utils/globals';

const SELECTORS = {
    slot: '*[id^="cq_recomm_slot-"]'
};

export default class Recommendations extends Component {
    init () {
        const config = { childList: true, subtree: true };
        const $slots = this.$el.find(SELECTORS.slot);

        const reInitChildComponents = (mutationsList, observer) => {
            observer.disconnect();

            const $slot = $(mutationsList[0].target).closest(SELECTORS.slot);

            $slot.removeClass(CLASSES.hide);

            this.emitter.emit('namespace.component.initall', $slot, {
                carousels: false
            });
            this.emitter.emit('afterRecommendations', $slot);
        };

        $slots.addClass(CLASSES.hide);

        $slots.each((index, slot) => {
            const observer = new MutationObserver(reInitChildComponents);

            observer.observe(slot, config);
        });
    }
}
