import Component from 'client/core/Component';
import ajax from 'client/utils/ajax';
import { compile } from 'handlebars';

const SELECTORS = {
    target: '.js-target',
    source: '.js-source'
};

export default class ProductStores extends Component {
    onVariationUpdate(product) {
        ajax.getJson({
            url: this.config.updateurl,
            type: 'GET',
            data: {
                pid: product.id
            }
        }).then(async (response) => {
            // we request stores statuses, update product object and re-render templates
            if (response && response.stores) {
                let stores = response.stores;

                product.stores.forEach((store, index) => {
                    if (stores[store.ID]) {
                        product.stores[index].isProductAvailable = true;
                    }
                });

                this.$target.html(this.template(product));
            }
        });
    }

    init() {
        this.emitter.addListener('variation.updated', (product) => this.onVariationUpdate(product));

        this.$target = this.$el.find(SELECTORS.target);
        this.source = this.$el.find(SELECTORS.source).html();
        this.template = compile(this.source);
    }
}
