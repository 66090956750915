import MainNamespace from 'buffalo/globalComponents/MainNamespace';

import checkoutMgr from 'buffalo/components/checkout/CheckoutMgr';
import shippingStep from 'buffalo/components/checkout/ShippingStep';
import paymentStep from 'buffalo/components/checkout/PaymentStep';
import placeOrderStep from 'buffalo/components/checkout/PlaceOrderStep';
import paymentOptions from 'buffalo/components/checkout/PaymentOptions';
import giftCardPaymentOption from 'client/components/checkout/GiftCardPaymentOption';
import giftCardItem from 'client/components/checkout/GiftCardItem';
import checkoutOrderSummary from 'client/components/checkout/CheckoutOrderSummary';
import cartCoupon from 'client/components/cart/CartCoupon';

import infoBlock from 'client/components/checkout/InfoBlock';
import suggestionAddressModal from 'buffalo/components/checkout/SuggestionAddressModal';
import addressValidator from 'buffalo/components/forms/AddressValidator';
import autocompleteAddress from 'buffalo/components/forms/AutocompleteAddress';
import checkoutAddressForm from 'buffalo/components/forms/CheckoutAddressForm';
import checkoutPaymentForm from 'client/components/forms/CheckoutPaymentForm';
import checkoutPlaceOrderForm from 'client/components/forms/CheckoutPlaceOrderForm';
import shippingBillingSame from 'client/components/forms/ShippingBillingSame';
import acceptMobileGdpr from 'client/components/forms/AcceptMobileGdpr';

import shippingMethod from 'buffalo/components/checkout/shipping/ShippingMethod';
import shipmentSelector from 'buffalo/components/checkout/shipping/ShipmentSelector';
import shippingFinder from 'client/components/checkout/shipping/ShippingFinder';
import shippingRates from 'buffalo/components/checkout/shipping/ShippingRates';
import packstationNumber from 'buffalo/components/checkout/shipping/PackstationNumber';

import inputText from 'client/components/forms/commonElements/InputText';
import inputCheckbox from 'client/components/forms/commonElements/InputCheckbox';
import inputSelect from 'client/components/forms/commonElements/InputSelect';
import inputPostNumber from 'client/components/checkout/shipping/InputPostNumber';
import InputAutocomplete from 'client/components/checkout/shipping/InputPostNumber';
import countryField from 'buffalo/components/checkout/shipping/CountryField';
import lockedCountryField from 'buffalo/components/checkout/shipping/LockedCountryField';
import stateField from 'buffalo/components/checkout/shipping/StateField';

const mainNamespace = new MainNamespace();

mainNamespace.addComponents({
    checkoutMgr,
    shippingStep,
    paymentStep,
    placeOrderStep,
    paymentOptions,
    giftCardPaymentOption,
    giftCardItem,
    checkoutOrderSummary,
    cartCoupon,

    infoBlock,
    suggestionAddressModal,
    addressValidator,
    autocompleteAddress,
    checkoutAddressForm,
    checkoutPaymentForm,
    checkoutPlaceOrderForm,
    shippingBillingSame,
    acceptMobileGdpr,

    shippingMethod,
    shipmentSelector,
    shippingFinder,
    shippingRates,
    packstationNumber,

    inputText,
    inputCheckbox,
    inputSelect,
    inputPostNumber,
    InputAutocomplete,
    countryField,
    lockedCountryField,
    stateField
});

mainNamespace.init();
