import Input from 'client/core/Input';

import { CLASSES } from 'client/utils/globals';

const NUMBER_REGEX = /[0-9]/gmi;

export default class InputText extends Input {
    static get SELECTORS() {
        return {
            inputField: '.js-field'
        };
    }

    init () {
        super.init();

        this.inputField = this.$el.find(InputText.SELECTORS.inputField);
        this.placeholderToggle();
        this.bindEvent('focusout', InputText.SELECTORS.inputField, this.validate);

        if (this.config.initialPlaceholder) {
            this.placeholderToggle();
            this.bindEvent('focus', InputText.SELECTORS.inputField, this.placeholderMoveUp);
            this.bindEvent('blur', InputText.SELECTORS.inputField, this.placeholderToggle);
        } else {
            this.bindEvent('focus', InputText.SELECTORS.inputField, this.placeholderHide);
            this.bindEvent('blur', InputText.SELECTORS.inputField, this.placeholderVisibilityToggle);
        }

        if (this.config.withoutNumbers) {
            this.bindEvent('keyup', InputText.SELECTORS.inputField, this.replaceNumbers);
        }
    }

    replaceNumbers() {
        this.inputField.val(this.inputField.val().replace(NUMBER_REGEX, ''));
    }

    placeholderMoveUp() {
        this.$el.addClass(CLASSES.placeholderUp);
    }

    placeholderToggle() {
        let searchPhrase = this.inputField.val().trim();

        if (searchPhrase.length === 0) {
            this.$el.removeClass(CLASSES.placeholderUp);
        } else {
            this.placeholderMoveUp();
        }
    }

    placeholderHide() {
        this.$el.addClass(CLASSES.placeholderHidden);
    }

    placeholderVisibilityToggle() {
        let searchPhrase = this.inputField.val().trim();

        if (searchPhrase.length === 0) {
            this.$el.removeClass(CLASSES.placeholderHidden);
        }
    }

    setValue(newVal, silently = false) {
        if (this.config.initialPlaceholder) {
            this.placeholderMoveUp();
        } else {
            this.placeholderHide();
        }

        super.setValue(newVal, silently);

        this.placeholderToggle();
    }

    getInputField() {
        return this.inputField;
    }
}
