import Component from 'client/core/Component';

const
    SELECTORS = {
        hamburger: '.js-hamburger'
    };

export default class MobileMenuSwitcher extends Component {

    init () {
        this.bindEvent('click', SELECTORS.hamburger, this.menuShow);
    }

    menuShow () {
        this.emitter.emit('mobileMenuShow');
    }
}
