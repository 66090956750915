const PREFERENCE_NAME = 'prefn';
const PREFERENCE_VALUE = 'prefv';

/**
* @function
* @description appends the parameter with the given name and value to the given url and returns the changed url
* @param {String} url the url to which the parameter will be added
* @param {String} name the name of the parameter
* @param {String} value the value of the parameter
*/
export function appendParamToURL(url, name, value) {
    // if the param already exists
    const pattern = new RegExp(`\\b(${name}=).*?(&|$)`);

    if (url.search(pattern) >= 0) {
        // replace it
        return url.replace(pattern, `$1${encodeURIComponent(value)}$2`);
    }

    return `${url}${url.includes('?') ? '&' : '?'}${name}=${encodeURIComponent(value)}`;
}

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
export function appendParamsToURL(url, params) {
    let newUrl = url;

    newUrl += (newUrl.indexOf('?') > -1 ? '&' : '?') + Object.keys(params).map((key) => {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * @function
 * @param {String} url
 * @param {String} param
 * @returns {String}
 */
export function extractParamFromURL(url, name) {
    const pattern = new RegExp(`\\b(${name}=).*?(&|$)`);

    if (url.search(pattern) >= 0) {
        let result = pattern.exec(url),
            param = result[0];

        param = param.replace(result[1], '');
        param = param.replace(/&$/, '');

        return decodeURIComponent(param);
    }

    return null;
}

/**
 * @function
 * @param {String} url
 * @param {String} name
 * @returns {String}
 */
export function removeParamFromURL(url, name) {
    let head = '',
        tail = '';

    if (url.includes('?')) {
        [head, tail] = url.split('?');
    } else {
        tail = url;
    }

    let arr = tail.split('&'),
        regex = new RegExp(`\\b(${name}=).*$`);

    arr = arr.filter(param => !param.match(regex));
    tail = arr.join('&');

    return `${head}${head.length && tail.length ? '?' + tail : tail}`;
}

/**
 * Parse URL
 *
 * @param {String} url
 * @returns {URL|HTMLAnchorElement}
 */
export function parseURL(url) {
    if (typeof URL === 'function') {
        return new URL(url);
    } else {
        const parser = document.createElement('a');

        parser.href = url;

        return parser;
    }
}

/**
* @function
* @description appends the new preference with the given name and value to the given url and returns the changed url
* @param {String} url the url to which the parameter will be added
* @param {String} baseUrl (config) the url to which the parameter will be added in some cases
* @param {String} name the name of the preference
* @param {String} value the value of the preference
*/
export function appendPreferenceToURL(url, baseUrl, name, value) {
    // Our constants for standart SFCC preferences
    const SPECIFIC_CATEGORIES = {
        new: 'isNew',
        soon: 'isComingSoon',
        sale: 'isSale'
    };

    // If we already have that preference, then return existing url
    const existingPrefRegex = new RegExp(`${PREFERENCE_NAME}\\d=${encodeURIComponent(name)}`, 'g');
    const isPrefExists = url.match(existingPrefRegex);

    if (isPrefExists) {
        return url;
    }

    const prefnPattern = new RegExp(`${PREFERENCE_NAME}\\d`, 'g');
    const prefs = url.match(prefnPattern);
    const prefsCount = prefs ? prefs.length : 0;

    // Variables for detecting if url already contains any preferences
    const lastPrefn = PREFERENCE_NAME + prefsCount;
    const lastPrefv = PREFERENCE_VALUE + prefsCount;
    const lastPrefRegex = new RegExp(`${lastPrefn}=(.*?)&${lastPrefv}=(.*?)&`, 'g');
    const isLastPrefExists = url.match(lastPrefRegex);

    // Variables for constructing preferense parameter for url
    const newPrefNumber = prefsCount + 1;
    const newPrefnName = PREFERENCE_NAME + newPrefNumber;
    const newPrevnValue = encodeURIComponent(name);
    const newPrefvName = PREFERENCE_VALUE + newPrefNumber;
    const newPrefvValue = encodeURIComponent(value);
    const newPrefForUrl = `${newPrefnName}=${newPrevnValue}&${newPrefvName}=${newPrefvValue}`;

    // Basic url that will be changed
    let newUrl = url;

    // Is specific category in url
    const urlSpecificCatParam = Object
    .keys(SPECIFIC_CATEGORIES)
    .find(key => SPECIFIC_CATEGORIES[key] === name);
    const isUrlSpecificCatParamInUrl = url.includes(urlSpecificCatParam);

    // If we already have other preferences in url, then add after the last one, otherwise to the end of url
    if (isLastPrefExists && isLastPrefExists.length) {
        let result = lastPrefRegex.exec(url);
        let text;
        let index;

        while (result) {
            result = lastPrefRegex.exec(url);
            text = result[0];
            index = result.index;
        }

        const isertIndex = index + text.length;
        const insertText = `${newPrefForUrl}&`;

        newUrl = appendParamToURL(newUrl, 'specificCategory', urlSpecificCatParam);
        newUrl = newUrl.slice(0, isertIndex) + insertText + newUrl.slice(isertIndex);

        return newUrl;

    } else {
        const isParamsInUrl = url.includes('?');

        if (isUrlSpecificCatParamInUrl && !isParamsInUrl) {
            newUrl = appendParamToURL(newUrl, 'specificCategory', urlSpecificCatParam);
            return newUrl;
        } else {
            newUrl = baseUrl;
            newUrl = appendParamToURL(newUrl, 'specificCategory', urlSpecificCatParam);
            newUrl = `${newUrl}${newUrl.includes('?') ? '&' : '?'}${newPrefForUrl}`;

            return newUrl;
        }
    }
}

/**
 * Construct url string from object
 * @param  {String} url
 * @return {String}
 */
export function getUrlWithoutQueryString (url) {
    if (url) {
        return url.split(/[?#]/)[0];
    }

    return location.href.split(/[?#]/)[0];
}

/**
 * @function
 * @param {String} url
 * @returns {Object}
 */
export function toObjectQueryString (url) {
    if (!url) {
        return '';
    }

    const queryString = url.split('?')[1];

    if (!queryString) {
        return '';
    }

    return queryString.split('&').reduce(function(acc, item) {
        if (!item) {
            return acc;
        }

        var pair = item.split('=');

        acc[pair[0]] = pair[1];
        return acc;
    }, {});
}

/**
 * Construct url string from object
 * @param  {String} str
 * @param  {Object} obj
 * @return {String}
 */
export function objectToUrlParamsString (str, obj) {
    return str + '?' + Object.keys(obj).map(function (key) {
        return '&' + key + '=' + obj[key];
    }).join('').slice(1);
}

/**
 * Delete one-pair preferences and update sequence numbers for prefn and prefv
 * @param  {Object} obj
 * @return {Object}
 */
export function updatePreferencesNumbers (obj) {
    const prefsObj = {};
    let i = 1;

    return Object.keys(obj).reduce((acc, key) => {
        const isPreferenceName = new RegExp(`${PREFERENCE_NAME}\\d`).test(key);
        const isPreferenceValue = new RegExp(`${PREFERENCE_VALUE}\\d`).test(key);

        if (isPreferenceName) {
            const currentNum = key.match(/\d+/)[0];
            const correspondingValue = `${PREFERENCE_VALUE}${currentNum}`;
            const newPrefName = `${PREFERENCE_NAME}${i}`;

            prefsObj[correspondingValue] = `${PREFERENCE_VALUE}${i}`;
            acc[newPrefName] = obj[key];
            ++i;

            return acc;
        } else if (isPreferenceValue) {
            const newPrefValue = prefsObj[key];

            if (newPrefValue) {
                acc[newPrefValue] = obj[key];
            }

            return acc;
        } else {
            acc[key] = obj[key];
        }

        return acc;
    }, {});
}


/**
 * @function
 * @param {String} url
 * @param {String} value
 * @returns {String}
 */
export function removePreferenceFromURL(url, value) {
    const urlObj = toObjectQueryString(url);
    const newObj = Object.keys(urlObj).reduce((acc, key) => {

        if (urlObj[key] !== value) {
            acc[key] = urlObj[key];
        }

        return acc;
    }, {});
    const updatedObj = updatePreferencesNumbers(newObj);
    const result = objectToUrlParamsString(getUrlWithoutQueryString(''), updatedObj);

    return result;
}
