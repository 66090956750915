import Form from 'client/core/Form';
import { CLASSES } from 'client/utils/globals';
import ajax from 'client/utils/ajax';
import { scrollToMsg } from 'client/utils/common';

const SELECTORS = {
    error: '.js-error'
};

export default class TrackOrderForm extends Form {
    onSubmit (el, e) {
        e.preventDefault();

        const submitted = super.onSubmit();

        submitted.then(isValid => {
            if (isValid) {
                const url = this.config.actionUrl,
                    data = this.$el.serialize();

                ajax.getJson({
                    url: url,
                    data: data
                }).then((resp) => {
                    if (resp.success) {
                        window.location.href = `${url}?${data}`;
                    } else {
                        this.showError();
                    }
                }).fail(() => {
                    window.location.reload();
                });
            }
        });

        return false;
    }

    showError() {
        this.$.error.removeClass(CLASSES.hide);

        scrollToMsg(this.$.error);
    }

    init() {
        this.$ = {
            error: this.$el.find(SELECTORS.error)
        };
    }
}
