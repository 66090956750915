import Component from 'client/core/Component';
import { CLASSES } from 'client/utils/globals';
import { isCurrentlySmall, isCurrentlyMedium } from 'client/utils/screendetector';
import { throttle } from 'lodash';

import $ from 'jquery';

const SELECTORS = {
    storeLocatorLink: '.js-store-locator-link',
    stickyInner: '.js-sticky-inner',
    stickyElement: '.js-sticky-element',
    secondLevelMenu: '.js-second-level-menu',
    searchFormField: '.js-search-form-inner',
    stickyContainer: '.js-sticky-container',
    headerSearch: '.js-header-search',
    headerMobile: '.js-header-sticky-mobile',
    countrySelector: '.js-country-selector',
    searchStickyButton: '.js-search-switcher',
    searchButton: '.js-search-btn'
};
const secondLevelMenuHeight = 20;
const DEFAULT_CONFIG = {
    offset: 1,
    paddingUnsticky: 16
};

export default class StickyHeader extends Component {
    init() {
        this.isSticky = false;
        this.disabledScroll = false;
        this.isCalculateHeight = false;
        this.headerHeight = {
            regular: null,
            sticky: null
        };

        this.config = Object.assign({}, DEFAULT_CONFIG, this.config);

        this.$secondLevelMenu = $(SELECTORS.secondLevelMenu);
        this.$stickyElement = this.$el.find(SELECTORS.stickyElement).first();
        this.$stickyInner = this.$el.find(SELECTORS.stickyInner).first();
        this.$searchField = $(SELECTORS.searchFormField);
        this.$stickyContainer = this.$el.find(SELECTORS.stickyContainer);
        this.$headerSearch = this.$el.find(SELECTORS.headerSearch);
        this.$headerMobile = this.$el.find(SELECTORS.headerMobile);
        this.$countrySlector = this.$el.find(SELECTORS.countrySelector);
        this.$searchStickyButton = this.$el.find(SELECTORS.searchStickyButton);
        this.$searchButtonHalfHeight = this.$el.find(SELECTORS.searchButton).height() / 2;

        this.calculateMobileHeight();

        if (this.$stickyElement.length) {
            this.calculateHeight();
            this.calculatePosition();
            // For cases when user scrolled before the component is initialized
            this.onScroll({ scrollTop: $(window).scrollTop() });

            this.emitter.addListener('scroll', throttle(this.onScroll.bind(this), 100));
            this.emitter.addListener('deviceChange', this.calculatePosition.bind(this));
            this.emitter.addListener('resize', this.calculatePosition.bind(this));
            this.emitter.addListener('orientationchange', this.calculatePosition.bind(this));
            this.addListener('scrollIsDisabled', () => (this.disabledScroll = true));
            this.addListener('scrollIsEnabled', () => (this.disabledScroll = false));
            this.addListener('recalcStickyPosition', this.calculatePosition.bind(this));
        }

        if (this.gtmEnabled) {
            this.bindEvent('click', SELECTORS.storeLocatorLink, this.trackStoreFinderClick);
        }
    }

    trackStoreFinderClick() {
        const data = {
            eventCategory: 'store finder',
            eventAction: 'header',
            eventLabel: ''
        };

        this.emitter.emit('gtmEvent', data);
    }

    calculateMobileHeight() {
        if ((isCurrentlySmall() || isCurrentlyMedium())) {
            this.headerDefaultHeight = this.$headerSearch.height();
            this.headerMobileHeight = this.$headerMobile.height();
        }
    }

    calculateHeight() {
        let height = 0;
        let countrySelectorHeight = this.$countrySlector.height();
        let searchHeight = this.$searchField.height();

        if (isCurrentlySmall() || isCurrentlyMedium()) {
            if (this.isSticky) {
                height = this.headerMobileHeight;
            } else {
                height = this.headerDefaultHeight;
            }
        } else if (this.$secondLevelMenu.length) {
            height = Math.round(DEFAULT_CONFIG.paddingUnsticky + countrySelectorHeight +
                searchHeight + secondLevelMenuHeight);
        } else {
            height = Math.round(DEFAULT_CONFIG.paddingUnsticky + countrySelectorHeight + searchHeight);
        }

        this.headerHeight.regular = height;
        this.$el.css('min-height', height);
    }

    calculatePosition(offset = 0) {
        this.calculateMobileHeight();
        this.stickyElementPositionTop = (this.$stickyInner.offset().top + offset) + this.config.offset;

        if (this.isCalculateHeight) {
            this.calculateHeight();
        } else {
            this.isCalculateHeight = true;
        }
    }

    onScroll({ scrollTop: scrollTop } = { scrollTop: 0 }) {
        let isMobile = isCurrentlySmall() || isCurrentlyMedium();

        if (!this.disabledScroll) {
            if (this.isSticky && scrollTop < this.stickyElementPositionTop) {
                this.$el.removeClass(this.config.stickyClass);
                this.isSticky = false;
                this.emitOnChild('quicksearchform', 'headerIsNotSticky');
            } else if (!this.isSticky && scrollTop >= this.stickyElementPositionTop) {
                this.$el.addClass(this.config.stickyClass);
                this.isSticky = true;
                this.emitOnChild('quicksearchform', 'headerIsSticky');
                setTimeout(() => {
                    this.headerHeight.sticky = Math.round(this.$stickyContainer.innerHeight());
                }, 200);
            } else if (this.isSticky && isMobile && scrollTop < this.headerDefaultHeight) {
                this.$el.css('min-height', this.headerMobileHeight);
            }
        }
        if (isMobile) {
            if (this.isSticky && scrollTop >= (this.$searchField.offset().top - this.$searchButtonHalfHeight)) {
                this.$searchStickyButton.removeClass(CLASSES.hide);
            } else {
                this.$searchStickyButton.addClass(CLASSES.hide);
            }
        } else {
            this.$searchStickyButton.removeClass(CLASSES.hide);
        }
    }
}
