import Component from 'client/core/Component';
import { compile } from 'handlebars';
import prefs from 'sitePreferences';
import { throttle } from 'lodash';

import $ from 'jquery';

import { isSmall, isMedium } from 'client/utils/screendetector';

const POPUP_DELAY = 3000,
    SELECTORS = {
        popup: '.js-pdp-added-to-cart-popup',
        image: {
            carousel: '.js-pdp-carousel',
            prev: '.js-pdp-carousel-control--prev',
            next: '.js-pdp-carousel-control--next',
            dots: '.js-pdp-carousel-dots'
        },
        thumbs: {
            carousel: '.js-pdp-thumbs-carousel',
            prev: '.js-pdp-thumbs-carousel-control--prev',
            next: '.js-pdp-thumbs-carousel-control--next'
        },
        target: '.js-target',
        source: '.js-source'
    };

const THUMBS_TO_SHOW = prefs.pdpImageCarouselThumbsToShow;

const CONFIGS = {
    image: {
        dots: true,
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        asNavFor: SELECTORS.thumbs.carousel,
        draggable: false,
        touchThreshold: 15,
        speed: 400,
        fade: false,
        waitForAnimate: false
    },
    thumbs: {
        dots: false,
        infinite: true,
        arrows: false,
        slidesToShow: THUMBS_TO_SHOW,
        slidesToScroll: 1,
        autoplay: false,
        focusOnSelect: true,
        asNavFor: SELECTORS.image.carousel,
        vertical: true,
        verticalSwiping: true,
        draggable: false
    }
};

export default class ImageCarousel extends Component {
    initCarousel(instance) {
        const $elements = this.$elements[instance],
            $carousel = $elements.carousel;

        let config = Object.assign({}, CONFIGS[instance]);

        if (config.dots && $elements.dots) {
            config.appendDots = $elements.dots;

            // if (isSmall() || isMedium()) {
            //     $elements.dots.show();
            // } else {
            //     $elements.dots.hide();
            // }
        }

        const limit = (instance === 'image') ? 1 : THUMBS_TO_SHOW;

        if (this.imageCount > limit) {
            $elements.prev.show();
            $elements.next.show();
        } else {
            $elements.prev.hide();
            $elements.next.hide();
        }

        $carousel.on('init', () => {
            this.emitter.emit('namespace.component.initall', $carousel, {
                onAfterInit: () => {
                    this.onScreenResize();
                }
            });
        });

        $carousel.slick(config);

        if (this.imageCount <= THUMBS_TO_SHOW) {
            $(window).on('load.slick', () => {
                $carousel.slick('slickGoTo', 0, true);
            });
        }

        $elements.prev.click(() => {
            $carousel.slick('slickPrev');
        });

        $elements.next.click(() => {
            $carousel.slick('slickNext');
        });
    }

    onScreenResize() {
        if (this.inResize) {
            clearTimeout(this.inResize);
        }

        this.inResize = setTimeout(() => {
            if (isSmall() || isMedium()) {
                this.$elements.thumbs.carousel.show();

                this.$elements.thumbs.prev.show();
                this.$elements.thumbs.next.show();

                // this.$elements.image.prev.show();
                // this.$elements.image.next.show();
                // this.$elements.image.dots.show();
            } else {
                this.$elements.thumbs.carousel.show();
                this.$elements.thumbs.carousel.slick('setPosition');

                if (this.imageCount > THUMBS_TO_SHOW) {
                    this.$elements.thumbs.prev.show();
                    this.$elements.thumbs.next.show();
                } else {
                    this.$elements.thumbs.prev.hide();
                    this.$elements.thumbs.next.hide();
                }

                if (this.imageCount > 1) {
                    this.$elements.image.prev.show();
                    this.$elements.image.next.show();
                } else {
                    this.$elements.image.prev.hide();
                    this.$elements.image.next.hide();
                }

                // this.$elements.image.dots.hide();
            }

            this.inResize = null;

            if (this.imageCount <= THUMBS_TO_SHOW) {
                this.$elements.thumbs.carousel.slick('reinit');
            }
        });
    }

    onVariationUpdate(product, queryParams) {
        if (queryParams.chosen !== 'color') {
            return;
        }

        let result = this.template(product);

        if (!this.config.disableCarousel) {
            this.destroyCarousels();
        }

        this.replaceHtml(result, this.$target, {
            carousels: this.config.disableCarousel,
            onAfterInit: () => {
                if (!this.config.disableCarousel) {
                    this.initCarousels();
                }
            }
        });
    }

    onCartAdded() {
        if (isSmall() || isMedium()) {
            const $popup = this.$el.find(SELECTORS.popup);

            $popup.show();

            setTimeout(() => {
                $popup.hide();
            }, POPUP_DELAY);
        }
    }

    // eslint-disable-next-line consistent-return
    initCarousels() {
        this.imageCount = this.$el.find(SELECTORS.image.carousel).data('length');

        this.$elements = {
            image: {
                carousel: this.$el.find(SELECTORS.image.carousel),
                prev: this.$el.find(SELECTORS.image.prev),
                next: this.$el.find(SELECTORS.image.next),
                dots: this.$el.find(SELECTORS.image.dots)
            },
            thumbs: {
                carousel: this.$el.find(SELECTORS.thumbs.carousel),
                prev: this.$el.find(SELECTORS.thumbs.prev),
                next: this.$el.find(SELECTORS.thumbs.next)
            }
        };

        if (!('slick' in $.fn) || this.$elements.image.carousel.hasClass('slick-initialized')) {
            return false;
        }

        for (let instance of Object.keys(SELECTORS)) {
            if ((typeof SELECTORS[instance] === 'object') && ('carousel' in SELECTORS[instance])) {
                this.initCarousel(instance);
            }
        }
    }

    destroyCarousels() {
        for (let instance of Object.keys(SELECTORS)) {
            if ((typeof SELECTORS[instance] === 'object') && ('carousel' in SELECTORS[instance])) {
                const $carousel = this.$elements[instance].carousel;

                $carousel.slick('unslick');
            }
        }
    }

    onPhotoswipeGalleryClose(index) {
        this.$elements.image.carousel.slick('slickGoTo', index, true);
    }

    // eslint-disable-next-line consistent-return
    init() {
        if (!('slick' in $.fn)) {
            return false;
        }

        if (!this.config.disableCarousel) { // eslint-disable-line no-negated-condition
            this.initCarousels();
            this.emitter.addListener('deviceChange', () => this.onScreenResize());
            this.emitter.addListener('resize', throttle(() => this.onScreenResize(), 250));
            this.emitter.addListener('orientationchange', () => this.onScreenResize());
            this.emitter.addListener(
                'on.photoswipe.gallery.close',
                (index) => this.onPhotoswipeGalleryClose(index)
            );
        } else {
            this.emitter.emit('namespace.component.initall', this.$el);
        }

        this.emitter.addListener(
            'variation.updated',
            (product, queryParams) => this.onVariationUpdate(product, queryParams)
        );
        this.emitter.addListener('cart.add.added', () => this.onCartAdded());

        this.$target = this.$el.find(SELECTORS.target);
        this.source = this.$el.find(SELECTORS.source).html();
        this.template = compile(this.source);
    }
}
