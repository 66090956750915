import Component from 'client/core/Component';
import { getJson, getCSRFToken } from 'client/utils/ajax';
import { appendParamsToURL } from 'client/utils/url';
import { CLASSES } from 'client/utils/globals';
import $ from 'jquery';

const SELECTORS = {
    TEMPLATE: '.js-shipping-rates-ups-template',
    INPUT: '.js-rates-input'
};

export default class ShippingRates extends Component {
    init() {
        this.$template = this.$el.find(SELECTORS.TEMPLATE);

        this.countryCode = null;

        this.emitter.addListener('shippingRates.address.changed', this.onAddressChanged.bind(this));
        this.emitter.addListener('shippingRates.ups.hide', this.hideUPSBlock.bind(this));
    }

    /* eslint-disable consistent-return */
    async onAddressChanged(data) {

        const csrfToken = await getCSRFToken({ doNotStopSpinner: true });

        if (!csrfToken) {
            return {
                error: true
            };
        }

        this.emitter.emit('shippingRates.load.start');

        if (data.countryCode) {
            this.countryCode = data.countryCode;
        }

        data[csrfToken.tokenName] = csrfToken.token;

        let response = await getJson({
            url: this.config.fetchUrl,
            type: 'POST',
            data: data
        });

        // response.errorMessage is possible response due to requestValidator
        if (response.error || response.errorMessage) {
            this.emitter.emit('checkout.shipping.infoblock.show', {
                type: 'error', message: (response.errorMessage || this.config.errorMsg), scrollTo: true
            });
            this.emitter.emit('shippingRates.error');
        } else {
            this.emitter.emit('checkout.shipping.infoblock.hide');
            this.onResponse(response);
        }

        if (response.order) {
            // Update order summary with new shipping price
            this.emitter.emit('action.summary.update', response);
        }
    }

    async onChange(el) {
        let $el = $(el);

        if ($el.val && $el.val()) {
            this.emitter.emit('shippingRates.load.start');

            let methodId = $el.val();
            let url = appendParamsToURL(this.config.selectUrl, { 'methodID': methodId });

            if (this.countryCode) {
                url = appendParamsToURL(url, { countryCode: this.countryCode });
            }

            let response = await getJson({ url: url, type: 'POST' });

            if (response.error) {
                this.emitter.emit('checkout.shipping.infoblock.show', {
                    type: 'error', message: this.config.errorMsg, scrollTo: true
                });
                this.emitter.emit('shippingRates.error');
            } else {
                this.emitter.emit('checkout.shipping.infoblock.hide');
                this.onResponse(response);
            }

            if (response.order) {
                // Update order summary with new shipping price
                this.emitter.emit('action.summary.update', response);
            }
        }
    }

    onResponse(response) {
        // Clear previous events
        this.$el.off('change', SELECTORS.INPUT);

        // Show/Hide UPS shipping methods block
        this.$el.toggleClass(CLASSES.hide, response.service === 'DHL');
        this.replaceHtml(response.upsTemplate ? response.upsTemplate : '', this.$template);

        if (response.service === 'UPS') {
            // Bind new events to UPS radio buttons
            this.bindEvent('change', SELECTORS.INPUT, this.onChange.bind(this));
        }
    }

    hideUPSBlock() {
        // Clear previous events
        this.$el.off('change', SELECTORS.INPUT);

        this.$el.addClass(CLASSES.hide);
        this.replaceHtml('', this.$template);
    }
}
