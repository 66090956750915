import AbstractExpandedPaymentOptionTab from './AbstractExpandedPaymentOptionTab';
import { CLASSES } from 'client/utils/globals';
import ajax from 'client/utils/ajax';
import { scrollToMsg } from 'client/utils/common';
import $ from 'jquery';
import Handlebars from 'handlebars';
import handlebarsHelper from 'client/utils/handlebarsHelper';

const SELECTORS = {
    TAB: '.js-gift-card-tab',
    CONTENT: '.js-gift-card-content',
    PAYMENT_OPTION: '#paymentMethod_',
    CARD_FIELDS: '.js-gift-card-fields',
    GENERAL_ERROR: '.js-gcerror',
    APPLIED: '.js-giftCard-applied',
    APPLY_BTN: '.js-apply-gift-card',
    ADD_ANOTHER_BTN: '.js-add-another-giftcard',
    BILLING_FORM: 'form#dwfrm_billing',
    TARGET: '.js-target',
    CARD_NUMBER: '#dwfrm_giftCard_cardNumber'
};

const STATE_CLASSES = {
    active: 'b-checkout-giftcard-tab--active'
};

export default class GiftCardPaymentOption extends AbstractExpandedPaymentOptionTab {
    init() {
        super.init();

        handlebarsHelper(Handlebars);
        this.$target = this.$el.find(SELECTORS.TARGET);
        this.source = this.$el.find(SELECTORS.APPLIED).html();
        this.template = Handlebars.compile(this.source);
        this.tab = this.$el.find(SELECTORS.TAB);
        this.content = this.$el.find(SELECTORS.CONTENT);
        this.error = this.$el.find(SELECTORS.GENERAL_ERROR);
        this.applyBtn = this.$el.find(SELECTORS.APPLY_BTN);
        this.addAnotherBtn = this.$el.find(SELECTORS.ADD_ANOTHER_BTN);
        this.cardFields = this.$el.find(SELECTORS.CARD_FIELDS);
        this.billingForm = $(SELECTORS.BILLING_FORM);
        this.isGiftCardApplied = this.config.applied;
        this.fullyCoveredByGiftCard = this.config.fullyCovered;

        this.bindEvent('click', SELECTORS.APPLY_BTN, this.applyGiftCard.bind(this));
        this.bindEvent('click', SELECTORS.ADD_ANOTHER_BTN, this.addAnotherGiftCard.bind(this));
        this.bindEvent('change', () => this.removeGiftCard());
        this.bindEvent('mousewheel', SELECTORS.CARD_NUMBER, this.disableScrollAdjustment.bind(this));
        this.emitter.addListener('giftCard.removed', (r) => this.showGiftCardForm(r));
        this.emitter.addListener('giftCard.clear.form.if.tab.disabled', () => this.clearBeforePaymentStepSubmit());
    }

    clear() {
        this.$target.empty();
        this.isGiftCardApplied = false;
        this.addAnotherBtn.addClass(CLASSES.hide);
        this.clearFormFields();

        this.emitter.emit('giftCards.updated', {
            order: {
                billing: {
                    payment: {}
                }
            }
        });
    }

    clearBeforePaymentStepSubmit() {
        let state = this.$paymentOption.prop('checked');

        if (!state) {
            this.clear();
        }
    }

    toggleContent() {
        if (this.isLocked) {
            this.$paymentOption.prop('checked', false);
        }

        let state = this.$paymentOption.prop('checked');

        this.adjustFields();

        this.tab.toggleClass(STATE_CLASSES.active, state);
        this.content.toggleClass(CLASSES.hide, !state);
    }

    showError(errorMessage = this.config.textErrorCommon) {
        this.error
            .text(errorMessage)
            .removeClass(CLASSES.hide);

        scrollToMsg(this.error);
    }

    async applyGiftCard(el, ev) {
        ev.preventDefault();

        this.error.addClass(CLASSES.hide);

        let childrenAreValid = true;

        await this.iterateNestedComponents(async cmp => {
            if (typeof cmp.validate === 'function') {
                let validationResult = await cmp.validate();

                childrenAreValid = childrenAreValid ? validationResult : false;
            }
        }, true);

        if (!childrenAreValid) {
            return;
        }

        this.applyBtn.attr('disabled', 'disabled');

        let response;

        try {
            response = await ajax.getJson({
                url: this.applyBtn.data('action'),
                type: 'POST',
                data: this.billingForm.serializeArray()
            });

            if (response.error) {
                throw new Error(response.errorMessage);
            }
        } catch (err) {
            this.applyBtn.removeAttr('disabled');
            this.showError(err.message);

            return;
        }

        this.applyBtn.removeAttr('disabled');

        this.error.addClass(CLASSES.hide);
        let result = this.template([response]);

        this.cardFields.addClass(CLASSES.hide);
        this.appendHtml(result, this.$target);
        if (response.totalValue > 0) {
            (response.appliedGiftCards < 3) && this.addAnotherBtn.removeClass(CLASSES.hide);
        } else {
            this.addAnotherBtn.addClass(CLASSES.hide);
            this.emitter.emit('payment.coveredByGC', true);
        }
        this.isGiftCardApplied = true;
        this.emitter.emit('giftCards.updated', response);
        response.order && this.emitter.emit('action.summary.update', response);
    }

    showGiftCardForm(response) {
        if (response.error) {
            this.showError(response.errorMessage);
        } else {
            this.error.addClass(CLASSES.hide);
            if (response.appliedGiftCards === 0) {
                this.isGiftCardApplied = false;
                this.addAnotherBtn.addClass(CLASSES.hide);
                this.clearFormFields();
            } else {
                this.addAnotherBtn.removeClass(CLASSES.hide);
            }

            this.adjustFields();
        }
    }

    addAnotherGiftCard(el, ev) {
        ev.preventDefault();
        this.clearFormFields();
        this.addAnotherBtn.addClass(CLASSES.hide);
    }

    clearFormFields() {
        this.cardFields.find("[name*='giftCard']").val('');
        this.cardFields.find('.js-field-cmp.' + CLASSES.formFieldSuccess)
            .removeClass(CLASSES.formFieldSuccess);
        this.cardFields.removeClass(CLASSES.hide);
    }

    isSelected() {
        return this.isGiftCardApplied || this.$paymentOption.prop('checked');
    }

    adjustFields() {
        let checked = this.$paymentOption.prop('checked');

        this.config.requiredFields.forEach((cmpId) => {
            let gcInputComponent = this.getNestedComponentById(cmpId);

            gcInputComponent.config.required = checked;
            gcInputComponent.$el.toggleClass(CLASSES.formFieldRequired, checked);
        });
    }

    removeGiftCard() {
        const checked = this.$paymentOption.prop('checked');

        if (checked) return;

        this.$el.find('*[data-cmp="giftCardItem"]').each(async (index, element) => {
            const url = $(element).find('.js-remove-gift-card').attr('href');

            let response = await ajax.getJson({
                url
            });

            if (response.error) {
                throw new Error(response.errorMessage);
            }

            $(element).remove();
            this.emitter.emit('giftCard.removed', response);
            this.emitter.emit('payment.coveredByGC', false);
            this.emitter.emit('giftCards.updated', response);
            response.order && this.emitter.emit('action.summary.update', response);
        });
    }

    disableScrollAdjustment(ev) {
        ev.blur();
    }
}
