import Component from 'client/core/Component';
import ajax from 'client/utils/ajax';

const SELECTORS = {
    closeBtn: '.js-close-btn'
};

export default class Banner extends Component {
    onClose() {
        const url = this.$el.data('url');

        if (url) {
            ajax.getJson({
                url
            });
        }

        this.destroy();

        this.$el.remove();
    }

    init() {
        this.bindEvent('click', SELECTORS.closeBtn, this.onClose.bind(this));
    }
}
