import Component from 'client/core/Component';
import { isRNWebView, sendEventToMobileApp } from 'client/utils/react';

export default class CheckoutApplicationEvents extends Component {
    init () {
        // don't init component if it's not React Native Web View
        if (!isRNWebView()) { return; }

        if (this.config.returningCustomer) {
            sendEventToMobileApp({
                type: `checkout.finished.${this.config.authenticated ? 'authenticated' : 'registered'}`
            }, true);
        } else {
            sendEventToMobileApp({
                type: 'checkout.finished.guest'
            }, true);
        }
    }
}
