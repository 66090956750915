'use strict';
import $ from 'jquery';

$(document).ready(function () {
    var bvpixel = $('.bvdata').data('bvpixel');

    window.bvCallback = function (BV) {
        BV.pixel.trackTransaction(bvpixel);
    };
});
