import StoreFinderMapMgr from 'client/components/StoreFinderMapMgr';

import ajax from 'client/utils/ajax';
import { isAuthenticated } from 'sitePreferences';
import { hasCookie, getCookie, setCookie, removeCookie } from 'client/utils/cookie';
import { appendParamToURL } from 'client/utils/url';
import { CLASSES, COOKIE, GLOBAL_SELECTORS } from 'client/utils/globals';
import { isRNWebView } from 'client/utils/react';
import $ from 'jquery';
import { trackBopisStoreData } from 'client/utils/storefinderHelper';

const SELECTORS = {
    'ERROR_CONTAINER': 'js-error',
    'PRODUCT_SIZE_SELECT': '.js-find-in-store-product-size',
    'SELECTED_OPTION': '.js-field option:selected',
    'APP_FIND_IN_STORE_WRAPPER': '.js-app-find-in-store-wrapper'
};

const ATTRIBUTES = {
    DATA_FAVOURITE_STORE_ID: 'data-favourite-store-id',
    DATA_FAVOURITE_STORE_ADDRESS: 'data-favourite-store-address'
};

export default class FavouriteStoreButton extends StoreFinderMapMgr {
    init () {
        this.errorContainer = $(SELECTORS.ERROR_CONTAINER);
        this.bindEvent('click', this.$el, this.handleClick);
        this.selectStoreOnInit();
    }

    getSelectedVariantID() {
        return $(SELECTORS.PRODUCT_SIZE_SELECT).find(SELECTORS.SELECTED_OPTION).val();
    }

    async selectStoreOnInit() {
        const customerFavouriteStoreID = await this.getFavouriteStoreAttrValue('DATA_FAVOURITE_STORE_ID');

        if (this.config.storeId === customerFavouriteStoreID) {
            this.$el.addClass(CLASSES.active);
        } else {
            this.$el.removeClass(CLASSES.active);
        }
    }

    async handleClickForRegistered() {
        const url = appendParamToURL(this.config.saveUrl, 'storeID', this.config.storeId);

        try {
            const result = await ajax.getJson({ url: url, type: 'POST' });

            return result;
        } catch (e) {
            return false;
        }
    }

    handleClickForGuest() {
        const cookieName = COOKIE.favouriteStore;
        let cookieValue;
        const result = {
            success: true,
            savedStoreID: ''
        };

        if (hasCookie(cookieName)) {
            cookieValue = getCookie(cookieName);
        }

        if (cookieValue && cookieValue === this.config.storeId) {
            removeCookie(cookieName);
        } else {
            const additionalCookieData = {
                expires: new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 365)).toUTCString()
            };

            setCookie(cookieName, this.config.storeId, additionalCookieData);
            result.savedStoreID = this.config.storeId;
        }


        return result;
    }

    updatePDPOnModalClose() {
        const variantId = this.getSelectedVariantID();

        this.emitter.emit('product.attribute.clicked', {
            variantId: variantId
        });

        this.emitter.emit('modal.close', {
            id: this.config.cmp,
            params: {
                storeId: this.config.storeId,
                storeAddress: this.config.storeAddress
            }
        });
    }

    handleClickInApp() {
        const appStoreContainer = $(SELECTORS.APP_FIND_IN_STORE_WRAPPER);

        if (!appStoreContainer || !appStoreContainer.length) {
            return;
        }

        this.$el.toggleClass(CLASSES.active);

        const customerFavouriteStoreID = this.getFavouriteStoreAttrValue('DATA_FAVOURITE_STORE_ID');
        const newStoreID = this.config.storeId === customerFavouriteStoreID ? '' : this.config.storeId;
        const variantId = this.getSelectedVariantID();

        this.emitter.emit('favourite.store.updated', {
            params: {
                storeId: newStoreID,
                variantId
            }
        });

        appStoreContainer.attr(
            ATTRIBUTES.DATA_FAVOURITE_STORE_ID,
            newStoreID
        );

        appStoreContainer.attr(
            ATTRIBUTES.DATA_FAVOURITE_STORE_ADDRESS,
            (newStoreID ? this.config.storeAddress : '')
        );
    }

    async handleClick() {
        this.errorContainer.addClass(CLASSES.hide);

        if (isRNWebView() || this.isApp()) {
            this.handleClickInApp();
            //return true;
        }

        const result = isAuthenticated ? await this.handleClickForRegistered() : this.handleClickForGuest();

        if ((result && result.error) || !result) {
            this.errorContainer.removeClass(CLASSES.hide);
        } else {
            this.handleFSBActiveness(result.savedStoreID);

            $(GLOBAL_SELECTORS.mediaInteraction).attr(
                ATTRIBUTES.DATA_FAVOURITE_STORE_ID,
                (result.savedStoreID || '')
            );

            $(GLOBAL_SELECTORS.mediaInteraction).attr(
                ATTRIBUTES.DATA_FAVOURITE_STORE_ADDRESS,
                (result.savedStoreID ? this.config.storeAddress : '')
            );

            this.updatePDPOnModalClose();

            trackBopisStoreData({
                context: this,
                eventAction: result.savedStoreID ? 'pdp-layer/set-favorite-store' : 'pdp-layer/remove-favorite-store',
                storeID: result.savedStoreID || this.config.storeId
            });
        }

        return result;
    }
}
