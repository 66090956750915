'use strict';
/**
 * This validator is used for validation only for front-end
*/

import { getJson } from 'client/utils/ajax';
import { autocompleteServiseURL } from 'urls';
import {
    SVPCEnabled,
    postalCodeValidConfig } from 'sitePreferences';

function getPostalCodeConfig(country) {
    return (postalCodeValidConfig && postalCodeValidConfig[country]) ?
        postalCodeValidConfig[country] :
        postalCodeValidConfig.default || {};
}

function getData (config) {
    if (config.snipesValidatorIsEnabled === true) {
        return getJson({
            type: 'POST',
            url: config.url,
            async: config.async,
            data: config.data
        });
    } else {
        config.responseJSON = {
            action: 'AutocompleteServise-City',
            exists: true,
            locale: 'hr_HR',
            queryString: 'check=true&format=ajax',
            status: 'OK'
        };

        return config;
    }
}


function validatePostalCode (value, countryCode) {
    let resConfig;
    const { validationRule } = getPostalCodeConfig(countryCode);
    let regEx = new RegExp(validationRule);
    let isValidRegex = regEx.test(value);

    let config = {
        url: autocompleteServiseURL + '?check=true',
        snipesValidatorIsEnabled: SVPCEnabled,
        valid: null,
        async: false,
        data: {
            'format': 'json',
            'postalcode': value
        }
    };

    if (isValidRegex) {
        resConfig = getData(config);
    }

    return resConfig && resConfig.responseJSON && resConfig.responseJSON.exists;
}


export {
    validatePostalCode,
    getPostalCodeConfig
};
