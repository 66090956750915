import Component from 'client/core/Component';
import { CLASSES } from 'client/utils/globals';
import { scrollTo } from 'client/utils/common';
import $ from 'jquery';

const SELECTORS = {
    outer: '.js-outer-content',
    inner: '.js-inner-content',
    btnWrapper: '.js-more-btn-wrapper',
    expandBtn: '.js-expand-btn',
    compressBtn: '.js-compress-btn',
    seoExpandWrapper: '.js-expand-seo-wrapper',
    seoSlotWrapper: '.js-category-seo-slot-wrapper',
    accordionItem: '.js-accordion-item',
    accordionHead: '.js-feature-head',
    accordionBody: '.js-feature-body'
};

const STATE_CLASSES = {
    seoExpandWrapper: 'js-expand-seo-wrapper',
    compressScrollTop: 'js-compress-scroll-top',
    accordionBodyOpened: 'show'
};

export default class ExpandBlock extends Component {
    init() {
        this.inner = this.$el.find(SELECTORS.inner);
        this.outer = this.$el.find(SELECTORS.outer);
        this.btnWrapper = this.$el.find(SELECTORS.btnWrapper);

        if (this.$el.hasClass(STATE_CLASSES.seoExpandWrapper) && !this.outer.length) {
            this.$el.addClass(CLASSES.hide);
        }

        this.bindEvent('click', SELECTORS.expandBtn, this.expand);
        this.bindEvent('click', SELECTORS.compressBtn, this.compress);
        this.bindEvent(
            'click',
            SELECTORS.accordionHead,
            (el, ev) => this.recalcExpandHeightOnAccordionToggle(el, ev)
        );
    }

    expand() {
        const height = this.inner.innerHeight();

        this.outer.css('height', height);
        this.$el.addClass(CLASSES.expanded);
    }

    compress() {
        const height = this.btnWrapper.innerHeight();
        const offsetTop = +this.$el.data('compress-scroll-offset') ? +this.$el.data('compress-scroll-offset') : 0;

        if (this.$el.hasClass(STATE_CLASSES.compressScrollTop)) {
            scrollTo(this.$el.parent(), {
                offset: offsetTop,
                callback: () => {
                    this.removeExpandedValue(height);
                }
            });
        } else {
            this.removeExpandedValue(height);
        }
    }

    recalcExpandHeightOnAccordionToggle(el, ev) {
        const height = 200;
        const $accordionItem = $(ev.target).closest(SELECTORS.accordionItem);
        const $accordionItemBody = $accordionItem.find(SELECTORS.accordionBody);
        const accordionItemToBeClosed = $accordionItemBody.hasClass(STATE_CLASSES.accordionBodyOpened);
        const extraHeight = $accordionItemBody.innerHeight();

        if (accordionItemToBeClosed) {
            this.outer.css('height', (height - extraHeight));
        } else {
            this.outer.css('height', (height + extraHeight));
        }
    }

    removeExpandedValue(height) {
        this.outer.css('height', height);
        this.$el.removeClass(CLASSES.expanded);
        $(SELECTORS.accordionHead).addClass('collapsed');
        $(SELECTORS.accordionBody).removeClass('show');
    }

}
