import Component from 'client/core/Component';
import $ from 'jquery';
import { CLASSES } from 'client/utils/globals';

const
    SELECTORS = {
        tabHeader: '.js-store-locator-tab-header',
        resultsCount: '.js-store-locator-results-count',
        listViewButton: '.js-store-locator-list-view-button',
        mapViewButton: '.js-store-locator-map-view-button',
        listViewResults: '.js-store-locator-list-view-wrapper',
        mapViewResults: '.js-store-locator-map-view-wrapper',
        storeLocatorTabItems: '.js-store-locator-tab-item',
        storeLocatorTabButton: '.js-store-locator-tab-button',
        storeLocatorTabButtons: '.js-store-locator-tab-buttons'
    };

const
    STATE = {
        storeLocatorTabButtonActive: 'b-store-locator-tab-button--active',
        storeLocatorNoResults: 'b-store-locator-results-info--no-results'
    };

export default class StoreLocatorTabs extends Component {
    init () {
        this.$tabHeader = this.$el.find(SELECTORS.tabHeader);
        this.$resultsCount = this.$el.find(SELECTORS.resultsCount);
        this.$listViewResults = this.$el.find(SELECTORS.listViewResults);
        this.$mapViewResults = this.$el.find(SELECTORS.mapViewResults);
        this.$tabItems = this.$el.find(SELECTORS.storeLocatorTabItems);
        this.$tabButton = this.$el.find(SELECTORS.storeLocatorTabButton);
        this.$tabButtons = this.$el.find(SELECTORS.storeLocatorTabButtons);

        this.bindEvent('click', SELECTORS.listViewButton, (e) => this.tabViewModeHandle(e, this.$listViewResults));
        this.bindEvent('click', SELECTORS.mapViewButton, (e) => this.tabViewModeHandle(e, this.$mapViewResults));

        this.emitter.addListener('storeLocator.data.updated', this.onDataUpdated.bind(this));
        this.emitter.addListener('storeLocator.setView', this.onSetView.bind(this));
    }

    onSetView(view = 'map') {
        this.$el.find((view === 'map') ? SELECTORS.mapViewButton : SELECTORS.listViewButton).click();
    }

    onDataUpdated(data) {
        if (data.noResults) {
            this.$el.find(SELECTORS.mapViewButton).click();

            const $tabHeader = this.$tabHeader;

            $tabHeader.html($tabHeader.data('no-results')).removeClass(CLASSES.hide)
            .addClass(STATE.storeLocatorNoResults);
            this.$tabButtons.addClass(CLASSES.hide);
        } else {
            this.$el.find(SELECTORS.listViewButton).click();

            this.$resultsCount.html(data.storesResultsMesssage);
            this.$tabButtons.removeClass(CLASSES.hide);
            this.$tabHeader.addClass(CLASSES.hide).removeClass(STATE.storeLocatorNoResults);
        }
    }

    tabViewModeHandle(source, el) {
        for (let i = 0; i < this.$tabButton.length; i++) {
            if ($(this.$tabButton[i]).hasClass(STATE.storeLocatorTabButtonActive)) {
                $(this.$tabButton[i]).removeClass(STATE.storeLocatorTabButtonActive);
            }
        }

        for (let i = 0; i < this.$tabItems.length; i++) {
            if (!$(this.$tabItems[i]).hasClass(CLASSES.hide)) {
                $(this.$tabItems[i]).addClass(CLASSES.hide);
            }
        }

        $(source).addClass(STATE.storeLocatorTabButtonActive);
        $(el).removeClass(CLASSES.hide);

        this.emitter.emit('storeLocator.view.updated');
    }
}

