/* eslint-disable class-methods-use-this */
/* eslint-disable no-restricted-globals */
import Component from 'client/core/Component';
import $ from 'jquery';
import ajax from 'client/utils/ajax';
import { CLASSES } from 'client/utils/globals';

const SELECTORS = {
    'QTY': '.js-line-item-qty-selector',
    'REMOVE_BTN': '.js-remove-line-item-btn',
    'CONTENT': '.js-line-item-content',
    'FOOTER_TOTAL': '.js-line-item-total',
    'FOOTER_LOYALTY': '.js-line-item-loyalty-info',
    'REMOVE_CONFIRMATION': '.js-line-item-remove-confirmation',
    'REMOVE_CONFIRMATION_ACTION': '.js-remove-confirmation-action',
    'PRODUCT_LINK': '.js-product-link'
};

export default class CartLineItem extends Component {
    init () {
        this.$content = this.$el.find(SELECTORS.CONTENT);
        this.$footerTotal = this.$el.find(SELECTORS.FOOTER_TOTAL);
        this.$footerLoyalty = this.$el.find(SELECTORS.FOOTER_LOYALTY);
        this.$removeConfirmation = this.$el.find(SELECTORS.REMOVE_CONFIRMATION);

        this.bindEvent('change', SELECTORS.QTY, this.onQuantityChange);
        this.bindEvent('click', SELECTORS.REMOVE_BTN, this.showRemoveConfirmation);
        this.bindEvent('click', SELECTORS.REMOVE_CONFIRMATION_ACTION, (el) => this.onItemRemoveConfirm(el));

        if (this.gtmEnabled) {
            this.bindEvent('click', SELECTORS.PRODUCT_LINK, this.trackProductClick.bind(this));
        }
    }

    trackProductClick() {
        const product = this.config.gtm;

        if (product) {
            const index = isNaN(this.config.index) ? 0 : Number(this.config.index),
                position = index + 1;

            const eventData = {
                'currencyCode': window.SitePreferences.currencyCode,
                'click': {
                    'actionField': {
                        'list': `${this.gtmPageType}/${this.config.list}`
                    },
                    'products': [
                        Object.assign({ position: position }, product)
                    ]
                }
            };

            this.emitter.emit('gtmEcommerceEvent', 'eeProductClick', eventData);
        }
    }

    onQuantityChange(el) {
        let $el = $(el),
            selectedQty = $el.val();

        this.itemAction($el.data('action'), {
            pid: $el.data('pid'),
            quantity: selectedQty,
            uuid: $el.data('uuid')
        }, response => {
            if (response.isGiftCardAmountExceed) {
                let selectTitle = $el.siblings('.js-custom-select').find('.js-custom-select-title');

                selectTitle.text(response.qtyAlreadyInCart);
                this.emitter.emit('giftcard.popup.show', response);
            } else {
                this.emitter.emit('cart.updated', response);
            }
        });
    }

    trackRemoveFromBasket(data) {
        if (data.gtm) {
            let product = data.gtm;

            if (typeof product === 'string') {
                try {
                    product = JSON.parse(product);
                } catch (e) {
                    return;
                }
            }

            const qty = this.$el.find(SELECTORS.QTY).val();

            const eventData = {
                'currencyCode': window.SitePreferences.currencyCode,
                'remove': { // 'add' actionFieldObject measures.
                    'products': [Object.assign({ quantity: Number(qty || 1) }, product)]
                }
            };

            this.emitter.emit('gtmEcommerceEvent', 'eeRemoveFromBasket', eventData);
        }
    }

    onItemRemoveConfirm(el) {
        let $el = $(el);

        if ($el.data('remove') === true) {
            this.itemAction($el.data('action'), {
                pid: $el.data('pid'),
                uuid: $el.data('uuid')
            }, response => {
                if (this.gtmEnabled) {
                    this.trackRemoveFromBasket(response);
                }
                this.emitter.emit('trbo.remove.item', response.basket);
                this.emitter.emit('cart.updated', response.basket, this.config.list);
            });
        }

        this.hideRemoveConfirmation();
    }

    itemAction (url, data, callback) {
        let promise = ajax.getJson({
            url: url,
            data: data
        });

        promise.then(response => callback(response));
    }

    showRemoveConfirmation() {
        this.$content.addClass(CLASSES.hide);
        this.$footerTotal.addClass(CLASSES.hide);
        this.$footerLoyalty.addClass(CLASSES.hide);
        this.$removeConfirmation.removeClass(CLASSES.hide);
    }

    hideRemoveConfirmation() {
        this.$content.removeClass(CLASSES.hide);
        this.$footerTotal.removeClass(CLASSES.hide);
        this.$footerLoyalty.removeClass(CLASSES.hide);
        this.$removeConfirmation.addClass(CLASSES.hide);
    }
}
