import $ from 'jquery';
import Carousel from 'client/components/Carousel';

const DEFAULT_CONFIG = {
    dots: false,
    infinite: true,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: false,
    draggable: false
};

const SELECTORS = {
    carousel: '.js-plp-swatch-carousel',
    main: '.js-swatch-carousel-placeholder'
};

export default class SwatchCarousel extends Carousel {
    onUpdateContent($tileWrapper, uuid) {
        if (this.config.disableCarousel) {
            return;
        }

        this.id = 'swatchCarousel_' + uuid;

        var currentID = 'swatchCarousel_' + uuid;

        if (currentID === this.id) {

            this.$el = $tileWrapper.find(SELECTORS.main);

            if (!this.$el.length) {
                return;
            }

            this.init();
        }
    }

    // eslint-disable-next-line consistent-return
    init() {
        let config = Object.assign({}, DEFAULT_CONFIG);
        let carousel = this.$el.find(SELECTORS.carousel);

        if (!('slick' in $.fn) || carousel.hasClass('slick-initialized')) {
            return false;
        }

        carousel.on('init', () => {
            this.emitter.emit('namespace.component.initall', this.$el);
        });

        this.emitter.addListener('on.click.update.conent',
            ($content, uuid) => this.onUpdateContent($content, uuid)
        );

        carousel.slick(config);
    }
}
