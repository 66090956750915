import Component from 'client/core/Component';
import { CLASSES } from 'client/utils/globals';

export default class StickyPanel extends Component {
    init() {
        this.emitter.addListener('show.sticky.panel', () => this.showStickyPanel());
        this.emitter.addListener('hide.sticky.panel', () => this.hideStickyPanel());
    }

    showStickyPanel() {
        if (!this.$el.hasClass(CLASSES.stickyPanelShow)) {
            this.$el.addClass(CLASSES.stickyPanelShow);
        }
    }

    hideStickyPanel() {
        if (this.$el.hasClass(CLASSES.stickyPanelShow)) {
            this.$el.removeClass(CLASSES.stickyPanelShow);
        }
    }
}
