import Component from 'client/core/Component';
import { CLASSES as GLOBAL_CLASSES } from 'client/utils/globals';
import $ from 'jquery';

const CLASSES = {
    indexBtn: '.js-index-btn',
    resetBtn: '.js-reset-btn',

    activeLetter: 'b-brand-refinement-button--active'
};

export default class BrandsMgr extends Component {
    init () {
        this.brandTilesCmp = this.getNestedComponentById('brandTiles');

        if (!this.brandTilesCmp) { return; }

        this.brands = this.config.brands;

        this.$letters = this.$el.find(CLASSES.indexBtn);
        this.$resetBtn = this.$el.find(CLASSES.resetBtn);

        this.bindEvent('click', CLASSES.indexBtn, this.onIndexClick);
        this.bindEvent('click', CLASSES.resetBtn, this.onResetClick);
    }

    onIndexClick (el) {
        let $el = $(el);

        this.$letters.removeClass(CLASSES.activeLetter);

        $el.addClass(CLASSES.activeLetter);

        this.showResetBtn();

        this.renderTilesAppropriateLetter($el.data('letter'));
    }

    onResetClick () {
        this.$letters.removeClass(CLASSES.activeLetter);
        this.hideResetBtn();
        this.renderTilesAppropriateLetter();
    }

    hideResetBtn () {
        this.$resetBtn.addClass(GLOBAL_CLASSES.hide);
    }

    showResetBtn () {
        this.$resetBtn.removeClass(GLOBAL_CLASSES.hide);
    }

    renderTilesAppropriateLetter (letter = '') {
        letter += '';

        this.brandTilesCmp.render(
            letter ?
                this.brands.filter(brand => brand.letter === letter) :
                this.brands
        );
    }
}
