import { regions } from 'sitePreferences';

const defaultRegion = 'europe';

class Localization {
    getRegionByCountry (country) {
        let found = null;
        let regionKeys = Object.keys(regions);

        for (const region of regionKeys) {
            if (regions[region].indexOf(country) !== -1) {
                found = region;
                break;
            }
        }

        return found;
    }

    getDefaultRegion () {
        return defaultRegion;
    }
}

const localization = new Localization();

export default localization;
