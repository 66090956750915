import InputText from './InputText';

import {
    PASSWORD_STRENGTH_NOT_SAFE,
    PASSWORD_STRENGTH_OK,
    PASSWORD_STRENGTH_SAFE
} from 'resources';

const SELECTORS = {
    strengthBar: '.js-strength_bar',
    strengthMessage: '.js-strength_message',
    inputField: '.js-field'
};

const STRENGTH_ENUM = {
    EMPTY: 0,
    WEEK: 1,
    OK: 2,
    SAFE: 3
};

const VALIDATION_RULES = {
    occurrences: {
        0: STRENGTH_ENUM.EMPTY,
        1: STRENGTH_ENUM.WEEK,
        2: STRENGTH_ENUM.WEEK,
        3: STRENGTH_ENUM.OK,
        4: STRENGTH_ENUM.OK,
        5: STRENGTH_ENUM.SAFE,
        6: STRENGTH_ENUM.SAFE
    },
    rules: {
        positive: [
            /[a-z]/,
            /[A-Z]/,
            /\d/,
            /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/,
            /.{10}/
        ],
        negative: [
            /(.)\1/
        ]
    }
};

const STRENGTH_MESSAGES = {
    [STRENGTH_ENUM.EMPTY]: '',
    [STRENGTH_ENUM.WEEK]: PASSWORD_STRENGTH_NOT_SAFE,
    [STRENGTH_ENUM.OK]: PASSWORD_STRENGTH_OK,
    [STRENGTH_ENUM.SAFE]: PASSWORD_STRENGTH_SAFE
};


/**
 * Config (data attribute):
 * - strength-color-not-checked {string} Optional. CSS class for "not checked" state
 * - strength-color-not-safe {string} Optional. CSS class for "not safe" state
 * - strength-color-ok {string} Optional. CSS class for "OK" state
 * - strength-color-safe {string} Optional. CSS class for "safe" state
 * - password-cmp-id {string} Required. ID of the password (input) component which is inside of this one
 */
export default class PasswordStrength extends InputText {
    init () {
        super.init();

        this.currentStrength = STRENGTH_ENUM.EMPTY;

        this.$bar = this.$el.find(SELECTORS.strengthBar);
        this.$message = this.$el.find(SELECTORS.strengthMessage);

        this.COLOR_CLASSES = {
            [STRENGTH_ENUM.WEEK]: (this.config.strengthColorNotSafe || ''),
            [STRENGTH_ENUM.OK]: (this.config.strengthColorOk || ''),
            [STRENGTH_ENUM.SAFE]: (this.config.strengthColorSafe || '')
        };

        this.emitter.addListener('password.strength.clear', () => this.onKeyUp());
        this.availableColorClasses = Object.values(this.COLOR_CLASSES).filter(c => c.length).join(' ');

        this.getNestedComponentById(
            this.config.passwordCmpId,
            cmp => {
                cmp.bindEvent('blur', SELECTORS.inputField, this.placeholderToggle.bind(this));
                cmp.bindEvent('keyup', SELECTORS.inputField, this.onKeyUp.bind(this));
            }
        );
    }

    onKeyUp () {
        let value = this.getValue();

        if (typeof value !== 'string' && typeof value !== 'number') {
            return;
        }

        let rulesCount = 0;

        if (value.toString()) {
            for (let rule of VALIDATION_RULES.rules.positive) {
                if (rule.test(value)) {
                    rulesCount++;
                }
            }

            for (let rule of VALIDATION_RULES.rules.negative) {
                if (!rule.test(value)) {
                    rulesCount++;
                }
            }
        }

        let strength = VALIDATION_RULES.occurrences[rulesCount],
            color = this.COLOR_CLASSES[strength];

        if (this.currentStrength === strength) {
            return;
        }

        this.$bar.removeClass(this.availableColorClasses).addClass(color);
        this.$message.removeClass(this.availableColorClasses).addClass(color).text(STRENGTH_MESSAGES[strength]);
        this.currentStrength = strength;
    }

    async validate () {
        let inputCmp = this.getNestedComponentById(this.config.passwordCmpId);

        if (!inputCmp || typeof inputCmp.validate !== 'function') {
            return false;
        }

        return inputCmp.validate();
    }

    clearError() {
        super.clearError();

        this.getNestedComponentById(this.config.passwordCmpId).clearError();
    }

    placeholderToggle() {
        super.placeholderToggle();

        this.getNestedComponentById(this.config.passwordCmpId).placeholderToggle();
    }
}
