export async function getLocation() {
    if (!navigator.geolocation) {
        throw new Error('The browser is not able to detect user\'s location');
    }

    let permissionStatus = 'allowed';

    if (navigator.permissions) {
        permissionStatus = await navigator.permissions.query({ name: 'geolocation' });

        if (permissionStatus.state === 'denied') {
            throw new Error('The browser is prohibited to use the location API');
        }
    }

    return new Promise((res, rej) => {
        navigator.geolocation.getCurrentPosition(position => res(position), e => rej(e));
    });
}
