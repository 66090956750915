import Modal from 'client/components/Modal';
import { load } from 'client/utils/ajax';
import { login } from 'urls';
import { isRNWebView } from 'client/utils/react';
import { LOGIN_POPUP_TITLE, REGISTER_POPUP_TITLE } from 'resources';

export default class LoginPopup extends Modal {
    init() {
        if (isRNWebView()) {
            return;
        }

        var config = {
            title: LOGIN_POPUP_TITLE,
            body: '',
            cssClass: 'b-login-popup'
        };

        this.globalParams = {};

        this.config = Object.assign({}, config, this.config);
        this.emitter.addListener('login.popup.show', (params) => this.showModal(params));
        this.emitter.addListener('login.popup.hide', () => this.hideModal());

        super.init();
    }

    showModal(params) {
        this.globalParams = params;
        let getParams = '';

        if (params && params.isCustomLoginBtn) {
            this.config.title = REGISTER_POPUP_TITLE;
            getParams = '?customlogin=true';
        } else {
            this.config.title = LOGIN_POPUP_TITLE;
        }

        var promise = load({
            url: login + getParams,
            type: 'GET'
        });

        promise.then(response => {
            this.config.body = response;
            super.showModal();
        });
    }

    hideModal() {
        if (this.globalParams && this.globalParams.callback) {
            this.globalParams.callback(this.globalParams);
        }
        super.close();
        this.emitter.emit('account.header.reinit');
        this.activateWishListTiles();
    }

    // eslint-disable-next-line class-methods-use-this
    activateWishListTiles() {
        window.SitePreferences.isAuthenticated = true;
    }
}
