import Component from 'client/core/Component';
import { scrollTo } from 'client/utils/common';

import $ from 'jquery';

export default class BlockReviewAnchorUrl extends Component {

    init() {
        this.$reviewAnchorElement = $(window.location.hash);
        window.location.hash = '';

        if (this.$reviewAnchorElement.length) {
            $(document).ready(() => scrollTo(this.$reviewAnchorElement));
        }
    }
}
