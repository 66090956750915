import Component from 'client/core/Component';

export default class DataLink extends Component {
    init() {
        if (this.config.href) {
            this.bindEvent('click', (el, ev) => this.onClick(el, ev));
        }
    }

    onClick(el, ev) {
        ev && ev.preventDefault();
        ev && ev.stopPropagation();

        window.location = this.config.href;
    }
}
