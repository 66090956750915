import Component from 'client/core/Component';
import { CLASSES } from 'client/utils/globals';
import MenuItem from 'client/model/MenuItem';
import prefs from 'sitePreferences';
import $ from 'jquery';
import { isTablet, refresh } from 'client/utils/mobileDetect';

const SELECTORS = {
    'CONTENT': '.js-mobile-menu-content',
    'OVERLAY': '.js-mobile-menu-overlay',
    'CLOSE_BTN': '.js-menu-close',
    'BOTTOM_CLOSE_BTN': '.js-bottom-close',
    'TITLE': '.js-menu-title',
    'WRAPPER': '.js-mobile-menu-wrapper',
    'LIST_SHOW': '.js-list-show',
    'LIST_HIDE': '.js-list-hide',
    'LIST_CONTENT': '.js-list-content',
    'LIST_ITEM': '.js-list-item',
    'SHOW_ALL_RESULTS_BTN': '.js-bottom-show-all-results'
};

const STATE_CLASSES = {
    'OVERLAY_SHOW': 'b-mobile-menu-overlay--show',
    'BUTTON_INVISIBLE': 'b-mobile-menu-close-button-bottom--invisible'
};

export default class MobileMenu extends Component {
    init (reInit = false) {
        this.$content = this.$el.find(SELECTORS.CONTENT);
        this.$overlay = this.$el.find(SELECTORS.OVERLAY);
        this.$title = this.$el.find(SELECTORS.TITLE);
        this.$wrapper = this.$el.find(SELECTORS.WRAPPER);
        this.$backButton = this.$el.find(SELECTORS.LIST_HIDE);
        this.$bottomCloseButton = this.$el.find(SELECTORS.BOTTOM_CLOSE_BTN);
        this.$showAllResultButton = this.$el.find(SELECTORS.SHOW_ALL_RESULTS_BTN);
        this.listElements = this.getListElements();

        this.isOpen = false;
        this.triggeredMenuItemLocked = false;
        this.menuItemLocked = false;
        this.openElements = [];

        if (!reInit) {
            this.emitter.addListener(`mobileMenu.${this.config.type}.open`, () => this.open());
            this.bindEvent('click', `${SELECTORS.OVERLAY},${SELECTORS.CLOSE_BTN}`, () => this.close());
            this.bindEvent('click', SELECTORS.LIST_SHOW, (el, ev) => this.pullLeft(el, ev));
            this.bindEvent('click', SELECTORS.LIST_HIDE, () => this.pullRight());
            this.bindEvent('click', SELECTORS.TITLE, () => this.processTitleLink());
            this.emitter.addListener('deviceChange', this.setHeight.bind(this));
            this.emitter.addListener('resize', this.setHeight.bind(this));
            this.emitter.addListener('orientationChange', this.setHeight.bind(this));
            this.emitter.addListener('orientationChange', () => this.toggleScroll(true));
            // eslint-disable-next-line function-call-argument-newline, function-paren-newline
            this.bindEvent('transitionend webkitTransitionEnd oTransitionEnd', SELECTORS.WRAPPER,
                this.wrapperTransitionEnd.bind(this)
            );

            this.emitter.addListener('refinement.applyFilter', () => {
                this.menuItemLocked = true;
            });
            this.emitter.addListener('refinement.updated', () => {
                this.menuItemLocked = false;
            });
        }

        this.setTitle(this.config.menuTitle);
    }

    getListElements() {
        let $elements = this.$el.find(SELECTORS.LIST_ITEM),
            items = new Map(); // eslint-disable-line no-undef

        $elements.each((index, el) => {
            let item = new MenuItem($(el));

            items.set(item.getId(), item);
        });

        return items;
    }

    open() {
        this.setHeight();
        this.$overlay.addClass(STATE_CLASSES.OVERLAY_SHOW);
        this.emitter.emit('disabledScroll');
        this.isOpen = true;
        if (this.$backButton.hasClass(CLASSES.hide)) {
            this.$content.find(SELECTORS.CLOSE_BTN).first().focus();
        } else {
            this.$backButton.focus();
        }
    }

    close() {
        this.$overlay.removeClass(STATE_CLASSES.OVERLAY_SHOW);
        this.emitter.emit('enabledScroll');
        this.isOpen = false;
    }

    setHeight() {
        this.$content.css('min-height', `${$(window).innerHeight()}px`);
    }

    setTitle(title, link = null, name = null) {
        this.$title.html(title);

        this.$title.data('href', link);
        this.$title.data('name', name);
    }

    processTitleLink() {
        let href = this.$title.data('href');

        if (href) {
            // eslint-disable-next-line no-restricted-globals
            location.href = href;
        }
    }

    toggleButtonState() {
        if (this.openElements.length > 0) {
            this.$backButton.removeClass(CLASSES.hide);
            this.$bottomCloseButton.addClass(STATE_CLASSES.BUTTON_INVISIBLE);
        } else {
            this.$backButton.addClass(CLASSES.hide);
            this.$bottomCloseButton.removeClass(STATE_CLASSES.BUTTON_INVISIBLE);
        }
    }

    pullLeft(el, event) {
        event.preventDefault();
        if (!this.triggeredMenuItemLocked && !this.menuItemLocked) {
            this.triggeredMenuItemLocked = true;

            this.$showAllResultButton.addClass('h-hide');

            const $el = $(el),
                $listItem = $el.closest(SELECTORS.LIST_ITEM).first(),
                list = this.listElements.get($listItem.data('listId'));

            if (list) {
                this.openElements.push(list);

                list.show();
                this.$wrapper.css('transform', `translateX(-${100 * this.openElements.length}%)`);
                this.setTitle(list.getTitle(), $el.data('href'), $el.data('listName'));
                this.toggleButtonState();
            }
        }
    }

    pullRight() {
        this.$showAllResultButton.removeClass('h-hide');

        let cssDelay = this.$wrapper.css('transition-duration'),
            item = this.openElements.pop(),
            delay = +(cssDelay.substring(0, cssDelay.length - 1)) * 1000;

        if (this.openElements.length > 1) {
            let lastItem = this.getLastOpenedItem();

            this.setTitle(lastItem.getTitle());
            lastItem.show(this.openElements.length);
        } else {
            this.setTitle(this.config.menuTitle);
        }

        this.$wrapper.css('transform', `translateX(-${100 * this.openElements.length}%)`);
        setTimeout(() => {
            if (item) {
                item.hide();
            }
        }, delay);

        this.toggleButtonState();
    }

    wrapperTransitionEnd() {
        this.triggeredMenuItemLocked = false;
    }

    pullAllRight() {
        do {
            this.pullRight();
        } while (this.openElements.length > 0);
    }

    getLastOpenedItem() {
        if (this.openElements.length > 0) {
            return this.openElements[this.openElements.length - 1];
        }

        return null;
    }

    toggleScroll(isChange) {
        if (isChange) {
            refresh();
        }

        const windowWidth = $(window).innerWidth();
        const tablet = isTablet();

        if (this.isOpen && tablet && windowWidth > prefs.breakpointMedium) {
            this.emitter.emit('enabledScroll');
        } else if (this.isOpen) {
            this.emitter.emit('disabledScroll');
        }
    }
}
