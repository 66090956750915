import Component from 'client/core/Component';
import { CLASSES } from 'client/utils/globals';
import $ from 'jquery';
import { isInViewport } from 'client/utils/common';
import { throttle } from 'lodash';

const SELECTORS = {
    count: '.js-wishlist-count',
    data: '.js-wishlist-data',
    empty: '.js-wishlist-empty',
    productCard: '.js-wishlist-product-card',
    productTile: '.js-product-tile',
    productTileLink: '.js-product-tile-link'
};

export default class Wishlist extends Component {
    onRemoved() {
        const itemsCount = Number(this.$.count.data('count')) - 1;

        if (itemsCount > 0) {
            this.$.count.data('count', itemsCount);
            this.$.count.html(
                itemsCount === 1 ?
                this.$.count.data('text-one') :
                this.$.count.data('text').replace('[count]', itemsCount)
            );
        } else {
            this.$.data.addClass(CLASSES.hide);
            this.$.empty.removeClass(CLASSES.hide);
        }
    }

    init() {
        this.$ = {};

        for (let k of Object.keys(SELECTORS)) {
            this.$[k] = this.$el.find(SELECTORS[k]);
        }

        this.emitter.addListener('wishlist.removed', this.onRemoved.bind(this));

        if (this.gtmEnabled) {
            if (document.readyState === 'complete') {
                this.trackProductImpression();
            } else {
                $(window).on('load', this.trackProductImpression.bind(this));
            }

            this.emitter.addListener('orientationChange', this.trackProductImpression.bind(this));
            this.emitter.addListener('resize', this.trackProductImpression.bind(this));
            this.emitter.addListener('scroll', throttle(this.trackProductImpression.bind(this), 200));
            this.bindEvent('click', SELECTORS.productTileLink, (el, ev) => { this.trackProductClick(el, ev); });
        }
    }

    getProductData($el) {
        const product = $el.data('gtm');

        if (product) {
            const eventData = Object.assign({ list: `${this.gtmPageType}/Wishlist` }, product);

            return eventData;
        }

        return null;
    }

    trackProductImpression() {
        const $productTile = this.$el.find(SELECTORS.productCard);

        if (!$productTile.length) {
            return;
        }

        const impressions = [];

        $productTile.each((idx, el) => {
            const $el = $(el);

            if ($el.data('tracked') || !isInViewport($el)) {
                return;
            }

            const data = this.getProductData($el);

            if (data) {
                const position = $el.data('position');

                impressions.push(Object.assign({ position: position || 1 }, data));
            }

            $el.data('tracked', '1');
        });

        if (impressions.length) {
            const eventData = Object.assign(
                { currencyCode: window.SitePreferences.currencyCode },
                { impressions: impressions }
             );

            this.emitter.emit('gtmEcommerceEvent', 'eeProductImpression', eventData);
        }
    }

    trackProductClick(el) {
        const $el = $(el);
        const $productTile = $el.closest(SELECTORS.productCard);
        const product = $productTile.length ? $productTile.data('gtm') : null;

        if (product) {
            const position = $el.closest(SELECTORS.tileContainer).data('position');

            const eventData = Object.assign(
                { currencyCode: window.SitePreferences.currencyCode },
                {
                    'click': {
                        'actionField': {
                            'list': 'Wishlist/Wishlist'
                        },
                        'products': [
                            Object.assign({ position: position || 1 }, product)
                        ]
                    }
                }
            );

            this.emitter.emit('gtmEcommerceEvent', 'eeProductClick', eventData);
        }
    }
}
