import BaseShippingMethod from 'client/components/checkout/shipping/ShippingMethod';
import ajax from 'client/utils/ajax';
import $ from 'jquery';
import { appendParamsToURL } from 'client/utils/url';
import localization from 'buffalo/utils/localization';
import { CLASSES } from 'client/utils/globals';

const SELECTORS = {
    METHOD: '.js-shipping-method',
    INPUT: '.js-shipping-method-input'
};

export default class ShippingMethod extends BaseShippingMethod {
    init() {
        this.$selectedMethod = this.getSelectedMethod();
        this.$shippingMethodList = this.$el.find(SELECTORS.METHOD);

        if (this.$selectedMethod && this.$selectedMethod.length > 0) {
            this.methodID = this.$selectedMethod.data('shipping-method');
        }

        this.bindEvent('click', SELECTORS.METHOD, (el, ev) => this.select(el, ev));
        this.emitter.addListener('step.shipping.address.changed', data => this.onAddressChanged(data));
    }

    async onAddressChanged(data) {
        if (this.methodID && ['pick-up-station'].indexOf(this.methodID.split('_')[0]) !== -1) {
            return;
        }

        if (!data.addressType) {
            return;
        }

        let isNewAddress = data.id === 'new';
        let url = appendParamsToURL(this.config.updateUrl, {
            'addressType': data.addressType,
            'newAddress': isNewAddress,
            'country': data.countryCode
        });

        if (isNewAddress) {
            this.emitter.emit('shippingRates.ups.hide');
        }

        let response = await ajax.getJson({ url: url, type: 'POST' });

        this.emitter.emit('checkout.shipping.methods.updated', response);

        if (isNewAddress && response && response.order) {
            this.emitter.emit('action.summary.update', response);
        }

        setTimeout(() => {
            if (this.$el.find(SELECTORS.METHOD).length > 1) {
                this.$el.parent().removeClass(CLASSES.hide);
            } else {
                this.$el.parent().addClass(CLASSES.hide);
            }
        });
    }

    getMatchingMethod(data) {
        let $list = this.$el.find(SELECTORS.METHOD);
        let $matching = [];
        let region = localization.getRegionByCountry(data.countryCode);
        let method = `${data.addressType}_${region}`;

        for (let item of $list) {
            let $item = $(item);

            if ($item.data('shipping-method') === method) {
                $matching = $item;
                break;
            }
        }

        return $matching;
    }

    select(el, ev, silentUpdate = false) {
        let changedByEvent = false;

        if (ev) {
            typeof ev.preventDefault === 'function' && ev.preventDefault();
            changedByEvent = true;
        }

        let $el = $(el);

        if (this.$selectedMethod === null || !this.$selectedMethod.is($el)) {
            this.toggleState($el);

            this.selectMethod(
                $el.data('selected-carrier-id') || $el.data('shipping-method'),
                $el.data('shipment-uuid'),
                $el.data('shipment-method-name'),
                changedByEvent,
                silentUpdate
            );
        }
    }

    async selectMethod(methodID, shipmentUUID, shipmentMethodName, changedByEvent, silentUpdate = false) {
        this.methodID = methodID;

        if (methodID && shipmentUUID && !this.config.addressBook && !silentUpdate) {
            let response = await ajax.getJson({
                url: this.config.selectShippingMethodUrl,
                data: { methodID, shipmentUUID },
                type: 'POST'
            });

            this.emitter.emit('step.shipping.updated', response);
            this.emitter.emit('action.summary.update', response);
        }

        this.emitter.emit('step.shipping.method.changed', {
            id: methodID,
            changedByEvent: changedByEvent,
            shipmentMethodName: shipmentMethodName
        });
    }

    toggleState($el) {
        this.$el.find(SELECTORS.METHOD).each((idx, el) => {
            let $item = $(el);

            $item.data('selected', false);
            $item.removeClass(CLASSES.selected);
        });

        super.toggleState($el);
    }
}
