import Component from 'client/core/Component';
import $ from 'jquery';
import { load } from 'client/utils/ajax';
import { accountHeader } from 'urls';
import { CLASSES } from 'client/utils/globals';
import { isAuthenticated, isWishListEnabled } from 'sitePreferences';
import templatesManager from 'client/utils/templatesManager';

const SELECTORS = {
    wishlistQuantity: '.js-wishlist-quantity',
    wishlistProducts: '.js-wishlist-info',
    wishListTiles: '.js-wish-list-btn',
    logoutLink: '.js-logout-link',
    wishlistBtn: '.js-wish-list-btn',
    wishlistIcon: '.js-wishlist-icon',
    wlMobileContainer: '.js-mobile-account-header-ajax-container',
    productTile: '.js-product-tile',
    productTiles: '.js-product-tiles',
    productDetails: '.js-product-details',
    modalCartEdit: '.js-modal-cart-edit'
};

const WISHLIST_CLASSES = {
    wishlistAdd: 'js-wish-list--add',
    wishlistRemove: 'js-wish-list--remove',
    iconWishlistAdded: 'i-wishlist--added',
    wlMobileContainer: 'js-mobile-account-header-ajax-container'
};

export default class AccountHeader extends Component {
    onWishlistUpdated(data) {
        this.$wishlistQuantity = this.$el.find(SELECTORS.wishlistQuantity);
        this.$wishlistProducts = this.$el.find(SELECTORS.wishlistProducts);

        if (data.wishlistItemsCount) {
            this.$wishlistQuantity.addClass(CLASSES.productAdded);
        } else {
            this.$wishlistQuantity.removeClass(CLASSES.productAdded);
        }

        this.$wishlistQuantity.toggleClass(CLASSES.hide, !data.wishlistItemsCount);
        this.$wishlistQuantity.html(data.wishlistItemsCount || 0);

        let products = this.$wishlistProducts.data('wishlistProducts');

        if (products) {
            products = products.split(',');

            if (data.action === 'Wishlist-RemoveProduct') {
                products.splice(products.indexOf(data.pid), 1);
            } else {
                products.push(data.pid);
            }

            this.$wishlistProducts.data('wishlistProducts', products.join(','));
        }
        if (this.gtmEnabled) {
            this.trackWishlistAdded(data);
        }
    }

    trackLogout() {
        const data = {
            eventCategory: 'account',
            eventAction: 'logout',
            eventLabel: '',
            ga4eventname: 'account_logout'
        };

        this.emitter.emit('gtmEvent', data);
    }

    getProductData($el) {
        const product = $el.data('gtm');

        if (product) {
            const eventData = Object.assign({ list: `${this.gtmPageType}/Wishlist` }, product);

            return eventData;
        }
        return null;
    }

    trackWishlistAdded(data) {
        let addedFrom;
        let $productTile;

        switch (data.addedFrom) {
            case 'pdp':
                addedFrom = 'product page';
                $productTile = $(SELECTORS.productDetails);
                break;
            case 'plp':
                addedFrom = 'category page';
                $productTile = $(SELECTORS.productTile);
                break;
            case 'cqv':
                addedFrom = 'cart quick view';
                $productTile = $(SELECTORS.modalCartEdit);
                break;
            default:
                addedFrom = '';
                $productTile = this.$el.find(SELECTORS.productTile);
        }

        const impressions = [];

        $productTile.each((idx, el) => {
            const $el = $(el);
            const productData = this.getProductData($el);

            if (productData && ((productData.dimension23 === data.pid) || (productData.dimension27 === data.pid))) {
                const position = $el.closest(SELECTORS.tileContainer).data('position');

                impressions.push(Object.assign({ position: position || 1 }, productData));
            }
            $el.data('tracked', '1');
        });


        const eventData = {
            eventCategory: 'product',
            eventAction: 'add to wishlist',
            eventLabel: addedFrom,
            ga4eventname: 'add_to_wishlist',
            uuid: data.pid,
            ecommerce: {
                currencyCode: window.SitePreferences.currencyCode,
                add: {
                    products: impressions
                }
            }
        };

        this.emitter.emit('gtmEvent', eventData);
    }

    async reInit() {

        const requestConfig = [
            {
                url: accountHeader,
                containers: this.$el
            },
            {
                url: accountHeader + '?mobile=true',
                containers: $(SELECTORS.wlMobileContainer)
            }
        ];

        for (const config of requestConfig) {
            const response = await load({
                url: config.url,
                type: 'GET'
            });

            this.replaceHtml(response, config.containers, {
                onAfterInit: () => {
                    this.destroy();
                    this.init();
                    this.updateTiles();
                }
            });
        }
    }

    updateTiles() {
        let products = this.$wishlistProducts.data('wishlistProducts');

        products && products.split(',').forEach(function (product) {
            var tiles = $(SELECTORS.wishlistBtn + '[data-pid="' + product + '"]');

            tiles.removeClass(WISHLIST_CLASSES.wishlistAdd).addClass(WISHLIST_CLASSES.wishlistRemove);
            tiles.find(SELECTORS.wishlistIcon).addClass(WISHLIST_CLASSES.iconWishlistAdded);
        });
    }

    init () {
        this.isMobile = this.$el.hasClass(WISHLIST_CLASSES.wlMobileContainer);
        this.container = this.$el;

        if (this.isMobile) {
            this.headerTemplate = templatesManager.templates.headerMobileTemplate;
            this.container = $(SELECTORS.wlMobileContainer);
        } else {
            this.headerTemplate = templatesManager.templates.headerTemplate;
        }

        this.replaceHtml(
            this.headerTemplate({
                isAuthenticated: isAuthenticated,
                isWishListEnabled: isWishListEnabled
            }),
            this.container
        );

        // show header buttons
        if (!isWishListEnabled) {
            $('.b-header-utilities-container').removeClass('h-hide');
            $('.b-account-wishlist-minicart').removeClass('h-hide');
        }

        this.$wishlistQuantity = this.$el.find(SELECTORS.wishlistQuantity);
        this.$wishlistProducts = this.$el.find(SELECTORS.wishlistProducts);

        this.emitter.addListener('account.header.reinit', this.reInit.bind(this));
        this.emitter.addListener('wishlist.added', this.onWishlistUpdated.bind(this));
        this.emitter.addListener('wishlist.removed', this.onWishlistUpdated.bind(this));

        if (this.gtmEnabled) {

            this.bindEvent('click', SELECTORS.logoutLink, this.trackLogout);
        }
    }
}
