import Component from 'client/core/Component';

import { CLASSES, COOKIE } from 'client/utils/globals';
import { setCookie, hasCookie } from 'client/utils/cookie';

const SELECTORS = {
    acceptBtn: '.js-accept-cookie-btn'
};

export default class CookieHint extends Component {
    init () {
        if (hasCookie(COOKIE.acceptCookie)) {
            this.notifyDone();

            this.emitter.addListener('popupNotify', () => {
                this.notifyDone();
            });
        } else {
            this.bindEvent('click', SELECTORS.acceptBtn, this.accept);
            this.$el.removeClass(CLASSES.hide);
        }
    }

    notifyDone() {
        this.emitter.emit('popupDone', this.config.cmp);
    }

    accept() {
        setCookie(COOKIE.acceptCookie, true);
        this.$el.addClass(CLASSES.hide);
        this.notifyDone();
    }
}
