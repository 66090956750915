import Component from 'client/core/Component';

export default class SubmitButton extends Component {
    init () {
        this.bindEvent('click', this.onClick);
    }

    onClick(el, ev) {
        if (ev) {
            ev.preventDefault();
        }

        if (this.config.submitEvent) {
            this.emitter.emit(this.config.submitEvent);
        }

        this.emit('clicked');
    }
}
