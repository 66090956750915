import Component from 'client/core/Component';
import { appendParamToURL, extractParamFromURL, removeParamFromURL } from 'client/utils/url';
import { addGridNavigation, addSearchNavigation } from 'client/utils/navigation';
import { getAppRequiredParams, getAppParam } from 'client/utils/common';
import ajax from 'client/utils/ajax';
import $ from 'jquery';

const SELECTORS = {
    'ACTIVE_PROMOTION_FILTER': 'div.active-promo-filter'
};

const UTM_PARAMETRS_ARRAY = [
    'campaign',
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'aaaid',
    'ext_publisher_id',
    'awc',
    'il'
];

function getUrlForSpecificCategory (url) {
    // @ts-ignore
    const locationUrl = window.location.href.split('?')[0];
    const queryString = url.split('?')[1];

    if (queryString) {
        return `${locationUrl}?${queryString}`;
    } else {
        return locationUrl;
    }
}

export default class RefinementsWrapper extends Component {
    // eslint-disable-next-line complexity
    init() {
        // @ts-ignore
        this.baseUrl = this.config.baseUrl || window.location.pathname;
        this.promotionFilterActive = $(SELECTORS.ACTIVE_PROMOTION_FILTER).length > 0;

        this.attributeRefinements = this.getAttributeRefinements(this.config.attributeRefinements);
        this.priceRefinements = {
            // @ts-ignore
            min: extractParamFromURL(window.location.href, 'pmin') || null,
            // @ts-ignore
            max: extractParamFromURL(window.location.href, 'pmax') || null,
            // @ts-ignore
            onlySalePrices: extractParamFromURL(window.location.href, 'isSale') || false
        };
        this.utmСamPaignParams = {};

        UTM_PARAMETRS_ARRAY.forEach(item => {
            // @ts-ignore
            this.utmСamPaignParams[item] = extractParamFromURL(window.location.href, item);
        });

        this.emitter.addListener('refinement.applyFilter', data => this.applyChanges(data));
    }

    applyChanges(data) {
        let isUpdateSuccess = false;

        if (data.type === 'category' || data.type === 'filterBar') {
            this.makeRequest(data.url, data.type);
        } else {
            if (data.type === 'price') {
                isUpdateSuccess = this.updatePriceRefinements(data);

                if (this.baseUrl) {
                    this.baseUrl = removeParamFromURL(this.baseUrl, 'pmin');
                    this.baseUrl = removeParamFromURL(this.baseUrl, 'pmax');
                }
            } else {
                isUpdateSuccess = this.updateAttributeRefinements(data);
            }

            if (isUpdateSuccess) {
                this.makeRequest();
            }
        }
    }

    makeRequest (url = null, type = null) {
        if (this.config.specificCategory) {
            switch (this.config.specificCategory) {
                case 'soon':
                    this.updateAttributeRefinements({
                        attribute: 'isComingSoon',
                        values: ['true']
                    });
                    break;
                case 'sale':
                    if (!this.promotionFilterActive) {
                        this.updateAttributeRefinements({
                            attribute: 'isSale',
                            values: ['true']
                        });
                    }
                    break;
                case 'new':
                    this.updateAttributeRefinements({
                        attribute: 'isNew',
                        values: ['true']
                    });
                    break;
            }
        }

        if (!url) {
            url = this.buildUrl();
        }

        if (this.config.specificCategory) {
            url = appendParamToURL(url, 'specificCategory', this.config.specificCategory);
        }

        const request = { url: url, spinner: 'grid' };

        if (this.config.isBrandListingPage === true) {
            request.format = 'html';
        }

        const productGridCurrentVersionNumber = Date.now();

        window.productGridOCA = productGridCurrentVersionNumber;

        // @ts-ignore
        const promise = ajax.load(request);

        // refine
        promise.then((response) => {
            if (productGridCurrentVersionNumber !== window.productGridOCA) {
                console.log('discard RefinementsWrapper response');
                return;
            }
            const $response = $('<div></div>').append($(response));
            const stateUrl = this.config.specificCategory && type !== 'category' ?
                addGridNavigation(getUrlForSpecificCategory(url)) :
                addGridNavigation(url);

            this.emitter.emit('showAllResultsButton.lock', false);
            this.emitter.emit('history.state.push', stateUrl);
            this.emitter.emit('refinement.updated', $response);
        });
    }

    updatePriceRefinements(data) {
        this.priceRefinements.min = data.values.min;
        this.priceRefinements.max = data.values.max;
        this.priceRefinements.onlySalePrices = data.values.onlySale;

        this.updateAttributeRefinements({
            attribute: 'isSale',
            values: this.priceRefinements.onlySalePrices ? ['true'] : []
        });

        return true;
    }

    updateAttributeRefinements(data) {
        if (this.attributeRefinements.has(data.attribute)) {
            if (data.values.length === 0) {
                this.attributeRefinements.delete(data.attribute);
                this.updateParameterNumbers();
            } else {
                this.attributeRefinements.get(data.attribute).values = data.values;
            }
        } else if (data.values.length > 0) {
            this.attributeRefinements.set(data.attribute, {
                parameter: '',
                values: data.values
            });
            this.updateParameterNumbers();
        } else {
            return false;
        }

        return true;
    }

    updateParameterNumbers() {
        let index = 1;

        // @ts-ignore
        // eslint-disable-next-line no-restricted-syntax
        for (let [key, value] of this.attributeRefinements) {
            if (key && value.parameter !== `prefn${index}`) {
                value.parameter = `prefn${index}`;
            }

            index += 1;
        }
    }

    // eslint-disable-next-line complexity
    buildUrl() {
        let url = this.baseUrl;

        // @ts-ignore
        // eslint-disable-next-line no-restricted-syntax
        for (let [key, value] of this.attributeRefinements) {
            if (key) {
                if (key === 'promotion') {
                    this.config.pmid = value.values.join('|');
                } else {
                    url = appendParamToURL(url, value.parameter, key);
                    url = appendParamToURL(
                        url,
                        `prefv${parseInt(value.parameter.replace('prefn', ''), 10)}`,
                        value.values.join('|')
                    );
                }
            }
        }

        let minPrice = this.priceRefinements.min ?
            this.clearPriceValue(this.priceRefinements.min) : this.priceRefinements.min;

        if (minPrice && !Number.isNaN(minPrice)) {
            url = appendParamToURL(url, 'pmin', minPrice);
        }

        let maxPrice = this.priceRefinements.max ?
            this.clearPriceValue(this.priceRefinements.max) : this.priceRefinements.max;

        if (maxPrice && !Number.isNaN(maxPrice)) {
            url = appendParamToURL(url, 'pmax', maxPrice);
        }

        let brandRefinements = this.attributeRefinements.get('brand');

        if (!this.config.isBrandListingPage && brandRefinements && brandRefinements.values.length === 1) {
            // @ts-ignore
            url = appendParamToURL(url, 'brandRefinement', true);
        }

        if (this.config.pmid) {
            url = appendParamToURL(url, 'pmid', this.config.pmid);
        }

        if (this.isApp()) {
            getAppRequiredParams().forEach((paramName) => {
                const param = getAppParam(paramName);

                if (param) {
                    url = appendParamToURL(url, param.name, param.value);
                }
            });
        }

        Object.keys(this.utmСamPaignParams)
            .filter(key => this.utmСamPaignParams[key] !== null)
            .forEach(key => {
                url = appendParamToURL(url, key, this.utmСamPaignParams[key]);
            });

        // @ts-ignore
        url = appendParamToURL(url, 'openCategory', true);

        return addSearchNavigation(url);
    }

    // eslint-disable-next-line class-methods-use-this
    clearPriceValue(value) {
        if (value) {
            let delimiter = null;

            if (value.indexOf('.') !== -1) {
                delimiter = '.';
            } else if (value.indexOf(',') !== -1) {
                delimiter = ',';
            }

            if (delimiter) {
                value = value.split(delimiter)[0];
            }
        }

        return value;
    }

    getAttributeRefinements(data = null) {
        let attributeRefinements = new Map(), // eslint-disable-line no-undef
            // @ts-ignore
            params = window.location.search ? window.location.search.substring(1) : '';

        if (data && Array.isArray(data)) {
            let index = 1;

            data.forEach((attr) => {
                attributeRefinements.set(attr.parameter, {
                    parameter: `prefn${index}`,
                    values: attr.values
                });
                index += 1;
            });
        } else if (params) {
            params = params.split('&');

            let attrs = this.collectRefinementAttributes(params),
                values = this.collectRefinementValues(params);

            for (let i = 0; i < attrs.length; i++) {
                attributeRefinements.set(attrs[i][1], {
                    parameter: attrs[i][0],
                    values: values[i]
                });
            }
        }

        return attributeRefinements;
    }

    // eslint-disable-next-line class-methods-use-this
    collectRefinementAttributes(params) {
        let attributes = [];

        // eslint-disable-next-line no-restricted-syntax
        for (let param of params) {
            if (param.indexOf('prefn') !== -1) {
                let parts = param.split('=');

                attributes.push(parts);
            }
        }

        return attributes;
    }

    // eslint-disable-next-line class-methods-use-this
    collectRefinementValues(params) {
        let values = [];

        // eslint-disable-next-line no-restricted-syntax
        for (let param of params) {
            if (param.indexOf('prefv') !== -1) {
                let parts = param.split('=');

                parts[1] = decodeURIComponent(parts[1]).split('|');
                values.push(parts[1]);
            }
        }

        return values;
    }
}
