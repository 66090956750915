'use strict';

export default class EventEmitter {
    constructor () {
        this._events = {};
    }

    /**
     * Add Event Listener
     * @param {String} event
     * @param {function} listener
     */
    addListener (event, listener) {
        if (typeof this._events[event] !== 'object') {
            this._events[event] = [];
        }

        this._events[event].push(listener);
    }

    /**
     * Remove Event Listener
     * @param {string} event
     * @param {function} listener
     */
    removeListener (event, listener) {
        if (typeof this._events[event] !== 'object') {
            return false;
        }

        var idx = this._events[event].indexOf(listener);

        ~idx && this._events[event].splice(idx, 1);

        return true;
    }

    /**
     * Add Unique Listener
     * @param {String} event
     * @param {function} listener
     */
    // addUniqueListener (event, listener) {
    //     this.removeListener(event, listener);
    //     this.addListener(event, listener);
    // }

    /**
     * Emit Event
     * @param {String} event
     */
    emit (event) {
        if (typeof this._events[event] !== 'object') {
            return false;
        }

        var listeners = this._events[event].slice(),
            length = listeners.length,
            args = [].slice.call(arguments, 1),
            i;

        for (i = 0; i < length; i++) {
            try {
                listeners[i].apply(this, args);
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error('Event handler error\n' + e.stack);
            }
        }

        return true;
    }

    /**
     * It will add a listener that will be
     * automatically removed after its first execution.
     * @param {String} event
     * @param {function} listener
     */
    // once (event, listener) {
    //     this.addListener(event, function g () {
    //         this.removeListener(event, g);
    //         listener.apply(this, arguments);
    //     });
    // }
}
