import Component from 'client/core/Component';

import { appendParamToURL } from 'client/utils/url';

import $ from 'jquery';
import { debounce } from 'lodash';
import { CLASSES } from 'client/utils/globals';
import { isMedium, isSmall, isCurrentlySmall, isCurrentlyMedium } from 'client/utils/screendetector';
import { isIOS, isTablet, refresh } from 'client/utils/mobileDetect';
import prefs from 'sitePreferences';
import { scrollTo } from 'client/utils/common';
import { handleSearchSuggestion,
         fireSearchSuggestionTag,
         trackSuggestionPressEnter,
         trackSuggestionPressSearchIcon }
from 'client/components/google/GtmSearchSuggestion';

const DEFAULT_CONFIG = {
    delay: 300,
    symbolsLength: 2,
    searchParamName: 'q'
};

const SELECTORS = {
    searchInput: '.js-search-input',
    clearSearchBtn: '.js-clear-search',
    closeSearchBtn: '.js-close-search',
    searchBtn: '.js-search-btn',
    suggestionsWrapper: '.js-suggestions-wrapper',
    searchFormWrap: '.js-search-form-wrapper',
    searchFormInner: '.js-search-form-inner',
    headerBanner: '.js-header-banner',
    stickyHeader: '.js-sticky-element',
    searchComponent: '.js-quick-search-form',
    searchSwitcher: '.js-search-switcher',
    headerWrapper: '.js-header',
    headerSearch: '.js-header-search',
    stickyContainer: '.js-sticky-container',
    headerMobile: '.js-header-sticky-mobile',
    searchContainer: '.js-search-container'
};

const STATE_CLASSES = {
    searchShow: 'h-search-visible',
    searchShadow: 'c-search-form-inner--shadow',
    searchInnerSize: 'c-search-form-inner--fixed-width',
    closeBTtnShow: 'f-clear-search--show',
    searchOpened: 'b-search--opened',
    searchSuggestionsOpened: 'h-search-suggestions--opened',
    headerSticked: 'h-header-sticky'
};

export default class QuickSearchForm extends Component {
    init () {
        this.config = Object.assign({}, this.config, DEFAULT_CONFIG);

        this.$searchInput = this.$el.find(SELECTORS.searchInput);
        this.$suggestionsWrapper = this.$el.find(SELECTORS.suggestionsWrapper);
        this.$clearSearch = this.$el.find(SELECTORS.clearSearchBtn);
        this.$closeSearch = this.$el.find(SELECTORS.closeSearchBtn);
        this.$searchFormWrap = this.$el.find(SELECTORS.searchFormWrap);
        this.$searchFormInner = this.$el.find(SELECTORS.searchFormInner);
        this.$headerWrapper = $(SELECTORS.headerWrapper);
        this.$headerSearch = $(SELECTORS.headerSearch);
        this.$stickyContainer = $(SELECTORS.stickyContainer);
        this.suggestionNotEmpty = false;
        this.suggestionsHidden = true;
        this.isHeaderSticky = false;
        this.isSearchOpened = false;
        this.previousSearchPhrase = '';
        this.isIOSDevice = isIOS();
        this.$searchContainer = $(SELECTORS.searchContainer);
        this.$headerMobile = $(SELECTORS.headerMobile);

        this.headerStickyDetermination();
        this.calculateHeaderMobile();

        this.bindEvent('click', SELECTORS.clearSearchBtn, this.clearSearch);
        this.bindEvent('click', SELECTORS.closeSearchBtn, this.closeSearch);
        this.bindEvent('click', SELECTORS.searchBtn, this.runSearch);
        this.bindEvent(
            'keyup input propertychange',
            SELECTORS.searchInput,
            debounce(this.onInputTyping, this.config.delay)
        );

        this.bindEvent('focus', SELECTORS.searchInput, this.activateSearchInput);
        this.bindEvent('focus', SELECTORS.suggestionsWrapper, this.activateSearchInput);

        this.addListener('headerIsSticky', this.headerBecameSticky.bind(this));
        this.addListener('headerIsNotSticky', this.headerBecameNotSticky.bind(this));
        this.bindEvent('blur', SELECTORS.searchInput, () => this.blurSearch(this.isSearchOpened));
        this.bindEvent('blur', SELECTORS.suggestionsWrapper, () => this.emitter.emit('enabledScroll'));
        this.emitter.addListener('headerSearchShow', this.searchShow.bind(this));
        this.emitter.addListener('deviceChange', () => this.recalculateHeight());
        this.emitter.addListener('resize', () => this.recalculateHeight());
        this.emitter.addListener('orientationChange', () => this.toggleScroll(true));
        this.emitter.addListener('globalClick', this.globalClick.bind(this));
        this.emitter.addListener('globalWindowBlur', this.globalWindowBlur.bind(this));

        if (this.gtmEnabled) {
            this.emitter.addListener('fireSearchSuggestionTag', fireSearchSuggestionTag.bind(this));
            this.emitter.addListener('handleSearchSuggestion', handleSearchSuggestion.bind(this));
            this.emitter.addListener('trackSuggestionPressEnter', trackSuggestionPressEnter.bind(this));
            this.emitter.addListener('trackSuggestionPressSearchIcon', trackSuggestionPressSearchIcon.bind(this));
            this.keyPressList = [];
            this.bindEvent('keyup', SELECTORS.searchInput, this.trackSuggestionKeyup);
        }
    }

    trackSuggestionKeyup(el, ev) {
        ev.preventDefault();
        var code = (ev.keyCode ? ev.keyCode : ev.which);

        this.keyPressList.push(code);

        if (this.keyPressList.indexOf(13) !== -1) {
            this.emitter.emit('trackSuggestionPressEnter', this.emitter);
        }

        return false;
    }

    calculateHeaderMobile() {
        let isMobile = isCurrentlySmall() || isCurrentlyMedium();

        if (isMobile) {
            this.headerDefaultHeight = this.$headerSearch.outerHeight();
            this.headerMobileHeight = this.$headerMobile.height();
        }
    }

    blurSearch(isOpen) {
        if (isOpen) {
            if (isSmall() || isMedium()) {
                if (this.suggestionNotEmpty && !this.suggestionsHidden) {
                    setTimeout(() => this.recalculateHeight(), 200);
                    return;
                }
                if (this.gtmEnabled) {
                    this.emitter.emit('fireSearchSuggestionTag', this.emitter);
                }
                this.emitter.emit('enabledScroll');
            }

            this.isSearchOpened = !isOpen;
        }
    }

    activateSearchInput () {
        this.isSearchOpened = true;

        this.$searchFormWrap.addClass(STATE_CLASSES.searchShow);
        this.$searchFormInner.addClass(`${STATE_CLASSES.searchShadow} ${STATE_CLASSES.searchInnerSize}`);
        if (this.suggestionNotEmpty) {
            this.showSuggestions();
        }
    }

    onInputTyping (input, ev) {
        if (this.gtmEnabled && ((ev.keyCode ? ev.keyCode : ev.which) === 13)) {
            this.emitter.emit('fireSearchSuggestionTag', this.emitter);
        }

        let searchPhrase = this.$searchInput.val().trim();

        if (searchPhrase.length === 0) {
            this.$clearSearch.removeClass(STATE_CLASSES.closeBTtnShow);
        } else {
            this.$clearSearch.addClass(STATE_CLASSES.closeBTtnShow);
        }

        if (this.config.symbolsLength <= searchPhrase.length) {
            if (this.previousSearchPhrase === searchPhrase) {
                return;
            }

            this.emitter.emit('emarsys.search.term', searchPhrase);
            $.ajax({
                url: appendParamToURL(this.config.suggestUrl, this.config.searchParamName, searchPhrase),
                method: 'GET'
            }).then(data => {
                if ((typeof data === 'string') && this.isSearchOpened) {
                    const $html = $(data);

                    this.emitter.emit('namespace.component.initall', $html, {
                        onAfterInit: () => {
                            const $images = $html.find('img'),
                                imgCount = $images.length;

                            let imgLoaded = 0;

                            $images.on('load', () => {
                                if (++imgLoaded === imgCount) {
                                    this.emitter.emit('namespace.component.destroyall', this.$suggestionsWrapper);
                                    this.$suggestionsWrapper.empty().append($html);

                                    this.suggestionNotEmpty = true;
                                    this.showSuggestions();
                                }
                            });

                            if (this.gtmEnabled) {
                                this.emitter.emit('handleSearchSuggestion', $html, this.emitter);
                            }
                        }
                    });
                } else {
                    this.$suggestionsWrapper.empty();
                    this.hideSuggestions();
                }
            });
        } else {
            this.clearSuggestions();
        }

        this.previousSearchPhrase = searchPhrase;
    }

    runSearch (el, e) {
        e.preventDefault();
        if (this.gtmEnabled) {
            setTimeout(() => {
                if (this.keyPressList.indexOf(13) === -1) {
                    this.emitter.emit('trackSuggestionPressSearchIcon', this.emitter);
                }
            }, DEFAULT_CONFIG.delay);
        }

        this.$el[0] && this.$el[0].nodeName === 'FORM' && this.$el.submit();
    }

    clearSearch (el, e) {
        e.preventDefault();
        this.$searchInput.val('');
        this.clearSuggestions();
        this.$clearSearch.removeClass(STATE_CLASSES.closeBTtnShow);

        if (isSmall() || isMedium()) {
            this.previousSearchPhrase = '';
        }
    }

    closeSearch (el, e) {
        e.preventDefault();
        this.searchHide();
    }

    clearSuggestions () {
        this.$suggestionsWrapper.empty();
        this.suggestionNotEmpty = false;
        this.hideSuggestions();

        if (this.gtmEnabled) {
            this.emitter.emit('fireSearchSuggestionTag', this.emitter);
        }
    }

    showSuggestions () {
        if (this.suggestionsHidden) {

            this.$suggestionsWrapper.removeClass(CLASSES.hide);
            this.$searchFormInner.addClass(STATE_CLASSES.searchSuggestionsOpened);
            this.recalculateHeight();
            if (isSmall() || isMedium()) {
                this.emitter.emit('disabledScroll');

                if (this.isIOSDevice) {
                    scrollTo(this.$headerSearch, {
                        duration: 0
                    });
                }
            }
            this.suggestionsHidden = !this.suggestionsHidden;
        }
    }

    hideSuggestions () {
        if (!this.suggestionsHidden) {
            this.$suggestionsWrapper.addClass(CLASSES.hide);
            this.$searchFormInner.removeClass(STATE_CLASSES.searchSuggestionsOpened);
            this.suggestionsHidden = !this.suggestionsHidden;

            if (this.gtmEnabled) {
                this.emitter.emit('fireSearchSuggestionTag', this.emitter);
            }
        }
    }

    searchShow () {
        this.calculateHeaderMobile();
        this.$searchFormWrap.addClass(STATE_CLASSES.searchShow);
        this.$searchFormInner.addClass(`${STATE_CLASSES.searchShadow} ${STATE_CLASSES.searchInnerSize}`);
        let headerIsSticked = this.$headerWrapper.hasClass(STATE_CLASSES.headerSticked);

        if (headerIsSticked && (isCurrentlySmall() || isCurrentlyMedium())) {
            if ($(window).scrollTop() > this.headerDefaultHeight) {
                this.$headerWrapper.css('min-height', this.headerDefaultHeight);
            } else {
                this.$headerWrapper.css('min-height', this.headerMobileHeight);
            }

            this.$headerWrapper.addClass(STATE_CLASSES.searchOpened);

            if (!this.isIOSDevice) {
                this.emitter.emit('disabledScroll');
            }
        }

        this.$headerWrapper.addClass(STATE_CLASSES.searchOpened);
        this.$searchInput.focus();
    }

    searchHide () {
        this.$headerWrapper.removeClass(STATE_CLASSES.searchOpened);
        this.$searchFormWrap.removeClass(STATE_CLASSES.searchShow);
        this.$searchFormInner.removeClass(`${STATE_CLASSES.searchShadow} ${STATE_CLASSES.searchInnerSize}`);
        this.emitter.emit('searchSwitcherShow');
        this.hideSuggestions();
        this.$searchInput.blur();
    }

    recalculateHeight() {
        const viewPortScrollTop = $(window).scrollTop();
        const elementOffsetTop = this.$suggestionsWrapper.offset().top;

        this.viewPortHeight = $(window).innerHeight();
        this.stickyHeaderHeight = this.$stickyContainer.innerHeight();

        if (this.isHeaderSticky) {
            this.$headerHeight = this.stickyHeaderHeight;
        } else {
            this.$headerHeight = elementOffsetTop - viewPortScrollTop;
        }

        let height = Math.round(this.viewPortHeight - this.$headerHeight);

        const isMobile = isSmall() || isMedium();

        if (isMobile && this.suggestionNotEmpty) {
            this.$suggestionsWrapper.css('min-height', height);
        } else {
            this.$suggestionsWrapper.css('min-height', '500px');
        }
    }

    headerBecameSticky() {
        this.isHeaderSticky = true;

        if (this.suggestionNotEmpty) {
            this.recalculateHeight();
        }
    }

    headerBecameNotSticky() {
        this.isHeaderSticky = false;

        if (this.suggestionNotEmpty) {
            this.recalculateHeight();
        }
    }

    globalClick(target) {
        const isSearchComponent = $(target).parents(SELECTORS.searchContainer).length;
        const isSearchSwitcher = $(target).parents(SELECTORS.searchSwitcher).length;

        if (isSearchComponent || isSearchSwitcher) {
            return null;
        } else {
            return this.searchHide();
        }
    }

    globalWindowBlur () {
        if (!isSmall() && !isMedium()) {
            this.searchHide();
        }
    }

    headerStickyDetermination() {
        if ($(SELECTORS.stickyHeader).parent().hasClass(CLASSES.headerSticky)) {
            this.isHeaderSticky = true;
        }
    }

    toggleScroll(isChange) {
        if (isChange) {
            refresh();
        }

        const windowWidth = $(window).innerWidth();
        const tablet = isTablet();

        if (!this.suggestionsHidden && tablet && windowWidth > prefs.breakpointMedium) {
            this.emitter.emit('enabledScroll');
            scrollTo(this.$searchInput);
        } else if (!this.suggestionsHidden) {
            this.emitter.emit('disabledScroll');
        }
    }
}
