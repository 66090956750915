import Component from 'client/core/Component';
import { CLASSES } from 'client/utils/globals';
import ajax from 'client/utils/ajax';
import $ from 'jquery';
import prefs from 'sitePreferences';
import Tooltip from 'client/components/Tooltips';
import { CONSENT_MESSAGE_SHIPTOSTORE } from 'resources';
import { isManageGoogleMapByConsent } from 'sitePreferences';

const SELECTORS = {
    METHOD: '.js-shipping-method',
    INPUT: '.js-shipping-method-input',
    CHECKOUT_MGR: '[data-cmp="checkoutMgr"]'
};

const ATTRIBUTES = {
    CURRENT_SHIPPING_METHOD: 'data-current-shipping-method',
    IS_BOPIS_SHIP_TO_STORE: 'data-is-bopis-ship-to-store'
};

export default class ShippingMethod extends Component {
    init() {
        this.$selectedMethod = this.getSelectedMethod();
        this.$shippingMethodList = this.$el.find(SELECTORS.METHOD);

        if (!this.config.addressBook) {
            if (this.$selectedMethod.length) {
                this.select(this.$selectedMethod.get(0));
            } else if (this.config.selectedMethod) {
                this.select(this.$el.find(`#shippingMethod-${this.config.selectedMethod}`).get(0));
            } else if (this.$shippingMethodList.length > 0) {
                this.select(this.$shippingMethodList.get(0));
            }
        }

        this.bindEvent('click', SELECTORS.METHOD, (el, ev) => {
            if (
                !window.consentStatus &&
                isManageGoogleMapByConsent &&
                $(el).data('shipping-method') === 'ship-to-store'
            ) {
                this.toolTip = new Tooltip($(el), CONSENT_MESSAGE_SHIPTOSTORE, this.acceptConsent.bind(this));
                this.toolTip.setParams({ el: el, ev: ev });
                this.toolTip.showTooltip();
                return;
            } else {
                this.select(el, ev);
            }
        });
    }

    acceptConsent(el, ev) {
        window.dispatchEvent(new CustomEvent('apply.consent.cookie', { detail: { consentStatus: true } }));
        this.select(el, ev);
    }

    select(el, ev) {
        let $el = $(el);

        let changedByEvent = false;

        if (ev) {
            typeof ev.preventDefault === 'function' && ev.preventDefault();
            changedByEvent = true;
        }

        if (this.$selectedMethod === null || !this.$selectedMethod.is($el)) {
            this.toggleState($el);

            this.selectMethod(
                $el.data('selected-carrier-id') || $el.data('shipping-method'),
                $el.data('shipment-uuid'),
                $el.data('shipment-method-name'),
                changedByEvent
            );
        }
    }

    selectMethod(methodID, shipmentUUID, shipmentMethodName, changedByEvent) {
        this.emitter.emit('step.shipping.method.changed', {
            id: methodID,
            changedByEvent: changedByEvent,
            shipmentMethodName: shipmentMethodName
        });

        if (methodID && shipmentUUID && !this.config.addressBook) {
            let promise = ajax.getJson({
                url: this.config.selectShippingMethodUrl,
                data: { methodID, shipmentUUID },
                type: 'POST'
            });

            promise.then(response => {
                if (prefs.isBopisEnabled) {
                    this.updateCheckoutMgrAttrs(methodID);
                }
                response.methodID = methodID;
                this.emitter.emit('step.shipping.updated', response);
            });
        }
    }

    updateMethodsList() {
        let promise = ajax.getJson({
            url: this.config.actionUrl,
            data: { shipmentUUID: this.config.shipmentUuid }
        });

        promise.then(response => {
            this.emitter.emit('step.shipping.updated', response);
        });
    }

    toggleState($el) {
        this.$selectedMethod.data('selected', false);
        this.$selectedMethod.removeClass(CLASSES.selected);

        $el.data('selected', true);
        $el.addClass(CLASSES.selected);

        // Check hidden radio input
        $el.find(SELECTORS.INPUT).prop('checked', true);

        this.$selectedMethod = $el;
    }

    getSelectedMethod() {
        return this.$el.find(`${SELECTORS.METHOD}[data-selected=true]`);
    }

    isBopisShipToStore(shippingMethodID) {
        const isBopisEnabled = prefs.isBopisEnabled;
        const isShipToStore = new RegExp('^ship-to-store.*').test(shippingMethodID);

        return isBopisEnabled && isShipToStore;
    }

    updateCheckoutMgrAttrs(shippingMethodID) {
        const checkoutMgr = $(SELECTORS.CHECKOUT_MGR);
        const isBopisShipToStore = this.isBopisShipToStore(shippingMethodID);

        checkoutMgr.attr(ATTRIBUTES.CURRENT_SHIPPING_METHOD, shippingMethodID);
        checkoutMgr.attr(ATTRIBUTES.IS_BOPIS_SHIP_TO_STORE, String(isBopisShipToStore));
    }
}
