import Component from 'client/core/Component';
import { compile } from 'handlebars';


export default class BrandTiles extends Component {
    init () {
        this.$target = this.$el.find('.js-target');
        this.source = this.$el.find('.js-source').html();
        this.template = compile(this.source);
    }

    render (data) {
        let result = this.template(data);

        this.$target.html(result);
    }
}
