import Component from 'client/core/Component';

const
    SELECTORS = {
        searchBtn: '.js-show-search-btn'
    };

export default class HeaderSearchMobileShow extends Component {

    init () {
        this.bindEvent('click', SELECTORS.searchBtn, this.searchShow);
    }

    searchShow () {
        this.emitter.emit('headerSearchShow');
    }
}
