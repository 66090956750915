import CartComponent from './CartComponent';
import ajax from 'client/utils/ajax';

export default class OrderSummary extends CartComponent {
    onCartUpdate(data) {
        let result = this.template(data);

        this.replaceHtml(result, this.$target);
    }

    onCouponApplied() {
        const url = this.$el.data('url');

        if (url) {
            ajax.getJson({
                url
            }).then((resp) => {
                if (resp) {
                    this.onCartUpdate(resp);
                }
            });
        }
    }

    init() {
        super.init();

        this.emitter.addListener('cart.coupon.applied', this.onCouponApplied.bind(this));
        this.emitter.addListener('cart.updated', (r) => this.onCartUpdate(r));
    }
}
