import StoreFinderMapMgr from 'client/components/StoreFinderMapMgr';
import { CLASSES } from 'client/utils/globals';
import { getJson } from 'client/utils/ajax';
import { getToken } from 'urls';
import prefs from 'sitePreferences';
import $ from 'jquery';
import { trackBopisStoreData } from 'client/utils/storefinderHelper';
import {
    SERVER_UNAVAILABLE,
    FIND_IN_STORE_OUT_OF_BOUNDARY
} from 'resources';
import {
    isMedium,
    isSmall
} from 'client/utils/screendetector';

export default class FindInStore extends StoreFinderMapMgr {
    async init () {
        this.productSizesCmp = this.getNestedComponentById(this.config.productSizesCmp);

        this.searchPanelCmp = this.getNestedComponentById(this.config.searchPanelCmp);
        this.searchPanelCmp && this.searchPanelCmp.addListener('action.find', this.search.bind(this));

        this.$resultContainer = this.$el.find('.js-results');

        this.markersCache = {};

        this.$infoBlockStarter = this.$el.find('.js-infoblock-start');
        this.$infoBlockResults = this.$el.find('.js-infoblock-results');
        this.$infoBlockNoResults = this.$el.find('.js-infoblock-empty');

        this.generalMap = this.getNestedComponentById(this.config.mapGeneralMapCmp);
        this.listResult = this.getNestedComponentById(this.config.mapResultListCmp);

        const customerFavouriteStoreAddress = await this.getFavouriteStoreAttrValue('DATA_FAVOURITE_STORE_ADDRESS');
        const variantId = this.productSizesCmp.$el.find('.js-field option:selected').val();

        if (prefs.isBopisEnabled && customerFavouriteStoreAddress && variantId) {
            const addressInput = this.searchPanelCmp.$el.find('.js-field');

            addressInput.val(customerFavouriteStoreAddress);
            addressInput.focus();
            this.searchPanelCmp.onSubmit();
        }

        if (prefs.isBopisEnabled) {
            trackBopisStoreData({
                context: this,
                eventAction: 'pdp-layer/open'
            });
        }
    }

    onStoreSelected (storeInfo) {
        if (isMedium() || isSmall()) {
            if (this.markersCache[storeInfo.storeID]) {
                this.markersCache[storeInfo.storeID].initComponent();
            } else {
                this.getNestedComponentById(storeInfo.storeID, cmp => {
                    cmp.initComponent();
                    this.markersCache[storeInfo.storeID] = cmp;
                });
            }
        } else {
            this.generalMap.activateMarker(storeInfo.storeID);
        }

        this.initFavouriteStoreButton.call(this);
    }

    showError(errorMsg) {
        this.$resultContainer.addClass(CLASSES.hide);

        this.$infoBlockStarter.addClass(CLASSES.hide);
        this.$infoBlockResults.addClass(CLASSES.hide);
        this.$infoBlockNoResults.addClass(CLASSES.hide);

        this.emitter.emit('findinstore.infoblock.show', {
            type: 'error',
            message: errorMsg || SERVER_UNAVAILABLE
        });
    }

    async search (data = {}) { // eslint-disable-line
        if (
            !this.productSizesCmp ||
            !this.searchPanelCmp
        ) { return; }

        let searchPanelValidation = await this.searchPanelCmp.validate(),
            productSizesValidation = await this.productSizesCmp.validate();

        if (!searchPanelValidation || !productSizesValidation) {
            return;
        }

        if (!data.lat || !data.lng) {
            this.showError(FIND_IN_STORE_OUT_OF_BOUNDARY);
            return;
        }

        let response = { error: true };

        this.emitter.emit('findinstore.getstores');

        try {
            let csrfToken = (await getJson({
                type: 'POST',
                url: getToken,
                doNotStopSpinner: true
            })).csrf;

            response = await getJson({ // eslint-disable-line
                type: 'POST',
                url: this.config.url,
                doNotStopSpinner: true,
                data: Object.assign({}, data, {
                    [csrfToken.tokenName]: csrfToken.token,
                    pid: this.productSizesCmp.getValue()
                })
            });
        } catch (errorResponse) {
            $.spinner.stopAll();

            if (errorResponse.redirectUrl) {
                document.location.href = errorResponse.redirectUrl;

                return;
            }

            this.showError();

            this.generalMap && this.generalMap.$el &&
                this.emitter.emit('namespace.component.destroy', this.generalMap.$el) && this.generalMap.$el.empty();

            this.listResult && this.listResult.$el &&
                this.emitter.emit('namespace.component.destroy', this.listResult.$el) && this.listResult.$el.empty();

            return;
        }

        if (response.error || !response.results) {
            $.spinner.stopAll();

            this.showError();

            return;
        }

        this.emitter.emit('findinstore.infoblock.hide');

        try {
            if (this.generalMap && this.listResult) {
                this.$infoBlockStarter.addClass(CLASSES.hide);

                if (response.results.length) {
                    this.$resultContainer.removeClass(CLASSES.hide);
                    this.$infoBlockNoResults.addClass(CLASSES.hide);
                    this.$infoBlockResults.removeClass(CLASSES.hide);

                    await this.listResult.showResults(response.results);

                    this.generalMap.initComponent();
                    this.generalMap.clear();
                    this.generalMap.addMarkers(response.results);

                    this.listResult = this.getNestedComponentById(this.config.mapResultListCmp);

                    this.listResult && this.listResult.addListener(
                        'event.store.selected',
                        this.onStoreSelected.bind(this)
                    );

                    $('.js-store-info').first().trigger('click', { isAuto: true });
                    $('.pac-container.pac-logo').hide(); // Hide google autocomplete list
                } else {
                    this.$resultContainer.addClass(CLASSES.hide);
                    this.$infoBlockResults.addClass(CLASSES.hide);
                    this.$infoBlockNoResults.removeClass(CLASSES.hide);
                    trackBopisStoreData({
                        context: this,
                        eventAction: 'pdp-layer/product-availability'
                    });
                }
            }
        } catch (err) {
            console.log(err); // eslint-disable-line no-console
        }

        $.spinner.stopAll();
    }
}
