import { getJson, getCSRFToken } from 'client/utils/ajax';
import Component from 'client/core/Component';
import { CONTACT_NO_FOUND } from 'resources';

export default class ToggleLink extends Component {
    init() {
        this.isToggled = false;

        if (this.$el.attr('href')) {
            this.bindEvent('click', (el, ev) => this.onClick(el, ev));
        }
    }

    // eslint-disable-next-line consistent-return
    async onClick(el, ev) {
        ev && ev.preventDefault();
        ev && ev.stopPropagation();

        var data = {};

        const csrfToken = await getCSRFToken({ doNotStopSpinner: true });

        if (!csrfToken) {
            return {
                error: true
            };
        }

        data[csrfToken.tokenName] = csrfToken.token;
        data.isToggled = this.isToggled;

        getJson({
            type: 'post',
            url: this.$el.attr('href'),
            data: data
        }).then(response => {
            if (response && response.success) {
                this.onToggle();

            // eslint-disable-next-line no-negated-condition
            } else if (!response.loggedin) {
                this.emitter.emit('alert', {
                    body: CONTACT_NO_FOUND
                });
            } else {
                this.emitter.emit('alert', {
                    body: CONTACT_NO_FOUND
                });
            }
        });
    }

    onToggle() {
        this.isToggled = !this.isToggled;
        this.$el.text(this.isToggled ? this.config.toggleText : this.config.text);
    }
}
