import InputText from 'client/components/forms/commonElements/InputText';
import { CLASSES } from 'client/utils/globals';
import $ from 'jquery';
import templatesManager from 'client/utils/templatesManager';

const SELECTORS = {
    inputField: '.js-field',
    list: '.js-autocomplete-select-list',
    listItem: '.js-autocomplete-select-item'
};

export default class InputAutocomplete extends InputText {
    init() {
        this.config.initialPlaceholder = true;
        super.init();

        this.isOpen = false;
        this.inFocusField = false;
        this.inputField = this.$el.find(SELECTORS.inputField);
        this.list = this.$el.find(SELECTORS.list);

        this.bindEvent('mousedown', SELECTORS.listItem, (el) => this.selectValue(el));
        this.emitter.addListener('globalClick', this.globalClick.bind(this));
        this.emitter.addListener(`autocomlete.list.${this.config.id}.render`, (data) => this.renderList(data));
        this.emitter.addListener(`autocomlete.list.${this.config.id}.close`, this.listHide.bind(this));
        this.bindEvent('focus', SELECTORS.inputField, () => {
            this.inFocusField = true;
        });
        this.bindEvent('blur', SELECTORS.inputField, () => {
            if (this.isOpen) {
                this.listHide();
            }
            this.inFocusField = false;
        });
        this.bindEvent('keydown', SELECTORS.inputField, (element, event) => {
            if (this.isOpen && event.keyCode === 13) {
                let countItem = this.$el.find(SELECTORS.listItem).length;

                if (countItem === 1) {
                    let firstItem = this.$el.find(SELECTORS.listItem).first();

                    this.selectValue(firstItem);
                }
            }
        });
    }

    listOpen() {
        if (!this.isOpen && this.inFocusField) {
            this.$el.addClass(CLASSES.customSelectOpened);
            this.isOpen = true;
        }
    }

    listHide() {
        this.$el.removeClass(CLASSES.customSelectOpened);
        this.isOpen = false;
    }

    renderList(data) {
        this.list.children().remove();
        this.list.append(
            $(templatesManager.templates.autocompleteSelectTemplate(
                { data: data }
            )
        ));
        this.listOpen();
    }

    selectValue (el) {
        if ($(el).data('event')) {
            let cmpID = $(el).data('fieldname'),
                value = $(el).data('fieldvalue');

            this.emitter.emit($(el).data('event'), { 'cmpID': cmpID, 'value': value });
        }
        this.inputField.val($(el).data('value'));

        this.placeholderToggle();
        this.listHide();
        this.inFocusField = false;
    }

    globalClick(target) {
        if (!$(target).parents(SELECTORS.list).length) {
            this.listHide();
        }
    }
}
