import Component from 'client/core/Component';
import $ from 'jquery';
import Shuffle from 'shufflejs';

const SELECTORS = {
    ITEM_SHUFFFLE: 'js-item-shuffle-container',
    PRODUCT_SEARCH_RESULT: '.experience-region.experience-releaseproducttiles',
    PRODUCT_TILE_OUTER: '.experience-assets-customreleaseproducttile',
    CARD_TILE_OUTER: '.experience-assets-customcard',
    PRODUCT_TILE_INNER: '.js-rlp-product-tile',
    SIZER: '.js-sizer-element',
    FILTER_LINK: '.js-filter-group .js-filter-item span',
    BADGE_DATE_MONTH: '.js-product-date-month',
    NONE_LISTING: '.js-rlp-none-products-wrapper',
    PRODUCT_LISTING: '.js-rlp-products-wrapper'
};

const ATTR = {
    DATAGROUPS: 'data-groups',
    DATAMONTH: 'data-month',
    DATASORTTAG: 'data-sorttag',
    DATASOLDOUT: 'data-soldout',
    DATAGENDER: 'data-gender'
};

const GENDER = {
    WOMEN: 'women',
    MEN: 'men',
    NEUTRAL: 'neutral'
};

const FILTER_SHOW_ALL = 'show_all';
const FILTER_IS_COMING_SOON = 'isComingSoon';
const FILTER_PAST_RELEASES = 'isNew';
const ACTIVE_CLASS = 'selected';
const HIDE_CLASS = 'h-hide';
const HIDE_IMITATION_CLASS = 'h-hide-imitation';

export default class ReleaseLandingPage extends Component {

    init () {

        this.zeroTimeStampCounterComingSoon = 0;

        this.zeroTimeStampCounterAvailable = 0;

        this.zeroTimeStampCounterReleased = 0;

        this.requstLocale = this.$el.data('locale');

        this.genderFilter = this.$el.data('genderFilter');

        this.bindEvent('click', SELECTORS.FILTER_LINK, (el, ev) => this.handleFilterNavigation(el, ev));

        this.initElements();

        this.updateMonthNumberWithLiteral(this.requstLocale.replace('_', '-'));

        this.prepareShuffleItems();

        const shuffleInstance = this.createShuffleInstance();

        if (!shuffleInstance) {
            return;
        }

        this.shuffleInstance = shuffleInstance;

        this.sortItems();
    }

    initElements() {
        this.$el = $(SELECTORS.PRODUCT_SEARCH_RESULT);
    }

    sortIsComingSoonItems() {
        // sort by dataRelease from from nearest to future
        this.shuffleInstance.sort({
            compare: (a, b) => {

                // @ts-ignore
                const elementA = a.element.dataset.sorttag.split('_')[2];
                // @ts-ignore
                const elementB = b.element.dataset.sorttag.split('_')[2];

                return elementA - elementB;
            }
        });
    }

    sortItems() {
        this.shuffleInstance.sort({
            compare: (a, b) => {
                // @ts-ignore
                const elementA = a.element.dataset;
                // @ts-ignore
                const elementB = b.element.dataset;

                const aParts = elementA.sorttag.split('_');
                const bParts = elementB.sorttag.split('_');

                //if item soldout move it to the bottom
                if (elementA.soldout === 'true') return 1;
                if (elementB.soldout === 'true') return -1;

                if (aParts[1] === 'isComingSoon' && bParts[1] === 'isComingSoon') {
                    if (aParts[2] === undefined) return 0;
                    if (bParts[2] === undefined) return 0;

                    let numA = aParts[2].startsWith('00000') ? Infinity : Number(aParts[2]);
                    let numB = bParts[2].startsWith('00000') ? Infinity : Number(bParts[2]);

                    return numA - numB;
                }

                // Handle "2_isComingSoon" status - always first.
                if (aParts[1] === 'isComingSoon') return -1;
                if (bParts[1] === 'isComingSoon') return 1;

                // if item clique
                if (aParts[2] === undefined) return 0;
                if (bParts[2] === undefined) return 0;

                let numA = aParts[2].startsWith('00000') ? Infinity : Number(aParts[2]);
                let numB = bParts[2].startsWith('00000') ? Infinity : Number(bParts[2]);

                // For all other statuses, sort by timestamp.
                return numB - numA;
            }
        });
    }

    // eslint-disable-next-line class-methods-use-this
    updateMonthNumberWithLiteral(requstLocale) {
        const locale = requstLocale;
        const dateTimeOptions = { month: 'short' };

        $(SELECTORS.BADGE_DATE_MONTH).each(function() {
            const monthNumber = $(this).data('month');
            const date = (new Date()).setMonth(monthNumber - 1);

            try {
                // @ts-ignore
                const dateTimeFormat = new Intl.DateTimeFormat(locale, dateTimeOptions).format(date);

                $(this).text(dateTimeFormat);
            } catch (e) {
                console.error(e);
            }
        });
    }

    handleFilterNavigation(el, ev) {
        ev.preventDefault();

        var dataGroup = $(el).data('value');

        $(SELECTORS.FILTER_LINK).removeClass();
        $(el).addClass(ACTIVE_CLASS);

        if (dataGroup === FILTER_SHOW_ALL) {
            this.shuffleInstance.filter(Shuffle.ALL_ITEMS);
        } else if (dataGroup === FILTER_PAST_RELEASES) {
            this.shuffleInstance.filter(
                (element) => {
                    // @ts-ignore
                    return element.dataset.groups.includes(dataGroup) && element.dataset.soldout === 'true';
                }
            );
        } else {
            this.shuffleInstance.filter(dataGroup);
        }

        if (dataGroup === FILTER_IS_COMING_SOON) {
            this.sortIsComingSoonItems();
        } else {
            this.sortItems();
        }

        if (this.isAllItemsHidden()) {
            this.renderNoneProductsPage();
        } else {
            this.renderProductsListing();
        }

        return false;
    }

    createShuffleInstance() {
        this.createSizerElement();

        if (!this.$el.length) {
            return false;
        }

        // @ts-ignore
        var shuffleInstance = new Shuffle(this.$el, {
            itemSelector: '.' + SELECTORS.ITEM_SHUFFFLE
        });

        return shuffleInstance;
    }

    // eslint-disable-next-line class-methods-use-this
    createSizerElement() {
        $('<div>', {
            class: SELECTORS.SIZER.substring(1)
        }).appendTo(SELECTORS.PRODUCT_SEARCH_RESULT);
    }

    createNewSortTag(sortTag) {
        var subTasgs = sortTag.split('_');
        var lastItem = false;

        if (subTasgs.length > 1) {
            lastItem = subTasgs[2];
        }

        // @ts-ignore
        if (lastItem !== '0000000000000') {
            return sortTag;
        }

        var toBePadded = '';

        if (subTasgs[1] === 'isAvailable') {
            this.zeroTimeStampCounterAvailable += 1;
            toBePadded = this.zeroTimeStampCounterAvailable.toString();
        } else if (subTasgs[1] === 'isComingSoon') {
            this.zeroTimeStampCounterComingSoon += 1;
            toBePadded = this.zeroTimeStampCounterComingSoon.toString();
        } else if (subTasgs[1] === 'isReleased') {
            this.zeroTimeStampCounterReleased += 1;
            toBePadded = this.zeroTimeStampCounterReleased.toString();
        }

        if (toBePadded) {
            subTasgs[2] = toBePadded.padStart(subTasgs[2].length, '0');
        }

        return subTasgs.join('_');
    }

    // eslint-disable-next-line class-methods-use-this
    createCardSortTag(sortTag) {
        var subTasgs = sortTag.split('_');

        subTasgs[2] += '_';

        return subTasgs.join('_');
    }

    findFirstItem(sortTag) {
        var $firstItem = this.$el.find(SELECTORS.PRODUCT_TILE_OUTER + '[data-sorttag="' + sortTag + '"]');

        return $firstItem.first();
    }

    prepareShuffleItems() {
        const _this = this;
        var sortTagItems = [];

        $(SELECTORS.PRODUCT_TILE_OUTER).each(function() {
            const $item = $(this).find(SELECTORS.PRODUCT_TILE_INNER);
            const gender = $item.attr(ATTR.DATAGENDER) || GENDER.NEUTRAL;

            if (_this.genderFilter && gender !== _this.genderFilter) {
                $(this).remove();
            } else {
                $(this).addClass(SELECTORS.ITEM_SHUFFFLE);

                const dataGroup = $item.attr(ATTR.DATAGROUPS) || '[]';
                const sortTag = $item.attr(ATTR.DATASORTTAG) || '';
                const soldOut = $item.attr(ATTR.DATASOLDOUT) || 'false';
                const sortTagUpdated = _this.createNewSortTag(sortTag);

                sortTagItems.push(sortTagUpdated);

                $(this).attr(ATTR.DATASORTTAG, sortTagUpdated);
                $item.attr(ATTR.DATASORTTAG, sortTagUpdated);
                $(this).attr(ATTR.DATAGROUPS, dataGroup);
                $(this).attr(ATTR.DATASOLDOUT, soldOut);
                $(this).attr(ATTR.DATAGENDER, gender);
            }
        });

        sortTagItems.sort();

        var $cardTile = $(SELECTORS.CARD_TILE_OUTER);
        var $firstItem = this.findFirstItem(sortTagItems[0]);
        var cardSortTag = '';

        if ($firstItem.length) {
            cardSortTag = this.createCardSortTag($firstItem.attr(ATTR.DATASORTTAG));
        }

        $cardTile.each(function() {
            $(this).addClass(SELECTORS.ITEM_SHUFFFLE);

            $(this).attr(ATTR.DATASORTTAG, cardSortTag);
            $(this).attr(ATTR.DATAGROUPS, '[]');
        });
    }

    // eslint-disable-next-line class-methods-use-this
    isAllItemsHidden() {
        var allItemsCount = $(SELECTORS.PRODUCT_TILE_OUTER).length;
        var hiddenItemsCount = $(SELECTORS.PRODUCT_TILE_OUTER + '[aria-hidden="true"]').length;

        return allItemsCount === hiddenItemsCount;
    }

    // eslint-disable-next-line class-methods-use-this
    renderNoneProductsPage() {
        $(SELECTORS.PRODUCT_LISTING).addClass(HIDE_CLASS);
        $(SELECTORS.NONE_LISTING).removeClass(HIDE_IMITATION_CLASS);
    }

    // eslint-disable-next-line class-methods-use-this
    renderProductsListing() {
        $(SELECTORS.PRODUCT_LISTING).removeClass(HIDE_CLASS);
        $(SELECTORS.NONE_LISTING).addClass(HIDE_IMITATION_CLASS);
    }
}
