import Modal from 'client/components/Modal';
import { hasCookie, setCookie } from 'client/utils/cookie';
import { COOKIE, CLASSES } from 'client/utils/globals';
import $ from 'jquery';

const SELECTORS = {
    closeBtn: '.js-close-btn'
};

const $body = $('body');

export default class FirstVisitPopup extends Modal {
    init () {
        if (hasCookie(COOKIE.subscribeEmarsys) || hasCookie(COOKIE.hideFirstVisitPopup)) {
            this.notifyDone();

            this.emitter.addListener('popupNotify', () => {
                this.notifyDone();
            });

            return;
        }

        this.addListenerOnChild('modalContainerForFvp', 'modal.close', this.hidePopup.bind(this));
        this.emitter.addListener('hideFvpModal', this.hidePopup.bind(this));
        this.bindEvent('click', SELECTORS.closeBtn, this.hidePopup);

        this.runTimer();

        this.emitter.addListener('modal.close', this.runTimer.bind(this));
        this.emitter.addListener('modal.open', () => {
            this.intervalID && this.stopTimer();
        });
    }

    notifyDone() {
        this.emitter.emit('popupDone', this.config.cmp);
    }

    runTimer () {
        if (this.isThereActive() || this.intervalID) { return; }

        this.intervalID = setInterval(() => {
            this.stopTimer();
            this.showModal();
        }, this.config.delay);
    }

    stopTimer () {
        this.intervalID && clearInterval(this.intervalID);
        this.intervalID = null;
    }

    trackNewsletterPopup() {
        const data = {
            eventCategory: 'newsletter',
            eventAction: 'pop-up view',
            ga4eventname: 'newsletter'
        };

        this.emitter.emit('gtmEvent', data);
    }

    showModal () {
        if (this.isThereActive()) {
            this.stopTimer();
            return;
        }

        if (!this.doNotShowAgain) {
            this.$el.removeClass(CLASSES.hideFirstVisitPopup);
            this.emitter.emit('disabledScroll');
            $body.addClass(CLASSES.modalOpened);
        }

        if (this.gtmEnabled) {
            this.trackNewsletterPopup();
        }

        this.doNotShowAgain = true;

        this.addListenerOnChild('subscribeForm', 'submit.fail', this.onFail.bind(this));
    }

    onFail () {
        this.doNotShowAgain = false;
    }

    hidePopup () {
        if (this.doNotShowAgain) {
            setCookie(COOKIE.hideFirstVisitPopup, 'true');
            this.stopTimer();
        }

        $body.removeClass(CLASSES.modalOpened);
        this.emitter.emit('enabledScroll');
        this.$el.addClass(CLASSES.hideFirstVisitPopup);

        this.notifyDone();
    }
}
