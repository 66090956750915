import Form from 'client/core/Form';

import ajax from 'client/utils/ajax';
import $ from 'jquery';
import { CLASSES } from 'client/utils/globals';
import { scrollToMsg, getCurrentDateAsString, validateDate } from 'client/utils/common';
import { appendParamToURL } from 'client/utils/url';


import inputmask from 'inputmask';

const PREFERENCES_SHOE_SIZE = 'pr_shoesize',
    PREFERENCES_BIRTHDAY = 'birthday',
    PREFERENCES_INTEREST = 'pr_interest';

const SELECTORS = {
    error: '.js-error',
    successMsg: '.js-success-saved-msg',
    birthday: '.js-newsletter-preference-birthday-input',
    interestInput: `[name^="${PREFERENCES_INTEREST}"]`
};

class NewsletterPreferencesForm extends Form {
    toggleError(msg = '') {
        this.$.error.html(msg);
        this.$.error.toggleClass(CLASSES.hide, !msg);

        if (msg) {
            scrollToMsg(this.$.error);
        }
    }

    handleMessages(resp) {
        this.toggleError(resp.errorMessage || (resp.error && this.config.generalError) || '');

        if (resp.success) {
            this.$.successMsg.removeClass('h-hide');
            scrollToMsg(this.$.successMsg);
        }
    }

    convertDateString(birthday) {
        return birthday.split('.').reverse().join('-');
    }

    collectInterests() {
        const interestInputs = this.$el.find(SELECTORS.interestInput);

        return interestInputs.toArray().reduce((acc, interestCheckbox) => {
            const name = $(interestCheckbox).attr('name');
            const checked = Number($(interestCheckbox).prop('checked') + 1);

            acc[name] = checked;
            return acc;
        }, {});
    }

    preparePreferencesDataForSubmit(data) {
        if (!data || data.length === 0) {
            return null;
        }

        const interests = this.collectInterests();
        const formObj = data.reduce(function(acc, obj) {
            let key = obj.name;

            if (key in acc) {
                if (key === PREFERENCES_SHOE_SIZE) {
                    acc[key].push(this.config.sizeMappingForEmarsys[obj.value]);
                } else {
                    acc[key].push(obj.value);
                }
            } else if (key === PREFERENCES_SHOE_SIZE) {
                acc[key] = [this.config.sizeMappingForEmarsys[obj.value]];
            } else if (key === PREFERENCES_BIRTHDAY) {
                acc[key] = this.convertDateString(obj.value);
            } else if (key.startsWith(PREFERENCES_INTEREST)) {
                return acc;
            } else {
                acc[key] = obj.value;
            }

            return acc;
        }.bind(this), {});
        const fullData = Object.assign(formObj, interests, { uid: this.config.uid });
        const dataForSubmit = Object.keys(fullData).map((key) => {
            return {
                name: key,
                value: formObj[key]
            };
        });

        return dataForSubmit;
    }

    onSubmit(el, ev) {
        this.$.successMsg.addClass('h-hide');
        ev.preventDefault();

        const isValid = super.onSubmit();

        if (isValid) {
            const $form = this.$el,
                url = $form.attr('action'),
                data = this.preparePreferencesDataForSubmit($form.serializeArray());

            ajax.getJson({
                url: this.config.uid ? appendParamToURL(url, 'uid', this.config.uid) : url,
                type: 'POST',
                data
            }).then((resp) => {
                this.handleMessages(resp);
            }).catch(() => {
                this.handleMessages({ error: true });
            });
        }

        return isValid;
    }

    onValidateBirthday(cmp) {
        return validateDate(cmp.getValue());
    }

    init() {

        this.$ = {};

        for (let k of Object.keys(SELECTORS)) {
            this.$[k] = this.$el.find(SELECTORS[k]);
        }

        inputmask(
            'datetime',
            {
                inputFormat: 'dd.mm.yyyy',
                placeholder: '_',
                min: '01.01.1901',
                max: getCurrentDateAsString()
            }
        )
            .mask(SELECTORS.birthday);
    }
}

export default NewsletterPreferencesForm;
