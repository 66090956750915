//import Tooltip from 'client/components/Tooltip';
import Component from 'client/core/Component';
import { redirectTo } from 'client/utils/common';
import { isManageGoogleMapByConsent, isManageShopTheLookByConsent } from 'sitePreferences';
import Tooltip from 'client/components/Tooltips';
import { CONSENT_MESSAGE_SHOPTHELOOK, CONSENT_MESSAGE_STOREFINDER } from 'resources';

const CONSENT_TYPE = {
    SHOPTHELOOK: 'shopthelook',
    GOOGLEMAP: 'googlemap'
};

export default class ConsentTooltip extends Component {
    init () {
        if (window.consentStatus) {
            return;
        }

        if (this.config.consentType === CONSENT_TYPE.SHOPTHELOOK && !isManageShopTheLookByConsent) {
            return;
        }

        if (this.config.consentType === CONSENT_TYPE.GOOGLEMAP && !isManageGoogleMapByConsent) {
            return;
        }


        this.consentMessage = this.config.consentType === CONSENT_TYPE.GOOGLEMAP ?
            CONSENT_MESSAGE_STOREFINDER :
            CONSENT_MESSAGE_SHOPTHELOOK;

        this.toolTip = new Tooltip(this.$el, this.consentMessage, this.acceptConsent.bind(this));

        this.bindEvent('click', this.$el, (el, ev) => {
            ev.stopPropagation();
            ev.preventDefault();

            this.toolTip.showTooltip();
            return;
        });
    }

    acceptConsent() {
        window.dispatchEvent(new CustomEvent('apply.consent.cookie', { detail: { consentStatus: true } }));
        if (this.config.targetUrl) {
            redirectTo(this.config.targetUrl);
        }
    }
}
