import Component from 'client/core/Component';
import $ from 'jquery';

const SELECTORS = {
    availability: '.b-product-tile-badge--release',
    releaseDate: '.js-plp-release-date, .js-release-date'
};

export default class ProductTiles extends Component {
    init() {
        this.updateAllTilesReleaseDates();
    }

    updateAllTilesReleaseDates() {
        this.updateTilesReleaseDates(this.$el);
    }

    /**
     * @param {JQuery} $grid Ajax-loaded products grid or this.$el
     */
    updateTilesReleaseDates($grid) {
        const $releaseDateObjs = $grid.find(SELECTORS.availability);

        if ($releaseDateObjs && $releaseDateObjs.length) {
            $.each($releaseDateObjs, (i, releaseDateObj) => {
                let $releaseDateObj = $(releaseDateObj);
                let releaseDateUTC = $releaseDateObj.data('release-date');
                let $releaseDate = $releaseDateObj.find(SELECTORS.releaseDate);

                if (releaseDateUTC && $releaseDate.length) {
                    $releaseDate.html(this.formatDate(new Date(releaseDateUTC), false));
                }
            });
        }

        // if that was a ajax load, return grid in order to use it as response
        return $grid;
    }
}
