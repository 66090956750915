import Component from 'client/core/Component';
import { getCustomReferer } from 'client/utils/history';
import { redirectTo } from 'client/utils/common';
import { parseURL } from 'client/utils/url';
import { isSmall, isMedium } from 'client/utils/screendetector';

export default class BackButton extends Component {
    init () {
        this.isMobile = isSmall() || isMedium();

        this.bindEvent('click', this.onClick);
    }

    onClick (el, e) {
        e.preventDefault();

        if (this.isMobile && this.config.plpUrl) {
            redirectTo(this.config.plpUrl);
            return;
        }

        if (window.SnipesAppIntegration.isRNWebView()) {
            try {
                window.SnipesAppIntegration.sendEventToMobileApp({
                    type: 'navigation.back'
                }, true);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log('There was something wrong with APP');
            }
        } else {
            let referer = getCustomReferer();

            if (referer) {
                redirectTo(referer);
                return;
            }

            referer = document.referrer;

            try {
                let hostURL = parseURL(referer);

                // eslint-disable-next-line no-restricted-globals
                if (location.hostname === hostURL.hostname) {
                    // eslint-disable-next-line no-restricted-globals
                    if (history.length > 1) {
                        // eslint-disable-next-line no-restricted-globals
                        history.back();

                        return;
                    }

                    redirectTo(referer);

                    return;
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(`There was something wrong with URL: ${referer}. The error is: ${error}`);
            }

            if (this.config.homeUrl) {
                redirectTo(this.config.homeUrl);
            }
        }
    }
}
