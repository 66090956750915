module.exports = function(Handlebars) {
    if (typeof Handlebars === 'undefined') {
        return;
    }

    // eslint-disable-next-line complexity
    function compare(v1, operator, v2) {
        var resultValue = false;

        switch (operator) {
            case '==':
                // eslint-disable-next-line
                resultValue = (v1 == v2);
                break;
            case '===':
                resultValue = (v1 === v2);
                break;
            case '!=':
                // eslint-disable-next-line
                resultValue = (v1 != v2);
                break;
            case '!==':
                // eslint-disable-next-line
                resultValue = (v1 !== v2);
                break;
            case '<':
                resultValue = (v1 < v2);
                break;
            case '<=':
                resultValue = (v1 <= v2);
                break;
            case '>':
                resultValue = (v1 > v2);
                break;
            case '>=':
                resultValue = (v1 >= v2);
                break;
            case '&&':
                resultValue = (v1 && v2);
                break;
            case '||':
                resultValue = (v1 || v2);
                break;
        }

        return resultValue;
    }

    Handlebars.registerHelper('ifCond', function(v1, operator, v2, options) { // eslint-disable-line
        return compare(v1, operator, v2) ? options.fn(this) : options.inverse(this);
    });

    Handlebars.registerHelper('or', function() {

        var args = Array.prototype.slice.call(arguments);
        var options = args.pop();

        for (var i = 0; i < args.length; i++) {
            if (args[i]) {
                return args[i];
            }
        }
        return false;
    });

    Handlebars.registerHelper('ifNotCond', function(v1, operator, v2, options) { // eslint-disable-line
        return compare(v1, operator, v2) ? options.inverse(this) : options.fn(this);
    });

    Handlebars.registerHelper('toLowerCase', function(str) {
        return str.toLowerCase();
    });

    Handlebars.registerHelper('ifIn', function(elem, list, options) {
        return (list.indexOf(elem) > -1) ? options.fn(this) : options.inverse(this);
    });

    Handlebars.registerHelper('toSafeJSON', function(object) {
        return new Handlebars.SafeString(JSON.stringify(object));
    });

    Handlebars.registerHelper('toUnsafeJSON', function(object) {
        return JSON.stringify(object);
    });

    Handlebars.registerHelper('ifSomeIsTrue', function() { // eslint-disable-line
        var options = arguments[arguments.length - 1];

        if (arguments.length < 3 || (arguments.length > 3 && (arguments.length) % 3)) {
            return options.inverse(this);
        }

        var data = Array.prototype.slice.call(arguments, 0, arguments.length - 1);

        var isTrue = false;

        for (var i = 0, l = data.length - 1; i < l; i++) {
            var property = data[i];
            var list = data[++i];
            var operator = data[++i] || '||';

            isTrue = compare(isTrue, operator, property && list && Array.isArray(list) && list.some(function (item) { // eslint-disable-line no-loop-func
                return item[property];
            }));
        }

        return isTrue ? options.fn(this) : options.inverse(this);
    });

    Handlebars.registerHelper('decimalWithCommaDelimeter',
        /**
         * @param {number | string} number
         */
        function (number) {
            if (!number) { return number; }

            var splitNumber = number.toString().split('.');

            if (!splitNumber[1]) {
                splitNumber[1] = '00';
            } else if (splitNumber[1].length === 1) {
                splitNumber[1] += '0';
            }

            return splitNumber.join(',');
        }
    );
};
