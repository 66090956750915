import Component from 'client/core/Component';

const SELECTORS = {
    'SEARCH': '.js-packstation-search-input',
    'BUTTON': '.js-pick-up-station-search-button',
    'LOCATION': '.js-pick-up-station-search-location',
    'MODAL': '.js-pick-up-station-modal',
    'EDIT': '.js-edit-pickup-station',
    'POST_NUMBER_BLOCK': '.js-post-number-block',
    'SEARCH_BLOCK': '.js-pickup-search-block',
    'STATION_FIELDS': '.js-pickup-station-fields',
    'STATION_RESULT': '.js-pickup-station-result',
    'STATION_HINT': '.js-pickup-station-hint'
};

const CARRIERS = ['dhl', 'hermes', 'sda', 'ups'];
const PICK_UP_STATION_FIELDS = ['packstationNumber', 'postOfficeNumber', 'carrierStationId'];

export default class PickUpStation extends Component {
    init() {
        this.$searchButton = this.$el.find(SELECTORS.BUTTON);
        this.$searchBlock = this.$el.find(SELECTORS.SEARCH_BLOCK);
        this.$stationFields = this.$el.find(SELECTORS.STATION_FIELDS);
        this.$stationResult = this.$el.find(SELECTORS.STATION_RESULT);
        this.$postNumberBlock = this.$el.find(SELECTORS.POST_NUMBER_BLOCK);
        this.$stationHint = this.$el.find(SELECTORS.STATION_HINT);
        this.$searchByLocation = false;
        this.bindEvent('keyup', SELECTORS.SEARCH, () => this.onInputChange());
        this.bindEvent('click', SELECTORS.BUTTON, () => this.startSearchByValue());
        this.bindEvent('click', SELECTORS.LOCATION, () => this.startSearchByLocation());
        this.bindEvent('click', SELECTORS.EDIT, () => this.showSearchBlock());
        this.emitter.addListener('addressBook.shipping.updated.pickup', f => this.fillPickUpStationAddressBlock(f));
        this.emitter.addListener('search.by.value', () => this.setSearchValue());
        this.emitter.addListener('search.by.location', () => this.initLocationSearch());
        this.pickUpStationComponent = this.getNestedComponentById('pick-up-station-search');

        if (this.pickUpStationComponent && this.pickUpStationComponent.getValue().length > 0) {
            this.$searchButton.removeAttr('disabled');
        }
    }

    initLocationSearch() {
        if (this.$searchByLocation) {
            this.emitter.emit('search.by.location.init');
        }
    }

    setSearchValue() {
        let searchInput = this.getNestedComponentById('pick-up-station-search');

        if (searchInput.getValue().length > 0 && !this.$searchByLocation) {
            this.emitter.emit('search.by.value.init', searchInput.getValue());
        }
    }

    showSearchBlock () {
        this.$el.find(this.$searchBlock).removeClass('h-hide');
        this.$el.find(this.$stationFields).addClass('h-hide');
        this.$el.find(this.$stationResult).addClass('h-hide');
    }

    hideSearchBlock () {
        this.$el.find(this.$stationFields).removeClass('h-hide');
        this.$el.find(this.$stationResult).removeClass('h-hide');
        this.$el.find(this.$searchBlock).addClass('h-hide');
    }

    fillPickUpStationAddressBlock(fields) {
        PICK_UP_STATION_FIELDS.forEach(field => {
            this.$el.find(`.js-pickup-${field}`).empty();
            if (fields[field]) {
                fields.stationType += ' ' + fields[field];
            }
        });
        for (const key in fields) { // eslint-disable-line no-restricted-syntax
            if (Object.prototype.hasOwnProperty.call(fields, key) && key !== 'id') {
                let field = this.$el.find(`.js-pickup-${key}`);

                field.html(fields[key]);
            }
        }

        CARRIERS.forEach(carrier => {
            this.$el.find(`.js-pickup-${carrier}-logo`).addClass('h-hide');
        });
        this.$el.find(`.js-pickup-${fields.carrier}-logo`).toggleClass('h-hide');

        this.hideSearchBlock();
        let postOfficeField = this.getNestedComponentById('shipping-postNumber');
        let carrierStationIdField = this.getNestedComponentById('shipping-carrierStationId');
        let carrierStationNameField = this.getNestedComponentById('shipping-carrierStationName');

        if (fields.carrier === 'dhl') {
            this.$postNumberBlock.removeClass('h-hide');
            this.$stationHint.removeClass('h-hide');
            postOfficeField.config.required = true;
            carrierStationIdField.config.required = false;
            carrierStationNameField.config.required = false;
        } else {
            this.$postNumberBlock.addClass('h-hide');
            this.$stationHint.addClass('h-hide');
            postOfficeField.config.required = false;
            carrierStationIdField.config.required = true;
            carrierStationNameField.config.required = true;
        }
    }

    onInputChange () {
        let cmp = this.getNestedComponentById('pick-up-station-search');

        if (cmp.getValue().length > 0) {
            this.$searchButton.removeAttr('disabled');
        } else {
            this.$searchButton.attr('disabled', true);
        }
    }

    startSearchByValue () {
        let searchInput = this.getNestedComponentById('pick-up-station-search');

        this.$searchByLocation = false;
        if (searchInput.getValue().length > 0) {
            this.emitter.emit('show.modal.search');
        }
    }

    startSearchByLocation () {
        this.emitter.emit('show.modal.search');
        this.$searchByLocation = true;
    }
}
