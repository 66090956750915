import Refinement from 'client/components/search/Refinement';
import { stringToInteger } from 'client/utils/string';
import { extractParamFromURL } from 'client/utils/url';

const SELECTORS = {
    PRICE_MIN_SELECTOR: '.js-filter-min-price',
    PRICE_MAX_SELECTOR: '.js-filter-max-price',
    ONLY_SALES_SELECTOR: '.js-only-sale-price',
    DOUBLE_PRICE_MIN_SELECTOR: '.js-double-filter-min-price',
    DOUBLE_MAX_SELECTOR: '.js-double-filter-max-price',
    DOUBLE_PRICE_BLOCK: '.js-double-price-block'
};

export default class PriceRefinement extends Refinement {
    init () {
        super.init();

        this.$maxPriceEl = this.$el.find(SELECTORS.PRICE_MAX_SELECTOR);
        this.$minPriceEl = this.$el.find(SELECTORS.PRICE_MIN_SELECTOR);
        this.$onlySaleEl = this.$el.find(SELECTORS.ONLY_SALES_SELECTOR);
        this.$doubleMaxPriceEl = this.$el.find(SELECTORS.DOUBLE_MAX_SELECTOR);
        this.$doubleMinPriceEl = this.$el.find(SELECTORS.DOUBLE_PRICE_MIN_SELECTOR);
        this.$doublePriceBlock = this.$el.find(SELECTORS.DOUBLE_PRICE_BLOCK);
        if (this.$doublePriceBlock) {
            this.exchangeRate = this.$doublePriceBlock.data('rate');
        }
        this.updatePriceElements();

        this.initialState = {
            min: this.$minPriceEl.val(),
            max: this.$maxPriceEl.val(),
            onlySale: this.$onlySaleEl.get(0).checked
        };

        this.bindEvent('change', SELECTORS.ONLY_SALES_SELECTOR, () => this.onChange());
        this.bindEvent(
            'keyup change focusout',
            `${SELECTORS.PRICE_MAX_SELECTOR},${SELECTORS.PRICE_MIN_SELECTOR}`,
            () => this.onChange()
        );

        this.bindEvent('change', SELECTORS.PRICE_MAX_SELECTOR, this.checkMaxValue);
        this.bindEvent('change', SELECTORS.PRICE_MIN_SELECTOR, this.checkMinValue);
    }

    onChange() {
        super.changed = this.isRefinementStateChanged();
        super.changeButtonState();

        this.emitter.emit('refinement.selectionChanged', {
            device: this.device,
            min: this.$minPriceEl.val(),
            max: this.$maxPriceEl.val(),
            onlySale: this.$onlySaleEl.get(0).checked,
            attr: this.config.attr
        });
    }

    /**
     * @param {String} value
     * @returns {String}
     */
    formatValue(value) {
        let floatRegex = /[^0-9].*/gi,
            priceRegex = /^0|[^\d]/gi,
            zeroRegex = /^0$/,
            maxlength = 4;

        if (value.match(zeroRegex) !== null || value === '') {
            return value;
        }

        value = value.replace(floatRegex, '');
        value = value.replace(priceRegex, '');
        value = value.slice(0, maxlength);

        return value;
    }

    isRefinementStateChanged() {
        // Remove characters that is not numbers
        if (!this.$maxPriceEl.val()) {
            this.$maxPriceEl.get(0).value = '';
        }

        if (!this.$minPriceEl.val()) {
            this.$minPriceEl.get(0).value = '';
        }


        if (this.$maxPriceEl.val() !== this.initialState.max) {
            this.$maxPriceEl.val(this.formatValue(this.$maxPriceEl.val()));
        }

        if (this.$minPriceEl.val() !== this.initialState.min) {
            this.$minPriceEl.val(this.formatValue(this.$minPriceEl.val()));
        }

        return this.$maxPriceEl.val() !== this.initialState.max ||
            this.$minPriceEl.val() !== this.initialState.min ||
            this.$onlySaleEl.get(0).checked !== this.initialState.onlySale;
    }

    onRefinementChange(data) {
        if (this.config.attr === data.attr && this.config.device !== data.device) {
            this.$maxPriceEl.val(data.max);
            this.$minPriceEl.val(data.min);
            this.$onlySaleEl.get(0).checked = data.onlySale;

            super.changed = this.isRefinementStateChanged();
            super.changeButtonState();
        }
    }


    applyFilter() {
        let data = {
            attribute: 'price',
            type: 'price',
            values: {
                min: this.$minPriceEl.val(),
                max: this.$maxPriceEl.val(),
                onlySale: this.$onlySaleEl.get(0).checked
            }
        };

        // Let parent component reload grid with refined search
        this.emitter.emit('refinement.applyFilter', data);

        super.applyFilter();
    }

    getPrices() {
        let sMinPrice = this.$minPriceEl.val(),
            sMaxPrice = this.$maxPriceEl.val();

        return {
            sMinPrice: sMinPrice,
            sMaxPrice: sMaxPrice,
            minPrice: stringToInteger(sMinPrice),
            maxPrice: stringToInteger(sMaxPrice)
        };
    }

    checkMaxValue() {
        let prices = this.getPrices(),
            finalValue;

        if (prices.maxPrice && prices.maxPrice < prices.minPrice) {
            finalValue = prices.sMinPrice;
        } else {
            finalValue = prices.sMaxPrice;
        }

        if (!isNaN(finalValue) && parseInt(finalValue, 10) > 0) {
            this.$maxPriceEl.val(finalValue);
            if (this.$doubleMaxPriceEl) {
                this.$doubleMaxPriceEl.empty();
                finalValue = (finalValue / this.exchangeRate).toFixed(2);
                this.$doubleMaxPriceEl.append(finalValue || '-');
            }
        } else {
            this.$maxPriceEl.val('');
            if (this.$doubleMaxPriceEl) {
                this.$doubleMaxPriceEl.empty();
                this.$doubleMaxPriceEl.append('-');
            }
        }
    }

    checkMinValue() {
        let prices = this.getPrices(),
            finalValue;

        if (prices.maxPrice && prices.maxPrice < prices.minPrice) {
            finalValue = prices.sMaxPrice;
        } else {
            finalValue = prices.sMinPrice;
        }

        if (!isNaN(finalValue) && parseInt(finalValue, 10) >= 0) {
            this.$minPriceEl.val(finalValue);
            if (this.$doubleMinPriceEl) {
                this.$doubleMinPriceEl.empty();
                finalValue = (finalValue / this.exchangeRate).toFixed(2);
                this.$doubleMinPriceEl.append(finalValue || '-');
            }
        } else {
            this.$minPriceEl.val('');
            if (this.$doubleMinPriceEl) {
                this.$doubleMinPriceEl.empty();
                this.$doubleMinPriceEl.append('-');
            }
        }
    }

    updatePriceElements() {
        let priceMin = extractParamFromURL(location.href, 'pmin'),
            priceMax = extractParamFromURL(location.href, 'pmax');

        if (priceMin && !isNaN(priceMin)) {
            this.$minPriceEl.val(priceMin);
        }

        if (priceMax && !isNaN(priceMax)) {
            this.$maxPriceEl.val(priceMax);
        }
    }
}
