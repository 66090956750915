'use strict';

import $ from 'jquery';

function getAuthData() {
    var token = $('#username').val() + ':' + $('#password').val();

    return btoa(token);
}

// eslint-disable-next-line wrap-iife
(function basicAuth() {
    var preloader = $('div').find('[data-cmp="spinnerPreloader"]');

    preloader.remove();

    $('#submitForm').on('click', function() {
        let authData = getAuthData();
        let actionUrl = $('#basicAuthForm').data('actionUrl');

        window.location.href = actionUrl + '?' + authData;
    });
})();
