import Component from 'client/core/Component';

import $ from 'jquery';
import Headroom from 'headroom.js';

const SELECTORS = {
    headerSticky: '.js-header-sticky'
};

const headroomOptions = {
    tolerance: {
        up: 5,
        down: 5
    },
    classes: {
        initial: 'b-header',
        pinned: 'h-header-pinned',
        unpinned: 'h-header-hidden'
    }
};

export default class HideableHeader extends Component {
    init() {
        this.$headerSticky = $(SELECTORS.headerSticky);
        headroomOptions.offset = this.$headerSticky.innerHeight();
        const headroom = new Headroom(this.$el.get(0), headroomOptions);

        headroom.init();

        this.headerHeight = {
            regular: 0,
            sticky: 0
        };

        this.calculateHeight();

        this.emitter.addListener('deviceChange', this.calculateHeight.bind(this));
        this.emitter.addListener('resize', this.calculateHeight.bind(this));
        this.emitter.addListener('orientationchange', this.calculateHeight.bind(this));

        this.emitter.addListener('mobileCartPopupDisplayed', () => {
            headroom.freeze();
            headroom.pin();
        });

        this.emitter.addListener('mobileCartPopupHidden', () => {
            headroom.unfreeze();
        });
    }

    calculateHeight() {
        this.headerHeight.regular = this.$headerSticky.height();
    }
}
