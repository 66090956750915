import Form from 'client/core/Form';
import { getJson } from 'client/utils/ajax';
import { CLASSES } from 'client/utils/globals';
import { getToken } from 'urls';
import { scrollToMsg, getCurrentDateAsString, validateDate } from 'client/utils/common';
import inputmask from 'inputmask';

const SELECTORS = {
    successBlock: '.js-success',
    failBlock: '.js-fail',
    fieldsContainer: '.js-fields-container',
    fieldsContainerContent: '.js-fields-container-content',
    contestDescription: '.js-fields-container-description'
};

const INPUTS = {
    birthday: '[name="{FORM}_birthday"]'
};

export default class ContestForm extends Form {
    beforeSubmit () {
        this.config.emitEventBeforeSubmit &&
        this.emitter.emit(this.config.emitEventBeforeSubmit);
    }

    async onSubmit (el, e) {
        e.preventDefault();

        let isValid = await super.onSubmit();

        if (isValid) {
            let data = this.collectDataForAction(el);
            let csrfToken = (await getJson({
                type: 'POST',
                url: getToken
            })).csrf;

            if ('beforeSubmit' in this && typeof this.beforeSubmit === 'function') {
                this.beforeSubmit();
            }

            this.emit('submit.before');

            //Apply new csrf token for cached form
            for (let i = 0; i < data.length; i++) {
                if ('name' in data[i] && data[i].name === csrfToken.tokenName) {
                    data[i].value = csrfToken.token;
                    break;
                }
            }

            try {
                let callResult = await getJson({
                    url: this.config.actionUrl,
                    type: 'POST',
                    dataType: 'JSON',
                    data: data
                });

                if (callResult.error || callResult.accountStatus === 'error') {
                    this.onFailSubmit();
                    this.emit('submit.fail');

                    isValid = false;
                } else {
                    this.onSuccessSubmit(callResult);
                    this.emit('submit.success');
                }
            } catch (err) {
                this.onFailSubmit();
                this.emit('submit.fail');

                isValid = false;
            }

            this.emit('submit.done');
        }

        return isValid;
    }

    hideFieldsContainer (content) {
        if (content) {
            this.$el.find(content).addClass(CLASSES.hide);
        } else {
            this.$el.find(SELECTORS.fieldsContainerContent).addClass(CLASSES.hide);
            this.$el.find(SELECTORS.contestDescription).addClass(CLASSES.hide);
            scrollToMsg(this.$el.find(SELECTORS.fieldsContainer));
        }
    }

    onFailSubmit () {
        this.hideFieldsContainer(SELECTORS.fieldsContainerContent);
        this.$el.find(SELECTORS.failBlock).removeClass(CLASSES.hide);
        scrollToMsg(this.$el.find(SELECTORS.fieldsContainer));
        this.emit('submit.fail');
    }

    onValidateBirthday(cmp) {
        return validateDate(cmp.getValue());
    }

    onSuccessSubmit (data) {

        if (this.emarsysTrackingJsEnabled) {
            this.emitter.emit('emarsys.set.email', data.cuid);
        }

        this.hideFieldsContainer();
        this.$el.find(SELECTORS.failBlock).addClass(CLASSES.hide);

        if (
            data.accountStatus === 'accountcreated' ||
            data.accountStatus === 'submitted' ||
            data.accountStatus === 'accountexists'
        ) {
            this.$el.find(SELECTORS.successBlock).removeClass(CLASSES.hide);
        }

        setTimeout(() => this.emitter.emit('hideFvpModal'), 5000);
        this.emit('submit.success');
    }

    resolveSelector(selector) {
        return selector.replace('{FORM}', this.form);
    }

    init() {
        this.form = this.$el.data('form');

        this.FIELD_PATTERN = new RegExp(`^${this.form}_(.+)$`, 'i');

        this.$ = {};

        for (let k of Object.keys(SELECTORS)) {
            this.$[k] = this.$el.find(this.resolveSelector(SELECTORS[k]));
        }

        inputmask(
            'datetime',
            {
                inputFormat: 'dd.mm.yyyy',
                placeholder: '_',
                min: '01.01.1901',
                max: getCurrentDateAsString()
            }
        )
            .mask(this.resolveSelector(INPUTS.birthday));
    }
}
