import Component from 'client/core/Component';
import * as GLOBAL_CLASSES from 'client/utils/globals';

import $ from 'jquery';
import ajax from 'client/utils/ajax';
import { scrollTo } from 'client/utils/common';
import {
    trackBopisStoreData,
    getFavouriteStoreAttrValue
} from 'client/utils/storefinderHelper';

const SELECTORS = {
    fitFinderLink: '.js-fit-finder-link', // TODO: v.fastov - should be added to FitFinder link
    attributeBtn: '.js-pdp-attribute-btn',
    selectedColor: '.js-pdp-attributes--color .b-swatch-value--selected',
    sizeAttribututes: '.js-pdp-attributes--size',
    sizeBtn: '.js-pdp-attribute-btn--size',
    selectedSize: '.js-pdp-attributes--size .b-swatch-value--selected',
    attributeTile: '.js-pdp-attribute-tile',
    productName: '.js-product-name',
    pdpFindInStoreButton: '.js-pdp-find-in-store',
    findInStoreWrap: '.js-find-in-store',
    productMainTabs: '.js-pdp-main-tabs',
    productTabInput: '.js-tabs-input',
    releaseDate: '[data-release-date]',
    carouselItem: '.js-pdp-carousel-item',
    slickItem: '[data-slick-index]',
    addToCartBtn: '.js-btn-add-to-cart',
    promo: '.js-promo-callout-sticky',
    productVariationSection: '.js-pdp-variation-section'
};

const CLASSES = {
    notReadyToAddClicked: 'h-not-ready-to-add-clicked',
    selected: 'b-swatch-value--selected',
    modelCartEdit: 'js-modal-cart-edit',
    productSmallTabs: 'js-pdp-small-tabs',
    notReadyToAdd: 'js-not-ready-to-add',
    promotions: 'b-pdp-promotions',
    promoMsg: 'b-promo-callout'
};

export default class ProductMgr extends Component {
    init() {
        this.$elements = {};

        this.$elements.productName = this.$el.find(SELECTORS.productName);

        this.bindEvent('click', SELECTORS.attributeBtn, this.onAttributeClick);
        this.emitter.addListener(
            'product.attribute.clicked',
            this.onAttributeClick.bind(this)
        );
        this.emitter.addListener(
            'product.refresh',
            this.onRefreshRequest.bind(this)
        );
        this.emitter.addListener(
            'product.addtocart.clicked',
            this.showNotReadyToAddState.bind(this)
        );
        this.bindEvent('click', SELECTORS.carouselItem, (el, ev) => this.triggerPhotoswipeInit(el, ev));
        this.bindEvent('touchstart', SELECTORS.carouselItem, (el, ev) => {
            if (ev.touches.length > 1) {
                ev.preventDefault();
                this.triggerPhotoswipeInit(el, ev);
            }
        });

        if (!this.$el.hasClass('js-modal-cart-edit')) {
            this.emitter.emit('pdp.loaded', this.config);
        }

        if (this.gtmEnabled) {
            this.bindEvent(
                'click',
                SELECTORS.fitFinderLink,
                this.trackFitFinderClick
            );
            if (this.config.gtm) {
                this.trackProductDetail(
                    this.config.gtm,
                    window.isDirectRequest
                );
            }
        }

        this.pdpFindInStoreButton = $(SELECTORS.pdpFindInStoreButton);
        this.findInStoreWrap = this.pdpFindInStoreButton.find(
            SELECTORS.findInStoreWrap
        );

        if (!this.findInStoreWrap.length) {
            this.pdpFindInStoreButton.addClass(GLOBAL_CLASSES.CLASSES.hide);
            this.pdpFindInStoreButton
                .parent()
                .addClass(GLOBAL_CLASSES.CLASSES.oneCta);
        }

        this.synchronizeProductTabs();

        window.addEventListener('popstate', function () {
            var url = window.location.href;
            var productVariationSection = $(SELECTORS.productVariationSection);
            var colorSwatch = productVariationSection.find(`.js-pdp-attribute-btn[data-visible-url='${url}']`);

            if (colorSwatch && colorSwatch.length) {
                colorSwatch.trigger('click');
            } else {
                // In case if visible url that matches browser url is not found,
                // we go through the collection of all color swatch urls and
                // find the most relevant one for current page to click to it.
                var allVisibleUrls = Array
                    .from(document.querySelectorAll('[data-visible-url]'))
                    .map(function (element) {
                        return element.getAttribute('data-visible-url');
                    });
                var relevantColorUrl = allVisibleUrls.find(function (visibleUrl) {
                    return url.includes(visibleUrl.replace(/\.[^.]*$/, ''));
                });
                var relevantColorSwatch = productVariationSection
                    .find(`.js-pdp-attribute-btn[data-visible-url='${relevantColorUrl}']`);

                if (relevantColorSwatch && relevantColorSwatch.length) {
                    relevantColorSwatch.trigger('click');
                }
            }
        });
    }

    async trackProductDetail(product, direct = false) {
        if (product && !this.$el.hasClass(CLASSES.modelCartEdit)) {
            if (typeof product === 'string') {
                try {
                    product = JSON.parse(product);
                } catch (e) {
                    return;
                }
            }

            const customerFavouriteStoreID = await getFavouriteStoreAttrValue();

            const eventData = {
                currencyCode: window.SitePreferences.currencyCode,
                eventLabel: customerFavouriteStoreID || 'none',
                detail: {
                    actionField: {
                        list: direct ? 'pdp entrance' : ''
                    }, // special case
                    products: [product]
                }
            };

            this.emitter.emit(
                'gtmEcommerceEvent',
                'eeProductDetail',
                eventData
            );
            trackBopisStoreData({
                context: this,
                eventAction: product.isOnlineOnly ?
                    'pdp/preselected-store (online only)' :
                    'pdp/preselected-store'
            });
        }
    }

    trackFitFinderClick() {
        const data = {
            eventCategory: 'product',
            eventAction: 'fitfinder',
            eventLabel: ''
        };

        this.emitter.emit('gtmEvent', data);
    }

    onRefreshRequest() {
        const $selectedColorBtn = this.$el
            .find(SELECTORS.selectedColor)
            .closest(SELECTORS.attributeBtn);

        if ($selectedColorBtn.length) {
            this.onAttributeClick($selectedColorBtn[0]);
        }
    }

    onAttributeClick(el, ev = null) {
        let $el;

        if (!ev && el && el.variantId) {
            $el = this.findProductAttributeElement(el.variantId);
        } else {
            ev.preventDefault();
            $el = $(el);
        }

        let url = $el.data('href');

        if (!url) {
            return false;
        }

        if (
            ev &&
            $el.children(SELECTORS.attributeTile).hasClass(CLASSES.selected)
        ) {
            // do not make a request for already selected value, when the element is clicked (not triggered)
            return false;
        }

        if (
            $el.data('attr-id') === 'size' &&
            !this.$el.find(SELECTORS.selectedColor).length
        ) {
            // select color first
            return false;
        }

        if (
            $el.data('attr-id') === 'size' &&
            !$el.children(SELECTORS.attributeTile).hasClass(CLASSES.selected)
        ) {
            $el.children(SELECTORS.attributeTile).addClass(
                GLOBAL_CLASSES.CLASSES.clicked
            );
        }

        if ($el.data('attr-id') === 'color') {
            // pre-selected size
            const selectedSize = this.$el.find(SELECTORS.selectedSize);

            if (selectedSize.length) {
                url += '&size=' + selectedSize.data('attrValue');
            }
        }

        this.emitter.emit('variation.update');

        ajax.getJson({
            url: url
        }).then((data) => {
            if (data && data.product) {
                const queryParams = data.queryParams || {};
                const product = this.updateReleaseDate(data.product);

                this.$elements.productName.html(data.product.productName);
                this.updatePromoMsg(product);

                this.emitter.emit('attribute.selected', $el);
                this.emitter.emit(
                    'variation.updated',
                    product,
                    queryParams,
                    data.photoswipeData
                );
                // this.hideStoreBtnIfSoldOut(data.product);
                if (this.gtmEnabled && $el.data('attr-id') === 'color') {
                    this.trackProductDetail(data.product.gtm);
                    this.emitter.emit('trbo.color.click', data.product, true);
                }
            }
        });

        return false;
    }

    updateReleaseDate(product) {
        const $releaseDate = this.$el.find(SELECTORS.releaseDate),
            releaseDateUTC = $releaseDate.data('release-date'),
            isCountdown = $releaseDate.data('countdown');

        if (releaseDateUTC) {
            const updated = this.formatDate(
                new Date(releaseDateUTC),
                isCountdown
            );

            product &&
                product.availability &&
                product.availability.custom &&
                (product.availability.custom.addToCartButtonLabel = updated);
        }

        return product;
    }

    updatePromoMsg(product) {
        const $callout = this.$el.find(SELECTORS.promo);

        $callout.empty();

        if (product.promotions && product.promotions.length > 0) {
            let $element = $(document.createElement('div')).addClass(
                CLASSES.promotions
            );

            product.promotions.forEach((promo) => {
                if (promo.calloutMsg) {
                    const promoElem = $(document.createElement('div'))
                        .addClass(CLASSES.promoMsg)
                        .attr('title', promo.details)
                        .html(promo.calloutMsg);

                    $element.append(promoElem);
                }
            });

            $callout.append($element);
        }
    }

    synchronizeProductTabs() {
        this.$el.find(SELECTORS.productTabInput).on('change', (e) => {
            let currentTabName = e.target.getAttribute('data-synchronize');

            if ($(e.target).parent().hasClass(CLASSES.productSmallTabs)) {
                scrollTo(this.$el.find(SELECTORS.productMainTabs));
            }

            this.$el.find(SELECTORS.productTabInput).each((idx, el) => {
                let $item = $(el);

                if (
                    $item.data('synchronize') === currentTabName &&
                    !$item.prop('checked')
                ) {
                    $item.prop('checked', true);
                }
            });
        });
    }

    triggerPhotoswipeInit(el, ev) {
        const $slickCarouselItem = $(ev.target).closest(SELECTORS.slickItem);

        this.emitter.emit('init.photoswipe.gallery', {
            $slickCarouselItem,
            touchLength: ev && ev.touches && ev.touches.length,
            touchEvent: ev
        });
    }

    findProductAttributeElement(variantID) {
        const sizesContainer = this.$el.find(SELECTORS.sizeAttribututes);
        const selector = `${SELECTORS.sizeBtn}[data-variant-id="${variantID}"]`;
        const $variantEl = sizesContainer.find(selector);

        return $variantEl;
    }

    hideStoreBtnIfSoldOut(product) {
        if (
            product &&
            product.bopisAvailabilityData &&
            product.bopisAvailabilityData.addToCartButton &&
            product.bopisAvailabilityData.addToCartButton.isSoldOut
        ) {
            this.$el
                .find(SELECTORS.findInStoreWrap)
                .addClass(GLOBAL_CLASSES.CLASSES.hide);
        }
    }

    getAddToCartBtn() {
        var addToCartButton = this.$el.find(SELECTORS.addToCartBtn);

        return addToCartButton[0];
    }

    showNotReadyToAddState() {
        this.$el
            .find(SELECTORS.addToCartBtn)
            .addClass(CLASSES.notReadyToAddClicked);
    }

    // eslint-disable-next-line class-methods-use-this
    getSizeChartLink() {
        return '';
    }

    getCurrentSize() {
        return this.$el.find(SELECTORS.selectedSize).data('attrValue');
    }

    addToCart(productId, size) {
        if (!productId || !size) {
            // eslint-disable-next-line no-console
            console.log('Incorrect parameters');
        }

        if (this.selectSize(productId, size)) {
            const interval = setInterval(() => {
                const addtoCartButton = this.$el.find(
                    SELECTORS.addToCartBtn
                )[0];
                const selectedSize = this.$el.find(SELECTORS.selectedSize);

                if (
                    !$(addtoCartButton).hasClass(CLASSES.notReadyToAdd) &&
                    $(selectedSize).parent().data('value') &&
                    $(selectedSize).parent().data('value').toString() === size
                ) {
                    $(addtoCartButton).trigger('click');
                    clearInterval(interval);
                }
            }, 500);
        }
    }

    selectSize(productId, size) {
        if (!productId || !size) {
            // eslint-disable-next-line no-console
            console.log('Incorrect parameters');
        }

        if (
            [
                this.config.pid.toString(),
                this.config.articleCode.toString()
            ].includes(productId)
        ) {
            const sizesContainer = this.$el.find(SELECTORS.sizeAttribututes);
            const selector = `${SELECTORS.sizeBtn}[data-value="${size}"]`;
            const $variantEl = sizesContainer.find(selector);

            $variantEl.trigger('click');

            return true;
        }

        return false;
    }

    // eslint-disable-next-line class-methods-use-this
    loadCartItems() {
        var response = ajax.getJson({
            url: window.Urls.getFitAnalyticsData,
            async: false,
            ignoreCache: true,
            type: 'GET'
        });

        if ('fitAnalyticsData' in response.responseJSON) {
            return response.responseJSON.fitAnalyticsData;
        }

        return {};
    }

    getCartItems() {
        return this.loadCartItems();
    }
}
