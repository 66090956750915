import Component from 'client/core/Component';

import { CLASSES } from 'client/utils/globals';
import { scrollTo } from 'client/utils/common';

import $ from 'jquery';

const DEFAULT_CONFIG = {
    namespace: 'global'
};

const DEFAULT_STATE = {
    type: '',
    message: ''
};

export default class InfoBlock extends Component {
    init () {
        this.config = Object.assign({}, DEFAULT_CONFIG, this.config);

        this.stateClasses = this.getStateClasses();

        this.$messageContainer = this.$el.find(`.${this.config.messageContainer}`);

        if (!this.$messageContainer.length) {
            this.$messageContainer = this.$el;
        }

        if (this.config.scrollToContainer) {
            this.$scrollToElement = $(`.${this.config.scrollToContainer}`);
        } else {
            this.$scrollToElement = this.$el;
        }

        this.emitter.addListener(`${this.config.namespace}.infoblock.show`, this.show.bind(this));
        this.emitter.addListener(`${this.config.namespace}.infoblock.hide`, this.hide.bind(this));
        this.emitter.addListener(`${this.config.namespace}.infoblock.${this.config.id}.show`, this.show.bind(this));
        this.emitter.addListener(`${this.config.namespace}.infoblock.${this.config.id}.hide`, this.hide.bind(this));
    }

    getStateClasses () {
        let classesToRemove = [],
            regExp = /^stateType/;

        Object.keys(this.config).forEach(key => {
            regExp.test(key) && classesToRemove.push(this.config[key]);
        });

        return classesToRemove.join(' ');
    }

    show (data = DEFAULT_STATE) {
        if (data.type) {
            let stateClassName = `stateType${data.type.replace(/(^.)/, $1 => $1.toUpperCase())}`,
                stateClass = this.config[stateClassName];

            stateClass && this.$el.removeClass(this.stateClasses).addClass(stateClass);
        }

        data.message && this.$messageContainer.text(data.message);
        this.$el.removeClass(CLASSES.hide);

        setTimeout(() => {
            data.scrollTo && scrollTo(this.$scrollToElement);
        }, 100);
    }

    hide () {
        this.$el.addClass(CLASSES.hide);
    }
}
