import Modal from 'client/components/Modal';
import { CLASSES } from 'client/utils/globals';
import $ from 'jquery';
import ajax from 'client/utils/ajax';

const SELECTORS = {
    modal: '.js-modal',
    btn: '.js-modal-button',
    modalOpen: '.h-modal-open',
    modalContainer: '[data-cmp="modalContainer"]',
    modalCloseButton: '.js-close-btn'
};

const $body = $('body');

export default class PickUpStationModal extends Modal {
    init () {
        super.init();
        this.emitter.addListener('show.modal.search', () => this.showModal());
    }

    showModalLocation() {
        this.showModal();
        this.emitter.emit('search.location.start');
    }

    showModal (el, e) {
        e && e.preventDefault();
        let config = this.config;
        let params = {};

        Object.keys(config).forEach((key) => {
            let selectorPos = key.indexOf('Selector');

            if (~selectorPos) {
                params[key.substr(0, selectorPos)] = $(document).find(config[key]).html();
            } else {
                params[key] = config[key];
            }
        });

        if (this.config.id) {
            this.emitEvent('modal.open', { id: this.config.id, params: params });
        }

        if (params.targetUrl) {
            ajax.load({
                url: params.targetUrl
            }).then((response) => {
                params.body = response;
                this.open(params);
            });
        } else {
            this.open(params);
        }
    }

    close (params) {
        if (params && params.id) {
            const pickUpStationTypes = ['filiale', 'packstation', 'hermes', 'sda', 'ups'];

            // eslint-disable-next-line no-negated-condition
            if (pickUpStationTypes.indexOf(params.id) !== -1) {
                this.emitEvent('modal.closed', params);
            } else {
                return;
            }
        }

        this.$modalContainer && this.$modalContainer.find(SELECTORS.modal).modal('hide');
        this.emitter.emit('enabledScroll');
        $body.removeClass(CLASSES.modalOpened);
        this.isOpened(false);
    }
}
