import Namespace from 'client/core/Namespace';
import $ from 'jquery';
import { throttle, debounce } from 'lodash';

import headerSlot from 'client/components/header/HeaderContentSlot';
import headerBrowserError from 'client/components/header/HeaderBrowserError';
import accountHeader from 'client/components/header/AccountHeader';
import wishlistHeader from 'client/components/header/WishlistHeader';
import hideableHeader from 'client/components/header/HideableHeader';
import stickyButton from 'client/components/StickyButton';
import stickyButtonTopBottom from 'client/components/StickyButtonTopBottom';
import stickyPanel from 'client/components/product/StickyPanel';
import quickSearchForm from 'client/components/header/QuickSearchForm';
import improvementQuickSearchForm from 'client/components/header/ImprovementQuickSearchForm';
import headerHamburgerRedesigned from 'client/components/header/HeaderHamburgerRedesigned';
import mobileMenuSwitcher from 'client/components/header/MobileMenuSwitcher';
import inputText from 'client/components/forms/commonElements/InputText';
import inputCheckbox from 'client/components/forms/commonElements/InputCheckbox';
import inputHidden from 'client/components/forms/commonElements/InputHidden';
import inputSelect from 'client/components/forms/commonElements/InputSelect';
import submitButton from 'client/components/forms/commonElements/SubmitButton';
import inputTextarea from 'client/components/forms/commonElements/InputTextArea';
import inputAutocomplete from 'client/components/forms/commonElements/InputAutocomplete';
import globalCustomSelect from 'client/components/forms/commonElements/GlobalCustomSelect';
import globalCustomSelectWrap from 'client/components/forms/commonElements/GlobalCustomSelectWrap';
import subscribeForm from 'client/components/forms/SubscribeForm';
import contestForm from 'client/components/forms/ContestForm';
import unsubscribeForm from 'client/components/forms/UnsubscribeForm';
import headerSearchMobileShow from 'client/components/header/HeaderSearchMobileShow';
import miniCart from 'client/components/header/MiniCart';
import cartLineItem from 'client/components/cart/CartLineItem';
import cookieHint from 'client/components/CookieHint';
import backToTop from 'client/components/BackToTop';
import productBrand from 'client/components/product/ProductBrand';
import carousel from 'client/components/Carousel';
import expandBlock from 'client/components/ExpandBlock';
import heroCarousel from 'client/components/homepage/HeroCarousel';
import mediaInteraction from 'client/globalComponents/mediaInteraction';
import gtm from 'client/globalComponents/gtm';
import mobileMenu from 'client/components/MobileMenu';
import mobileMenuToggle from 'client/components/MobileMenuToggle';
import spinner from 'client/components/Spinner';
import justAddedPopup from 'client/components/JustAddedPopup';
import spinnerPreloader from 'client/components/SpinnerPreloader';
import backButton from 'client/components/BackButton';
import modal from 'client/components/Modal';
import modalContainer from 'client/components/ModalContainer';
import loginPopup from 'client/components/LoginPopup';
import giftCardPopup from 'client/components/GiftCardPopup';
import recommendations from 'client/components/Recommendations';
import tooltip from 'client/components/Tooltip';
import consentTooltip from 'client/components/ConsentTooltip';
import swatchCarousel from 'client/components/product/SwatchCarousel';
import localizationDialog from 'client/components/LocalizationDialog';
import shipToStore from 'client/components/shipToStore';
import shipToStoreModal from 'client/components/shipToStoreModal';
import pickUpStation from 'client/components/PickUpStation';
import pickUpStationModal from 'client/components/PickUpStationModal';
import dataLink from 'client/components/DataLink';
import livereach from 'client/components/product/Livereach';
import emarsys from 'client/globalComponents/Emarsys';
import trbo from 'client/globalComponents/trbo';
import heightIframe from 'client/components/HeightIframe';
import toggleLink from 'client/components/ToggleLink';
import alert from 'client/components/Alert';
// @ts-ignore
import { isLoyaltyEnabled } from 'sitePreferences';
import loginForm from 'client/components/forms/LoginForm';
import einsteinRecommendations from 'client/components/EinsteinRecommendations';
import breadcrumbs from 'client/components/Breadcrumbs';
import htmlHead from 'client/components/HtmlHead';
import initGTM from 'client/components/InitGTM';
import mainMenu from 'client/components/header/MainMenu';
import favouriteStoreButton from 'client/components/FavouriteStoreButton';
import variationAttributesColor from 'client/components/product/VariationAttributesColor';
import imageStickyPanel from 'client/components/product/ImageStickyPanel';
import collapse from 'client/components/Collapse';
import raffleCountdown from 'client/components/raffle/RaffleCountdown';
import raffleCountdownGroup from 'client/components/raffle/RaffleCountdownGroup';
import productGrid from 'client/components/product/ProductGrid';
import consentModal from 'client/components/ConsentModal';

import checkoutConfirmation from 'client/components/order/confirmation/CheckoutConfirmation';

import loyaltySignupPopup from 'client/components/loyalty/LoyaltySignupPopup';
import dashBoardProfileCard from 'client/components/loyalty/DashBoardProfileCard';

import 'client/thirdParty/bootstrap';
import 'client/utils/spinner';
import { addAppParamsToAllLinks, getAppRequiredParams, getAppParam } from 'client/utils/common';

import firstVisitPopup from 'client/components/homepage/FirstVisitPopup';

require('client/utils/screendetector');

export default class Main extends Namespace {
    getDefaultComponents () {
        let cmps = Object.assign({}, super.getDefaultComponents(), {
            mediaInteraction,
            gtm: gtm,
            emarsys,
            trbo,
            einsteinRecommendations,
            // Header
            headerSlot,
            headerBrowserError,
            accountHeader,
            wishlistHeader,
            hideableHeader,
            stickyButton,
            stickyButtonTopBottom,
            stickyPanel,
            quickSearchForm,
            improvementQuickSearchForm,
            headerHamburgerRedesigned,
            mobileMenuSwitcher,
            headerSearchMobileShow,
            miniCart,
            cartLineItem,
            backButton,
            cookieHint,
            modal,
            modalContainer,
            consentModal,

            carousel,
            heroCarousel,
            productGrid,
            productBrand,
            expandBlock,
            spinner,
            recommendations,
            justAddedPopup,
            loginPopup,
            giftCardPopup,
            loginForm,
            swatchCarousel,
            localizationDialog,
            shipToStore,
            shipToStoreModal,
            pickUpStation,
            pickUpStationModal,
            dataLink,
            livereach,
            heightIframe,
            firstVisitPopup,
            toggleLink,
            alert,
            contestForm,
            breadcrumbs,
            htmlHead,
            initGTM,
            mainMenu,
            favouriteStoreButton,
            variationAttributesColor,
            imageStickyPanel,
            collapse,
            raffleCountdown,
            raffleCountdownGroup,

            // Mobile menu components
            mobileMenu,
            mobileMenuToggle,

            // Footer
            backToTop,
            subscribeForm,
            unsubscribeForm,

            // Common form elements
            inputText,
            inputCheckbox,
            inputHidden,
            inputSelect,
            submitButton,
            inputTextarea,
            inputAutocomplete,
            globalCustomSelect,
            globalCustomSelectWrap,
            tooltip,
            consentTooltip,
            // checkout
            checkoutConfirmation
        });

        if (isLoyaltyEnabled) {
            Object.assign(cmps, {
                loyaltySignupPopup,
                dashBoardProfileCard
            });
        }

        return cmps;
    }

    afterInit () {
        super.afterInit();

        const $window = $(window);

        $window.on('scroll touchmove', throttle(() => {
            this.eventEmitter.emit('scroll', {
                scrollTop: $window.scrollTop(),
                scrollDown: $window.scrollTop() + $window.height()
            });
        }, 200));

        $window.on('resize', debounce(() => {
            this.eventEmitter.emit('resize');
        }, 10));

        // @ts-ignore
        let oldHash = window.location.hash;

        $window.on('hashchange', () => {
            this.eventEmitter.emit('hashchange', {
                old: oldHash,
                // @ts-ignore
                new: window.location.hash
            });

            // @ts-ignore
            oldHash = window.location.hash;
        });

        $window.on('click touchstart', e => {
            this.eventEmitter.emit('globalClick', e.target);

            return true;
        });

        $window.on('orientationchange', debounce(() => {
            this.eventEmitter.emit('orientationChange');
        }, 10));
        $window.blur(() => this.eventEmitter.emit('globalWindowBlur'));

        let preloaderInit = () => {
            this.addComponents({ spinnerPreloader });
            // @ts-ignore
            this.initComponent($(document).find('[data-is-preloader="true"]'));
        };

        // @ts-ignore
        window.toggleМobileMenu = () => {
            this.eventEmitter.emit('mobileMenuShow');
        };

        // @ts-ignore
        (document.readyState === 'complete') ? preloaderInit() : $window.on('load', preloaderInit);

        // @ts-ignore
        const storedAppParams = sessionStorage.getItem('appParams');

        const appParams = storedAppParams ?
            JSON.parse(storedAppParams) :
            getAppRequiredParams().reduce((acc, paramName) => {
                const param = getAppParam(paramName);

                if (param) {
                    acc.push(param);
                }
                return acc;
            }, []);

        if (appParams.length) {
            addAppParamsToAllLinks(appParams);

            this.eventEmitter.addListener('afterAppendHtml', function(el) {
                addAppParamsToAllLinks(appParams, el);
            });
            this.eventEmitter.addListener('afterReplaceHtml', function(el) {
                addAppParamsToAllLinks(appParams, el);
            });

            if (!storedAppParams) {
                // @ts-ignore
                sessionStorage.setItem('appParams', JSON.stringify(appParams));
            }
        }

    }
}
