/**
 * This validator is used for validation in the front-end and back-end
*/

function validateEmail (value) {
    var MAX_USERNAME_LENGTH = 64;
    var MAX_DOMAIN_LENGTH = 253;
    // eslint-disable-next-line max-len
    var rule = '^([A-Za-z0-9]+([!#$%\'*+/=?^_`{|}.\x26-]{1,1}[A-Za-z0-9]+)*)@([A-Za-z0-9])+([.-]{1,1}[A-Za-z0-9]+)+$';
    var regEx = new RegExp(rule);
    var isValidRegex = regEx.test(value);
    var username = '';
    var domain = '';
    var isValidLength = false;

    if (isValidRegex) {
        username = value.split('@')[0];
        domain = value.split('@')[1];

        isValidLength = (
            username.length <= MAX_USERNAME_LENGTH &&
            domain.length <= MAX_DOMAIN_LENGTH
        );
    }

    return isValidRegex && isValidLength;
}

exports.validateEmail = validateEmail;
