import Component from 'client/core/Component';
import { CLASSES } from 'client/utils/globals';

const SELECTORS = {
    closeBtn: '.js-cookie-close-btn',
    cookieDisabledContainer: '.js-cookie-disabled'
};

export default class HeaderBrowserError extends Component {
    init () {
        this.$cookieContainer = this.$el.find(SELECTORS.cookieDisabledContainer);
        this.bindEvent('click', SELECTORS.closeBtn, this.hideCookieMessage);

        if (!this.cookiesEnabled()) {
            this.showCookieMessage();
        }
    }

    cookiesEnabled() {
        return navigator.cookieEnabled &&
            'cookie' in document &&
            (document.cookie = 'test').indexOf.call(document.cookie, 'test') > -1;
    }

    showCookieMessage() {
        this.$cookieContainer.removeClass(CLASSES.hide);
    }

    hideCookieMessage() {
        this.$cookieContainer.addClass(CLASSES.hide);
    }
}
