'use strict';

/** Npm Modules */
const MobileDetect = require('mobile-detect');

/** Some Variables */
let userAgent = window.navigator.userAgent,
    MobileDetectInstance = new MobileDetect(userAgent);

function iOSRequestDesktop() {
    let ua = window.navigator.userAgent.toLowerCase();
    let iOSRequestDesktop = (ua.indexOf('iphone') === -1) && ua.indexOf('macintosh') > -1 && 'ontouchend' in document;

    return iOSRequestDesktop;
}

/** Detect snipes mobile APP */
function isMobileApp() {
    let ua = window.navigator.userAgent.toLowerCase();
    let isMobileApp = ua.indexOf('snipes') > -1;

    return isMobileApp;
}

export function isIOS() {
    return MobileDetectInstance.os() === 'iOS' || iOSRequestDesktop();
}

export function isAndroid() {
    return MobileDetectInstance.os() === 'AndroidOS';
}

export function isTablet() {
    return MobileDetectInstance.tablet() || iOSRequestDesktop();
}

export function isMobile() {
    return MobileDetectInstance.mobile() || iOSRequestDesktop() || isMobileApp();
}

export function isPhone() {
    return isMobile() && !isTablet();
}

export function refresh() {
    userAgent = window.navigator.userAgent;
    MobileDetectInstance = new MobileDetect(userAgent);
}
