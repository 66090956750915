import Modal from 'client/components/Modal';
import { isRNWebView } from 'client/utils/react';
import $ from 'jquery';
import ajax from 'client/utils/ajax';
import { redirectTo } from 'client/utils/common';

const SELECTORS = {
    replaceInCart: '.js-replace-in-cart',
    continueShopping: '.js-continue-shopping',
    document: document
};

export default class GiftCardPopup extends Modal {
    init() {
        if (isRNWebView()) {
            return;
        }
        this.emitter.addListener('giftcard.popup.show', (data) => this.showModal(data));
        $(SELECTORS.document).on('click', SELECTORS.replaceInCart, (e) => this.replaceInCart(e));
        $(SELECTORS.document).on('click', SELECTORS.continueShopping, (e) => this.continueShopping(e));

        super.init();
    }

    replaceInCart(e) {
        let productData = e.target.getAttribute('data-productdata');
        let productObj = JSON.parse(productData);
        let gtmData = {
            'event': 'gtmEvent',
            'eventCategory': 'gift-card',
            'eventLabel': 'add-gift-card',
            'eventAction': 'products-to-be-removed-layer',
            'eventNonInteraction': 'false',
            'ga4eventname': 'gitf-card'
        };

        ajax.getJson({
            url: productObj.url,
            type: 'POST',
            data: {
                pid: productObj.pid,
                options: productObj.options,
                quantity: productObj.quantity,
                clearBasket: true
            }
        }).then((data) => {
            if (data.error) {
                this.emitter.emit('cart.add.error', data);
            } else {
                if (productObj.isGiftCardBasket) {
                    gtmData.eventAction = 'gift-cards-to-be-removed-layer';
                    gtmData.eventLabel = 'add-product';
                }
                this.emitter.emit('gtmEvent', gtmData);
                this.emitter.emit('cart.add.added', data);
            }
        });
        this.close();
    }

    continueShopping(e) {
        let redirectUrl = e.target.getAttribute('data-url');

        redirectTo(redirectUrl);
    }

    showModal(data) {
        this.config = Object.assign({}, data.giftCardModalTemplate, {});

        super.showModal();
    }
}
