import ProductTiles from 'client/components/product/ProductTiles';
import ajax from 'client/utils/ajax';
import $ from 'jquery';
import { isInViewport } from 'client/utils/common';
import { appendParamToURL, extractParamFromURL } from 'client/utils/url';
import { productTileRender } from 'urls';
import prefs from 'sitePreferences';
import { throttle } from 'lodash';

const SELECTORS = {
    'LOAD_MORE_BTN': '.js-show-more-products',
    'TILES_CONTAINER': '.js-product-tiles',
    'FOOTER': '.js-grid-footer',
    'PAGINATION': {
        'TITLE': '.js-pagination-title',
        'BUTTON': '.js-pagination-button',
        'CONTAINER': '.js-grid-pagination'
    },
    productImageLink: '.js-plp-product-image-link',
    tileContainer: '.js-tile-container',
    productTile: '.js-product-tile',
    wishListTiles: '.js-wish-list-btn',
    wishlistIcon: '.js-wishlist-icon',
    wishlistProducts: '.js-wishlist-info'
};

const CLASSES = {
    wishlistAdd: 'js-wish-list--add',
    wishlistRemove: 'js-wish-list--remove',
    iconWishlistAdded: 'i-wishlist--added'
};

export default class ProductGrid extends ProductTiles {
    init() {
        this.$tilesContainer = this.$el.find(SELECTORS.TILES_CONTAINER);
        this.bindEvent('click', SELECTORS.productImageLink, this.onProductImageClick);

        this.emitter.addListener('refinement.updated', $response => this.updateGrid($response, true));
        this.emitter.addListener('sort.updated', $response => this.updateGrid($response, true));
        this.emitter.addListener('update.tile.wishlist.icon', (data) => this.updateWishListIcons(data));

        this.updateWishListIcons();
        $(window).on('load', () => this.checkCountProduct(this.$loadMoreBtn = this.$el.find(SELECTORS.LOAD_MORE_BTN)));

        this.assignControls();

        if (this.gtmEnabled) {
            if (document.readyState === 'complete') {
                this.trackProductImpression();
            } else {
                $(window).on('load', this.trackProductImpression.bind(this));
            }

            this.config.list = this.$el.closest('[data-list]').data('list');

            this.emitter.addListener('orientationChange', this.trackProductImpression.bind(this));
            this.emitter.addListener('resize', this.trackProductImpression.bind(this));
            this.emitter.addListener('scroll', throttle(this.trackProductImpression.bind(this), 200));
        }
        this.updateAllTilesReleaseDates();
    }

    onProductImageClick(el, ev = null) {
        let $el;
        let url = productTileRender;

        $el = $(el);
        if (ev !== null) {
            ev.preventDefault();
        }

        let $tileWrapper = $el.closest(SELECTORS.productTile).parent('div');

        if ($tileWrapper.length) {
            $el.prop('disabled', true);

            let pid = $el.attr('data-product-id');

            if (pid) {
                if ($tileWrapper.length && pid) {
                    url = appendParamToURL(url, 'pid', pid);
                    url = appendParamToURL(url, 'isPlp', true);

                    let promise = ajax.load({ url, spinner: $tileWrapper });

                    promise.then(response => {
                        $el.prop('disabled', false);

                        if (response && response.length) {
                            let $productTile = $tileWrapper.find(SELECTORS.productTile);

                            if ($productTile.length) {
                                $productTile.remove();
                            }

                            $tileWrapper.append(response);

                            this.emitter.emit('on.click.update.conent',
                            $tileWrapper,
                            $productTile.data('uuid'));
                        }
                    });
                }
            }
        }
        return false;
    }

    getProductData($el) {
        const product = $el.data('gtm');

        if (product) {
            const eventData = Object.assign({
                list: `${this.gtmPageType}/List/${this.config.list}`
            }, product);

            return eventData;
        }

        return null;
    }

    trackProductImpression() {
        const $productTile = this.$el.find(SELECTORS.productTile);

        if (!$productTile.length || !this.config.list) {
            return;
        }

        const impressions = [];

        $productTile.each((idx, el) => {
            const $el = $(el);

            if ($el.data('tracked') || !isInViewport($el)) {
                return;
            }

            const data = this.getProductData($el);

            if (data) {
                const position = $el.closest(SELECTORS.tileContainer).data('position');

                impressions.push(Object.assign({ position: position || 1 }, data));
            }

            $el.data('tracked', '1');
        });

        if (impressions.length) {
            const eventData = Object.assign(
                { currencyCode: window.SitePreferences.currencyCode },
                { impressions: impressions }
            );

            this.emitter.emit('gtmEcommerceEvent', 'eeProductImpression', eventData);
        }
    }

    assignControls() {
        this.$loadMoreBtn = this.$el.find(SELECTORS.LOAD_MORE_BTN);
        this.pagination = {
            $title: this.$el.find(SELECTORS.PAGINATION.TITLE),
            $button: this.$el.find(SELECTORS.PAGINATION.BUTTON)
        };

        if (this.$loadMoreBtn.length > 0) {
            this.bindEvent('click', SELECTORS.LOAD_MORE_BTN, (el, ev) => {
                ev.preventDefault();

                this.loadMore(this.$loadMoreBtn.data('url'));
            });
        }
    }

    // load more should be interupted if any other activity has been triggered
    loadMore(url) {
        this.emitter.emit('grid.update');

        let promise = ajax.load({ url, spinner: 'grid' });
        let start = Number(extractParamFromURL(url, 'start'));

        const productGridCurrentVersionNumber = Date.now();

        window.productGridOCA = productGridCurrentVersionNumber;

        promise.then(response => {
            if (productGridCurrentVersionNumber !== window.productGridOCA) {
                console.log('discard ProductGrid response');
                return;
            }
            this.updateGrid(this.updateTilesReleaseDates($(response)), false, true, start);
            setTimeout(() => {
                this.emitter.emit('scroll.to.anchor');
            }, 100);
        });
    }

    checkCountProduct($el) {
        if ($el.data('current') < $el.data('pagesize')) {
            this.loadMore($el.data('url'));
            return true;
        } else if ($el.data('current') === $el.data('pagesize')) {
            return true;
        }
        return false;
    }

    updateGrid($response, replace = false, isLoadMore = false, start = null) {
        let $loadMore = $response.find(SELECTORS.PAGINATION.BUTTON);

        if (replace) {
            this.replaceHtml($response.find(SELECTORS.TILES_CONTAINER).html(), this.$tilesContainer, {}, () => {
                this.updateWishListIcons();
            });
        } else {
            this.appendHtml($response.find(SELECTORS.TILES_CONTAINER).html(), this.$tilesContainer, false, () => {
                this.updateWishListIcons();
            });
        }

        const updateState = () => {
            this.$loadMoreBtn = this.$el.find(SELECTORS.LOAD_MORE_BTN);
            const current = this.$loadMoreBtn.data('current');
            const pageSize = this.$loadMoreBtn.data('pagesize');
            let { productsTotal, productsStartSize, pageNumber } = $response.find(SELECTORS.FOOTER).data() || false,
                url,
                sz;

            if (start < pageSize) {
                return;
            }

            pageNumber = Number(pageNumber);

            if (productsTotal && isLoadMore) {
                sz = productsTotal;
            } else if (this.checkCountProduct(this.$loadMoreBtn)) {
                sz = pageSize;
            } else {
                sz = current || prefs.defaultGridSize || 'all';
            }

            url = appendParamToURL(location.href, 'sz', sz);

            this.emitter.emit('history.state.push', { url, isLoadMore, start, productsStartSize, pageNumber });

        };

        if (this.$el.find(SELECTORS.PAGINATION.CONTAINER).length > 0) {
            this.replaceHtml($response.find(SELECTORS.PAGINATION.TITLE).html(), this.pagination.$title);
            this.replaceHtml($loadMore.html(), this.pagination.$button, {}, updateState);
        } else {
            let $container = $response.find(SELECTORS.PAGINATION.CONTAINER);

            if ($container.length > 0) {
                this.appendHtml($container, this.$el.find(SELECTORS.FOOTER), true, () => {
                    this.assignControls();
                    updateState();
                });
            }
        }
    }

    updateWishListIcons(data) {
        let wishlistProducts = [];
        let wishListProductData;

        if (data) {
            wishListProductData = data;
        } else {
            let wishListProduct = $(SELECTORS.wishlistProducts);

            wishListProductData = wishListProduct.data('wishlistProducts');
        }

        if (wishListProductData) {
            wishlistProducts = wishListProductData.split(',');
        }

        this.$tilesContainer.find(SELECTORS.wishListTiles).each(function (index, element) {
            let $elem = $(element),
                pidInWishlist = wishlistProducts.indexOf($elem.data('pid')) > -1;

            $elem.toggleClass(CLASSES.wishlistAdd, !pidInWishlist)
                .toggleClass(CLASSES.wishlistRemove, pidInWishlist)
                .find(SELECTORS.wishlistIcon)
                .toggleClass(CLASSES.iconWishlistAdded, pidInWishlist);
        });
    }
}
