import Component from 'client/core/Component';
import { sendEventToMobileApp } from 'client/utils/react';

export default class RaffleButton extends Component {
    init() {
        const pid = this.$el.data('pid');

        // @ts-ignore
        this.bindEvent('click', (el, ev) => {
            // Prevent globalClick event
            ev.stopPropagation();

            if (pid) {
                sendEventToMobileApp({
                    type: 'raffle.product.selected',
                    data: {
                        productId: pid
                    }
                }, true);
            } else {
                // eslint-disable-next-line no-console
                console.warn('Raffle product is not selected');
            }
        });
    }
}
