import BaseShipmentSelector from 'client/components/checkout/shipping/ShipmentSelector';
import $ from 'jquery';
import prefs from 'sitePreferences';
import { CLASSES } from 'client/utils/globals';

const ADDRESS_FIELDS = [
    'id',
    'title',
    'firstName',
    'lastName',
    'address1',
    'address2',
    'city',
    'street',
    'suite',
    'countryCode',
    'postalCode',
    'phone',
    'carrierName',
    'postNumber',
    'packstationNumber',
    'postOfficeNumber',
    'snipesStore',
    'addressType',
    'stateCode',
    'carrierStationId',
    'carrierStationName'
];

const SELECTORS = {
    'SHIPMENT': '.js-shipment',
    'LABEL': '.js-shipment-label',
    'NEW': '.js-add-new-shipment',
    'NEW_WRAPPER': '.js-add-new-shipment-wrapper',
    'NEW_RADIO': '#new-shipping-address-selector',
    'NEW_CONTAINER': '.js-new-shipping-selector',
    'NEW_ADDRESS_REQUIRED': '.js-open-new-address',
    'SHIPPING_ADDRESS': '.js-shipping-address',
    'SHIPPING_COUNTRY_FIELD': '.js-selected-tab .js-shipping-countryCode'
};

export default class ShipmentSelector extends BaseShipmentSelector {
    init() {
        this.$selectedAddress = this.getSelectedAddress();
        this.$newAddressContainer = this.$el.find(SELECTORS.NEW_CONTAINER);
        this.$newAddressButton = this.$el.find(SELECTORS.NEW);
        this.$newAddressButtonWrapper = this.$el.find(SELECTORS.NEW_WRAPPER);
        this.$tabs = this.getTabs();

        this.bindEvent('click', SELECTORS.LABEL, this.onLabelClick);
        this.bindEvent('change', SELECTORS.SHIPMENT, (el, ev) => this.selectShipment(el, null, ev));
        this.bindEvent('click', SELECTORS.NEW, (el, ev) => this.newShipment(el, ev));
    }

    isTabActive($tab) {
        var $tabInputs = $tab.find('input');

        for (const tabInput of $tabInputs) {
            let $tabInput = $(tabInput);

            if ($tabInput.data('selected')) {
                return true;
            }
        }
        return false;
    }

    getTabs() {
        let methods = prefs.shippingMethods.list,
            $tabs = {};

        for (const method of methods) {
            $tabs[method] = this.$el.find(`.js-${method}-tab`);

            if ($tabs[method].length > 0 && this.isTabActive($tabs[method])) {
                this.$selectedTab = $tabs[method];
            }
        }

        if (!this.$selectedTab) {
            this.$selectedTab = $tabs[methods[0]];
        }

        return $tabs;
    }

    getSelectorFields(data) {
        let fields = {};

        for (let field of ADDRESS_FIELDS) {
            fields[field] = data[field];
        }

        return fields;
    }

    _decorateEntireNewShipmentFields(fields) {
        for (let key of ADDRESS_FIELDS) {
            if (key === 'addressType') {
                fields[key] = this.$selectedTab.data('address-type');
            } else if (key !== 'id' && key !== 'countryCode') {
                fields[key] = '';
            }
        }

        if (fields.countryCode === '') {
            // dirty fix for case when new address being reselected few times and previously selected countryCode is not being taken into account
            const shippingCountryField = this.$el
                .closest(SELECTORS.SHIPPING_ADDRESS)
                .find(SELECTORS.SHIPPING_COUNTRY_FIELD);

            if (shippingCountryField.length) {
                fields.countryCode = shippingCountryField.data('cmp-instance').getValue() || '';
            }
        }
    }

    selectShipment(el, isEntireNew, event, selectedMethod) {
        let $el = $(el),
            data = $el.data(),
            fields = this.getSelectorFields(data);

        if (isEntireNew) {
            this._decorateEntireNewShipmentFields(fields);
        }

        let countryCodeChanged = fields.countryCode !== this.$selectedAddress.data('countryCode');

        fields.isEntireNew = isEntireNew;

        if (countryCodeChanged) {
            let countryCode = fields.countryCode;
            let options = {
                enforced: true,
                selectedAddressID: fields.id,
                language: data.language
            };

            // get country code from selected address for new one
            if (fields.id === 'new') {
                countryCode = this.$selectedAddress.data('countryCode');
            }

            this.emitter.emit('checkout.shipping.country.changed', countryCode, options);

            return;
        }

        if (this.$selectedAddress.data('country-code') !== $el.data('country-code')) {
            this.emitter.emit('step.shipping.country.changed');
        }

        this.$selectedAddress = $el;

        // Show/Hide button Add new address
        this.toggleNewAddress();

        if (!this.$selectedAddress.prop('checked')) {
            this.$selectedAddress.prop('checked', true);
        }

        fields.event = true;
        this.emitter.emit('step.shipping.address.changed', fields);

        if (selectedMethod) {
            let isEnabledForMethod = ['pick-up-station'].indexOf(selectedMethod.split('_')[0]) === -1;

            if (isEnabledForMethod && fields.id !== 'new') {
                this.emitter.emit('shippingRates.address.changed', fields);
            }
        }
    }

    onMethodChanged(id, changedByEvent) {
        this.$selectedTab.find('input').data('selected', false);
        this.$selectedTab.addClass(CLASSES.hide);

        this.$selectedTab = this.$tabs[id];
        let $addressList = this.$selectedTab.find('input');

        if ($addressList.length > 0) {
            let $address = $addressList.first();

            $address.data('selected', true);
            this.selectShipment($address.get(0), null, changedByEvent, changedByEvent ? id : null);

            this.$selectedTab.removeClass(CLASSES.hide);
            this.isNewAddress = false;
        } else if (changedByEvent) {
            this.newShipment(null, true);
        } else {
            this.newShipment();
        }

        this.toggleNewAddress();
    }
}
