import Component from 'client/core/Component';
import { CLASSES } from 'client/utils/globals';
import Handlebars from 'handlebars';
import handlebarsHelper from 'client/utils/handlebarsHelper';
import { isRNWebView, sendEventToMobileApp } from 'client/utils/react';

const SELECTORS = {
    CONTENT: '.js-checkout-step-content',
    SUMMARY: '.js-checkout-step-summary',
    SUBMIT: '.js-checkout-step-submit',
    EDIT: '.js-checkout-step-edit',
    TEMPLATE: '.js-checkout-step-template'
};

const CLICK_TIMEOUT = 500;

export default class AbstractCheckoutStep extends Component {
    init() {
        handlebarsHelper(Handlebars);

        this.Handlebars = Handlebars;

        this.submitted = false;
        this.form = this.getNestedComponentById('step-form');
        this.$content = this.$el.find(SELECTORS.CONTENT);
        this.$summary = this.$el.find(SELECTORS.SUMMARY);

        let rawTemplate = this.$el.find(SELECTORS.TEMPLATE);

        if (rawTemplate.length > 0) {
            this.template = Handlebars.compile(rawTemplate.html());
        }

        this.emitter.addListener('lock.submit.button', () => this.lockSubmitButton());
        this.emitter.addListener('unlock.submit.button', () => this.unlockSubmitButton());
        this.emitter.addListener('prevent.multiple.clicks', () => this.preventMultipleClicks());

        this.handlerTimeout = null;
        this.bindEvent('click', SELECTORS.SUBMIT, async () => {
            if (!this.handlerTimeout) {
                await this.onSubmit();
                this.handlerTimeout = setTimeout(() => {
                    this.handlerTimeout = null;
                }, CLICK_TIMEOUT);
            }
        });

        this.bindEvent('click', SELECTORS.EDIT, this.edit);

    }

    active(stepIndex, currentStep) {
        this.$content.removeClass(CLASSES.hide);
        if (this.$summary.length > 0) {
            this.replaceHtml(this.compile(stepIndex, currentStep), this.$summary);
        }

        this.emitter.emit('checkout.step.active', stepIndex);
    }

    inactive(stepIndex, currentStep) {
        this.$content.addClass(CLASSES.hide);
        if (this.$summary.length > 0) {
            this.replaceHtml(this.compile(stepIndex, currentStep), this.$summary);

        }
    }

    edit() {
        if (isRNWebView()) {
            sendEventToMobileApp({
                type: 'checkout.next.step.action'
            }, true);
        }

        this.emit('checkout.step.edit');
        this.emitter.emit('checkout.placeorder.infoblock.hide');
    }

    async onSubmit() {
        this.emit('checkout.step.submitted');
    }

    getSummary() {
        return this.form && this.form.summary;
    }

    compile(step, currentStep) {
        let data = {
            submitted: currentStep > step,
            step: step,
            active: currentStep === step
        };
        let summaryData = this.getSummary();

        if (summaryData) {
            data = Object.assign({}, data, summaryData);
        }

        return this.template(data);
    }

    lockSubmitButton() {
        this.$el.find(SELECTORS.SUBMIT).prop('disabled', true);
        setTimeout(this.unlockSubmitButton.bind(this), 6000);
    }

    unlockSubmitButton() {
        this.$el.find(SELECTORS.SUBMIT).prop('disabled', false);
    }

    preventMultipleClicks() {
        this.lockSubmitButton();
        setTimeout(this.unlockSubmitButton.bind(this), 2500);
    }
}
