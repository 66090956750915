import Modal from 'client/components/Modal';

import { CLASSES } from 'client/utils/globals';
import { getJson } from 'client/utils/ajax';
import { getToken, signupInLoyalty, setLoyaltyPopupShowed } from 'urls';
import { isRNWebView } from 'client/utils/react';

const SELECTORS = {
    signupInLoyalty: '.js-signup',
    submitBtn: '.js-submit-btn',
    signupMsgErr: '.js-loyalty-signup-msg-err',
    signupPopupClass: '.js-loyalty-signup-popup',
    signupErrPopupStatusClass: 'b-loyalty-signup-popup--error',
    signupOkPopupStatusClass: 'b-loyalty-signup-popup--success'
};

export default class LoyaltySignupPopup extends Modal {
    init() {
        if (isRNWebView()) {
            return;
        }

        this.isSuccessSignup = false;

        if (this.config.delay) {
            this.config.delay = this.config.delay * 1000;
        }

        this.config.buttons = [{
            selector: SELECTORS.signupInLoyalty,
            callback: this.onSignupBtnClick.bind(this)
        }];

        this.addListener('modal.open', this.setLoyaltyPopupShowed);

        if (this.config.isAsset) {
            this.renderModalContent();
        }

        super.init();
    }

    stringToHTML(str) {
        var dom = document.createElement('div');

        dom.innerHTML = str;
        return dom;
    }

    renderModalContent() {
        getJson({
            url: this.config.signupUrl,
            type: 'GET'
        }).then((resp) => {
            if (resp.template) {
                document.body.appendChild(this.stringToHTML(resp.template));
            }
        });
    }

    async onSignupBtnClick(e, self, $btn) {
        e.stopPropagation();
        e.preventDefault();

        if (this.isSuccessSignup) { return; }

        let signupResult;

        $btn.addClass(CLASSES.disabledLegacy);

        try {
            signupResult = await this.signup();
        } catch (e) { // eslint-disable-line no-catch-shadow
            signupResult = false;
        }

        this.isSuccessSignup = signupResult;

        if (signupResult) {
            this.addListener('modal.close', () => window.location.reload());
        }

        $btn.addClass(CLASSES.hide);
        this.toggleErrorMsgBlock(signupResult);
    }

    toggleErrorMsgBlock(state = true) {
        const $modalContainer = this.getModalContainer();

        $modalContainer.find(SELECTORS.signupMsgErr).toggleClass(CLASSES.hide, state);

        $modalContainer
            .find(SELECTORS.signupPopupClass)
            .addClass(state ? SELECTORS.signupOkPopupStatusClass : SELECTORS.signupErrPopupStatusClass);
    }

    async signup() {
        const csrf = (await getJson({
            url: getToken,
            type: 'POST'
        })).csrf;

        if (!csrf) { throw new Error(); }

        const signupResponse = await getJson({
            url: signupInLoyalty,
            type: 'POST',
            data: {
                [csrf.tokenName]: csrf.token
            }
        });

        return signupResponse && !signupResponse.error;
    }

    async setLoyaltyPopupShowed() {
        const csrf = (await getJson({
            url: getToken,
            type: 'POST'
        })).csrf;

        if (!csrf) { throw new Error(); }

        await getJson({
            url: setLoyaltyPopupShowed,
            type: 'POST',
            data: {
                [csrf.tokenName]: csrf.token
            }
        });
    }
}
