import Component from 'client/core/Component';
import { CLASSES } from 'client/utils/globals';

const SELECTORS = {
    'FILTER': {
        'APPLY': '.js-apply-filter',
        'CLOSE': '.js-close-filter',
        'APPLYONOFF': '.js-apply-filter-on-off'
    }
};

export default class Refinement extends Component {
    init() {
        this.device = this.config.device;

        this.filterButtons = {
            $apply: this.$el.find(SELECTORS.FILTER.APPLY),
            $close: this.$el.find(SELECTORS.FILTER.CLOSE)
        };

        this.changed = false;

        if (this.config.multiple) {
            this.bindEvent('click', SELECTORS.FILTER.APPLY, () => this.applyFilter());
            this.bindEvent('click', SELECTORS.FILTER.CLOSE, () => {
                this.emitter.emit('refinement.close', this.config.attr);
            });
            this.changeButtonState();
        }

        this.emitter.addListener('refinement.selectionChanged', data => this.onRefinementChange(data));
    }

    onRefinementChange() {}

    changeButtonState() {
        if (this.changed) {
            if (this.$el.find(SELECTORS.FILTER.APPLYONOFF).length > 0) {
                this.$el.find(SELECTORS.FILTER.APPLY).removeAttr('disabled');
            } else {
                this.$el.find(SELECTORS.FILTER.CLOSE).addClass(CLASSES.hide);
                this.$el.find(SELECTORS.FILTER.APPLY).removeClass(CLASSES.hide);
            }
        } else if (this.$el.find(SELECTORS.FILTER.APPLYONOFF).length > 0) {
            this.$el.find(SELECTORS.FILTER.APPLY).attr('disabled', 'disabled');
        } else {
            this.$el.find(SELECTORS.FILTER.CLOSE).removeClass(CLASSES.hide);
            this.$el.find(SELECTORS.FILTER.APPLY).addClass(CLASSES.hide);
        }
    }

    applyFilter() {
        // Change close/apply buttons state to initial
        this.changed = false;
        this.changeButtonState();
    }
}
