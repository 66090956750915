import Component from 'client/core/Component';
import Handlebars from 'handlebars';
import handlebarsHelper from 'client/utils/handlebarsHelper';

const SELECTORS = {
    target: '.js-target',
    source: '.js-source'
};

export default class CartComponent extends Component {
    init() {
        handlebarsHelper(Handlebars);

        this.$target = this.$el.find(SELECTORS.target);
        this.source = this.$el.find(SELECTORS.source).html();
        this.template = Handlebars.compile(this.source);
    }
}
