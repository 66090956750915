import Component from 'client/core/Component';
import { CLASSES } from 'client/utils/globals';
import $ from 'jquery';

const SELECTORS = {
    'NATIVE_SELECT': '.js-native-select',
    'CUSTOM_SELECT': '.js-custom-select',
    'CUSTOM_SELECT_HEADER': '.js-custom-select-header',
    'CUSTOM_SELECT_TITLE': '.js-custom-select-title',
    'CUSTOM_SELECT_LIST': '.js-custom-select-list',
    'CUSTOM_LIST_ITEM': '.js-custom-select-item',
    'CUSTOM_SELECT_COMPONENT': '.js-custom-select-cmp'
};

const STATE_CLASSES = {
    selectPlaceholder: 'f-custom-select-title--placeholder',
    itemSelected: 'h-item-selected'
};

export default class GlobalCustomSelect extends Component {
    init(reInit = false) {
        this.nativeSelect = this.$el.find(SELECTORS.NATIVE_SELECT);
        this.customSelect = this.$el.find(SELECTORS.CUSTOM_SELECT);
        this.customSelectTitle = this.$el.find(SELECTORS.CUSTOM_SELECT_TITLE);
        this.customSelectList = this.$el.find(SELECTORS.CUSTOM_SELECT_LIST);
        this.isOpen = false;
        this.isSelected = false;
        if (reInit) {
            this.updateSelectOptions();
        } else {
            this.renderSelect();
        }
        this.changeCustomSelect(this.nativeSelect.find('option:selected').val());

        let hideItemsRestriction = typeof this.config.maxItemsToShow !== 'undefined' &&
            this.config.maxItemsToShow === 0;

        if (!this.nativeSelect.prop('disabled') && !this.config.disabled && !hideItemsRestriction) {
            this.initEvents();
        }

        if (this.config.id) {
            this.emitter.addListener(`globalCustomSelect.${this.config.id}.enable`, () => this.initEvents());
        }
    }

    initEvents() {
        this.bindEvent('click', SELECTORS.CUSTOM_LIST_ITEM, this.itemClick);
        this.bindEvent('click', SELECTORS.CUSTOM_SELECT_HEADER, this.listToggle);
        this.bindEvent('change', SELECTORS.NATIVE_SELECT, this.changeTitle);
        this.bindEvent('keydown', SELECTORS.CUSTOM_SELECT, this.accessibleClick);

        this.emitter.addListener('custom.select.open', (that) => this.onSelectOpen(that));
        this.emitter.addListener('custom.select.close', () => this.listHide());
        this.emitter.addListener('globalClick', this.globalClick.bind(this));
        this.emitter.addListener('globalWindowBlur', this.listHide.bind(this));

        if (this.config.id) {
            this.emitter.addListener(
                `custom.select.${this.config.id}.change`,
                () => this.changeTitle()
            );

            this.emitter.addListener(
                `custom.select.${this.config.id}.options.updated`,
                () => this.updateSelectOptions()
            );
        }
    }

    accessibleClick(el, ev) {
        if (ev.keyCode === 32) {
            ev.preventDefault();
            this.listToggle(this, ev);
        } else if (ev.keyCode === 13) {
            const elem = document.activeElement;

            this.itemClick(elem);
        } else if (ev.keyCode === 9) {
            let lastItem = this.$el.find(SELECTORS.CUSTOM_LIST_ITEM).last().data('index');

            if ($(document.activeElement).data('index') === lastItem) {
                this.listHide();
            }
        }
    }

    updateSelectOptions() {
        this.customSelectList.empty();

        this.renderSelect();
    }

    renderSelect() {
        let options = Array.from(this.nativeSelect.find('option'));

        if (this.config.maxItemsToShow && !isNaN(this.config.maxItemsToShow) &&
            this.config.maxItemsToShow < options.length
        ) {
            options = options.slice(0, this.config.maxItemsToShow);
        }

        const title = this.nativeSelect.find('option:selected').text();
        const value = this.nativeSelect.find('option:selected').val();

        if (!value) {
            this.customSelectTitle.addClass(STATE_CLASSES.selectPlaceholder);
        }

        this.placeholderText = title;
        this.customSelectTitle.text(title);
        this.$el.toggleClass(CLASSES.customsSelectSetValue, !!value);
        this.nativeSelect.addClass(CLASSES.transparent);
        options.map(this.addItem.bind(this));
    }

    changeTitle() {
        const title = this.nativeSelect.find('option:selected').text();
        const value = this.nativeSelect.find('option:selected').val();

        this.isSelected = (title !== this.placeholderText);
        this.$el.toggleClass(CLASSES.customsSelectSetValue, this.isSelected);

        if (value) {
            this.customSelectTitle.removeClass(STATE_CLASSES.selectPlaceholder);
        } else {
            this.customSelectTitle.addClass(STATE_CLASSES.selectPlaceholder);
        }

        this.customSelectTitle.text(title);
        this.listHide();
        this.changeCustomSelect(value);
    }

    changeCustomSelect(value) {
        this.customSelect.find('li').removeClass(STATE_CLASSES.itemSelected);
        this.customSelect.find(`li[data-value="${value}"]`).addClass(STATE_CLASSES.itemSelected);
    }

    addItem(el, index) {
        let cssClass = '',
            name = $(el).text(),
            id = $(el).attr('id') || name,
            language = $(el).data('language'),
            languageAttribute = '',
            value = $(el).val();

        if (!id) {
            id = Number(Math.random()).toString(16);
        }

        if (!value) {
            cssClass = CLASSES.hide;
        }

        if (language) {
            languageAttribute = `data-language=${language}`;
        }

        const item = `
            <li class="f-custom-select-item js-custom-select-item ${cssClass}"
            data-index="${index}" ${languageAttribute}  data-value="${value}" role="option" id="${id}" tabindex="0">
                ${name}
            </li>
        `;

        this.customSelectList.append(item);
    }

    itemClick(el) {
        const value = $(el).data('value');

        this.nativeSelect.val(value).trigger('change');
    }

    listToggle(el, e) {
        e.stopPropagation();
        this.customSelect.toggleClass(CLASSES.customSelectOpened);

        this.isOpen = !this.isOpen;

        if (this.isOpen) {
            this.emitter.emit('custom.select.open', this);
        }
    }

    globalClick(target) {
        if (!$(target).parents(SELECTORS.CUSTOM_SELECT_COMPONENT).length) {
            this.listHide();
        }
    }

    listHide() {
        this.customSelect.removeClass(CLASSES.customSelectOpened);
        this.isOpen = false;
    }

    onSelectOpen(that) {
        if (that.$el.data('cmp-real-id') !== this.$el.data('cmp-real-id')) {
            this.listHide();
        }
    }
}
