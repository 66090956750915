import Form from 'client/core/Form';

import ajax from 'client/utils/ajax';
import { CLASSES } from 'client/utils/globals';
import { scrollToMsg } from 'client/utils/common';

const SELECTORS = {
    error: '.js-error',
    password: '.js-password',
    successMsgBlock: '.js-success-saved-msg',
    passwordConfirm: '[data-id="{FORM}_passwordconfirm"]'
};

export default class ChangePasswordForm extends Form {
    toggleError(msg = '') {
        this.$.error.html(msg);
        this.$.error.toggleClass(CLASSES.hide, !msg);

        if (msg) {
            scrollToMsg(this.$.error);
        }
    }

    setFieldError(field, message) {
        let fld = field.match(this.FIELD_PATTERN);

        let cmp = this.$el.find(`#${fld}`).closest('[data-cmp]').data('cmp-instance');

        cmp && cmp.setError && cmp.setError(message);
    }

    showFieldErrors(fields) {
        if (typeof fields === 'object') {
            // eslint-disable-next-line no-restricted-syntax
            for (let key of Object.keys(fields)) {
                this.setFieldError(key, fields[key]);
            }
        }
    }

    async onSubmit(el, ev) {
        ev.preventDefault();
        this.hideSuccessMsg();

        const isValid = await super.onSubmit();

        if (isValid) {
            const $registerForm = this.$el,
                url = $registerForm.attr('action'),
                data = $registerForm.serializeArray();

            // @ts-ignore
            ajax.getJson({
                url: url,
                type: 'POST',
                data
            }).then((resp) => {
                this.toggleError(resp.errorMessage || '');

                if (resp.success) {
                    this.showSuccessMsg();
                    this.clearAuthData();
                    this.emitter.emit('password.strength.clear');
                } else if (resp.fields && !resp.errorMessage) {
                    this.showFieldErrors(resp.fields);
                }
            });
        }

        return isValid;
    }

    onValidatePasswordConfirm(cmp) {
        const passwordCmp = this.$el.find(this.$.password).data('cmp-instance');

        return (passwordCmp.getValue() === cmp.getValue());
    }

    showSuccessMsg() {
        this.$.successMsgBlock.removeClass(CLASSES.hide);

        scrollToMsg(this.$.successMsgBlock);
    }

    clearAuthData() {
        this._nestedComponents.forEach(function (cmp) {
            cmp.setValue('');
            cmp.clearError();
            cmp.placeholderToggle();
        });
    }

    hideSuccessMsg() {
        this.$.successMsgBlock.addClass(CLASSES.hide);
    }

    init() {
        this.form = this.$el.data('form');

        this.FIELD_PATTERN = new RegExp(`^${this.form}_(.+)$`, 'i');

        this.$ = {};

        Object.keys(SELECTORS).forEach((k) => {
            let selector = SELECTORS[k];

            if (selector.includes('{FORM}_')) {
                selector = selector.replace('{FORM}', this.form);
            }

            this.$[k] = this.$el.find(selector);
        });

        this.bindEvent('focusout', SELECTORS.password, function() {
            const passwordCmp = this.$.password.data('cmp-instance');
            const passwordConfirmCmp = this.$.passwordConfirm.data('cmp-instance');

            if (passwordConfirmCmp.getValue() && passwordCmp.getValue()) {
                passwordConfirmCmp.validate();
            }
        });
    }
}
