import LoginForm from './LoginForm';

const SELECTORS = {
    PASSWORD_INPUT: '.js-login-password'
};

export default class LoginFormCheckout extends LoginForm {
    async validate() {
        const $password = this.$el.find(SELECTORS.PASSWORD_INPUT);
        const passwordCmp = this.getNestedComponentById($password.data('id'));

        if (passwordCmp) {
            passwordCmp.config.required = true;
        }

        const validationResult = super.validate();

        if (passwordCmp) {
            passwordCmp.config.required = false;
        }

        return validationResult;
    }
}
