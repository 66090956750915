import Input from 'client/core/Input';

import { CLASSES } from 'client/utils/globals';
import $ from 'jquery';

const SELECTORS = {
    inputField: '.js-field',
    selectHint: '.js-custom-select-hint'
};

export default class InputSelect extends Input {
    init () {
        super.init();

        this.inputField = this.$el.find(SELECTORS.inputField);
        this.selectHint = this.$el.find(SELECTORS.selectHint);

        this.bindEvent('change', SELECTORS.inputField, this.validate);

        if (this.config.initialPlaceholder) {
            this.placeholderToggle();
            this.bindEvent('change', SELECTORS.inputField, this.placeholderToggle);
        }

        this.bindEvent('change', SELECTORS.inputField, () => {
            this.emit('changed', this.$field.val());
        });
    }

    placeholderMoveUp() {
        this.$el.addClass(CLASSES.placeholderUp);
    }

    placeholderToggle() {
        let searchPhrase = this.inputField.val();

        searchPhrase = searchPhrase ? searchPhrase.trim() : '';

        if (searchPhrase.length === 0) {
            this.$el.removeClass(CLASSES.placeholderUp);
            this.selectHint.removeClass(CLASSES.hide);
        } else {
            this.placeholderMoveUp();
            this.selectHint.addClass(CLASSES.hide);
        }
    }

    setValue (newVal, silently = false) {
        super.setValue(newVal, silently);

        this.placeholderToggle();

        if (silently) {
            this.emit(`custom.select.${this.config.id}-select.change`);
        } else {
            this.inputField.trigger('change');
        }
    }

    updateOptions(options) {
        let selected = null;

        this.inputField.empty();

        for (let option of options) {
            let $option = $('<option></option>')
                .attr('value', option.value)
                .attr('id', option.id)
                .text(option.text);

            this.inputField.append($option);

            if (option.selected) {
                $option.prop('selected', true);
                selected = $option;
            }
        }

        if (!selected) {
            selected = this.inputField.children().first();
            selected.prop('selected', true);
        }

        this.emitter.emit(`custom.select.${this.config.id}-select.options.updated`);

        this.setValue(selected.val(), true);
    }
}
