import Component from 'client/core/Component';
import { addSearchNavigation } from 'client/utils/navigation';
import { appendParamToURL } from 'client/utils/url';
import $ from 'jquery';

const CATEGORY_REFINEMENT = '.js-category-refinement';

export default class CategoryRefinement extends Component {
    init() {
        this.bindEvent('click', CATEGORY_REFINEMENT, (el, ev) => this.applyFilter(el, ev));
    }

    applyFilter(el, ev) {
        ev.preventDefault();

        let $el = $(el),
            url = addSearchNavigation($el.attr('href'));

        let expanded = false;

        if ('expanded' in this.config) {
            expanded = this.config.expanded;
        }

        url = appendParamToURL(url, 'openCategory', !expanded);

        this.emitter.emit('refinement.applyFilter', {
            type: 'category',
            url: url,
            name: $el.data('name'),
            categoryChain: $el.data('categoryChain')
        });
    }
}
