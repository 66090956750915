import { CLASSES } from 'client/utils/globals';

const SELECTORS = {
    'LINK': '.js-list-show',
    'LIST': '.js-list-content'
};

export default class MenuItem {
    /**
     * @param {jQuery} $el
     */
    constructor ($el) {
        this.$link = $el.find(SELECTORS.LINK).first();
        this.$list = $el.find(SELECTORS.LIST).first();
    }

    getId() {
        return this.$link.data('listId');
    }

    getTitle() {
        return this.$link.data('listName');
    }

    show() {
        this.$list.removeClass(CLASSES.hide);
    }

    hide() {
        this.$list.addClass(CLASSES.hide);
    }
}
