import Tooltip from 'client/components/Tooltip';

export default class LoyaltyTooltip extends Tooltip {
    init () {
        super.init();

        this.bindEvent('click', '.js-cta', (el, ev) => {
            // Prevent globalClick event
            ev.stopPropagation();

            this.shown = false;
            this.onClick();
        });
    }
}
