import Component from 'client/core/Component';
import {
    extractParamFromURL,
    removeParamFromURL,
    appendParamToURL,
    removePreferenceFromURL
} from 'client/utils/url';
import { addSearchNavigation } from 'client/utils/navigation';
import { CLASSES } from 'client/utils/globals';
import $ from 'jquery';

const SELECTORS = {
    'FILTER_BAR': '.js-filter-bar',
    'RESET_BUTTON': '.js-reset-btn',
    'FILTER_ITEM': '.js-filter-bar-item',
    'PRICE': {
        'DROPDOWN': '.js-price-dropdown',
        'TEMPLATE': '.js-price-template',
        'TITLE': '.js-price-title',
        'LINK': '.js-price-link',
        'MIN_EL': '.js-min-price-element',
        'MAX_EL': '.js-max-price-element',
        'ONLY_SALES_CHECKBOX': '.js-only-sale-price',
        'ONLY_SALES_EL': '.js-only-sale-price-element'
    }
};

const STATE_CLASSES = {
    'FILTER_BAR': 'b-plp-filter-bar--full'
};

export default class FilterBar extends Component {
    init() {
        this.$parentSection = $(SELECTORS.FILTER_BAR);
        this.$priceTemplate = this.$el.find(SELECTORS.PRICE.TEMPLATE);
        this.$resetBtn = this.$el.find(SELECTORS.RESET_BUTTON);

        this.addPriceValues();

        this.bindEvent('click', SELECTORS.FILTER_ITEM, (el, ev) => this.removeFilter(el, ev));

        if (this.$parentSection.width() <= this.$el.width()) {
            this.$el.addClass(STATE_CLASSES.FILTER_BAR);
        }
    }

    removeFilter(el, ev) {
        ev.preventDefault();

        let $el = $(el),
            url = addSearchNavigation($el.attr('href'));

        url = appendParamToURL(url, 'openCategory', 'true');

        // Start spinner
        this.emitter.emit('refinement.applyFilter', { type: 'filterBar', url: url });
    }

    addPriceValues() {
        // eslint-disable-next-line no-restricted-globals
        let searchQuery = location.search,
            minPrice = extractParamFromURL(searchQuery, 'pmin'),
            maxPrice = extractParamFromURL(searchQuery, 'pmax'),
            specificCategory = extractParamFromURL(searchQuery, 'specificCategory'),
            isOnlySale = (
                this.config.onlySalePrice &&
                $(`${SELECTORS.PRICE.DROPDOWN} ${SELECTORS.PRICE.ONLY_SALES_CHECKBOX}`).prop('checked')
            );

        if (minPrice) {
            let $minPriceEl = this.createPriceEl('pmin', minPrice, this.config.minPriceMsg, false, specificCategory);

            this.appendPriceEl($minPriceEl, SELECTORS.PRICE.MIN_EL);
        }

        if (maxPrice) {
            let $maxPriceEl = this.createPriceEl('pmax', maxPrice, this.config.maxPriceMsg, false, specificCategory);

            this.appendPriceEl($maxPriceEl, SELECTORS.PRICE.MAX_EL);
        }

        if (isOnlySale) {
            let $saleOnlyEl = this.createPriceEl(null, null, null, isOnlySale, specificCategory);

            this.appendPriceEl($saleOnlyEl, SELECTORS.PRICE.ONLY_SALES_EL);
        }

        if (minPrice || maxPrice || isOnlySale) {
            this.$resetBtn.removeClass(CLASSES.hide);
        }
    }

    createPriceEl(param, val, msg, isOnlySale, specificCategory) {
        let $priceEl = this.$priceTemplate.clone();

        $priceEl.removeClass(CLASSES.hide);

        const filterText = isOnlySale ?
            this.config.onlySalePrice :
            this.config.priceTitle.replace('{0}', msg.replace('{0}', val));

        $priceEl.find(SELECTORS.PRICE.TITLE).html(filterText);

        // eslint-disable-next-line no-restricted-globals
        let url = location.href;

        url = isOnlySale ?
            removePreferenceFromURL(url, 'isSale') :
            removeParamFromURL(url, param);

        if (specificCategory) {
            const searchPath = $priceEl.data('search-url');

            if (searchPath) {
                url = `${searchPath}?${url.split('?')[1]}`;

            }
        }

        $priceEl.find(SELECTORS.PRICE.LINK).attr('href', url);

        return $priceEl;
    }

    appendPriceEl($el, $selector) {
        let $old = this.$el.find($selector);

        if ($old.length > 0) {
            this.replaceHtml($el.html(), $old);
        } else {
            $el.addClass($selector.replace('.', ''));
            this.$el.append($el);
        }
    }
}
