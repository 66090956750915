import Spinner from 'client/components/Spinner';
import { CLASSES } from 'client/utils/globals';
import $ from 'jquery';

const PAGE_WRAPPER = '.js-page-wrapper';

export default class SpinnerPreloader extends Spinner {
    init () {
        this.pageWrapper = $(PAGE_WRAPPER);
        if (this.config.isPreloader) {
            this.remove();
            return;
        }
    }

    remove () {
        this.$el.remove();
        this.pageWrapper.removeClass(CLASSES.loading);
        this.pageWrapper.addClass(CLASSES.loaded);
    }
}
