import Component from 'client/core/Component';
import { load } from 'client/utils/ajax';

const
    SELECTORS = {
        recommendWrapper: '.js-recommend-wrapper',
        elementHided: '.js-element-hided'
    },
    STATE_CLASSES = {
        hide: 'h-hide'
    };

export default class EinsteinRecommendations extends Component {
    init() {
        this.einsteinUtils = this.getEinsteinUtils();
        this.loadRecommendations();

        this.recommendWrapper = this.$el.find(SELECTORS.recommendWrapper);
        this.elementHided = this.$el.find(SELECTORS.elementHided);
    }

    /**
    * Validates and Return the cquotient namespace provided by the commerce cloud platform
    */
    getEinsteinUtils() {
        const einsteinUtils = window.CQuotient;

        if (einsteinUtils &&
            (typeof einsteinUtils.getCQUserId === 'function') &&
            (typeof einsteinUtils.getCQCookieId === 'function')
            ) {
            return einsteinUtils;
        }
        return null;
    }

    /**
     * Gets all appropriate placeholder elements, which hold einstein recommendations queries the details from the
     * einstein engine and feeds them back to the dom element
     */
    loadRecommendations() {
        if (this.einsteinUtils) {
            this.createAnchor();
            this.processRecommendationsTile();
        }
    }

    /**
     * Processes a recommendation tile, with an already initialized specific anchors array
     */
    createAnchor() {
        switch (this.config.recommenderType) {
            case 'category':
                this.anchor = [{ id: this.config.categoryId }];
                break;
            case 'product':
                this.anchor = [{
                    id: this.config.primaryProductId,
                    sku: this.config.secondaryProductId,
                    type: this.config.alternativeGroupType,
                    alt_id: this.config.alternativeGroupId
                }];
                break;
        }
    }

    /**
     * Processes a recommendation tile, with an already initialized anchors array
     */
    processRecommendationsTile() {
        const params = {
            userId: this.einsteinUtils.getCQUserId(),
            cookieId: this.einsteinUtils.getCQCookieId(),
            ccver: '1.01'
        };

        if (this.anchor) {
            params.anchors = this.anchor;
        }

        function recommendationsReceived(einsteinResponse) {
            this.fillEinsteinDomElement(einsteinResponse);
        }

        if (this.einsteinUtils.getRecs) {
            this.einsteinUtils.getRecs(
                this.einsteinUtils.clientId,
                this.config.recommender,
                params,
                recommendationsReceived.bind(this)
            );
        } else {
            this.einsteinUtils.widgets = this.einsteinUtils.widgets || [];
            this.einsteinUtils.widgets.push({
                recommenderName: this.config.recommender,
                parameters: params,
                callback: recommendationsReceived.bind(this)
            });
        }
    }

    /**
     * Renders the einstein response into a given dom element
     */
    async fillEinsteinDomElement(einsteinResponse) {
        const recommendedProducts = einsteinResponse[this.config.recommender].recs;
        const recommenderName = einsteinResponse[this.config.recommender].displayMessage;

        if (recommendedProducts && recommendedProducts.length > 0) {
            const components = recommendedProducts.map(recommendedProduct => {
                const tiledefinition = {};

                tiledefinition.model = { type: 'product', id: recommendedProduct.id };
                return tiledefinition;
            });

            const url = new URL(this.config.productLoadUrl);

            url.searchParams.append('components', JSON.stringify(components));
            url.searchParams.append('limit', this.config.limit);
            url.searchParams.append('title', this.config.title);
            url.searchParams.append('titleAdditionalClasses', this.config.titleAdditionalClasses);
            url.searchParams.append('isArrowsLight', this.config.isArrowsLight);
            url.searchParams.append('isAnimationEnabled', this.config.isAnimationEnabled);
            url.searchParams.append('isCombinedRecoBrandbar', this.config.isCombinedRecoBrandbar);
            url.searchParams.append('productLoadUrl', this.config.productLoadUrl);
            url.searchParams.append('elementId', this.config.elementId);
            url.searchParams.append('recommenderName', recommenderName);
            url.searchParams.append('inventoryLimit', this.config.inventoryLimit);

            let promise = load({
                url: url.href,
                type: 'GET'
            });

            promise.then(response => {
                if (this.recommendWrapper.length > 0) {
                    this.recommendWrapper.html(response);
                    this.elementHided.removeClass(STATE_CLASSES.hide);
                } else {
                    this.$el.html(response);
                }

                this.emitter.emit('namespace.component.initall', this.$el, {
                    carousels: true
                });
                this.emitter.emit('processScrollMoveUp');
            });
        }
    }
}
