import Component from 'client/core/Component';

export default class VariationAttributesColor extends Component {
    init() {
        this.emitter.addListener('change.color', this.changeColor.bind(this));
    }

    changeColor($el, value) {
        this.$el.find($el).html(value);
    }
}
