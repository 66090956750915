import AbstractCheckoutStep from 'client/components/checkout/AbstractCheckoutStep';
import ajax from 'client/utils/ajax';
import { CLASSES } from 'client/utils/globals';
import { redirectTo } from 'client/utils/common';
import { isRNWebView, sendEventToMobileApp } from 'client/utils/react';

export default class PaymentStep extends AbstractCheckoutStep {
    init() {
        super.init();

        this.giftCardCmp = this.getNestedComponentById('giftCard-payment-option');
        this.paymentOptions = this.getNestedComponentById('payment-options');

        this.emitter.addListener('checkout.registerDuringCheckout.steps', () => this.onSubmit());
    }

    trackCheckoutPayment(resp) {
        let methods = '';

        if (resp.paymentMethods) {
            methods = resp.paymentMethods;
        } else if (resp.paymentMethod) {
            methods = resp.paymentMethod.value;
        }

        this.emitter.emit('gtmCheckoutOptionsEvent', 4, methods);
    }

    async onSubmit() {

        this.emitter.emit('giftCard.clear.form.if.tab.disabled');

        let { submitted, form } = await this.form.onSubmit();

        if (submitted) {
            this.emitter.emit('lock.submit.button');

            let promise = ajax.getJson({
                url: form.data('paymentUrl'),
                type: 'POST',
                data: form.serialize()
            });

            promise.then((response) => {
                if (response.cartError && response.redirectUrl) {
                    redirectTo(response.redirectUrl);
                } else {
                    if (this.gtmEnabled) {
                        this.trackCheckoutPayment(response);
                    }

                    if (isRNWebView()) {
                        sendEventToMobileApp({
                            type: 'checkout.next.step.action'
                        }, true);
                    }

                    super.onSubmit();
                    this.emitter.emit('step.payment.updated', response);
                }
                this.emitter.emit('unlock.submit.button');
                this.emitter.emit('checkout.registerDuringCheckout.registration.payment.done');
            });
        }
    }

    onStepError(errorType) {
        if (errorType && errorType === 'giftcard') {
            this.giftCardCmp.error.removeClass(CLASSES.hide);
            this.giftCardCmp.clear();
        } else if (errorType && errorType === 'missingpayment') {
            this.paymentOptions.inactive();
        } else {
            this.giftCardCmp.clear();
            this.paymentOptions.inactive();
        }
    }
}
