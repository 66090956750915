import Component from 'client/core/Component';
import ajax from 'client/utils/ajax';
import { CLASSES } from 'client/utils/globals';
import { isSmall, isMedium } from 'client/utils/screendetector';
import $ from 'jquery';
import { isInViewport } from 'client/utils/common';
import { appendParamToURL } from 'client/utils/url';
import { throttle } from 'lodash';

const MINICART_SHOW_TIME = 3000;

const SELECTORS = {
    miniCart: '.js-minicart',
    miniCartQty: '.js-minicart-quantity',
    miniCartPopover: '.js-minicart-popover',
    miniCartPopoverQty: '.js-minicart-popover-quantity',
    miniCartItems: '.js-minicart-items',
    cartLineWrapper: '.js-cart-item-wrapper'
};

const STATE_CLASSES = {
    overflow: 'overflow-scroll',
    shadowVisiblde: 'b-minicart-items-shadow--visible',
    popoverVisible: 'c-utility-popover-outer--visible',
    miniCartOpened: 'h-minicart--opened'
};

export default class MiniCart extends Component {
    init () {
        this.popoverTimerID = null;
        this.isPopoverShown = false;

        const isMobile = isSmall() || isMedium();

        this.emitter.addListener('cart.add.added', data => this.updateMiniCartCount(data.quantityTotal));
        this.emitter.addListener('cart.updated', basket => this.updateMiniCartCount(basket.numItems));

        if (this.gtmEnabled) {
            if (document.readyState === 'complete') {
                this.trackProductImpression();
            } else {
                $(window).on('load', this.trackProductImpression.bind(this));
            }

            this.emitter.addListener('orientationChange', this.trackProductImpression.bind(this));
            this.emitter.addListener('resize', this.trackProductImpression.bind(this));
            this.emitter.addListener('scroll', throttle(this.trackProductImpression.bind(this), 200));
        }

        if (!isMobile) {
            this.$el.one('mouseover', this.updateMiniCart.bind(this));
            this.$el.on('mouseenter', this.checkMinicartPopupHeight.bind(this));
            this.initPopoverOnAddToCart();
        }


        this.initOutsideTriggerMiniCart();
    }

    initPopoverOnAddToCart() {
        const miniCart = this.$el.find(SELECTORS.miniCart);
        const miniCartPopover = this.$el.find(SELECTORS.miniCartPopover);

        this.emitter.addListener('cart.add.adding', () => {
            this.isPopoverShown = true;
        });

        this.emitter.addListener('minicart.after.init', () => {
            if (this.isPopoverShown) {
                miniCartPopover.addClass(STATE_CLASSES.popoverVisible);
                miniCart.addClass(STATE_CLASSES.miniCartOpened);
                this.checkMinicartPopupHeight();

                this.popoverTimerID = window.setTimeout(() => {
                    miniCartPopover.removeClass(STATE_CLASSES.popoverVisible);
                    miniCart.removeClass(STATE_CLASSES.miniCartOpened);
                }, MINICART_SHOW_TIME);
            }
        });

        miniCartPopover.on('mouseenter', () => {
            this.popoverTimerID && window.clearTimeout(this.popoverTimerID);
            this.isPopoverShown = true;
        });

        miniCartPopover.on('mouseleave', () => {
            miniCartPopover.removeClass(STATE_CLASSES.popoverVisible);
            miniCart.removeClass(STATE_CLASSES.miniCartOpened);
            this.isPopoverShown = false;
        });
    }

    getProductData($el) {
        const product = $el.data('gtm');

        if (product) {
            const eventData = Object.assign({ list: `${this.gtmPageType}/Minicart` }, product);

            return eventData;
        }

        return null;
    }

    trackProductImpression() {
        const $productTile = this.$el.find(SELECTORS.cartLineWrapper);

        if (!$productTile.length) {
            return;
        }

        const impressions = [];

        $productTile.each((idx, el) => {
            const $el = $(el);

            if ($el.data('tracked') || !isInViewport($el)) {
                return;
            }

            const data = this.getProductData($el);

            if (data) {
                const index = Number($el.data('index'));

                impressions.push(Object.assign({ position: (index + 1) || 1 }, data));
            }

            $el.data('tracked', '1');
        });

        if (impressions.length) {
            const eventData = Object.assign(
                { currencyCode: window.SitePreferences.currencyCode },
                { impressions: impressions }
             );

            this.emitter.emit('gtmEcommerceEvent', 'eeProductImpression', eventData);
        }
    }

    checkMinicartPopupHeight() {
        this.$shadowContainer = $(SELECTORS.miniCartItems);
        if (this.$shadowContainer.length && this.$shadowContainer[0].scrollHeight > 410) {
            this.$shadowContainer.parent().addClass(STATE_CLASSES.shadowVisiblde);
        }

        if (this.gtmEnabled) {
            this.trackProductImpression();
        }
    }

    updateMiniCart() {
        this.emitter.emit('minicart.load');
        let itemsCount = this.getItemsCount();
        let miniCartPopover = this.$el.find(SELECTORS.miniCartPopover);
        let miniCart = this.$el.find(SELECTORS.miniCart);

        if (itemsCount === 0) {
            miniCartPopover.empty();
            miniCartPopover.addClass(CLASSES.hide);
            miniCart.removeClass(STATE_CLASSES.miniCartOpened);
            return;
        }

        miniCartPopover.removeClass(CLASSES.hide);
        miniCartPopover.toggleClass(STATE_CLASSES.overflow, itemsCount > 2);

        let url = this.config.actionUrl;

        ajax.load({
            url: url
        }).then((response) => {
            this.replaceHtml(response, miniCartPopover, {
                onAfterInit: () => {
                    this.updateMiniCartCountFromPopover();
                    this.checkMinicartPopupHeight();
                    this.emitter.emit('minicart.after.init');
                }
            });
        });
    }

    updateMiniCartCount(count) {
        const $miniCartQty = this.$el.find(SELECTORS.miniCartQty);

        $miniCartQty.toggleClass(CLASSES.hide, !count);
        $miniCartQty.text(count || 0);

        if (count) {
            $miniCartQty.addClass(CLASSES.productAdded);
        } else {
            $miniCartQty.removeClass(CLASSES.productAdded);
        }

        this.updateMiniCart();
    }

    getItemsCount() {
        let count = parseInt(this.$el.find(SELECTORS.miniCartQty).text(), 10);

        return isNaN(count) ? 0 : count;
    }

    updateMiniCartCountFromPopover() {
        const $miniCartPopoverQty = this.$el.find(SELECTORS.miniCartPopoverQty);

        if ($miniCartPopoverQty.length) {
            const $miniCartQty = this.$el.find(SELECTORS.miniCartQty);
            const itemsCountPopover = $miniCartPopoverQty.attr('data-cart-qty');
            const count = isNaN(itemsCountPopover) ? 0 : itemsCountPopover;

            $miniCartQty.toggleClass(CLASSES.hide, !count);
            $miniCartQty.text(count || '');
        }
    }

    getGtmAndBasketData(pid) {
        let url = window.Urls.getGtmData;

        if (pid) {
            url = appendParamToURL(url, 'pid', pid);
            url = appendParamToURL(url, 'basketData', true);
        }

        let promise = ajax.load({
            url: url,
            type: 'GET'
        });

        promise.then(response => {
            let result = {};

            try {
                result = JSON.parse(response);
            } catch (e) {
                return;
            }

            let { success, currencyCode, data, miniCartData } = result;

            this.emitter.emit('cart.add.added', miniCartData);

            if (success && this.gtmEnabled) {
                const eventData = {
                    'currencyCode': currencyCode,
                    'add': { // 'add' actionFieldObject measures.
                        'products': [Object.assign({ quantity: 1 }, data)]
                    }
                };

                this.emitter.emit('gtmEvent', {
                    event: 'eeAddToBasket',
                    eventCategory: 'product',
                    ecommerce: eventData,
                    eventAction: 'ee-add-to-basket'
                });
            }
        });
    }

    initOutsideTriggerMiniCart() {

        window.triggerMiniCart = (pid) => {
            this.popoverTimerID && window.clearTimeout(this.popoverTimerID);
            this.isPopoverShown = true;
            this.emitter.emit('minicart.after.init');
            if (this.getItemsCount() === 0) {
                this.updateMiniCartCount(1);
            }
            this.updateMiniCart();
            this.getGtmAndBasketData(pid);
        };


    }

}
