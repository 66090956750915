import Component from 'client/core/Component';

const SELECTOR = '.js-grid-quantity';

export default class GridQuantity extends Component {
    init() {
        this.emitter.addListener('refinement.updated', ($response) => this.updateGridQuantity($response));
    }

    updateGridQuantity($response) {
        this.replaceHtml($response.find(SELECTOR).html());
    }
}
