import BaseAddToCartProduct from 'client/components/product/AddToCartProduct';
// eslint-disable-next-line no-unused-vars
import $ from 'jquery';

const SELECTORS = {
        promoCalloutMsgStatic: 'js-promo-callout-static',
        promoCalloutMsgSticky: '.js-promo-callout-sticky'
    },
    CLASSES = {
        hideShowPromoMsgClass: 'h-hide-static-promo-msg'
    };

export default class AddToCartProduct extends BaseAddToCartProduct {
    // eslint-disable-next-line class-methods-use-this
    hideShowPromoMsg() {
        const promoCalloutMsgStatic = document.getElementsByClassName(SELECTORS.promoCalloutMsgStatic);
        let bounding = promoCalloutMsgStatic[0].getBoundingClientRect();

        if (bounding.top >= 37) {
            $(SELECTORS.promoCalloutMsgSticky).addClass(CLASSES.hideShowPromoMsgClass);
        } else {
            $(SELECTORS.promoCalloutMsgSticky).removeClass(CLASSES.hideShowPromoMsgClass);
        }
    }
}
