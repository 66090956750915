/*Same as ImageCarousel.js - needs to be modified for plp*/
import ImageCarousel from 'client/components/product/ImageCarousel';
import { compile } from 'handlebars';

import $ from 'jquery';

import { isSmall, isMedium } from 'client/utils/screendetector';

const SELECTORS = {
    image: {
        carousel: '.js-plp-carousel',
        prev: '.js-plp-carousel-control--prev',
        next: '.js-plp-carousel-control--next',
        dots: '.js-plp-carousel-dots'
    },
    source: '.b-plp-image-main',
    tileContainer: '.js-tile-container',
    main: '.b-plp-product-preview-wrapper'
};

const CONFIGS = {
    image: {
        dots: true,
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        draggable: true,
        touchThreshold: 15,
        speed: 400,
        fade: false,
        waitForAnimate: false
    }
};

export default class ImageListingCarousel extends ImageCarousel {
    initCarousel(instance) {
        const $elements = this.$elements[instance],
            $carousel = $elements.carousel;

        let config = Object.assign({}, CONFIGS[instance]);

        if (config.dots && $elements.dots) {
            config.appendDots = $elements.dots;
        }
        if (isSmall() || isMedium()) {
            $carousel.on('init', () => {
                this.emitter.emit('namespace.component.initall', $carousel, {
                    onAfterInit: () => {
                        this.onScreenResize();
                    }
                });
            });
            $carousel.slick(config);
            $carousel.on('afterChange', function(event) {
                this.emitter.emit('gtmEvent', {
                    eventCategory: 'product',
                    eventAction: 'plp/swipe-pic',
                    eventLabel: $(event.target).closest(SELECTORS.tileContainer).data('position'),
                    eventNonInteraction: false,
                    ga4eventname: 'plp_swipe_pic'
                });
            }.bind(this));
        }
    }

    onScreenResize() {
        if (this.inResize) {
            clearTimeout(this.inResize);
        }

        this.inResize = setTimeout(() => {
            if (isSmall() || isMedium()) {
                this.initCarousels();
                this.$elements.image.dots.show();
            } else {
                this.$elements.image.dots.hide();
                this.$elements.image.draggable = false;
            }
            this.inResize = null;
        });
    }

    // eslint-disable-next-line consistent-return
    initCarousels() {
        this.imageCount = this.$el.find(SELECTORS.image.carousel).data('length');

        this.$elements = {
            image: {
                carousel: this.$el.find(SELECTORS.image.carousel),
                dots: this.$el.find(SELECTORS.image.dots)
            }
        };

        if (!('slick' in $.fn) || this.$elements.image.carousel.hasClass('slick-initialized')) {
            return false;
        }

        for (let instance of Object.keys(SELECTORS)) {
            if ((typeof SELECTORS[instance] === 'object') && ('carousel' in SELECTORS[instance])) {
                this.initCarousel(instance);
            }
        }
    }

    onPhotoswipeGalleryClose(index) {
        this.$elements.image.carousel.slick('slickGoTo', index, true);
    }

    destroyCarousels() {
        for (let instance of Object.keys(SELECTORS)) {
            if ((typeof SELECTORS[instance] === 'object') && ('carousel' in SELECTORS[instance])) {
                const $carousel = this.$elements[instance].carousel;

                if (('slick' in $.fn) && this.$elements.image.carousel.hasClass('slick-initialized')) {
                    $carousel.slick('unslick');
                }

            }
        }
    }

    onUpdateContent($tileWrapper, uuid) {
        const currentID = 'imageListingCarousel_' + uuid;

        if (currentID === this.id) {
            if (!this.config.disableCarousel) {
                this.destroyCarousels();
            }

            this.$el = $tileWrapper.find(SELECTORS.main);

            this.replaceHtml(this.$el.find(SELECTORS.target), this.$target, {
                carousels: this.config.disableCarousel,
                onAfterInit: () => {
                    if (!this.config.disableCarousel) {
                        this.initCarousels();
                    }
                }
            });
        }
    }

    // eslint-disable-next-line consistent-return
    init() {
        if (!('slick' in $.fn)) {
            return false;
        }

        if (!this.config.disableCarousel) { // eslint-disable-line no-negated-condition
            this.initCarousels();
            this.emitter.addListener('deviceChange', () => this.onScreenResize());
            this.emitter.addListener('resize', () => this.onScreenResize());
            this.emitter.addListener('orientationchange', () => this.onScreenResize());
            this.emitter.addListener(
                'on.photoswipe.gallery.close',
                (index) => this.onPhotoswipeGalleryClose(index)
            );
            this.emitter.addListener('on.click.update.conent',
                ($content, uuid) => this.onUpdateContent($content, uuid)
            );
        } else {
            this.emitter.emit('namespace.component.initall', this.$el);
        }

        this.$target = this.$el.find(SELECTORS.target);
        this.source = this.$el.find(SELECTORS.source).html();
        this.template = compile(this.source);
    }
}
