import BaseNewAddress from 'client/components/account/addressBook/NewAddress';
import { CLASSES, ADDRESS_TYPES } from 'client/utils/globals';

const SELECTORS = {
    'US': '.js-state-field-US'
};

export default class NewAddress extends BaseNewAddress {
    init () {
        super.init();

        let event = `checkout.${this.config.isBilling ? 'billing' : 'shipping'}.country.changed`;

        this.isActive = false;

        if (this.config.isBilling) {
            this.emitter.addListener(event, this.onBillingCountryChanged.bind(this));
        } else {
            this.emitter.addListener(event, this.onCountryChanged.bind(this));
        }
    }

    onBillingCountryChanged (country) {
        if (!this.isActive) {
            return;
        }

        switch (country) {
            case 'US':
                this.$states.US.removeClass(CLASSES.hide).find('select').prop('disabled', false);
                break;
            default:
                this.$states.US.addClass(CLASSES.hide).find('select').prop('disabled', true);
                break;
        }

        this.validatePostalCode(ADDRESS_TYPES.billing);
    }

    onCountryChanged() {
        if (!this.isActive) {
            return;
        }

        let formCmp = this.$addForm.find('form').data('cmp-instance');

        if (formCmp) {
            let formData = formCmp.getFields('shipping');

            this.onAdd(null, true, true, formData);
        }
    }

    getCountryCode(addFormID) {
        let formCmp = this.$addForm.find('form').data('cmp-instance');

        if (formCmp) {
            let formData = formCmp.getFields(addFormID || 'shipping');

            if (formData && formData.countryCode) {
                return formData.countryCode;
            }
        }

        return null;
    }

    onAdd(e, inSameContainer = false, emittedFromEvent = false, formData = null) {
        if (e) {
            e.preventDefault();
        }

        if (emittedFromEvent && this.config.isBilling) {
            return;
        } else {
            this.$input.prop('checked', true);

            this.emitter.emit('addressbook.address.add.start', {
                callback: (r) => this.buildAddForm(r),
                methodId: this.selectedShippingMethod,
                isBilling: this.config.isBilling,
                section: this,
                sameContainer: inSameContainer,
                formData: formData
            });
        }
    }

    buildAddForm(response) {
        this.$addButton.addClass(CLASSES.hide);
        this.$container.removeClass(CLASSES.hide);

        this.replaceHtml(response, this.$addForm, {
            onAfterInit: () => {
                this.isActive = true;

                if (!this.selectedShippingMethod) {
                    let method = this.$addForm.find('form').data('method-id');

                    if (method) {
                        this.selectedShippingMethod = method;
                    }
                }

                if (this.config.isBilling) {
                    this.$states = { US: this.$el.find(SELECTORS.US) };

                    // this.emitter.emit('globalCustomSelect.billing-stateCode-CA-select.enable');
                    this.emitter.emit('globalCustomSelect.billing-stateCode-US-select.enable');

                    this.onBillingCountryChanged(this.getCountryCode('billing'));
                } else {
                    this.validatePostalCode(ADDRESS_TYPES.shipping);
                }
            }
        });
    }

    async onSave(e) {
        if (e) {
            e.preventDefault();
        }

        let form = this.$addForm.find('form').data('cmp-instance');

        if (form.config.methodId === 'pick-up-station') {
            this.getNestedComponentById('pick-up-station-search').config.required = true;
        }
        if (form) {
            let isValid = await form.validate();

            if (isValid) {
                this.emitter.emit('addressbook.address.add.save', {
                    form: form.$el.serialize(),
                    methodId: this.selectedShippingMethod,
                    isBilling: this.config.isBilling,
                    callback: (r) => this.onResponse(r),
                    countryCode: this.getCountryCode()
                });
            }
        }
    }

    onMethodChanged(id) {
        if (id) {
            this.selectedShippingMethod = id;
            this.onCountryChanged();
        }
    }
}
