/* global google */

import StoreFinderMapMgr from 'client/components/StoreFinderMapMgr';

export default class GoogleMapsAbstractComponent extends StoreFinderMapMgr {
    init () {
        this.interval = null;

        // all google events should be put into this object {key: value}={eventName: instanceOfEvent}
        this.giListeners = {};
        // an instance of the google API should be set into this variable
        this.giComponent = null;

        if (!this.config.doNotInit) {
            this.initComponent();
        }
    }

    initComponent () {
        if (typeof google === 'undefined') {
            this.interval = setInterval(() => {
                if (typeof google !== 'undefined') {
                    clearInterval(this.interval);
                    this.interval = null;

                    this._initComponent();
                }
            }, 100);
        } else {
            this._initComponent();
        }
    }

    /**
     * @private
     */
    _initComponent () {
        throw new Error('It\'s an abstract component');
    }

    destroy () {
        super.destroy();

        if (typeof google !== 'undefined') {
            this.giListeners && Object.keys(this.giListeners).forEach(key => {
                google.maps.event.removeListener(this.giListeners[key]);
            });

            this.giComponent && google.maps.event.clearInstanceListeners(this.giComponent);
        }

        this.interval && clearInterval(this.interval);
    }
}
