import BasePriceRefinement from 'client/components/search/PriceRefinement';
import { extractParamFromURL } from 'client/utils/url';

export default class PriceRefinement extends BasePriceRefinement {
    init () {
        super.init();
    }

    updatePriceElements() {
        let priceMin = extractParamFromURL(location.href, 'pmin'),
            priceMax = extractParamFromURL(location.href, 'pmax');

        if (priceMin && !isNaN(priceMin)) {
            this.$minPriceEl.val(Number.parseFloat(priceMin).toFixed(2));
        }

        if (priceMax && !isNaN(priceMax)) {
            this.$maxPriceEl.val(Number.parseFloat(priceMax).toFixed(2));
        }
    }
}
