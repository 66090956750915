import Component from 'client/core/Component';
import ajax from 'client/utils/ajax';
import { extractParamFromURL, appendParamToURL } from 'client/utils/url';
import $ from 'jquery';

export default class GridSort extends Component {
    init() {
        this.bindEvent('change', this.onChange);
    }

    trackSort(sort) {
        const data = {
            eventCategory: 'sort',
            eventAction: sort,
            eventLabel: '',
            ga4eventname: 'plp_sort'
        };

        this.emitter.emit('gtmEvent', data);
    }

    onChange(el) {
        this.emitter.emit('sort.update');

        let $el = $(el),
            url = $el.val(),
            promise = ajax.load({ url: url, spinner: 'grid' });

        if (this.gtmEnabled) {
            this.trackSort($el.find('option:selected').data('id'));
        }

        promise.then((response) => {
            let srule = extractParamFromURL(url, 'srule'),
                // eslint-disable-next-line no-restricted-globals
                updatedUrl = location.href;

            if (srule) {
                updatedUrl = appendParamToURL(updatedUrl, 'srule', srule);
            }

            this.emitter.emit('history.state.push', updatedUrl);
            this.emitter.emit('sort.updated', $(response));
        });
    }
}
