import $ from 'jquery';
import Component from 'client/core/Component';
import { CLASSES } from 'client/utils/globals';

const SELECTORS = {
    variationSection: '.js-pdp-variation-section',
    sizeAttributeTile: '.js-pdp-attributes--size .js-pdp-attribute-tile',
    sizingSystemSwitch: '.js-sizing-system-switch',
    sizingSystemOption: '.js-sizing-system-option',
    swatchWrapper: '.b-swatch-value-wrapper'
};

const STATE_CLASSES = {
    sizingSystemSelected: 'b-sizing-system--selected'
};

export default class SizingSystemSwitch extends Component {
    init() {
        this.$variationSection = $(SELECTORS.variationSection);
        this.config.selectedSizingSystem = this.$el.data('selectedSizingSystem');
        this.$sizingSystemSwitch = this.$variationSection.find(SELECTORS.sizingSystemSwitch);
        this.$sizeAttributeTiles = this.$variationSection.find(SELECTORS.sizeAttributeTile);

        this.$sizingSystemSwitch.on('click', SELECTORS.sizingSystemOption, (e) => {
            e.preventDefault();
            const system = $(e.target).data('sizingSystem');

            this.config.selectedSizingSystem = system;
            this.changeSizingSystem(system);
        });

        this.emitter.addListener('variation.updated', this.onVariationUpdate.bind(this));
    }

    onVariationUpdate() {
        this.$sizeAttributeTiles = this.$variationSection.find(SELECTORS.sizeAttributeTile);
        this.changeSizingSystem(this.config.selectedSizingSystem);
    }

    changeSizingSystem(system) {
        const sizingSystems = this.config.sizingSystems;

        this.$sizingSystemSwitch.find(SELECTORS.sizingSystemOption).removeClass(STATE_CLASSES.sizingSystemSelected);
        this.$sizingSystemSwitch.find(`[data-sizing-system="${system}"]`).addClass(STATE_CLASSES.sizingSystemSelected);

        this.$sizeAttributeTiles.each((idx, el) => {
            const $el = $(el);
            const size = $el.data('attrValue');

            if (size && sizingSystems[size] && sizingSystems[size][system]) {
                $el.text(sizingSystems[size][system]);
            } else if (system === 'EU') {
                $el.closest(SELECTORS.swatchWrapper).removeClass(CLASSES.hide);
            } else {
                $el.closest(SELECTORS.swatchWrapper).addClass(CLASSES.hide);
                this.emitter.emit('attribute.unselected', $el);
            }
        });
    }
}
