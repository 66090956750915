import Component from 'client/core/Component';

export default class MobileMenuToggle extends Component {
    init () {
        if (this.config.type) {
            this.bindEvent('click', () => this.openMenu());
        } else {
            throw new Error('Please set "data-type" attribute to "mobileMenuToggle" component');
        }
    }

    openMenu() {
        this.emitter.emit(`mobileMenu.${this.config.type}.open`);
    }
}

