// eslint-disable-next-line no-unused-vars
/* global google */

import Component from 'client/core/Component';
import { getJson } from 'client/utils/ajax';
import { appendParamsToURL } from 'client/utils/url';
import $ from 'jquery';
export default class InitGTM extends Component {
    init () {
        this.$htmlHead = this.$el.parent();
        window.addEventListener('apply.consent.cookie', (data) => this.handleGTM(data.detail));
        window.addEventListener('decline.consent.cookie', (data) => this.setConsent(data.detail));
    }

    handleGTM(params) {
        this.setConsent(params);

        const cookieCheckbox = $('[for="ot-group-id-C0007"]');
        const cookieInput = $('#ot-group-id-C0007')[0];

        if (!cookieInput.checked) {
            cookieCheckbox.click();
            $('.save-preference-btn-container .save-preference-btn-handler').click();
        }
    }

    setConsent(params) {
        let setConsentUrl = appendParamsToURL(
            this.config.setConsentUrl,
            { consent: params.consentStatus }
        );

        if ('consentStatus' in window && window.consentStatus !== params.consentStatus) {
            let promiseConsentStatus = getJson({
                url: setConsentUrl,
                type: 'GET'
            });

            promiseConsentStatus.then(response => {
                if (response) {
                    window.consentStatus = true;

                    if (params.consentStatus) {
                        this.emitter.emit('apply.consent');
                    }
                }
            });
        }
    }
}
