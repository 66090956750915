import ProductComponent from './ProductComponent';

const SELECTORS = {
    factValues: '.js-fact-values',
    factAttr: '.js-fact-attr',
    factValue: '.js-fact-value'
};

export default class ProductFacts extends ProductComponent {
    onVariationUpdate(product) {
        super.onVariationUpdate(product);

        this.actualizeData();
    }

    actualizeData() {
        const $factValues = this.$el.find(SELECTORS.factValues),
            releaseDate = $factValues.data('release-date'),
            $factAttr = $factValues.find(`${SELECTORS.factAttr}[data-attr="releaseDate"]`);

        if (!releaseDate || !$factAttr.length) {
            return;
        }

        $factAttr.find(SELECTORS.factValue).html(this.formatDate(new Date(releaseDate), $factValues.data('countdown')));
    }

    init() {
        super.init();

        this.actualizeData();
    }
}
