//That is mock. Uncomment for DEBUG on browser, don't remove this lines!
// window.ReactNativeWebView = {
//     postMessage: function (data) {
//         console.log('POST Message to APP:', data);
//         return;
//     }
// };

window.SnipesAppIntegration = window.SnipesAppIntegration || {
    'getReactNative': () => {
        return window.ReactNativeWebView ? window.ReactNativeWebView : null;
    },
    'isRNWebView': () => {
        return window.SnipesAppIntegration.getReactNative() !== null;
    },
    'sendEventToMobileApp': (eventData, isJSONData) => {
        if (window.SnipesAppIntegration.isRNWebView()) {
            let reactNative = window.SnipesAppIntegration.getReactNative();

            if (!reactNative) {
                console.warn('It is not react native webview.'); // eslint-disable-line no-console
                return;
            }
            if (isJSONData) {
                reactNative.postMessage(JSON.stringify(eventData));
            } else {
                reactNative.postMessage(eventData);
            }
        }
    },
    'trackEvent': (eventData) => {
        window.SnipesAppIntegration.sendEventToMobileApp({
            type: 'app.analytics.tracking',
            event: {
                eventType: 'TRACKING',
                data: eventData
            }
        }, true);
    },
    'navigate': (routeName, params) => {
        window.SnipesAppIntegration.sendEventToMobileApp({
            type: 'app.analytics.navigate',
            event: {
                eventType: 'NAVIGATE',
                routeName: routeName,
                params: params
            }
        }, true);
    },
    // eslint-disable-next-line complexity
    'postMessageListener': (event) => {
        let message = {};
        let reactNative = window.SnipesAppIntegration.getReactNative();
        const orderSummary = document.querySelector('.b-cart-order-summary-wrapper .b-sticky-button-inner');
        //const pdpAddToCart = document.querySelector('.h-sticky-element-bottom .b-pdp-actions-wrapper');

        if (!reactNative) {
            console.warn('It is not react native webview.'); // eslint-disable-line no-console
            return;
        }

        try {
            message = JSON.parse(event.data);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn('JSON parse is failed', e);
        }

        switch (message.type) {
            case 'picker.set.value':
                if (message.data) {
                    const selectElementId = message.data.selectElementId;
                    const selectedValue = message.data.selectedValue;
                    const customSelect = document.querySelector('[data-appid="' + selectElementId + '"]');

                    if (customSelect) {
                        const option = customSelect.querySelector('[data-value="' + selectedValue + '"]');

                        option.click();
                    }
                }
                break;
            case 'page.navigation.back':
                try {
                    if (document.referrer === '') {
                        reactNative.postMessage(JSON.stringify({
                            type: 'navigation.back',
                            data: { goToBackScreen: true }
                        }));
                    } else {
                        window.history.back();
                    }
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.warn(e);
                }
                break;
            case 'app.tabbar.positionY':
                if (message.data) {
                    const tabbarPosition = message.data.tabbarPositionY + 'px';

                    if (orderSummary) {
                        orderSummary.style.bottom = tabbarPosition;
                    }
                    // if (pdpAddToCart) {
                    //   pdpAddToCart.style.top = 'calc(100% - ' + tabbarPosition + ')';
                    // }
                }
                break;
            case 'page.prev.route':
                if (message.data) {
                    localStorage.setItem('prevRoute', JSON.stringify(message.data));
                }
                break;
            default:
                break;
        }
    },
    'initDropdownTrigger': () => {
        document.addEventListener('touchend', e => {
            const customSelectHeader = e.target.closest('.js-custom-select-header');

            if (customSelectHeader) {
                const nativeSelect = customSelectHeader
                    .closest('.js-custom-select-cmp')
                    .querySelector('select');
                const customSelect = customSelectHeader
                    .closest('.js-custom-select-cmp')
                    .querySelector('.js-custom-select');
                const customSelectList = customSelect
                    .closest('.js-custom-select-cmp')
                    .querySelector('.js-custom-select-list');
                const optionsForApp = [];
                const options = nativeSelect.querySelectorAll('option');
                var selectedItem = nativeSelect.value;

                customSelectList.style.display = 'none';

                options.forEach(option => {
                    const label = option.innerText.trim().replace('::', '  ');
                    const value = option.getAttribute('value');
                    const optionClasses = option.dataset.class;
                    let color = '';

                    if (optionClasses && optionClasses.includes('lowStock')) {
                        color = 'lowstock';
                    }
                    if (optionClasses && optionClasses.includes('instock')) {
                        color = 'instock';
                    }

                    if (value) {
                        optionsForApp.push({ label, value, color });
                        if (!selectedItem) {
                            selectedItem = value;
                        }
                    }
                });
                const uuid = new Date().getTime().toString();

                customSelect.dataset.appid = uuid;

                window.SnipesAppIntegration.sendEventToMobileApp({
                    type: 'picker.open',
                    data: {
                        selectElementId: uuid,
                        pickerOptions: optionsForApp,
                        selectedItem: selectedItem
                    }
                }, true);
            }
        });
    },
    'initScrollEvent': () => {
        window.addEventListener('scroll', function () {
            window.SnipesAppIntegration.sendEventToMobileApp({
                offset: window.pageYOffset,
                type: 'tabbar.offset'
            }, true);
        });
    },
    'initBottomPadding': () => {
        document.getElementsByTagName('BODY')[0].style.paddingBottom = '100px';
    },
    'isWebviewApp': () => {
        return document.body && document.body.classList.contains('h-is-webview-app');
    },
    'initPdpBackButton': function () {
        const pdpMainSection = document.querySelector('.b-pdp-main-info-section');

        if (pdpMainSection) {
            pdpMainSection.insertAdjacentHTML(
                'beforeend',
                "<a class='js-pdp-back-btn b-pdp-back-btn m-app i-carousel-previous'></a>"
            );
        }
    },
    'initPdpBackButtonHandler': function () {
        document.body.addEventListener('click', function (e) {
            if (!e.target.className.includes('js-pdp-back-btn')) {
                return;
            }
            e.preventDefault();

            try {
                window.SnipesAppIntegration.sendEventToMobileApp({
                    type: 'navigation.back'
                }, true);
            } catch (error) {
                console.log('There was something wrong with APP'); // eslint-disable-line no-console
            }
        }, true);
    },
    'inited': false,
    'init': () => {
        if (!window.SnipesAppIntegration.isWebviewApp()) {
            return;
        }

        if (window.SnipesAppIntegration.inited) {
            return;
        }

        if (window.SnipesAppIntegration.isRNWebView()) {
            window.SnipesAppIntegration.inited = true;
            // 'document' for Android, 'window' for ios.
            document.addEventListener('message', window.SnipesAppIntegration.postMessageListener, false);
            window.addEventListener('message', window.SnipesAppIntegration.postMessageListener, false);

            if (!window.SitePreferences.isSoleboxApp) {
                window.SnipesAppIntegration.initDropdownTrigger();
            }

            window.SnipesAppIntegration.initScrollEvent();
            window.SnipesAppIntegration.initBottomPadding();
            window.SnipesAppIntegration.initPdpBackButton();
            window.SnipesAppIntegration.initPdpBackButtonHandler();
        }
    }
};
//NOTE: If you rename SnipesAppIntegration, tracking will be broken.
//Please notify Timo Puschmann snipes-digitaldata@snipes.com about that changes.
module.exports = window.SnipesAppIntegration;
window.SnipesAppIntegration.init();
