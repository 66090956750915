import Component from 'client/core/Component';
import $ from 'jquery';

// @ts-ignore
const analyticsData = window.analyticsData || null;

export default class Emarsys extends Component {
    initQuickViewAnalytics() {
        let executeCommandsInQueue = this.executeCommandsInQueue;

        $('.product').on('click', '.quickview', function () {
            window.ScarabQueue.push(['view', $(this).closest('.product').data('pid')]);
            executeCommandsInQueue();
        });
    }

    initShopTheLookAnalytics() {
        let executeCommandsInQueue = this.executeCommandsInQueue;
        let clickTimeout;

        $('.js-s-bndla').on('click', '.bndla-category-button', function (event) {
            clearTimeout(clickTimeout);

            const outfitCategoryName = $(event.currentTarget).find('span').text();

            if (outfitCategoryName) {
                clickTimeout = setTimeout(function() {
                    window.ScarabQueue.push(['tag', 'predict_last_session_shop_the_look',
                        { shopthelook: 'shopthelook-overview', shopthelookdetails: outfitCategoryName }]);
                    executeCommandsInQueue();
                }, 500);
            }
        });
    }

    // eslint-disable-next-line class-methods-use-this
    executeShowTheLookAnalytics() {
        const categoryRegex = /.*category=([a-zA-Z\s]+)/gm;
        // @ts-ignore
        const result = categoryRegex.exec(window.location.href);

        if (result) {
            window.ScarabQueue.push(['tag', 'predict_last_session_shop_the_look',
                { shopthelook: 'shopthelook-overview', shopthelookdetails: result[1] }]);
        }
    }

    // eslint-disable-next-line class-methods-use-this
    executeCliquePricesAnalytics() {
        window.ScarabQueue.push(['tag', 'predict_last_session_clique_prices_overview',
            { cliqueoffer: 'cliqueprices' }]);
    }

    // eslint-disable-next-line class-methods-use-this
    executeViewAccountAnalytics() {
        window.ScarabQueue.push(['tag', 'predict_last_session_my_account', { cliqueaccount: 'view-account' }]);
    }

    // eslint-disable-next-line class-methods-use-this
    executeUTMCampaignAnalytics() {
        let utmCampaign = analyticsData.emarsysAnalytics.utmCampaign;

        if (utmCampaign) {
            window.ScarabQueue.push(['tag', 'utm_campaign', { utm_campaing: utmCampaign }]);
        } else {
            window.ScarabQueue.push(['tag', 'empty_campaign']);
        }
    }

    identifyCustomer() {
        let customerData = analyticsData.emarsysAnalytics.customerData;

        this.isIdentifyCustomer = customerData && (customerData.isCustomer || customerData.cuid);

        if (this.isIdentifyCustomer) {
            window.ScarabQueue.push(['setCustomerId', customerData.cuid]);
        } else if (customerData && customerData.guestEmail) {
            window.ScarabQueue.push(['setCustomerId', customerData.guestEmail]);
        }
    }

    // eslint-disable-next-line class-methods-use-this
    getCartOnPageLoad() {
        window.ScarabQueue.push(['cart', analyticsData.emarsysAnalytics.currentBasket]);
    }

    async addInitialCommandsOnPageLoad() {
        if (!this.config.isCheckoutPage) {
            this.getCartOnPageLoad();
        }

        this.identifyCustomer();

        if (analyticsData && analyticsData.emarsysAnalytics.nameTracking &&
            analyticsData.emarsysAnalytics.trackingData) {
            window.ScarabQueue.push([
                analyticsData.emarsysAnalytics.nameTracking,
                analyticsData.emarsysAnalytics.trackingData
            ]);
        }

        if (analyticsData.emarsysAnalytics.event_shopthelook_enabled && this.isIdentifyCustomer) {
            this.executeShowTheLookAnalytics();
        }

        if ((analyticsData.emarsysAnalytics.event_cliqueprices_enabled ||
            analyticsData.emarsysAnalytics.isCliquepricesCategory) &&
            this.isIdentifyCustomer) {
            this.executeCliquePricesAnalytics();
        }

        if (analyticsData.emarsysAnalytics.isViewAccountEventEnabled && this.isIdentifyCustomer) {
            this.executeViewAccountAnalytics();
        }

        if (analyticsData.emarsysAnalytics.isUTMCampaignEventEnabled && this.isIdentifyCustomer) {
            this.executeUTMCampaignAnalytics();
        }

        if (this.config.isCheckoutPage && analyticsData.emarsysAnalytics.customerData.cuid) {
            this.getCartOnPageLoad();
            this.executeCommandsInQueue();
        }

        if (!this.config.isCheckoutPage) {
            this.executeCommandsInQueue();
        }
    }

    onAddedToCart(data) {
        window.ScarabQueue.push(['cart', this.convertCartItems(data.cart.items)]);
        this.executeCommandsInQueue();
    }

    onCartUpdated(cart) {
        window.ScarabQueue.push(['cart', this.convertCartItems(cart.items)]);
        this.executeCommandsInQueue();
    }

    onRefinementApplyFilter(data) {
        if (data.type === 'category' && data.categoryChain) {
            this.getCartOnPageLoad();

            window.ScarabQueue.push([
                'category',
                data.categoryChain
            ]);

            this.executeCommandsInQueue();
        }
    }

    // eslint-disable-next-line class-methods-use-this
    convertCartItems(items) {
        let result = [];

        if (items.length > 0) {
            result = items.map((item) => {
                return { item: item.variationGroupID, price: item.priceTotal.priceDecimal, quantity: item.quantity };
            });
        }

        return result;
    }

    onSearch(searchTerm) {
        window.ScarabQueue.push(['searchTerm', searchTerm]);
        this.getCartOnPageLoad();
        this.executeCommandsInQueue();
    }

    // eslint-disable-next-line class-methods-use-this
    executeCommandsInQueue() {
        // @ts-ignore
        if (window.EmarsysPreferences.isAnaliticsTestMode) {
            window.ScarabQueue.push(['testMode']);
        }

        window.ScarabQueue.push(['go']);
    }

    onSetCUID(cuid) {
        if (cuid) {
            this.getCartOnPageLoad();
            window.ScarabQueue.push(['setCustomerId', cuid]);
            this.executeCommandsInQueue();

            if (analyticsData.emarsysAnalytics.customerData) {
                // @ts-ignore
                window.analyticsData.emarsysAnalytics.customerData.cuid = cuid;
            }
        }
    }

    initializeEventListeners() {
        this.initQuickViewAnalytics();

        if (analyticsData.emarsysAnalytics.event_shopthelook_enabled && this.isIdentifyCustomer) {
            this.initShopTheLookAnalytics();
        }

        this.emitter.addListener('cart.add.added', this.onAddedToCart.bind(this));
        this.emitter.addListener('cart.updated', this.onCartUpdated.bind(this));
        this.emitter.addListener('emarsys.search.term', this.onSearch.bind(this));
        this.emitter.addListener('emarsys.set.email', this.onSetCUID.bind(this));

        if (this.config.isCheckoutPage && !analyticsData.emarsysAnalytics.customerData.cuid) {
            this.emitter.addListener('emarsys.guest.email.filled', this.onSetCUID.bind(this));
        }

        this.emitter.addListener('refinement.applyFilter', this.onRefinementApplyFilter.bind(this));
    }

    async init() {
        const isEmarsysEnabled = analyticsData && analyticsData.emarsysAnalytics.isEnableEmarsys;

        if (isEmarsysEnabled) {
            this.addInitialCommandsOnPageLoad();
            this.initializeEventListeners();
        }
    }
}
