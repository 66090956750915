import BaseAddressBookMgr from 'client/components/account/addressBook/AddressBookMgr';
import ajax from 'client/utils/ajax';
import { appendParamToURL, appendParamsToURL } from 'client/utils/url';

export default class AddressBookMgr extends BaseAddressBookMgr {
    addAddressStart(data) {
        if (!data.sameContainer) {
            this.toggleSections();
        }

        this.activeAddSection = data.section;

        let url = data.isBilling ? this.config.addBillingUrl : this.config.addUrl;

        if (data.formData) {
            url = appendParamsToURL(url, data.formData);
        }

        if (data.methodId) {
            url = appendParamToURL(url, 'methodId', data.methodId);
        }

        let promise = ajax.load({ url: url });

        promise.then(response => data.callback(response));
    }

    addAddressSave(data) {
        this.activeAddSection = null;

        let url = this.config.saveUrl;

        if (data.isBilling) {
            url = appendParamToURL(url, 'isBilling', data.isBilling);
        } else if (data.methodId) {
            url = appendParamToURL(url, 'methodId', data.methodId);
        }

        if (data.countryCode) {
            url = appendParamToURL(url, 'countryCode', data.countryCode);
        }

        let promise = ajax.getJson({
            url: url,
            type: 'POST',
            data: data.form
        });

        promise.then(response => {
            data.callback(response);

            if (response.success) {
                this.updateList();
            }
        });
    }

    editAddressStart(data) {
        this.toggleSections();

        this.activeEditSection = data.section;

        let url;

        if (data.isBilling) {
            url = this.config.editBillingUrl;
        } else {
            url = appendParamToURL(this.config.editUrl, 'methodId', data.methodId);
        }

        url = appendParamToURL(url, 'addressId', data.addressId);

        if (data.countryCode) {
            url = appendParamToURL(url, 'countryCode', data.countryCode);
        }

        let promise = ajax.load({ url: url });

        promise.then((r) => data.callback(r));
    }

    editAddressSave(data) {
        this.activeEditSection = null;

        let url = this.config.saveUrl;

        if (data.isBilling) {
            url = appendParamToURL(url, 'isBilling', data.isBilling);
        } else if (data.methodId) {
            url = appendParamToURL(url, 'methodId', data.methodId);
        }

        if (data.addressId) {
            url = appendParamToURL(url, 'addressId', data.addressId);
        }

        if (data.countryCode) {
            url = appendParamToURL(url, 'countryCode', data.countryCode);
        }

        let promise = ajax.getJson({
            url: url,
            type: 'POST',
            data: data.form
        });

        promise.then(response => {
            data.callback(response);

            this.updateList();
        });
    }
}
