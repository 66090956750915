import ProductTiles from 'client/components/product/ProductTiles';
import $ from 'jquery';
import 'slick-carousel';
import prefs from 'sitePreferences';
import { isInViewport } from 'client/utils/common';
import { throttle } from 'lodash';

const DEFAULT_CONFIG = {
    'dots': false,
    'arrows': true,
    'infinite': true,
    'autoplay': false,
    'autoplaySpeed': 7000,
    'pauseOnHover': true,
    'speed': 300,
    'touchThreshold': 10,
    'waitForAnimate': false,
    'responsive': [
        {
            'breakpoint': prefs.breakpointMedium + 1,
            'settings': {
                'arrows': false,
                'dots': true,
                'draggable': false
            }
        }
    ]
};

const SELECTORS = {
    tileContainer: '.js-tile-container',
    productTile: '.js-product-tile',
    wishListTiles: '.js-wish-list-btn',
    wishlistIcon: '.js-wishlist-icon',
    wishlistProducts: '.js-wishlist-info',
    slide: '.slick-slide',
    cloned: '.slick-cloned',
    contentSlot: '[data-slot-id]'
};

const CLASSES = {
    wishlistAdd: 'js-wish-list--add',
    wishlistRemove: 'js-wish-list--remove',
    iconWishlistAdded: 'i-wishlist--added',
    slickInitialized: 'slick-initialized'
};

export default class Carousel extends ProductTiles {
    // eslint-disable-next-line consistent-return
    init() {
        this.orientationChange = false;

        this.config = Object.assign({}, DEFAULT_CONFIG, this.config, this.config.assetConfig);
        delete this.config.assetConfig;
        this.emitter.addListener('update.tile.wishlist.icon', (data) => this.updateWishListIcons(data));
        let isProductCarousel = this.$el.find('[data-cmp="productBrand"]');

        if (!('slick' in $.fn)) {
            return false;
        }

        this.updateWishListIcons();

        this.$el.on('reinit', () => {
            this.reInit();
        });

        this.$el.on('init', () => {
            this.emitter.emit('namespace.component.initall', this.$el, {
                swatches: false
            });

            // TODO should be improve to generate event inside component
            if (isProductCarousel.length > 0) {
                this.emitter.emit('recalculateBrandCarousel');
            }
        });

        this.$el.on('afterChange', (event, slick, currentSlide) => {
            // TODO should be improve to generate event inside component
            if (isProductCarousel.length > 0) {
                this.emitter.emit('recalculateBrandCarousel');
            }
            this.emitter.emit('carouselAfterChange', event, slick, currentSlide);

            setTimeout(() => {
                if (this.orientationChange) {
                    this.emitter.emit('namespace.component.destroyall', this.$el, {
                        onAfterDestroy: () => {
                            this.emitter.emit('namespace.component.initall', this.$el);
                        }
                    });

                    this.orientationChange = false;
                }
            });

            if (this.gtmEnabled) {
                this.trackProductImpression.bind(this);
            }
        });

        this.emitter.addListener('orientationChange', () => {
            this.orientationChange = true;

            if (this.gtmEnabled) {
                this.trackProductImpression.bind(this);
            }
        });

        if (this.config.pageDesigner) {
            this.$el.find('.experience-component').unwrap();
        }

        this.$el.slick(this.config);

        if (this.gtmEnabled) {
            if (document.readyState === 'complete') {
                this.trackProductImpression();
            } else {
                $(window).on('load', this.trackProductImpression.bind(this));
            }

            this.emitter.addListener('resize', this.trackProductImpression.bind(this));
            this.emitter.addListener('scroll', throttle(this.trackProductImpression.bind(this), 200));
        }

        this.updateAllTilesReleaseDates();

        this.emitter.addListener('slick.reinit', () => this.$el.slick('reinit'));
    }

    getProductData($el, list) {
        const product = $el.data('gtm');

        if (product) {
            const eventData = Object.assign({ list }, product);

            return eventData;
        }

        return null;
    }

    trackProductImpression() {
        const $container = this.$el.is(':hidden') ? this.$el.parent() : this.$el,
            contentSlot = this.$el.closest(SELECTORS.contentSlot),
            slotID = contentSlot.data('slot-id'),
            slotName = contentSlot.data('slot-name'),
            recommenderName = contentSlot.data('recommender-name') || contentSlot.data('recommender'),
            $productTile = $container.find(SELECTORS.productTile);

        if (!$productTile.length) {
            return;
        }

        const impressions = [];

        $productTile.each((idx, el) => {
            const $el = $(el);

            if ($el.data('tracked') || !isInViewport($el) || $el.is(':hidden')) {
                return;
            }

            if ($el.closest(SELECTORS.slide).attr('aria-hidden') === 'true') {
                return;
            }

            // eslint-disable-next-line no-nested-ternary
            const slotPart = recommenderName ?
                (slotName ? `/${slotName}/${recommenderName}` : `/${recommenderName}`) :
                (slotID ? `/${slotID}` : '');

            const data = this.getProductData(
                $el,
                `${this.gtmPageType}/Reco${slotPart}`
            );

            if (data) {
                const position = $el.closest(SELECTORS.tileContainer).data('position');

                impressions.push(Object.assign({ position: position || 1 }, data));
            }

            $el.data('tracked', '1');
        });

        if (impressions.length) {
            const eventData = Object.assign(
               { currencyCode: window.SitePreferences.currencyCode },
               { impressions: impressions }
            );

            this.emitter.emit('gtmEcommerceEvent', 'eeProductImpression', eventData);
        }
    }

    updateWishListIcons(data) {
        let wishlistProducts = [];
        let wishListProductData;

        if (data) {
            wishListProductData = data;
        } else {
            let wishListProduct = $(SELECTORS.wishlistProducts);

            wishListProductData = wishListProduct.data('wishlistProducts');
        }

        if (wishListProductData) {
            wishlistProducts = wishListProductData.split(',');
        }

        let $wishListTiles = this.$el.parent().find(SELECTORS.wishListTiles);

        $wishListTiles.each(function (index, element) {
            let $elem = $(element),
                pidInWishlist = wishlistProducts.indexOf($elem.data('pid')) > -1;

            $elem.toggleClass(CLASSES.wishlistAdd, !pidInWishlist)
                .toggleClass(CLASSES.wishlistRemove, pidInWishlist)
                .find(SELECTORS.wishlistIcon)
                .toggleClass(CLASSES.iconWishlistAdded, pidInWishlist);
        });
    }

    reindex() {
        this.$el.find(SELECTORS.slide).not(SELECTORS.cloned).each((index, element) => {
            $(element).attr('data-slick-index', index);
        });

        this.emitter.emit('carouselAfterChange');
    }

    destroy() {
        if (this.$el.hasClass(CLASSES.slickInitialized)) {
            this.$el.slick('unslick');
        }

        super.destroy();
    }

    reInit() {
        this.destroy();
        this.init();
        this.$el.slick('reinit');
    }
}
