'use strict';

function searchCondition(condition) {
    return window.navigator.userAgent.indexOf(condition) > -1;
}

export function isValidUserAgent(rule) {
    if (!rule) {
        return true;
    }

    var valid = false;

    valid = rule.split('|').some(item => {
        var check;

        if (item.indexOf('&') > -1) {
            check = item.split('&').every(el => {
                return searchCondition(el);
            });
        } else {
            check = searchCondition(item);
        }

        return check;
    });

    return valid;
}
