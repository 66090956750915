import Form from 'client/core/Form';
import { getJson } from 'client/utils/ajax';
import { CLASSES } from 'client/utils/globals';
import { getToken } from 'urls';

const SELECTORS = {
    successBlock: '.js-success',
    failBlock: '.js-fail',
    fieldsContainer: '.js-fields-container',
    fieldsContainerContent: '.js-fields-container-content'
};

export default class UnsubscribeForm extends Form {
    beforeSubmit () {
        this.config.emitEventBeforeSubmit &&
        this.emitter.emit(this.config.emitEventBeforeSubmit);
    }

    async onSubmit (el, e) {
        e.preventDefault();

        let isValid = await super.onSubmit();

        if (isValid) {
            let data = this.collectDataForAction(el);
            let csrfToken = (await getJson({
                type: 'POST',
                url: getToken
            })).csrf;

            if ('beforeSubmit' in this && typeof this.beforeSubmit === 'function') {
                this.beforeSubmit();
            }

            this.emit('submit.before');

            //Apply new csrf token for cached form
            for (let i = 0; i < data.length; i++) {
                if ('name' in data[i] && data[i].name === csrfToken.tokenName) {
                    data[i].value = csrfToken.token;
                    break;
                }
            }

            try {
                let callResult = await getJson({
                    url: this.config.actionUrl,
                    type: 'POST',
                    dataType: 'JSON',
                    data: data
                });

                if (callResult.error) {
                    this.onFailSubmit();
                    this.emit('submit.fail');

                    isValid = false;
                } else {
                    this.onSuccessSubmit(callResult);
                    this.emit('submit.success');
                }
            } catch (err) {
                this.onFailSubmit();
                this.emit('submit.fail');

                isValid = false;
            }

            this.emit('submit.done');
        }

        return isValid;
    }

    hideFieldsContainer (content) {
        if (content) {
            this.$el.find(content).addClass(CLASSES.hide);
        } else {
            this.$el.find(SELECTORS.fieldsContainer).addClass(CLASSES.hide);
            this.$el.find(SELECTORS.fieldsContainerContent).addClass(CLASSES.hide);
        }
    }

    onFailSubmit () {
        this.hideFieldsContainer(SELECTORS.fieldsContainerContent);
        this.$el.find(SELECTORS.failBlock).removeClass(CLASSES.hide);
        this.emit('submit.fail');
    }

    onSuccessSubmit (data) {
        if (this.gtmEnabled) {
            this.trackSubscribe(data);
        }

        this.hideFieldsContainer();
        this.$el.find(SELECTORS.failBlock).addClass(CLASSES.hide);

        this.$el.find(SELECTORS.successBlock).removeClass(CLASSES.hide);

        this.emit('submit.success');
    }

    trackSubscribe(data) {
        const eventData = {
            eventCategory: 'newsletter',
            eventAction: this.config.gtmAction,
            eventLabel: data.interest,
            ga4eventname: 'newsletter'
        };

        this.emitter.emit('gtmEvent', eventData);
    }
}
