/**
 * @function
 * @param {String} val
 * @returns {Number|null}
 */
export function stringToInteger(val) {
    let parsed = parseInt(val, 10);

    return isNaN(parsed) ? null : parsed;
}
