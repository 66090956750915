import Component from 'client/core/Component';
import ajax from 'client/utils/ajax';
import $ from 'jquery';
export default class DashBoardProfileCard extends Component {

    async init() {
        this.url = this.$el.data('url');

        this.loadDashBoardProfile();
    }

    async loadDashBoardProfile () {
        try {
            var _$el = this.$el;

            let promise = ajax.load({
                url: this.url,
                type: 'GET'
            });

            promise.then(response => {
                _$el.html(response);
            });

        } catch (e) {
            $ && $.spinner && $.spinner.stopAll();
        }
    }
}
