import { getMelissaToken } from 'urls';
import { isValidUserAgent } from 'client/utils/checkUserAgent';
import { getJson, registerErrorHandler } from 'client/utils/ajax';
import { acsUserAgentRule,
    globalExpressPostalCodeUrl,
    globalExpressThoroughfareUrl,
    SVPCEnabled } from 'sitePreferences';
import { ERROR_MESSAGE_ZIP_CODE_NOTEXIST } from 'resources';
import { autocompleteServiseURL } from 'urls';
import Form from 'client/core/Form';
import { getPostalCodeConfig } from 'client/utils/customPostalCodeValidator';
import prefs from 'sitePreferences';
import $ from 'jquery';

const SELECTORS = {
    'INPUT_FIELD': '.js-field',
    'POSTAL_CODE_INPUT': '.js-postal-code-input .js-field',
    'COUNTRY_CODE_INPUT': '.js-shipping-countryCode .js-field',
    'SHIPPING_ADDRESS_STATE': '.js-shipping-address-state'
};
const MAX_RECORDS = 5;
const MIN_CHARS = 3;
const DEFAULT_CONFIG = {
    isEnabled: true
};

export default class AutocompleteAddress extends Form {
        // eslint-disable-next-line consistent-return
    init() {
        if (prefs.stateByZipMappingJSON) {
            this.bindEvent('blur', SELECTORS.POSTAL_CODE_INPUT, (el) => this.autoselectStateByZipCode(el));
            this.autoselectStateByZipCode();
        }

        this.config = Object.assign({}, DEFAULT_CONFIG, this.config);
        if (!this.config.isEnabled || !isValidUserAgent(acsUserAgentRule)) {
            return true;
        }
        this.$fields = new Map(this.config.fields);
        this.$isReconnect = false;
        if (this.$fields.get('postalCode')) {
            this.getNestedComponentById(
                    this.$fields.get('postalCode'),
                cmp => {
                    cmp.bindEvent(
                        'input',
                        SELECTORS.INPUT_FIELD,
                        this.rateLimit(this.onAutocompleteCity.bind(this), 500)
                    );
                }
            );
        }
        if (this.$fields.get('street')) {
            this.getNestedComponentById(
                this.$fields.get('street'),
                cmp => {
                    cmp.bindEvent(
                        'input',
                        SELECTORS.INPUT_FIELD,
                        this.rateLimit(this.onAutocompleteStreet.bind(this), 500)
                    );
                }
            );
        }
        this.emitter.addListener('autocomlete.fields', (data) => this.autocomleteFields(data));
    }

    // eslint-disable-next-line consistent-return
    onAutocompleteCity() {
        let postalCode = this.getFieldValue(this.$fields.get('postalCode'));
        const country = this.getFieldValue(this.$fields.get('country'));
        const { startAutocomplit = MIN_CHARS, startCheckMatches = MIN_CHARS } = getPostalCodeConfig(country);

        if ((postalCode.length < startAutocomplit) && (postalCode.length < startCheckMatches)) {
            this.emitter.emit(`autocomlete.list.${this.$fields.get('postalCode')}.close`);
            return false;
        }

        this.getData({
            url: SVPCEnabled === true ? autocompleteServiseURL : globalExpressPostalCodeUrl,
            snipesValidatorIsEnabled: SVPCEnabled,
            data: {
                'format': 'json',
                'postalcode': postalCode,
                'country': country,
                'nativecharset': true,
                'maxrecords': MAX_RECORDS
            },
            callback: (response) => this.handleCityResult(response)
        });
    }

    // eslint-disable-next-line consistent-return
    onAutocompleteStreet() {
        let postalCode = this.getFieldValue(this.$fields.get('postalCode'));
        let street = this.getFieldValue(this.$fields.get('street'));
        const country = this.getFieldValue(this.$fields.get('country'));
        const { startAutocomplit = MIN_CHARS } = getPostalCodeConfig(country);

        if (!postalCode.length || street.length < startAutocomplit) {
            this.emitter.emit(`autocomlete.list.${this.$fields.get('street')}.close`);
            return false;
        }

        let config = {
            url: globalExpressThoroughfareUrl,
            snipesValidatorIsEnabled: false,
            data: {
                'format': 'json',
                'postalcode': postalCode,
                'country': country,
                'thoroughfare': street,
                'nativecharset': true,
                'maxrecords': MAX_RECORDS
            },
            callback: (response) => this.handleStreetResult(response)
        };

        this.getData(config);
    }

    async getData(config) {
        if (config.snipesValidatorIsEnabled === true) {
            getJson({
                type: 'POST',
                url: config.url,
                data: config.data,
                callback: config.callback
            });
        }

        if (config.snipesValidatorIsEnabled === false) {
            let authData = await this.getAuthData();

            if (Object.keys(authData).length) {
                registerErrorHandler(this.setConnectionError.bind(this));

                getJson({
                    type: 'GET',
                    url: config.url,
                    headers: {
                        'Authorization': 'Bearer ' + authData.token,
                        'User-Session-Id': authData.sessionID,
                        'Signature': authData.signature
                    },
                    data: config.data,
                    callback: config.callback
                });

            }
        }
    }

    handleCityResult(response) {
        let postalCode = this.getFieldValue(this.$fields.get('postalCode'));
        let cmp = this.getNestedComponentById('shipping-postalCode');
        const country = this.getFieldValue(this.$fields.get('country'));
        const { startAutocomplit = MIN_CHARS, startCheckMatches = MIN_CHARS } = getPostalCodeConfig(country);


        if (response) {
            let result = this.parseCity(response);

            if (result.length && postalCode.length >= startAutocomplit) {
                this.emitter.emit(`autocomlete.list.${this.$fields.get('postalCode')}.render`, result);
            } else {
                this.emitter.emit(`autocomlete.list.${this.$fields.get('postalCode')}.close`);
            }

            if (result.length === 0 && postalCode.length >= startCheckMatches) {
                cmp.setError(ERROR_MESSAGE_ZIP_CODE_NOTEXIST);
            } else {
                cmp.clearError();
            }

            this.$isReconnect = false;
        } else {
            this.$isReconnect && this.onAutocompleteCity();
        }
    }

    handleStreetResult(response) {
        if (response) {
            let result = this.parseStreet(response);

            this.$isReconnect = false;

            if (result.length) {
                this.emitter.emit(`autocomlete.list.${this.$fields.get('street')}.render`, result);
            } else {
                this.emitter.emit(`autocomlete.list.${this.$fields.get('street')}.close`);
            }
        } else {
            this.$isReconnect && this.onAutocompleteStreet();
        }
    }

    async getAuthData() {
        let response = await getJson({
            type: 'GET',
            url: getMelissaToken
        });

        return response;
    }

    parseCity(response) {
        let result = [];

        response.Results.forEach(
            element => {
                if (
                    'Address' in element && 'Locality' in element.Address &&
                    'PostalCode' in element.Address
                ) {
                    // eslint-disable-next-line no-nested-ternary
                    let postalCode = element.Address.PostalCode ?
                        element.Address.PostalCode :
                        (('PostalCodePrimary' in element.Address) ?
                            element.Address.PostalCodePrimary :
                            ''
                        );

                    result.push(
                        {
                            'value': postalCode,
                            'htmlValue': postalCode + ' ' +
                                         element.Address.Locality,
                            'data': {
                                'fieldname': 'shipping-city',
                                'fieldvalue': element.Address.Locality,
                                'event': 'autocomlete.fields'
                            }
                        }
                    );
                }
            }
        );

        return result;
    }

    parseStreet(response) {
        let result = [];

        response.Results.forEach(
            element => {
                if ('Address' in element && 'Address1' in element.Address) {
                    // eslint-disable-next-line no-nested-ternary
                    let street = element.Address.Address1 ?
                        element.Address.Address1 :
                        (('Thoroughfare' in element.Address) ?
                            element.Address.Thoroughfare :
                            ''
                        );

                    result.push(
                        {
                            'value': street,
                            'htmlValue': street,
                            'data': {}
                        }
                    );
                }
            }
        );

        return result;
    }

    setConnectionError(xhr, textStatus, errorThrown) { // eslint-disable-line no-unused-vars
        //error 401 no authorize
        if (xhr.status === 401) {
            this.connectError = true;
        }
    }

    getFieldValue(cmpID) {
        let cmp = this.getNestedComponentById(cmpID),
            value = '';

        if (cmp && typeof cmp.getValue === 'function') {
            value = cmp.getValue().trim();

            if (value.length < MIN_CHARS) {
                cmp.$field.attr('autocomplete', 'off');
            } else {
                cmp.$field.attr('autocomplete', 'none');
            }
        }

        return value;
    }

    setFieldValue(cmpID, value) {
        let cmp = this.getNestedComponentById(cmpID);

        if (cmp && typeof cmp.setValue === 'function' && value) {
            cmp.setValue(value);
        }
    }

    autocomleteFields(data) {
        this.setFieldValue(data.cmpID, data.value);
    }

    rateLimit(fn, delay) {
        var queue = [],
            timer = null;

        function processQueue() {
            var item = queue.shift();

            if (item) {
                fn.apply(item.arguments);
            }

            if (!queue.length) {
                clearInterval(timer);
                timer = null;
            }
        }

        return function limited() {
            queue.push({
                arguments: [].slice.call(arguments)
            });

            if (!timer) {
                processQueue();
                timer = setInterval(processQueue, delay);
            }
        };
    }

    getZipCodeValue() {
        const currentZipElement = this.$el.find(SELECTORS.POSTAL_CODE_INPUT);
        const currentZip = currentZipElement && currentZipElement.val();

        return currentZip;
    }

    getStateByZipCode(stateByZipMapForCountry, enteredZipCode) {
        if (!stateByZipMapForCountry || !enteredZipCode) {
            return null;
        }

        let state = null;
        const states = Object.keys(stateByZipMapForCountry).reduce((result, item) => {
            const stateCode = item;
            const zipCodes = stateByZipMapForCountry[item];
            const matchedZip = zipCodes.find(zipCodePrefix => new RegExp(`^${zipCodePrefix}`).test(enteredZipCode));

            if (matchedZip) {
                result[matchedZip] = stateCode;
            }

            return result;

        }, {});

        if (states && Object.keys(states).length) {
            state = states[Object.keys(states).sort((a, b) => b.length - a.length)[0]];

        }

        return state;
    }

    autoselectStateByZipCode(el) {
        const stateByZipMappingJSON = prefs.stateByZipMappingJSON;
        const selectedCountry = (
            stateByZipMappingJSON &&
            this.$el.find(SELECTORS.COUNTRY_CODE_INPUT).find(':selected').val()
        );
        const stateByZipMapForCountry = selectedCountry && stateByZipMappingJSON[selectedCountry];
        const enteredZipCode = el ? $(el).val() : this.getZipCodeValue();
        const mappedState = this.getStateByZipCode(stateByZipMapForCountry, enteredZipCode);
        const $selectedAddressStateWrapper = this.$el.find(SELECTORS.SHIPPING_ADDRESS_STATE);
        const $selectedAddressStateSelect = $selectedAddressStateWrapper.find(SELECTORS.INPUT_FIELD);

        if (!stateByZipMapForCountry || !mappedState) {
            $selectedAddressStateWrapper.removeClass('m-locked');
            return;
        }

        $selectedAddressStateSelect.val(mappedState).trigger('change');
        $selectedAddressStateWrapper.addClass('m-locked');
    }
}
