import BasePaymentStep from 'client/components/checkout/PaymentStep';
import { CLASSES } from 'client/utils/globals';
import $ from 'jquery';
import inputmask from 'inputmask';
import { getCurrentDateAsString } from 'client/utils/common';

const SELECTORS = {
    TAB: '.js-payment-option-tab',
    SUBMIT_BTN: '.js-checkout-step-submit',
    birthday: '[data-id="klarna-birthday"] .js-field'
};

export default class PaymentStep extends BasePaymentStep {
    init () {
        super.init();

        this.$submitBtn = this.$el.find(SELECTORS.SUBMIT_BTN);

        this.emitter.addListener('checkout.step.shipping.submitted', this.updateMethodList.bind(this));
        this.emitter.addListener('checkout.payment.method.changed', this.toggleSubmitButton.bind(this));

        this.toggleSubmitButton();
    }

    onStepError(errorType) {
        if (errorType && errorType === 'giftcard') {
            if (this.giftCardCmp) {
                this.giftCardCmp.error.removeClass(CLASSES.hide);
                this.giftCardCmp.clear();
            }
        } else if (errorType && errorType === 'missingpayment') {
            this.paymentOptions.inactive();
        } else {
            if (this.giftCardCmp) {
                this.giftCardCmp.clear();
            }
            this.paymentOptions.inactive();
        }
    }

    updateMethodList(data) {
        if (data && data.paymentOptions && this.paymentOptions) {
            this.paymentOptions.$el.off('click', SELECTORS.TAB);
            this.emitter.emit('namespace.component.destroyall', this.paymentOptions.$el, { onAfterDestroy: () => {
                this.paymentOptions.$el.children().remove();
                this.paymentOptions.$el.append($('<div/>').append(data.paymentOptions).find('ul').children());
                this.paymentOptions.init();

                this.emitter.emit('namespace.component.reinitall', this.paymentOptions.$el, { onAfterInit: async () => {
                    this.getInputMask().mask(SELECTORS.birthday);

                    let birthdayCmp = await this.paymentOptions.getNestedComponentById('klarna-birthday');
                    let phoneCmp = await this.paymentOptions.getNestedComponentById('klarna-birthday');
                    let formCmps = [...this.form._nestedComponents];

                    for (let i = 0; i < formCmps.length; i++) {
                        if (birthdayCmp && formCmps[i].id === birthdayCmp.id) {
                            this.replaceCmp(this.form, i, birthdayCmp);
                        } else if (phoneCmp && formCmps[i].id === phoneCmp.id) {
                            this.replaceCmp(this.form, i, phoneCmp);
                        }
                    }

                    this.toggleSubmitButton();
                } });
            } });
        }
    }

    replaceCmp(cmpWithComponents, idx, cmpToReplace) {
        cmpWithComponents._nestedComponents[idx] = cmpToReplace;
    }

    toggleSubmitButton() {
        if (this.paymentOptions) {
            let selectedMethod = this.paymentOptions && this.paymentOptions.getValue();

            this.$submitBtn.prop('disabled', !selectedMethod || !selectedMethod.id);
        }
    }

    getInputMask () {
        return inputmask('datetime', {
            inputFormat: 'dd.mm.yyyy',
            placeholder: '_',
            min: '01.01.1901',
            max: getCurrentDateAsString()
        });
    }
}
