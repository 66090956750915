import EventEmitter from './EventEmitter';
import { uuidv4 } from './Base';
import mixins from './mixins';
import $ from 'jquery';
import utils from '../utils/componentsUtils';

const CAROUSELS = [
    'carousel',
    'imageCarousel',
    'variationAttributes',
    'swatchCarousel'
];

const SWATCHES = [
    'swatchCarousel'
];

const SELECTORS = {
    cmp: '[data-cmp]',
    recommendation: '*[id^="cq_recomm_slot-"]'
};

export default class Namespace {
    constructor () {
        this.eventEmitter = new EventEmitter();
        this.componentsRegister = this.getDefaultComponents();

        this.eventEmitter.addListener('namespace.component.reinit', this.reInitComponent.bind(this));
        this.eventEmitter.addListener('namespace.component.init', this.initComponent.bind(this));
        this.eventEmitter.addListener('namespace.component.destroy', this.destroyComponent.bind(this));
        this.eventEmitter.addListener('namespace.component.initall', this.initAllComponents.bind(this));
        this.eventEmitter.addListener('namespace.component.destroyall', this.destroyAllComponents.bind(this));
        this.eventEmitter.addListener('namespace.component.reinitall', this.reInitAllComponents.bind(this));
    }

    getDefaultComponents () {
        return {};
    }

    addComponents (components) {
        this.componentsRegister = Object.assign({}, this.componentsRegister, components);
    }

    getParentCmp (cmpInstance) {
        if (cmpInstance) {
            return (cmpInstance.$el || cmpInstance).parent().closest(SELECTORS.cmp).data('cmp-instance');
        } else {
            return null;
        }
    }

    /**
     * Link initialized components to parent, perform after-init actions
     * @param {Promise[]} promises
     * @param {Object} opts - init options
     * @param {Object} cmp - parent component
     * @returns {Promise}
     */
    prepareInitResult(promises, opts = {}, cmp = null) {
        return Promise.all(promises).then((nestedComponents) => {
            if (cmp) {
                cmp._nestedComponents = cmp._nestedComponents.concat(nestedComponents.filter((nestedComponent) => {
                    return !!nestedComponent;
                }));
            }

            if (typeof opts.onAfterInit === 'function') {
                opts.onAfterInit();
            }
        });
    }

    /**
     * Init all components in given scope(if specified) or in whole apllication
     * @param {JQuery} $scope - optional parameter that specify scope of components
     * @param {Object} opts - init options
     * @returns {Promise}
     */
    initAllComponents ($scope = null, opts = {}) {
        let cmp;

        if ($scope) {
            cmp = $scope.closest('[data-cmp]').data('cmp-instance');
        } else {
            $scope = $(document);
            cmp = null;
        }

        opts = Object.assign({ initSession: uuidv4() }, opts);

        const promises = $scope.find(SELECTORS.cmp).map((i, el) => this.initComponent($(el), opts));

        return this.prepareInitResult(promises, opts, cmp);
    }

    /**
     * ReInit all components in given scope(if specified) or in whole apllication
     * @param {JQuery} $scope - optional parameter that specify scope of components
     * @param {Object} opts - init options
     * @returns {Promise}
     */
    reInitAllComponents ($scope = null, opts = {}) {
        opts = Object.assign({ reInit: true }, opts);

        return this.initAllComponents($scope, opts);
    }

    /**
     * Destroy all components in given scope(if specified) or in whole apllication
     * @param {JQuery} $scope - optional parameter that specify scope of components
     * @returns {Promise}
     */
    destroyAllComponents ($scope = null, opts = {}) {
        if (!$scope) {
            $scope = $(document);
        }

        const promises = $scope.find(SELECTORS.cmp).map((i, el) => this.destroyComponent($(el)));

        return Promise.all(promises).then(() => {
            if (typeof opts.onAfterDestroy === 'function') {
                opts.onAfterDestroy();
            }
        });
    }

    /**
     * Return true, when component is allowed to init nested
     *
     * @param {String} cmpName
     * @param {Object} opts - init options
     * @return {boolean}
     */
    initCheck(cmpName, opts) {
        let result = opts.carousels || !~CAROUSELS.indexOf(cmpName);

        if (result) {
            result = opts.swatches || !~SWATCHES.indexOf(cmpName);
        }

        return result;
    }

    /**
     * Return true, when all the parents are allowed to init nested
     *
     * @param {jQuery} $el - element of component that need to be checked
     * @param {Object} opts - init options
     * @return {boolean}
     */
    initParentCheck($el, opts) {
        let result = true;

        $el.parents(SELECTORS.cmp).each((idx, el) => {
            if (result && !this.initCheck($(el).data('cmp'), opts)) {
                result = false;
            }
        });

        if (result && !opts.recommendations) {
            result = !$el.parents(SELECTORS.recommendation).length;
        }

        return result;
    }

    /**
     * Init specified component
     * @param {JQuery} $el - element of component that need to be initialized
     * @param {Object} opts - init options
     * @returns {Promise}
     */
    initComponent ($el, opts = {}) {
        opts = Object.assign({
            initSession: opts.initSession || uuidv4(),
            reInit: false, // re-init
            carousels: true, // init carousels
            swatches: true, // init swatches
            recommendations: true // init recommendations
        }, opts);

        return new Promise((resolve, reject) => { // eslint-disable-line complexity
            const cmpName = $el.data('cmp'),
                currentInstance = $el.data('cmp-instance');

            const doInit = (cmp) => {
                if (typeof cmp.init === 'function') {
                    cmp.$el.data('cmp-real-id', cmp.id);

                    try {
                        utils.collectExecutionData(cmp.init, cmp, opts.reInit);
                    } catch (error) {
                        console.error(`Initialization of component ${cmpName} failed: ${error.message}`); // eslint-disable-line no-console
                        utils.setInitStatus(cmpName, 'skipped');
                        reject(error.message);

                        return;
                    }
                }
                utils.setInitStatus(cmpName, 'initialized');
                resolve(cmp);
            };

            if (currentInstance && currentInstance.$el && currentInstance.$el.length) {
                if (!opts.reInit) {
                    resolve(currentInstance);

                    return;
                } else if (opts.initSession && (opts.initSession === $el.data('cmp-init-session'))) {
                    resolve(currentInstance);

                    return;
                }
            }

            if (!cmpName || !this.componentsRegister[cmpName] || !this.initParentCheck($el, opts)) {
                resolve(null);

                return;
            }

            let cmp;

            if (currentInstance) {
                cmp = currentInstance;
            } else {
                const Component = this.componentsRegister[cmpName];
                const mixinsData = $el.attr('data-mixins');
                let mixinsList;

                if (mixinsData) {
                    try {
                        mixinsList = JSON.parse(mixinsData);
                    } catch (e) {
                        console.log(e); // eslint-disable-line no-console
                    }
                }

                if (mixinsList && Array.isArray(mixinsList) && mixinsList.length) {
                    mixinsList.forEach(mixinName => {
                        const mixin = mixins[mixinName];

                        if (mixin) {
                            Object.assign(Component.prototype, mixin);
                        }
                    });
                }

                cmp = new Component($el, Object.assign({}, $el.data()), this.eventEmitter);

                $el.data('cmp-instance', cmp);
            }

            $el.data('cmp-init-session', opts.initSession);

            if (this.initCheck(cmpName, opts)) {
                const promises = $.makeArray($el.find(SELECTORS.cmp).map((i, el) => this.initComponent($(el), opts)));

                Promise.all(promises).then((nestedComponents) => {
                    cmp._nestedComponents = cmp._nestedComponents.concat(nestedComponents.filter((nestedComponent) => {
                        return !!nestedComponent;
                    }));

                    doInit(cmp);
                });
            } else {
                doInit(cmp);
            }
        });
    }

    /**
     * ReInit specified component
     * @param {JQuery} $el - element of component that need to be re-initialized
     * @param {Object} opts - init options
     * @returns {Promise}
     */
    reInitComponent($el, opts) {
        opts = Object.assign({ reInit: true }, opts);

        return new Promise((resolve, reject) => {
            this.initComponent($el, opts).then((cmp) => {
                let parent = this.getParentCmp(cmp);

                if (parent && parent._nestedComponents) {
                    parent._nestedComponents.push(cmp);
                }

                resolve(cmp);
            }).catch((reason) => {
                console.error('reInitComponent error:', reason); // eslint-disable-line no-console

                reject(reason);
            });
        });
    }

     /**
     * Destroy specified component
     * @param {JQuery} $el - element of component that need to be destroyed
     * @returns {Promise}
     */
    destroyComponent ($el) {
        return new Promise((resolve) => {
            let cmpInstance = $el.data('cmp-instance');

            if (cmpInstance) {
                if (cmpInstance.$el && cmpInstance.$el.length) {
                    let parent = this.getParentCmp(cmpInstance);

                    if (parent) {
                        for (let [i, parentSubComponent] of parent._nestedComponents.entries()) {
                            if (parentSubComponent.id === cmpInstance.id) {
                                parent._nestedComponents.splice(i, 1);
                                break;
                            }
                        }
                    }
                }

                if (typeof cmpInstance.destroy === 'function') {
                    cmpInstance.destroy();
                    $el.removeData('cmp-instance');
                }
            }

            resolve();
        });
    }

    /**
     * Executes before all components being initialized
     */
    beforeInit() {
    }

    /**
     * Executes after all components being initialized
     */
    afterInit() {
        utils.exposeStatuses();
    }

    init () {
        $(document).ready(() => {
            this.beforeInit();

            this.initAllComponents(null, { recommendations: false, carousels: false })
                .then(() => {
                    this.afterInit();
                });
        });
    }
}
