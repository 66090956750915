import { isTablet, isMobile } from 'client/utils/mobileDetect';
import { appendParamsToURL } from 'client/utils/url';
import { load } from 'client/utils/ajax';
import $ from 'jquery';

import Component from 'client/core/Component';

const defaultEventData = {
    eventCategory: '',
    eventAction: '',
    eventLabel: '',
    eventValue: '',
    eventNonInteraction: 'FALSE',
    event: 'gtmEvent'
};
const BOPIS_EVENT_CATEGOTY = 'bopis';
const BOPIS_FAVOURITE_ACTION = 'pdp-layer/set-favorite-store';
const BOPIS_PRESELECT_ACTION = 'pdp/preselected-store';
const OPTION_PREFIX = {
    2: 'type_',
    3: 'delivery_',
    4: 'payment_'
};
const DATALAYERSCRIPTID = 'dataLayerScript';

export default class Gtm extends Component {
    onAddedToCart(data, list) {
        if (!this.trackingEnabled) {
            return;
        }

        if (data.gtm) {
            let product = data.gtm;

            if (typeof product === 'string') {
                try {
                    product = JSON.parse(product);
                } catch (e) {
                    return;
                }
            }

            const eventData = {
                'currencyCode': window.SitePreferences.currencyCode,
                'add': { // 'add' actionFieldObject measures.
                    'products': [Object.assign({ quantity: 1 }, product)]
                }
            };

            if (list) {
                eventData.add.actionField = {
                    list: list
                };
            }

            this.onEcommerceEvent('eeAddToBasket', eventData);
            this.emitter.emit('cart.add.added.gtm', eventData);
        }
    }

    onCheckoutOptionsEvent(step, option) {
        if (!this.trackingEnabled) {
            return;
        }

        let data = {
            'checkout_option': {
                'actionField': {
                    'step': step,
                    'option': Array.isArray(option) ?
                        option.map(el => OPTION_PREFIX[step] + el).join(',') :
                        OPTION_PREFIX[step] + option
                }
            }
        };

        this.onEcommerceEvent('eeCheckoutOptions', data);
    }

    onEcommerceEvent(eventName, eventData, additionalData) {
        if (!this.trackingEnabled) {
            return;
        }

        let data = {
            event: eventName,
            ecommerce: eventData
        };

        if (additionalData) {
            data = Object.assign({}, data, additionalData);
        }

        window.dataLayer.push(data);
    }

    onEvent(eventData) {
        if (!this.trackingEnabled) {
            return;
        }

        if (typeof eventData.uuid === 'undefined' && eventData.eventAction === 'add to wishlist') return;

        var dataLayer = window.dataLayer;

        if (!this.dataLayerInited) {
            dataLayer = window.dataLayerMissedEvents;
        }

        if (this.isDuplicateActions(eventData.eventAction)) {
            if (eventData.eventAction === 'size selection') {
                eventData.ga4eventname = 'product_size_selection';
            }
            dataLayer.push(Object.assign({}, defaultEventData, eventData));
        }

        const duplicate = dataLayer.find(item => (item.eventAction === eventData.eventAction) &&
        (item.uuid === eventData.uuid));

        if (duplicate) return;

        if ('eventCategory' in eventData &&
            eventData.eventCategory === BOPIS_EVENT_CATEGOTY &&
            eventData.eventAction === BOPIS_FAVOURITE_ACTION
        ) {
            let preselectEvent = dataLayer.find(event => event.eventAction === BOPIS_PRESELECT_ACTION);

            if (preselectEvent) {
                preselectEvent.eventLabel = eventData.eventLabel;
            }
        }

        dataLayer.push(Object.assign({}, defaultEventData, eventData));

    }

    loadScript() {
        let loadUrl = appendParamsToURL(
            this.config.loadScriptUrl,
            { action: this.config.gtmInitParams.action, params: JSON.stringify(this.config.gtmInitParams) }
        );
        let promise = load({
            url: loadUrl,
            type: 'GET'
        });

        promise.then(response => {
            if (response) {
                $(response).appendTo($(this.$htmlHead));
                window.dataLayerInited = true;
            }
        });
    }

    // eslint-disable-next-line class-methods-use-this
    isDuplicateActions(eventAction) {
        const duplicateActions = [
            'ee-add-to-basket',
            'size selection',
            'color selection',
            'products-to-be-removed-layer',
            'gift-cards-to-be-removed-layer',
            'search-suggestion-layer/open',
            'account_creation_checkbox'
        ];

        return duplicateActions.indexOf(eventAction) > -1;
    }

    onRefinementApplyFilter(eventData) {
        if (!this.trackingEnabled) {
            return;
        }

        let attribute,
            eventLabel;

        switch (eventData.type) {
            case 'attribute':
                attribute = eventData.attribute;
                eventLabel = eventData.values.join(',');
                break;
            case 'price':
                attribute = eventData.attribute;
                Object.keys(eventData.values).forEach((key) => {
                    const data = {
                        eventCategory: 'filter',
                        eventAction: `${eventData.type} ${key} apply`,
                        eventLabel: eventData.values[key],
                        ga4eventname: 'plp_filter'
                    };

                    this.onEvent(data);
                });
                return;
            case 'category':
                attribute = eventData.type;
                eventLabel = eventData.name;
                break;
            default:
                return;
        }

        const data = {
            eventCategory: 'filter',
            eventAction: `${attribute} apply`,
            eventLabel: eventLabel,
            ga4eventname: 'plp_filter'
        };

        this.onEvent(data);
    }

    // eslint-disable-next-line class-methods-use-this
    getDevice() {
        if (isTablet()) {
            return 'tablet';
        } else if (isMobile()) {
            return 'mobile';
        }

        return 'desktop';
    }

    onCartUpdated(resp, list) {
        if (!this.trackingEnabled) {
            return;
        }

        if (resp.gtmCheckout && (list !== 'minicart')) {
            this.onEcommerceEvent('eeCheckout', resp.gtmCheckout);
        }
    }

    onCheckoutShippingChangeCountryEvent(data) {
        if (!this.trackingEnabled) {
            return;
        }

        if (this.dataLayerInited) {
            window.dataLayer[0].country = data.country;
            window.dataLayer[0].language = data.language;
        } else {
            window.dataLayerMissedEvents[0].country = data.country;
            window.dataLayerMissedEvents[0].language = data.language;
        }
    }

    onChangeListResults(data) {
        if (!this.trackingEnabled) {
            return;
        }

        if (this.dataLayerInited) {
            window.dataLayer[0].listResults = data.count || 0;
            window.dataLayer[0].topTenProduct = data.topTenProduct || [];
        } else {
            window.dataLayerMissedEvents[0].listResults = data.count || 0;
            window.dataLayerMissedEvents[0].topTenProduct = data.topTenProduct || [];
        }
    }

    onChangePageType(data) {
        if (!this.trackingEnabled) {
            return;
        }

        if (this.dataLayerInited) {
            window.dataLayer[0].pageType = data.pageType;
        } else {
            window.dataLayerMissedEvents[0].pageType = data.pageType;
        }

        if ('ga4pageTitle' in data) {
            if (this.dataLayerInited) {
                window.dataLayer[0].ga4pageTitle = data.ga4pageTitle;
            } else {
                window.dataLayerMissedEvents[0].ga4pageTitle = data.ga4pageTitle;
            }
        }
    }

    initDataLayerListeners() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayerMissedEvents = window.dataLayerMissedEvents || [];
        window.dataLayerMissedEvents.push = this.dataLayerPush.bind(this);

        this.emitter.addListener('gtmEvent', this.onEvent.bind(this));
        this.emitter.addListener('gtmEcommerceEvent', this.onEcommerceEvent.bind(this));
        this.emitter.addListener('gtmCheckoutOptionsEvent', this.onCheckoutOptionsEvent.bind(this));
        this.emitter.addListener('refinement.applyFilter', this.onRefinementApplyFilter.bind(this));
        this.emitter.addListener('cart.add.added', this.onAddedToCart.bind(this));
        this.emitter.addListener('cart.updated', this.onCartUpdated.bind(this));
        this.emitter.addListener('gtmChangeCountryEvent', this.onCheckoutShippingChangeCountryEvent.bind(this));
        this.emitter.addListener('gtmChangeListResults', this.onChangeListResults.bind(this));
        this.emitter.addListener('gtmChangePageType', this.onChangePageType.bind(this));

        const ecommerceEvents = this.config.ecommerceEvents;

        if (ecommerceEvents && Array.isArray(ecommerceEvents) && ecommerceEvents.length) {
            // eslint-disable-next-line no-restricted-syntax
            for (const event of ecommerceEvents) {
                this.onEcommerceEvent(event.event, event.data);
            }
        }

        this.emitter.addListener('gtmDisableTracking', () => {
            this.trackingEnabled = false;
            // eslint-disable-next-line no-console
            console.log('GTM tracking disabled');
        });
    }

    initDataLayer() {
        if (this.dataLayerInited) {
            return;
        }

        if (!window.dataLayerInited) {
            this.loadScript();
        }

        var maxAttempts = 200;
        var interval = setInterval(() => {
            var startEvents = window.dataLayer.filter((dlEvent) => {
                return this.requiredEvents.includes(dlEvent.event);
            });

            if (window.dataLayerInited && startEvents.length >= this.requiredEvents.length) {
                clearInterval(interval);
                this.dataLayerInited = true;

                var dataLayerScript = document.getElementById(DATALAYERSCRIPTID);

                if (dataLayerScript) {
                    dataLayerScript.setAttribute('data-dLayer-name', 'dataLayer');
                }

                this.moveEventsToDataLayer();
            }

            // eslint-disable-next-line no-plusplus
            maxAttempts--;
            if (maxAttempts < 0) {
                // eslint-disable-next-line no-console
                console.warn('Failed to init GTM.');
                clearInterval(interval);
            }
        }, 500);
    }

    // eslint-disable-next-line class-methods-use-this
    moveEventsToDataLayer() {
        if (window.dataLayerMissedEvents && window.dataLayerMissedEvents.length > 0) {
            for (let i = 0; window.dataLayerMissedEvents.length > 0; i++) {
                const event = window.dataLayerMissedEvents.shift();

                window.dataLayer.push(event);
            }
        }
    }

    dataLayerPush(event) {
        var states = [].slice.call(arguments, 0);

        if (this.dataLayerInited || this.requiredEvents.includes(event.event)) {
            this.oldDataLayerPush.apply(window.dataLayer, states);
        } else {
            window.dataLayerMissedEvents[window.dataLayerMissedEvents.length] = event;
        }
    }

    init () {
        this.$htmlHead = this.$el.parent();
        this.dataLayerInited = false;
        this.requiredEvents = JSON.parse(window.SitePreferences.gtmWaitEvents);
        this.trackingEnabled = true;

        window.dataLayerMissedEvents = window.dataLayerMissedEvents || [];
        window.dataLayerMissedEvents.push = this.dataLayerPush.bind(this);

        if (window.dataLayer) {
            if (!window.SitePreferences.gtmForcedLoad) {
                window.dataLayerMissedEvents = [...window.dataLayer, ...window.dataLayerMissedEvents];

                this.oldDataLayerPush = window.dataLayer.push;
                window.dataLayer.push = this.dataLayerPush.bind(this);
            }
        }

        if (!window.consentStatus && !window.SitePreferences.gtmForcedLoad) {
            window.addEventListener('apply.consent.cookie', this.initDataLayer.bind(this));
        } else {
            this.initDataLayer();
        }
        this.initDataLayerListeners();
    }
}
