import Component from 'client/core/Component';
import { compile } from 'handlebars';

const SELECTORS = {
    target: '.js-target',
    source: '.js-source'
};

export default class ProductComponent extends Component {

    onVariationUpdate(product) {
        let result = this.template(product);

        this.$target.html(result);
    }

    // eslint-disable-next-line consistent-return
    init() {
        this.emitter.addListener('variation.updated', (product) => this.onVariationUpdate(product));

        this.$target = this.$el.find(SELECTORS.target);
        this.source = this.$el.find(SELECTORS.source).html();
        this.template = compile(this.source);
    }
}
