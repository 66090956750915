import StickyButton from 'client/components/StickyButton';
import { CLASSES } from 'client/utils/globals';
import { isSmall, isMedium } from 'client/utils/screendetector';

export default class StickyButtonTopBottom extends StickyButton {
    init() {
        super.init();

        this.isStickyBtn = true;
        this.isStickyTop = false;
        this.isMobile = isSmall() || isMedium();

        this.onScroll();

        this.emitter.addListener('back.to.top', () => this.makeElStatic());
    }

    startPosition(isBtnStaticPosition, isBtnStickyTopPosition, isBtnStickyBottomPosition) {
        if (isBtnStaticPosition) {
            this.makeElStatic();
        } else if (isBtnStickyTopPosition || isBtnStickyBottomPosition) {
            this.makeElSticky(isBtnStickyBottomPosition);
        }
    }

    onScroll() {
        let { height } = screen;
        let scrollTop = this.$window.scrollTop();
        let elOffsetTop = this.$el.offset().top - (this.tabbarHeight || 0);

        if (!isSmall() && !isMedium()) {
            if (this.isMobile) {
                this.removeClassPrevState(CLASSES.stickyElementTop);
                this.removeClassPrevState(CLASSES.stickyElementBottom);

                this.isMobile = false;
            }

            if (scrollTop > elOffsetTop) {
                this.emitter.emit('show.sticky.panel');
            } else {
                this.emitter.emit('hide.sticky.panel');
            }
            return;
        }

        let positionBtn = scrollTop + height - this.$stickyElement.innerHeight(); // eslint-disable-line no-mixed-operators
        let isBtnStaticPosition = positionBtn >= elOffsetTop && scrollTop <= elOffsetTop;
        let isBtnStickyBottomPosition = positionBtn < elOffsetTop;
        let isBtnStickyTopPosition = scrollTop > elOffsetTop;

        if (!this.isMobile) {
            this.emitter.emit('hide.sticky.panel');

            this.isMobile = true;
            this.startPosition(isBtnStaticPosition, isBtnStickyTopPosition, isBtnStickyBottomPosition);

            return;
        }

        if (!this.disabledScroll) {
            if (isBtnStaticPosition && (this.isStickyBtn || this.isStickyTop)) {
                this.makeElStatic();
            } else if (!this.isStickyTop && isBtnStickyTopPosition) {
                this.makeElSticky(isBtnStickyBottomPosition);
            } else if (!this.isStickyBtn && isBtnStickyBottomPosition) {
                this.makeElSticky(isBtnStickyBottomPosition);
            }
        }
    }

    removeClassPrevState(classPosition) {
        if (this.$el.hasClass(classPosition)) {
            this.$el.removeClass(classPosition);
        }
    }

    makeElStatic() {
        this.removeClassPrevState(CLASSES.stickyElementTop);
        this.removeClassPrevState(CLASSES.stickyElementBottom);

        this.isStickyBtn = false;
        this.isStickyTop = false;
        this.$el.css('height', 'unset');
    }

    makeElSticky(isBtnStickyBottomPosition) {
        this.$el.css('height', this.$stickyElement.innerHeight());

        if (isBtnStickyBottomPosition) {
            this.$el.addClass(CLASSES.stickyElementBottom);
            this.$el.removeClass(CLASSES.stickyElementTop);
            this.isStickyBtn = true;
            this.isStickyTop = false;
        } else {
            this.$el.addClass(CLASSES.stickyElementTop);
            this.$el.removeClass(CLASSES.stickyElementBottom);
            this.isStickyBtn = false;
            this.isStickyTop = true;
        }
    }
}
