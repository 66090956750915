'use strict';

import { perimeterXErrorHandler } from 'client/utils/perimeterX';
import { appendParamToURL } from 'client/utils/url';
import $ from 'jquery';
import { getToken } from 'urls';

let currentRequests = [];
let errorHandlers = [];


function makeHash(url, data) {
    var params = '';

    if (data) {
        params = JSON.stringify(data);
    }

    return url + params;
}

/**
 * @function
 * @description Ajax request to get json response
 * @param {Boolean} async  Asynchronous or not
 * @param {String} url URI for the request
 * @param {Object} data Name/Value pair data request
 * @param {Function} callback  Callback function to be called
 * @returns {JQuery.jqXHR}
 */
var getJson = function (options) {
    if (
        (!options.ignoreCache) &&
        currentRequests[makeHash(options.url, options.data)]
    ) {
        return currentRequests[makeHash(options.url, options.data)];
    }

    // make the server call
    var deffered = $.ajax({
        dataType: options.dataType || 'json',
        headers: options.headers || {},
        type: options.type || 'GET',
        contentType: options.contentType || ((options.type && (options.type.toUpperCase() === 'POST')) ?
            'application/x-www-form-urlencoded; charset=UTF-8' : 'application/json'),
        url: appendParamToURL(options.url, 'format', options.format || 'ajax'),
        async: (typeof options.async === 'undefined' || options.async === null) ? true : options.async,
        data: options.data || {}
    })
        // success
        .done((response) => {
            if (options.callback) {
                options.callback(response);
            }
            if (options.spinner) {
                $.spinner.stop(options.spinner);
            }
        })
        // failed
        .fail((xhr, textStatus, errorThrown) => {
            if (options.callback) {
                options.callback(null);
            }

            if (options.spinner) { // stop current spinner
                $.spinner.stop(options.spinner);
            }

            for (let handler of errorHandlers) {
                handler(xhr, textStatus, errorThrown);
            }
        })
        // executed on success or fail
        .always(() => {
            // remove current request from hash
            if (currentRequests[makeHash(options.url, options.data)]) {
                delete currentRequests[makeHash(options.url, options.data)];
            }

            // if there is no more requests pending then remove all spinners
            if (currentRequests.length === 0) {
                !options.doNotStopSpinner && $.spinner.stopAll();
            }
        });

    currentRequests[makeHash(options.url, options.data)] = deffered;
    return deffered;
};
/**
 * @function
 * @description ajax request to load html response in a given container
 * @param {String} url URI for the request
 * @param {Object} data Name/Value pair data request
 * @param {Function} callback  Callback function to be called
 * @param {Object} target Selector or element that will receive content
 * @returns {JQuery.jqXHR}
 */
var load = function (options) {
    if (
        (!options.ignoreCache) &&
        currentRequests[makeHash(options.url, options.data)]
    ) {
        return currentRequests[makeHash(options.url, options.data)];
    }

    let callParams = {
        type: options.type || 'GET',
        dataType: options.dataType || 'html',
        url: appendParamToURL(options.url, 'format', options.format || 'ajax'),
        data: options.data
    };

    if (options.contentType) {
        callParams.contentType = options.contentType;
    }

    if (options.async) {
        callParams.async = options.async;
    }

    // make the server call
    var deffered = $.ajax(callParams)
        .done((response) => {
            // success
            if (options.target) {
                $(options.target).empty().html(response);
            }
            if (options.callback) {
                options.callback(response);
            }
            if (options.spinner) {
                $.spinner.stop(options.spinner);
            }
        })
        .fail((xhr, textStatus, errorThrown) => {
            // failed
            if (options.callback) {
                options.callback(null, textStatus);
            }

            if (options.spinner) { // stop current spinner
                $.spinner.stop(options.spinner);
            }

            for (let handler of errorHandlers) {
                handler(xhr, textStatus, errorThrown);
            }
        })
        .always(() => {
            // remove current request from hash
            if (currentRequests[makeHash(options.url, options.data)]) {
                delete currentRequests[makeHash(options.url, options.data)];
            }

            // if there is no more requests pending then remove all spinners
            if (currentRequests.length === 0) {
                $.spinner.stopAll();
            }
        });

    currentRequests[makeHash(options.url, options.data)] = deffered;
    return deffered;
};

var registerPrefilter = function(prefilter) {
    if (prefilter) {
        $.ajaxPrefilter(prefilter);
    }
};

var registerDataFilter = function (dataFilter) {
    if (typeof dataFilter === 'function') {
        $.ajaxSetup({
            dataFilter: dataFilter
        });
    }
};

var registerErrorHandler = function (handler) {
    if (typeof handler === 'function') {
        errorHandlers.push(handler);
    }
};

perimeterXErrorHandler(registerErrorHandler);

var getCSRFToken = async function (data = {}) {
    return (await getJson(Object.assign({}, {
        url: getToken,
        type: 'POST'
    }, data))).csrf;
};

module.exports = load;
module.exports.getJson = getJson;
module.exports.load = load;
module.exports.registerPrefilter = registerPrefilter;
module.exports.registerDataFilter = registerDataFilter;
module.exports.registerErrorHandler = registerErrorHandler;
module.exports.getCSRFToken = getCSRFToken;

export default load;

export {
    getJson,
    load,
    registerPrefilter,
    registerDataFilter,
    registerErrorHandler,
    getCSRFToken
};
