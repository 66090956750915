import Component from 'client/core/Component';

const SELECTORS = {
    SUBMIT_BTN: '.js-submit-order'
};

export default class AcceptMobileGdpr extends Component {
    init () {
        this.$submitMobileBtn = this.$el.find(SELECTORS.SUBMIT_BTN);
        this.$submitMobileBtn.on('click', this.onClick.bind(this));
    }

    onClick(el, ev) {
        if (ev) {
            ev.preventDefault();
        }

        this.emitter.emit(this.$submitMobileBtn.data('submit-event'));
    }
}
